import React from 'react';
import { Link } from 'react-router-dom';
import './RecoverPassword.scss';

/**
 * PasswordSent Component, just shows and asks user to come back to login.
 */
const ResetPasswordLogin = () => (
  <div className="PasswordSent">
    <h6 className="text-left">Password updated!</h6>
    <br />
    <div className="grid-x">
      <div className="small-12 medium-6 cell">
        <Link to="/login">
          <input type="button" className="button expanded login-btn" value="Login Again" />
        </Link>
      </div>
    </div>
  </div>
);

export default ResetPasswordLogin;
