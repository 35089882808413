import { bpServiceStatic } from "./BPService";
import { generateViewMap } from "./BPServiceUtils";
import { BPVIEWS } from "../../shared/Constants";
import Notify from "../../utils/Notify";
import { errorLog } from "../../utils/ErrorTracker";
import {
  getProjectPlatformsBound,
  getProjectSMFGoalsBound
} from "../../projects/ProjectReduxUtils";
import { commonDataServiceStatic } from "../../services/CommonData";
import { commonDataLoadFlagsBound } from "../../actions/commonDataActionsUtils";
import {
  getViewFromCommon,
  getMetaMetasFromCommon
} from "../../selectors/commonSelectors";
import { projectConstantsServiceStatic } from "../../services/ProjectConstantsService";
import { setDataBusFormattingBound } from "../bp/bpUtils";
import { memStorage } from "../../services/Storage";

const SEP = "_";

memStorage.setPrefixesToClean([
  BPVIEWS.DB_PREFIX,
  BPVIEWS.META_PREFIX,
  BPVIEWS.META_USER_PREFIX
]);

/**
 * Gets view from local storage
 * @param {string} name name of view from db
 * @param {string|number} projectId
 */
export const getView = (name, projectId) => {
  name = name.replace(/\s/g, "_");
  const retVal = memStorage.get(
    BPVIEWS.DB_PREFIX + SEP + projectId + SEP + name
  );
  if (!retVal) {
    errorLog(`View ${name} not found`);
    Notify.fireError(`View ${name} not found, system administrator notified.`);
  }
  return retVal;
};

export const getViewById = viewId => {
  const views = memStorage.get("viewsById");
  let tmpViews = {};
  let retVal = [];
  if (views) {
    tmpViews = { ...views };
    if (Array.isArray(tmpViews[viewId])) {
      retVal = [...tmpViews[viewId]];
    }
  }
  return retVal;
};

export const getViewsByIdMapped = viewId => {
  const viewsByIdMapped = memStorage.get("viewsByIdMapped");
  if (Array.isArray(viewsByIdMapped[viewId])) {
    return [...viewsByIdMapped[viewId]];
  } else if (viewsByIdMapped[viewId].__proto__.constructor.name === "Object") {
    return { ...viewsByIdMapped[viewId] };
  }
  return [];
};

export const getViewBySortedByCols = (name, projectId) =>
  getView(name + BPVIEWS.MAPPED_BY_NAME_SUFFIX, projectId);

export const getAllColsBySortedByCols = projectId =>
  getView(BPVIEWS.VIEWS.ALLCOLUMNS + BPVIEWS.MAPPED_BY_NAME_SUFFIX, projectId);

export const getAllTacticColsBySortedByCols = projectId =>
  getView(TACTIC_VIEW_STUB.viewName + BPVIEWS.MAPPED_BY_NAME_SUFFIX, projectId);

export const getAllCols = (projectId, level) =>
  getView(level + "." + BPVIEWS.VIEWS.ALLCOLUMNSNAME, projectId);

/**
 * Saves view to local storage
 * @param {string} name name of view from db
 * @param {*} obj view object
 */
export const saveView = (name, obj) => {
  memStorage.set(BPVIEWS.DB_PREFIX + name, obj);
};

export const saveViewsById = obj => {
  memStorage.set("viewsById", obj);
};

export const saveViewsByIdMapped = obj => {
  memStorage.set("viewsByIdMapped", obj);
};

export const removeViewsMetaInfo = (projectId, viewId) => {
  const views = getViewsMetaInfo(projectId);
  const filtered = views.filter(itm => itm.id !== viewId);
  memStorage.set(`${BPVIEWS.META_PREFIX}_${projectId}`, filtered);
  commonDataLoadFlagsBound("bpView", 1);
};

export const saveViewsMetaInfo = (projectId, obj) => {
  memStorage.set(`${BPVIEWS.META_PREFIX}_${projectId}`, obj);
};

export const removeCustomViewsMetaInfo = (projectId, viewId) => {
  const views = getCustomViewsNames(projectId);
  const filtered = views.filter(itm => itm.id !== viewId);
  saveCustomViewsNames(projectId, filtered);
  commonDataLoadFlagsBound("bpView", 1);
};

export const saveCustomViewsNames = (projectId, obj) => {
  memStorage.set(`${BPVIEWS.META_USER_PREFIX}_${projectId}`, obj);
};

// Seems to be not used anymore?
export const getCustomViewsNames = projectId => {
  const xx = memStorage.get(`${BPVIEWS.META_USER_PREFIX}_${projectId}`);
  console.log("[BPViewService.js] xxx", xx);
  return xx;
};

/**
 * Returns all views of the project.
 * @param {int|string} projectId
 */
export const getViewsMetaInfo = projectId =>
  memStorage.get(`${BPVIEWS.META_PREFIX}_${projectId}`);

export const getFirstAvailableView = projectId => {
  const views = getViewsMetaInfo(projectId);
  const first = views.find(
    v => v.viewName !== "AllColumns" && v.type === "dynamic1"
  );
  return first;
};

export const getViewMetaInfoByProject = (viewName, projectId) => {
  const metas = getViewsMetaInfo(projectId);
  return metas.find(
    m => `${m.type}.${m.viewName.replace(/\s/g, "_")}` === viewName
  );
};

export const getViewMetaInfoByProjectAndId = (viewId, projectId) => {
  const metas = getViewsMetaInfo(projectId);
  return metas.find(m => m.id === viewId);
};

const TACTIC_VIEW_STUB = {
  id: 9999,
  viewName: "Traffic",
  details: [
    {
      id: 1,
      field: "textColumn2",
      weight: 10
    },
    {
      id: 2,
      field: "textColumn3",
      weight: 20
    },
    {
      id: 3,
      field: "textColumn4",
      weight: 30
    },
    {
      id: 4,
      field: "textColumn5",
      weight: 40
    },
    {
      id: 5,
      field: "textColumn6",
      weight: 50
    },
    {
      id: 6,
      field: "creativeTraffickingName",
      weight: 60
    },
    {
      id: 7,
      field: "ctaText",
      weight: 70
    },
    {
      id: 8,
      field: "adTypeId",
      weight: 80
    }
  ]
};

/** Loads, transforms views to be accessible via names, etc and saves to local storage */
export const initViews = (projectId, cb) => {
  bpServiceStatic.loadMetas(projectId, (viewsRaw, metas, lists) => {
    saveViewsMetaInfo(projectId, viewsRaw);
    const customViewsNames = [];
    const projectPlatforms = getProjectPlatformsBound(projectId) || [];
    const smfGoals = getProjectSMFGoalsBound(projectId) || [];
    const viewsById = {};
    const viewsByIdMapped = {};

    console.log("[BPViewService.js] setDataBusFormattingBound", metas);

    if (metas[0]) {
      setDataBusFormattingBound("dynamic1", metas[0].formats);
    }
    if (metas[1]) {
      setDataBusFormattingBound("dynamic2", metas[1].formats);
    }
    if (metas[2]) {
      setDataBusFormattingBound("dynamic3", metas[2].formats);
    }

    viewsRaw.forEach(view => {
      const viewName = view.type + "." + view.viewName.replace(/\s/g, "_");
      const dynLevels = {
        dynamic1: 0,
        dynamic2: 1,
        dynamic3: 2
      };

      const level = dynLevels[view.type];

      const genView = generateViewMap(
        view,
        metas[level],
        lists[level],
        projectPlatforms,
        smfGoals
      );

      viewsById[view.id] = genView;

      saveView(`${SEP}${projectId}${SEP}${viewName}`, genView);

      if (view.userId) {
        customViewsNames.push({
          key: viewName,
          name: view.viewName,
          id: view.id
        });
      }
      const remappedByColName = {};
      genView.forEach(elem => {
        remappedByColName[elem.name] = elem;
      });
      // saving mapped by name, this reduces look up times.
      saveView(
        `${SEP}${projectId}${SEP}${viewName}${BPVIEWS.MAPPED_BY_NAME_SUFFIX}`,
        remappedByColName
      );
      viewsByIdMapped[view.id] = remappedByColName;
    });
    saveViewsById(viewsById);
    saveViewsByIdMapped(viewsByIdMapped);
    // console.log("[BPViewService.js] viewsByIdviewsById", viewsById);
    saveCustomViewsNames(projectId, customViewsNames);
    commonDataLoadFlagsBound("bpView", 1);
    if (cb) {
      setTimeout(() => {
        //TODO Not fully solved, but only fails 1/100 times. Fix properly
        cb("bpView", 1);
      }, 1500);
    }
  });
  // TODO: dead code, clean up all references
  // bpServiceStatic.loadTrafficMetas(projectId, meta => {
  //   TACTIC_VIEW_STUB.details.forEach(() => {
  //     const genView = generateViewMap(TACTIC_VIEW_STUB, meta, {}, {});
  //     const viewName = TACTIC_VIEW_STUB.viewName.replace(/\s/g, '_');
  //     saveView(`${SEP}${projectId}${SEP}${viewName}`, genView);
  //     const remappedByColName = {};
  //     genView.forEach(elem => {
  //       remappedByColName[elem.name] = elem;
  //     });
  //     // saving mapped by name, this reduces loop up times.
  //     saveView(
  //       `${SEP}${projectId}${SEP}${viewName}${BPVIEWS.MAPPED_BY_NAME_SUFFIX}`,
  //       remappedByColName
  //     );
  //   });
  //   commonDataLoadFlagsBound('trafficView', 1);
  //   if (cb) {
  //     cb('trafficView', 1);
  //   }
  // });
};

/**
 * Common data loader
 * @param {string} page loads common data for page.
 */
export const initCommonData = (page, projectId) => {
  switch (page) {
    case "BPPage":
      commonDataServiceStatic.getPlatforms();
      commonDataServiceStatic.getSMFGoals(projectId);
      projectConstantsServiceStatic.getValues(projectId);
      break;
    default:
  }
};

export const updateView = (projectId, name, items, cbOk, cbFail) => {
  const viewCopy = getViewFromCommon(projectId, name);
  const details = items.map((item, idx) => ({
    field: item._meta.field,
    id: item._meta.id,
    weight: (idx + 10) * 10
  }));
  viewCopy.details = details;
  bpServiceStatic.updateView(projectId, viewCopy, cbOk, cbFail);
};

export const deleteVewRaw = (projectId, view, cbOk, cbFail) => {
  bpServiceStatic.updateView(
    projectId,
    view,
    () => {
      removeViewsMetaInfo(projectId, view.id);
      removeCustomViewsMetaInfo(projectId, view.id);
      cbOk();
    },
    cbFail
  );
};

export const deleteViewsRaw = (projectId, viewsArray, cbOk, cbFail) => {
  bpServiceStatic.updateViews(
    projectId,
    viewsArray,
    () => {
      removeViewsMetaInfo(projectId, viewsArray[0].id);
      removeViewsMetaInfo(projectId, viewsArray[1].id);
      removeViewsMetaInfo(projectId, viewsArray[2].id);
      removeCustomViewsMetaInfo(projectId, viewsArray[0].id);
      removeCustomViewsMetaInfo(projectId, viewsArray[1].id);
      removeCustomViewsMetaInfo(projectId, viewsArray[2].id);
      cbOk();
    },
    cbFail
  );
};

export const updateViewRaw = (projectId, view, cbOk, cbFail) => {
  bpServiceStatic.updateView(projectId, view, cbOk, cbFail);
};

export const updateViewsRaw = (projectId, viewsArray, cbOk, cbFail) => {
  bpServiceStatic.updateViews(projectId, viewsArray, cbOk, cbFail);
};

export const updateMetas = (projectId, metasDiff, cbOk, cbFail) => {
  const metasCopy = getMetaMetasFromCommon(projectId);
  Object.values(metasDiff).forEach(el => {
    metasCopy.formats[el.name] = el.format || null;
    metasCopy.formulas[el.name] = el.formula || null;
    metasCopy.tooltips[el.name] = el.tooltip || null;
    metasCopy.titles[el.name] = el.label || null;
  });
  return bpServiceStatic.updateMetas(projectId, metasCopy, cbOk, cbFail);
};

export const updateMetaSinge = (projectId, metaVal, cbOk, cbFail) => {
  const metasCopy = getMetaMetasFromCommon(projectId);
  metasCopy.formats[metaVal.name] = metaVal.format || null;
  metasCopy.formulas[metaVal.name] = metaVal.formula || null;
  metasCopy.tooltips[metaVal.name] = metaVal.tooltip || null;
  metasCopy.titles[metaVal.name] = metaVal.label || null;
  return bpServiceStatic.updateMetas(projectId, metasCopy, cbOk, cbFail);
};

export const normalizeName = name => name.replace(/\s/g, "_");

export const updateMetaSingeDX = (
  projectId,
  metaVal,
  dynamicLevelInt,
  cbOk,
  cbFail
) => {
  const metasCopy = getMetaMetasFromCommon(projectId, dynamicLevelInt);
  metasCopy.formats = metasCopy.formats || {};
  metasCopy.formulas = metasCopy.formulas || {};
  metasCopy.tooltips = metasCopy.tooltips || {};
  metasCopy.titles = metasCopy.titles || {};
  metasCopy.formats[metaVal.name] = metaVal.format || null;
  metasCopy.formulas[metaVal.name] = metaVal.formula || null;
  metasCopy.tooltips[metaVal.name] = metaVal.tooltip || null;
  metasCopy.titles[metaVal.name] = metaVal.label || null;
  metasCopy.json = metasCopy.json ? metasCopy.json : {};
  metasCopy.json[metaVal.name] = metaVal.json || null;
  return bpServiceStatic.updateMetasDX(
    projectId,
    metasCopy,
    dynamicLevelInt,
    cbOk,
    cbFail
  );
};

export const deleteMetaSingeDX = (
  projectId,
  metaFieldName,
  dynamicLevelInt,
  cbOk,
  cbFail
) => {
  const metasCopy = getMetaMetasFromCommon(projectId, dynamicLevelInt);
  metasCopy.formats = metasCopy.formats || {};
  metasCopy.formulas = metasCopy.formulas || {};
  metasCopy.tooltips = metasCopy.tooltips || {};
  metasCopy.titles = metasCopy.titles || {};
  metasCopy.formats[metaFieldName] = null;
  metasCopy.formulas[metaFieldName] = null;
  metasCopy.tooltips[metaFieldName] = null;
  metasCopy.titles[metaFieldName] = null;
  return bpServiceStatic.updateMetasDX(
    projectId,
    metasCopy,
    dynamicLevelInt,
    cbOk,
    cbFail
  );
};

export const checkIfFieldReferencedInFormula = (
  projectId,
  metaFieldName,
  dynamicLevelInt
) => {
  const metasCopy = getMetaMetasFromCommon(projectId, dynamicLevelInt);
  let found = false;
  const formulas = metasCopy.formulas;
  for (let key in formulas) {
    if (
      formulas[key] &&
      formulas[key].toString().indexOf(metaFieldName + "]") > 0
    ) {
      found = true;
      break;
    }
  }
  return found;
};
