export const getFiltersSel = (state) => {
  return state.MediaPlanStore.filters;
};

export const getMediaPlanSel = (state) => {
  return state.MediaPlanStore.mediaPlan;
};

export const getMediaPlanViewSel = (state) => {
  return state.MediaPlanStore.view;
};

export const getHookDataByLineIdSel = (state, lineId) => {
  return state.MediaPlanStore.hooksByLineId[lineId];
};

/**
 * @returns {object} ok: int, fail: int, sum: int, iconState: -2,-1,0,1 == unknown,fail, partially fail, ok
 * @param {object} state global state from redux
 * @param {number} lineId media plan line id
 */
export const getHookDataByLineIdSummarySel = (state, lineId) => {
  const hooksLines = state.MediaPlanStore.hooksByLineId[lineId];
  let ok = 0;
  let fail = 0;
  let sum = 0;
  let iconState = -2; // unknown
  if (hooksLines) {
    Object.keys(hooksLines).forEach((hdKey) => {
      if (hooksLines[hdKey].maps.complete) {
        ok++;
      } else {
        fail++;
      }
      sum++;
    });

    if (ok === sum) {
      iconState = 1;
    } else if (fail === sum) {
      iconState = -1;
    } else {
      iconState = 0;
    }
  }
  return { ok, fail, sum, iconState };
};

/// ok fail = state
///  3 0 = green
///  2 1 = yellow
//   1 2 = yellow
//   0 3 = red
//   0 0 = no hooks?
