import React, { Component } from "react";
import Dropzone from "react-dropzone";
import format from "date-fns/format";
import compareAsc from "date-fns/compare_asc";
import compareDesc from "date-fns/compare_desc";
import "./ProjectReportsMainView.scss";
import ProjectsService from "../../ProjectsService";
import FileTypes from "../fileTypes/FileTypes";
import { getParamAfterSlug } from "../../../utils/URLUtils";
import {
  DEFAULT_DATE_FORMAT,
  FILE_TYPES,
  FILE_SIZE_MAX,
} from "../../../shared/Constants";
import ProfilePicture from "../../../shared/profilepictures/ProfilePicture";
import SortImg from "../../../shared/icons/sort.svg";
import DownImg from "../../../shared/icons/download.gif";
import Notifications from "../../../shared/notifications/Notifications";
import Notify from "../../../utils/Notify";
import { bytesToSize } from "../../../utils/StringUtils";

class ProjectReportsMainView extends Component {
  constructor(props) {
    super(props);
    this.dropzoneRef = null;
    this.projectsService = new ProjectsService();
    this.state = {
      project: {},
      projectId: 0,
      reports: [],
      sortedBy: 0,
      inProgress: false,
      isUploading: false,
      isLoading: true,
      forbidden: false,
    };
    this.downStyle = {
      height: "9px",
      backgroundImage: `url(${DownImg})`,
    };
    this.upStyle = {
      height: "9px",
      backgroundImage: `url(${DownImg})`,
      transform: "scaleY(-1)",
    };
  }

  componentDidMount() {
    this.projectId = getParamAfterSlug("projects");
    this.projectsService.getProject(
      this.projectId,
      (project) => {
        this.projectCopy = project;
        const filtered = project.files.filter(
          (file) => file.type === FILE_TYPES.REPORT
        );
        const reports = filtered.map((repo) => ({
          checked: false,
          ...repo,
        }));
        this.setState({
          ...this.state,
          project,
          projectId: this.projectId,
          reports,
          isLoading: false,
        });
      },
      (err) => {
        if (err.response.status === 403) {
          this.setState({ forbidden: true });
        }
      }
    );
  }

  onFileDrop(files) {
    this.setState({ isUploading: true });
    // if file was rejected files array is empty.
    if (files[0]) {
      if (files[0].preview) {
        window.URL.revokeObjectURL(files[0].preview);
      }
      this.uploadFile(files[0]);
    }
  }

  onDropRejected = (file) => {
    this.setState({ isUploading: false });
    if (file[0].size > FILE_SIZE_MAX) {
      const { size } = file[0];
      const max = FILE_SIZE_MAX;
      Notify.fireWarn(
        `File size too large (${bytesToSize(
          size
        )}), max upload file size is ${bytesToSize(max)}`
      );
    }
  };

  selectedForDownload(file) {
    const copy = [...this.state.reports];
    copy.forEach((repo) => {
      /* eslint-disable */
      if (repo.id === file.id) {
        repo.checked = !repo.checked;
      }
      /* eslint-enable */
      return repo;
    });
    this.setState({ file, reports: copy });
  }

  uploadFile(file) {
    this.setState({ isUploading: true });
    this.projectsService.uploadReportFile(
      this.projectId,
      file,
      () => {
        this.setState({ isUploading: false }, () => this.componentDidMount());
      },
      () => {
        this.setState({ isUploading: false });
      }
    );
  }

  handleDownload() {
    this.setState({ isDownloading: true });
    const down = this.state.reports.filter((file) => file.checked);
    this.projectsService.downloadFile(
      down,
      () => {
        this.setState({ isDownloading: false });
      },
      () => {
        this.setState({ isDownloading: false });
      }
    );
  }

  handleUpload() {
    this.dropzoneRef.open();
  }

  sort(by) {
    const clone = [...this.state.reports];
    const prev = this.state.sortedBy;
    let prevNew = 0;
    let reports;
    switch (by) {
      case "name":
        if (prev === 1) {
          reports = clone.sort((a, b) =>
            a.file.name.localeCompare(b.file.name)
          );
          prevNew = 0;
        } else {
          reports = clone.sort((b, a) =>
            a.file.name.localeCompare(b.file.name)
          );
          prevNew = 1;
        }
        break;
      case "user":
        if (prev === 1) {
          reports = clone.sort((a, b) =>
            a.user.contact.firstname.localeCompare(b.user.contact.firstname)
          );
          prevNew = 0;
        } else {
          reports = clone.sort((b, a) =>
            a.user.contact.firstname.localeCompare(b.user.contact.firstname)
          );
          prevNew = 1;
        }
        break;
      case "date":
        if (prev === 1) {
          reports = clone.sort((a, b) =>
            compareAsc(a.file.dateCreated, b.file.dateCreated)
          );
          prevNew = 0;
        } else {
          reports = clone.sort((a, b) =>
            compareDesc(a.file.dateCreated, b.file.dateCreated)
          );
          prevNew = 1;
        }
        break;
      default:
        break;
    }
    this.setState({ reports, sortedBy: prevNew });
  }

  render() {
    if (this.state.forbidden) {
      return (
        <div className="BPPage">
          <div>
            <div className="u-padding-top-10 u-padding-both-sides-25">
              Sorry, seems you have no access to this page.
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="ProjectReportsMainView">
        <div className="filter-menu">
          <div className="u-padding-both-sides-25">
            <ul className="menu">
              <li>
                {/* eslint-disable-next-line */}
                <a className="active">All Files</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="u-padding-both-sides-25 u-padding-top-10">
          <Notifications />
        </div>
        <div
          className={`ld-over ${
            !this.state.isLoading ? "not-running" : "running"
          }`}
        >
          <span className="ld ld-ring ld-spin" />
          <div className="u-padding-both-sides-25">
            <div className="grid-x title-row hide-for-small-only">
              <div className="cell small-6 medium-9 name-cell-header">
                Document
                <button
                  onClick={() => this.sort("name")}
                  onKeyPress={() => this.sort("name")}
                >
                  <img src={SortImg} className="sort-img" alt="sort" />
                </button>
              </div>
              <div className="cell small-6 medium-2 date-cell-header">
                Published
                <button
                  onClick={() => this.sort("date")}
                  onKeyPress={() => this.sort("name")}
                >
                  <img src={SortImg} className="sort-img" alt="sort" />
                </button>
              </div>
              <div className="cell small-6 medium-1 by-cell-header">
                By
                <button
                  onClick={() => this.sort("user")}
                  onKeyPress={() => this.sort("name")}
                >
                  <img src={SortImg} className="sort-img" alt="sort" />
                </button>
              </div>
            </div>
            <div className="acitivty-row">
              {this.state.reports.map((obj) => (
                <div className="grid-x file-row" key={obj.id}>
                  <div
                    className="cell small-12 medium-9 u-vertical-middle u-clickable"
                    role="link"
                    tabIndex={0}
                    onKeyPress={() => this.selectedForDownload(obj)}
                    onClick={() => this.selectedForDownload(obj)}
                  >
                    <input
                      checked={obj.checked}
                      type="checkbox"
                      name="download"
                      className="download-checkbox"
                      onChange={() => {}}
                    />
                    <FileTypes type={obj.file.ext} />
                    {obj.file.name}
                  </div>
                  <div className="cell small-6 medium-2 u-vertical-middle date-cell">
                    {format(obj.file.dateCreated, DEFAULT_DATE_FORMAT)}
                  </div>
                  <div className="cell small-6 medium-1 u-vertical-middle align-center">
                    {obj.user ? <ProfilePicture user={obj.user} /> : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="u-padding-both-sides-25">
            <div className="grid-x bottom-row">
              <div className="cell small-12">
                <button
                  className="button download-button"
                  onClick={() => this.handleDownload()}
                  disabled={!this.state.file || this.state.isDownloading}
                >
                  Download
                  <div
                    className={`ld ld-over-full ld-loader ${
                      this.state.isDownloading ? "show" : "hide"
                    }`}
                    style={this.downStyle}
                  />
                </button>

                <button
                  className="button upload-button"
                  onClick={() => this.handleUpload()}
                  disabled={this.state.isUploading}
                >
                  Upload
                  <div
                    className={`ld ld-over-full ld-loader ${
                      this.state.isUploading ? "show" : "hide"
                    }`}
                    style={this.upStyle}
                  />
                </button>
                <div className="file-upload-oute">
                  <Dropzone
                    ref={(node) => {
                      this.dropzoneRef = node;
                    }}
                    disablePreview
                    multiple={false}
                    accept=""
                    maxSize={FILE_SIZE_MAX}
                    className="file-dnd"
                    onDrop={(acceptedFile) => this.onFileDrop(acceptedFile)}
                    onDropRejected={(e) => this.onDropRejected(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectReportsMainView;
