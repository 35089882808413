import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SMFSettings.scss';

import SMFSettingsBPLists from './SMFSettingsBPLists';

class SMFSettings extends Component {
  constructor(props) {
    super(props);
    this.project = this.props.project;
  }

  render() {
    return (
      <div className="SMFSettings">
        <h6>Goal Mappings</h6>
        <div className="grid-x">
          <div className="cell small-12 large-12">
            <SMFSettingsBPLists projectId={this.project.id} />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default SMFSettings;

SMFSettings.propTypes = {
  project: PropTypes.object.isRequired,
};
