import React, { Component } from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import NewProjectSubTitle from "./NewProjectSubTitle";
import NewProjectMilestoneLine from "./NewProjectMilestoneLine";
import ProjectsService from "../ProjectsService";
import { MILESTONES } from "../../shared/Constants";

/**
 * Milestones block, handles addition and removal
 * @param {func} onMilestoneChange called when milestone changes, @returns {milestone, date}
 */
class NewProjectMilestones extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectsService();
    this.iniVals = {
      date: new Date(),
    };
    this.state = {
      selectedDay: "",
      line: [],
      apiLoaded: false,
    };
    this.callbackVals = [];
    this.constructAndExportVals();
  }

  componentDidMount() {
    this.projectService.getMilestones((milestones) => {
      const linesInit = milestones.filter((el) => el.required);
      this.maxOrder = Math.max(
        ...milestones.map((elem) => (elem.order ? elem.order : 0))
      );
      this.key = this.maxOrder;
      this.setState(
        {
          ...this.state,
          milestones,
          line: linesInit,
          apiLoaded: true,
        },
        () => {
          this.constructAndExportVals();
        }
      );
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.valid.milestonesStartEnd === false) {
      const newLines = [...this.state.line];
      newLines.forEach((ln) => {
        // this is stupid and bad, but whole this validaion will be done in other place in p2.
        if (ln.originalVal.id === MILESTONES.END_ID) {
          /* eslint-disable */
          // [no-param-reassign] - does not apply here, you stupid linter.
          ln.showEndDateError = true;
          /* eslint-enable */
        }
      });
      this.setState({ line: newLines });
    }
  }

  onLineChange = (val, key) => {
    const upLine = [...this.state.line];
    const needle = upLine.find((el) => el.order === key);
    needle.selectedDate = val;
    this.setState({ line: upLine }, () => {
      this.constructAndExportVals();
    });
  };

  getNextKey = () => {
    this.key = this.key + 1;
    return this.key;
  };

  removeLine = (key) => {
    const upLine = [...this.state.line];
    const needle = upLine.filter((el) => el.order !== key);
    this.setState({ line: needle }, () => {
      this.constructAndExportVals();
    });
  };

  handleSelecteDay(day) {
    this.setState({ selectedDay: day }, () => {
      // console.log(day, this.state.selectedDay);
    });
  }

  addMoreMilestones = () => {
    const upLine = [...this.state.line];
    upLine.push({ order: this.getNextKey(), required: false });
    this.setState({ line: upLine }, () => {
      this.constructAndExportVals();
    });
  };

  constructAndExportVals = () => {
    this.callbackVals = this.state.line.map((elem) => {
      if (elem.selectedDate) {
        if (!elem.selectedDate.selectedMilestoneOption) {
          // this happens when label is removed.
          return {};
        }
        let retV = {};
        if (elem.selectedDate.selectedMilestoneOption.originalVal) {
          retV = {
            id: elem.selectedDate.selectedMilestoneOption.originalVal.id,
            date: format(elem.selectedDate.selectedDay, "YYYY-MM-DD"),
          };
        } else {
          retV = {
            name: elem.selectedDate.selectedMilestoneOption.value,
            date: format(elem.selectedDate.selectedDay, "YYYY-MM-DD"),
          };
        }
        return retV;
      }
      return {};
    });
    this.props.onMilestoneChange(this.callbackVals);
  };

  render() {
    const requiredDates = this.state.line.filter((l) => l.required);
    const optionalDates = this.state.line.filter((l) => !l.required);

    console.log("[NewProjectMilestones.jsx] linesssss", this.state.line);
    return (
      <div className="NewProjectMilestones">
        <div
          className={`grid-container ld-over ${
            this.state.apiLoaded ? "not-running" : "running"
          }`}
        >
          <span className="ld ld-ring ld-spin" />
          <div className="mid grid-x">
            <div className="cell small-12 medium-8">
              {this.props.noHeaderTest ? (
                <div />
              ) : (
                <NewProjectSubTitle title="Plan Dates" />
              )}
              {requiredDates.map((obj) => (
                <NewProjectMilestoneLine
                  key={obj.order}
                  currentStone={obj.order}
                  initDate={
                    obj.selectedDate
                      ? obj.selectedDate.selectedDay
                      : this.iniVals.date
                  }
                  showEndDateError={obj.showEndDateError}
                  milestones={this.state.milestones}
                  removeLine={() => {
                    this.removeLine(obj.order);
                  }}
                  onLineChange={(val) => {
                    this.onLineChange(val, obj.order);
                  }}
                />
              ))}
            </div>
          </div>

          <div className="mid grid-x">
            <div className="cell small-12 medium-8">
              {this.props.noHeaderTest ? (
                <div />
              ) : (
                <NewProjectSubTitle title="Other Dates" />
              )}
              {optionalDates.map((obj) => (
                <NewProjectMilestoneLine
                  key={obj.order}
                  currentStone={obj.order}
                  initDate={
                    obj.selectedDate
                      ? obj.selectedDate.selectedDay
                      : this.iniVals.date
                  }
                  showEndDateError={obj.showEndDateError}
                  milestones={this.state.milestones}
                  removeLine={() => {
                    this.removeLine(obj.order);
                  }}
                  onLineChange={(val) => {
                    this.onLineChange(val, obj.order);
                  }}
                />
              ))}
              <div className="grid-x">
                <div className="cell small-12 medium-6 v-center">
                  <button className="add-more" onClick={this.addMoreMilestones}>
                    Add another date
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewProjectMilestones;

NewProjectMilestones.defaultProps = {
  noHeaderTest: false,
};

NewProjectMilestones.propTypes = {
  onMilestoneChange: PropTypes.func.isRequired,
  valid: PropTypes.any.isRequired,
  noHeaderTest: PropTypes.bool,
};
