import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProfilePicture from "../../../shared/profilepictures/ProfilePicture";

const MainProjectLinePeopleInner = ({ users, userId }) => {
  const user = users.find(u => u.id === Number(userId));
  return (
    <div className="MainProjectLinePeople">
      {user && <ProfilePicture user={user} />}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  users: state.commonStore.users,
  userId: ownProps.userId
});

const MainProjectLinePeople = connect(mapStateToProps)(
  MainProjectLinePeopleInner
);

export default MainProjectLinePeople;

MainProjectLinePeopleInner.defaultProps = {
  users: {}
};

MainProjectLinePeopleInner.propTypes = {
  users: PropTypes.array
};

MainProjectLinePeople.propTypes = {
  userId: PropTypes.string.isRequired
};
