import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NOTE_LENGTH } from '../../../shared/Constants';
import './ProjectNotes.scss';
import ProfilePicture from '../../../shared/profilepictures/ProfilePicture';

class ProjectNotes extends Component {
  constructor(props) {
    super(props);
    this.state = { note: '', warn: '' };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.wipe) {
      this.setState({ ...this.state, note: '' });
    }
  }

  noteHandler = (event) => {
    let warn = '';
    let len = 0;
    if (this.state.note.length >= NOTE_LENGTH.WARN) {
      len = this.state.note.length + 1;
      if (len >= NOTE_LENGTH.MAX) len = NOTE_LENGTH.MAX;
      warn = `Limit:${len}/${NOTE_LENGTH.MAX}`;
    }

    this.setState({ note: event.target.value, warn });
  };

  enterKeyHandler = (event) => {
    // 13 = Enter
    if (event.keyCode === 13) {
      // if shift is not held.
      if (!event.shiftKey) {
        event.preventDefault();
        this.props.onSubmit(this.state.note);
      }
    }
  };

  render() {
    return (
      <div className="ProjectNotes">
        <div className={`ld-over ${this.props.showLoading ? 'running' : 'not-running'}`}>
          <span className="ld ld-ring ld-spin" />
          <div className="grid-x">
            <div className="cell hide-for-small-only medium-1">
              <ProfilePicture user={this.props.user} bigger />
            </div>
            <div className="cell small-12 medium-11">
              <textarea
                onChange={this.noteHandler}
                onKeyDown={this.enterKeyHandler}
                value={this.state.note}
                placeholder="Type here to add to the conversation (Enter to submit; Shift-enter for line break)."
                maxLength={NOTE_LENGTH.MAX}
              />
              <div className="warn" data-warn={this.state.warn} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectNotes;

ProjectNotes.defaultProps = {
  showLoading: false,
  wipe: false,
};

ProjectNotes.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  showLoading: PropTypes.bool,
  // used to wipe text, if API fails it should not be wiped.
  wipe: PropTypes.bool,
};
