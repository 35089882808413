import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UUID } from '../../../../utils/StringUtils';
import RoleLine from './RoleLine';
import ProjectRoleNewLine from './ProjectRoleNewLine';
import ProjectsService from '../../../ProjectsService';
import './ProjectPeopleSettings.scss';
import { ROLE_ADMIM_ID } from '../../../../shared/Constants';

class ProjectPeopleSettings extends Component {
  constructor(props) {
    super(props);
    this.projectServie = new ProjectsService();
    this.usersCopy = [];
    this.state = {
      people: [],
      roles: [],
      users: [],
      addNewLine: false,
      isAddNewLoading: false,
    };
  }

  componentDidMount() {
    this.projectServie.getUsers((users) => {
      this.usersCopy = users;
      this.setState({ users });
    });
    this.projectServie.getRoles((roles) => {
      this.setState({ roles });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.project) {
      this.setState({ ...this.state, people: nextProps.project.roles || [] }, () => {
        this.updateAllowedUsers();
      });
    }
  }

  onNewSave = (role) => {
    this.setState({ isAddNewLoading: true });

    this.projectServie.addProjectRole(
      this.props.project.id,
      role,
      () => {
        this.props.onSave();
        this.setState({
          isAddNewLoading: false,
          addNewLine: false,
        });
      },
      () => {
        this.setState({ isAddNewLoading: false });
        this.props.onFail();
      },
    );
  };

  onRemove = (role) => {
    this.setState({ isAddNewLoading: true });
    if (role.roleId === ROLE_ADMIM_ID) {
      const admins = this.state.people.filter(elem => elem.role.id === ROLE_ADMIM_ID);
      if (admins.length === 1) {
        return this.setState({ isAddNewLoading: false });
      }
    }
    return this.projectServie.removeProjectRole(
      this.props.project.id,
      role.roleMainId,
      () => {
        this.props.onSave();
        this.setState({
          isAddNewLoading: false,
          addNewLine: false,
        });
      },
      () => {
        this.setState({ isAddNewLoading: false });
        // / fail.
      },
    );
  };

  onNewCancel() {
    this.setState({ addNewLine: false });
  }

  addMorePeople() {
    this.setState({ addNewLine: true });
  }

  updateAllowedUsers = () => {
    if (this.usersCopy.length > 0 && this.state.people.length > 0) {
      const addedUsers = this.state.people.map(user => user.user.id);
      const nUsers = this.usersCopy.filter(user => !addedUsers.includes(user.value));
      this.setState({ users: nUsers });
    }
  };

  render() {
    return (
      <div className="ProjectPeopleSettings">
        <div className={`ld-over ${!this.state.isAddNewLoading ? 'not-running' : 'running'}`}>
          <span className="ld ld-ring ld-spin" />
          <div>
            <h6>People</h6>
          </div>
          <p>
            These fine people are working on your project. Assign roles so everyone knows what
            they’re doing.
            {' '}
            <br />
            {/* New users will be sent an invite via email. */}
          </p>
          {this.state.people.map((obj, ord) => (
            <RoleLine
              role={obj}
              onRemove={rmItem => this.onRemove(rmItem)}
              key={UUID()}
              data-ft-order={ord}
            />
          ))}
          <div className="add-new-outer">
            <div className="grid-x">
              <div className={`cell small-11 medium-8 ${this.state.addNewLine ? 'show' : 'hide'}`}>
                <ProjectRoleNewLine
                  saveCallback={role => this.onNewSave(role)}
                  roles={this.state.roles}
                  users={this.state.users}
                  cancelCallback={() => this.onNewCancel()}
                  showLoading={this.state.isAddNewLoading}
                />
              </div>
            </div>
            <div className="grid-x sub-title">
              <div className={`cell small-11 medium-8 ${this.state.addNewLine ? 'hide' : 'show'}`}>
                <div className="add-new">
                  <button onClick={() => this.addMorePeople()}>Add another role</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectPeopleSettings;

ProjectPeopleSettings.propTypes = {
  project: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};
