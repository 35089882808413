import React, { useState } from "react";
import PropTypes from "prop-types";
import Switch from "antd/lib/switch";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import FasthooksInclude from "../../modals/fasthooks/FasthooksInclude";
import FasthookLogs from "../../modals/fasthooks/FasthookLogs";
import { openLogsOutside } from "../../modals/fasthooks/FasthooksLogsOutside";
import ProjectAdminSettingsFashooksDefaults from "../../projects/projectView/projectSettings/projectAdminSettings/ProjectAdminSettingsFashooksDefaults";
import "./BPDevRow.scss";

export const BPDevRow = ({ projectId, project, defaultShowFashooks }) => {
  const [showFasthooks, setShowFasthooks] = useState(defaultShowFashooks);
  const [showLogs, setShowLogs] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  console.log("[BPDevRow.jsx] project", project.customer.id, projectId);
  return (
    <div className="BPDevRow">
      <Modal
        title="Fasthooks settings"
        visible={showSettings}
        onCancel={() => {
          setShowSettings(false);
        }}
        onOk={() => {
          setShowSettings(false);
        }}
        footer={null}
      >
        <ProjectAdminSettingsFashooksDefaults
          projectId={projectId}
          clientId={project.customer.id}
        />
      </Modal>
      <div className="header">
        <div className="name">Fasthooks Tools</div>
        <div className="controls">
          <Button
            icon="control"
            size="small"
            onClick={() => setShowSettings(true)}
          >
            Settings
          </Button>
          <Switch
            className="elem"
            checkedChildren="Hide Fasthooks"
            unCheckedChildren="Show Fasthooks"
            onChange={(e) => setShowFasthooks(e)}
            defaultChecked={defaultShowFashooks}
          />
          <Switch
            className="elem"
            checkedChildren="Hide Fasthooks Log"
            unCheckedChildren="Show Fasthooks Log"
            onChange={(e) => setShowLogs(e)}
          />
          <Button
            className="elem-btn"
            icon="fullscreen"
            size="small"
            type="link"
            onClick={() => {
              openLogsOutside(Number(projectId), {
                projectName: project.name,
                projectDocket: project.docket,
              });
            }}
          >
            Open logs in new window
          </Button>
        </div>
      </div>
      <div className="body">
        {showFasthooks && <FasthooksInclude projectId={Number(projectId)} />}
        {showLogs && showFasthooks && <br />}
        {showLogs && <FasthookLogs projectId={Number(projectId)} />}
      </div>
    </div>
  );
};

BPDevRow.defaultProps = {
  project: {},
  defaultShowFashooks: false,
};

BPDevRow.propTypes = {
  projectId: PropTypes.number.isRequired,
  project: PropTypes.object,
  defaultShowFashooks: PropTypes.bool,
};
