import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import './ProfilePage.scss';

const ProfileMenu = ({ onClick, active }) => (
  <div className="ProfileMenu">
    <ul className="menu">
      <li>
        <Button
          color={`${active === 'user' ? 'primary' : 'default'}`}
          className={`${active === 'user' ? 'active' : ''}`}
          onClick={() => onClick('user')}
        >
          User
        </Button>
      </li>
      <li>
        <Button
          color={`${active === 'org' ? 'primary' : 'default'}`}
          className={`${active === 'org' ? 'active' : ''}`}
          onClick={() => onClick('org')}
        >
          Organization
        </Button>
      </li>
    </ul>
  </div>
);

export default ProfileMenu;

ProfileMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
};
