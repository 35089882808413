/* eslint no-unused-vars: 1  */
// xx: 1 making a warning, used in this file because vars are placeholders for future.
import { BPVIEWS } from "../Constants";

export const isValidBudgetAndDate = () => true;

export const isValidTactics = inputs => {
  const errors = {};
  let count = 0;
  if (
    !inputs[BPVIEWS.KNOWN_CONST.TACTIC_NAME] ||
    inputs[BPVIEWS.KNOWN_CONST.TACTIC_NAME].trim().lenght === 0
  ) {
    errors[BPVIEWS.KNOWN_CONST.TACTIC_NAME] = true;
    count += 1;
  }

  return {
    result: count === 0,
    errors
  };
};

export const isRequired = (settings, fieldName) => {
  const rules = settings.get("rules");
  const rulesMeta = settings.get("rules_meta");
  let valiColor = "";
  let valiRequired = false;
  if (Array.isArray(rules[fieldName])) {
    const rulesArray = rules[fieldName];
    if (rulesArray[0] === "required") {
      valiRequired = true;
      valiColor = rulesMeta[fieldName];
    }
  }
  return { valiRequired, valiColor };
};
