import React, { Component } from "react";
import PropTypes from "prop-types";
// import isAfter from "date-fns/is_after";
import moment from "moment";
// import isWithinRange from "date-fns/is_within_range";
import Notifications from "../../shared/notifications/Notifications";
import { parseQueryString } from "../../utils/URLUtils";
import {
  PROJECT_FILTER_LIST,
  PROJECT_TEXT_SEARCH_MIN_CHAR,
} from "../../shared/Constants";
import "./MainProjectsView.scss";
import MainProjectsLine from "./mainProjectsLine/MainProjectsLine";
import MainProjectsLineHeader from "./mainProjectsLine/MainProjectsLineHeader";
import ProjectsService from "../ProjectsService";
import { listenForUpdateFilters } from "../../utils/ProjectSearchEvents";

class MainProjectsView extends Component {
  constructor(props) {
    super(props);
    this.todaysDate = new Date();
    this.yesterdayDate = new Date(this.todaysDate);
    this.yesterdayDate.setDate(this.todaysDate.getDate() - 1);
    this.urlFilter = "";
    this.projectService = new ProjectsService();
    this.state = {
      projects: [],
      projectsFiltered: [],
      searchInput: "",
      apiLoading: true,
      selectedClientId: 0,
    };
  }

  componentDidMount() {
    this.projectService.getListProjects((data) => {
      this.setState({ projects: data, apiLoading: false }, () => {
        this.urlFilter = this.props.location.search;
        this.setFilter();
      });
    });
    this.projectService.getUsers(() => {}, () => {});
    this.listenForFilters = listenForUpdateFilters((data) => {
      console.log("[MainProjectsView.jsx] data", data.detail);
      if (data.detail.clientId !== null) {
        this.onClientSelect(data.detail.clientId);
      }

      if (data.detail.search !== null) {
        this.onSearch(data.detail.search);
      }
    });
  }
  componentWillUnmount() {
    this.listenForFilters();
  }

  componentWillReceiveProps(nextProps) {
    if (this.urlFilter !== nextProps.location.search) {
      this.urlFilter = nextProps.location.search;
      this.setFilter();
    }
  }

  setFilter() {
    const queryString = parseQueryString(this.urlFilter);
    const filterVal = queryString[PROJECT_FILTER_LIST.FILTER];
    this.filterProjects(filterVal);
  }

  filterProjects(timeFilter) {
    let retVal;
    let projects = [];
    if (this.state.selectedClientId !== 0) {
      projects = this.state.projects.filter((p) => {
        return Number(p.customerId) === Number(this.state.selectedClientId);
      });
    } else {
      projects = this.state.projects;
    }
    switch (timeFilter) {
      case PROJECT_FILTER_LIST.INPLANNING:
        retVal = projects.filter((item) => {
          return (
            item.status.toLowerCase() ===
            PROJECT_FILTER_LIST.INPLANNING.toLowerCase()
          );
        });
        break;
      case PROJECT_FILTER_LIST.LIVE:
        retVal = projects.filter((item) => {
          return (
            item.status.toLowerCase() === PROJECT_FILTER_LIST.LIVE.toLowerCase()
          );
        });
        break;
      case PROJECT_FILTER_LIST.COMPLETED:
        retVal = projects.filter((item) => {
          return (
            item.status.toLowerCase() ===
            PROJECT_FILTER_LIST.COMPLETED.toLowerCase()
          );
        });
        break;
      case PROJECT_FILTER_LIST.PAUSED:
        retVal = projects.filter((item) => {
          return (
            item.status.toLowerCase() ===
            PROJECT_FILTER_LIST.PAUSED.toLowerCase()
          );
        });
        break;
      default:
        retVal = projects;
        break;
    }
    const input = this.state.searchInput.toLowerCase();

    const retValSorted = retVal.sort((a, b) => {
      const aa = a.name.toUpperCase();
      const bb = b.name.toUpperCase();
      return aa.localeCompare(bb);
    });

    let retValFinal = retValSorted;
    if (input.length >= PROJECT_TEXT_SEARCH_MIN_CHAR) {
      retValFinal = retVal.filter((item) => {
        const isInName = this.inName(input, item.name);
        const isInDocket = this.inDocket(input, item.docketId);
        // const isInTags = this.inTags(input, item.tags);
        return isInName || isInDocket;
      });
    }
    this.setState({ projectsFiltered: retValFinal });
  }

  inName = (search, name) => name.toLowerCase().indexOf(search) > -1;

  inDocket = (search, docket) => docket.toLowerCase().indexOf(search) > -1;

  inTags = (search, tags) => {
    const retVal = tags.filter(
      (tag) => tag.tag.tag.toLowerCase().indexOf(search) > -1
    );
    return retVal.length > 0;
  };

  onSearch = (input) => {
    this.setState({ searchInput: input }, () => {
      if (input.length >= PROJECT_TEXT_SEARCH_MIN_CHAR) {
        this.setFilter();
      }
    });
  };

  onClientSelect = (clientId) => {
    console.log("[MainProjectsView.jsx] client", clientId);
    this.setState({ selectedClientId: clientId }, () => {
      this.setFilter();
    });
  };

  render() {
    return (
      <div className="MainProjectsView">
        <div className="u-padding-both-sides-25 u-padding-top-10">
          <Notifications />
        </div>
        {/* Hiding to test out  */}
        {/* <div className="grid-x grid-margin-x search-line">
          <div className="cell small-0 medium-6" />
          <div className="cell small-12 medium-6">
            <div className="project-settings">
              <div className="ProjectSearch">
                <div className="input-group">
                  <Search onSearch={(val) => this.onSearch(val)} />
                </div>
              </div>
              <ClientSelector
                onSelect={(clientId) => this.onClientSelect(clientId)}
              />
            </div>
          </div>
        </div> */}
        <div className="u-padding-both-sides-25">
          <MainProjectsLineHeader />
          {this.state.projectsFiltered.length === 0 &&
          !this.state.apiLoading ? (
            <p className="not-found">
              {/* https://youtu.be/bhNQ9cv5K7Q */}
              Looking in all the names and codes, but cannot find anything.
            </p>
          ) : (
            <span />
          )}
          {this.state.projectsFiltered.map((obj) => (
            <MainProjectsLine
              companyName={obj.docketId}
              key={obj.id}
              status={obj.status}
              projectId={Number(obj.id)}
              lastUpdateUserId={obj.lastUpdateUserId}
              projectName={obj.name}
              docketCode={obj.docketId}
              // timePercent={obj.stats.timePercent}
              timePercent={0}
              // budgetPercent={obj.stats.budgetPercent}
              budgetPercent={0}
              // cpmPercent={obj.stats.cpmPercentChange.toString().substring(0, 5)}
              cpmPercent={"0"}
              // cpm={obj.stats.cpm}
              cpm={0}
              // spend={obj.stats.spend}
              spend={0}
              // spendPercent={obj.stats.spendPercentChange
              //   .toString()
              //   .substring(0, 5)}
              spendPercent={"0"}
              // vf={obj.stats.vf.toString().substring(0, 5)}
              // projectEnd={
              //   obj.milestones
              //     ? getEndDateMilestone(obj.milestones)
              //     : { empty: true }
              // }
              // projectStarts={
              //   obj.milestones
              //     ? getStartDateMilestone(obj.milestones)
              //     : { empty: true }
              // }
              projectEnd={moment(obj.endDate)}
              projectStarts={moment(obj.startDate)}
              reportingUrl={obj.reportingUrl}
            />
          ))}
        </div>
        {/* <div className="project-new">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <Link to="/projects/new">
                <strong>+ New Project</strong>
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default MainProjectsView;

MainProjectsView.propTypes = {
  location: PropTypes.object.isRequired,
};
