import React from 'react';
import PropTypes from 'prop-types';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import FileTypes from '../fileTypes/FileTypes';
import ProfilePicture from '../../../shared/profilepictures/ProfilePicture';
import { getUserNames } from '../../ProjectsService';

const ActivityLogFileLine = ({
  user, fileName, fileType, date,
}) => (
  <div className="ActivityLogFileLine line">
    <div className="grid-x">
      <div className="cell small-1 u-vertical-middle">
        <ProfilePicture user={user} />
      </div>
      <div className="cell small-11 u-vertical-middle align-left">
        <span className="user">
          <FileTypes type={fileType} />
          <span className="u-gray">
            {getUserNames(user)}
            {' '}
uploaded a file:
            {' '}
          </span>
        </span>
        {' '}
        <span className="note">{fileName}</span>
        {' '}
        <span className="date" title={date}>
          <em className="u-gray">
(
            {distanceInWordsToNow(date, { includeSeconds: true })}
)
          </em>
        </span>
      </div>
    </div>
  </div>
);

export default ActivityLogFileLine;

ActivityLogFileLine.propTypes = {
  user: PropTypes.object.isRequired,
  fileName: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
