import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "antd/lib/table";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import SSF from "ssf";

import { getView, getFirstAvailableView } from "../BPViewService";
import { bpServiceStatic } from "../BPService";
import { megaMerge2, mergeViews } from "./MPUtils";
import { getListForColFactory2 } from "../BPServiceUtils";
import BPJoinedControls from "./BPJoinedControls";
import BPJoinedNav from "./BPJoinedNav";
import { listenForModalsCloseChanges } from "../../../actions/commonDataActionsUtils";
import { exportsJoinedTable } from "../../bp/bpTable/bpExports";
import { notify } from "../../../shared/notifications/Notify";
import "./BPJoined.scss";

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`
            }
          ],
          initialValue: record[dataIndex]
        })(
          <>
            <Input
              ref={node => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
            />
          </>
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  format = val => {
    if (this.props._meta.format) {
      return SSF.format(this.props._meta.format, val);
    }
    return val;
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      _meta,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          <>{children}</>
        )}
      </td>
    );
  }
}

class BPJoined extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      dataSource: [],
      width: 1000,
      showFullText: false,
      viewName: "",
      viewNames: [],
      viewId: 0,
      dx: 1
    };
    this.filteredDataSource = [];
    this.getListForCol = getListForColFactory2(this.props.projectId);
    this.cellWidth = 250;
  }
  componentDidMount() {
    const viewToShow = getFirstAvailableView(this.props.projectId);
    console.log("[BPJoined.jsx] viewToShow", viewToShow);
    if (viewToShow) {
      this.setState({ viewName: viewToShow.viewName }, () => {
        this.constructView(viewToShow.viewName);
        this.loadLines();
        this.listenForModalChange = listenForModalsCloseChanges(
          "customviews",
          val => {
            if (!val) {
              this.setState({ isLoading: true });
              this.loadLines();
            }
          }
        );
      });
      this.setState({ viewName: viewToShow.viewName });
    } else {
      notify(
        "Oh no!",
        "There are no views! Please create view to see anything!",
        "error"
      );
    }
  }
  componentWillUnmount() {
    this.listenForModalChange();
  }

  constructView = viewName => {
    this.view1 = getView("dynamic1." + viewName, this.props.projectId);
    this.view2 = getView("dynamic2." + viewName, this.props.projectId);
    this.view3 = getView("dynamic3." + viewName, this.props.projectId);

    const columns = mergeViews(
      this.state.dx,
      this.getListForCol,
      [this.view1, this.view2, this.view3],
      {
        cellWidth: this.cellWidth,
        hide: this.hide,
        pin: this.pin,
        showFullText: false
      }
    );

    this.setState({ columns, width: this.cellWidth * columns.length });
  };

  loadLines = () => {
    this.constructView(this.state.viewName);
    this.setState({ isLoading: true });
    bpServiceStatic.loadDynFlat(this.props.projectId, this.state.dx, data => {
      const mergedData = megaMerge2(data);
      this.filteredDataSource = mergedData;

      this.setState({ dataSource: mergedData, isLoading: false });
    });
  };
  showMenu = (col, x) => {
    console.log("[BPJoined.jsx] col", col.target, x);
  };
  hide = name => {
    const columns = this.state.columns.filter(c => c.dataIndex !== name);
    this.setState({ columns, width: this.cellWidth * columns.length });
  };
  pin = name => {
    const columns = this.state.columns.map(c => {
      if (c.dataIndex !== name) return c;
      return {
        ...c,
        fixed: "left"
      };
    });
    this.setState({ columns });
  };

  onClickViewName = (activeSection, viewId) => {
    // console.log("[BPJoined.jsx] activeSection", activeSection, viewId);
    const name = activeSection.split(".")[0];
    this.constructView(name);
    this.setState({ viewName: name, viewId: Number(viewId.key) });
  };

  showFullText = show => {
    const columns = mergeViews(
      this.state.dx,
      this.getListForCol,
      [this.view1, this.view2, this.view3],
      {
        cellWidth: this.cellWidth,
        hide: this.hide,
        pin: this.pin,
        showFullText: show
      }
    );

    this.setState({ columns, showFullText: show });
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ dataSource: newData });
  };
  onDXChange(dval) {
    this.setState({ dx: dval }, () => {
      this.loadLines();
    });
  }

  onChangeTable(pagination, filters, sorter, extra) {
    if (extra && extra.currentDataSource) {
      this.filteredDataSource = [...extra.currentDataSource];
    }
  }

  exportData() {
    exportsJoinedTable(
      this.props.projectId,
      this.filteredDataSource,
      [this.view1, this.view2, this.view3],
      this.state.dx,
      this.state.viewId,
      this.state.columns
    );
    // console.log("[BPJoined.jsx] this.state.columns", this.state.columns);
  }

  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          _meta: col._meta
        })
      };
    });
    console.log("[BPJoined.jsx] dataSource", this.state.dataSource);

    return (
      <div className="BPJoined">
        <BPJoinedNav
          handleClick={this.onClickViewName}
          projectId={this.props.projectId}
        />
        <BPJoinedControls
          fullTextChange={val => this.showFullText(val)}
          onDXChange={v => this.onDXChange(v)}
          dxSelected={Number(this.state.dx)}
          onExport={() => this.exportData()}
        />
        <Table
          rowKey={record => record._uid}
          dataSource={this.state.dataSource}
          onChange={(pagination, filters, sorter, extra) =>
            this.onChangeTable(pagination, filters, sorter, extra)
          }
          columns={columns}
          components={components}
          loading={this.state.isLoading}
          scroll={{ x: this.state.width + "px", y: "800px" }}
          size="small"
          bordered
          pagination={{
            defaultPageSize: 20,
            pageSizeOptions: ["20", "40", "80", "100", "200", "500"],
            showSizeChanger: true
          }}
          onHeaderRow={column => {
            return {
              onClick: (el, x) => {
                // this.showMenu(el, x);
              },
              onContextMenu: event => {
                // this.showMenu(event, 'cm');
              }
            };
          }}
        />
      </div>
    );
  }
}

export default BPJoined;

BPJoined.propTypes = {
  projectId: PropTypes.number.isRequired
};
