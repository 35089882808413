import React, { Component } from "react";
import "antd/dist/antd.css";
import { Modal, Mentions, Button, Form } from "antd";

import UsersService from "../../../../services/UsersService.js";

const { Option } = Mentions;

const CollectionCreateForm = Form.create({ name: "form_in_export_modal" })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const { visible, onCancel, showName, onExport, users, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          okText={"Export"}
          title={"Export Google Sheets"}
          visible={visible}
          onCancel={onCancel}
          onOk={onExport}
        >
          <p>Type '-' to search for user, or type out new Email address.</p>
          <Form>
            <Form.Item>
              {getFieldDecorator("emails", { initialValue: "" })(
                <Mentions
                  style={{ width: "100%" }}
                  placeholder="Add one or more email to export too."
                  prefix="-"
                  onSelect={this.handleSelect}
                  onChange={this.handleChange}
                >
                  {users.map(user => (
                    <Option key={user.id} value={user.username}>
                      <span>{showName(user)}</span>
                    </Option>
                  ))}
                </Mentions>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class ProjectExportSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      apiLoaded: false,
      apiFail: false,
      visible: false
    };
    this.usersService = new UsersService();
  }

  componentDidMount() {
    this.usersService.getUsers(
      data => {
        const users = data.filter(u => !u.blocked);
        this.setState({
          apiLoaded: true,
          users
        });
      },
      () => {
        this.setState({
          apiFail: true
        });
      }
    );
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  showName = user => {
    return user.contact.firstname + " " + user.contact.lastname;
  };

  handleCancel = () => {
    const { form } = this.formRef.props;
    form.resetFields();
    this.setState({ visible: false });
  };

  handleExport = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onExport(values);
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div>
        <Button onClick={this.showModal}>Export Google Sheets</Button>
        <CollectionCreateForm
          visible={this.state.visible}
          wrappedComponentRef={this.saveFormRef}
          onCancel={this.handleCancel}
          users={this.state.users}
          showName={this.showName}
          onSearch={this.handleSearch}
          onExport={this.handleExport}
        />
      </div>
    );
  }
}

export default ProjectExportSheet;
