import React from "react";
import "../../../projects/mainNavView/MainNavView.scss";
import "./ReportsPageNavView.scss";
import CompanyAndUser from "../../../shared/companyAndUser/CompanyAndUser";

const ReportsPageNavView = () => (
  <div className="MainNavView ReportsPageNavView">
    <div className="grid-container full">
      <div className="grid-x projects-header">
        <div className="cell small-10">
          <h6>
            Fastloop Data Systems Inc. <span className="u-gray">Reports</span>
          </h6>
        </div>
        <div className="cell small-2">
          <CompanyAndUser />
        </div>
      </div>
    </div>
  </div>
);

export default ReportsPageNavView;
