/* eslint no-use-before-define:0, no-case-declarations:0 */
import * as types from "../types/types";

const initState = {
  platforms: [],
  adtypesByPlatform: {
    // data would be like - 'platofmrid': [list]
  },
  dataLoadedFlags: {
    bpView: 0,
    trafficView: 0,
  },
  dataLoadedObjects: {
    // for storing data like view or other
    views: {}, // key - projectId, body - rest of the object
    lists: {}, // key - projectId, body - rest of the object
    metas: {}, // key - projectId, body - rest of the object
  },
  metasEditor: {
    dialogOpen: false,
    payloadBody: {},
  },
  metasReorder: {
    dialogOpen: false,
    payloadBody: {},
  },
  metasViews: {},
  modals: {},
  callForReload: {},
  users: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.CM_USERS_LOAD:
      return {
        ...state,
        users: action.payload,
      };
    case types.CM_DATA_PLATFORMS:
      return {
        ...state,
        platforms: action.payload,
        adtypesByPlatform: adtypesByPlatformTrans(action.payload),
      };
    case types.CM_LOADED_FLAGS:
      return {
        ...state,
        dataLoadedFlags: {
          [action.payload.flag]: action.payload.flagState,
        },
      };
    case types.CM_LOADED_DATA_VIEWS:
      const { views } = state.dataLoadedObjects;
      views[action.payload.projectId] = action.payload.views;
      return {
        dataLoadedObjects: {
          views,
          ...state.dataLoadedObjects,
        },
        ...state,
      };
    case types.CM_RELOADED_DATA_VIEWS:
      const viewsBackup =
        state.dataLoadedObjects.views[action.payload.projectId];
      const retVal = updateViews(viewsBackup, action.payload.views);
      const newViews = {};
      newViews[action.payload.projectId] = retVal;
      return {
        dataLoadedObjects: {
          views: newViews,
          ...state.dataLoadedObjects,
        },
        ...state,
      };
    case types.CM_LOADED_DATA_LISTS:
      const { lists } = state.dataLoadedObjects;
      lists[action.payload.projectId] = action.payload.lists;
      return {
        dataLoadedObjects: {
          lists,
          ...state.dataLoadedObjects,
        },
        ...state,
      };
    case types.CM_RELOADED_DATA_LISTS:
      const listsBackup =
        state.dataLoadedObjects.lists[action.payload.projectId];
      const retValLists = updateLists(listsBackup, action.payload.lists);
      const newLists = {};
      newLists[action.payload.projectId] = retValLists;
      return {
        dataLoadedObjects: {
          lists: newLists,
          ...state.dataLoadedObjects,
        },
        ...state,
      };
    case types.CM_LOADED_DATA_METAS:
      const { metas } = state.dataLoadedObjects;
      metas[action.payload.projectId] = action.payload.metas;
      return {
        dataLoadedObjects: {
          metas,
          ...state.dataLoadedObjects,
        },
        ...state,
      };
    case types.CM_METAS_EDITOR_OPEN:
      const { metasEditor } = state;
      metasEditor.dialogOpen = action.payload.open;
      metasEditor.payloadBody = action.payload.payloadBody;
      return { ...state, metasEditor };
    case types.MODALS_OPEN:
      const { modals } = state;
      modals[action.payload.name] = {
        open: action.payload.open,
        rest: action.payload.rest,
      };
      return { ...state, modals };
    case types.CALL_FOR_RELOAD:
      const { callForReload } = state;
      callForReload[action.payload.name] = {
        rest: action.payload.rest,
      };
      return { ...state, callForReload };
    default:
      return state;
  }
};

const adtypesByPlatformTrans = (payload) => {
  const adtypesByPlatform = {};
  Object.values(payload).forEach((element) => {
    adtypesByPlatform[element.id] = element.adTypes;
  });
  return adtypesByPlatform;
};
// array, array of one
const updateViews = (all, incoming) => {
  const incomingId = incoming[0].id;
  const allWithoutOne = all.filter((itm) => itm.id !== incomingId);
  const merged = { ...allWithoutOne, ...incoming };
  return merged;
};

const updateLists = (all, incoming) => {
  const merged = { ...incoming };
  return merged;
};
