import React from "react";
import { components } from "react-select2";
import Typography from "@material-ui/core/Typography";
import AntSelect from "antd/lib/select";
import Icon from "antd/lib/icon";

export const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 12,
};

export const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

export const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length - 1}</span>
  </div>
);

export const SingleValue = ({ children, ...props }) => {
  const group = props.options.find((op) => op.eventId === props.data.eventId);
  return (
    <components.SingleValue {...props}>
      {group.label} - {children}
    </components.SingleValue>
  );
};

export const Option = ({ children, ...props }) => {
  const complete = props.data._meta.hook.complete;
  return (
    <components.Option {...props}>
      <div className="flex-container align-justify">
        {children}{" "}
        {complete && (
          <Icon
            title="Hook is fully mapped"
            className="complete-indicator"
            type="check-circle"
          />
        )}
      </div>
    </components.Option>
  );
};

export const NameInput = ({
  value,
  disabled,
  handelInput,
  onSave,
  onCancel,
}) => {
  return (
    <div className="NameInput input-group">
      <input
        className="input-group-field"
        type="text"
        value={value}
        name="eventname"
        onChange={(e) => handelInput(e)}
      />
      <div className="input-group-button">
        <button
          type="submit"
          disabled={disabled}
          onClick={() => onCancel("eventname")}
          className="button"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={disabled}
          onClick={onSave}
          className="save button"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export const makeEventName = (event) => {
  return `Event:: ${event.name}`;
};
export const makeEventOptions = (event) => {
  const optionsList = [];
  event.hooks.forEach((hook) => {
    optionsList.push({
      value: `${hook.id}:${event.id}`,
      label: hook.name,
      eventId: event.id,
      isNew: false,
      _meta: {
        hook,
        event,
      },
    });
  });
  optionsList.push({
    value: `0:${event.id}`,
    label: "<New>",
    eventId: event.id,
    isNew: true,
    _meta: {
      hook: { isNew: true },
      event,
    },
  });
  return optionsList;
};
export const makeOptions = (events) => {
  const making = [];
  events.forEach((event) => {
    const name = makeEventName(event);
    const eventId = event.id;
    const options = makeEventOptions(event);
    making.push({
      label: name,
      eventId,
      options,
    });
  });
  return making;
};

export const tabsStyles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
    minHeight: 30,
    marginLeft: 4,
  },
  tabsIndicator: {
    backgroundColor: "#3e4c5c",
  },
  tabRoot: {
    minWidth: 150,
    minHeight: 30,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 2,
    textTransform: "none",
    "&:hover": {
      color: "#3e4c5c",
      opacity: 1,
    },
    // TODO: causing console error, check out docs
    // '&$selected': {
    //   color: '#3e4c5c',
    //   fontWeight: theme.typography.fontWeightMedium,
    // },
    "&:focus": {
      color: "#3e4c5c",
    },
  },
  typography: {
    padding: theme.spacing.unit * 2,
  },
});

export function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 3 }}>
      {props.children}
    </Typography>
  );
}

export const getMappedLabel = (name, viewCols) => {
  let label = name;
  const sp = label.split(".");
  const DX = sp[sp.length - 2];
  const theName = sp[sp.length - 1];

  if (DX && DX.indexOf("Dynamic") === 0) {
    const DSelected = viewCols.blocking[DX];
    const metaObj = DSelected[theName];
    if (metaObj && metaObj.label) {
      const labelText = metaObj.label;
      sp.pop();
      let ret = sp.join(".");
      ret = ret + ".[" + labelText + "]";
      label = ret;
    }
  }
  return label;
};

export const labelParams = (parameters, viewCols) => {
  const optionsFastloop = [];
  if (!parameters) return optionsFastloop;

  const sortedParams = parameters.sort((a, b) => a.localeCompare(b));
  sortedParams.forEach((param) => {
    let label = getMappedLabel(param, viewCols);
    optionsFastloop.push({ value: param, label: label });
  });
  return optionsFastloop;
};

export const mergeHookMap = (hook, viewCols) => {
  if (hook.callbacks && hook.callbacks.length > 0) {
    hook.callbacks.forEach((callback) => {
      const callbacksId = callback.id;
      if (callback.map.length > 0) {
        const variables = new Set(callback.variables);
        callback.map.forEach((m) => {
          variables.delete(m.fromName);
          const label = getMappedLabel(m.toName, viewCols);
          m.toLabel = label ? label : m.toName;
        });
        variables.forEach((v) => {
          callback.map.push(
            mappingObject({ callback: callbacksId, fromName: v })
          );
        });
      } else {
        const variables = new Set(callback.variables);
        callback.map = [];
        variables.forEach((v) => {
          callback.map.push(
            mappingObject({ callback: callbacksId, fromName: v })
          );
        });
      }
    });
  }
  const hookId = hook.id;
  const variables = new Set(hook.variables);
  if (!hook.isNew) {
    if (hook.map.length > 0) {
      hook.map.forEach((m) => {
        variables.delete(m.toName);
        const label = getMappedLabel(m.fromName, viewCols);
        m.fromLabel = label ? label : m.fromName;
      });
      variables.forEach((v) => {
        hook.map.push(mappingObject({ hook: hookId, toName: v }));
      });
    } else {
      variables.forEach((v) => {
        hook.map.push(mappingObject({ hook: hookId, toName: v }));
      });
    }
  }
  return hook;
};

const mappingObject = (extra) => {
  const main = {
    _notUsed: true,
    _new: true,
    id: 0,
    toName: "",
    toLabel: "",
    fromName: "",
  };

  return { ...main, ...extra };
};

export const byKey = (arr) => {
  const retVal = {};
  arr.forEach((v) => {
    retVal[v.name] = v;
  });
  return retVal;
};

export const removeNewFlags = (cb) => {
  if (!cb) return cb;
  return cb.map((c) => {
    if (c._new) {
      delete c.id;
      return c;
    }
    return c;
  });
};

export const makeHookTabs = (cb) => {
  if (!cb) return [];
  return cb.map((c, idx) => {
    return {
      id: idx,
      data: { ...c },
    };
  });
};

export const makeOptionsDropDown = (vals) => {
  if (!vals) return null;
  return vals.map((par) => {
    return (
      <AntSelect.Option
        key={par.value}
        value={par.value}
        title={`${par.value}`}
      >
        {par.label}
      </AntSelect.Option>
    );
  });
};
