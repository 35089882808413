import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ActivityLog.scss';

import Icon from '../../../shared/icons/account-grey.png';

import ActivityLogNoteLine from './ActivityLogNoteLine';
import ActivityLogFileLine from './ActivityLogFileLine';

class ActivityLog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="ActivityLog">
        <div className={`ld-over ${this.props.showLoading ? 'running' : 'not-running'}`}>
          <span className="ld ld-ring ld-spin" />
          {this.props.activities.map(obj => (
            <div key={obj.uniqKey}>
              {obj.type === 'note' ? (
                <ActivityLogNoteLine
                  key={obj.uniqKey}
                  profileImg={Icon}
                  user={obj.user}
                  username={obj.username}
                  noteText={obj.noteText}
                  date={obj.date}
                />
              ) : (
                <ActivityLogFileLine
                  key={obj.uniqKey}
                  profileImg={Icon}
                  user={obj.user}
                  username={obj.username}
                  fileName={obj.fileName}
                  fileType={obj.fileType}
                  date={obj.date}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ActivityLog;

ActivityLog.propTypes = {
  showLoading: PropTypes.bool.isRequired,
  activities: PropTypes.array.isRequired,
};
