import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import AuthService from '../../auth/AuthService';

/**
 *
 * @param {Component} component to render
 * @param {any} rest rest of params to <Router>
 */

const DashboardRoute = ({ component: Component, ...rest }) => {
  const auth = new AuthService();
  return (
    <Route
      {...rest}
      render={matchProps =>
        auth.isLogged() ? (
          <DashboardLayout {...rest}>
            <Component {...matchProps} />
          </DashboardLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/logout',
            }}
          />
        )
      }
    />
  );
};

export default DashboardRoute;

DashboardRoute.defaultProps = {
  rest: {},
};

DashboardRoute.propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.any,
};
