import axios from "axios";
import { ERRORS } from "../shared/Constants";
import { errorLog } from "./ErrorTracker";
/**
 * kicks to login page, but firstly redirects to logout to clean all session.
 */
const kickToLoginPage = () => window.location.replace("/logout");

const kickToMaintenancePage = () => window.location.replace("/maintenance");

/**
 * Adds axios interceptor to check if token is epxired, if so kicks to login
 */
const addSessionExpirationInterceptor = () => {
  axios.interceptors.response.use(
    response => response,
    error => {
      // timeout error
      if (error.code === "ECONNABORTED") {
        errorLog(error);
        // kickToLoginPage(); - should we? maybe in the future.
      }
      // seems like server is down
      if (!error.response) {
        kickToMaintenancePage();
      } else {
        if (
          error.response.status === 400 &&
          error.response.data.error === ERRORS.AUTH_TOKEN_EXPIRED
        ) {
          kickToLoginPage();
        }
        if (error.response.status === 401) {
          kickToLoginPage();
        }
        if (error.response.status === 503) {
          kickToMaintenancePage();
        }
      }
      // lets leave it for now it,
      // looks ok without this but may cause problems later, so just as are reference
      return Promise.reject(error);
    }
  );
};

export { kickToLoginPage, addSessionExpirationInterceptor };
