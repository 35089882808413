import { throttle, addStyleString } from './Utils';
import eventManager from '../shared/EventManager';

export function scrollWatcher() {
  eventManager.emit(eventManager.TYPES_MAP.WINDOW.SCROLL);
}

/** This function used to trigger re-calculations when real event is not fired */
export function scrollWatcherTrigger() {
  throttle(scrollWatcher, 250);
}

export const startWindowSizeWatcher = () => {
  window.addEventListener('scroll', throttle(scrollWatcher, 250), false);
};

let maxBPSmartLineWidth = 0;
export const updateFXLongLineWidth = (viewElemCount, viewName) => {
  let len = viewElemCount * 210;
  len += 30; // adding margin
  if (maxBPSmartLineWidth < len) {
    addStyleString(`.fx-long-body { width:  ${len}px } `, viewName);
    maxBPSmartLineWidth = len;
  }
};

let lastSize = 0;
let el;
let inited = false;
export const updateFXBody = () => {
  if (!el) {
    // eslint-disable-next-line prefer-destructuring
    el = document.getElementsByClassName('top-shell')[0];
  }
  console.info('[RES] - el.offsetWidth', el.offsetWidth);
  if (el.offsetWidth === 0) {
    return;
  }
  const lenOfOuter = el.offsetWidth - 160;
  if (lastSize !== lenOfOuter) {
    // addStyleString(`.fx-fixed-body { width:  ${lenOfOuter}px } `, 'fx-fixed-body-st');
    addStyleString(
      `.fx-sticky-line { max-width:  ${lenOfOuter}px; width:  ${lenOfOuter}px } `,
      'fx-sticky-line-st',
    );
  }
  lastSize = lenOfOuter;

  if (!inited) {
    // console.log('resize');
    window.addEventListener('resize', throttle(updateFXBody, 250), false);
    inited = true;
  }
};

export const handleResizeWithThrottle = (fn) => {
  window.addEventListener('resize', throttle(fn, 250), false);
};
