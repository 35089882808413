import React from "react";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import Upload from "antd/lib/upload";

class BPImportsUploadCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      fileName: "",
    };
  }
  beforeUpload = (file) => {
    const reader = new FileReader();
    this.setState({ fileName: file.name });
    reader.onload = () => {
      const res = reader.result;
      this.props.onDone(res);
    };
    reader.readAsText(file);
    return false;
  };
  render() {
    return (
      <div className="BPImportsUploadCSV">
        <span style={{ paddingRight: 5 }}>{this.state.fileName}</span>
        <Upload beforeUpload={this.beforeUpload} showUploadList={false}>
          <Button icon="upload" size="default" loading={this.state.isUploading}>
            Import CSV
          </Button>
        </Upload>
      </div>
    );
  }
}

export default BPImportsUploadCSV;

BPImportsUploadCSV.propTypes = {
  onDone: PropTypes.func.isRequired,
};
