import Storage from "../services/Storage";

class UserSettings {
  constructor() {
    this.settings = {};
    this.project = 0;
    this.storage = new Storage("USER_SETTINGS");
    this.storage
      .getProm("USER_SETTINGS")
      .then((value) => {
        if (value) {
          this.settings = value;
        } else {
          this.settings = this.reset();
        }
      })
      .catch((err) => {
        this.settings = this.reset();
      });
  }
  reset() {
    return {
      version: 1,
      last: +new Date(),
      bpTable1: {
        colors: {},
        widths: {},
        sort: {},
        filter: {},
        hidden: {},
        order: {},
        pagination: {},
        positionXY: {},
      },
      project: {
        0: {
          bpTable: {
            0: {
              // could be bp1, bp2, etc
              colors: {},
              widths: {},
              sort: {},
              filter: {},
              hidden: {},
              order: {},
              pagination: {},
              positionXY: {},
              autoSaveToggleStatusEnabled: true,
            },
          },
        },
      },
    };
  }
  setProject(projectId) {
    this.projectId = projectId;
  }
  /**
   *
   * @param {int} projectId
   * @param {string} entity bpTable, etc
   * @param {int} lineId bp table line id
   */
  setContext(projectId, entity, lineId) {
    this.projectId = projectId;
    this.lineId = lineId;
    this.entity = entity;
    // console.log("[UserSettings.js] setContect", projectId, lineId, entity);
  }
  /**
   * Gets Value from settings, ProjectId and entity has to be set via `setContext`.
   * @param {string} thing colors or width
   */
  getBy(thing) {
    // console.log(
    //   "[UserSettings.js] `${this.entity}/${this.lineId}/${thing}`",
    //   `${this.entity}/${this.lineId}/${thing}`
    // );

    // console.log("getBy", this.settings);

    if (this.settings.project && this.settings.project[this.projectId]) {
      return this.settings.project[this.projectId][
        `${this.entity}/${this.lineId}/${thing}`
      ];
    } else {
      return false;
    }
  }

  setBy(thing, value) {
    // console.log(
    //   "[UserSettings.js] `${this.entity}/${this.lineId}/${thing}`",
    //   `${this.entity}/${this.lineId}/${thing}`
    // );
    if (!this.settings.project[this.projectId]) {
      this.settings.project[this.projectId] = {};
    }
    this.settings.project[this.projectId][
      `${this.entity}/${this.lineId}/${thing}`
    ] = value;

    this.saveToLocal();

    return this;
  }
  resetViewsOrder(projectId, viewId) {
    const all = this.settings.project[projectId];
    for (const key in all) {
      if (key.endsWith("order/" + viewId)) {
        delete all[key];
      }
    }
    this.settings.project[projectId] = all;
    this.saveToLocal();
  }
  saveToLocal() {
    this.settings.last = +new Date();

    this.storage
      .setProm("USER_SETTINGS", this.settings)
      .then(function(value) {
        console.log("[UserSettings.js] saved", 1, value);
      })
      .catch(function(err) {
        console.log("[UserSettings.js] err", 0);
      });
  }

  toString() {
    return JSON.stringify(this.settings);
  }
}

const UserSettingsStatic = new UserSettings();

export { UserSettings, UserSettingsStatic };

window.userSettings = UserSettingsStatic;
