import React from "react";
import Papa from "papaparse";
import Icon from "antd/lib/icon";
import { isFloat, isInt, isListOrSMF, isBool } from "../bpTable/BPTableDataUtils";
import {
  FloatParser,
  IntParser,
  EchoParserTrim,
  BoolParser,
} from "../../../utils/NumberParser";
import { isNAList, safeCompare } from "../BPServiceUtils";
import { bpServiceStatic } from "../BPService";
import { doIgnoreProjectValidation } from "../bpTable/BPTableDataUtils";
import { getProjectByIdSelBound } from "../../../projects/ProjectReduxUtils";

export const PARENT_ID = "Parent Id";
export const PARENT_ID_KEY = "_PARENT_ID_";

export const formatDataFile = (dataRaw, delimiter = "") => {
  const fullPapa = Papa.parse(dataRaw, {
    delimiter,
  });
  const matrixRaw = fullPapa.data;
  const errors = fullPapa.errors;
  const meta = fullPapa.meta;
  /// cutting empty rows from the bottom
  //. cutting empty cols

  // const lastEmpty = matrix.pop();
  // if (lastEmpty.length > 1) {
  //   matrix.push(lastEmpty);
  // }

  const cleaned = cutFromBottom(matrixRaw);
  const matrix = cleaned.cleanMatrix;
  // const infoRows = cleaned.infoRows;
  const infoEmptyColCount = cleaned.infoEmptyColCount;

  return { matrix, errors, meta, infoEmptyColCount };
};

const matchKey = (label, viewArray) => {
  if (safeCompare(label, PARENT_ID)) {
    return PARENT_ID_KEY;
  }
  const found = viewArray.find((n) => safeCompare(n.label, label));
  if (found) {
    return found.name;
  } else {
    return "";
  }
};
const GEN_STR = "[gen]";
const NEW_ID_STR = "[new id]";
// TODO check if have save titles
export const makeTableData = (
  matrix,
  view,
  listsFn,
  idsSet,
  ignoreMalformedIds,
  projectId
) => {
  console.log("[bpMassImportsUtils.js] view view", view);
  const project = getProjectByIdSelBound(projectId);
  const docket = project.docket;

  const top = matrix.shift();
  const keys = [];
  let missedCounter = 0;
  let cols = [];
  try {
    cols = top.map((label) => {
      // mapping here first because not all cols may be found
      // we still want to warn users and show that col
      const key = `idx_${replToSafe(label)}`;
      const mpKey = matchKey(label, view);
      missedCounter = mpKey ? missedCounter : missedCounter + 1;
      keys.push({ key, dataType: mpKey });
      const addition = {};
      if (key.toLowerCase() === "idx_Id".toLowerCase()) {
        addition.fixed = "left";
        addition.width = 160;
      }
      if (mpKey.toLowerCase() === PARENT_ID_KEY.toLowerCase()) {
        addition.fixed = "left";
        addition.width = 160;
      }
      addition.ellipsis = true;
      return {
        title: () => {
          return (
            <span className="ok">
              <Icon
                data-mp-key={mpKey}
                type={mpKey ? "check-circle" : "close-circle"}
                style={{ color: mpKey ? "green" : "red" }}
              />{" "}
              {label}
            </span>
          );
        },
        render: (text, rec) => {
          if (isBool(mpKey)) {
            if (text) {
              return "true";
            } else {
              return "false";
            }
          }
          return text;
        },
        dataIndex: key,
        key: key,
        mpKey: mpKey,
        label: label,
        ...addition,
      };
    });
  } catch (error) {
    return { error: "Cannot map file headers." };
  }

  const dataSource = [];
  const invalidKeys = new Set();
  const invalidLabels = new Set();
  const invalidIds = new Set();
  const newIds = [];
  const wrongDocket = [];
  try {
    matrix.forEach((cell, idx) => {
      const dataCell = {
        mapped: {},
      };
      cell.forEach((rawValue, i) => {
        if (keys[i]) {
          const dt = keys[i].dataType;
          const parserFn = getParser(dt);
          if (isListOrSMF(dt)) {
            // TODO: refactor to make it nice
            if (isNAList(rawValue)) {
              dataCell[keys[i].key] = rawValue;
              dataCell.mapped[`${dt}`] = "";
            } else {
              const listVal = listsFn(dt, rawValue);
              if (listVal) {
                dataCell[keys[i].key] = rawValue;
                dataCell.mapped[`${dt}`] = listVal.value;
              } else {
                /// invalid!!!
                invalidKeys.add(keys[i].key);
                invalidLabels.add(rawValue);
                dataCell[keys[i].key] = (
                  <span style={{ color: "red" }}>{rawValue}</span>
                );
              }
            }
          } else {
            dataCell[keys[i].key] = parserFn(rawValue);
            dataCell.mapped[`${dt}`] = parserFn(rawValue);
            if (dt === "textColumn1") {
              let colorMsg = "New Id will be generated";
              let color = "barkblue";
              // if [gen] or empty string, we making new id.
              if (
                rawValue.trim().toLowerCase() === GEN_STR.toLowerCase() ||
                rawValue.trim().toLowerCase() === NEW_ID_STR.toLowerCase() ||
                rawValue.trim().length === 0
              ) {
                dataCell[keys[i].key] = (
                  <span title={colorMsg} style={{ color }}>
                    [NEW_ID]
                  </span>
                );
                // newIds.push(rawValue.trim());
              } else {
                if (idsSet.has(rawValue.trim())) {
                  color = "green"; // id matched
                  colorMsg =
                    "Id matched with existing one, line values will be replaced";
                } else {
                  const reg = rawValue.trim().match(/^[a-zA-Z0-9-]+$/);
                  if (rawValue.trim().indexOf(docket) !== 0) {
                    wrongDocket.push(rawValue.trim());
                  }
                  if (!ignoreMalformedIds && !reg) {
                    color = "red"; // id will be replaced with new one
                    colorMsg = "Id not matched rules and new will be generated";
                    invalidIds.add(rawValue.trim());
                  } else {
                    color = "blue"; // id will be used from user
                    colorMsg = "Id matched rules and will be used";
                  }
                }
                dataCell[keys[i].key] = (
                  <span title={colorMsg} style={{ color }}>
                    {rawValue}
                  </span>
                );
                newIds.push(rawValue.trim());
              }
            }
          }
        } else {
        }
      });
      const ts = +new Date();
      dataCell["key"] = ts + idx;

      dataSource.push(dataCell);
    });
  } catch (error) {
    console.log("[bpMassImportsUtils.js] error", error);
    return { error: "Cannot parse file contents." };
  }

  const count = (ids) =>
    ids.reduce((a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});

  const duplicates = (dict) => Object.keys(dict).filter((a) => dict[a] > 1);

  const allIds = [...newIds];

  const theDupes = duplicates(count(allIds));

  invalidKeys.forEach((ik) => {
    const idx = cols.findIndex((ci) => ci.key === ik);
    if (cols[idx].mpKey) {
      cols[idx].invalidListEntry = true;
      cols[idx].title = () => {
        return (
          <span className="ok">
            <Icon
              data-mp-key={cols[idx].mpKey}
              type="exclamation-circle"
              style={{ color: "darkorange" }}
            />{" "}
            {cols[idx].label}
          </span>
        );
      };
    }
  });

  const invalidLabelColumns = new Set(
    cols.filter((c) => c.invalidListEntry).map((l) => l.label)
  );

  console.log("[bpMassImportsUtils.js] dataSouce ", dataSource);
  const differentDocketAndIdList = wrongDocket;

  return {
    cols,
    dataSource,
    missedCounter,
    invalidKeys,
    invalidLabels,
    invalidLabelColumns,
    invalidIds,
    theDupes,
    differentDocketAndIdList,
  };
};

const replToSafe = (input) => {
  const retVal = input.replace(/[^a-zA-Z0-9\s]/gi, "_");
  return retVal;
};

const getParser = (typeName) => {
  if (isInt(typeName)) {
    return IntParser;
  }
  if (isFloat(typeName)) {
    return FloatParser;
  }
  if (isBool(typeName)) {
    return BoolParser;
  }
  return EchoParserTrim;
};

// TODO cleanup not used vars

export const massImport = (
  importData,
  existingData,
  dynLevel,
  editedIdsIndex,
  view,
  filters,
  projectId,
  cb
) => {
  const impArr = importData.data.dataSource;
  const dynLevelFromEvent = importData.level;

  if ("dynamic1" === dynLevelFromEvent) {
    bpServiceStatic.loadDynAll(projectId, (lookupData) => {
      // console.log("[bpMassImportsUtils.js] lookupData", lookupData);
      const combined = d1Fitting(impArr, lookupData, projectId);

      bpServiceStatic.postPutImportsD1(
        projectId,
        combined,
        (dataBack) => {
          console.log("[bpMassImportsUtils.js] back", dataBack);
          cb({ dynLevel: dynLevelFromEvent });
        },
        (err) => {
          console.log("[bpMassImportsUtils.js] errr", err);
        }
      );
    });
  } else if ("dynamic2" === dynLevel) {
    //** Assuming tht all ids are unique */
    bpServiceStatic.loadDynAll(projectId, (lookupData) => {
      // console.log("[bpMassImportsUtils.js] lookupData", lookupData);
      const combined = d2Fitting(impArr, lookupData, projectId);

      // console.log("[bpMassImportsUtils.js] combined", combined);

      bpServiceStatic.postPutImportsD2(
        projectId,
        combined,
        (dataBack) => {
          console.log("[bpMassImportsUtils.js] back", dataBack);
          cb({ dynLevel: dynLevelFromEvent });
        },
        (err) => {
          console.log("[bpMassImportsUtils.js] errr", err);
        }
      );
    });
  } else if ("dynamic3" === dynLevel) {
    //** Assuming tht all ids are unique */
    bpServiceStatic.loadDynAll(projectId, (lookupData) => {
      const combined = d3Fitting(lookupData, impArr, projectId);

      bpServiceStatic.postPutImportsD3(
        projectId,
        combined,
        (dataBack) => {
          cb({ dynLevel: dynLevelFromEvent });
        },
        (err) => {
          console.log("[bpMassImportsUtils.js] errr", err);
        }
      );
    });
  }
};

export const d3Fitting = (lookupData, impArr, projectId) => {
  // console.log("[bpMassImportsUtils.js] lookupData", lookupData);
  const childrenArray = [];
  const childrenObj = {};
  const notFoundArray = [];
  const parentIdsByUserId = {};
  const d2Arr = [];
  lookupData.dynamic1.forEach((d2) => {
    const dx = [...d2.dynamic2];
    dx.forEach((dxx) => {
      d2Arr.push(dxx);
    });
  });

  impArr.forEach((impLine) => {
    const foundParent = d2Arr.find((d2) => {
      return d2.textColumn1 === impLine.mapped[PARENT_ID_KEY];
    });
    if (foundParent) {
      childrenObj[foundParent.id] = foundParent.dynamic3;
      parentIdsByUserId[foundParent.textColumn1] = foundParent.id;
    } else {
      notFoundArray.push(impLine.mapped[PARENT_ID_KEY]);
    }
  });
  // Making all to array, cuz this object made uniq vals.

  const combined = mergeData(
    childrenObj,
    childrenArray,
    impArr,
    parentIdsByUserId,
    notFoundArray,
    projectId
  );
  return combined;
};

function d2Fitting(impArr, lookupData, projectId) {
  const childrenArray = [];
  const childrenObj = {};
  const notFoundArray = [];
  const parentIdsByUserId = {};
  impArr.forEach((impLine) => {
    const foundParent = lookupData.dynamic1.find((d1) => {
      return d1.textColumn1 === impLine.mapped[PARENT_ID_KEY];
    });
    if (foundParent) {
      childrenObj[foundParent.id] = foundParent.dynamic2;
      parentIdsByUserId[foundParent.textColumn1] = foundParent.id;
    } else {
      notFoundArray.push(impLine.mapped[PARENT_ID_KEY]);
    }
  });
  // Making all to array, cuz this object made uniq vals.
  Object.keys(childrenObj).forEach((lineKey) => {
    childrenObj[lineKey].forEach((single) => {
      childrenArray.push({ ...single });
    });
  });
  // we need objects because put function requires to fire by parent id.
  // const combined = {};
  // TODO: Add warning for notFoundArray array.
  const combined = mergeData(
    childrenObj,
    childrenArray,
    impArr,
    parentIdsByUserId,
    notFoundArray,
    projectId
  );
  return combined;
}

function d1Fitting(impArr, lookupData, projectId) {
  const parentId = projectId;
  const combined = { [parentId]: { newLines: [], editedLines: [] } };
  const childrenArray = lookupData.dynamic1;

  const ignoreBadId = doIgnoreProjectValidation(projectId);

  impArr.forEach((impLine) => {
    const mapped = impLine.mapped;
    // console.log("[bpMassImportsUtils.js] mappe", mapped);
    const userId = mapped.textColumn1;
    const foundExistingLineIndex = lookupData.dynamic1.findIndex(
      (ed) => ed.textColumn1 === userId
    );

    let textColumn1 = "";
    // if(mapped.textColumn1.trim() === "" || mapped.textColumn1)
    if (ignoreBadId) {
      textColumn1 = mapped.textColumn1;
    } else {
      try {
        const reg = mapped.textColumn1.match(/^[a-zA-Z0-9-]+$/);
        if (reg) {
          // id passed check and it is valid.
          textColumn1 = mapped.textColumn1;
        } else {
          textColumn1 = "";
        }
        // may fail if is id missing, so we just let gen new ids
      } catch (error) {
        textColumn1 = "";
      }
    }

    if (foundExistingLineIndex >= 0) {
      combined[parentId].editedLines.push({
        ...childrenArray[foundExistingLineIndex],
        ...mapped,
      });
    } else {
      combined[parentId].newLines.push({
        ...mapped,
        textColumn1: textColumn1, // allowing id if its ok
        _isNew: true,
        projectId,
        dynamic1Id: parentId,
      });
    }
  });
  return combined;
}

function mergeData(
  childrenObj,
  childrenArray,
  impArr,
  parentIdsByUserId,
  notFoundArray,
  projectId
) {
  Object.keys(childrenObj).forEach((lineKey) => {
    childrenObj[lineKey].forEach((single) => {
      childrenArray.push({ ...single });
    });
  });

  const ignoreBadId = doIgnoreProjectValidation(projectId);

  // we need objects because put function requires to fire by parent id.
  const combined = {};
  // TODO: Add warning for notFoundArray array.
  impArr.forEach((impLine) => {
    const mapped = impLine.mapped;
    // debugger;
    const userId = mapped.textColumn1;
    const foundExistingLineIndex = childrenArray.findIndex(
      (ed) => ed.textColumn1 === userId
    );
    const parentId = parentIdsByUserId[mapped[PARENT_ID_KEY]];
    // debugger;
    if (parentId) {
      if (foundExistingLineIndex >= 0) {
        if (!combined[parentId]) {
          combined[parentId] = { newLines: [], editedLines: [] };
        }
        combined[parentId].editedLines.push({
          ...childrenArray[foundExistingLineIndex],
          ...mapped,
        });
      } else {
        if (!notFoundArray.includes(mapped[PARENT_ID_KEY])) {
          if (!combined[parentId]) {
            combined[parentId] = { newLines: [], editedLines: [] };
          }
          let textColumn1 = "";
          if (ignoreBadId) {
            textColumn1 = mapped.textColumn1;
          } else {
            try {
              const reg = mapped.textColumn1.match(/^[a-zA-Z0-9-]+$/);
              if (reg) {
                // id passed check and it is valid.
                textColumn1 = mapped.textColumn1;
              } else {
                textColumn1 = "";
              }
            } catch (error) {
              textColumn1 = "";
            }

          }

          combined[parentId].newLines.push({
            ...mapped,
            textColumn1: textColumn1, // allowing id if its ok
            _isNew: true,
            projectId,
            dynamic1Id: parentId,
          });
        }
      }
    }
  });
  return combined;
}

/**
 * @returns {object(cleanMatrix, infoRows, infoEmptyColCount)} -- matrix without empty rows from the bottom, stops when finds first non zero row. {infoRows} - gives len of each row, infoEmptyColCount - count of empty cols
 * @param {array of arrays} matrixIn import matrix
 */
export const cutFromBottom = (matrixIn) => {
  const tmpMatrix = [...matrixIn];

  /// cutting from the bottom
  const lenHolder = [];
  for (let ln in tmpMatrix) {
    const lenLast = tmpMatrix[ln].reduce(
      (acc, cur) => acc + cur.trim().length,
      0
    );
    lenHolder.push(lenLast);
  }

  while (true) {
    const lenPart = lenHolder.pop();
    if (lenPart === 0) {
      tmpMatrix.pop();
    } else {
      // not empty, putting it back
      lenHolder.push(lenPart);
      break;
    }
  }

  /// cutting empty cols

  const header = tmpMatrix[0];
  const emptyIdx = [];
  header.forEach((h, i) => {
    const len = h.trim().length;
    if (len === 0) {
      emptyIdx.push(i);
    }
    return len;
  });

  const reversed = emptyIdx.reverse();

  if (reversed.length > 0) {
    reversed.forEach((realIdx) => {
      tmpMatrix.forEach((matrixElem) => {
        // console.log("[matrix.test.js] realIdx", realIdx);
        matrixElem.splice(realIdx, 1);
      });
    });
  }

  return {
    cleanMatrix: tmpMatrix,
    infoRows: lenHolder,
    infoEmptyColCount: emptyIdx.length,
  };
};
