import React, { Component } from "react";
import PropTypes from "prop-types";
import { PROJECT_SETTINGS_SAVED_MSG_TO_FADE } from "../../../../shared/Constants";

import "./SingleSetting.scss";

import SavedImg from "../../../../shared/icons/green-round-tick.svg";

/**
 * Displays single line with value in read-only text input,
 * has Edit button which makes text editable and on save callbacks provided function.
 * During API load blocks line with loader icon and on save shows green tick.
 * Cancel button returns value to original state.
 */
class SingleSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueCopy: "",
      value: "",
      valueSavedClassName: "hide",
      isEditMode: false,
      showLoading: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const cp = Object.assign({}, this.state);

    if (nextProps.value !== this.state.valueCopy) {
      cp.value = nextProps.value;
      cp.valueCopy = nextProps.value;
    }
    if (nextProps.showLoading !== this.state.showLoading) {
      cp.showLoading = nextProps.showLoading;
    }
    if (nextProps.valueSaved) {
      cp.valueSavedClassName = "";
      cp.valueCopy = nextProps.value;
      cp.value = nextProps.value;
      setTimeout(() => {
        this.setState({ ...this.state, valueSavedClassName: "fade-out" });
      }, PROJECT_SETTINGS_SAVED_MSG_TO_FADE);
    }
    this.setState({ ...this.state, ...cp });
  }

  valueChangeHandler(event) {
    this.setState({ ...this.state, value: event.target.value });
  }

  editHandler() {
    this.setState({
      ...this.state,
      isEditMode: true,
      valueSavedClassName: "hide"
    });
  }

  cancelHandler() {
    this.setState({
      ...this.state,
      isEditMode: false,
      value: this.state.valueCopy,
      valueSavedClassName: "hide"
    });
  }

  saveHandler() {
    this.setState({ ...this.state, isEditMode: false }, () => {
      this.props.onSave(this.state.value);
    });
  }

  render() {
    return (
      <div className="SingleSetting">
        <div
          className={`ld-over ${
            this.state.showLoading ? "running" : "not-running"
          }`}
        >
          <span className="ld ld-ring ld-spin" />
          <div className="grid-x">
            <div className="cell small-10 medium-7">
              {this.props.isFloat && (
                <input
                  type="number"
                  onChange={e => this.valueChangeHandler(e)}
                  value={this.state.value}
                  readOnly={!this.state.isEditMode}
                  pattern="[0-9]+([,\.][0-9]+)?"
                />
              )}
              {!this.props.isFloat && (
                <input
                  type="text"
                  onChange={e => this.valueChangeHandler(e)}
                  value={this.state.value}
                  readOnly={!this.state.isEditMode}
                />
              )}
            </div>
            <div className="cell auto">
              <div
                className={`mode-view ${this.state.isEditMode ? "hide" : ""}`}
              >
                <button
                  className="action-btn"
                  onClick={e => this.editHandler(e)}
                >
                  Edit
                </button>
                <div className={`saved ${this.state.valueSavedClassName} `}>
                  <img src={SavedImg} alt="saved" /> Saved!
                </div>
              </div>
              <div
                className={`mode-edit ${this.state.isEditMode ? "" : "hide"}`}
              >
                <button
                  className="action-btn"
                  onClick={e => this.saveHandler(e)}
                >
                  Save
                </button>
                <span>/</span>
                <button
                  className="action-btn"
                  onClick={e => this.cancelHandler(e)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SingleSetting;

SingleSetting.defaultProps = {
  isFloat: false
};

/**
 * @param {string} value to display/edit
 * @param {func} onSave callbck with value
 * @param {bool} showLoading flags loading overlay
 * @param {bool} valueSave used to show tickbox
 */
SingleSetting.propTypes = {
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  showLoading: PropTypes.bool.isRequired,
  valueSaved: PropTypes.bool.isRequired,
  isFloat: PropTypes.bool
};
