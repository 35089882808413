import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import { SnackbarProvider } from "notistack";

import reduxStore from "./reduxStore";
import LoginPage from "./auth/LoginPage";
import LogoutPage from "./auth/LogoutPage";
import RecoverPassword from "./auth/RecoverPassword";
import ResetPassword from "./auth/ResetPassword";
import NewProject from "./projects/NewProject";
import NotFoundPage from "./shared/NotFoundPage";
import RegisterPage from "./pages/registrations/RegisterPage";
import MaintenancePage from "./pages/maintenanceage/MaintenancePage";
import DashboardRoute from "./shared/layoutsroutes/DashboardRoute";
import MainProjectsView from "./projects/mainProjectsView/MainProjectsView";
import ProjectView from "./projects/projectView/ProjectView";
import ProjectSettingsMainView from "./projects/projectView/projectSettings/ProjectSettingsMainView";
import ProjectActivityMainView from "./projects/projectView/projectActivityMainView/ProjectActivityMainView";
import ProjectReportsMainView from "./projects/projectView/projectReportsMainView/ProjectReportsMainView";
import ProjectBlockingMainView from "./projects/projectView/projectBlockingMainView/ProjectBlockingMainView";
import ProjectTrafficingSheetMainView from "./projects/projectView/projectTrafficingSheetMainView/ProjectTrafficingSheetMainView";
import PBPage from "./pages/bp/BPPage";
import PBJoinedShell from "./pages/bp/bpCosting/BPJoinedShell";
import TagManager from "react-gtm-module";

import ComingSoon from "./shared/comingSoon/ComingSoon";
import Desktop from "./shared/desktop/Desktop";
import InfoPage from "./info/infoPages/InfoPages";
// import PeoplePage from "./people/peoplePage/PeoplePage";
import UsersPage from "./users/peoplePage/PeoplePage";
import ClientsPage from "./clients/clientsPage/ClientsPage";
import ClientEditPage from "./clients/clientEditPage/ClientEditPage";
import ReportsPage from "./reports/reportsPage/ReportsPage";
import ProfilePage from "./pages/profile/ProfilePage";
import ProjectStatsMainView from "./projects/projectView/projectStatsMainView/ProjectStatsMainView";
import { initErrorTracker } from "./utils/ErrorTracker";
import { addSessionExpirationInterceptor } from "./utils/AuthUtils";
import { history } from "./utils/HistoryUtils";
import { startWindowSizeWatcher } from "./utils/WindowSizesService";
import { PerfAxiosTimer } from "./utils/Perf";
import { EvenBusListener } from "./actions/eventBusActionsUtil";
import { devModeStatic } from "./utils/DevMode";
import { initializeHelpComponentGoogleSheetData } from "./shared/helpComponent/HelpTool";

import FasthooksLogsOutside from "./modals/fasthooks/FasthooksLogsOutside";

// this used for checking re-renders of app, used only for development
// if (process.env.NODE_ENV === 'development') {
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-TXJ6PMD",
  };
  TagManager.initialize(tagManagerArgs);
}

devModeStatic.checkUrl();

PerfAxiosTimer();
loadProgressBar();

initErrorTracker();
addSessionExpirationInterceptor();
startWindowSizeWatcher();
EvenBusListener();
initializeHelpComponentGoogleSheetData();

function App() {
  return (
    <Provider store={reduxStore}>
      <SnackbarProvider maxSnack={3}>
        <Router history={history}>
          <div className="xxx">
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route path="/register" component={RegisterPage} />
              <Route path="/logout" component={LogoutPage} />
              <Route path="/recoverpassword" component={RecoverPassword} />
              <Route path="/user/resetpassword" component={ResetPassword} />
              <Route path="/info" component={InfoPage} />
              <Route path="/maintenance" component={MaintenancePage} />
              <DashboardRoute
                path="/ampinfo"
                exact
                component={ComingSoon}
                topBar="soon"
              />
              <DashboardRoute
                exact
                path="/projects/all"
                component={MainProjectsView}
                topBar="projects"
              />
              <DashboardRoute
                exact
                path="/projects/new"
                component={NewProject}
                topBar="projects"
              />
              <DashboardRoute
                exact
                path="/projects/:id"
                component={ProjectView}
                topBar="project"
              />
              <DashboardRoute
                exact
                path="/projects/:id/settings"
                component={ProjectSettingsMainView}
                topBar="project"
              />
              <DashboardRoute
                path="/projects/:id/activity"
                exact
                component={ProjectActivityMainView}
                topBar="project"
              />
              <DashboardRoute
                path="/projects/:id/reports"
                exact
                component={ProjectReportsMainView}
                topBar="project"
              />
              <DashboardRoute
                path="/projects/:id/blocking"
                exact
                component={ProjectBlockingMainView}
                topBar="project"
              />
              <DashboardRoute
                path="/projects/:id/traffic"
                exact
                component={ProjectTrafficingSheetMainView}
                topBar="project"
              />
              <DashboardRoute
                path="/projects/:id/stats"
                exact
                component={ProjectStatsMainView}
                topBar="project"
              />
              <DashboardRoute
                path="/profile"
                exact
                component={ProfilePage}
                topBar="profilePage"
              />
              <DashboardRoute
                path="/projects/:id/bp"
                exact
                component={PBPage}
                topBar="bp"
              />
              <DashboardRoute
                path="/projects/:id/flat"
                exact
                component={PBJoinedShell}
                topBar="bp"
              />
              <DashboardRoute
                path="/reports"
                exact
                component={ReportsPage}
                topBar="reportsPage"
              />
              <DashboardRoute
                path="/users"
                exact
                component={UsersPage}
                topBar="users"
              />
              <DashboardRoute
                path="/clients"
                exact
                component={ClientsPage}
                topBar="clients"
              />
              <DashboardRoute
                path="/client/:id/edit"
                exact
                component={ClientEditPage}
                topBar="client"
              />
              <DashboardRoute
                path="/blocking"
                exact
                component={ComingSoon}
                topBar="soon"
              />
              <DashboardRoute
                path="/traffic"
                exact
                component={ComingSoon}
                topBar="soon"
              />
              <DashboardRoute
                path="/desktop"
                exact
                component={Desktop}
                topBar="soon"
              />
              <Route exact component={NotFoundPage} />
            </Switch>
            <FasthooksLogsOutside />
          </div>
        </Router>
      </SnackbarProvider>
    </Provider>
  );
}

// export default App;
export default hot(module)(App);
