import React from 'react';
import './MainNavView.scss';
import NoiseLogo from '../../shared/icons/logo.png';
import { getQueryStringParam } from '../../utils/URLUtils';

const SoonNavView = () => {
  let name = '';
  if (window.location.pathname.indexOf('blocking') > -1) {
    name = 'Blocking';
  }
  if (window.location.pathname.indexOf('traffic') > -1) {
    name = 'Trafficking';
  }
  if (window.location.pathname.indexOf('report') > -1) {
    name = 'Reports';
  }
  if (!name) {
    const section = getQueryStringParam('section');
    switch (section) {
      case 'tnc':
        name = 'Terms & Conditions';
        break;
      case 'pp':
        name = 'Privacy Policy';
        break;
      case 'su':
        name = 'Support';
        break;
      case 'cu':
        name = 'Contact Us';
        break;
      default:
        name = 'nobody home';
        break;
    }
  }
  return (
    <div className="MainNavView SoonNavView">
      <div className="grid-container full">
        <div className="grid-x projects-header">
          <div className="cell small-10">
            <h6>
              Fastloop Data Systems Inc.
              {' '}
              <span className="u-gray">{name}</span>
            </h6>
          </div>
          <div className="cell small-2">
            <img className="logo" src={NoiseLogo} alt="noise logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoonNavView;
