import React from "react";
import { connect } from "react-redux";
import { Drawer } from "antd";
import PropTypes from "prop-types";

import { doOpenModal } from "../../../selectors/commonSelectors";
import { commonModalsCloseBound } from "../../../actions/commonDataActionsUtils";

import { BP_DRAWER } from "../../../shared/Constants";

import Unhide from "./BPUnhide";
import ImportCol from "./BPImportCol";
import ImportMass from "./BPImportMass";

const VIEW_NAME = "BPDrawer";

const BPDrawerInner = ({ openVars }) => {
  let CMP = <div />;
  let width = 600;
  if (openVars.open) {
    if (openVars.rest.type === BP_DRAWER.UNHIDE) {
      CMP = (
        <Unhide
          closeCall={() => commonModalsCloseBound(VIEW_NAME)}
          {...openVars.rest}
        />
      );
    }
    if (openVars.rest.type === BP_DRAWER.IMPORT_COL1) {
      CMP = (
        <ImportCol
          closeCall={() => commonModalsCloseBound(VIEW_NAME)}
          {...openVars.rest}
        />
      );
    }
    if (openVars.rest.type === BP_DRAWER.IMPORT_COL2) {
      CMP = (
        <ImportCol
          closeCall={() => commonModalsCloseBound(VIEW_NAME)}
          {...openVars.rest}
        />
      );
    }
    if (openVars.rest.type === BP_DRAWER.IMPORT_COL3) {
      CMP = (
        <ImportCol
          closeCall={() => commonModalsCloseBound(VIEW_NAME)}
          {...openVars.rest}
        />
      );
    }
    if (
      openVars.rest.type === BP_DRAWER.IMPORT_COL1_MASS ||
      openVars.rest.type === BP_DRAWER.IMPORT_COL2_MASS ||
      openVars.rest.type === BP_DRAWER.IMPORT_COL3_MASS
    ) {
      width = "90%";
      CMP = (
        <ImportMass
          closeCall={() => commonModalsCloseBound(VIEW_NAME)}
          {...openVars.rest}
        />
      );
    }
  }

  return (
    <div className="BPDrawerInner">
      {openVars && openVars.open && (
        <Drawer
          width={width}
          placement="right"
          closable={false}
          onClose={() => commonModalsCloseBound(VIEW_NAME)}
          visible={openVars.open}
        >
          {CMP}
        </Drawer>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  openVars: doOpenModal(state, VIEW_NAME),
});

const BPDrawer = connect(mapStateToProps)(BPDrawerInner);
export default BPDrawer;

BPDrawerInner.propTypes = {
  openVars: PropTypes.object.isRequired,
};
