import React, { Component } from "react";
import BPTable from "../bpTable/BPTable";

import { getActiveProjectId } from "../bpUtils";
import MetasEditorDialog from "../../../modals/metas/metasEditor/MetasEditorDialog";
import CustomViewsDialog from "../../../modals/customViews/CustomViewsDialog";
import BigQueryQueryDialog from "../../../modals/biqqueryDialog/BiqqueryDialog";
// import BPCustomViewsNav from "../bpNav/bpSubNav/BPCustomViewsNav";
import { BP_LEVELS } from "../../../shared/Constants";
import { bpServiceStatic } from "../../bp/BPService";
import { getViewSel } from "../../../actions/MediaPlanActionsUtils";
import {
  listenForMetasCloseChanges,
  listenForMetasReorderCloseChanges,
  listenForModalsCloseChanges,
} from "../../../actions/commonDataActionsUtils";
import BPJoinedNav from "../bpCosting/BPJoinedNav";

import "./BPCosting.scss";

class BPShell extends Component {
  constructor(props) {
    super(props);

    const viewName = getViewSel();

    this.state = {
      viewId: 0,
      viewName: viewName,
      metasUpdated: 0,
      clipboard: {},
    };
    this.projectId = Number(getActiveProjectId());
    this.bodyCss = document.getElementById("body");
    this.didMetasUpdate = this.didMetasUpdate.bind(this);
    this.tableRef = React.createRef();
    window.didMetasUpdate = this.didMetasUpdate;
  }
  onSubSectionChange(viewName, id, doTransform = false) {
    this.tableRef.current.reload();
  }

  componentDidMount() {
    this.listenForMetasChange = listenForMetasCloseChanges(this.didMetasUpdate);
    this.listenForModalChange = listenForModalsCloseChanges(
      "customviews",
      this.didMetasUpdate
    );
    this.listenForMetasReorderChange = listenForMetasReorderCloseChanges(
      this.didMetasUpdate
    );
    this.bodyCss.classList.add("no-footer");
    bpServiceStatic.loadDynAll(this.projectId, (data) => {
      console.info("[BPShell.jsx] MEDIAPLAN loaded", 1);
    });
  }
  componentWillUnmount() {
    this.listenForMetasChange();
    this.listenForMetasReorderChange();
    this.listenForModalChange();
    this.bodyCss.classList.remove("no-footer");
  }

  didMetasUpdate(val) {
    console.log("[BPShell.jsx] update calll", 1);
    if (!val) {
      this.tableRef.current.reload();
    }
  }

  updateClipboard = (data) => {
    this.setState({ clipboard: data });
  };

  getClipboard = () => {
    const data = this.state.clipboard;
    return data;
  };

  render() {
    return (
      <div className="BPShell">
        <div className="u-padding-both-sides-25">
          <BPJoinedNav
            projectId={this.projectId}
            handleClick={(name, id) => this.onSubSectionChange(name, id, true)}
          />
        </div>
        <div className="grid-x BPTableDyn-wrap">
          <div className="cell small-12">
            <div className="u-padding-both-sides-25">
              <BPTable
                projectId={this.projectId}
                idKey={this.projectId}
                ref={this.tableRef}
                level={BP_LEVELS.DYN1}
                parentIdKey={this.projectId}
                updateClipboard={(data) => this.updateClipboard(data)}
                getClipboard={this.getClipboard}
              />
            </div>
          </div>
        </div>
        <MetasEditorDialog />
        <CustomViewsDialog />
        <BigQueryQueryDialog />
      </div>
    );
  }
}

export default BPShell;
