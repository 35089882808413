/**
 * Capitalizes first letter of the string.
 * @param {string} string to Capitalize
 */
const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Title Case Converter.
 * @param {string} string to Title Case
 */
const titleCaseString = string =>
  string.toLowerCase().replace(/(^| )(\w)/g, s => s.toUpperCase());

/**
 * Generates UUID
 * @return {string} UUID
 */
/* eslint-disable */
const UUID = () => {
  let uuid = "";
  let ii;
  for (ii = 0; ii < 32; ii += 1) {
    switch (ii) {
      case 8:
      case 20:
        uuid += "-";
        uuid += ((Math.random() * 16) | 0).toString(16);
        break;
      case 12:
        uuid += "-";
        uuid += "4";
        break;
      case 16:
        uuid += "-";
        uuid += ((Math.random() * 4) | 8).toString(16);
        break;
      default:
        uuid += ((Math.random() * 16) | 0).toString(16);
    }
  }
  return uuid;
};
/* eslint-enable */

const bytesToSize = bytes => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  /* eslint-disable */
  // for mixed operators
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  /* eslint-enable */
};

export { capitalizeFirstLetter, titleCaseString, UUID, bytesToSize };

export const getHumanFriendlyLabel = field => {
  const names = {
    text: "Text",
    float: "Decimal Num",
    int: "Whole Num",
    date: "Date",
    bool: "Checkbox",
    list: "List",
    file: "File"
  };
  let name = "";
  Object.keys(names).forEach(nameKey => {
    if (field.indexOf(nameKey) === 0) {
      name = names[nameKey];
    }
  });
  if (name) {
    const number = field.split("Column")[1];
    return `${name} ${number}`;
  } else {
    return field;
  }
};
