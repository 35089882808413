/**
 * Here should be declared all setting constants used in the project for easy configuration.
 */

/**
 * Lines to show on project overview activity log.
 */
const PROJECT_OVERVIEW_ACTIVITY_LINES_TO_SHOW = 5;

/**
 * Individual saved message will fade out in x secs.
 */
const PROJECT_SETTINGS_SAVED_MSG_TO_FADE = 3 * 1000;

/**
 * Default format - Friday January 19, 2038
 */
const DEFAULT_DATE_FORMAT = "ddd MMM DD, YYYY";

/**
 * Default format, but shorter 'MMM D, YYYY'
 */
const DEFAULT_DATE_FORMAT_SHORTER = "MMM D, YYYY";

const DEFAULT_DATE_FORMAT_WITH_TIME = "MMM D, YYYY h:mm:ss A";

const DEFAULT_DATE_FORMAT_FOR_DB = "YYYY-MM-DD";
/**
 * This is default role for adding new people to project.
 */
const DEFAULT_ROLE_FOR_NEW_ROLE = { value: 1, label: "Admin" };

const ROLE_ADMIM_ID = 1;
/**
 * Milestones start, end ids.
 */
const MILESTONES = {
  START_ID: 1,
  END_ID: 2,
};

const PROJECT_FILTER_LIST = {
  FILTER: "filter",
  ALL: "all",
  INPLANNING: "inplanning",
  RUNNING: "running",
  COMPLETED: "completed",
  ARCHIVED: "archived",
  LIVE: "live",
  PAUSED: "paused",
};

const PROJECT_TEXT_SEARCH_MIN_CHAR = 0;

const FILE_TYPES = {
  REPORT: "report",
  BLOCKING: "blocking",
  TRAFFICING: "trafficing",
};

/**
 * Length of note, enforceds from FE.
 * NOTE_LENGTH.MAX - cant' enter more.
 * NOTE_LENGTH.WARN - show warning.
 */
const NOTE_LENGTH = {
  MAX: 1000,
  WARN: 950,
};

const FILE_SIZE_MAX = 64 * 1024 * 1024; // 64 Mb
// in backend it's 64, but 1mb for rouding errors and so.

/** Some classes are special, like footer here, adding as constants will rise awarnes */
const SPECIAL_CLASSES = {
  FOOTER: "footer-special",
};

const ERRORS = {
  STORE_KEY: "error.cl",
  TYPES: {
    CAMP: "camp.js",
    UI: "ui",
    OTHER: "other",
  },
  AUTH_TOKEN_EXPIRED: "invalid_request",
};

const BPVIEWS = {
  DB_PREFIX: "view",
  META_PREFIX: "viewMeta",
  META_USER_PREFIX: "viewUserMetaNames",
  MAPPED_BY_NAME_SUFFIX: "_byName",
  VIEWS: {
    TACTICS: "Tactics", // Name comes comes from DB.
    TACTICS_INLINE: "TacticsInline", // Name comes comes from DB.
    ADSET: "dynamic1.default", // Name comes comes from DB.
    DEFAULT2: "dynamic2.default", // Name comes comes from DB.
    DEFAULT3: "dynamic3.default", // Name comes comes from DB.
    TRAFFIC: "Traffic",
    // TODO rm dynamic1 here
    ALLCOLUMNSNAME: "AllColumns",
    DEFAULTNAME: "default",
    ALLCOLUMNS: "dynamic1.AllColumns",
    ALL1COLUMNS: "dynamic1.AllColumns",
    ALL2COLUMNS: "dynamic2.AllColumns",
    ALL3COLUMNS: "dynamic3.AllColumns",
  },
  DO_NOT_SHOW_VIEWS: ["AllColumns"],
  KNOWN_CONST: {
    LINE_ID: "textColumn1",
    TACTIC_NAME: "textColumn2",
    TACTIC_BUDGET: "floatColumn10",
    GROSS_MEDIA_BUDGET: "floatColumn2",
    PROJECT_ID: "projectId",
    FORMULA_ERROR: "boolColumn1",
  },
  WEIGHT_MULTIPLICATOR: 10, // used for adding new lines.
};

export const GOOGLE_SHEETS_HELP_STORAGE_KEY = "GOOGLE_DATA";

export const INPUT_REGEX_FILTERS = {
  /* eslint-disable */
  REAL: new RegExp(/^0$|^-?[1-9]\d*?$/, "gi"), // NOT WORKING :(
  FLOAT: new RegExp("[^0-9.]", "g"),
  FLOAT_2_DECI: new RegExp(/^[+-]?\d+(\.\d{0,2})?$/),
  /* eslint-ensable */
};

// used with https://github.com/SheetJS/ssf, https://customformats.com/
export const NUMBER_SFF_FORMATS = {
  BIG_NUM: "$#,##0.00", // -$12,345.53
  CURRENCY_PRECISION: 2,
};

export const BP_NAV = {
  COSTING: "costing",
  CREATIVE: "creative",
  SCHEDULING: "scheduling",
  TRAFFIC: "traffic",
};

export const BP_TABLE = {
  ICON_CLICK_EDIT: "icon-click-edit",
  ICON_CLICK_HIDE: "icon-click-hide",
  ICON_CLICK_UNHIDE: "icon-click-unhide",
  ICON_CLICK_IMPORT_COL: "icon-import-col",
  ICON_CLICK: "icon-click",
  ICON_CLICK_COLOR: "icon-click-color",
  FILTER_EMPTY: "[empty]",
};

export const BP_LEVELS = {
  DYN1: "dynamic1",
  DYN2: "dynamic2",
  DYN3: "dynamic3",
};

export const BP_LEVELS_FOR_HUMANS = {
  dynamic1: "Plan",
  dynamic2: "Placement",
  dynamic3: "Creative",
};

export const BP_LEVELS_FOR_HUMANS_BY_INT = {
  "1": BP_LEVELS_FOR_HUMANS.dynamic1,
  "2": BP_LEVELS_FOR_HUMANS.dynamic2,
  "3": BP_LEVELS_FOR_HUMANS.dynamic3,
};

export const BP_DRAWER = {
  UNHIDE: "unhide",
  IMPORT_COL: "import_col",
  IMPORT_COL1: "import_col1",
  IMPORT_COL2: "import_col2",
  IMPORT_COL3: "import_col3",
  IMPORT_COL1_MASS: "import_col1_mass",
  IMPORT_COL2_MASS: "import_col2_mass",
  IMPORT_COL3_MASS: "import_col3_mass",
};

export const MAINTENANCE_MODE = {
  ENABLED: "enabled",
  DISABLED: "disabled",
  LOADING: "loading",
};

/* eslint-disable */
export {
  PROJECT_OVERVIEW_ACTIVITY_LINES_TO_SHOW,
  PROJECT_SETTINGS_SAVED_MSG_TO_FADE,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_SHORTER,
  DEFAULT_DATE_FORMAT_WITH_TIME,
  DEFAULT_ROLE_FOR_NEW_ROLE,
  DEFAULT_DATE_FORMAT_FOR_DB,
  PROJECT_FILTER_LIST,
  PROJECT_TEXT_SEARCH_MIN_CHAR,
  MILESTONES,
  NOTE_LENGTH,
  SPECIAL_CLASSES,
  ROLE_ADMIM_ID,
  FILE_TYPES,
  ERRORS,
  FILE_SIZE_MAX,
  BPVIEWS,
};
/* eslint-enable */

/// name, base, text
export const LABEL_COLORS = [
  // "COLOR0",
  "COLOR1",
  "COLOR2",
  "COLOR3",
  "COLOR4",
  "COLOR5",
  "COLOR6",
  "COLOR7",
  "COLOR8",
  "COLOR9",
  "COLOR10",
  "COLOR11",
  "COLOR12",
  "COLOR13",
  "COLOR14",
  "COLOR15",
];

export const COLORRESET = "COLORRESET";
