import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getInfos, getWarns, getErrors } from '../../selectors/notificationsSelectors';
import { dismissNotification, dismissNotificationByType } from '../../actions/notificationActions';
import WarnLine from './NotificationsLine';
import './Notifications.scss';

const SHOW_DISMISS_COUNT = 1;

class NotificationsInner extends Component {
  onClose(id) {
    this.props.dispatch(dismissNotification(id));
  }

  lineGenerator(type) {
    return (
      <div>
        {this.props[type].map((single, idx) => {
          const isLast = idx + 1 === this.props[type].length;
          return (
            <div key={single.id} data-key={single.id}>
              <WarnLine
                type={type.toUpperCase()}
                onClose={() => this.onClose(single.id)}
                msg={`${single.text}`}
              />
              {isLast ? null : <div className="subline" />}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const infoClasses = classnames({
      info: true,
      outer: true,
      hide: this.props.infos.length === 0,
    });
    const warnClasses = classnames({
      warn: true,
      outer: true,
      hide: this.props.warns.length === 0,
    });
    const errorClasses = classnames({
      error: true,
      outer: true,
      hide: this.props.errors.length === 0,
    });
    return (
      <div className="Notifications">
        <div className={errorClasses}>
          <div className="grid-x">
            <div className="cell small-12">{this.lineGenerator('errors')}</div>
            {this.props.errors.length > SHOW_DISMISS_COUNT ? (
              <div className="cell small-12">
                <div className="dismiss-all">
                  <button
                    className=""
                    onClick={() => this.props.dispatch(dismissNotificationByType('error'))}
                  >
                    [Dismiss All]
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={warnClasses}>
          <div className="grid-x">
            <div className="cell small-12">{this.lineGenerator('warns')}</div>
            {this.props.warns.length > SHOW_DISMISS_COUNT ? (
              <div className="cell small-12">
                <div className="dismiss-all">
                  <button onClick={() => this.props.dispatch(dismissNotificationByType('warn'))}>
                    [Dismiss All]
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={infoClasses}>
          <div className="grid-x">
            <div className="cell small-12">{this.lineGenerator('infos')}</div>
            {this.props.infos.length > SHOW_DISMISS_COUNT ? (
              <div className="cell small-12">
                <div className="dismiss-all">
                  <button onClick={() => this.props.dispatch(dismissNotificationByType('info'))}>
                    [Dismiss All]
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  infos: getInfos(state),
  warns: getWarns(state),
  errors: getErrors(state),
});

const Notifications = connect(mapStateToProps)(NotificationsInner);

export default Notifications;

NotificationsInner.propTypes = {
  infos: PropTypes.array.isRequired,
  warns: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};
