import { globalErrorCallback } from "../utils/ErrorTracker";
import { camp } from "../camp";
import moment from "moment";

//TODO: change to entity (not name), those will stay unchanged.
export const EVENT_IDS = {
  LOGIN: 1,
  PROJECT: 2,
  DYNAMIC1: 4,
  DYNAMIC2: 5,
  DYNAMIC3: 6,
  NOTE: 7,
};

class FLHService {
  constructor() {
    this.camp = camp;
    this.EVENT_IDS = EVENT_IDS;
  }

  getHookReadinessInfo(
    projectId,
    eventId,
    entityIds,
    callbackOk,
    callbackFail
  ) {
    this.camp
      .getEventEntityInfo({ eventId, entityIds })
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  getAllEventsAndHooks(projectId, callbackOk, callbackFail) {
    this.camp
      .getEvents()
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  getHooksByProject(projectId, callbackOk, callbackFail) {
    const params = { id: projectId };
    this.camp
      .getEventHooks(params)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  getEventsAndHooks(projectId, callbackOk, callbackFail) {
    const paramsHooks = { id: projectId };
    const hooks = this.camp.getEventHooks(paramsHooks);
    const events = this.camp.getEvents();
    Promise.all([hooks, events])
      .then((resolvedValues) => {
        const hooksVals = resolvedValues[0];
        const eventsVals = resolvedValues[1];
        const merged = [];
        eventsVals.forEach((event) => {
          const filteredHooks = hooksVals.filter(
            (ev) => ev.event.id === event.id
          );
          event.hooks = filteredHooks;
          merged.push(event);
        });
        callbackOk(merged);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  execEvent(eventId, ids, callbackOk, callbackFail) {
    const params = {
      id: eventId,
      body: ids,
    };
    this.camp
      .eventTrigger(params)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  execEventByHookIds(eventId, hookIds, entityIds, callbackOk, callbackFail) {
    const params = {
      id: eventId,
      hookIds: hookIds,
      body: entityIds,
    };
    this.camp
      .eventHookTrigger(params)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  getHooksMapping(hookId, callbackOk, callbackFail) {
    const params = { id: hookId };
    this.camp
      .getHookMap(params)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  postHook(inParams, callbackOk, callbackFail) {
    const params = { body: inParams };
    this.camp
      .addHook(params)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  putHook(inParams, callbackOk, callbackFail) {
    const params = { id: inParams.id, body: inParams };
    this.camp
      .updateHook(params)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  deleteHook(hookId, callbackOk, callbackFail) {
    const params = { id: hookId };
    this.camp
      .deleteHook(params)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  loadDefaultHooks(clientId, callbackOk, callbackFail) {
    const params = { id: clientId };
    this.camp
      .getHookDefaultGroups(params)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  addDefaultHooks(projectId, body, callbackOk, callbackFail) {
    const params = { id: projectId, body };
    this.camp
      .postHookDefaults(params)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  hookInitBlueprint = {
    projectId: 0,
    eventId: 0,
    parentId: 0,
    grouping: "",
    name: "",
    method: "",
    uri: "",
    header: "",
    body: "",
    conditions: [],
    map: [],
    callbacks: [],
    synchronous: true,
  };

  callbackInitBlueprint = {
    name: "",
    method: "",
    uri: "",
    header: "",
    body: "",
    map: [],
    id: 0,
  };
  getNewHookBlueprint = () => {
    const id = +new Date();
    const retVal = this.callbackInitBlueprint;
    retVal.id = id;
    retVal.name = "New.." + moment(id).format("MMDDHH");
    retVal._new = true;

    return retVal;
  };
}

export default FLHService;
export const FLHServiceStatic = new FLHService();
