import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  DraggableModal,
  DraggableModalProvider
} from "ant-design-draggable-modal";

import "ant-design-draggable-modal/dist/index.css";

import {
  doOpenMetasEditor,
  metasEditorVars
} from "../../../selectors/commonSelectors";
import { commonMetasEditorCloseBound } from "../../../actions/commonDataActionsUtils";
import MetasEditor from "./MetasEditor";

import "./MetasEditor.scss";

const MetasEditorDialogInner = ({ open, vars }) => {
  return (
    <div className="MetasEditorDialog">
      <DraggableModalProvider>
        <DraggableModal
          visible={open}
          onCancel={() => commonMetasEditorCloseBound()}
          footer={null}
          title="Metas Editor/Viewer"
          width={1060}
        >
          {vars.projectId && (
            <MetasEditor
              projectId={vars.projectId}
              viewName={vars.viewName}
              fieldName={vars.fieldName}
              dynamicLevelInt={vars.dynamicLevelInt}
              viewId={vars.viewId}
            />
          )}
        </DraggableModal>
      </DraggableModalProvider>
    </div>
  );
};

const mapStateToProps = state => ({
  open: doOpenMetasEditor(state),
  vars: metasEditorVars(state)
});

const MetasEditorDialog = connect(mapStateToProps)(MetasEditorDialogInner);
export default MetasEditorDialog;

MetasEditorDialogInner.propTypes = {
  open: PropTypes.bool.isRequired,
  vars: PropTypes.object.isRequired
};
