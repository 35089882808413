import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import reduxStore from '../reduxStore';

class UpdateAppTracker extends Component {
  constructor() {
    super();
    this.unsubscribe = null;
    this.showCounter = 0;
  }

  componentDidMount() {
    // only for production
    if (process.env.NODE_ENV === 'production') {
      this.checkPing(this.fire);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  fire = () => {
    if (this.showCounter === 0) {
      this.props.enqueueSnackbar('New APP version ready, please reload.', {
        variant: 'info',
        action: (
          <Button color="primary" onClick={this.btnAction} size="small">
            Reload
          </Button>
        ),
        autoHideDuration: 3600000,
      });
    }
    this.showCounter += 1;
  };

  btnAction = () => {
    this.showCounter -= 1;
    window.location.reload(true);
  };

  checkPing = (changedFn) => {
    const selectBus = state => state.versionping.ui;
    const currentValueHTML = document.head.querySelector("meta[name='version']").content;
    let currentValueAPI;
    function handleChange() {
      currentValueAPI = selectBus(reduxStore.getState());
      if (currentValueHTML === undefined) {
        return;
      }
      if (currentValueAPI === undefined || currentValueAPI === '') {
        return;
      }
      if (currentValueHTML !== currentValueAPI) {
        setTimeout(() => {
          // setting time out so all cache in server has time to be invalidated
          changedFn();
        }, 2000 * 60); // 2min
      }
    }
    this.unsubscribe = reduxStore.subscribe(handleChange);
  };

  render() {
    return null;
  }
}

UpdateAppTracker.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(UpdateAppTracker);
