export const createNewView = (name, userId, type) => {
  const newView = {
    viewName: name,
    // userId: userId, // Not needed, left for reference
    settings: null,
    type: type,
    details: [],
    _new: true
  };
  return newView;
};

export const createNewViewsD123 = (name, userId) => {
  const retVal = [];

  retVal.push(createNewView(name, userId, "dynamic1"));
  retVal.push(createNewView(name, userId, "dynamic2"));
  retVal.push(createNewView(name, userId, "dynamic3"));

  return retVal;
};

export const sortByLabel = objIn => {
  if (objIn) {
    const ar = Object.values(objIn);
    const sortedArray = ar.sort((a, b) => {
      const aa = a.label.toUpperCase();
      const bb = b.label.toUpperCase();
      return aa.localeCompare(bb);
    });
    return sortedArray;
  } else {
    return [];
  }
};

export const filterOption = (inputValue, option) =>
  option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

export const viewSearchFn = (v, name, type) => {
  return v.viewName.toUpperCase() === name.toUpperCase() && v.type === type;
};

export const getViewsByName = (name, views, allViewsSortedArr) => {
  let inSelectedViewD1 = views.find(v => viewSearchFn(v, name, "dynamic1"));
  if (!inSelectedViewD1) {
    inSelectedViewD1.viewName = name;
    inSelectedViewD1._new = true;
    inSelectedViewD1.details = [];
    delete inSelectedViewD1.id;
  }
  const d1Selected = [];
  inSelectedViewD1.details.sort((a, b) => a.weight - b.weight);
  inSelectedViewD1.details.forEach(d => {
    d1Selected.push(d.field);
  });

  let inSelectedViewD2 = views.find(v => viewSearchFn(v, name, "dynamic2"));
  if (!inSelectedViewD2) {
    inSelectedViewD2.viewName = name;
    inSelectedViewD2._new = true;
    inSelectedViewD2.details = [];
    delete inSelectedViewD2.id;
  }
  const d2Selected = [];
  inSelectedViewD2.details.sort((a, b) => a.weight - b.weight);
  inSelectedViewD2.details.forEach(d => {
    d2Selected.push(d.field);
  });

  let inSelectedViewD3 = views.find(v => viewSearchFn(v, name, "dynamic3"));
  if (!inSelectedViewD3) {
    inSelectedViewD3.viewName = name;
    inSelectedViewD3._new = true;
    inSelectedViewD3.details = [];
    delete inSelectedViewD3.id;
  }
  const d3Selected = [];
  inSelectedViewD3.details.sort((a, b) => a.weight - b.weight);
  inSelectedViewD3.details.forEach(d => {
    d3Selected.push(d.field);
  });

  const d1 = [];
  allViewsSortedArr[0].forEach(f => {
    if (f.label.trim() !== "") {
      d1.push({
        key: f.name,
        title: f.label,
        rest: f
      });
    }
  });

  const d2 = [];
  allViewsSortedArr[1].forEach(f => {
    if (f.label.trim() !== "") {
      d2.push({
        key: f.name,
        title: f.label,
        rest: f
      });
    }
  });

  const d3 = [];
  allViewsSortedArr[2].forEach(f => {
    if (f.label.trim() !== "") {
      d3.push({
        key: f.name,
        title: f.label,
        rest: f
      });
    }
  });

  return {
    d1,
    d1Selected,
    inSelectedViewD1,
    d2,
    d2Selected,
    inSelectedViewD2,
    d3,
    d3Selected,
    inSelectedViewD3
  };
};

//**
// * Returns View Details
export const calcFields = (cloneDX, selectedX, type) => {
  const dXSet = new Set(selectedX);
  let max = 0;
  const namedDetails = [];
  cloneDX.details.forEach(d => {
    // marking all as deleted
    const dd = { ...d, delete: true };
    namedDetails.push(dd);
    max = dd.weight > max ? dd.weight : max;
  });

  const newDXDetails = [];
  namedDetails.forEach(detailField => {
    // iterate all and mark the ones from selected to not be deleted
    const clone = { ...detailField };
    delete clone.view;
    clone.delete = !dXSet.has(detailField.field);
    newDXDetails.push(clone);
    // removing from the set all the ones are in cloned view
    dXSet.delete(detailField.field);
  });

  let w = max;
  // adding brand new from the set
  dXSet.forEach(dElem => {
    newDXDetails.push({
      type: type,
      field: dElem,
      settings: null,
      weight: w,
      delete: false
    });
    w = w + 10;
  });

  selectedX.forEach((itm, count) => {
    const idx = newDXDetails.findIndex(n => n.field === itm);
    newDXDetails[idx].weight = (1 + count) * 10;
  });

  return newDXDetails;
};
