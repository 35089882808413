import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Creatable } from 'react-select';
import 'react-select/dist/react-select.css';

class MilestonSetingsNewLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMilestoneOption: '',
      selectedDay: format(new Date(), 'YYYY-MM-DD'),
    };
  }

  innerHandleMilestoneChange(val) {
    this.setState({ selectedMilestoneOption: val });
  }

  handleSelecteDay(val) {
    const formatted = format(parse(val), 'YYYY-MM-DD');
    this.setState({ selectedDay: formatted });
  }

  saveCallback() {
    if (this.state.selectedMilestoneOption) {
      const retV = {
        date: format(this.state.selectedDay, 'YYYY-MM-DD'),
      };
      if (this.state.selectedMilestoneOption.originalVal) {
        retV.milestoneId = this.state.selectedMilestoneOption.originalVal.id;
      } else {
        retV.name = this.state.selectedMilestoneOption.value;
      }
      this.props.saveCallback(retV);
    }
  }

  cancelCallback() {
    this.props.cancelCallback();
  }

  render() {
    return (
      <div className="MilestonSetingsNewLine">
        <div
          className={`grid-container ld-over ${this.props.showLoading ? 'running' : 'not-running'}`}
        >
          <span className="ld ld-ring ld-spin" />
          <div className="grid-x">
            <div className="cell small-5 medium-2 u-vertical-middle">
              <DayPickerInput
                value={this.state.selectedDay}
                onDayChange={val => this.handleSelecteDay(val)}
              />
            </div>
            <div className="cell small-7 medium-6 u-vertical-middle">
              <Creatable
                name="milestone"
                value={this.state.selectedMilestoneOption}
                options={this.props.milestones.filter(elem => !elem.onlyOnce)}
                onChange={(val) => {
                  this.innerHandleMilestoneChange(val);
                }}
                placeholder="Type to create new..."
              />
              <div className="controls">
                <button onClick={() => this.cancelCallback()} className="action-btn">
                  Cancel
                </button>
                <button onClick={() => this.saveCallback()} className="action-btn">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MilestonSetingsNewLine;

MilestonSetingsNewLine.propTypes = {
  saveCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  milestones: PropTypes.array.isRequired,
  showLoading: PropTypes.bool.isRequired,
};
