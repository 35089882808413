import React, { Component } from "react";
import BPTable from "../bpTable/BPTable";
import { BP_LEVELS } from "../../../shared/Constants";
import {
  listenForMetasCloseChanges,
  listenForMetasReorderCloseChanges,
  listenForModalsCloseChanges
} from "../../../actions/commonDataActionsUtils";

class BPTableDyn3 extends Component {
  constructor(props) {
    super(props);
    this.bodyCss = document.getElementById("body");
    this.didMetasUpdate = this.didMetasUpdate.bind(this);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.listenForMetasChange = listenForMetasCloseChanges(this.didMetasUpdate);
    this.listenForModalChange = listenForModalsCloseChanges(
      "customviews",
      this.didMetasUpdate
    );
    this.listenForMetasReorderChange = listenForMetasReorderCloseChanges(
      this.didMetasUpdate
    );
    this.bodyCss.classList.add("no-footer");
  }
  componentWillUnmount() {
    this.listenForMetasChange();
    this.listenForMetasReorderChange();
    this.listenForModalChange();
    this.bodyCss.classList.remove("no-footer");
  }

  didMetasUpdate(val) {
    console.log("[BPTableDyn3.jsx] update calll", 1);
    if (!val) {
      // this.setState({ metasUpdated: this.state.metasUpdated + 1 });
      this.tableRef.current.reload();
    }
  }

  render() {
    return (
      <div className="BPTableDyn3 BPTableDyn-wrap">
        <div className="grid-x">
          <div className="cell small-12">
            <div className="">
              <BPTable
                projectId={this.props.projectId}
                ref={this.tableRef}
                level={BP_LEVELS.DYN3}
                idKey={this.props.idKey}
                parentIdKey={this.props.parentIdKey}
                updateClipboard={this.props.updateClipboard}
                getClipboard={this.props.getClipboard}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BPTableDyn3;
