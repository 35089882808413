export const PROJECT_SEARCH = "PROJECT_SEARCH";

export const updateFilters = (search, clientId) => {
    const event = new CustomEvent(PROJECT_SEARCH, {
        detail: {
            search,
            clientId,
        },
    });
    window.dispatchEvent(event);
};

export const listenForUpdateFilters = (handlerFn) => {
    window.addEventListener(PROJECT_SEARCH, handlerFn);

    return () => {
        window.removeEventListener(PROJECT_SEARCH, handlerFn);
    };
};

export const listenForEvents = (eventName, handlerFn) => {
    window.addEventListener(eventName, handlerFn);

    return () => {
        window.removeEventListener(eventName, handlerFn);
    };
};
