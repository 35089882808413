import React from 'react';
import Button from 'antd/lib/button';
import PropTypes from 'prop-types';

export const FasthookCallbackNew = ({ addNewCb }) => {
  return (
    <div className="FasthookCallbackNew">
      <Button className="" onClick={() => addNewCb()}>
        Add New
      </Button>
    </div>
  );
};

FasthookCallbackNew.propTypes = {
  addNewCb: PropTypes.func.isRequired,
};
