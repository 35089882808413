import React from "react";
import PropTypes from "prop-types";
import "./IconComp.scss";

const PlacementIconComp = ({ w, h, fill }) => (
  <div className="PlacementIconComp IconComp">
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        // fill="#000000"
        stroke="none"
      >
        <path
          fill={fill}
          d="M194 5101 c-136 -13 -162 -43 -176 -205 -14 -160 -3 -817 16 -990 18
-160 43 -317 57 -363 6 -18 26 -46 46 -64 33 -29 41 -31 90 -27 l55 5 181 181
c100 100 186 182 190 182 13 0 205 -152 462 -366 127 -105 259 -212 295 -238
61 -45 68 -48 113 -43 183 17 437 283 437 457 0 48 -18 72 -286 382 -235 271
-374 440 -374 454 0 5 82 91 182 191 l181 181 5 55 c4 49 2 57 -27 90 -37 41
-59 49 -192 71 -236 39 -372 48 -774 51 -220 2 -436 0 -481 -4z"
        />
        <path
          fill={fill}
          d="M4130 5104 c-276 -22 -515 -53 -587 -75 -18 -6 -46 -26 -64 -46 -29
-33 -31 -41 -27 -90 l5 -55 181 -181 c100 -100 182 -186 182 -190 0 -13 -152
-205 -366 -462 -105 -126 -212 -259 -238 -295 -45 -61 -48 -68 -43 -113 17
-183 283 -437 457 -437 48 0 66 13 406 307 298 257 414 352 429 353 5 0 92
-82 193 -182 l182 -183 59 0 c52 0 64 4 90 29 17 16 35 43 40 60 13 42 47 264
63 416 15 141 19 932 5 1013 -9 54 -56 110 -103 121 -30 8 -785 16 -864 10z"
        />
        <path
          fill={fill}
          d="M1402 1923 c-27 -21 -159 -133 -293 -249 -273 -236 -441 -374 -455
-374 -5 0 -91 82 -191 182 l-181 181 -55 5 c-49 4 -57 2 -90 -27 -20 -18 -40
-46 -46 -64 -13 -43 -47 -263 -63 -417 -15 -141 -19 -932 -5 -1013 11 -65 59
-113 124 -124 81 -14 872 -10 1013 5 154 16 374 50 417 63 18 6 46 26 64 46
29 33 31 41 27 90 l-5 55 -181 181 c-100 100 -182 186 -182 190 0 13 152 205
366 462 105 127 212 259 238 295 45 61 48 68 43 113 -14 151 -202 360 -377
422 -71 25 -112 20 -168 -22z"
        />
        <path
          fill={fill}
          d="M3520 1927 c-80 -34 -138 -73 -201 -137 -93 -95 -159 -220 -159 -300
0 -48 13 -66 307 -406 257 -298 352 -414 353 -429 0 -5 -82 -92 -182 -193
l-183 -182 0 -59 c0 -52 4 -64 29 -90 16 -17 43 -35 60 -40 42 -13 264 -47
416 -63 141 -15 932 -19 1013 -5 65 11 113 59 124 124 14 81 10 872 -5 1013
-16 154 -50 374 -63 417 -6 18 -26 46 -46 64 -33 29 -41 31 -90 27 l-55 -5
-181 -181 c-100 -100 -186 -182 -190 -182 -13 0 -205 152 -462 366 -126 105
-259 212 -294 237 -74 55 -106 59 -191 24z"
        />
      </g>
    </svg>
  </div>
);

export default PlacementIconComp;

PlacementIconComp.defaultProps = {
  fill: "black"
};

PlacementIconComp.propTypes = {
  fill: PropTypes.string,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired
};
