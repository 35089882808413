import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Input from "antd/lib/input";

import "./MainNavView.scss";
import CompanyAndUser from "../../shared/companyAndUser/CompanyAndUser";
import { PROJECT_FILTER_LIST } from "../../shared/Constants";
import { getQueryStringParam } from "../../utils/URLUtils";
import RegisterDialog from "../../auth/RegisterDialog";
import ClientSelector from "../mainNavView/ClientSelector";
import { updateFilters } from "../../utils/ProjectSearchEvents";
const { Search } = Input;

class MainNavView extends Component {
  constructor(props) {
    super(props);
    this.state = { activeLink: PROJECT_FILTER_LIST.LIVE };
  }

  componentDidMount() {
    const query = getQueryStringParam(PROJECT_FILTER_LIST.FILTER);
    let link = "running";
    if (query) {
      link = query;
    }
    this.setState({ activeLink: link });
  }

  activate(link) {
    this.setState({ activeLink: link });
  }

  openOrg(orClose) {
    this.setState({ registerOpen: orClose });
  }

  render() {
    return (
      <div className="MainNavView">
        {this.state.registerOpen && (
          <RegisterDialog
            type="org"
            open={this.state.registerOpen}
            handleClose={() => this.openOrg(false)}
          />
        )}
        <div className="grid-container full">
          <div className="grid-x projects-header">
            <div className="cell small-9">
              <h5>
                <SelectedName /> <span className="u-gray">Plans</span>
              </h5>
            </div>
            <div className="cell small-3">
              <CompanyAndUser />
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              <div className="top-bar">
                <div className="top-bar-left">
                  <ul className="dropdown menu">
                    <li>
                      <Link
                        to={`/projects/all?${PROJECT_FILTER_LIST.FILTER}=${PROJECT_FILTER_LIST.INPLANNING}`}
                        className={
                          this.state.activeLink ===
                          PROJECT_FILTER_LIST.INPLANNING
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          this.activate(PROJECT_FILTER_LIST.INPLANNING)
                        }
                      >
                        In Planning
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/projects/all?${PROJECT_FILTER_LIST.FILTER}=${PROJECT_FILTER_LIST.LIVE}`}
                        className={
                          this.state.activeLink === PROJECT_FILTER_LIST.LIVE
                            ? "active"
                            : ""
                        }
                        onClick={() => this.activate(PROJECT_FILTER_LIST.LIVE)}
                      >
                        Live
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/projects/all?${PROJECT_FILTER_LIST.FILTER}=${PROJECT_FILTER_LIST.COMPLETED}`}
                        className={
                          this.state.activeLink ===
                          PROJECT_FILTER_LIST.COMPLETED
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          this.activate(PROJECT_FILTER_LIST.COMPLETED)
                        }
                      >
                        Completed
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/projects/all?${PROJECT_FILTER_LIST.FILTER}=${PROJECT_FILTER_LIST.PAUSED}`}
                        className={
                          this.state.activeLink === PROJECT_FILTER_LIST.PAUSED
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          this.activate(PROJECT_FILTER_LIST.PAUSED)
                        }
                      >
                        Paused
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/projects/all"
                        className={
                          this.state.activeLink === PROJECT_FILTER_LIST.ALL
                            ? "active"
                            : ""
                        }
                        onClick={() => this.activate(PROJECT_FILTER_LIST.ALL)}
                      >
                        All
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="top-bar-right">
                  <div className="project-settings">
                    <div className="ProjectSearch">
                      <div className="input-group">
                        <Search onSearch={(val) => updateFilters(val, null)} />
                      </div>
                    </div>
                    <ClientSelector
                      onSelect={(clientId) => updateFilters(null, clientId)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainNavView;

function SelectedName() {
  const name = useSelector((state) => {
    if (state.qndui.selectedClient && state.qndui.selectedClient.name) {
      return state.qndui.selectedClient.name;
    }
    return "All";
  });
  return <span className="SelectedName">{name}</span>;
}
