import React from "react";
import PropTypes from "prop-types";
import "./IconComp.scss";

const PlanIconComp = ({ w, h, fill }) => (
  <div className="PlanIconComp IconComp">
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        // fill="#000000"
        stroke="none"
      >
        <path
          fill={fill}
          d="M446 4904 c-96 -23 -193 -80 -271 -158 -81 -82 -127 -163 -155 -272
-20 -76 -20 -118 -20 -2008 0 -2112 -3 -2000 59 -2128 67 -141 203 -260 351
-308 l75 -25 2075 0 2075 0 75 25 c148 48 284 167 351 308 62 128 59 16 59
2128 0 1890 0 1932 -20 2008 -28 109 -74 190 -155 272 -80 80 -176 135 -276
159 -93 22 -4132 21 -4223 -1z m4284 -2634 c0 -1187 -3 -1684 -11 -1721 -16
-72 -76 -132 -148 -148 -74 -16 -3948 -16 -4022 0 -72 16 -132 76 -148 148 -8
37 -11 534 -11 1721 l0 1670 2170 0 2170 0 0 -1670z"
        />
        <path
          fill={fill}
          d="M933 3560 c-155 -36 -217 -229 -114 -352 68 -81 34 -78 754 -78 696
0 678 -1 740 58 94 89 90 253 -7 327 -73 56 -67 55 -730 54 -336 -1 -625 -4
-643 -9z"
        />
        <path
          fill={fill}
          d="M1713 2770 c-34 -8 -49 -17 -96 -59 -52 -47 -75 -132 -56 -208 16
-63 86 -134 149 -151 34 -10 196 -12 675 -10 629 3 630 3 672 25 94 51 133
154 102 267 -14 49 -84 118 -137 134 -45 14 -1253 16 -1309 2z"
        />
        <path
          fill={fill}
          d="M2275 1971 c-139 -65 -172 -244 -65 -351 72 -72 60 -71 766 -68 l629
3 45 27 c79 46 115 115 108 207 -5 72 -38 128 -100 168 l-42 28 -650 2 c-617
3 -653 2 -691 -16z"
        />
        <path
          fill={fill}
          d="M950 1201 c-106 -22 -179 -108 -181 -214 -2 -64 20 -115 69 -163 62
-60 47 -59 742 -59 l635 0 45 23 c139 73 166 248 54 356 -63 61 -51 60 -719
62 -335 1 -626 -1 -645 -5z"
        />
      </g>
    </svg>
  </div>
);

export default PlanIconComp;

PlanIconComp.defaultProps = {
  fill: "black"
};

PlanIconComp.propTypes = {
  fill: PropTypes.string,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired
};
