import React from "react";
import "../../projects/mainNavView/MainNavView.scss";
import CompanyAndUser from "../../shared/companyAndUser/CompanyAndUser";

const ClientEditNavView = () => (
  <div className="MainNavView ClientsNavView">
    <div className="grid-container full">
      <div className="grid-x projects-header">
        <div className="cell small-10">
          <h6>
            Fastloop <span className="u-gray">Account Holder</span>
          </h6>
        </div>
        <div className="cell small-2">
          <CompanyAndUser />
        </div>
      </div>
    </div>
  </div>
);

export default ClientEditNavView;
