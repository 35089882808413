import React from "react";
import PropTypes from "prop-types";

import Fasthooks from "./Fasthooks";

const FasthooksInclude = ({ projectId }) => {
  return (
    <div className="FasthooksInclude">
      <Fasthooks projectId={projectId} />
    </div>
  );
};

export default FasthooksInclude;

FasthooksInclude.propTypes = {
  projectId: PropTypes.number.isRequired,
};
