/* eslint-disable react/sort-comp */
/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { profileServiceStatic } from '../profile/ProfileService';
import SaveButton from '../../shared/saveWithLoadingBtn/SaveWithLoadingBtn';
import RegistrationNotes from './RegistrationNotes';
import RedStar from './Red';
import './Registrations.scss';

const Red = min => (
  <span>
    {' '}
    {min.min !== 0 ? <RedStar /> : null}
    {' '}
  </span>
);

class RegistrationOrgAndUser extends Component {
  constructor(props) {
    super(props);
    this.hasErrorsSet = new Set();
    this.fieldsClient = [
      {
        label: 'Legal Business Name',
        type: 'string',
        min: 5,
        max: 255,
        name: 'org.legalName',
      },
      {
        label: 'Operating Business Name',
        type: 'string',
        min: 5,
        max: 255,
        name: 'org.name',
      },
      {
        label: 'Address',
        type: 'string',
        min: 5,
        max: 255,
        name: 'org.address1',
      },
      {
        label: 'Address 2',
        type: 'string',
        min: 0,
        max: 255,
        name: 'org.address2',
      },
      {
        label: 'City',
        type: 'string',
        min: 5,
        max: 255,
        name: 'org.city',
      },
      {
        label: 'Province',
        type: 'string',
        min: 2,
        max: 255,
        name: 'org.province',
      },
      {
        label: 'Postal code',
        type: 'string',
        min: 5,
        max: 255,
        name: 'org.postalCode',
      },
      {
        label: 'Phone',
        type: 'phone',
        min: 5,
        max: 255,
        name: 'org.phone',
      },
      {
        label: 'Fax',
        type: 'phone',
        min: 0,
        max: 255,
        name: 'org.fax',
      },
      {
        label: 'Legal Structure',
        type: 'select',
        options: [
          { label: 'Corporation', value: 'Corporation' },
          { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
          { label: 'Proprietorship', value: 'Proprietorship' },
          { label: 'Non Profit', value: 'Non Profit' },
          { label: 'Other', value: 'Other' },
        ],
        name: 'org.legalStructure',
        max: 255,
        min: 0,
      },
      {
        label: 'Business Number (or SIN)',
        type: 'number',
        min: 5,
        max: 255,
        name: 'org.businessNumber',
      },
      {
        label: 'Email',
        type: 'string',
        min: 5,
        max: 255,
        validator: 'email',
        name: 'org.email',
      },
    ];

    this.fieldsContact = [
      {
        label: 'First Name',
        type: 'string',
        min: 2,
        max: 255,
        name: 'primary.firstname',
      },
      {
        label: 'Last Name',
        type: 'string',
        min: 2,
        max: 255,
        name: 'primary.lastname',
      },
      {
        label: 'Email Address',
        type: 'string',
        min: 5,
        max: 255,
        validator: 'email',
        name: 'primary.email',
      },
      {
        label: 'Phone',
        type: 'phone',
        min: 5,
        max: 255,
        name: 'primary.phone',
      },
    ];

    this.fieldsAccounting = [
      {
        label: 'First Name',
        type: 'string',
        min: 2,
        max: 255,
        name: 'account.firstname',
      },
      {
        label: 'Last Name',
        type: 'string',
        min: 2,
        max: 255,
        name: 'account.lastname',
      },
      {
        label: 'Email Address',
        type: 'string',
        min: 5,
        max: 255,
        validator: 'email',
        name: 'account.email',
      },
      {
        label: 'Phone',
        type: 'phone',
        min: 5,
        max: 255,
        name: 'account.phone',
      },
    ];
    this.fieldsUser = [
      {
        label: 'First Name',
        type: 'string',
        min: 2,
        max: 255,
        name: 'user.firstname',
      },
      {
        label: 'Last Name',
        type: 'string',
        min: 2,
        max: 255,
        name: 'user.lastname',
      },
      {
        label: 'Email Address',
        type: 'string',
        min: 5,
        max: 255,
        validator: 'email',
        name: 'user.email',
      },
      {
        label: 'Phone',
        type: 'phone',
        min: 5,
        max: 255,
        name: 'user.phone',
      },
    ];
    const fields = {};
    if (!this.props.noUser) {
      this.fieldsUser.forEach((el) => {
        fields[el.name] = el;
      });
    }
    this.fieldsContact.forEach((el) => {
      fields[el.name] = el;
    });
    this.fieldsClient.forEach((el) => {
      fields[el.name] = el;
    });
    this.fieldsAccounting.forEach((el) => {
      fields[el.name] = el;
    });
    this.state = {
      data: {},
      fields,
      saveSuccess: true,
      saveLoading: false,
      hasErrors: false,
      hasErrorsNotes: false,
      registered: false,
      goCheck: false,
    };
  }

  componentDidMount() {}

  onBlur(e) {
    if (this.state.fields) {
      const fieldsClone = this.state.fields;
      const name = e.target.name;
      const value = e.target.value;
      const el = this.state.fields[e.target.name];
      if (!this.betweenRange(value, el.min, el.max)) {
        fieldsClone[name].invalid = true;
        this.hasErrorsSet.add(name);
      } else {
        this.hasErrorsSet.delete(name);
        fieldsClone[name].invalid = false;
      }

      if (fieldsClone[name].validator && fieldsClone[name].validator === 'email') {
        if (value.indexOf('@') === -1) {
          fieldsClone[name].invalid = true;
          this.hasErrorsSet.add(name);
        } else {
          this.hasErrorsSet.delete(name);
          fieldsClone[name].invalid = false;
        }
      }
      this.setState({ fields: fieldsClone, hasErrors: this.hasErrorsSet.size !== 0 });
    }
  }

  validateAll() {
    this.setState({ goCheck: true }, () => {
      Object.keys(this.state.fields).forEach((key) => {
        const ev = {
          target: {
            name: key,
            value: this.state.data[key] || '',
          },
        };
        this.onBlur(ev);
      });
      if (this.hasErrorsSet.size === 0) {
        this.onRegister();
      }
      this.setState({ goCheck: false });
    });
  }

  onPreRegister() {
    this.validateAll();
  }

  onRegister() {
    const data = this.state.data;
    if (!data['org.legalStructure']) {
      data['org.legalStructure'] = 'Corporation'; // silly default
    }
    data['org.postalCode'] = data['org.postalCode'].replace(/ /g, '');

    const dataOut = {
      name: data['org.name'],
      legalName: data['org.legalName'],
      address1: data['org.address1'],
      address2: data['org.address2'],
      city: data['org.city'],
      province: data['org.province'],
      postalCode: data['org.postalCode'],
      phone: data['org.phone'],
      fax: data['org.fax'],
      email: data['org.email'],
      legalStructure: data['org.legalStructure'],
      businessNumber: data['org.businessNumber'],
      notes: [
        {
          note: this.fullNote,
        },
      ],
      contacts: [
        {
          type: 'ACCOUNTING',
          firstname: data['account.firstname'],
          lastname: data['account.lastname'],
          email: data['account.email'],
          phone: data['account.phone'],
        },
        {
          type: 'PRIMARY',
          firstname: data['primary.firstname'],
          lastname: data['primary.lastname'],
          email: data['primary.email'],
          phone: data['primary.phone'],
        },
      ],
    };
    const dataOutUsers = [
      {
        firstname: data['user.firstname'],
        lastname: data['user.lastname'],
        email: data['user.email'],
        phone: data['user.phone'],
        roleId: 1,
      },
    ];
    if (!this.props.noUser) {
      dataOut.users = dataOutUsers;
    }

    profileServiceStatic.registerNewCustomer(
      dataOut,
      () => {
        this.setState({ registered: true, saveSuccess: true });
      },
      () => {
        this.setState({ registered: false, saveSuccess: false, saveLoading: true });
      },
    );
  }

  getNotesInfo(notesData) {
    this.fullNote = notesData.fullNote;
    this.setState({ hasErrorsNotes: notesData.hasError });
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    const dataClone = { ...this.state.data };

    dataClone[name] = value;
    this.setState({ data: dataClone }, () => {
      // console.log('state', this.state);
    });
  }

  betweenRange = (input, min, max) => input.trim().length >= min && input.trim().length <= max;

  render() {
    const disableSave = this.state.hasErrors || this.state.hasErrorsNotes;
    return (
      <div className="RegistrationOrg Registration">
        {this.state.registered ? (
          <div className="registered" style={this.formSize}>
            <div className="grid-x grid-padding-x align-center-middle text-center">
              <h5>Thank you!</h5>
            </div>
          </div>
        ) : (
          <div>
            {!this.props.noUser ? (
              <div className="block">
                <div className="grid-x grid-padding-x">
                  <div className="cell small-12">
                    <h6>User Information</h6>
                  </div>
                </div>
                <div className="grid-x grid-padding-x">
                  {this.fieldsUser.map(el => (
                    <div
                      className="cell small-12"
                      key={el.name}
                      data-invalid={this.state.fields[el.name].invalid || false}
                    >
                      <label>
                        {el.label}
                        {' '}
                        <Red min={el.min} />
                      </label>
                      <input
                        type="text"
                        name={el.name}
                        placeholder={el.label}
                        value={el.value}
                        onChange={e => this.handleChange(e)}
                        onBlur={e => this.onBlur(e)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <div className="block">
              <div className="grid-x grid-padding-x">
                <div className="cell small-12">
                  <h6>Client Information</h6>
                </div>
              </div>
              <div className="grid-x grid-padding-x">
                {this.fieldsClient.map((el) => {
                  if (el.type === 'select') {
                    return (
                      <div
                        className="cell small-12"
                        key={el.name}
                        data-invalid={this.state.fields[el.name].invalid || false}
                      >
                        <label>
                          {el.label}
                          {' '}
                          <Red min={el.min} />
                        </label>
                        <select
                          name={el.name}
                          value={el.value}
                          onBlur={e => this.onBlur(e)}
                          onChange={e => this.handleChange(e)}
                        >
                          {el.options.map(op => (
                            <option key={op.value} value={op.value}>
                              {op.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  }
                  return (
                    <div
                      className="cell small-12"
                      key={el.name}
                      data-invalid={this.state.fields[el.name].invalid || false}
                    >
                      <label>
                        {el.label}
                        {' '}
                        <Red min={el.min} />
                      </label>
                      <input
                        type="text"
                        name={el.name}
                        placeholder={el.label}
                        value={el.value}
                        onChange={e => this.handleChange(e)}
                        onBlur={e => this.onBlur(e)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="block">
              <div className="grid-x grid-padding-x">
                <div className="cell small-12">
                  <h6>Primary Contact Information</h6>
                </div>
              </div>
              <div className="grid-x grid-padding-x">
                {this.fieldsContact.map(el => (
                  <div
                    className="cell small-12"
                    key={el.name}
                    data-invalid={this.state.fields[el.name].invalid || false}
                  >
                    <label>
                      {el.label}
                      {' '}
                      <Red min={el.min} />
                    </label>
                    <input
                      type="text"
                      name={el.name}
                      placeholder={el.label}
                      value={el.value}
                      onChange={e => this.handleChange(e)}
                      onBlur={e => this.onBlur(e)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="block">
              <div className="grid-x grid-padding-x">
                <div className="cell small-12">
                  <h6>Accounting Contact Information</h6>
                </div>
              </div>
              <div className="grid-x grid-padding-x">
                {this.fieldsAccounting.map(el => (
                  <div
                    className="cell small-12"
                    key={el.name}
                    data-invalid={this.state.fields[el.name].invalid || false}
                  >
                    <label>
                      {el.label}
                      {' '}
                      <Red min={el.min} />
                    </label>
                    <input
                      type="text"
                      name={el.name}
                      placeholder={el.label}
                      value={el.value}
                      onChange={e => this.handleChange(e)}
                      onBlur={e => this.onBlur(e)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <RegistrationNotes
              onBlurCB={data => this.getNotesInfo(data)}
              goCheck={this.state.goCheck}
            />
            <div className="grid-x grid-padding-x align-center">
              <div className="cell small-2">
                <SaveButton
                  onSave={() => this.onPreRegister()}
                  disable={disableSave}
                  success={this.state.saveSuccess}
                  loading={this.state.saveLoading}
                  label="Register"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RegistrationOrgAndUser;

RegistrationOrgAndUser.defaultProps = {
  noUser: false,
};

RegistrationOrgAndUser.propTypes = {
  noUser: PropTypes.bool,
};
