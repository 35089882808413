import React, { Component } from 'react';

import './RecoverPassword.scss';
import Footer from '../shared/footer/Footer';
import PasswordSent from './PasswordSent';
import RecoverPasswordForm from './RecoverPasswordForm';
import AuthService from './AuthService';
import { validateEmail } from '../utils/EmailUtils';

/**
 * RecoverPassword Component, takes email.
 * Uses: RecoverPasswordForm and PasswordSent Components to display data.
 */
class RecoverPassword extends Component {
  constructor() {
    super();
    this.auth = new AuthService();
    this.state = {
      email: '',
      paswordSent: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.email.trim().length > 0 && validateEmail(this.state.email)) {
      this.auth.requestPasswordReset(
        this.state.email,
        () => {
          this.setState({ paswordSent: true });
        },
        () => {
          this.setState({ paswordSent: false });
        },
      );
    }
  };

  handleInnerValue = (innerVal) => {
    this.setState({ email: innerVal });
  };

  render() {
    return (
      <div className="RecoverPassword">
        <div className="grid-container ">
          <div className="RecoverPassword">
            <div className="grid-y v-grid-height">
              <div className="cell small-3">
                <div className="grid-x grid-padding-x">
                  <div className="small-12 cell" />
                </div>
              </div>
              <div className="cell small-9">
                <div className="grid-x grid-padding-x main-row-padding">
                  <div className="small-12 medium-6 cell">
                    {this.state.paswordSent ? (
                      <PasswordSent />
                    ) : (
                      <RecoverPasswordForm
                        handleSubmit={this.handleSubmit}
                        innerValue={this.handleInnerValue}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid-container full">
          <div className="grid-x">
            <div className="small-12 cell">
              <div className="footer">
                <Footer logged />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecoverPassword;
