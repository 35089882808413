import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";
import notification from "antd/lib/notification";
import { projectsServiceStatic } from "../../../ProjectsService";
import { showConfirmDelete } from "./ProjectDeleteConfirm";
import ProjectAddUser from "./ProjectAddUser";

import "./ProjectAdminSettings.scss";

class ProjectAdminSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      binAdminLoading: false,
      sheetsLoading: false,
    };
    this.project = this.props.project;
  }

  onDelete = () => {
    return showConfirmDelete(
      this.project.docket,
      () => {
        this.binAdmin(4);
        notification["success"]({
          message: "All good",
          description: "Project is being deleted!",
        });
        setTimeout(() => {
          window.location.href = "/projects/all/";
        }, 3000);
      },
      () => {
        notification["error"]({
          message: "Project Code is wrong",
          description: "Try again!",
        });
      }
    );
  };

  updateSharedList = (data) => {
    if (data.emails === undefined) return;
    var str = data.emails;
    var modStr = "";
    if (str[0] === "-") {
      modStr = str.slice(1);
    } else {
      modStr = str;
    }
    var resArray = modStr.split(/\s+[-]*/);
    if (resArray[resArray.length - 1] === "") {
      resArray.pop();
    }
    projectsServiceStatic.shareSheet(this.project.id, resArray, (data) => {
      this.setState({ binAdminLoading: false });
      this.showMessage(data);
    });
  };

  binAdmin(no, str) {
    if (!str) {
      this.setState({ binAdminLoading: true });
      projectsServiceStatic.binAdmin(no, [this.project.docket], (data) => {
        this.setState({ binAdminLoading: false });
      });
    } else {
      this.setState({ binAdminLoading: true });
      projectsServiceStatic.binAdmin(no, [this.project.docket, str], (data) => {
        this.setState({ binAdminLoading: false });
        this.showMessage(data);
      });
    }
  }

  sheetsPull() {
    this.setState({ sheetsLoading: true });
    projectsServiceStatic.pullSheet(
      this.project.id,
      () => {
        this.setState({ sheetsLoading: false });
        notification["info"]({
          message: "Sheets Synced back",
          description: "All good.",
          duration: 10,
        });
      },
      () => {
        this.setState({ sheetsLoading: false });
        notification["error"]({
          message: "Something went wrong!",
          description: "Please try again.",
          duration: 0,
        });
      }
    );
  }

  showMessage = (data) => {
    if (Array.isArray(data) && data.length === 0) {
      notification["success"]({
        message: "Success!",
        description: "Your project's Google Sheets has been shared!",
        duration: 3,
      });
    } else {
      notification["error"]({
        message: "Something went wrong!",
        description: "Please try to re-enter emails and try again.",
        duration: 0,
      });
    }
  };
  render() {
    return (
      <div className="ProjectGeneralSettings">
        <div>
          <h6>Project Admin Settings</h6>
        </div>
        <div className="grid-x setting-row" aria-labelledby="Customer">
          <div className="cell small-12 medium-2 name">Admin Bin</div>
          <div className="cell small-10 medium-10 value">
            <div
              className={`ld-over ${
                this.state.binAdminLoading ? "running" : "not-running"
              }`}
            >
              <span className="ld ld-ring ld-spin" />

              <div className="admin-action">
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => this.binAdmin(1)}
                >
                  <Button>Update BigQuery</Button>
                </Popconfirm>
              </div>
              <div className="admin-action">
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => this.binAdmin(2)}
                >
                  <Button>Reset Defaults</Button>
                </Popconfirm>
              </div>
              <div className="admin-action">
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => this.binAdmin(3)}
                >
                  <Button>Do DS Sync</Button>
                </Popconfirm>
              </div>
              <div className="admin-action">
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => this.onDelete(4)}
                >
                  <Button type="danger">Delete Project</Button>
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-x setting-row" aria-labelledby="Other">
          <div className="cell small-12 medium-2 name">Google Sheets</div>
          <div className="cell small-10 medium-10 value">
            <div
              className={`ld-over ${
                this.state.sheetsLoading ? "running" : "not-running"
              }`}
            >
              <span className="ld ld-ring ld-spin" />
              <div className="display-inline">
                <ProjectAddUser
                  onShared={(data) => this.updateSharedList(data)}
                />
                <Button onClick={() => this.sheetsPull()}>
                  Sync Google Sheet to Fastloop
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectAdminSettings;

ProjectAdminSettings.propTypes = {
  project: PropTypes.object.isRequired,
};
