import React, { Component } from 'react';
import ProjectsService from '../../ProjectsService';
import { getParamAfterSlug } from '../../../utils/URLUtils';
import './ProjectStatsMainView.scss';
import HelpIcon from '../../../shared/helpComponent/HelpIcon';

class ProjectStatsMainView extends Component {
  constructor(props) {
    super(props);
    this.projectsService = new ProjectsService();
    this.state = { project: {}, isLoading: true, biurl: '' };
  }

  componentDidMount() {
    this.projectId = getParamAfterSlug('projects');
    this.projectsService.getProject(this.projectId, project => {
      this.setState({
        ...this.state,
        project,
        biurl: project.reportingToken,
        projectId: this.projectId,
        isLoading: false,
      });
    });
  }

  render() {
    return (
      <div className="ProjectStatsMainView">
        <div
          className={`ld-over ${
            !this.state.isLoading ? 'not-running' : 'running'
          }`}
        >
          <span className="ld ld-ring ld-spin" />
          <div className="u-padding-both-sides-25">
          <div align='right' ><HelpIcon code="3" placement='left' /></div>
            <div className="stats-body">
              {/* <div className="hideme" /> */}
              {this.state.biurl ? (
                <iframe
                  title="power bi"
                  width="1080"
                  height="700"
                  src={this.state.biurl}
                  frameBorder="0"
                  allowFullScreen={true}
                />
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectStatsMainView;
