import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "antd/lib/input";
import Cascader from "antd/lib/cascader";
import NewProjectSubTitle from "./NewProjectSubTitle";
import ProjectsService, { isValid } from "../ProjectsService";
import Notifications from "../../shared/notifications/Notifications";
import FireImg from "../../shared/icons/fire.gif";
import AdvertiserView from "../../modals/advertiserView/AdvertiserView";

const InputGroup = Input.Group;

const STYLE = {
  input: {
    width: "100px",
    height: "41px",
  },
};

const filter = (inputValue, path) => {
  return path.some(
    (option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};

/**
 * New project header, contains name and docket input.
 * @param {func} handler callback fn, returns state - name and docket back
 * @param {any} valid object with validation info
 * @param {bool} showInvalidTopMessage flag to show or hide main error message
 */
class NewProjectHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      budget: "",
      docket: "",
      apiLoaded: false,
      apiFail: false,
      shortcode: "",
      customersRaw: [],
      selectedCustomerOption: "",
      defValueArr: [],
      valueArr: [],
    };
    this.fireStyle = {
      backgroundImage: `url(${FireImg})`,
      fontSize: "2em",
    };
    this.projectServie = new ProjectsService();
  }

  componentDidMount() {
    this.projectServie.getCustomers(
      (data) => {
        const defValueArr = [data[0].name];
        const placeholder = new Date()
          .getFullYear()
          .toString()
          .substr(-2);
        this.setState({
          apiLoaded: true,
          customersRaw: data,
          defValueArr,
          docketPlaceholder: placeholder + "-00",
        });
      },
      () => {
        this.setState({
          apiFail: true,
        });
      }
    );
  }

  updateClientsList = (data, allCustomersData) => {
    const shortcode = this.makeShortCode(data.shortcode);
    const parent = allCustomersData.find((d) => d.id === data.parent.id);
    const customerObj = parent.children.find((c) => c.id === data.id);
    this.setState(
      {
        customersRaw: allCustomersData,
        selectedCustomerOption: customerObj,
        shortcode,
        defValueArr: [data.parent.id, data.id],
      },
      () => {
        this.props.handler(this.state);
      }
    );
  };

  makeShortCode = (code) => {
    return code + "-";
  };

  handlerCustomerChange = (valuesIds, objs) => {
    console.log("[NewProjectHeader.jsx] objs ", objs, valuesIds);
    const local = [...objs];
    const customerObj = local.pop();
    const defValueArr = valuesIds;
    this.setState(
      {
        defValueArr: defValueArr,
        selectedCustomerOption: customerObj,
        shortcode: this.makeShortCode(customerObj.shortcode),
      },
      () => {
        this.props.handler(this.state);
      }
    );
  };

  nameHandler = (event) => {
    this.setState({ name: event.target.value }, () => {
      this.props.handler(this.state);
    });
  };

  budgetHandler = (event) => {
    this.setState({ budget: event.target.value }, () => {
      this.props.handler(this.state);
    });
  };

  docketHandler = (event) => {
    /* eslint-disable no-useless-escape */
    let val = event.target.value.replace(/[^0-9\-]+/, "");
    this.setState({ docket: val }, () => {
      this.props.handler(this.state);
    });
  };

  render() {
    return (
      <div className="NewProjectHeader">
        <div
          className={`grid-container ld-over ${
            this.state.apiLoaded ? "not-running" : "running"
          }`}
        >
          <span className="ld ld-ring ld-spin" />
          <span
            className={`ld ld-loader ${this.state.apiFail ? "show" : "hide"}`}
            style={this.fireStyle}
          />
          <div className="grid-container full new-project-head d">
            <div className="grid-container">
              <div className="mid grid-x">
                <div className="cell small-12 medium-10">
                  <h4>
                    Fastloop <span className="u-gray">New Plan</span>
                  </h4>

                  <Notifications />

                  {this.props.showInvalidTopMessage ? null : (
                    <p>
                      Entering as much data as possible up front will make life
                      easier in the long run.
                      <br />
                      Items with <span className="u-red">•</span> are mandatory.
                    </p>
                  )}
                  <NewProjectSubTitle title="Plan Details" desc="" />

                  <div className="grid-x">
                    <div className="cell small-12 medium-3 v-center">
                      <span>
                        <span className="u-red">• </span>
                        <span className="u-gray">Plan Name</span>
                      </span>
                    </div>
                    <div className="cell small-12 medium-5 v-center">
                      <input
                        type="text"
                        name="projectName"
                        className="projectName"
                        id="projectName"
                        placeholder="Plan Name"
                        value={this.state.name}
                        onChange={this.nameHandler}
                      />
                    </div>
                    <div className="cell small-1 medium-1 v-center">
                      {isValid(this.props.valid, "name") ? (
                        <span />
                      ) : (
                        <div className="validation-required">Required</div>
                      )}
                    </div>
                  </div>
                  <div className="grid-x">
                    <div className="cell small-12 medium-3 v-center">
                      <span>
                        <span className="u-red">• </span>
                        <span className="u-gray">Advertiser</span>
                      </span>
                    </div>
                    <div className="cell small-12 medium-5 v-center">
                      <Cascader
                        className="customer"
                        options={this.state.customersRaw}
                        changeOnSelect
                        onChange={(val, opt) => {
                          this.handlerCustomerChange(val, opt);
                        }}
                        allowClear={false}
                        showSearch={{ filter }}
                        fieldNames={{ label: "name", value: "id" }}
                        value={this.state.defValueArr}
                      />
                      <AdvertiserView
                        parentsList={this.state.customersRaw}
                        onCreated={(data, allCustomersData) =>
                          this.updateClientsList(data, allCustomersData)
                        }
                      />
                    </div>
                    <div className="cell small-1 medium-1 v-center">
                      {isValid(this.props.valid, "docket") ? (
                        <span />
                      ) : (
                        <div className="validation-required">Required</div>
                      )}
                    </div>
                  </div>
                  <div className="grid-x">
                    <div className="cell small-12 medium-3 v-center">
                      <span>
                        <span className="u-red">• </span>
                        <span className="u-gray">Plan Code</span>
                      </span>
                    </div>
                    <div className="cell small-12 medium-5 v-center">
                      <InputGroup>
                        <Input
                          className="short-code"
                          readOnly
                          value={this.state.shortcode}
                          style={STYLE.input}
                        />
                        <Input
                          className="rest-code"
                          value={this.state.docket}
                          onChange={this.docketHandler}
                          placeholder={this.state.docketPlaceholder}
                          style={STYLE.input}
                        />
                      </InputGroup>
                    </div>
                    <div className="cell small-1 medium-1 v-center">
                      {isValid(this.props.valid, "docket", "docketNumbers") ? (
                        <span />
                      ) : (
                        <div className="validation-required">Required</div>
                      )}
                    </div>
                  </div>
                  <div className="grid-x">
                    <div className="cell small-12 medium-3 v-center">
                      <span>
                        <span className="u-gray">Budget</span>
                      </span>
                    </div>
                    <div className="cell small-12 medium-5 v-center">
                      <input
                        type="number"
                        pattern="[0-9]+([,\.][0-9]+)?"
                        name="projectBudget"
                        className="projectBudget"
                        id="projectBudget"
                        placeholder="Budget"
                        value={this.state.budget}
                        onChange={this.budgetHandler}
                      />
                    </div>
                    <div className="cell small-1 medium-1 v-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewProjectHeader;

NewProjectHeader.propTypes = {
  handler: PropTypes.func.isRequired,
  valid: PropTypes.any.isRequired,
  showInvalidTopMessage: PropTypes.bool.isRequired,
};
