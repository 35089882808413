import React from "react";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import FasthooksTabs from "./FasthooksTabs";
import FasthooksSelection from "./FasthooksSelection";
import { FLHServiceStatic } from "../../services/FLHService";
import {
  getAllColsBySortedByCols,
  initViews,
  getAllCols,
} from "../../pages/bp/BPViewService";

import {
  labelParams,
  mergeHookMap,
  byKey,
  makeOptionsDropDown,
  removeNewFlags,
  makeHookTabs,
} from "./FasthooksUtils";

import { updatedHook } from "./FasthooksUIEvents";

class Fasthooks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsAndHooks: [],
      event: {},
      hook: { isEmpty: true },
      saveSuccess: false,
      saveLoading: false,
      deleteSuccess: false,
      deleteLoading: false,
      newSelected: "",
      data1: [],
    };
    this.eventFromCB = {};
    this.eventFromCBFromGrid = {};
    this.selectionChild = React.createRef();
    this.viewCols = { blocking: {} };
    this.selectedHook = {};
    this.optionsDropDown = [];
  }
  componentWillMount() {
    initViews(this.props.projectId, () => {
      this.loadData();
      this.viewCols.blocking = getAllColsBySortedByCols(this.props.projectId);
      this.allColsD1 = getAllCols(this.props.projectId, "dynamic1");
      this.allColsD2 = getAllCols(this.props.projectId, "dynamic2");
      this.allColsD3 = getAllCols(this.props.projectId, "dynamic3");

      this.viewCols.blocking = {
        Dynamic1: byKey(this.allColsD1),
        Dynamic2: byKey(this.allColsD2),
        Dynamic3: byKey(this.allColsD3),
      };
    });
  }
  addNewCallBack = () => {
    const hook = { ...this.selectedHook };
    const newCb = FLHServiceStatic.getNewHookBlueprint();

    if (hook.callbacks) {
      hook.callbacks.push(newCb);
    } else {
      hook.callbacks = [newCb];
    }
    this.selectedHook = hook;
    hook.tabs = makeHookTabs(hook.callbacks);
    this.setState({ hook });
  };

  onSelected(event, hook) {
    const data1 = mergeHookMap(hook, this.viewCols);
    const params = labelParams(event.parameters, this.viewCols);

    this.optionsDropDown = makeOptionsDropDown(params);
    this.eventFromCB = data1;
    if (data1.event) {
      data1.event.parametersObj = params;
    }
    data1.tabs = makeHookTabs(data1.callbacks);
    this.setState({ event, hook: data1 });
    this.selectedHook = hook;
  }
  onSave() {
    this.setState({ saveLoading: true });
    if (!this.eventFromCB.id) {
      const mergedEvent = {
        ...FLHServiceStatic.hookInitBlueprint,
        ...this.eventFromCB,
        id: null,
        projectId: this.props.projectId,
        eventId: this.state.event.id,
      };

      mergedEvent.callbacks = removeNewFlags(this.selectedHook.callbacks);
      FLHServiceStatic.postHook(
        mergedEvent,
        () => {
          this.setState({ saveLoading: false, newSelected: true });
          this.loadData({
            name: mergedEvent.name,
            eventId: mergedEvent.eventId,
          });
          updatedHook(this.props.projectId, mergedEvent.eventId);
        },
        () => {
          this.setState({ saveLoading: false, saveSuccess: false });
          this.loadData({
            name: mergedEvent.name,
            eventId: mergedEvent.eventId,
          });
        }
      );
    } else {
      const mergedEvent = { ...this.eventFromCB };
      mergedEvent.map = removeNewFlags(this.selectedHook.map);
      mergedEvent.callbacks = removeNewFlags(this.selectedHook.callbacks);
      mergedEvent.conditions = removeNewFlags(this.selectedHook.conditions);
      FLHServiceStatic.putHook(
        mergedEvent,
        () => {
          this.setState({ saveLoading: false });
          this.loadData({
            name: mergedEvent.name,
            eventId: this.state.event.id,
          });
          updatedHook(this.props.projectId, this.state.event.id);
        },
        () => {
          this.setState({ saveLoading: false, saveSuccess: false });
        }
      );
    }
  }
  onDelete() {
    this.selectionChild.current.doReset();
    if (this.eventFromCB.id) {
      this.setState({ deleteLoading: true });
      FLHServiceStatic.deleteHook(
        this.eventFromCB.id,
        () => {
          this.loadData();
          this.selectionChild.current.doReset();
          this.setState({
            deleteLoading: false,
            deleteSuccess: false,
            hook: { isNew: true },
          });
          updatedHook(this.props.projectId, null);
        },
        (err) => {
          this.setState({ deleteLoading: false, deleteSuccess: false });
        }
      );
    }
  }
  onValuesCB(obj) {
    this.eventFromCB = obj;
  }
  onValuesCBFromGrid(obj) {
    if (obj.key === "hookmap") {
      // this.eventFromCBFromGrid = obj.data;
      const map = this.selectedHook.map.map((m) => {
        if (m.toName === obj.data.toName) {
          return obj.data;
        }
        return m;
      });
      this.selectedHook.map = map;
    }
    if (obj.key === "callbackmap") {
      const cbs = this.selectedHook.callbacks.map((c) => {
        //data.callback is id of the callback
        if (c.id === obj.data.callback) {
          c.map = c.map.map((cm) => {
            if (cm.fromName === obj.data.fromName) {
              return obj.data;
            }
            return cm;
          });
        }
        return c;
      });
      this.selectedHook.callbacks = cbs;
    }
    if (obj.key === "callback") {
      const cbs = this.selectedHook.callbacks.map((c) => {
        if (c.id === obj.data.id) {
          return obj.data;
        }
        return c;
      });
      this.selectedHook.callbacks = cbs;
    }
    if (obj.key === "condition") {
      if (obj.action === "new") {
        if (!Array.isArray(this.selectedHook.conditions)) {
          this.selectedHook.conditions = [];
        }
        this.selectedHook.conditions.push({
          condition: "exists",
          value: "",
          variable: "",
          id: +new Date(),
          _new: true,
        });
        this.setState({ hook: this.selectedHook });
      } else if (obj.action === "delete") {
        const conditions = this.selectedHook.conditions.map((con) => {
          if (Number(con.id) === Number(obj.data.id)) {
            con.delete = true;
            return con;
          }
          return con;
        });
        this.selectedHook.conditions = conditions;
        this.setState({ hook: this.selectedHook });
      } else {
        const conditions = this.selectedHook.conditions.map((con) => {
          if (Number(con.id) === Number(obj.data.id)) {
            return obj.data;
          }
          return con;
        });
        this.selectedHook.conditions = conditions;
        this.setState({ hook: this.selectedHook });
      }
    }
  }
  loadData = (newNameAndEventId) => {
    FLHServiceStatic.getEventsAndHooks(this.props.projectId, (data) => {
      this.setState({ eventsAndHooks: data });
      if (newNameAndEventId) {
        const event = data.find((d) => d.id === newNameAndEventId.eventId);
        const hook = event.hooks.find((e) => e.name === newNameAndEventId.name);
        this.setState({ event, hook });
        this.selectionChild.current.doSelect(newNameAndEventId);
      }
    });
  };
  render() {
    return (
      <div className="Fasthooks">
        <div className="grid-x">
          <div className="cell small-3">
            <FasthooksSelection
              ref={this.selectionChild}
              eventsAndHooks={this.state.eventsAndHooks}
              onSelected={(e, h) => this.onSelected(e, h)}
              onValuesCB={(obj) => this.onValuesCB(obj)}
              newSelected={this.state.newSelected}
            />
          </div>
          <div className="cell small-9">
            <FasthooksTabs
              event={this.state.event}
              hook={this.state.hook}
              onValuesCB={(data) => this.onValuesCBFromGrid(data)}
              viewCols={this.viewCols}
              data1={this.state.data1}
              addNewCallBack={this.addNewCallBack}
              optionsDropDown={this.optionsDropDown}
            />
          </div>
        </div>
        <div className="grid-x align-right btn-wrap">
          <div className="cell small-2">
            <div className="btn-place">
              <Button
                type="danger"
                onClick={() => this.onDelete()}
                loading={this.state.deleteLoading}
              >
                Delete
              </Button>{" "}
              <Button
                onClick={() => this.onSave()}
                loading={this.state.saveLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Fasthooks;

Fasthooks.defaultProps = {
  selectedViewName: "",
};

Fasthooks.propTypes = {
  projectId: PropTypes.number.isRequired,
};
