import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Version.scss';
import tileImg from '../icons/tile-1s-178x15px.svg';

const VersionInner = ({ ui, api, db, tiny }) => (
  <div className={`Version ${tiny && 'tiny-root'}`}>
    {tiny ? (
      <div className="number tiny">
        <span title="Versions">
          ui:
          {ui} <br /> api:
          {api} <br /> db:
          {db}
        </span>
      </div>
    ) : (
      <div className="inner">
        <div className="number ui">
          {ui === '' ? <img alt="Loading" src={tileImg} /> : ui} : UI
        </div>
        <div className="number api">
          {api === '' ? <img alt="Loading" src={tileImg} /> : api} : API
        </div>
        <div className="number db">
          {db === '' ? <img alt="Loading" src={tileImg} /> : db} : DB
        </div>
      </div>
    )}
  </div>
);

const mapStateToProps = (state, ownProps) => ({
  ui: state.versionping.ui,
  api: state.versionping.api,
  db: state.versionping.db,
  tiny: ownProps.tiny,
});

const Version = connect(mapStateToProps)(VersionInner);

export default Version;

VersionInner.defaultProps = {
  tiny: false,
};

VersionInner.propTypes = {
  ui: PropTypes.string.isRequired,
  api: PropTypes.string.isRequired,
  db: PropTypes.string.isRequired,
  tiny: PropTypes.bool,
};
