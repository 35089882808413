/* eslint import/prefer-default-export: 0 */

import * as types from "../types/types";

export const commonDataUsers = (users) => (dispatch) => {
  dispatch({
    type: types.CM_USERS_LOAD,
    payload: users,
  });
};

export const commonDataPlatforms = (platforms) => (dispatch) => {
  dispatch({
    type: types.CM_DATA_PLATFORMS,
    payload: platforms,
  });
};

export const commonDataLoadFlags = (flag, flagState) => (dispatch) => {
  dispatch({
    type: types.CM_LOADED_FLAGS,
    payload: {
      flag,
      flagState,
    },
  });
};

export const commonDataViews = (projectId, views) => (dispatch) => {
  dispatch({
    type: types.CM_LOADED_DATA_VIEWS,
    payload: {
      projectId,
      views,
    },
  });
};

export const commonDataViewsReloaded = (projectId, views) => (dispatch) => {
  dispatch({
    type: types.CM_RELOADED_DATA_VIEWS,
    payload: {
      projectId,
      views,
    },
  });
};

export const commonDataLists = (projectId, lists) => (dispatch) => {
  dispatch({
    type: types.CM_LOADED_DATA_LISTS,
    payload: {
      projectId,
      lists,
    },
  });
};

export const commonDataReloadedLists = (projectId, lists) => (dispatch) => {
  dispatch({
    type: types.CM_RELOADED_DATA_LISTS,
    payload: {
      projectId,
      lists,
    },
  });
};

export const commonDataMetas = (projectId, metas) => (dispatch) => {
  dispatch({
    type: types.CM_LOADED_DATA_METAS,
    payload: {
      projectId,
      metas,
    },
  });
};

export const commonMetasEditorOpen = (
  projectId,
  viewName,
  fieldName,
  dynamicLevelInt,
  userSettings,
  viewId
) => (dispatch) => {
  dispatch({
    type: types.CM_METAS_EDITOR_OPEN,
    payload: {
      open: true,
      payloadBody: {
        projectId,
        fieldName,
        viewName,
        dynamicLevelInt,
        userSettings,
        viewId,
      },
    },
  });
};

export const commonMetasEditorClose = () => (dispatch) => {
  dispatch({
    type: types.CM_METAS_EDITOR_OPEN,
    payload: {
      open: false,
      payloadBody: {},
    },
  });
};

export const commonModalsOpenClose = (name, open, rest) => (dispatch) => {
  dispatch({
    type: types.MODALS_OPEN,
    payload: {
      open,
      name,
      rest,
    },
  });
};

export const commonCallForReload = (name, rest) => (dispatch) => {
  dispatch({
    type: types.CALL_FOR_RELOAD,
    payload: {
      name,
      rest,
    },
  });
};
