import { camp } from '../camp';
import { globalErrorCallback } from '../utils/ErrorTracker';
import { busFireBound, KEYS, UUIDGen } from '../actions/eventBusActionsUtil';
import { constantsLoadedBound } from '../projects/ProjectReduxUtils';

class ProjectConstantsService {
  constructor() {
    this.camp = camp;
  }

  getValues(projectId, callbackOk, callbackFail) {
    const param = { id: projectId };
    const uuid = UUIDGen();
    busFireBound(KEYS.PROJECT_CONSTANTS_GET, 0, uuid);
    this.camp
      .getProjectConstants(param)
      .then((data) => {
        const byKey = {};
        data.forEach((line) => {
          byKey[line.id] = line;
        });
        constantsLoadedBound(projectId, byKey);
        busFireBound(KEYS.PROJECT_CONSTANTS_GET, 1, uuid, data);
        if (callbackOk) callbackOk(byKey);
      })
      .catch((err) => {
        busFireBound(KEYS.PROJECT_CONSTANTS_GET, 2, uuid);
        globalErrorCallback(err, callbackFail);
      });
  }

  setValues(projectId, singeLine, callbackOk, callbackFail) {
    const params = { id: projectId, body: [singeLine] };
    const uuid = UUIDGen();
    busFireBound(KEYS.PROJECT_CONSTANTS_POST, 0, uuid);
    let verb = 'postProjectConstants';
    if (singeLine.id) {
      verb = 'putProjectConstants';
    }
    this.camp[verb](params)
      .then((data) => {
        const byKey = {};
        data.forEach((line) => {
          byKey[line.id] = line;
        });
        busFireBound(KEYS.PROJECT_CONSTANTS_POST, 1, uuid);
        callbackOk(data);
      })
      .catch((err) => {
        busFireBound(KEYS.PROJECT_CONSTANTS_POST, 2, uuid);
        globalErrorCallback(err, callbackFail);
      });
  }

  deleteValue(projectId, singeLine, callbackOk, callbackFail) {
    const params = { id: singeLine.id };
    const uuid = UUIDGen();
    busFireBound(KEYS.PROJECT_CONSTANTS_DELETE, 0, uuid);
    this.camp
      .deleteProjectConstants(params)
      .then((data) => {
        busFireBound(KEYS.PROJECT_CONSTANTS_DELETE, 1, uuid);
        callbackOk(data);
      })
      .catch((err) => {
        busFireBound(KEYS.PROJECT_CONSTANTS_DELETE, 2, uuid);
        globalErrorCallback(err, callbackFail);
      });
  }
}

export default ProjectConstantsService;

export const projectConstantsServiceStatic = new ProjectConstantsService();
