import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Alert from "antd/lib/alert";
import { MAINTENANCE_MODE } from "../../shared/Constants";

const MaintenanceEnabled = ({ maintenance, children }) => {
  return <>{maintenance === MAINTENANCE_MODE.ENABLED && <>{children}</>}</>;
};
MaintenanceEnabled.propTypes = {
  maintenance: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const MaintenanceDisabled = ({ maintenance, children }) => {
  return <>{!maintenance === MAINTENANCE_MODE.DISABLED && <>{children}</>}</>;
};
MaintenanceDisabled.propTypes = {
  maintenance: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const MaintenanceLoading = ({ maintenance, children }) => {
  return <>{!maintenance === MAINTENANCE_MODE.LOADING && <>{children}</>}</>;
};
MaintenanceLoading.propTypes = {
  maintenance: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const mapState = (state, ownProps) => ({
  maintenance: state.versionping.maintenance,
  children: ownProps.children
});

export const MaintenanceEnabledContainer = connect(mapState)(
  MaintenanceEnabled
);
export const MaintenanceDisabledContainer = connect(mapState)(
  MaintenanceDisabled
);
export const MaintenanceLoadingContainer = connect(mapState)(
  MaintenanceLoading
);

export const MaintenanceMessage = () => {
  return (
    <Alert
      message="Fastloop is under maintenance, please come back shortly!"
      banner
    />
  );
};
