/* eslint-disable import/prefer-default-export, consistent-return */

import TagManager from "react-gtm-module";
import { createBrowserHistory } from "history";
import { browserHistoryChanged } from "../actions/browserHistoryActions";
import reduxStore from "../reduxStore";
import { memStorage } from "../services/Storage";

const history = createBrowserHistory();

// const unlisten = history.lis... < use this and call if need to unlisten
history.listen((location, action) => {
  reduxStore.dispatch(browserHistoryChanged(location, action));
  if (!location.pathname.startsWith("/projects/")) {
    memStorage.cleanUpBP();
  }
  const tagManagerArgs = {
    dataLayer: {
      page: location ? location.pathname : ""
    },
    dataLayerName: "PageDataLayer"
  };
  TagManager.dataLayer(tagManagerArgs);
});

history.block((location, action) => {
  if (action === "POP") {
    return "Are you sure you want to leave this page? Going back in History is very dangerous!";
  }
});

export { history };
