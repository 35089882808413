import React from "react";
import Tooltip from "antd/lib/tooltip";
import moment from "moment";
import { DEFAULT_DATE_FORMAT_FOR_DB } from "../../../shared/Constants";
import {
  PlanIconComp,
  PlacementIconComp,
  CreativeIconComp
} from "../../../shared/iconComponents/IconComp";

export const extractViewNames = data => {
  const views = data.views;
  const retVal = views.filter(v => v.type === "dynamic1");
  return retVal;
};

export const extractViewNames2 = data => {
  if (Array.isArray(data)) {
    const retVal = data.filter(v => v.type === "dynamic1");
    return retVal;
  }
  return [];
};

export const megaMerge2 = dataIn => {
  const dataOut = dataIn.map(d => {
    return {
      ...d,
      _uid: `${d.d1_id};${d.d2_id || ""};${d.d3_id || ""};`
    };
  });
  return dataOut;
};

export const megaMerge = dataIn => {
  const ar = [];
  const data = dataIn.dynamic1;
  data.forEach(d1 => {
    const d1line = {};
    Object.keys(d1).forEach(k => {
      if (k !== "dynamic2") {
        d1line[`dynamic1_${k}`] = d1[k];
      }
    });
    d1line._tmp_id = `${d1.id};`;

    if (d1.dynamic2Count > 0) {
      d1.dynamic2.forEach(d2 => {
        const d2line = {};
        Object.keys(d2).forEach(k => {
          if (k !== "dynamic3") {
            d2line[`dynamic2_${k}`] = d2[k];
          }
        });
        d2line._tmp_id = `${d1.id};${d2.id};`;

        if (d2.dynamic3Count > 0) {
          d2.dynamic3.forEach(d3 => {
            const d3line = {};
            Object.keys(d3).forEach(k => {
              d3line[`dynamic3_${k}`] = d3[k];
            });
            d3line._tmp_id = `${d1.id};${d2.id};${d3.id};`;
            ar.push({ ...d1line, ...d2line, ...d3line, _uid: d3line._tmp_id });
          });
        } else {
          ar.push({ ...d1line, ...d2line, _uid: d2line._tmp_id });
        }
      });
    } else {
      ar.push({ ...d1line, _uid: d1line._tmp_id });
    }
  });
  return ar;
};

const cut = line => {
  if (!line) return;
  if (line.length > 30) {
    const ln = line.substring(0, 27);
    return (
      <>
        <Tooltip title={line}>{ln} ...</Tooltip>
      </>
    );
  }
  return <>{line}</>;
};

export const mergeViews = (
  dxLevel,
  getListForColFn,
  [view1, view2, view3],
  { cellWidth, hide, pin, showFullText }
) => {
  const cols = [];
  let cutFn = cut;
  if (showFullText) {
    cutFn = line => line;
  }
  view1.forEach(v => {
    const DKEY = "d1_";
    const ret = {
      title: () => (
        <div className="xxxx">
          <span className="view-level-name l1" title="Plan">
            <PlanIconComp w={10} h={10} fill={"darkgray"} />
          </span>{" "}
          {v.label}{" "}
        </div>
      ),
      onFilter: (value, record) => {
        const name = DKEY + v.name;
        return record[name].startsWith(value);
      },
      sorter: (a, b) => sortFN(a, b, v.name, "dynamic1", DKEY, getListForColFn),
      sortDirections: ["descend", "ascend"],
      dataIndex: `${DKEY}${v.name}`,
      width: cellWidth + "px",
      render: (text, record) => {
        if (
          v.name.indexOf("list") === 0 ||
          v.name.indexOf("smfDataGroupId") === 0
        ) {
          const listVal = getListForColFn(v.name, text, "dynamic1");
          return <>{cutFn(listVal)}</>;
        }
        if (v.name.indexOf("date") === 0) {
          return moment(text).format(DEFAULT_DATE_FORMAT_FOR_DB);
        }
        return <>{cutFn(text)}</>;
      },
      className: "D1title"
    };
    // ret.editable = true;
    ret._meta = {
      format: v.format
    };
    ret.editable = false;
    cols.push(ret);
  });
  if (dxLevel >= 2) {
    const DKEY = "d2_";
    view2.forEach(v => {
      const ret = {
        title: () => (
          <div className="xxxx">
            {" "}
            <span className="view-level-name l2" title="Placement">
              <PlacementIconComp w={10} h={10} fill={"darkgray"} />
            </span>{" "}
            {v.label}{" "}
          </div>
        ),
        sorter: (a, b) =>
          sortFN(a, b, v.name, "dynamic2", DKEY, getListForColFn),
        sortDirections: ["descend", "ascend"],
        dataIndex: `${DKEY}${v.name}`,
        width: cellWidth + "px",
        render: text => {
          if (v.name.indexOf("list") === 0) {
            const listVal = getListForColFn(v.name, text, "dynamic2");
            return <>{cutFn(listVal)}</>;
          }
          if (v.name.indexOf("date") === 0) {
            return moment(text).format(DEFAULT_DATE_FORMAT_FOR_DB);
          }
          return <>{cutFn(text)}</>;
        },
        className: "D2title"
      };
      ret._meta = {
        format: v.format
      };
      cols.push(ret);
    });
  }
  if (dxLevel >= 3) {
    const DKEY = "d3_";
    view3.forEach(v => {
      const ret = {
        title: () => (
          <div className="xxxx">
            {" "}
            <span className="view-level-name l3" title="Creative">
              <CreativeIconComp w={10} h={10} fill={"darkgray"} />
            </span>
            {v.label}
          </div>
        ),
        sorter: (a, b) =>
          sortFN(a, b, v.name, "dynamic3", DKEY, getListForColFn),
        sortDirections: ["descend", "ascend"],
        dataIndex: `${DKEY}${v.name}`,
        width: cellWidth + "px",
        render: text => {
          if (v.name.indexOf("list") === 0) {
            const listVal = getListForColFn(v.name, text, "dynamic3");
            return <>{cutFn(listVal)}</>;
          }
          if (v.name.indexOf("date") === 0) {
            return moment(text).format(DEFAULT_DATE_FORMAT_FOR_DB);
          }
          return <>{cutFn(text)}</>;
        },
        className: "D3title"
      };
      ret._meta = {
        format: v.format
      };
      cols.push(ret);
    });
  }

  return cols;
};

const sortFN = (a, b, vname, dynamicFullStr, dkey, getListForColFn) => {
  const name = dkey + vname;
  if (a[name] && b[name]) {
    if (vname.indexOf("list") === 0 || vname.indexOf("smfDataGroupId") === 0) {
      const listValA = getListForColFn(vname, a[name], dynamicFullStr);
      const listValB = getListForColFn(vname, b[name], dynamicFullStr);
      return listValA.localeCompare(listValB);
    } else if (vname.indexOf("int") === 0 || vname.indexOf("float") === 0) {
      return a[name] - b[name];
    } else if (vname.indexOf("date") === 0) {
      const momentA = moment(a[name]);
      const momentB = moment(b[name]);
      if (momentA > momentB) return 1;
      else if (momentA < momentB) return -1;
      else return 0;
    } else if (vname.indexOf("bool") === 0) {
      return a[name] > b[name];
    } else if (vname.indexOf("file") === 0) {
      return a[name].length() - b[name].length();
    } else {
      return a[name].localeCompare(b[name]);
    }
  } else {
    return false;
  }
};
