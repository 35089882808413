import * as types from "../types/types";

export const mediaPlanLoaded = (mediaPlan) => (dispatch) => {
  dispatch({
    type: types.MEDIAPLAN_LOADED,
    payload: {
      mediaPlan,
    },
  });
};

export const mediaPlanReload = (reload) => (dispatch) => {
  dispatch({
    type: types.MEDIAPLAN_RELOAD,
    payload: {
      reload,
    },
  });
};

window.mediaPlanReload = mediaPlanReload;

/**
 *
 * @param {int} parentId
 * @param {int} rowId
 * @param {bool} expanded
 */
export const mediaPlanExpanded = (parentId, rowId, expanded, level) => (
  dispatch
) => {
  dispatch({
    type: types.MEDIAPLAN_EXPANDED,
    payload: {
      parentId,
      rowId,
      expanded,
      level,
    },
  });
};

export const setFilters = (type, filter) => (dispatch) => {
  dispatch({
    type: types.MEDIAPLAN_FILTERS,
    payload: {
      type,
      filter,
    },
  });
};

export const setView = (view) => (dispatch) => {
  dispatch({
    type: types.MEDIAPLAN_VIEW,
    payload: {
      view,
    },
  });
};

export const setHooksValidation = (hooksValidation) => (dispatch) => {
  dispatch({
    type: types.MEDIAPLAN_HOOKS_VALIDATION,
    payload: {
      hooksValidation,
    },
  });
};
