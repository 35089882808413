/* eslint no-case-declarations: 0, import/prefer-default-export: 0 */
import * as types from '../types/types';

/**
 * Helper function to manage altered lines.
 * @param {object} state state from BPReducer
 * @param {*} action reducer action
 */
export const lineAlterationHelper = (state, action) => {
  const linesChangedClone = {
    added: [...state.linesChanged.added],
    deleted: [...state.linesChanged.deleted],
    altered: [...state.linesChanged.altered],
    all: { ...state.linesChanged.all },
    dirty: state.linesChanged.dirty,
    cloneId: 0,
  };
  const cloneLines = { ...state.lines };
  const cloneTrafficLines = { ...state.trafficLines };
  const { line, subaction } = action.payload;
  switch (subaction) {
    case types.BP_LINE_ACTION_SUB.ADD:
      if (!linesChangedClone.added.includes(line.id)) {
        linesChangedClone.added.push(line.id);
      }
      break;
    case types.BP_LINE_ACTION_SUB.CLO:
      linesChangedClone.cloneId = line.id;
      break;
    case types.BP_LINE_ACTION_SUB.CLORESET:
      linesChangedClone.cloneId = 0;
      break;
    case types.BP_LINE_ACTION_SUB.DEL:
      if (!linesChangedClone.deleted.includes(line.id)) {
        linesChangedClone.deleted.push(line.id);
      }
      if (line.blockingPlanLineId) {
        if (state.trafficLines[line.id]) {
          delete cloneTrafficLines[line.id];
        }
      } else if (state.lines[line.id]) {
        delete cloneLines[line.id];
      }
      break;
    case types.BP_LINE_ACTION_SUB.ALT:
      if (!linesChangedClone.altered.includes(line.id)) {
        linesChangedClone.altered.push(line.id);
      }
      break;
    case types.BP_LINE_ACTION_SUB.ALT_MAS:
      const { lines } = action.payload;
      Object.keys(lines).forEach((masslineId) => {
        linesChangedClone.altered.push(lines[masslineId].id);
        linesChangedClone.all[lines[masslineId].id] = lines[masslineId];
      });
      linesChangedClone.dirty = true;
      return { ...state, linesChanged: linesChangedClone };
    default:
      break;
  }
  if (subaction === types.BP_LINE_ACTION_SUB.RESET) {
    linesChangedClone.all = {};
    linesChangedClone.added = [];
    linesChangedClone.deleted = [];
    linesChangedClone.altered = [];
    linesChangedClone.dirty = false;
  } else if (subaction === types.BP_LINE_ACTION_SUB.CLORESET) {
    console.log('reset');
  } else {
    linesChangedClone.all[line.id] = line;
    linesChangedClone.dirty = true;
  }
  return {
    ...state,
    lines: cloneLines,
    linesChanged: linesChangedClone,
    trafficLines: cloneTrafficLines,
  };
};
