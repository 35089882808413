import ClientOAuth2 from "client-oauth2";
import Ajv from "ajv";
import store from "store";
import { camp } from "../camp";
import { StorageStaticDefault } from "../services/Storage";
// const store = require('store');

/**
 * Returns user object from browser storage
 */
const getCurrentUserStaticCB = cb => {
  StorageStaticDefault.getCB("currUser", cb);
};

const isAdmin = () => store.get("isAdmin");
const isRoot = () => store.get("isRoot");

/**
 * Auth Service, handles general user auth, logout, handels user data.
 */
class AuthService {
  constructor() {
    this.camp = camp;
    this.ajv = new Ajv({ allErrors: true });
  }

  /**
   * Logs user in, sets auth token, retrives current user object.
   * @todo missing error handling if getuser call fails.
   * @param  {string} username
   * @param  {string} password
   * @param  {function} callbackOk
   * @param  {function} callbackFail
   */
  login = (username, password, callbackOk, callbackFail) => {
    this.username = username;
    const oauth = new ClientOAuth2({
      clientId: process.env.REACT_APP_CLIENT_ID,
      clientSecret: process.env.REACT_APP_CLIENT_SECRET,
      accessTokenUri: process.env.REACT_APP_ACCESS_TOKEN_URI
    });
    oauth.owner
      .getToken(username, password)
      .then(retVal => {
        StorageStaticDefault.set("session", retVal.accessToken);
        this.camp.setToken(retVal.accessToken);
        this.camp
          .getUserByName({ username: this.username })
          .then(currUser => {
            this.setUser(currUser, callbackOk);
          })
          .catch(usererr => {
            console.log("eer", usererr);
          });
      })
      .catch(err => {
        callbackFail(err);
      });
  };

  /**
   * Sets user in local storage
   * @param  {object} currUser returned from db
   * @param  {function} callbackOk to update UI
   */
  setUser = (currUser, callbackOk) => {
    currUser.isRoot = currUser.roles.includes("ROLE_ROOT");
    if (currUser.isRoot) {
      currUser.isAdmin = true;
    } else {
      currUser.isAdmin = currUser.roles.includes("ROLE_ADMIN");
    }
    StorageStaticDefault.set("currUser", currUser, () => {
      store.set("isLogged", true);
      store.set("isAdmin", currUser.isAdmin);
      store.set("isRoot", currUser.isRoot);
      store.set("id", currUser.id);
      callbackOk();
    });
  };


  /**
   * Used to set token in App.jsx when page is reloaded.
   */
  setTokenOnReload = (cb = () => { }) => {
    StorageStaticDefault.getCB("session", token => {
      if (token) {
        this.camp.setToken(token);
      }
      cb();
    });
  };

  /**
   * Clears storage and calls API to logout user
   * @todo API impl is missing.
   */
  logout = () => {
    // Todo: missing API
    // this.camp.logoutUser();
    store.set("isLogged", false);
    store.clearAll();
    StorageStaticDefault.cleanAll();
  };

  /**
   * Calls API with new pwd, reset code and username
   * @param  {string} username
   * @param  {string} newPwd
   * @param  {string} code reset code
   * @param  {function} successCB
   * @param  {function} failCB
   */
  resetPassword = (newPwd, code, successCB, failCB) => {
    const body = {
      token: code,
      password: newPwd
    };
    this.camp
      .passwordReset({ body })
      .then(successCB)
      .catch(failCB);
  };

  /**
   * Calls API to request reset
   * @param  {string} username
   * @param  {function} successCB
   * @param  {function} failCB
   */
  requestPasswordReset = (username, successCB, failCB) => {
    const body = {
      username
    };
    this.camp
      .requestPasswordReset({ body })
      .then(successCB)
      .catch(failCB);
  };

  /**
   * Returns user object from browser storage
   */
  getCurrentUser = cb => getCurrentUserStaticCB(cb);

  /**
   * Checks if user is logged in
   * @return {boolean} - logged in or not.
   */
  isLogged = () => {
    return store.get("isLogged") || false;
  };

  updateUser(userObj, callbackOk, callbackFail) {
    // const userSchema = {
    //   type: 'object',
    //   properties: {
    //     firstname: { type: 'string', minLength: 1, maxLength: 34 },
    //     lastname: { type: 'string', minLength: 1, maxLength: 64 },
    //     password: { type: 'string', minLength: 1, maxLength: 255 },
    //     newpassword: { type: 'string', minLength: 1, maxLength: 255 },
    //   },
    // };
    const body = {
      userObj
    };
    this.camp
      .updateUser({ body })
      .then(callbackOk)
      .catch(callbackFail);
  }

  blockUser(userId, callbackOk, callbackFail) {
    this.camp
      .blockUser({ id: userId })
      .then(callbackOk)
      .catch(callbackFail);
  }
  unblockUser(userId, callbackOk, callbackFail) {
    this.camp
      .unblockUser({ id: userId })
      .then(callbackOk)
      .catch(callbackFail);
  }

  /**
   * Overrides all other roles!!!!
   * @param {string} userId user email
   * @param {string} role one of : ROLE_USER, ROLE_ADMIN, ROLE_ROOT
   * @param {func} callbackOk ok
   * @param {func} callbackFail fail
   */
  setUserRole(userId, role, callbackOk, callbackFail) {
    this.camp.updateUserAdmin({
      username: userId, body: {
        roles: [role]
      }
    }).then(callbackOk)
      .catch(callbackFail);
  }
}

export { isRoot, isAdmin, getCurrentUserStaticCB };

export default AuthService;

export const authServiceStatic = new AuthService();
