export const getProjectByIdSel = (state, id) => state.ProjectStore.projects[id];
export const getProjectsBudgetSel = (state, id) =>
  state.ProjectStore.projects[id].budget;

export const getCustomerByProject = (state, id) =>
  state.ProjectStore.projects[id].customer;

export const getProjectLoadingSel = state => state.ProjectStore.isLoading;

export const getProjectSMFGoals = (state, projectId) =>
  state.ProjectStore.smfGoals[projectId];

export const getProjectSMFGoalsMeta = (state, projectId) =>
  state.ProjectStore.smfGoalsMeta[projectId];

export const getProjectPlatforms = (state, id) => {
  const pro = state.ProjectStore.projects[id];
  const retVal = [];
  if (pro && pro.campaigns) {
    pro.campaigns.forEach(cam => {
      retVal.push(cam.platform);
    });
  }
  return retVal;
};

export const getProjectConstants = (state, projectId) => {
  const pro = state.ProjectStore.constants[projectId];
  return pro || {};
};
