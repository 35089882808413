import React from "react";
import PropTypes from "prop-types";
import NewProjectSubTitle from "./NewProjectSubTitle";

/**
 * Create project footer.
 * @param {bool} isLoading sets button to disable and adds spinner when true
 * @param {func} onCreateHandler returns button click
 */

const NewProjectFooter = ({ onCreateHandler, isLoading, onCancel }) => (
  <div className="NewProjectFooter">
    <div className="grid-container">
      <div className="mid grid-x">
        <div className="cell small-12 medium-6">
          <NewProjectSubTitle
            title=""
            desc="Please ensure that all fields are correct. See you inside!"
          />
          <div className="grid-x">
            <div className="cell small-12 v-center">
              <button
                className={`button create-button ld-ext-right ${
                  isLoading ? "running" : ""
                }`}
                disabled={isLoading}
                onClick={(event) => {
                  onCreateHandler(event);
                }}
              >
                Create Plan
                <div className="ld ld-ring ld-spin" />
              </button>
              {/* eslint-disable-next-line */}
              <a
                role="link"
                tabIndex={0}
                onKeyDown={() => onCancel()}
                onClick={() => onCancel()}
                className="cancel-button"
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NewProjectFooter;

NewProjectFooter.propTypes = {
  onCreateHandler: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
