import React from 'react';
import PropTypes from 'prop-types';
import { parseAndFormatDate } from '../../../utils/DateUtils';

const BlockingFileInfo = ({ file }) => (
  <span className="BlockingFileInfo">
    <span className="name">
      <strong>{file.file.name}</strong>
    </span>
    <span className="by-who u-gray" title={file.file.dateCreated}>
      (updated
      {' '}
      {parseAndFormatDate(file.file.dateCreated)}
      {' '}
by
      {' '}
      {file.user.contact.firstname}
      {' '}
      {file.user.contact.lastname}
)
    </span>
  </span>
);

export default BlockingFileInfo;

BlockingFileInfo.propTypes = {
  file: PropTypes.object.isRequired,
};
