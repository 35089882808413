import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DraggableModal,
  DraggableModalProvider
} from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import { doOpenModal } from '../../selectors/commonSelectors';
import { commonModalsCloseBound } from '../../actions/commonDataActionsUtils';
import CustomViews from './CustomViews2';


import "./CustomViews.scss";

const VIEW_NAME = "customviews";

const CustomViewsDialogInner = ({ openVars }) => (
  <div className="CustomViewsDialog">
    <DraggableModalProvider>
      <DraggableModal
        visible={openVars.open}
        onCancel={() => commonModalsCloseBound(VIEW_NAME)}
        width={1040}
        height={910}
        footer={null}
        title="Personal views"
      >
        {openVars.rest && openVars.rest.projectId && (
          <CustomViews
            onClose={() => commonModalsCloseBound(VIEW_NAME)}
            projectId={openVars.rest.projectId}
            selectedViewName={openVars.rest.selectedViewName}
            userSettings={openVars.rest.userSettings}
          />
        )}
      </DraggableModal>
    </DraggableModalProvider>
  </div>
);

const mapStateToProps = state => ({
  openVars: doOpenModal(state, VIEW_NAME)
});

const CustomViewsDialog = connect(mapStateToProps)(CustomViewsDialogInner);
export default CustomViewsDialog;

CustomViewsDialogInner.propTypes = {
  openVars: PropTypes.object.isRequired
};
