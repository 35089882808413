/* eslint prefer-destructuring: 0 */
import { camp } from '../camp';
import { globalErrorCallback } from '../utils/ErrorTracker';
import {
  projectSMFGoalsLoadedBound,
  getSMFGoalsAndActionsBound,
  getProjectSMFGoalsMetaBound,
  smfGoalsAndActionsUpdateBound,
  getCustomerByProjectBound,
  smfGoalsAndGroupsUpdateBound,
  smfSettingsDataBound,
} from '../projects/ProjectReduxUtils';

class SMFService {
  constructor() {
    this.camp = camp;
  }

  getSMFGoals = (projectId, cbOk = noop) => {
    this.camp
      .getSmf({ id: projectId })
      .then(smfGoals => {
        const adjustments = {};
        smfGoals.forEach(elem => {
          adjustments[elem.id] = elem;
        });
        projectSMFGoalsLoadedBound(projectId, adjustments);
        if (cbOk) cbOk(adjustments);
      })
      .catch(globalErrorCallback);
  };

  getSMFGoalTypes = cbOk => {
    this.camp
      .getSmfGoalTypes()
      .then(data => {
        if (cbOk) cbOk(data);
      })
      .catch(globalErrorCallback);
  };

  /**
   * @deprecated and broken
   */
  getSMFActions = cbOk => {
    this.camp
      .getSmfActions()
      .then(data => {
        if (cbOk) cbOk(data);
      })
      .catch(globalErrorCallback);
  };

  getSMFGoalsAndActions = (projectId, cbOk = noop) => {
    const customer = getCustomerByProjectBound(projectId);
    const customerId = customer.id;
    const goals = this.camp.getSmfGoalTypes();
    const actions = this.camp.getSmfActions({ id: customerId });

    Promise.all([goals, actions])
      .then(vals => {
        const goalsAdjusted = {};
        vals[0].forEach(elem => {
          goalsAdjusted[elem.id] = elem;
        });
        const actionsAdjusted = {};
        vals[1].forEach(elem => {
          actionsAdjusted[elem.id] = elem;
        });
        getSMFGoalsAndActionsBound(projectId, goalsAdjusted, actionsAdjusted);
        if (cbOk) {
          cbOk({
            goals: vals.goals,
            actions: vals.actions,
          });
        }
      })
      .catch(globalErrorCallback);
  };

  getSMFSettings = (projectId, cbOk = noop) => {
    const customer = getCustomerByProjectBound(projectId);
    const customerId = customer.id;
    console.log('[SMFService.js] customerId', customerId);
    const goals = this.camp.getSmfGoalTypes();
    const groups = this.camp.getSmfGroups({ id: customerId });
    const actions = this.camp.getSmfActions({ id: customerId });

    Promise.all([goals, actions, groups])
      .then(vals => {
        const goalsAdjusted = {};
        vals[0].forEach(elem => {
          goalsAdjusted[elem.id] = elem;
        });
        const actionsAdjusted = {};
        vals[1].forEach(elem => {
          actionsAdjusted[elem.id] = elem;
        });
        const groupsAdjusted = {};
        vals[2].forEach(elem => {
          groupsAdjusted[elem.id] = elem;
        });
        // smfGoalsAndGroupsUpdateBound(
        smfSettingsDataBound(
          projectId,
          goalsAdjusted,
          actionsAdjusted,
          groupsAdjusted
        );
        if (cbOk) {
          cbOk({
            goals: vals[0],
            actions: vals[1],
            groups: vals[2],
          });
        }
      })
      .catch(globalErrorCallback);
  };

  postSMF = (projectId, value, smfDataGroupId, actionId, cbOk = noop) => {
    const params = {
      id: projectId,
      body: [
        {
          projectId,
          value,
          smfDataGroupId,
          actionId,
        },
      ],
    };
    this.camp
      .postSmf(params)
      .then(data => cbOk(data))
      .catch(globalErrorCallback);
  };

  putSMF = (
    projectId,
    goalEntityId,
    value,
    smfDataGroupId,
    actionId,
    cbOk = noop
  ) => {
    const params = {
      id: projectId,
      body: [
        {
          projectId,
          value,
          smfDataGroupId,
          actionId,
          id: goalEntityId,
        },
      ],
    };
    this.camp
      .putSmf(params)
      .then(data => cbOk(data))
      .catch(globalErrorCallback);
  };

  postAction = (projectId, actionName, cbOk = noop) => {
    const parms = {
      id: 1, // this required by swagger, but has no use.
      body: [
        {
          name: actionName,
        },
      ],
    };
    this.camp
      .postSmfAction(parms)
      .then(data => cbOk(data))
      .catch(globalErrorCallback);
  };

  postActionNPushToReducer = (projectId, actionName, cbOk = noop) => {
    const parms = {
      body: [
        {
          name: actionName,
        },
      ],
    };
    this.camp
      .postSmfAction(parms)
      .then(data => {
        const meta = getProjectSMFGoalsMetaBound(projectId);
        meta.smfActions[data[0].id] = data[0];
        smfGoalsAndActionsUpdateBound(projectId, null, meta.smfActions);
        cbOk(data);
      })
      .catch(globalErrorCallback);
  };

  putAction = (projectId, actionId, actionName, cbOk = noop) => {
    const parms = {
      body: [
        {
          name: actionName,
          id: actionId,
        },
      ],
    };
    this.camp
      .putSmfAction(parms)
      .then(data => cbOk(data))
      .catch(globalErrorCallback);
  };

  // postGroup = (projectId, name, cbOk) => {
  //   const customer = getCustomerByProjectBound(projectId);
  //   const customerId = customer.id;

  //   const params = {
  //     id: customerId,
  //     body: { name },
  //   };
  //   this.camp
  //     .postSmfGroup(params)
  //     .then(data => cbOk(data))
  //     .catch(globalErrorCallback);
  // };

  postGroup = (projectId, name, goalTypeId, cbOk, cbFail) => {
    const customer = getCustomerByProjectBound(projectId);
    const customerId = customer.id;

    const params = {
      id: customerId,
      body: [{ name, goalTypeId }],
    };
    this.camp
      .postSmfGroup(params)
      .then(data => {
        //TODO get rid of Bounds
        // const meta = getProjectSMFGoalsMetaBound(projectId);
        // meta.smfGroups[data[0].id] = data[0];
        // smfGoalsAndGroupsUpdateBound(projectId, null, meta.smfGroups);
        cbOk(data);
      })
      .catch(err => {
        globalErrorCallback(err, cbFail);
      });
  };

  getGroups = (projectId, cbOk, cbFail) => {
    const customer = getCustomerByProjectBound(projectId);
    const customerId = customer.id;

    const params = {
      id: customerId,
    };

    this.camp
      .getSmfGroups(params)
      .then(data => {
        const meta = getProjectSMFGoalsMetaBound(projectId);
        meta.smfGroups[data[0].id] = data[0];
        smfGoalsAndGroupsUpdateBound(projectId, null, meta.smfGroups);
        cbOk(data);
      })
      .catch(err => {
        globalErrorCallback(err, cbFail);
      });
  };

  putActionNPushToReducer = (projectId, actionId, actionName, cbOk = noop) => {
    const parms = {
      body: [
        {
          name: actionName,
          id: actionId,
        },
      ],
    };
    this.camp
      .putSmfAction(parms)
      .then(data => {
        const meta = getProjectSMFGoalsMetaBound(projectId);
        meta.smfActions[data[0].id] = data[0];
        smfGoalsAndActionsUpdateBound(projectId, null, meta.smfActions);
        cbOk(data);
      })
      .catch(globalErrorCallback);
  };
}

export default SMFService;

export const SMFServiceStatic = new SMFService();

const noop = () => {};
