import React from "react";
import PropTypes from "prop-types";
import Menu from "antd/lib/menu";
import {
  EyeOffIcon,
  EyeOnIcon,
  EditIcon,
  ImportIcon,
} from "../../../shared/Icons";
import { BP_TABLE, LABEL_COLORS, COLORRESET } from "../../../shared/Constants";

class BPTableContextMenu extends React.Component {
  render() {
    return (
      <Menu
        onClick={(e) => this.props.handleClick(e, this.props.target)}
        mode="vertical"
        className="BPContextMenu"
      >
        <Menu.Item key={BP_TABLE.ICON_CLICK_HIDE}>
          <EyeOffIcon className="icon" /> Hide
        </Menu.Item>
        <Menu.Item key={BP_TABLE.ICON_CLICK_UNHIDE}>
          <EyeOnIcon className="icon" /> Insert Hidden
        </Menu.Item>
        <Menu.Item key={BP_TABLE.ICON_CLICK_EDIT}>
          <EditIcon className="icon" /> View/Edit Metas
        </Menu.Item>
        <Menu.Item key={BP_TABLE.ICON_CLICK_IMPORT_COL}>
          <ImportIcon className="icon" /> Import Column Data
        </Menu.Item>
        <Menu.SubMenu className="color-menu" title="Color">
          <Menu.Item key={BP_TABLE.ICON_CLICK_COLOR + "." + COLORRESET}>
            <span className={COLORRESET}>RESET</span>
          </Menu.Item>
          {LABEL_COLORS.map((c, i) => {
            return (
              <Menu.Item key={BP_TABLE.ICON_CLICK_COLOR + "." + c}>
                <span className={c + " box"}>color {i + 1}</span>
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      </Menu>
    );
  }
}

export default BPTableContextMenu;

// Target is injected by <ContextMenu />
BPTableContextMenu.defaultProps = {
  target: {},
};

BPTableContextMenu.propTypes = {
  handleClick: PropTypes.func.isRequired,
  target: PropTypes.object,
};
