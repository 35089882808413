import React from "react";
import PropTypes from "prop-types";
import Tabs from "antd/lib/tabs";
import FasthookCallbackPanel from "./FasthookCallbackPanel";
import { FasthookCallbackNew } from "./FasthookCallbackNew";

const { TabPane } = Tabs;

class FasthooksCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      event: {},
      hook: {},
      eventId: 0,
      hookId: 0,
      optionsFastloop: [],
      activeKey: "0",
    };
  }
  onTabChange = (e) => {
    console.log("[FasthooksCallback.jsx] e", e);
    this.setState({ activeKey: e });
  };
  addNewCallBack = () => {
    const activeKey = this.props.hook.tabs.length;
    this.setState({ activeKey: activeKey.toString() });
    this.props.addNewCallBack();
  };
  render() {
    return (
      <div className="FasthookCallbackPanel">
        <Tabs
          onChange={this.onTabChange}
          activeKey={this.state.activeKey}
          type="card"
        >
          {this.props.hook.tabs &&
            this.props.hook.tabs.map((cb) => {
              return (
                <TabPane
                  tab={cb.data.name}
                  key={cb.id}
                  onChange={(e) => this.onTabChange(e)}
                >
                  <FasthookCallbackPanel
                    onValuesCB={this.props.onValuesCB}
                    event={this.props.event}
                    hook={this.props.hook}
                    callback={cb.data}
                    viewCols={this.props.viewCols}
                    optionsDropDown={this.props.optionsDropDown}
                  />
                </TabPane>
              );
            })}

          <TabPane tab="New..." key="1000">
            <FasthookCallbackNew addNewCb={() => this.addNewCallBack()} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default FasthooksCallback;

FasthooksCallback.propTypes = {
  event: PropTypes.object.isRequired,
  hook: PropTypes.object.isRequired,
  onValuesCB: PropTypes.func.isRequired,
  addNewCallBack: PropTypes.func.isRequired,
  viewCols: PropTypes.object.isRequired,
  optionsDropDown: PropTypes.array.isRequired,
};
