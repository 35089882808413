import React, { Component } from "react";
import "antd/dist/antd.css";
import { Form, Input, notification, Row, Col } from "antd";

import NextFinish from "./NextFinish";

export default class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactInfo: this.props.contactInfo
    };
  }

  getInfo = (str, type) => {
    const contact = this.state.contactInfo.find(c => c.type === type);
    if (!contact) return "";
    return contact[str];
  };

  setInfo = (str, type, e) => {
    const { value } = e.target;
    const newContactInfo = [...this.state.contactInfo];
    if (!newContactInfo || !newContactInfo.some(c => c.type === type)) {
      const newContact = { type: type };
      newContactInfo.push(newContact);
    }
    newContactInfo.map(c => {
      if (c.type !== type) return c;
      c[str] = value;
      return c;
    });
    this.setState({ contactInfo: newContactInfo });
  };

  showMessage = (type, title, descr) => {
    let dur = 0;
    if (type === "success") {
      dur = 2;
    }
    notification[type]({
      message: title,
      description: descr,
      duration: dur,
      placement: "bottomRight"
    });
  };

  onSave = () => {
    this.showMessage(
      "success",
      "Success!",
      "Your client`s contact information has been updated."
    );
  };

  onFail = () => {
    this.showMessage(
      "error",
      "Error!",
      "Something went wrong! Information did not save, please check information and try again."
    );
  };

  render() {
    const { client, changeTab, tabKey } = this.props;
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={9}>
            <Form layout="vertical">
              Accounting Contact Information
              <br />
              <br />
              <Form.Item label="First Name" style={{ width: 250 }}>
                <Input
                  value={this.getInfo("firstname", "ACCOUNTING")}
                  onChange={e => this.setInfo("firstname", "ACCOUNTING", e)}
                />
              </Form.Item>
              <Form.Item label="Last Name" style={{ width: 250 }}>
                <Input
                  value={this.getInfo("lastname", "ACCOUNTING")}
                  onChange={e => this.setInfo("lastname", "ACCOUNTING", e)}
                />
              </Form.Item>
              <Form.Item label="Email" style={{ width: 250 }}>
                <Input
                  value={this.getInfo("email", "ACCOUNTING")}
                  onChange={e => this.setInfo("email", "ACCOUNTING", e)}
                />
              </Form.Item>
              <Form.Item label="Phone" style={{ width: 250 }}>
                <Input
                  value={this.getInfo("phone", "ACCOUNTING")}
                  onChange={e => this.setInfo("phone", "ACCOUNTING", e)}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={9}>
            <Form layout="vertical">
              Primary User Contact Information
              <br />
              <br />
              <Form.Item label="First Name" style={{ width: 250 }}>
                <Input
                  value={this.getInfo("firstname", "PRIMARY")}
                  onChange={e => this.setInfo("firstname", "PRIMARY", e)}
                />
              </Form.Item>
              <Form.Item label="Last Name" style={{ width: 250 }}>
                <Input
                  value={this.getInfo("lastname", "PRIMARY")}
                  onChange={e => this.setInfo("lastname", "PRIMARY", e)}
                />
              </Form.Item>
              <Form.Item label="Email" style={{ width: 250 }}>
                <Input
                  value={this.getInfo("email", "PRIMARY")}
                  onChange={e => this.setInfo("email", "PRIMARY", e)}
                />
              </Form.Item>
              <Form.Item label="Phone" style={{ width: 250 }}>
                <Input
                  value={this.getInfo("phone", "PRIMARY")}
                  onChange={e => this.setInfo("phone", "PRIMARY", e)}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <div align="right">
          <NextFinish
            changeTab={changeTab}
            client={client}
            tabKey={tabKey}
            tabType={"ACCOUNT"}
            edittedData={this.state.contactInfo}
            onSave={() => this.onSave()}
            onFail={() => this.onFail()}
          />
        </div>
      </div>
    );
  }
}
