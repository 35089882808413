import reduxStore from '../reduxStore';
import {
  projectLoaded,
  projectLoad,
  projectSave,
  smfGoalsLoaded,
  smfGoalsAndActions,
  smfGoalsAndActionsUpdate,
  smfGoalsAndGroupsUpdate,
  constantsLoaded,
  smfSettingsData,
} from '../actions/ProjectActions';
import ProjectsService from './ProjectsService';
import * as selectors from '../selectors/ProjectSelectors';

export const projectLoadedBound = project =>
  reduxStore.dispatch(projectLoaded(project));
export const projectLoadBound = () => reduxStore.dispatch(projectLoad());
export const projectSaveBound = () => reduxStore.dispatch(projectSave());
export const constantsLoadedBound = (...rest) =>
  reduxStore.dispatch(constantsLoaded(...rest));

export const updateProjectBudget = (projectId, value, callbackOk) => {
  const ps = new ProjectsService();
  ps.updateProjectGeneralByKeyVal(projectId, 'budget', value, databack => {
    callbackOk(databack);
  });
};

export const getProjectByIdSelBound = id =>
  selectors.getProjectByIdSel(reduxStore.getState(), id);

export const getCustomerByProjectBound = id =>
  selectors.getCustomerByProject(reduxStore.getState(), id);

export const getProjectPlatformsBound = id =>
  selectors.getProjectPlatforms(reduxStore.getState(), id);

export const getProjectSMFGoalsBound = projectId =>
  selectors.getProjectSMFGoals(reduxStore.getState(), projectId);

export const projectSMFGoalsLoadedBound = (projectId, smfGoals) =>
  reduxStore.dispatch(smfGoalsLoaded(projectId, smfGoals));

export const smfSettingsDataBound = (projectId, smfActions, smfGoals) => {
  reduxStore.dispatch(smfSettingsData(projectId, smfActions, smfGoals));
};

export const getSMFGoalsAndActionsBound = (projectId, smfActions, smfGoals) =>
  reduxStore.dispatch(smfGoalsAndActions(projectId, smfActions, smfGoals));

export const smfGoalsAndActionsUpdateBound = (
  projectId,
  smfGoals,
  smfActions
) =>
  reduxStore.dispatch(
    smfGoalsAndActionsUpdate(projectId, smfGoals, smfActions)
  );

export const smfGoalsAndGroupsUpdateBound = (
  projectId,
  smfGoals,
  smfActions,
  smfGroups
) =>
  reduxStore.dispatch(
    smfGoalsAndGroupsUpdate(projectId, smfGoals, smfActions, smfGroups)
  );

export const getgetProjectLoadingSelBound = () =>
  selectors.getProjectLoadingSel(reduxStore.getState());

export const getProjectsBudgetSelBound = id =>
  selectors.getProjectsBudgetSel(reduxStore.getState(), id);

export const getProjectSMFGoalsMetaBound = projectId =>
  selectors.getProjectSMFGoalsMeta(reduxStore.getState(), projectId);

export const getProjectConstantsBound = projectId =>
  selectors.getProjectConstants(reduxStore.getState(), projectId);
