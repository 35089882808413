import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Notify from "../utils/Notify";
import NewProjectHeader from "./newProject/NewProjectHeader";
import NewProjectPeople from "./newProject/NewProjectPeople";
import NewProjectMilestones from "./newProject/NewProjectMilestones";
// import NewProjectTags from './newProject/NewProjectTags';
import NewProjectNotes from "./newProject/NewProjectNotes";
import NewProjectFooter from "./newProject/NewProjectFooter";
import ProjectsService from "./ProjectsService";

import "./NewProject.scss";

/**
 * New projects page, handles creation and validation.
 */
class NewProject extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectsService();
    this.state = {
      valid: {},
      showInvalidTopMessage: false,
      isLoading: false,
      redirectToNew: false,
      redirectToAll: false,
      newId: 0,
    };
    this.newProjectBluePrint = {
      name: "",
      budget: 0,
      docket: "",
      docketNumbers: "",
      reportingUrl: "",
      customerId: 0,
      milestones: [],
      note: "",
      roles: [
        {
          role: "",
          username: "",
        },
      ],
      tags: [],
    };
  }

  componentDidMount() {
    document.getElementById("body").classList.add("section_new-plan");
  }

  componentWillUnmount() {
    document.getElementById("body").classList.remove("section_new-plan");
  }

  onCancel = () => {
    this.setState({ redirectToAll: true });
  };

  onCreateHandler = () => {
    const validation = this.projectService.validateProject(
      this.newProjectBluePrint
    );

    if (validation.cnt > 0) {
      this.setState({
        ...this.state,
        valid: validation.retVal,
        showInvalidTopMessage: true,
        isLoading: false,
      });
      window.scrollTo(0, 0);
      Notify.fireError(`Check entered data, correct the items in red and don't
      make any sudden movements.`);
    } else {
      this.setState({
        ...this.state,
        valid: {},
        showInvalidTopMessage: false,
        isLoading: true,
      });
      const onlyTagIds = [];
      const plaforms = [];
      this.newProjectBluePrint.tags.forEach((elem) => {
        if (elem.category !== "platform") {
          onlyTagIds.push(elem.tag);
        } else {
          plaforms.push(elem.tag);
        }
      });
      this.newProjectBluePrint.tags = onlyTagIds;
      this.projectService.createProject(
        this.newProjectBluePrint,
        (databack) => {
          // Disabling for now, in subject of change
          // this.projectService.setPlatforms(
          //   databack.id,
          //   plaforms,
          //   () => {
          //     window.scrollTo(0, 0);
          //     this.setState({
          //       ...this.state,
          //       redirectToNew: true,
          //       newId: databack.id,
          //     });
          //   },
          //   () => {
          //     this.setState({ showInvalidTopMessage: true, isLoading: false });
          //   }
          // );
          window.scrollTo(0, 0);
          this.setState({
            ...this.state,
            redirectToNew: true,
            newId: databack.id,
          });
        },
        () => {
          window.scrollTo(0, 0);
          this.setState({ showInvalidTopMessage: true, isLoading: false });
        }
      );
    }
  };

  nameHandler = (nameState) => {
    this.newProjectBluePrint.name = nameState.name;
    this.newProjectBluePrint.budget = parseFloat(nameState.budget);
    this.newProjectBluePrint.docket = nameState.shortcode + nameState.docket;
    this.newProjectBluePrint.docketNumbers = nameState.docket;
    this.newProjectBluePrint.reportingUrl = nameState.reportingUrl;
    this.newProjectBluePrint.customerId = nameState.selectedCustomerOption
      ? nameState.selectedCustomerOption.id
      : null;
  };

  noteCallback = (noteState) => {
    this.newProjectBluePrint.note = noteState;
  };

  handleRoleChange = (selectedOption) => {
    this.newProjectBluePrint.roles = selectedOption;
  };

  handleTagsChange = (selectedTagsOption) => {
    this.newProjectBluePrint.tags = selectedTagsOption;
  };

  handleMilestoneChange = (selectedMilestones) => {
    this.newProjectBluePrint.milestones = selectedMilestones;
  };

  render() {
    if (this.state.redirectToNew) {
      return <Redirect to={`/projects/${this.state.newId}/?new=1`} />;
    }
    if (this.state.redirectToAll) {
      return <Redirect to="/projects/all/" />;
    }
    return (
      <div className="NewProject">
        <div className="grid-container full">
          <NewProjectHeader
            valid={this.state.valid}
            showInvalidTopMessage={this.state.showInvalidTopMessage}
            handler={this.nameHandler}
          />
          <NewProjectPeople
            valid={this.state.valid}
            onRoleChange={this.handleRoleChange}
          />
          <NewProjectMilestones
            valid={this.state.valid}
            onMilestoneChange={this.handleMilestoneChange}
          />
          {/* <NewProjectTags
            valid={this.state.valid}
            onTagsChange={this.handleTagsChange}
          /> */}
          <NewProjectNotes noteCallback={this.noteCallback} />
          <NewProjectFooter
            isLoading={this.state.isLoading}
            onCreateHandler={this.onCreateHandler}
            onCancel={this.onCancel}
          />
        </div>
      </div>
    );
  }
}

export default NewProject;
