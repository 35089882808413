/* eslint-disable */
/* This library is cloned from handsontable/RuleJS, modernized for camp */
import Formula from '@lfx/formulajs';

import Parser from './parserModule';

const formulasEngine = function() {
  /**
   * object instance
   */
  let instance = this;

  /**
   * current version
   * @type {string}
   */
  const version = '0.0.5.Noise';

  /**
   * parser object delivered by jison library
   * @type {Parser|*|{}}
   */
  let parser = {};

  const FormulaParser = handler => {
    const formulaLexer = () => {};
    formulaLexer.prototype = Parser.lexer;

    const formulaParser = function() {
      this.lexer = new formulaLexer();
      this.yy = {};
    };

    formulaParser.prototype = Parser;
    const newParser = new formulaParser();
    newParser.setObj = obj => {
      newParser.yy.obj = obj;
    };

    newParser.yy.parseError = (str, hash) => {
      //      if (!((hash.expected && hash.expected.indexOf("';'") >= 0) &&
      //        (hash.token === "}" || hash.token === "EOF" ||
      //          parser.newLine || parser.wasNewLine)))
      //      {
      //        throw new SyntaxError(hash);
      //      }
      throw {
        name: 'Parser error',
        message: str,
        prop: hash,
      };
    };

    newParser.yy.handler = handler;

    return newParser;
  };

  /**
   * Exception object
   * @type {{errors: {type: string, output: string}[], get: get}}
   */
  const Exception = {
    /**
     * error types
     */
    errors: [
      { type: 'NULL', output: '#NULL' },
      { type: 'DIV_ZERO', output: '#DIV/0!' },
      { type: 'VALUE', output: '#VALUE!' },
      { type: 'REF', output: '#REF!' },
      { type: 'NAME', output: '#NAME?' },
      { type: 'NUM', output: '#NUM!' },
      { type: 'NOT_AVAILABLE', output: '#N/A!' },
      { type: 'ERROR', output: '#ERROR' },
      { type: 'NEED_UPDATE', output: '#NEED_UPDATE' },
    ],
    /**
     * get error by type
     * @param {String} type
     * @returns {*}
     */
    get(type) {
      const error = Exception.errors.filter(
        item => item.type === type || item.output === type
      )[0];

      return error ? error.output : null;
    },
  };

  /**
   * matrix collection for each form, contains cache of all form element
   */
  const Matrix = function() {
    /**
     * single item (cell) object
     * @type {{name: string, formula: string, value: string, error: string, deps: Array, formulaEdit: boolean}}
     */
    const item = {
      name: '',
      formula: '',
      value: '',
      error: '',
      deps: [],
      formulaEdit: false,
    };

    /**
     * array of items
     * @type {Array}
     */
    this.data = [];

    /**
     * form elements, which can be parsed
     * @type {string[]}
     */
    // var formElements = ['input[type=text]', '[data-formula]'];

    // var listen = function() {
    //   if (document.activeElement && document.activeElement !== document.body) {
    //     document.activeElement.blur();
    //   } else if (!document.activeElement) {
    //     //IE
    //     document.body.focus();
    //   }
    // };

    /**
     * get item from data array
     * @param {String} name
     * @returns {*}
     */
    this.getItem = name =>
      instance.matrix.data.filter(item => item.name === name)[0];

    /**
     * remove item from data array
     * @param {String} name
     */
    this.removeItem = name => {
      instance.matrix.data = instance.matrix.data.filter(
        item => item.name !== name
      );
    };

    /**
     * remove items from data array in col
     * @param {Number} col
     */
    this.removeItemsInCol = col => {
      instance.matrix.data = instance.matrix.data.filter(
        item => item.col !== col
      );
    };

    /**
     * remove items from data array in row
     * @param {Number} row
     */
    this.removeItemsInRow = row => {
      instance.matrix.data = instance.matrix.data.filter(
        item => item.row !== row
      );
    };

    /**
     * remove items from data array below col
     * @param col
     */
    this.removeItemsBelowCol = col => {
      instance.matrix.data = instance.matrix.data.filter(
        item => item.col < col
      );
    };

    /**
     * remove items from data array below row
     * @param row
     */
    this.removeItemsBelowRow = row => {
      instance.matrix.data = instance.matrix.data.filter(
        item => item.row < row
      );
    };

    /**
     * update item properties
     * @param {Object|String} item or name
     * @param {Object} props
     */
    this.updateItem = (item, props) => {
      if (instance.utils.isString(item)) {
        item = instance.matrix.getItem(item);
      }

      if (item && props) {
        for (const p in props) {
          if (item[p] && instance.utils.isArray(item[p])) {
            if (instance.utils.isArray(props[p])) {
              props[p].forEach(i => {
                if (!item[p].includes(i)) {
                  item[p].push(i);
                }
              });
            } else {
              if (!item[p].includes(props[p])) {
                item[p].push(props[p]);
              }
            }
          } else {
            item[p] = props[p];
          }
        }
      }
    };

    /**
     * add item to data array
     * @param {Object} item
     */
    this.addItem = item => {
      const cellName = item.name;
      const coords = instance.utils.cellCoords(cellName);

      item.row = coords.row;
      item.col = coords.col;

      const cellExist = instance.matrix.data.filter(
        cell => cell.name === cellName
      )[0];

      if (!cellExist) {
        instance.matrix.data.push(item);
      } else {
        instance.matrix.updateItem(cellExist, item);
      }

      return instance.matrix.getItem(cellName);
    };

    /**
     * get references items to column
     * @param {Number} col
     * @returns {Array}
     */
    this.getRefItemsToColumn = col => {
      const result = [];

      if (!instance.matrix.data.length) {
        return result;
      }

      instance.matrix.data.forEach(item => {
        if (item.deps) {
          const deps = item.deps.filter(cell => {
            const alpha = instance.utils.getCellAlphaNum(cell).alpha;
            const num = instance.utils.toNum(alpha);

            return num >= col;
          });

          if (deps.length > 0 && !result.includes(item.name)) {
            result.push(item.name);
          }
        }
      });

      return result;
    };

    this.getRefItemsToRow = row => {
      const result = [];

      if (!instance.matrix.data.length) {
        return result;
      }

      instance.matrix.data.forEach(item => {
        if (item.deps) {
          const deps = item.deps.filter(cell => {
            const num = instance.utils.getCellAlphaNum(cell).num;
            return num > row;
          });

          if (deps.length > 0 && !result.includes(item.name)) {
            result.push(item.name);
          }
        }
      });

      return result;
    };

    /**
     * update element item properties in data array
     * @param {Element} element
     * @param {Object} props
     */
    this.updateElementItem = (element, props) => {
      const name = element.name;
      const item = instance.matrix.getItem(name);

      instance.matrix.updateItem(item, props);
    };

    /**
     * get cell dependencies
     * @param {String} name
     * @returns {Array}
     */
    this.getDependencies = name => {
      /**
       * get dependencies by element
       * @param {String} name
       * @returns {Array}
       */
      const getDependencies = name => {
        const filtered = instance.matrix.data.filter(cell => {
          if (cell.deps) {
            return cell.deps.includes(name);
          }
        });

        const deps = [];
        filtered.forEach(cell => {
          if (!deps.includes(cell.name)) {
            deps.push(cell.name);
          }
        });

        return deps;
      };

      const allDependencies = [];

      /**
       * get total dependencies
       * @param {String} name
       */
      const getTotalDependencies = name => {
        const deps = getDependencies(name);

        if (deps.length) {
          deps.forEach(refName => {
            if (!allDependencies.includes(refName)) {
              allDependencies.push(refName);

              const item = instance.matrix.getItem(refName);
              if (item.deps.length) {
                getTotalDependencies(refName);
              }
            }
          });
        }
      };

      getTotalDependencies(name);

      return allDependencies;
    };

    /**
     * get total element cell dependencies
     * @param {Element} element
     * @returns {Array}
     */
    this.getElementDependencies = element =>
      instance.matrix.getDependencies(element.name);

    /**
     * recalculate refs cell
     * @param {Element} element
     */
    const recalculateElementDependencies = element => {
      const allDependencies = instance.matrix.getElementDependencies(element);
      const name = element.name;

      allDependencies.forEach(refName => {
        const item = instance.matrix.getItem(refName);
        if (item && item.formula) {
          // var refElement = document.getElementByName(refName);
          const refElement = instance.elements[refName];
          calculateElementFormula(item.formula, refElement);
        }
      });
    };

    /**
     * calculate element formula
     * @param {String} formula
     * @param {Element} element
     * @returns {Object}
     */
    var calculateElementFormula = (formula, element) => {
      // to avoid double translate formulas, update item data in parser
      const parsed = parse(formula, element);

      const value = parsed.result;
      const error = parsed.error;
      // nodeName = element.nodeName.toUpperCase();

      instance.matrix.updateElementItem(element, {
        value,
        error,
      });

      // if (['INPUT'].indexOf(nodeName) === -1) {
      //   element.innerText = value || error;
      // }

      element.value = value || error;

      return parsed;
    };

    /**
     * register new found element to matrix
     * @param {Element} element
     * @returns {Object}
     */
    const registerElementInMatrix = element => {
      const name = element.name;
      const formula = element.formula;

      if (formula) {
        // add item with basic properties to data array
        instance.matrix.addItem({
          name,
          formula,
        });

        calculateElementFormula(formula, element);
      }
    };

    // Not using here, left for reference
    // /**
    //  * register events for elements
    //  * @param element
    //  */
    // var registerElementEvents = function(element) {
    //   var name = element.getAttribute('name');

    //   // on db click show formula
    //   element.addEventListener('dblclick', function() {
    //     var item = instance.matrix.getItem(name);

    //     if (item && item.formula) {
    //       item.formulaEdit = true;
    //       element.value = '=' + item.formula;
    //     }
    //   });

    //   element.addEventListener('blur', function() {
    //     var item = instance.matrix.getItem(name);

    //     if (item) {
    //       if (item.formulaEdit) {
    //         element.value = item.value || item.error;
    //       }

    //       item.formulaEdit = false;
    //     }
    //   });

    //   // if pressed ESC restore original value
    //   element.addEventListener('keyup', function(event) {
    //     switch (event.keyCode) {
    //       case 13: // ENTER
    //       case 27: // ESC
    //         // leave cell
    //         listen();
    //         break;
    //     }
    //   });

    //   // re-calculate formula if ref cells value changed
    //   element.addEventListener('change', function() {
    //     // reset and remove item
    //     instance.matrix.removeItem(name);

    //     // check if inserted text could be the formula
    //     var value = element.value;

    //     if (value[0] === '=') {
    //       element.setAttribute('data-formula', value.substr(1));
    //       registerElementInMatrix(element);
    //     }

    //     // get ref cells and re-calculate formulas
    //     recalculateElementDependencies(element);
    //   });
    // };

    this.callChange = element => {
      // reset and remove item
      instance.matrix.removeItem(element.name);
      recalculateElementDependencies(element);
    };

    this.setData = elements => {
      instance.elements = elements;
    }

    this.depsInFormula = item => {
      const formula = item.formula;
      let deps = item.deps;

      if (deps) {
        deps = deps.filter(name => formula.includes(name));

        return deps.length > 0;
      }

      return false;
    };

    /**
     * scan the form and build the calculation matrix
     */
    this.scan = () => {
      Object.values(instance.elements).forEach($item => {
        registerElementInMatrix($item);
      });
    };
  };

  /**
   * utils methods
   * @type {{isArray: isArray, toNum: toNum, toChar: toChar, cellCoords: cellCoords}}
   */
  const utils = {
    /**
     * check if value is array
     * @param value
     * @returns {boolean}
     */
    isArray(value) {
      return Object.prototype.toString.call(value) === '[object Array]';
    },

    /**
     * check if value is number
     * @param value
     * @returns {boolean}
     */
    isNumber(value) {
      return Object.prototype.toString.call(value) === '[object Number]';
    },

    /**
     * check if value is string
     * @param value
     * @returns {boolean}
     */
    isString(value) {
      return Object.prototype.toString.call(value) === '[object String]';
    },

    /**
     * check if value is function
     * @param value
     * @returns {boolean}
     */
    isFunction(value) {
      return Object.prototype.toString.call(value) === '[object Function]';
    },

    /**
     * check if value is undefined
     * @param value
     * @returns {boolean}
     */
    isUndefined(value) {
      return Object.prototype.toString.call(value) === '[object Undefined]';
    },

    /**
     * check if value is null
     * @param value
     * @returns {boolean}
     */
    isNull(value) {
      return Object.prototype.toString.call(value) === '[object Null]';
    },

    /**
     * check if value is set
     * @param value
     * @returns {boolean}
     */
    isSet(value) {
      return (
        !instance.utils.isUndefined(value) && !instance.utils.isNull(value)
      );
    },

    /**
     * check if value is cell
     * @param {String} value
     * @returns {Boolean}
     */
    isCell(value) {
      return value.match(/^[A-Za-z]+[0-9]+/) ? true : false;
    },

    /**
     * get row name and column number
     * @param cell
     * @returns {{alpha: string, num: number}}
     */
    getCellAlphaNum(cell) {
      const num = cell.match(/\d+$/);
      const alpha = cell.replace(num, '');

      return {
        alpha,
        num: parseInt(num[0], 10),
      };
    },

    /**
     * change row cell index A1 -> A2
     * @param {String} cell
     * @param {Number} counter
     * @returns {String}
     */
    changeRowIndex(cell, counter) {
      const alphaNum = instance.utils.getCellAlphaNum(cell);
      const alpha = alphaNum.alpha;
      const col = alpha;
      let row = parseInt(alphaNum.num + counter, 10);

      if (row < 1) {
        row = 1;
      }

      return `${col}${row}`;
    },

    /**
     * change col cell index A1 -> B1 Z1 -> AA1
     * @param {String} cell
     * @param {Number} counter
     * @returns {String}
     */
    changeColIndex(cell, counter) {
      const alphaNum = instance.utils.getCellAlphaNum(cell);
      const alpha = alphaNum.alpha;

      let col = instance.utils.toChar(
        parseInt(instance.utils.toNum(alpha) + counter, 10)
      );

      let row = alphaNum.num;

      if (!col || col.length === 0) {
        col = 'A';
      }

      const fixedCol = alpha[0] === '$' || false;
      const fixedRow = alpha[alpha.length - 1] === '$' || false;

      col = (fixedCol ? '$' : '') + col;
      row = (fixedRow ? '$' : '') + row;

      return `${col}${row}`;
    },

    changeFormula(formula, delta, change) {
      if (!delta) {
        delta = 1;
      }

      return formula.replace(/(\$?[A-Za-z]+\$?[0-9]+)/g, match => {
        const alphaNum = instance.utils.getCellAlphaNum(match);
        const alpha = alphaNum.alpha;
        let num = alphaNum.num;

        if (instance.utils.isNumber(change.col)) {
          num = instance.utils.toNum(alpha);

          if (change.col <= num) {
            return instance.utils.changeColIndex(match, delta);
          }
        }

        if (instance.utils.isNumber(change.row)) {
          if (change.row < num) {
            return instance.utils.changeRowIndex(match, delta);
          }
        }

        return match;
      });
    },

    /**
     * update formula cells
     * @param {String} formula
     * @param {String} direction
     * @param {Number} delta
     * @returns {String}
     */
    updateFormula(formula, direction, delta) {
      let type;
      let counter;

      // left, right -> col
      if (['left', 'right'].includes(direction)) {
        type = 'col';
      } else if (['up', 'down'].includes(direction)) {
        type = 'row';
      }

      // down, up -> row
      if (['down', 'right'].includes(direction)) {
        counter = delta * 1;
      } else if (['up', 'left'].includes(direction)) {
        counter = delta * -1;
      }

      if (type && counter) {
        return formula.replace(/(\$?[A-Za-z]+\$?[0-9]+)/g, match => {
          const alpha = instance.utils.getCellAlphaNum(match).alpha;

          const fixedCol = alpha[0] === '$' || false;
          const fixedRow = alpha[alpha.length - 1] === '$' || false;

          if (type === 'row' && fixedRow) {
            return match;
          }

          if (type === 'col' && fixedCol) {
            return match;
          }

          return type === 'row'
            ? instance.utils.changeRowIndex(match, counter)
            : instance.utils.changeColIndex(match, counter);
        });
      }

      return formula;
    },

    /**
     * convert string char to number e.g A => 0, Z => 25, AA => 27
     * @param {String} chr
     * @returns {Number}
     */
    toNum(chr) {
      //      chr = instance.utils.clearFormula(chr).split('');
      //
      //      var base = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      //          i, j, result = 0;
      //
      //      for (i = 0, j = chr.length - 1; i < chr.length; i += 1, j -= 1) {
      //        result += Math.pow(base.length, j) * (base.indexOf(chr[i]));
      //      }
      //
      //      return result;

      chr = instance.utils.clearFormula(chr);
      const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let i;
      let j;
      let result = 0;

      for (i = 0, j = chr.length - 1; i < chr.length; i += 1, j -= 1) {
        result += base.length ** j * (base.indexOf(chr[i]) + 1);
      }

      if (result) {
        --result;
      }

      return result;
    },

    /**
     * convert number to string char, e.g 0 => A, 25 => Z, 26 => AA
     * @param {Number} num
     * @returns {String}
     */
    toChar(num) {
      let s = '';

      while (num >= 0) {
        s = String.fromCharCode((num % 26) + 97) + s;
        num = Math.floor(num / 26) - 1;
      }

      return s.toUpperCase();
    },

    /**
     * get cell coordinates
     * @param {String} cell A1
     * @returns {{row: Number, col: number}}
     */
    cellCoords(cell) {
      const num = cell.match(/\d+$/);
      const alpha = cell.replace(num, '');

      return {
        row: parseInt(num[0], 10) - 1,
        col: instance.utils.toNum(alpha),
      };
    },

    /**
     * remove $ from formula
     * @param {String} formula
     * @returns {String|void}
     */
    clearFormula(formula) {
      return formula.replace(/\$/g, '');
    },

    /**
     * translate cell coordinates to merged form {row:0, col:0} -> A1
     * @param coords
     * @returns {string}
     */
    translateCellCoords(coords) {
      return `${instance.utils.toChar(coords.col)}${parseInt(
        coords.row + 1,
        10
      )}`;
    },

    /**
     * iterate cell range and get theirs indexes and values
     * @param {Object} startCell ex.: {row:1, col: 1}
     * @param {Object} endCell ex.: {row:10, col: 1}
     * @param {Function=} callback
     * @returns {{index: Array, value: Array}}
     */
    iterateCells(startCell, endCell, callback) {
      const result = {
        index: [], // list of cell index: A1, A2, A3
        value: [], // list of cell value
      };

      let cols = {
        start: 0,
        end: 0,
      };

      if (endCell.col >= startCell.col) {
        cols = {
          start: startCell.col,
          end: endCell.col,
        };
      } else {
        cols = {
          start: endCell.col,
          end: startCell.col,
        };
      }

      let rows = {
        start: 0,
        end: 0,
      };

      if (endCell.row >= startCell.row) {
        rows = {
          start: startCell.row,
          end: endCell.row,
        };
      } else {
        rows = {
          start: endCell.row,
          end: startCell.row,
        };
      }

      for (let column = cols.start; column <= cols.end; column++) {
        for (let row = rows.start; row <= rows.end; row++) {
          const cellIndex = instance.utils.toChar(column) + (row + 1);
          const cellValue = instance.helper.cellValue.call(this, cellIndex);

          result.index.push(cellIndex);
          result.value.push(cellValue);
        }
      }

      if (instance.utils.isFunction(callback)) {
        return callback.apply(callback, [result]);
      } else {
        return result;
      }
    },

    sort(rev) {
      return (a, b) => (a < b ? -1 : a > b ? 1 : 0) * (rev ? -1 : 1);
    },
  };

  /**
   * helper with methods using by parser
   * @type {{number: number, numberInverted: numberInverted, mathMatch: mathMatch, callFunction: callFunction}}
   */
  const helper = {
    /**
     * list of supported formulas
     */
    SUPPORTED_FORMULAS: [
      'ABS',
      'ACCRINT',
      'ACOS',
      'ACOSH',
      'ACOTH',
      'AND',
      'ARABIC',
      'ASIN',
      'ASINH',
      'ATAN',
      'ATAN2',
      'ATANH',
      'AVEDEV',
      'AVERAGE',
      'AVERAGEA',
      'AVERAGEIF',
      'BASE',
      'BESSELI',
      'BESSELJ',
      'BESSELK',
      'BESSELY',
      'BETADIST',
      'BETAINV',
      'BIN2DEC',
      'BIN2HEX',
      'BIN2OCT',
      'BINOMDIST',
      'BINOMDISTRANGE',
      'BINOMINV',
      'BITAND',
      'BITLSHIFT',
      'BITOR',
      'BITRSHIFT',
      'BITXOR',
      'CEILING',
      'CEILINGMATH',
      'CEILINGPRECISE',
      'CHAR',
      'CHISQDIST',
      'CHISQINV',
      'CODE',
      'COMBIN',
      'COMBINA',
      'COMPLEX',
      'CONCATENATE',
      'CONFIDENCENORM',
      'CONFIDENCET',
      'CONVERT',
      'CORREL',
      'COS',
      'COSH',
      'COT',
      'COTH',
      'COUNT',
      'COUNTA',
      'COUNTBLANK',
      'COUNTIF',
      'COUNTIFS',
      'COUNTIN',
      'COUNTUNIQUE',
      'COVARIANCEP',
      'COVARIANCES',
      'CSC',
      'CSCH',
      'CUMIPMT',
      'CUMPRINC',
      'DATE',
      'DATEVALUE',
      'DAY',
      'DAYS',
      'DAYS360',
      'DB',
      'DDB',
      'DEC2BIN',
      'DEC2HEX',
      'DEC2OCT',
      'DECIMAL',
      'DEGREES',
      'DELTA',
      'DEVSQ',
      'DOLLAR',
      'DOLLARDE',
      'DOLLARFR',
      'E',
      'EDATE',
      'EFFECT',
      'EOMONTH',
      'ERF',
      'ERFC',
      'EVEN',
      'EXACT',
      'EXPONDIST',
      'FALSE',
      'FDIST',
      'FINV',
      'FISHER',
      'FISHERINV',
      'IF',
      'INT',
      // 'ISBLANK',
      'ISEVEN',
      'ISODD',
      'LN',
      'LOG',
      'LOG10',
      'MAX',
      'MAXA',
      'MEDIAN',
      'MIN',
      'MINA',
      'MOD',
      'NOT',
      'ODD',
      'OR',
      'PI',
      'POWER',
      'ROUND',
      'ROUNDDOWN',
      'ROUNDUP',
      'SIN',
      'SINH',
      'SPLIT',
      'SQRT',
      'SQRTPI',
      'SUM',
      'SUMIF',
      'SUMIFS',
      'SUMPRODUCT',
      'SUMSQ',
      'SUMX2MY2',
      'SUMX2PY2',
      'SUMXMY2',
      'TAN',
      'TANH',
      'TRUE',
      'TRUNC',
      'XOR',
    ],

    /**
     * get number
     * @param  {Number|String} num
     * @returns {Number}
     */
    number(num) {
      switch (typeof num) {
        case 'number':
          return num;
        case 'string':
          if (!isNaN(num)) {
            return num.includes('.') ? parseFloat(num) : parseInt(num, 10);
          }
      }

      return num;
    },

    /**
     * get string
     * @param {Number|String} str
     * @returns {string}
     */
    string(str) {
      return str.substring(1, str.length - 1);
    },

    /**
     * invert number
     * @param num
     * @returns {Number}
     */
    numberInverted(num) {
      return this.number(num) * -1;
    },

    /**
     * match special operation
     * @param {String} type
     * @param {String} exp1
     * @param {String} exp2
     * @returns {*}
     */
    specialMatch(type, exp1, exp2) {
      let result;

      switch (type) {
        case '&':
          result = exp1.toString() + exp2.toString();
          break;
      }
      return result;
    },

    /**
     * match logic operation
     * @param {String} type
     * @param {String|Number} exp1
     * @param {String|Number} exp2
     * @returns {Boolean} result
     */
    logicMatch(type, exp1, exp2) {
      let result;

      switch (type) {
        case '=':
          result = exp1 === exp2;
          break;

        case '>':
          result = exp1 > exp2;
          break;

        case '<':
          result = exp1 < exp2;
          break;

        case '>=':
          result = exp1 >= exp2;
          break;

        case '<=':
          result = exp1 === exp2;
          break;

        case '<>':
          result = exp1 != exp2;
          break;

        case 'NOT':
          result = exp1 != exp2;
          break;
      }

      return result;
    },

    /**
     * match math operation
     * @param {String} type
     * @param {Number} number1
     * @param {Number} number2
     * @returns {*}
     */
    mathMatch(type, number1, number2) {
      let result;

      number1 = helper.number(number1);
      number2 = helper.number(number2);

      if (isNaN(number1) || isNaN(number2)) {
        if (number1[0] === '=' || number2[0] === '=') {
          throw Error('NEED_UPDATE');
        }

        throw Error('VALUE');
      }

      switch (type) {
        case '+':
          result = number1 + number2;
          break;
        case '-':
          result = number1 - number2;
          break;
        case '/':
          result = number1 / number2;
          if (result == Infinity) {
            throw Error('DIV_ZERO');
          } else if (isNaN(result)) {
            throw Error('VALUE');
          }
          break;
        case '*':
          result = number1 * number2;
          break;
        case '^':
          result = number1 ** number2;
          break;
      }

      return result;
    },

    /**
     * call function from formula
     * @param {String} fn
     * @param {Array} args
     * @returns {*}
     */
    callFunction(fn, args) {
      fn = fn.toUpperCase();
      args = args || [];

      if (instance.helper.SUPPORTED_FORMULAS.includes(fn)) {
        if (instance.formulas[fn]) {
          return instance.formulas[fn].apply(this, args);
        }
      }

      if (instance.customFormulas[fn]) {
        return instance.customFormulas[fn].apply(this, args);
      }

      throw Error('NAME');
    },

    /**
     * get variable from formula
     * @param {Array} args
     * @returns {*}
     */
    callVariable(args = []) {
      let str = args[0];

      if (str) {
        str = str.toUpperCase();
        if (instance.formulas[str]) {
          return typeof instance.formulas[str] === 'function'
            ? instance.formulas[str].apply(this, args)
            : instance.formulas[str];
        }
      }

      throw Error('NAME');
    },

    /**
     * Get cell value
     * @param {String} cell => A1 AA1
     * @returns {*}
     */
    cellValue(cell) {
      let value;
      const fnCellValue = instance.custom.cellValue;
      const element = this;
      const item = instance.matrix.getItem(cell);

      // check if custom cellValue fn exists
      if (instance.utils.isFunction(fnCellValue)) {
        const cellCoords = instance.utils.cellCoords(cell);

        var cellName = instance.utils.translateCellCoords({
          row: element.row,
          col: element.col,
        });

        // get value
        value = item ? item.value : fnCellValue(cellCoords.row, cellCoords.col);

        if (instance.utils.isNull(value)) {
          value = 0;
        }

        if (cellName) {
          //update dependencies
          instance.matrix.updateItem(cellName, { deps: [cell] });
        }
      } else {
        // get value
        // value = item ? item.value : document.getElementByIs(cell).value;
        value = item ? item.value : instance.elements[cell].value;

        //update dependencies
        instance.matrix.updateElementItem(element, { deps: [cell] });
      }

      // check references error
      if (item && item.deps) {
        if (item.deps.includes(cellName)) {
          throw Error('REF');
        }
      }

      // check if any error occurs
      if (item && item.error) {
        throw Error(item.error);
      }

      // return value if is set
      if (instance.utils.isSet(value)) {
        const result = instance.helper.number(value);

        return !isNaN(result) ? result : value;
      }

      // cell is not available
      throw Error('NOT_AVAILABLE');
    },

    /**
     * Get cell range values
     * @param {String} start cell A1
     * @param {String} end cell B3
     * @returns {Array}
     */
    cellRangeValue(start, end) {
      const fnCellValue = instance.custom.cellValue;
      const coordsStart = instance.utils.cellCoords(start);
      const coordsEnd = instance.utils.cellCoords(end);
      const element = this;

      // iterate cells to get values and indexes
      const cells = instance.utils.iterateCells.call(
        this,
        coordsStart,
        coordsEnd
      );

      const result = [];

      // check if custom cellValue fn exists
      if (instance.utils.isFunction(fnCellValue)) {
        const cellName = instance.utils.translateCellCoords({
          row: element.row,
          col: element.col,
        });

        //update dependencies
        instance.matrix.updateItem(cellName, { deps: cells.index });
      } else {
        //update dependencies
        instance.matrix.updateElementItem(element, { deps: cells.index });
      }

      result.push(cells.value);
      return result;
    },

    /**
     * Get fixed cell value
     * @param {String} name
     * @returns {*}
     */
    fixedCellValue(name) {
      name = name.replace(/\$/g, '');
      return instance.helper.cellValue.call(this, name);
    },

    /**
     * Get fixed cell range values
     * @param {String} start
     * @param {String} end
     * @returns {Array}
     */
    fixedCellRangeValue(start, end) {
      start = start.replace(/\$/g, '');
      end = end.replace(/\$/g, '');

      return instance.helper.cellRangeValue.call(this, start, end);
    },
  };

  /**
   * parse input string using parser
   * @returns {Object} {{error: *, result: *}}
   * @param formula
   * @param element
   */
  var parse = (formula, element) => {
    let result = null;
    let error = null;

    try {
      parser.setObj(element);
      result = parser.parse(formula);

      const name = element.name;

      // if (element instanceof HTMLElement) {
      //   name = element.getAttribute('name');
      // } else if (element && element.name) {
      //   name = element.name;
      // }

      const deps = instance.matrix.getDependencies(name);

      if (deps.includes(name)) {
        result = null;

        deps.forEach(name => {
          instance.matrix.updateItem(name, {
            value: null,
            error: Exception.get('REF'),
          });
        });

        throw Error('REF');
      }
    } catch (ex) {
      const message = Exception.get(ex.message);

      if (message) {
        error = message;
      } else {
        error = Exception.get('ERROR');
      }

      //console.debug(ex.prop);
      //debugger;
      //error = ex.message;
      //error = Exception.get('ERROR');
    }

    return {
      error,
      result,
    };
  };

  /**
   * initial method, create formulas, parser and matrix objects
   */

   /**
    * @returns {data - matrix data, onElemChange(elem) - fn to fire when elem changes, scan() rescan all values }
    * @param elems object of data
    * @param customFormulas object of custom fromulas
    */
  const init = function(elems, customFormulas) {
    instance = this;

    parser = new FormulaParser(instance);
    instance.elements = elems;
    instance.formulas = Formula;
    instance.matrix = new Matrix();

    instance.custom = {};
    // instance.customFormulas = {
    //   C_DIFF_IN_DAYS: function() {
    //     return arguments[1] - arguments[0];
    //   },
    // };
    instance.customFormulas = customFormulas || {};

    if (elems) {
      instance.matrix.scan();
    }

    return {
      data: instance.matrix.data,
      onElemChange: instance.matrix.callChange,
      scan: instance.matrix.scan,
      setData: instance.matrix.setData,
      _matrix: instance.matrix,
    };
  };

  return {
    init,
    version,
    utils,
    helper,
    parse,
  };
};

export default formulasEngine;
