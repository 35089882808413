import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import { ErrorIcon } from '../Icons';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    width: '24px',
    height: '24px',
  },
  spinnerSize: 24,
  errorIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    width: '24px',
    height: '24px',
    color: red[500],
  },
});

const CircularIntegration = ({
  loading,
  success,
  onSave,
  classes,
  label,
  disable,
  hideErrorIndicator,
  danger,
}) => {
  const buttonClassname = classNames({
    [classes.buttonSuccess]: success,
    saveBtn: true,
  });
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          color={danger ? 'secondary' : 'primary'}
          className={buttonClassname}
          disabled={disable || loading}
          onClick={() => onSave()}
        >
          {label}
        </Button>
        {loading && (
          <CircularProgress
            size={classes.spinnerSize}
            className={classes.buttonProgress}
          />
        )}
        {!hideErrorIndicator && (
          <span>{disable && <ErrorIcon className={classes.errorIcon} />}</span>
        )}
      </div>
    </div>
  );
};

CircularIntegration.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  disable: PropTypes.bool,
  hideErrorIndicator: PropTypes.bool,
  danger: PropTypes.bool,
};

CircularIntegration.defaultProps = {
  success: false,
  loading: false,
  disable: false,
  hideErrorIndicator: false,
  danger: false,
};

export default withStyles(styles)(CircularIntegration);
