import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import "./ProjectConstants.scss";
import { projectConstantsServiceStatic } from "../../../../services/ProjectConstantsService";
import SaveButton from "../../../../shared/saveWithLoadingBtn/SaveWithLoadingBtn";
import { UUID } from "../../../../utils/StringUtils";

class ProjectConstants extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, data: [], showNewBtn: true };
    this.lastOrder = 1;
  }

  componentDidMount() {
    this.setState({ loading: true });
    projectConstantsServiceStatic.getValues(
      this.props.project.id,
      (data) => {
        const dataWithOrder = {};
        Object.values(data).forEach((val, idx) => {
          dataWithOrder[val.id] = { order: idx, ...val };
          this.lastOrder = idx;
        });
        this.setState({
          loading: false,
          data: dataWithOrder,
          showNewBtn: true,
        });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  onSave(line) {
    const transLine = {
      value: line.value,
      name: line.name,
      type: line.type || "text",
    };
    if (!line._isNew) {
      transLine.id = line.id;
    }
    const clone = { ...this.state.data };
    clone[line.id].loading = true;
    this.setState({ data: clone });

    projectConstantsServiceStatic.setValues(
      this.props.project.id,
      transLine,
      () => {
        this.componentDidMount();
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  onChange(event, id) {
    const { name, value } = event.target;
    const data = { ...this.state.data };
    if (name === "name") {
      data[id][name] = value.toUpperCase();
    } else {
      data[id][name] = value;
    }
    this.setState({ data });
  }

  onDelete(line) {
    const clone = { ...this.state.data };
    clone[line.id].deleteLoading = true;
    this.setState({ data: clone });
    projectConstantsServiceStatic.deleteValue(
      this.props.project.id,
      line,
      () => {
        this.componentDidMount();
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  insertNew = (all) => {
    const id = UUID();
    const clone = { ...all };
    this.lastOrder = +1;
    clone[id] = { id, _isNew: true, _order: this.lastOrder };
    return clone;
  };

  addNew() {
    const dataIns = this.insertNew(this.state.data);
    this.setState({ data: dataIns, showNewBtn: false });
  }

  render() {
    const sortedList = Object.values(this.state.data).sort(
      (a, b) => a._order - b._order
    );
    return (
      <div data-loading={this.state.loading} className="ProjectConstants">
        <h6>Project Constants</h6>
        <div className="grid-x">
          <div className="cell small-12">
            {sortedList && (
              <Table
                onSave={(line) => this.onSave(line)}
                onDelete={(line) => this.onDelete(line)}
                onChange={(e, o) => this.onChange(e, o)}
                list={sortedList}
              />
            )}
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              {this.state.showNewBtn && (
                <Button onClick={() => this.addNew()}> Add New </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectConstants;

ProjectConstants.propTypes = {
  project: PropTypes.object.isRequired,
};

const Table = ({ list, onSave, onChange, onDelete }) => (
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Value</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {list.map((ls) => (
        <Line
          key={ls.id}
          lineData={ls}
          onChange={(e, o) => onChange(e, o)}
          onSave={(val) => onSave(val)}
          onDelete={(val) => onDelete(val)}
        />
      ))}
    </tbody>
  </table>
);

Table.propTypes = {
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
};

const Line = ({ lineData, onSave, onChange, onDelete }) => (
  <tr>
    <td>
      <input
        type="text"
        name="name"
        autoComplete="off"
        onChange={(e) => onChange(e, lineData.id)}
        value={lineData.name || ""}
      />
    </td>
    <td>
      <select
        value={lineData.type}
        name="type"
        onChange={(e) => onChange(e, lineData.id)}
      >
        <option value="text">Text</option>
        <option value="number">Number</option>
      </select>
    </td>
    <td>
      <input
        type={lineData.type === "number" ? "number" : "text"}
        name="value"
        onChange={(e) => onChange(e, lineData.id)}
        value={lineData.value || ""}
      />
    </td>
    <td className="flex-container">
      <div className="grid-x">
        <div className="cell small-6">
          <SaveButton
            label="Save"
            loading={lineData.loading}
            success={lineData.success}
            onSave={() => onSave(lineData)}
          />
        </div>
        <div className="cell small-6">
          <SaveButton
            label="Delete"
            loading={lineData.deleteLoading}
            success={lineData.success}
            onSave={() => onDelete(lineData)}
          />
        </div>
      </div>
    </td>
  </tr>
);

Line.defaultProps = {
  lineData: { name: "", value: "", type: "" },
};

Line.propTypes = {
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  lineData: PropTypes.object,
};
