import React from 'react';
import PropTypes from 'prop-types';
import './MetasEditor.scss';
import { getProjectConstantsBound } from '../../../projects/ProjectReduxUtils';

const ConstantsList = ({ projectId }) => {
  const constants = getProjectConstantsBound(projectId);
  return (
    <div className="ConstantsList">
      {Object.values(constants).map(con => (
        <div key={con.id}>
          {con.name} &#61; &#34;
          {con.value}
          &#34;
        </div>
      ))}
      <div />
    </div>
  );
};
export default ConstantsList;

ConstantsList.propTypes = {
  projectId: PropTypes.number.isRequired,
};
