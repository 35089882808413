import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import TagManager from "react-gtm-module";
import Layout from "antd/lib/layout";
import { authServiceStatic } from "../../auth/AuthService";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import MainNavView from "../../projects/mainNavView/MainNavView";
import ProjectNavView from "../../projects/mainNavView/projectNavView/ProjectNavView";
import SoonNavView from "../../projects/mainNavView/SoonNavView";
import PeopleNavView from "../../users/peopleNavView/PeopleNavView";
import ClientsNavView from "../../clients/clientsNavView/ClientsNavView";
import ClientEditNavView from "../../clients/clientEditPage/ClientEditNavView";
import ProfileNavView from "../../pages/profile/profileNavView/ProfileNavView";
import ReportsPageNavView from "../../reports/reportsPage/reportsNavView/ReportsPageNavView";
import SystemNotifications from "../notifications/SystemNotifications";
import { setErrorUserContext, gcpErrorHandler } from "../../utils/ErrorTracker";
import UpdateAppTracker from "../../services/UpdateService";
import {
  MaintenanceEnabledContainer,
  MaintenanceMessage,
} from "../../pages/maintenanceage/MaintenanceComp";

const STYLES = {
  layout: {
    // height: 'calc(100vh - 60px)',
    height: "100vh",
  },
  header: { height: "112px", lineHeight: "initial", padding: "0" },
  footer: { padding: "0 0" },
};

/**
 * Generic DashboardLayout with sidebar.
 * Used to load all private views here.
 */
class DashboardLayout extends Component {
  constructor() {
    super();
    this.state = {
      user: {},
      hasError: false,
      collapsed: true,
      showFooter: false,
      // uiVersion: '...',
      // apiVersion: '...',
    };
  }
  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  componentDidMount() {
    authServiceStatic.getCurrentUser((user) => {
      this.setState({ user });
      setErrorUserContext(user);
      const tagManagerArgs = {
        dataLayer: {
          userId: user.id,
        },
        dataLayerName: "PageDataLayer",
      };
      TagManager.dataLayer(tagManagerArgs);
    });
    // this would be good to use, but it drops router params so nothing gets loaded.
    // need more reaserch.
    this.childWithProp = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        sample: "this is from top",
      })
    );
    document.body.classList.add("login-screen");
  }

  componentWillUnmount() {
    document.body.classList.remove("login-screen");
  }

  getTopBar() {
    let retVal = "";
    switch (this.props.topBar) {
      case "projects":
        retVal = <MainNavView />;
        break;
      case "project":
        retVal = <ProjectNavView />;
        break;
      case "bp":
        retVal = <ProjectNavView forBP />;
        break;
      case "soon":
        retVal = <SoonNavView />;
        break;
      case "users":
        retVal = <PeopleNavView />;
        break;
      case "clients":
        retVal = <ClientsNavView />;
        break;
      case "client":
        retVal = <ClientEditNavView />;
        break;
      case "profilePage":
        retVal = <ProfileNavView />;
        break;
      case "reportsPage":
        retVal = <ReportsPageNavView />;
        break;
      default:
        retVal = <MainNavView />;
    }
    return retVal;
  }

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
      this.setState({
        hasError: true,
      });
    });
    gcpErrorHandler.report(error);
  }
  onShowFooter = () => {
    this.setState({ showFooter: !this.state.showFooter });
  };

  render() {
    return (
      <div>
        <MaintenanceEnabledContainer>
          <MaintenanceMessage />
        </MaintenanceEnabledContainer>
        <UpdateAppTracker />
        <SystemNotifications />
        <Layout style={STYLES.layout}>
          <Sidebar onShowFooter={() => this.onShowFooter()} />
          <Layout>
            <Layout.Header style={STYLES.header}>
              {this.getTopBar()}
            </Layout.Header>
            <Layout.Content>
              {this.state.hasError ? (
                <div className="cell small-12">
                  <div className="u-padding-both-sides-25">
                    <br />
                    <br />
                    <h6>
                      Sorry something went wrong... Fastloop team are looking
                      into it!
                      <br />
                      Please refresh page and try again.
                    </h6>
                    <br />
                    <br />
                    <img alt="error" src="https://http.cat/500" />
                  </div>
                </div>
              ) : (
                <div className="cell small-12">{this.props.children}</div>
              )}
            </Layout.Content>
          </Layout>
        </Layout>
        {this.state.showFooter && (
          <Footer logged version={this.state.version} />
        )}
      </div>
    );
  }
}

export default DashboardLayout;

/**
 * @param {object} children component to render.
 * @param {string} topBar top bar to render.
 */
DashboardLayout.propTypes = {
  children: PropTypes.object.isRequired,
  topBar: PropTypes.string.isRequired,
};
