import React from 'react';
import PropTypes from 'prop-types';
import { bpServiceStatic } from '../../bp/BPService';
import Spin from 'antd/lib/spin';
import Button from 'antd/lib/button';
import { bytesToSize } from '../../../utils/StringUtils';

const MB_SIZE_BYTES = 5000000;

class BPFileViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: null,
      spin: true,
      forceShowButton: false,
      fileSize: '',
    };
  }
  componentDidMount() {
    bpServiceStatic.getFileMeta(this.props.hash, dataArray => {
      const data = dataArray[0];
      const fileSize = bytesToSize(data.size);
      this.setState({ fileSize, spin: false });
      console.log(
        '[BPFileViewer.jsx] (data.size <= MB_SIZE_BYTES)',
        data.size >= MB_SIZE_BYTES,
        data.size,
        MB_SIZE_BYTES
      );
      if (data.size >= MB_SIZE_BYTES) {
        const exts = ['jpeg', 'jpg', 'jpe', 'png', 'gif', 'svg'];
        if (!exts.includes(data.ext)) {
          this.setState({ notImg: true });
        } else {
          this.setState({ forceShowButton: true });
        }
      } else {
        this.loadImage();
      }
    });
  }
  loadImage = () => {
    this.setState({ spin: true });
    bpServiceStatic.getFileJson(this.props.hash, data => {
      console.log('[BPFileUploader.jsx] down', data);
      const first = data[0];
      let img = null;
      let name = '';

      img = 'data:image/png;base64,' + first.content;
      name = first.name;

      this.setState({ spin: false, img: img, name, forceShowButton: false });
    });
  };
  forceLoad = () => {
    this.loadImage();
  };
  render() {
    return (
      <div className="BPFileViewer">
        <Spin spinning={this.state.spin}>
          {!this.state.notImg && (
            <>
              <span>
                Name: {this.state.name} Size: {this.state.fileSize}
              </span>
              <br />
              <br />
              <img src={this.state.img} alt="" />
            </>
          )}
          <br />
          {this.state.forceShowButton && (
            <Button onClick={this.forceLoad}>
              File is over 5MB, load anyway?
            </Button>
          )}
          {!this.state.spin && (
            <>
              {this.state.notImg && <>Seems like this file is not an image.</>}
            </>
          )}
        </Spin>
      </div>
    );
  }
}

export default BPFileViewer;

BPFileViewer.propTypes = {
  hash: PropTypes.string.isRequired,
};
