import React from "react";
import PropTypes from "prop-types";
import "./ProfilePicture.scss";

const ProfilePicture = ({ user, bigger }) => {
  if (user && user.contact) {
    const biggerTmp = bigger ? "bigger" : "";
    const name = `${user.contact.firstname} ${user.contact.lastname}`;
    const initials =
      user.contact.firstname.substr(0, 1) + user.contact.lastname.substr(0, 1);

    return (
      <span className="ProfilePicture" title={name}>
        <span className={`profs ${biggerTmp}`}>{initials}</span>
      </span>
    );
  }
  return <span />;
};

export default ProfilePicture;

ProfilePicture.defaultProps = {
  bigger: false,
};

ProfilePicture.propTypes = {
  user: PropTypes.object.isRequired,
  bigger: PropTypes.bool,
};
