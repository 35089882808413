import axios from "axios";
import FileSaver from "file-saver";
import parse from "date-fns/parse";
import compareAsc from "date-fns/compare_asc";
import compareDesc from "date-fns/compare_desc";
import JSZip from "jszip";
import moment from "moment";
import { camp } from "../camp";
import UsersService from "../services/UsersService";
import {
  capitalizeFirstLetter,
  titleCaseString,
  UUID,
} from "../utils/StringUtils";
import { MILESTONES, FILE_TYPES } from "../shared/Constants";
import { globalErrorCallback } from "../utils/ErrorTracker";
import EventManager from "../shared/EventManager";
import { projectLoadedBound, projectLoadBound } from "./ProjectReduxUtils";
import { commonDataUsersBound } from "../actions/commonDataActionsUtils";

class ProjectsService {
  constructor() {
    this.camp = camp;
    this.usersService = new UsersService();
  }

  /**
   * Gets tags from api
   * @param {func} callbackOk success callback @returns \{clients: [value, label],
   * years: [value, label], category: [value, label], scopes: [value, label] \}
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getTags = (callbackOk, callbackFail) => {
    this.camp
      .getProjectTags()
      .then((tags) => {
        const clients = [];
        const years = [];
        const scopes = [];
        tags.forEach((element) => {
          if (element.category === "client") {
            clients.push({ value: element.id, label: element.tag });
          }
          if (element.category === "year") {
            years.push({ value: element.id, label: element.tag });
          }
          if (element.category === "scope") {
            scopes.push({ value: element.id, label: element.tag });
          }
        });
        callbackOk({
          clients,
          years,
          scopes,
        });
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  getPlatforms = (callbackOk, callbackFail) => {
    this.camp
      .getPlatforms()
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  setPlatforms = (projectId, plaforms, callbackOk, callbackFail) => {
    const params = { id: projectId, body: plaforms };
    this.camp
      .postProjectPlatform(params)
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  setStatus = (projectId, status, callbackOk, callbackFail) => {
    const params = {
      id: projectId, body: {
        status
      }
    };
    this.camp
      .updateProject(params)
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  }

  /** Gets all users
   * @param {func} callbackOk @returns [{value, label}] - user id, label
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getUsers = (callbackOk, callbackFail) => {
    this.usersService.getUsers((users) => {
      // const sortUsers = users.sort((a, b) => a.firstname.localeCompare(b.firstname));
      const filteredUsers = users
        .filter((u) => !u.blocked)
        .map((elem) => ({
          value: elem.id,
          label: `${elem.contact.firstname} ${elem.contact.lastname}`,
        }));
      callbackOk(filteredUsers);
      commonDataUsersBound(users);
    }, callbackFail);
  };

  /**
   * Gets Customer list from API
   * @param {func} callbackOk returns Customers array
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getCustomers = (callbackOk, callbackFail) => {
    this.camp
      .getCustomers()
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  /**
   * Gets a Customer from API
   * @param num customer id
   * @param {func} callbackOk returns Customer
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getCustomer = (id, callbackOk, callbackFail) => {
    const params = { id };
    this.camp
      .getCustomer(params)
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  /**
   * Gets a Customer's users from API
   * @param num customer id
   * @param {func} callbackOk returns Customer's users array
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getCustomerUsers = (id, callbackOk, callbackFail) => {
    const params = { id };
    this.camp
      .getCustomerUsers(params)
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  /**
   * Gets a Customer's contacts from API
   * @param num customer id
   * @param {func} callbackOk returns Customer's contacts array
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getCustomerContacts = (id, callbackOk, callbackFail) => {
    const params = { id };
    this.camp
      .getCustomerContacts(params)
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  /**
   * Gets a Customer's credentials from API
   * @param num customer id
   * @param {func} callbackOk returns Customer's credentials array
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getCustomerCredentials = (id, callbackOk, callbackFail) => {
    const params = { id };
    this.camp
      .getCustomerCredentials(params)
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  /**
   * Gets roles from API
   * @param {func} callbackOk returns roles @returns [{value, label}] - role_name, nice value
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getRoles = (callbackOk, callbackFail) => {
    this.camp
      .getProjectRoles()
      .then((roles) => {
        const filteredRoles = roles.map((elem) => ({
          value: elem.id,
          label: capitalizeFirstLetter(elem.role),
        }));
        callbackOk(filteredRoles);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Gets information from customer from API
   * @param {func} callbackOk returns customer(contacts, users, credentials), user roles, and platforms
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getCustomerInfo = (id, callbackOk, callbackFail) => {
    const params = { id };
    Promise.all([
      this.camp.getCustomer(params),
      this.camp.getCustomerContacts(params),
      this.camp.getCustomerUsers(params),
      this.camp.getCustomerCredentials(params),
      this.camp.getPlatforms(),
    ])
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  /**
   * Creates new client
   * @param {object} info - object containing new customer info
   * @param {func} callbackOk
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  createCustomer = (info, callbackOk, callbackFail) => {
    const params = { body: info };
    this.camp
      .postCustomer(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Updates customer info
   * @param num customer id
   * @param {object} info - object containing customer info to update
   * @param {func} callbackOk
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  updateCustomer = (id, info, callbackOk, callbackFail) => {
    const params = { id, body: info };
    this.camp
      .putCustomer(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Deletes customer
   * @param num customer id
   * @param {func} callbackOk
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  deleteCustomer = (id, callbackOk, callbackFail) => {
    const params = { id };
    this.camp
      .deleteCustomer(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Updates customer's partners and advertisers
   * @param {array} data - array of partners or advertires that need to editted, added, and/or deleted
   * @param {func} callbackOk
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  updatePartnersAdvertisers = (data, callbackOk, callbackFail) => {
    const promiseQ = [];
    data.forEach((pa) => {
      if (pa.isNew) {
        const params = { body: pa };
        delete pa.isNew;
        delete pa.id;
        const promise = this.camp.postCustomer(params);
        promiseQ.push(promise);
      } else if (pa.toEdit) {
        const params = { id: pa.id, body: pa };
        delete pa.toEdit;
        delete pa.id;
        const promise = this.camp.putCustomer(params);
        promiseQ.push(promise);
      } else if (pa.toDelete) {
        const params = { id: pa.id };
        const promise = this.camp.deleteCustomer(params);
        promiseQ.push(promise);
      }
    });

    Promise.all(promiseQ)
      .then(() => {
        callbackOk();
      })
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  /**
   * Updates customer's users
   * @param num customer id
   * @param {array} users - array of customer users that need to editted, added, and/or deleted
   * @param {func} callbackOk
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  updateCustomerUsers = (id, users, callbackOk, callbackFail) => {
    const params = { id, body: users };
    this.camp
      .putCustomerUsers(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Updates customer's Accounting Contact info
   * @param num customer id
   * @param {array} info - array of contact info for customer that needs to editted.
   * @param {func} callbackOk
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  updateCustomerContacts = (id, info, callbackOk, callbackFail) => {
    const params = { id, body: info };
    this.camp
      .putCustomerContacts(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Updates customer's credentials
   * @param num customer id
   * @param {array} data - array of customer credntials that need to editted, added, and/or deleted
   * @param {func} callbackOk
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  updateCustomerCredentials = (id, data, callbackOk, callbackFail) => {
    const promiseQ = [];
    const newData = [];
    data.forEach((cred) => {
      if (cred.toDelete) {
        const params = { id: cred.id };
        const promise = this.camp.deleteCustomerCredential(params);
        promiseQ.push(promise);
      } else {
        newData.push(cred);
      }
    });
    if (!!newData) {
      const dataToSave = newData.map((cred) => {
        if (!cred.isNew) return cred;
        delete cred.id;
        delete cred.isNew;
        delete cred.toDelete;
        return cred;
      });
      const params = { id: id, body: dataToSave };
      const promise = this.camp.putCustomerCredentials(params);
      promiseQ.push(promise);
    }

    Promise.all(promiseQ)
      .then(() => {
        callbackOk();
      })
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  /**
   * Delete customer's credentials
   * @param num credential id
   * @param {func} callbackOk
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  deleteCustomerCredential = (id, callbackOk, callbackFail) => {
    const params = { id };
    this.camp
      .deleteCustomerCredential(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Gets milestones from API, adds start and end date as required, sets to current date.
   * @param {func} callbackOk [{value, label}] - name_from_db, date object
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getMilestones = (callbackOk, callbackFail) => {
    const today = new Date();
    // this is ridiculous, but linter looses it's mind if it's in one line.
    const tmr = 24 * 60 * 60 * 1000 * 30;
    const tomorrow = new Date(today.getTime() + tmr);
    this.camp
      .getProjectMilestones()
      .then((stones) => {
        const filteredStones = stones.map((elem) => {
          let required = false;
          if (elem.id === 1 || elem.id === 2) {
            required = true;
          }
          return {
            value: elem.id,
            label: elem.milestone,
            originalVal: elem,
            selectedDate: {
              selectedDay: elem.id === 2 ? tomorrow : today,
            },
            required,
            order: elem.id,
            uuid: UUID(),
            showEndDateError: false,
          };
        });
        const sorted = filteredStones.sort((a, b) => a.value > b.value);
        const retVal = [...sorted];
        callbackOk(retVal);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Gets all projects and transforms some data easer consupmtion for view.
   */
  getAllProjects = (callbackOk, callbackFail) => {
    this.camp.getProjects().then(
      (data) => {
        callbackOk(data);
      },
      (err) => {
        globalErrorCallback(err, callbackFail);
      }
    );
  };

  getListProjects = (callbackOk, callbackFail) => {
    this.camp.listProjects().then(
      (data) => {
        callbackOk(data);
      },
      (err) => {
        globalErrorCallback(err, callbackFail);
      }
    );
  };

  /**
   * Gets project by id.
   * @param {string} id project id
   * @param {func} callbackOk @returns {project}
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getProject = (id, callbackOk, callbackFail) => {
    projectLoadBound();
    this.camp.getProjectById({ id }).then(
      (data) => {
        const datum = data;
        const sortedRoles = data.roles.sort((a, b) => a.id - b.id);
        datum.roles = sortedRoles;
        projectLoadedBound(datum);
        if (callbackOk) {
          callbackOk(datum);
        }
        EventManager.emit(EventManager.TYPES_MAP.PROJECT.ONLOAD, datum);
      },
      (err) => {
        globalErrorCallback(err, callbackFail);
      }
    );
  };

  downloadFile = (files, callbackOk, callbackFail) => {
    // using interceptors to change responseType for binary files.
    const fd = axios.interceptors.request.use((config) => {
      if (config.headers.Accept[0] === "application/octet-stream") {
        /* eslint-disable */
        // because [no-param-reassign]
        config.responseType = "blob";
        /* eslint-enable */
      }
      return config;
    });
    const idsArray = [];
    if (Array.isArray(files)) {
      files.forEach((file) => {
        idsArray.push(file.file.id);
      });
    } else {
      idsArray.push(files.file.id);
    }
    const params = {
      ids: idsArray.join(","),
    };
    this.camp
      .getFileByID(params)
      .then((data) => {
        axios.interceptors.request.eject(fd);

        const reader = new FileReader();
        try {
          reader.onerror = function onerror(error) {
            globalErrorCallback(error, callbackFail);
          };
          reader.onload = function onload() {
            const resp = JSON.parse(reader.result);
            if (resp.length === 1) {
              const blob = new Blob([Buffer.from(resp[0].content, "base64")], {
                type: resp.type,
              });
              FileSaver.saveAs(blob, resp[0].name);
            } else {
              const zip = new JSZip();
              resp.forEach((file) => {
                zip.file(file.name, file.content, { base64: true });
              });
              zip.generateAsync({ type: "blob" }).then(function (content) {
                FileSaver.saveAs(content, "fastloop_files.zip");
              });
            }
          };
          reader.readAsText(data);
          callbackOk();
        } catch (error) {
          globalErrorCallback(error, callbackFail);
        }
      })
      .catch((err) => {
        axios.interceptors.request.eject(fd);
        globalErrorCallback(err, callbackFail);
      });
  };

  uploadFile = (projectId, file, type, callbackOk, callbackFail) => {
    const formData = new FormData();
    formData.append("file", file);
    const fd = axios.interceptors.request.use((config) => {
      if (config.url.endsWith("files") && config.method === "post") {
        /* eslint-disable */
        // generated lib is stupid for uploading files, so need interceptors to do the job.
        // because [no-param-reassign]
        config.data = formData;
        /* eslint-enable */
      }
      return config;
    });
    this.camp
      .uploadFile({ file: formData })
      .then((data) => {
        axios.interceptors.request.eject(fd);
        const fileParams = {
          id: projectId,
          body: {
            type,
            fileId: data.id,
          },
        };
        if (type === FILE_TYPES.BLOCKING) {
          const body = {
            fileId: data.id,
            projectId,
          };
          this.camp
            .postBlockingChart({ body })
            .then(callbackOk)
            .catch((err) => {
              axios.interceptors.request.eject(fd);
              globalErrorCallback(err, callbackFail);
            });
        } else if (type === FILE_TYPES.TRAFFICING) {
          const body = {
            fileId: data.id,
            projectId,
          };
          this.camp
            .postTraffickingSheet({ body })
            .then(callbackOk)
            .catch((err) => {
              axios.interceptors.request.eject(fd);
              globalErrorCallback(err, callbackFail);
            });
        } else {
          this.camp
            .postProjectFile(fileParams)
            .then(callbackOk)
            .catch((err) => {
              axios.interceptors.request.eject(fd);
              globalErrorCallback(err, callbackFail);
            });
        }
      })
      .catch((err) => {
        axios.interceptors.request.eject(fd);
        globalErrorCallback(err, callbackFail);
      });
  };

  uploadBlockingFile = (projectId, file, callbackOk, callbackFail) =>
    this.uploadFile(
      projectId,
      file,
      FILE_TYPES.BLOCKING,
      callbackOk,
      callbackFail
    );

  uploadTrafficingFile = (projectId, file, callbackOk, callbackFail) =>
    this.uploadFile(
      projectId,
      file,
      FILE_TYPES.TRAFFICING,
      callbackOk,
      callbackFail
    );

  uploadReportFile = (projectId, file, callbackOk, callbackFail) =>
    this.uploadFile(
      projectId,
      file,
      FILE_TYPES.REPORT,
      callbackOk,
      callbackFail
    );

  /**
   * Gets blocking chart
   */
  getBlockingChart = (id, callbackOk, callbackFail) => {
    this.camp
      .getBlockingChartByID({ fileId: id })
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Quick and dirty validation function
   * @param {object} data - all project object
   * @returns {object} {cnt, retVal} - count of Errors, object with bool values.
   */
  validateProject = (data) => {
    const retVal = {
      name: true,
      customerId: true,
      docket: true,
      docketNumbers: true,
      tags: {
        // client: true,
        // year: true,
        // platform: true,
        // scope: true,
      },
      milestonesStartEnd: true,
      roles: true,
      rolesLines: [],
    };
    let cnt = 0;

    if (data.name.trim().length < 3) {
      retVal.name = false;
      cnt += 1;
    }
    if (data.docket.trim().length < 3) {
      retVal.docket = false;
      cnt += 1;
    }
    if (data.docketNumbers.trim().length < 3) {
      retVal.docketNumbers = false;
      cnt += 1;
    }
    // Disabling for now, in subject of change
    // const clientTags = data.tags.filter(elem => elem.category === 'client');
    // if (clientTags.length === 0) {
    //   retVal.tags.client = false;
    //   cnt += 1;
    // }
    // const yearTags = data.tags.filter(elem => elem.category === 'year');
    // if (yearTags.length === 0) {
    //   retVal.tags.year = false;
    //   cnt += 1;
    // }
    // const platformTags = data.tags.filter(elem => elem.category === 'platform');
    // if (platformTags.length === 0) {
    //   retVal.tags.platform = false;
    //   cnt += 1;
    // }
    // rigth now is optional.
    // const scopeTags = data.tags.filter(elem => elem.category === 'scope');
    // if (scopeTags.length === 0) {
    //   retVal.tags.scope = false;
    //   cnt += 1;
    // }
    // const milestoneStart = data.milestones.find(
    //   elem => elem.id === MILESTONES.START_ID
    // );
    // const milestoneEnd = data.milestones.find(
    //   elem => elem.id === MILESTONES.END_ID
    // );
    // if (!isAfter(milestoneEnd.date, milestoneStart.date)) {
    //   retVal.milestonesStartEnd = false;
    //   cnt += 1;
    // }
    const rolesLines = data.roles.filter((elem) => {
      if (!elem.userId || !elem.roleId) {
        return true;
      }
      return false;
    });
    if (rolesLines.length > 0) {
      retVal.roles = false;
      retVal.rolesLines = rolesLines;
      cnt += 1;
    }

    return { cnt, retVal };
  };

  /**
   * Calls POST to create project
   * @param {object} data - project object
   * @param {func} callbackOk - callback on success, returns created object from DB.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  createProject = (data, callbackOk, callbackFail) => {
    this.camp
      .addProject({ body: data })
      .then((databack) => {
        callbackOk(databack);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /**
   * Calls POST to create note for project.
   * @param {number} projectId - id
   * @param {string} noteText - project object
   * @param {func} callbackOk - callback on success, no data.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  createNote = (projectId, noteText, callbackOk, callbackFail) => {
    const params = {
      id: projectId,
      body: { note: noteText },
    };
    this.camp
      .postProjectNote(params)
      .then((databack) => callbackOk(databack))
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /** Updates general project settings
   * @param {number} projectId - id
   * @param {string} name - project name
   * @param {string} reportingUrl - project name
   * @param {func} callbackOk - callback on success, no data.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  updateProjectGeneral = (
    projectId,
    name,
    budget,
    reportingUrl,
    reportingToken,
    callbackOk,
    callbackFail
  ) => {
    budget = parseFloat(budget);
    const params = {
      id: projectId,
      body: {
        name,
        reportingUrl,
        reportingToken,
        budget,
      },
    };
    this.camp
      .updateProject(params)
      .then((ok) => {
        callbackOk(ok);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  updateProjectGenNext(projectId, body, callbackOk, callbackFail) {
    const params = {
      id: projectId,
      body,
    };
    this.camp
      .updateProject(params)
      .then((ok) => {
        callbackOk(ok);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  bustBQCache(projectId, callbackOk, callbackFail) {
    const params = { id: projectId };
    this.camp
      .cacheBQ(params)
      .then((ok) => {
        callbackOk(ok);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  /** Updates general project settings
   * @param {number} projectId - id
   * @param {string} key - name of prop
   * @param {string} value - value to save
   * @param {func} callbackOk - callback on success, no data.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  updateProjectGeneralByKeyVal = (
    projectId,
    key,
    value,
    callbackOk,
    callbackFail
  ) => {
    const allowedProps = ["name", "reportingUrl", "reportingToken", "budget"];
    if (!allowedProps.includes(key)) {
      console.error("no such prop for project update");
      if (callbackFail) callbackFail();
    } else {
      const params = {
        id: projectId,
        body: {
          [key]: value,
        },
      };
      this.camp
        .updateProject(params)
        .then((data) => {
          projectLoadedBound(data);
          if (callbackOk) callbackOk(data);
        })
        .catch((err) => {
          this.globalErrorCallback(err, callbackFail);
        });
    }
  };

  /** Updates milestone
   * @param {number} projectId - id
   * @param {object} milestoneObj - milestone obj - {milestoneId, date, completed}
   * @param {func} callbackOk - callback on success, no data.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  updateProjectMilestone = (
    projectId,
    milestoneObj,
    callbackOk,
    callbackFail
  ) => {
    const params = {
      id: milestoneObj.milestoneEntityId,
      body: {
        date: milestoneObj.date,
        completed: milestoneObj.completed,
      },
    };
    this.camp
      .putProjectMilestone(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /** Add Tags
   * @param {number} projectId - id
   * @param {array} tagsIds - tags ids
   * @param {func} callbackOk - callback on success, no data.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  addProjectTag = (projectId, tagsIds, callbackOk, callbackFail) => {
    const params = {
      id: projectId,
      body: tagsIds,
    };
    this.camp
      .postProjectTag(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /** Remove Tags
   * @param {number} projectId - id
   * @param {array} tagsIds - tags ids
   * @param {func} callbackOk - callback on success, no data.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  removeProjectTag = (projectId, tagsIds, callbackOk, callbackFail) => {
    const promises = [];
    tagsIds.forEach((id) => {
      const prom = new Promise((resolve, reject) => {
        this.camp
          .deleteProjectTag({ id })
          .then(resolve)
          .catch((err) => {
            reject(err);
            globalErrorCallback(err, callbackFail);
          });
      });
      promises.push(prom);
    });

    Promise.all(promises)
      .then(callbackOk)
      .catch((err) => globalErrorCallback(err, callbackFail));
  };

  /** Add Milestone
   * @param {number} projectId - id
   * @param {object} milestoneObj - milestone obj - {milestoneId, date, completed}
   * @param {func} callbackOk - callback on success, no data.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  addProjectMilestone = (projectId, milestoneObj, callbackOk, callbackFail) => {
    const params = {
      id: projectId,
      body: [milestoneObj],
    };
    this.camp
      .postProjectMilestone(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /** Add Role to project
   * @param {number} projectId - id
   * @param {object} roleObj - role obj - {roleId, userId}
   * @param {func} callbackOk - callback on success, no data.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  addProjectRole = (projectId, roleObj, callbackOk, callbackFail) => {
    const params = {
      id: projectId,
      body: [roleObj],
    };
    this.camp
      .postProjectRole(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  /** Remove Role from project
   * @param {number} projectId - id
   * @param {number} roleId - role obj id
   * @param {func} callbackOk - callback on success, no data.
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  removeProjectRole = (projectId, roleId, callbackOk, callbackFail) => {
    const params = {
      id: roleId,
    };
    this.camp
      .deleteProjectRole(params)
      .then(() => {
        callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  getAllFiles = (callbackOk, callbackFail) => {
    this.camp
      .getProjectFiles()
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  getBlockingChartInfo = (projectId, callbackOk, callbackFail) => {
    this.camp
      .getBlockingChartByProject({ projectId })
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  lockBlockingChart = (projectId, isLocked, callbackOk, callbackFail) => {
    const body = {
      projectId,
      locked: isLocked ? 1 : 0,
    };
    this.camp
      .lockOrUnlockChart({ body })
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  getTrafficingSheetInfo = (projectId, callbackOk, callbackFail) => {
    this.camp
      .getTraffickingSheetByProject({ projectId })
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  lockTrafficingSheet = (projectId, isLocked, callbackOk, callbackFail) => {
    const body = {
      projectId,
      locked: isLocked ? 1 : 0,
    };
    this.camp
      .lockOrUnlockSheet({ body })
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  pullSheet = (projectId, callbackOk, callbackFail) => {
    const body = { id: projectId };
    this.camp
      .pullSheet({ id: projectId, body })
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  binAdmin = (actionNo, docket, callbackOk, callbackFail) => {
    const actions = {
      1: "syncBigQuery",
      2: "syncDefaults",
      3: "syncDS",
      4: "deleteProject",
      5: "deleteCustomer",
      6: "syncSheet",
      7: "shareSheet",
      8: "exportSheet",
    };

    let params;
    if (Array.isArray(docket)) {
      params = docket;
    } else {
      params = [docket];
    }

    const body = {
      script: actions[actionNo],
      params,
    };

    this.camp
      .adminBin({ body })
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };

  shareSheet = (projectId, emails, callbackOk, callbackFail) => {
    this.camp
      .shareSheet({ id: projectId, body: emails })
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };
  exportSheet = (projectId, emails, callbackOk, callbackFail) => {
    this.camp
      .exportSheet({ id: projectId, body: { emails } })
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  };
}

/**
 * Used for new project forms, returns false if only props object has paramName as property,
 * in this way on init when props are empty form is still valid.
 * @param {object} props object from ProjectService.validateProject()
 * @param {string} paramName param to look if valid.
 */
const isValid = (props, paramName, paramName2 = false) => {
  let retVal = true;
  if (props && Object.prototype.hasOwnProperty.call(props, paramName)) {
    retVal = props[paramName];
  }
  if (paramName2) {
    if (props && Object.prototype.hasOwnProperty.call(props, paramName2)) {
      retVal = props[paramName2];
    }
  }
  return retVal;
};

const getClientHelper = (project) => {
  let retVal = "";
  try {
    const filtered = project.tags.find(
      (elem) => elem.tag.category === "client"
    );
    if (filtered) {
      retVal = filtered.tag.tag;
    }
  } catch (err) {
    // this fails cuz of mock api.
    console.log("no object", err);
  }
  return retVal;
};

/**
 * Gets tags filtererd by category
 * @param {object} project project object with tags;
 * @param {string} cat name of needed catebory
 */
const getTagsByCat = (project, cat) =>
  project.tags.filter((elem) => elem.tag.category === cat);

/**
 * Gets tags ids by category
 * @param {object} project project object with tags;
 * @param {string} cat name of needed catebory
 */
const getTagsIdsByCat = (project, cat) => {
  const cats = getTagsByCat(project, cat);
  return cats.map((item) => item.tag.id);
};
/**
 * Converts milestons to UI friendly names.
 * @param {array} milestones from project api call
 * @returns {array} sorted by date, keys names changed to real names,
 * uniqId for react rendering keys.
 */
const milestonesHelper = (milestones) => {
  const milestonesParsed = milestones.map((obj, idx) => {
    let name = obj.milestone;
    if (obj.milestone.milestone) {
      name = titleCaseString(obj.milestone.milestone);
    }
    return {
      ...obj,
      dateParsed: moment(obj.date),
      milestoneParsed: name,
      uniqId: idx,
    };
  });
  const sorted = milestonesParsed.sort((a, b) =>
    compareAsc(a.dateParsed, b.dateParsed)
  );
  return sorted;
};

/**
 * Returns end date milestone.
 * @param {array} milestones from api.
 */
const getEndDateMilestone = (milestones) =>
  milestones.find((milestone) => milestone.milestone.id === MILESTONES.END_ID);

/**
 * Returns start date milestone.
 * @param {array} milestones from api.
 */
const getStartDateMilestone = (milestones) =>
  milestones.find(
    (milestone) => milestone.milestone.id === MILESTONES.START_ID
  );

/**
 * Returns only reports.
 * @param {array} files
 */
const getReportsHelper = (files) =>
  files.filter((obj) => obj.type === "report");

/**
 * Merges and sorts by date (DEC) files and notes for activities log.
 * @param {array} files files object from API
 * @param {array} notes notes object from API
 */
const mergeNotesAndFilesforActivity = (files, notes) => {
  let retVal = [];
  const notesTransformed = notes.map((note, idx) => ({
    type: "note",
    noteText: note.note.note,
    user: note.note.owner,
    date: note.note.dateCreated,
    dateObj: parse(note.note.dateCreated),
    uniqKey: `${idx}n`,
  }));
  const filesTransformed = files.map((file) => ({
    type: "file",
    user: file.user,
    fileName: file.file.name,
    fileType: file.file.ext,
    date: file.file.dateCreated,
    dateObj: parse(file.file.dateCreated),
    uniqKey: `${file.file.id}f`,
  }));
  const activitesCombined = [...notesTransformed, ...filesTransformed];
  retVal = activitesCombined.sort((a, b) => compareDesc(a.dateObj, b.dateObj));
  return retVal;
};

/**
 * Returns first and last name if empty returns username without email.
 * @param {object} user camp user
 */
const getUserNames = (user) => {
  let retVal = "_no_user_";
  if (user) {
    /* eslint-disable prefer-destructuring */
    retVal = user.username.split("@")[0];
    /* eslint-enable */
    if (
      user.contact.firstname.trim().length > 0 &&
      user.contact.lastname.trim().length > 0
    ) {
      retVal = `${user.contact.firstname} ${user.contact.lastname}`;
    }
  }
  return retVal;
};

export default ProjectsService;
export {
  isValid,
  getClientHelper,
  milestonesHelper,
  getEndDateMilestone,
  getStartDateMilestone,
  getReportsHelper,
  mergeNotesAndFilesforActivity,
  getUserNames,
  getTagsIdsByCat,
  getTagsByCat,
};

export const projectsServiceStatic = new ProjectsService();
