import Tabletop from "tabletop";
import localForage from "localforage";

const googleSheetShareURL = process.env.REACT_APP_HELP_GOOGLE_SHEET;

export const initializeHelpComponentGoogleSheetData = () => {
  if (process.env.REACT_APP_ENV === "dev") {
    return;
  }
  Tabletop.init({
    key: googleSheetShareURL,
    callback: googleData => {
      localForage
        .setItem("GOOGLE_DATA", googleData)
        .then(function(value) {
          // no callback needed
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    simpleSheet: true
  });
};
