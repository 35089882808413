import notification from "antd/lib/notification";

/**
 * Shows notification on the right top corner
 * @param {String} message
 * @param {String} description - optional
 * @param {String} type on of - success, info, warning, error
 * @param {Int} duration default = 10s
 */
export const notify = (
  message,
  description,
  type = "success",
  duration = 10
) => {
  notification[type]({
    message,
    description,
    duration,
  });
};

window.notify = notify;
