import React, { Component } from "react";
import {
  Table,
  Input,
  Popconfirm,
  Button,
  notification,
  Icon,
  Divider,
  Modal,
  Form
} from "antd";

import ProjectsService from "../../projects/ProjectsService";
import CreateNewModal from "./CreateNewModal";
import NextFinish from "./NextFinish";

const STYLES = {
  icon: {
    color: "red"
  }
};

const EditAccessToken = Form.create({ name: "edit_access_token" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onSave, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Change Access Token"
          okText="Change"
          onCancel={onCancel}
          onOk={onSave}
        >
          <Form layout="vertical">
            <Form.Item label="Access Token">
              {getFieldDecorator("accessToken", {
                rules: [
                  { required: true, message: "Please input new Access Token" }
                ]
              })(<Input.Password />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

export class AuthTable extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectsService();
    this.columns = [
      {
        title: "Platform",
        dataIndex: "platformId",
        render: text => this.getPlatformName(text)
      },
      {
        title: "Account ID",
        dataIndex: "customerId"
      },
      {
        title: "Access Token",
        dataIndex: "accessToken",
        render: () => this.hideToken()
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Button type="link" onClick={() => this.editModal(record)}>
              Edit
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure? Cannot undo once page is saved."
              placement="left"
              okText="Yes"
              icon={<Icon type="question-circle-o" style={STYLES.icon} />}
              onConfirm={() => this.handleDelete(record)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </span>
        )
      }
    ];

    this.state = {
      accountCredentials: this.props.creds,
      credsToBeEditted: [],
      edittedCredPlatId: 0,
      edittedCredId: 0,
      visible: false
    };
  }

  getPlatformName = pId => {
    if (pId === 0) return "";
    const displayPlat = this.props.platforms.find(p => p.id === pId);
    return displayPlat.name;
  };

  hideToken = () => {
    const aster = "*";
    return aster.repeat(8);
  };

  editModal = cred => {
    this.setState({
      visible: true,
      edittedCredPlatId: cred.platformId,
      edittedCredId: cred.id
    });
  };

  editCancel = () => {
    this.setState({ visible: false, edittedCredPlatId: 0, edittedCredId: 0 });
  };

  addNewCredential = values => {
    const newTobeAdded = [...this.state.credsToBeEditted];
    const newCreds = [...this.state.accountCredentials];
    const newId = Date.now();
    const newCred = {
      id: newId,
      customerId: this.props.client.id,
      platformId: values.platform,
      accessToken: values.accessToken,
      isNew: true
    };
    const newCredToAdd = {
      id: newId,
      platformId: values.platform,
      accessToken: values.accessToken,
      isNew: true
    };
    newCreds.push(newCred);
    newTobeAdded.push(newCredToAdd);
    this.setState({
      accountCredentials: newCreds,
      credsToBeEditted: newTobeAdded
    });
  };

  changeAccessToken = () => {
    const newTobeEditted = [...this.state.credsToBeEditted];
    const form = this.formRef.props.form;
    form.validateFields((err, value) => {
      if (err) {
        return;
      }
      const newCredToken = this.getNewCredToken(value);
      newTobeEditted.push(newCredToken);
      form.resetFields();
      this.setState({
        credsToBeEditted: newTobeEditted,
        visible: false,
        modalCredId: 0
      });
    });
  };

  getNewCredToken = value => {
    if (
      this.state.credsToBeEditted.some(c => c.id === this.state.edditedCredId)
    ) {
      const newTobeEditted = this.state.credsToBeEditted.map(c => {
        if (c.id !== this.state.edittedCredId) return c;
        c.accessToken = value.token;
        return c;
      });
      return newTobeEditted;
    } else {
      const newCredToEdit = {
        id: this.state.edittedCredId,
        platformId: this.state.edittedCredPlatId,
        accessToken: value.token
      };
      return newCredToEdit;
    }
  };

  handleDelete = cred => {
    const newCredentials = this.state.accountCredentials.filter(
      c => c.id !== cred.id
    );
    const newCredsToDelete = this.state.credsToBeEditted.filter(
      c => c.id !== cred.id
    );
    if (cred.isNew) {
      this.setState({
        accountCredentials: newCredentials,
        credsToBeEditted: newCredsToDelete
      });
    } else {
      const newCredtoDelete = {
        id: cred.id,
        toDelete: true
      };
      newCredsToDelete.push(newCredtoDelete);
      this.setState({
        accountCredentials: newCredentials,
        credsToBeEditted: newCredsToDelete
      });
    }
  };

  //   *For potential future use*
  //   addBlankRow = () => {
  //     // TODO
  //   };

  showMessage = (type, title, descr) => {
    let dur = 0;
    if (type === "success") {
      dur = 2;
    }
    notification[type]({
      message: title,
      description: descr,
      duration: dur,
      placement: "bottomRight"
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  onSave = () => {
    this.setState({ credsToBeEditted: [] });
    this.showMessage(
      "success",
      "Success!",
      "Your client`s connections have been updated."
    );
  };

  onFail = () => {
    this.showMessage(
      "error",
      "Error!",
      "Something went wrong! Connections did not save, please check info and try again."
    );
  };

  render() {
    const { client, platforms, changeTab, tabKey } = this.props;
    return (
      <div>
        <div align="right">
          <CreateNewModal
            buttonText="Add New Connection"
            modalTitle="Add a New Connection"
            currentConections={this.state.accountCredentials}
            platforms={platforms}
            addNew={this.addNewCredential}
          />
        </div>
        <div>
          <Table
            columns={this.columns}
            dataSource={this.state.accountCredentials}
            rowKey={record => record.id}
            size="middle"
          />
        </div>
        {/* For potential future use
        <div align="left">
          <Tooltip placement="bottom" title={"Add Credential"}>
            <Button shape="circle" icon="plus" onClick={() => this.addBlankRow()} />
          </Tooltip>
        </div> */}
        <div align="right">
          <NextFinish
            client={client}
            edittedData={this.state.credsToBeEditted}
            onSave={() => this.onSave()}
            onFail={() => this.onFail()}
            changeTab={changeTab}
            tabType={"CRED"}
            tabKey={tabKey}
          />
        </div>
        <EditAccessToken
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          edittedData={this.state.credsToBeEditted}
          onSave={this.changeAccessToken}
          onCancel={this.editCancel}
        />
      </div>
    );
  }
}

export default AuthTable;
