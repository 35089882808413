import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./MainProjectsLine.scss";
import ArrowImg from "../../../shared/icons/arrow-white.png";
// import ArrowUp from '../../../shared/icons/arrow-up-green.svg';
// import ArrowDown from '../../../shared/icons/arrow-down-orange.svg';
import ProfileImg from "../../../shared/icons/account-black.png";
import BlockingImg from "../../../shared/icons/dashboard-grey.png";
// import TimeVsBudgetLineSVG from '../../projectView/projectViewMetric/TimeVsBudgetLineSVG';
// import { stringToColour } from '../../../utils/ColorUtils';
import { DEFAULT_DATE_FORMAT_SHORTER } from "../../../shared/Constants";
import LineChart from "../../charts/LineChart";
import moment from "moment";
import MainProjectLinePeople from "./MainProjectLinePeople";
import ProjectStatus from "./ProjectStatus";

/**
 * Displays single project line. Params in the bottom of the file.
 */

class MainProjectsLine extends Component {
  constructor(props) {
    super(props);
    this.state = { isChartsOpen: false };
  }

  openCharts() {
    // this.setState({ isChartsOpen: !this.state.isChartsOpen });
    this.setState({ isChartsOpen: !!this.state.isChartsOpen });
    // hidden for new because wew don't have any data.
  }
  render() {
    // const colorName = this.props.companyName.split('-')[0];
    const blockColorStyle = {
      // backgroundColor: stringToColour(colorName),
      backgroudColor: "#8d67ad",
    };

    let projectEndStr = "n/a";
    if (this.props.projectEnd) {
      projectEndStr = moment(this.props.projectEnd).format(
        DEFAULT_DATE_FORMAT_SHORTER
      );
    }
    let startDateStr = "n/a";
    if (this.props.projectStarts) {
      startDateStr = moment(this.props.projectStarts).format(
        DEFAULT_DATE_FORMAT_SHORTER
      );
    }
    return (
      <div className="MainProjectsLine">
        <div className="grid-x grid-margin-x fixed-height">
          <div className="cell small-7 medium-3 large-4 name-cell">
            <div
              className={`company-name-color u-clickable is-open-${this.state.isChartsOpen}`}
              style={blockColorStyle}
              onClick={() => this.openCharts()}
              onKeyPress={() => this.openCharts()}
              role="button"
              tabIndex="0"
            >
              <img src={ArrowImg} alt="expand" />
            </div>
            <Link
              to={`/projects/${this.props.projectId}`}
              className="link-block"
              data-ft-docket={this.props.docketCode}
            >
              <span className="name">
                <strong>{this.props.projectName}</strong>
              </span>
              {"  "}
              <span className="u-gray code">{this.props.docketCode}</span>
            </Link>
          </div>
          <div className="cell small-5 medium-2 large-2 timevsbudget-cell">
            {/* <TimeVsBudgetLineSVG
              time={this.props.timePercent.toString()}
              budget={this.props.budgetPercent.toString()}
            /> */}
          </div>
          <div className="cell small-6 medium-1 large-1 spend-cell">
            <span className="show-for-small-only">Spend-V: </span>
            {/* {this.props.spendPercent > 0 ? (
              <img
                src={ArrowUp}
                className="arrow"
                alt="up"
                title={this.props.spend}
              />
            ) : (
              <img
                src={ArrowDown}
                className="arrow"
                alt="up"
                title={this.props.spend}
              />
            )}
            {this.props.spendPercent}{' '} */}
          </div>
          <div className="cell small-6 medium-1 large-1 ends-cell">
            <span className="show-for-small-only">Starts: </span>
            <span>{startDateStr}</span>
          </div>
          <div className="cell small-6 medium-1 large-1 ends-cell">
            <span className="show-for-small-only">Ends: </span>
            <span>{projectEndStr}</span>
          </div>
          <div className="cell small-12 medium-3 large-2 lu-cell no-right-margin">
            {this.props.lastUpdateUserId ? (
              <MainProjectLinePeople userId={this.props.lastUpdateUserId} />
            ) : (
              <img
                src={ProfileImg}
                className="profile-img"
                alt="n/a"
                title="n/a"
              />
            )}
            <Link to={`/projects/${this.props.projectId}/bp/`}>
              <img
                src={BlockingImg}
                className="blocking-img"
                alt="Media Plan"
                href=""
              />
            </Link>
          </div>
          <div className="cell small-6 medium-1 large-1 no-left-margin">
            <ProjectStatus
              status={this.props.status}
              projectId={this.props.projectId}
            />
          </div>
        </div>
        <div className={`chart-line is-open-${this.state.isChartsOpen}`}>
          <div className="grid-x grid-margin">
            <div className="cell small-12 medium-4 chart-cell">
              <LineChart />
            </div>
            <div className="cell small-12 medium-4 chart-cell">
              <LineChart />
            </div>
            <div className="cell small-12 medium-4 chart-cell last">
              <LineChart />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainProjectsLine;

MainProjectsLine.defaultProps = {
  projectEnd: {},
  projectStarts: {},
  lastUpdateUserId: "",
  status: "InPlanning",
};

MainProjectsLine.propTypes = {
  companyName: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  docketCode: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  timePercent: PropTypes.number.isRequired,
  budgetPercent: PropTypes.number.isRequired,
  cpm: PropTypes.number.isRequired,
  cpmPercent: PropTypes.string.isRequired,
  spend: PropTypes.number.isRequired,
  spendPercent: PropTypes.string.isRequired,
  // vf: PropTypes.string.isRequired,
  reportingUrl: PropTypes.string.isRequired,
  projectEnd: PropTypes.object,
  projectStarts: PropTypes.object,
  lastUpdateUserId: PropTypes.string,
  status: PropTypes.string,
};
