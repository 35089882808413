import React from 'react';
import './CompanyLogo.scss';
import Logo from '../icons/fastloop-logo-colour.png';

const CompanyLogo = () => (
  <div className="CompanyLogo">
    <div>
      <img className="logo" src={Logo} alt="logo" />
    </div>
  </div>
);

export default CompanyLogo;
