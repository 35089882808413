import reduxStore from '../reduxStore';

export const getAdTypes = state => state.commonStore.adtypesByPlatform;

export const getAdByPlatform = (state, typeId) =>
  state.commonStore.adtypesByPlatform[typeId];

export const getAdByPlatformBound = typeId =>
  reduxStore.getState().commonStore.adtypesByPlatform[typeId];

export const getAllPlatforms = state => state.commonStore.platforms;

export const getAllPlatformsBound = () =>
  getAllPlatforms(reduxStore.getState());

export const metaLoaded = () => {
  const flags = reduxStore.getState().commonStore.dataLoadedFlags;
  return !!flags.bpView && !!flags.trafficView;
};

export const getViewFromCommon = (projectId, name) => {
  const all = reduxStore.getState().commonStore.dataLoadedObjects.views[
    projectId
  ];
  //@TODO fix completely, ditch names and use IDs
  //@FIXME - handle names as objects.

  const nameIn = name.replace(/\s/g, '_');
  return all.find(view => {
    const nameTest = view.viewName.replace(/\s/g, '_');
    const nameTestFull = `${view.type}.${nameTest}`;
    return nameTestFull === nameIn;
  });
};

export const getMetaListFromCommon = (projectId, listId) => {
  const lists = {
    ...reduxStore.getState().commonStore.dataLoadedObjects.lists[projectId],
  };
  return lists.lists[listId];
};

export const getMetaListsFromCommon = projectId => ({
  ...reduxStore.getState().commonStore.dataLoadedObjects.lists[projectId],
});

// Down the rabbit hole.
export const getMetaMetasFromCommon = (projectId, dynamicLevelInt) => ({
  ...reduxStore.getState().commonStore.dataLoadedObjects.metas[projectId][
    dynamicLevelInt - 1
  ],
});

export const doOpenMetasEditor = () =>
  reduxStore.getState().commonStore.metasEditor.dialogOpen;

export const metasEditorVars = () => ({
  ...reduxStore.getState().commonStore.metasEditor.payloadBody,
});

export const doOpenMetasReorder = () =>
  reduxStore.getState().commonStore.metasReorder.dialogOpen;

export const metasReorderVars = () => ({
  ...reduxStore.getState().commonStore.metasReorder.payloadBody,
});

export const doOpenModal = (state, name) => {
  const mods = state.commonStore.modals;
  if (mods[name]) {
    return mods[name];
  }
  return { open: false };
};
