import React from "react";
import PropTypes from "prop-types";
import Checkbox from "antd/lib/checkbox";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";
import { FLHServiceStatic } from "../../../../services/FLHService";
const CheckboxGroup = Checkbox.Group;

// const plainOptions = ['Apple', 'Pear', 'Orange'];

class ProjectAdminSettingsFashooksDefaults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      options: [],
      checkedList: [],
      addingIsLoading: false,
      addedDone: false,
    };
  }
  componentDidMount() {
    FLHServiceStatic.loadDefaultHooks(this.props.clientId, (data) => {
      this.setState({ options: data, isLoading: false });
    });
  }
  onChange = (vals) => {
    console.log("[ProjectAdminSettingsFashooksDefaults.jsx] vals", vals);
    this.setState({ checkedList: vals });
  };
  onAdd = () => {
    console.log(
      "[ProjectAdminSettingsFashooksDefaults.jsx] this.vals",
      this.state.checkedList
    );
    this.setState({ addingIsLoading: true });
    FLHServiceStatic.addDefaultHooks(
      this.props.projectId,
      this.state.checkedList,
      (data) => {
        this.setState({ addingIsLoading: false, addedDone: true });
        setTimeout(() => {
          this.setState({ addedDone: false });
        }, 1000 * 10);
      }
    );
  };
  render() {
    return (
      <div className="ProjectAdminSettingsFashooksDefaults">
        <h6>Add defaults hooks to project</h6>
        <div
          className={`ld-over ${
            this.state.isLoading ? "running" : "not-running"
          }`}
        >
          <CheckboxGroup
            options={this.state.options}
            value={this.state.checkedList}
            onChange={this.onChange}
          />
          <br />
          <br />
          <Button
            loading={this.state.addingIsLoading}
            onClick={() => this.onAdd()}
          >
            Add
          </Button>
          {this.state.addedDone && (
            <>
              {"  "}
              <Icon
                type="check-circle"
                style={{ color: "green" }}
              /> Added!{" "}
            </>
          )}{" "}
          <br />
          <div style={{ textAlign: "right" }}>
            <em>Page reload may be required to see added hooks.</em>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectAdminSettingsFashooksDefaults;

ProjectAdminSettingsFashooksDefaults.propsTypes = {
  clientId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
};
