///// Some actions so we don't need to include store, etc to diff places.

import reduxStore from "../reduxStore";

export const setSelectedClient = (payload) => {
  reduxStore.dispatch({ type: "qndui/setSelectedClient", payload });
};

export const setSetBigQueryDialog = (open, projectId) => {
  reduxStore.dispatch({
    type: "qndui/setSetBigQueryDialog",
    payload: { open, projectId },
  });
};
