import Ajv from 'ajv';
import { camp } from '../../camp';

import { globalErrorCallback } from '../../utils/ErrorTracker';

class ProfileService {
  constructor() {
    this.camp = camp;
    this.ajv = new Ajv({ allErrors: true });
    this.customerSchema = {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: { type: 'string', minLength: 1, maxLength: 255 },
        legalName: { type: 'string', minLength: 1, maxLength: 255 },
        address1: { type: 'string', minLength: 1, maxLength: 255 },
        address2: { type: 'string' },
        city: { type: 'string', minLength: 1, maxLength: 255 },
        province: { type: 'string', minLength: 1, maxLength: 255 },
        postalCode: { type: 'string', minLength: 1, maxLength: 6 },
        phone: { type: 'string', minLength: 1, maxLength: 15 },
        fax: { type: 'string' }, // , minLength: 1, maxLength: 15
        email: {
          type: 'string',
          format: 'email',
          minLength: 1,
          maxLength: 255,
        },
        legalStructure: { type: 'string', minLength: 1, maxLength: 255 },
        businessNumber: { type: 'string', minLength: 1, maxLength: 255 },
      },
      required: [
        'legalName',
        'operatingName',
        'address1',
        'city',
        'province',
        'postalCode',
        'phone',
        'email',
        'legalStructure',
        'businessNumber',
      ],
    };
  }

  validateObj(obj, schema) {
    const validator = this.ajv.compile(schema);
    const test = validator(obj);
    return {
      valid: test,
      errors: validator.errors,
    };
  }

  /**
   * Gets Customer list from API
   * @param {func} callbackOk returns Customers array
   * @param {func} callbackFail faillure callback @returns err from axios
   */
  getCustomers = (callbackOk, callbackFail) => {
    this.camp
      .getCustomers()
      .then(callbackOk)
      .catch(err => globalErrorCallback(err, callbackFail));
  };

  registerNewCustomer(params, callbackOk, callbackFail) {
    // const valid = this.validateObj(params, this.customerSchema);
    const post = { body: params };
    this.camp
      .postCustomerInvite(post)
      .then(data => {
        // console.log('[ProfileService.js] data', data);
        callbackOk(data);
      })
      .catch(err => {
        globalErrorCallback(err, callbackFail);
      });
    // if (!valid.valid) {
    //   callbackFail({ validator: valid.errors });
    // } else {

    // }
  }

  registerAdvertiser(params, callbackOk, callbackFail) {
    const post = { body: params };
    this.camp
      .postCustomer(post)
      .then(data => {
        callbackOk(data);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          console.log(
            '[ProfileService.js] err.response.data.errorCode',
            err.response.data.errorCode
          );
          if (
            // Related to short code, all others should go to default error handler.
            err.response.data.errorCode === 409 ||
            err.response.data.errorCode === 422
          ) {
            callbackFail(err.response.data);
          } else {
            globalErrorCallback(err, callbackFail);
          }
        }
      });
  }

  putCustomerNotes(customerId, note, callbackOk, callbackFail) {
    this.camp
      .putCustomerNotes({
        customerId, body: [{
          note
        }]
      })
      .then(data => {
        // console.log('[ProfileService.js] data', data);
        callbackOk(data);
      })
      .catch(err => {
        globalErrorCallback(err, callbackFail);
      });
  }

  getAdvertiserBody = () => {
    return {
      name: '',
      legalName: '',
      parentId: 0,
      shortcode: '',
      address1: '',
      address2: '',
      city: '',
      province: '',
      postalCode: '',
      phone: '',
      fax: '',
      email: '',
      legalStructure: '',
      businessNumber: '',
      notes: [{ note: 'Registered via Project Creation Window' }],
      contacts: [],
      users: [],
    };
  };
  getUserBody = () => {
    return {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      roleId: 2, // 1 - admin, 2 - regular user
    };
  };
}

export default ProfileService;

export const profileServiceStatic = new ProfileService();
