import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'd3-format';
import ProjectViewMetric from './ProjectViewMetric';
// import ProjectViewMetricArc from './ProjectViewMetricArc';
import './ProjectViewMetricHolder.scss';

class ProjectViewMetricHolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cpm: '0',
      cpmv: '0',
      cpmDir: 'up',
      cpa: '0',
      cpav: '0',
      cpaDir: 'up',
      budget: '0',
      percentCompleteInner: 0,
      percentCompleteOuter: 0,
      percentCompleteInnerText: '0%',
      percentCompleteOuterText: '0%',
      tableau: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.project && nextProps.project.stats) {
      const stat = nextProps.project.stats;
      // sample data.
      // const stat = {
      //   timePercent: 99, // XX
      //   budget: 960000, // as is, FE adds k, m, by SI
      //   budgetPercent: 84, // XX
      //   cpa: 7.15, // XX.xx
      //   cpapercentchange: 99.99, // XX.xx
      //   cpm: 117, // XXX
      //   cpmpercentchange: 99.99, // XX.xx
      //   spend: 691893.7, // X{x...x}.xx
      //   spendpercentchange: 99.99, // XX.xx
      //   vf: 90.21000000000001, // rm.
      //   projectEnd: '2018-02-16',
      // };
      const numberFormatter = format('.2s');
      const budgetPercent = `${stat.budgetPercent.toString()}%`;
      const timePercent = `${stat.timePercent.toString()}%`;
      const timePercentForArch = this.toPercent(stat.timePercent);
      const budgetPercentForArch = this.toPercent(stat.budgetPercent);
      const cpmpercentchange = stat.cpmPercentChange.toString();
      const cpapercentchange = stat.cpaPercentChange.toString();
      this.setState({
        ...this.state,
        cpm: `$${stat.cpm.toString()}`,
        cpmv: `${cpmpercentchange}%`,
        cpmDir: stat.cpmpercentchange > 0 ? 'up' : 'down',
        cpa: `$${stat.cpa.toString()}`,
        cpav: `${cpapercentchange.toString()}%`,
        cpaDir: stat.cpapercentchange > 0 ? 'up' : 'down',
        budget: stat.budget
          ? `$${numberFormatter(stat.budget).toString()}`
          : '$0',
        // vf: stat.vf.toString(),
        percentCompleteInner: budgetPercentForArch,
        percentCompleteOuter: timePercentForArch,
        percentCompleteInnerText: budgetPercent,
        percentCompleteOuterText: timePercent,
        tableau: nextProps.project.reportingUrl,
      });
    }
  }

  toPercent = val => val / 100;

  render() {
    return (
      <div className="ProjectViewMetricHolder">
        <div className="metrics u-padding-both-sides-25">
          <div className="grid-x full">
            <div className="cell small-12 medium-6 large-4">
              <div className="grid-x small-up-2">
                <div className="cell metric-bordered-right">
                  <ProjectViewMetric
                    name="CPM"
                    value={this.state.cpm}
                    direction={this.state.cpmDir}
                  />
                  <div className="metric-bottom-line" />
                </div>
                <div className="cell metric-bordered-right">
                  <ProjectViewMetric
                    name="CPM-V"
                    value={this.state.cpmv}
                    noDirection
                  />
                  <div className="metric-bottom-line" />
                </div>
                <div className="cell metric-bordered-right">
                  <ProjectViewMetric
                    name="Budget"
                    value={this.state.budget}
                    noDirection
                  />
                </div>
                <div className="cell metric-bordered-right">
                  <ProjectViewMetric name="" value="" noDirection />
                </div>
              </div>
            </div>
            <div className="cell small-12 medium-6 large-4 text-center">
              {/* <ProjectViewMetricArc
                name="Time vs Budget"
                valueInner={this.state.percentCompleteInner}
                valueOuter={this.state.percentCompleteOuter}
                valueInnerText={this.state.percentCompleteInnerText}
                valueOuterText={this.state.percentCompleteOuterText}
              /> */}
            </div>
            <div className="cell small-12 medium-12 large-4">
              <div className="grid-x small-up-2">
                <div className="cell metric-bordered-left">
                  {/* <ProjectViewMetric
                    name="CPA"
                    value={this.state.cpa}
                    direction={this.state.cpaDir}
                  /> */}
                  <div className="metric-bottom-line" />
                </div>
                <div className="cell metric-bordered-left">
                  <ProjectViewMetric
                    name="CPA-V"
                    value={this.state.cpav}
                    noDirection
                  />
                  <div className="metric-bottom-line" />
                </div>
                <div className="cell metric-bordered-left">
                  <ProjectViewMetric name="" value="" noDirection />
                </div>
                <div className="cell metric-bordered-left">
                  {/* sample of adding image as metric */}
                  {/* <ProjectViewMetricImage
                    name="Reporting"
                    image={TableauImg}
                    url={this.state.tableau}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectViewMetricHolder;

ProjectViewMetricHolder.propTypes = {
  project: PropTypes.object.isRequired,
};
