import React, { Component } from "react";
import Notifications from "../../shared/notifications/Notifications";
import { Tabs, Button, Popconfirm, Icon, notification } from "antd";
import "antd/dist/antd.css";
import { Link, Redirect } from "react-router-dom";
import ProjectsService from "../../projects/ProjectsService";

import ContactInfo from "../clientPageComponents/ContactInfo";
import UserTable from "../clientPageComponents/UserTable";
import AuthTable from "../clientPageComponents/AuthTable";
import PartnerAdvertiserTable from "../clientPageComponents/PartnerAdvertiserTable";
import ClientDefaultView from "./clientDefaultView/ClientDefaultView";
import "../clientsPage/ClientsPage.scss";

import { isAdmin } from "../../auth/AuthService";

const { TabPane } = Tabs;
const STYLES = {
  icon: {
    color: "red",
  },
};

export default class ClientEditPage extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectsService();
    this.state = {
      activeTab: "2",
      client: {},
      contactInfo: {},
      userCredentials: [],
      accountUsers: [],
      platforms: [],
      isLoading: false,
      redirect: false,
    };
    this.isAdmin = isAdmin();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.projectService.getCustomerInfo(
      id,
      (data) => {
        this.setState({
          client: data[0],
          contactInfo: data[1],
          accountUsers: data[2],
          userCredentials: data[3],
          platforms: data[4],
          isLoading: true,
        });
      },
      () => {}
    );
  }

  changeTab = (activeKey) => {
    const newTabKey = activeKey.toString();
    this.setState({ activeTab: newTabKey });
  };

  deleteCustomer = () => {
    const { id } = this.props.match.params;
    this.projectService.deleteCustomer(
      id,
      () => this.onDelete(),
      () => this.onFail()
    );
  };

  onDelete = () => {
    notification.success({
      message: "Success!",
      description: "Customer was deleted!",
      duration: 3,
      placement: "bottomRight",
    });
    this.setState({ redirect: true });
  };

  onFail = () => {
    notification.error({
      message: "Error!",
      description: "Something went wrong, please try again!",
      duration: 0,
      placement: "bottomRight",
    });
  };

  render() {
    if (!this.state.isLoading) return null;
    if (this.state.redirect) {
      return <Redirect push to={`/clients/`} />;
    }
    return (
      <div className="ClientsPage">
        <div className="menu-row">
          <div className="u-padding-both-sides-25">
            <Notifications />
          </div>
          <div className="u-padding-both-sides-25">
            <div className="grid-x">
              <div className="cell small-12">
                <div className="top-bar">
                  <div className="top-bar-left">
                    <ul className="dropdown menu">
                      <li>
                        <Link to="/clients/" className="acitve">
                          Clients
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="customer-delete-button" align="right">
          <Popconfirm
            placement="bottomRight"
            title="Are you sure, it cannot be undone?"
            icon={
              <Icon type="question-circle" style={STYLES.icon} theme="filled" />
            }
            onConfirm={this.deleteCustomer}
            okText="Yes"
          >
            <Button type="danger">Delete Customer</Button>
          </Popconfirm>
        </div>
        <div className="tab-view">
          <Tabs
            activeKey={this.state.activeTab}
            onChange={this.changeTab}
            type="card"
          >
            <TabPane tab="Partner/Advertiser" key="2">
              <PartnerAdvertiserTable
                client={this.state.client}
                partners={this.state.client.children}
                changeTab={this.changeTab}
                tabKey={2}
              />
            </TabPane>
            <TabPane
              className="authorization-tab"
              tab="Platform Tokens"
              key="3"
            >
              <AuthTable
                client={this.state.client}
                creds={this.state.userCredentials}
                platforms={this.state.platforms}
                changeTab={this.changeTab}
                tabKey={3}
              />
            </TabPane>
            <TabPane tab="Contact" key="4">
              <ContactInfo
                contactInfo={this.state.contactInfo}
                client={this.state.client}
                changeTab={this.changeTab}
                tabKey={4}
              />
            </TabPane>
            <TabPane tab="Users" key="1">
              <UserTable
                client={this.state.client}
                accountUsers={this.state.accountUsers}
                changeTab={this.changeTab}
                tabKey={1}
              />
            </TabPane>
            {this.isAdmin && (
              <TabPane tab="Client Defaults" key="5">
                <ClientDefaultView clientId={this.state.client.id} />
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}
