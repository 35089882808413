import React from 'react';
import PropTypes from 'prop-types';
import './MetasEditor.scss';

const MetaFieldList = ({ onClick, allColsObj, dynamicLevelInt }) => {
  console.log("wtf", allColsObj);
  return (
    <div className="MetaFieldList">
      {dynamicLevelInt === 1 && (
        <DList dx={allColsObj.D1} name="D1" onClick={onClick} />
      )}
      {dynamicLevelInt === 2 && (
        <>
          <DList dx={allColsObj.D2} name="D2" onClick={onClick} />
          <DList dx={allColsObj.D1} name="D1" onClick={onClick} />
        </>
      )}
      {dynamicLevelInt === 3 && (
        <>
          <DList dx={allColsObj.D3} name="D3" onClick={onClick} />
          <DList dx={allColsObj.D2} name="D2" onClick={onClick} />
          <DList dx={allColsObj.D1} name="D1" onClick={onClick} />
        </>
      )}
    </div>
  );
};
export default MetaFieldList;

MetaFieldList.propTypes = {
  allColsObj: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  dynamicLevelInt: PropTypes.number.isRequired,
};

const DList = ({ dx, name, onClick }) => {
  console.log("dxdx", dx)
  if (dx) {
    const sortedX = dx.sort((a, b) => {
      const labela = a.label.toString();
      const labelb = b.label.toString();
      // console.log('ab', a, b)
      return labela.localeCompare(labelb);
    });
    return (
      <ul className={name}>
        <span>{name}</span>
        {sortedX.map(el => {
          if (!el.label) return false;
          return (
            <li key={el.name}>
              <button onClick={() => onClick({ name }, el)}>{el.label}</button>
            </li>
          );
        })}
      </ul>
    );
  } else {
    return <></>
  }
};
