import { camp } from '../camp';
import Notify from '../utils/Notify';

class UsersService {
  constructor() {
    this.camp = camp;
  }

  getUsers = (callbackOk, callbackFail) => {
    this.camp
      .getUsers()
      .then((users) => {
        const sortUsers = users.sort((a, b) => a.contact.firstname.localeCompare(b.contact.firstname));
        callbackOk(sortUsers);
      })
      .catch((err) => {
        Notify.fireError(err.message ? err.message : err);
        if (callbackFail) {
          callbackFail(err);
        }
      });
  };
}

export default UsersService;
