import React from "react";
import PropTypes from "prop-types";

/**
 * Shows new project block title
 * @param {string} title title to show
 * @param {string} dec description
 * @param {descEndInGray} description in gray - optional
 */
const NewProjectSutTitle = ({ title, desc, descEndInGray }) => (
  <div className="NewProjectSutTitle">
    <div className="sub-title">{title}</div>
    <p className="description">
      {desc}{" "}
      {descEndInGray ? (
        <span className="u-gray">{descEndInGray}</span>
      ) : (
        <span />
      )}
    </p>
  </div>
);

export default NewProjectSutTitle;

NewProjectSutTitle.defaultProps = {
  descEndInGray: "",
  desc: "",
};

NewProjectSutTitle.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  descEndInGray: PropTypes.string,
};
