/**
 * Translates formula human 2 robot and visa versa, for all `D` levels
 * @param {bool} forHumans
 * @param {string} formula
 * @param {string} fieldsObj = `{D1: [name: 'textColumn2', label: 'Channel',..], [] }`
 * @param {int} dynamicLevelInt
 * @returns {object} = `{hasError:bool, robot:str, human:str}`
 */
export const translateFormulaDX = (
  forHumans,
  formula,
  fieldsObj,
  dynamicLevelInt
) => {
  let retObj = {
    robot: formula,
    human: formula,
    hasError: false,
  };

  // 'MAX([D1.floatColumn6], [D2.textColumn2], [{D3.intColumn9}])'
  // regex = [[D1.floatColumn6], [D2.textColumn2]]

  const rg = new RegExp(/\[.*?\]/gim);
  let extracted = formula.match(rg) || [];
  let trans = formula;
  if (forHumans) {
    retObj = doItForHumans(
      extracted,
      fieldsObj,
      retObj,
      trans,
      dynamicLevelInt
    );
  } else {
    retObj = doItForRobots(
      extracted,
      fieldsObj,
      retObj,
      trans,
      dynamicLevelInt
    );
  }
  return retObj;
};

const NORMAL = {
  S: '[',
  E: ']',
};

const RANGE = {
  S: '{',
  E: '}',
};

function doItForRobots(extracted, fieldsObj, retObj, trans, dynamicLevelInt) {
  extracted.forEach(ex => {
    const isRange = ex[0] === RANGE.S;
    let { dLevel, seekParticle, dotRest } = extractMiddle(ex, dynamicLevelInt);
    const dx = fieldsObj[dLevel];
    if (!dx) {
      retObj.hasError = true;
    } else {
      const elem = Object.values(dx).find(v => v.label === seekParticle);
      let repl = ex;
      if (elem) {
        if (isRange) {
          repl = `${RANGE.S}${dLevel}.${elem.name}${dotRest}${RANGE.E}`;
        } else {
          repl = `${NORMAL.S}${dLevel}.${elem.name}${dotRest}${NORMAL.E}`;
        }
      } else {
        retObj.hasError = true;
      }
      trans = trans.replace(ex, repl);
    }
  });
  retObj.robot = trans;
  return retObj;
}

function doItForHumans(extracted, fieldsObj, retObj, trans, dynamicLevelInt) {
  extracted.forEach(ex => {
    const isRange = ex[0] === RANGE.S;
    let { dLevel, seekParticle, dotRest } = extractMiddle(ex, dynamicLevelInt);

    const dx = fieldsObj[dLevel];
    if (!dx) {
      retObj.hasError = true;
    } else {
      const elem = Object.values(dx).find(v => v.name === seekParticle);
      let repl = ex;
      if (elem) {
        if (isRange) {
          repl = `${RANGE.S}${dLevel}.${elem.label}${dotRest}${RANGE.E}`;
        } else {
          repl = `${NORMAL.S}${dLevel}.${elem.label}${dotRest}${NORMAL.E}`;
        }
      } else {
        retObj.hasError = true;
      }
      trans = trans.replace(ex, repl);
    }
  });
  retObj.human = trans;
  return retObj;
}

function extractMiddle(ex, dynamicLevelInt) {
  let seekParticle = '';
  let dLevel = '';
  let dotRest = '';
  // [D1.xxxx]
  let noBrackets = ex.substr(1, ex.length - 2);

  let sp = noBrackets.split('.');

  if (sp.length === 1) {
    dLevel = `D${dynamicLevelInt}`;
    seekParticle = sp[0];
  } else {
    dLevel = sp[0];
    seekParticle = sp[1];
    if (sp[2]) {
      dotRest = `.${sp[2]}`;
    }
  }

  // if (ex[1] === 'D') {
  //   dLevel = ex.substr(1, 2);
  //   // [D1.xxxx]
  //   // [D1.xxxx] => substr(4, ex.length-5) => xxxx
  //   seekParticle = ex.substr(4, ex.length - 5);
  // } else {
  //   /// [xxxx]
  //   dLevel = `D${dynamicLevelInt}`;
  //   seekParticle = ex.substr(1, ex.length - 2);
  // }
  return { dLevel, seekParticle, dotRest };
}
