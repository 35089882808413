export const NOTIFICATIONS_SEND_MESSAGE = "NOTIFICATIONS_SEND_MESSAGE";
export const NOTIFICATIONS_DISMISS_MESSAGE = "NOTIFICATIONS_DISMISS_MESSAGE";
export const NOTIFICATIONS_DISMISS_ALL_BY_TYPE =
  "NOTIFICATIONS_DISMISS_ALL_BY_TYPE";

export const VERSION_PING_UI_READ = "VERSION_PING_UI_READ";
export const VERSION_PING_API_READ = "VERSION_PING_API_READ";

export const BROWSER_HISTORY_CHANGE = "BROWSER_HISTORY_CHANGE";

export const PROJECT_LOADED = "PROJECT_LOADED";
export const PROJECT_LOAD = "PROJECT_LOAD";
export const PROJECT_SAVE = "PROJECT_SAVE";
export const PROJECT_CONST_LOADED = "PROJECT_CONST_LOADED";

export const SMF_GOALS_LOADED = "SMF_GOALS_LOADED";
export const SMF_GOALS_ACTIONS_LOADED = "SMF_GOALS_ACTIONS_LOADED";
export const SMF_GOALS_ACTIONS_UPDATE = "SMF_GOALS_ACTIONS_UPDATE";
export const SMF_SETTINGS_LOADED = "SMF_SETTINGS_LOADED";

export const BP_NAV_PAGE_CHANGE = "BP_NAV_PAGE_CHANGE";
export const BP_NAV_SUBPAGE_CHANGE = "BP_NAV_SUBPAGE_CHANGE";
export const BP_NAV_CHANGE = "BP_NAV_CHANGE";
export const BP_NAV_CUSTOM_VIEW = "BP_NAV_CUSTOM_VIEW";

export const BP_RESET_ALL = "BP_RESET_ALL";
export const BP_BUDGET = "BP_BUDGET";
export const BP_NEW_LINE_ADDED = "BP_NEW_LINE_ADDED";
export const BP_LOAD_LINES = "BP_LOAD_LINES";
export const BP_LINE_EXPAND = "BP_LINE_EXPAND";
export const BP_LINES_HAS_ERRORS = "BP_LINES_HAS_ERRORS";
export const BP_OPEN_SAVEDIALOG = "BP_OPEN_SAVEDIALOG";

export const BP_OPEN_DELETEDIALOG = "BP_OPEN_DELETEDIALOG";

export const BP_NAV_OPEN_TACTICS_POPUP = "BP_NAV_OPEN_TACTICS_POPUP";

export const BP_TR_EXPAND = "BP_TR_EXPAND";
export const BP_TR_LINES = "BP_TR_LINES";

export const BP_FILTERS = "BP_FILTERS";
export const BP_DATABUS = "BP_DATABUS";
export const BP_DATABUS_FORMATTING = "BP_DATABUS_FORMATTING";

export const BP_TR_LANDING_PAGE = "BP_TR_LANDING_PAGE";
export const BP_TR_LANDING_PAGE_ADD = "BP_TR_LANDING_PAGE_ADD";

export const BP_AD_POPUP_OPEN = "BP_AD_POPUP_OPEN";
export const BP_AD_POPUP_CLOSE = "BP_AD_POPUP_CLOSE";

export const BP_LINE_ACTION = "BP_LINE_ACTION";
export const BP_LINE_ACTION_SUB = {
  ADD: "BP_LINE_ACTION_SUB.ADD",
  DEL: "BP_LINE_ACTION_SUB.DEL",
  ALT: "BP_LINE_ACTION_SUB.ALT",
  CLO: "BP_LINE_ACTION_SUB.CLO",
  CLORESET: "BP_LINE_ACTION_SUB.CLORESET",
  ALT_MAS: "BP_LINE_ACTION_SUB.ALT_MAS",
  RESET: "BP_LINE_ACTION_SUB.RESET",
};

export const CM_DATA_PLATFORMS = "CM_DATA_PLATFORMS";
export const CM_LOADED_FLAGS = "CM_LOADED_FLAGS";
export const CM_LOADED_DATA_VIEWS = "CM_LOADED_DATA_VIEWS";
export const CM_RELOADED_DATA_VIEWS = "CM_RELOADED_DATA_VIEWS";
export const CM_LOADED_DATA_LISTS = "CM_LOADED_DATA_LISTS";
export const CM_RELOADED_DATA_LISTS = "CM_RELOADED_DATA_LISTS";
export const CM_LOADED_DATA_METAS = "CM_LOADED_DATA_METAS";

export const CM_METAS_EDITOR_OPEN = "CM_METAS_EDITOR_OPEN";

export const CM_USERS_LOAD = "CM_USERS_LOAD";

export const BUS_FIRE = "BUS_FIRE";

export const MODALS_OPEN = "MODALS_OPEN";
export const MODALS_CLOSE = "MODALS_CLOSE";
export const CALL_FOR_RELOAD = "CALL_FOR_RELOAD";

export const VIEWS_LOADED = "VIEWS_LOADED";

export const MEDIAPLAN_LOADED = "MEDIAPLAN_LOADED";
export const MEDIAPLAN_FILTERS = "MEDIAPLAN_FILTERS";
export const MEDIAPLAN_UPDATE_SINGLE = "MEDIAPLAN_UPDATE_SINGLE";
export const MEDIAPLAN_EXPANDED = "MEDIAPLAN_EXPANDED";
export const MEDIAPLAN_RELOAD = "MEDIAPLAN_RELOAD";
export const MEDIAPLAN_RECALC = "MEDIAPLAN_RECALC";
export const MEDIAPLAN_VIEW = "MEDIAPLAN_VIEW";
export const MEDIAPLAN_HOOKS_VALIDATION = "MEDIAPLAN_HOOKS_VALIDATION";

export const USER_SETTINGS = "USER_SETTINGS";
