import React from "react";
import Tabs from "antd/lib/tabs";
import PropTypes from "prop-types";
import ClientDefaults from "../../../modals/clientDefaults/ClientDefaults";
import ClientDefaultsCC from "./ClientDefaultsCC";

class ClientDefaultView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeKey: "views" };
  }
  onChange = activeKey => {
    this.setState({ activeKey });
  };
  render() {
    return (
      <div className="ClientDefaultView">
        <Tabs
          tabPosition="left"
          type="card"
          onChange={this.onChange}
          activeKey={this.state.activeKey}
        >
          <Tabs.TabPane tab="Defaults" key="defaults">
            <ClientDefaults clientId={this.props.clientId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Views" key="views">
            {this.state.activeKey === "views" && (
              <ClientDefaultsCC clientId={this.props.clientId} />
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ClientDefaultView;

ClientDefaultView.propTypes = {
  clientId: PropTypes.number.isRequired
};
