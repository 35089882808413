import React, { Component } from 'react';

// imports prapared to go under micro packages rather installing whole d3.
// this is left for later optimisation
import {
  select as d3select, // d3-selection
  event as d3event, // d3-selection
  line as d3line, // 'd3-shape'
  curveCardinal, // 'd3-shape'
  timeParse as d3timeParse, // d3-time-format
  timeFormat as d3timeFormat, // d3-time-format
  scaleTime as d3scaleTime, // d3-scale
  scaleLinear as d3scaleLinear, // d3-scale
  axisBottom as d3axisBottom, // axis
  extent as d3extent, // axis
} from 'd3';

import './LineChart.scss';

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.theDiv = '';
    this.tooltip = '';
    this.margin = {
      top: 5,
      right: 5,
      bottom: 10,
      left: 5,
    };
    this.data = [
      {
        date: '2018-02-16',
        value: 10,
      },
      {
        date: '2018-02-15',
        value: 5,
      },
      {
        date: '2018-02-14',
        value: 1,
      },
      {
        date: '2018-02-13',
        value: 6,
      },
      {
        date: '2018-02-12',
        value: 8,
      },
      {
        date: '2018-02-11',
        value: 7,
      },
      {
        date: '2018-02-10',
        value: 4,
      },
    ];
  }

  componentDidMount() {
    // set the dimensions and this.margins of the graph

    const width = 300 - this.margin.left - this.margin.right;
    const height = 130 - this.margin.top - this.margin.bottom;

    // draving main element
    const svg = d3select(this.theDiv);
    const g = svg
      .append('svg')
      .attr('width', width + this.margin.left + this.margin.right)
      .attr('height', height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    const div = d3select(this.tooltip)
      .attr('class', 'tooltip')
      .style('opacity', 0);

    const parseTime = d3timeParse('%Y-%m-%d');

    // Data parsing to d3js readable format.
    this.data.forEach((d) => {
      /* eslint-disable */
      d.date = parseTime(d.date);
      d.value = +d.value;
      /* eslint-enable */
    });

    // generators functions
    const xAxis = d3scaleTime().rangeRound([0, width]);
    const yAxis = d3scaleLinear().rangeRound([height, 0]);
    const lineGeneratorFn = d3line()
      .x(d => xAxis(d.date))
      .y(d => yAxis(d.value))
      .curve(curveCardinal);

    xAxis.domain(d3extent(this.data, d => d.date));
    yAxis.domain(d3extent(this.data, d => d.value));

    // day labels
    g
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3axisBottom(xAxis)
        .tickSizeInner(0) // sets tick stroke line to 0
        .ticks(7) // sets how many "days" is shown, it might be different number, not
        .tickFormat((d) => {
          const label = d3timeFormat('%a')(new Date(d));
          return label.substring(0, 2);
        }))
      .select('.domain')
      .remove();

    // line value
    g
      .append('path')
      .datum(this.data)
      .attr('class', 'line')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1.5)
      .attr('d', lineGeneratorFn);

    // tooltip
    g
      .selectAll('dot')
      .data(this.data)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('r', 2)
      .attr('cx', d => xAxis(d.date))
      .attr('cy', d => yAxis(d.value))
      .on('mouseover', (d) => {
        div
          .transition()
          .duration(200)
          .style('opacity', 0.9);
        div
          .html(d.value)
          .style('left', `${d3event.pageX}px`)
          .style('top', `${d3event.pageY}px`);
      })
      .on('mouseout', () => {
        div
          .transition()
          .duration(500)
          .style('opacity', 0);
      });
  }

  render() {
    return (
      <div className="LineChart">
        <div className="chart-outer">
          <div
            ref={(component) => {
              this.theDiv = component;
            }}
          />
          <div
            ref={(compo) => {
              this.tooltip = compo;
            }}
          />
        </div>
      </div>
    );
  }
}

export default LineChart;
