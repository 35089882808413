import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import './ProjectBlockingMainView.scss';
import FileDownloadImg from '../../../shared/icons/file-download.svg';
import { FILE_SIZE_MAX } from '../../../shared/Constants';
import Notify from '../../../utils/Notify';
import { bytesToSize } from '../../../utils/StringUtils';
import Notifications from '../../../shared/notifications/Notifications';

class ProjectBlockingEmpty extends Component {
  onFileDrop(files) {
    // if file was rejected files array is empty.
    if (files[0]) {
      if (files[0].preview) {
        window.URL.revokeObjectURL(files[0].preview);
      }
      this.props.onFileUploaded(files[0]);
    }
  }

  onDropRejected = (file) => {
    if (file[0].size > FILE_SIZE_MAX) {
      const { size } = file[0];
      const max = FILE_SIZE_MAX;
      Notify.fireWarn(`File size too large (${bytesToSize(size)}), max upload file size is ${bytesToSize(max)}`);
    }
  };

  render() {
    return (
      <div className="ProjectBlockingEmpty">
        <div className="u-padding-both-sides-25 u-padding-top-10">
          <Notifications />
        </div>
        <div className={`ld-over ${this.props.showLoading ? 'running' : 'not-running'}`}>
          <span className="ld ld-ring ld-spin" />
          <div className="u-padding-both-sides-25">
            <div className="empty-header u-vertical-middle">
              <p>
                Cannot find any
                {' '}
                {this.props.type === 'blocking' ? (
                  <span>blocking chart</span>
                ) : (
                  <span>trafficing sheet</span>
                )}
                {' '}
                .
                <button className="button clear donwload-template">
                  <img src={FileDownloadImg} alt="" className="down-img" />
                  Download
                  {' '}
                  {this.props.type === 'blocking' ? (
                    <span>blocking chart</span>
                  ) : (
                    <span>trafficing sheet</span>
                  )}
                  {' '}
                  template
                </button>
              </p>
            </div>

            <div className="file-upload-oute">
              <Dropzone
                multiple={false}
                className="file-dnd"
                maxSize={FILE_SIZE_MAX}
                onDrop={e => this.onFileDrop(e)}
                onDropRejected={e => this.onDropRejected(e)}
              >
                <p>
                  Drag a
                  {' '}
                  {this.props.type === 'blocking' ? (
                    <span>Blocking Chart</span>
                  ) : (
                    <span>Trafficing Sheet</span>
                  )}
                  {' '}
                  excel file and drop it here
                  {' '}
                  <br />
                  Or click here to Attach a file.
                  <br />
                  <input className="button add-empty" type="button" value="Add File..." />
                </p>
              </Dropzone>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectBlockingEmpty;

ProjectBlockingEmpty.propTypes = {
  onFileUploaded: PropTypes.func.isRequired,
  showLoading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['blocking', 'traffic']).isRequired,
};
