/**
 * Basic EventManager
 */

const TYPES = {
  WARN: {
    ERROR: 'warn.error',
    WARN: 'warn.warn',
    INFO: 'warn.info',
  },
  PROJECT: {
    ONLOAD: 'project.onload',
  },
  NAVIGATION: {
    RELOAD: 'navigation.reload',
  },
  WINDOW: {
    SCROLL: 'windown.scroll',
  },
};

const eventManager = {
  eventList: new Map(),
  /**
   * Event registartion
   * @param {string} event
   * @param {func} callback
   */
  on(event, callback) {
    /* eslint-disable */
    this.eventList.has(event) || this.eventList.set(event, []);
    /* eslint-enable */
    this.eventList.get(event).push(callback);

    return this;
  },
  /**
   * Event de-registration
   * @param {string} event
   */
  off(event = null) {
    return this.eventList.delete(event);
  },
  /**
   * Emitter!
   * @param {string} event
   * @param {*} args
   */
  emit(event, ...args) {
    if (!this.eventList.has(event)) {
      /* eslint no-console: 0 */
      console.warn(`<${event}> Event is not registered. Did you forget to bind the event ?`);
      return false;
    }

    this.eventList
      .get(event)
      .forEach(callback => setTimeout(() => callback.call(this, ...args), 0));

    return true;
  },
  // short hand for types, for less verbose imports.
  TYPES_MAP: TYPES,
};

export default eventManager;
export { TYPES };

// added for testing
window.eventManager = eventManager;
