/* eslint-disable no-use-before-define */
import ReactGA from 'react-ga';
/**
 * Docs from https://github.com/react-ga/react-ga
 */

const isEnabled = process.env.REACT_APP_GA === 'true';

/**
 * Initing GA
 */
export const GAinit = () => {
  if (isEnabled) {
    const debug = process.env.REACT_APP_GA_DEBUG === 'true';
    ReactGA.initialize(process.env.REACT_APP_GA_CODE, { debug });
    // GAPageView(window.location.pathname + window.location.search);
  }
};

/**
 * Setting user id, after user logins.
 * @param {string} userId - from user.id
 */
export const GAsetUser = (userId) => {
  if (isEnabled) {
    ReactGA.set({ userId });
  }
};

/**
 * For actions which trigger url change.
 * @param {string} path
 */

export const GAPageView = (path) => {
  if (isEnabled) {
    ReactGA.pageview(path);
  }
};

/**
 * For actions which don't trigger url change.
 * @param {string} path
 */
export const GAModalView = (path) => {
  if (isEnabled) {
    ReactGA.modalview(path);
  }
};

export const GAPerfAPI = (time, endpoint) => {
  if (isEnabled) {
    ReactGA.timing({
      category: 'API',
      variable: endpoint,
      value: time,
      label: 'API response time',
    });
  }
};

export const GAPerfUI = (time, name) => {
  if (isEnabled) {
    ReactGA.timing({
      category: 'UI',
      variable: name,
      value: time,
      label: 'UI render time',
    });
  }
};

export const GAEvent = (category, action, label) => {
  if (isEnabled) {
    ReactGA.event({
      category,
      action,
      label,
      nonInteraction: true,
    });
  }
};

/**
 * Expermental Error Handling tracking.
 * @param {string} type - one of ERRORS.TYPES
 */
export const GACrashEvent = (type) => {
  GAEvent('Error', 'Crash', type);
};

/** Expermental Exception Logging
 * @param {string} msg - JS error text
 * @param {bool} fatal - flag if error is fatal
 */
export const GAExceptionLog = (msg, fatal = false) => {
  if (isEnabled) {
    ReactGA.exception({
      description: msg,
      fatal,
    });
  }
};
