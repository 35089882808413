/**
 * Parse url to object.
 * @param {string} url usualy win.location.search.
 * @returns {object} url params as object.
 */
const parseQueryString = (url) => {
  const urlParams = {};
  url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
    urlParams[$1] = $3;
  });
  return urlParams;
};

/**
 * Returns string from url
 * @param {string} needle what we are looking for
 * @returns {string} found string
 */
const getQueryStringParam = (needle) => {
  const params = parseQueryString(window.location.search);
  return params[needle];
};

/**
 * Fn to find param after slug like /my/id/123, fn(id) -> 123.
 * @param {string} needle - slug before needle
 * @param {number} [extra=1] - how many after slug,
 * for extra=2 - example /my/id/123/settings -> settings
 * @returns {string}
 */
const getParamAfterSlug = (needle, extra = 1) => {
  const path = window.location.pathname.split('/');
  const pos = path.indexOf(needle);
  return path[pos + extra];
};

export { parseQueryString, getQueryStringParam, getParamAfterSlug };
