import React from 'react';
import SaveButton from '../../../shared/saveWithLoadingBtn/SaveWithLoadingBtn';
import './ProfileChangePwd.scss';

class ProfileChangePwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      success: false,
      loading: false,
      doNotMatch: false,
      data: {
        old: '',
        new1: '',
        new2: '',
      },
    };
  }

  onChange = (ev) => {
    const { name, value } = ev.target;
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  onBlur = () => {
    const doNotMatch = this.state.data.new1 !== this.state.data.new2;
    this.setState({ doNotMatch });
  };

  onSave = () => {
    this.setState({ loading: true });
  };

  render() {
    return (
      <div className="ProfileChangePwd">
        <div className="grid-x change-pwd">
          <div className="cell small-12 title">Change Password</div>
        </div>
        <div className="grid-x">
          <div className="cell small-10">
            <label className=" middle">Current</label>
            <input
              type="password"
              name="old"
              value={this.state.data.old}
              onChange={e => this.onChange(e)}
            />
          </div>
        </div>
        <div className="grid-x">
          <div className="cell small-10">
            <label className=" middle">New</label>
            <input
              type="password"
              name="new1"
              value={this.state.data.new1}
              onChange={e => this.onChange(e)}
            />
          </div>
        </div>
        <div className="grid-x">
          <div className="cell small-10">
            <label className="middle">New 2</label>
            <input
              type="password"
              name="new2"
              value={this.state.data.new2}
              onChange={e => this.onChange(e)}
              onBlur={e => this.onBlur(e)}
            />
            {this.state.doNotMatch ? (
              <div className="error">Passwords do not match.</div>
            ) : (
              <span />
            )}
          </div>
        </div>
        <div className="grid-x">
          <div className="cell small-10">
            <div className="btn">
              <SaveButton
                label="Change"
                onSave={() => this.onSave()}
                loading={this.state.loading}
                success={this.state.success}
                disable={this.state.disable}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileChangePwd;
