import React from 'react';
import CompanyLogo from '../companyLogo/CompanyLogo';
import AccountMenu from '../accountMenu/AccountMenu2';
import './CompanyAndUser.scss';

const CompanyAndUser = () => (
  <div className="CompanyAndUser">
    <CompanyLogo />
    <AccountMenu />
  </div>
);

export default CompanyAndUser;
