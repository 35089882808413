import React from 'react';
import PropTypes from 'prop-types';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import ProfilePicture from '../../../shared/profilepictures/ProfilePicture';
import { getUserNames } from '../../ProjectsService';

const ActivityLogNoteLine = ({ user, noteText, date }) => (
  <div className="ActivityLogNoteLine line">
    <div className="grid-x">
      <div className="cell small-1">{user ? <ProfilePicture user={user} /> : <span />}</div>
      <div className="cell small-11">
        <span className="user">
          <span className="u-gray">{getUserNames(user)}</span>
        </span>
        {' '}
        <span className="note">{noteText}</span>
        {' '}
        <span className="date" title={date}>
          <em className="u-gray">
(
            {distanceInWordsToNow(date, { includeSeconds: true })}
)
          </em>
        </span>
      </div>
    </div>
  </div>
);

export default ActivityLogNoteLine;

ActivityLogNoteLine.propTypes = {
  user: PropTypes.object.isRequired,
  noteText: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
