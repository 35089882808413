import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import './ProjectReports.scss';
import FileTypes from '../fileTypes/FileTypes';
import { DEFAULT_DATE_FORMAT_SHORTER } from '../../../shared/Constants';

const ProjectReports = ({ reports, showLoading, projectId }) => (
  <div className="ProjectReports">
    <div className="headline">
      <h6>Files</h6> &nbsp;&nbsp;
      <Link to={`/projects/${projectId}/reports/`}>See all</Link>
    </div>
    <div className={`ld-over ${showLoading ? 'running' : 'not-running'}`}>
      <span className="ld ld-ring ld-spin" />
      {reports.map(obj => (
        <div className="grid-x line" key={obj.file.id}>
          <div className="cell small-12">
            <FileTypes type={obj.file.ext} />
            &nbsp;{' '}
            <Link to={`/projects/${projectId}/reports/`}>
              {obj.file.name}
            </Link>{' '}
            &nbsp;
            <span className="u-gray">
              ({format(obj.file.dateCreated, DEFAULT_DATE_FORMAT_SHORTER)})
            </span>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default ProjectReports;

ProjectReports.defaultProps = {
  showLoading: false,
};

ProjectReports.propTypes = {
  reports: PropTypes.array.isRequired,
  projectId: PropTypes.number.isRequired,
  showLoading: PropTypes.bool,
};
