import React, { Component } from "react";
import "antd/dist/antd.css";
import { Table, Popconfirm, Select, Button, notification, Icon } from "antd";

import ProjectsService from "../../projects/ProjectsService";
import CreateNewModal from "./CreateNewModal";
import NextFinish from "./NextFinish";

const Option = Select.Option;
const PARTNER = "partner";

export class PartnerAdvertiserTable extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectsService();
    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        render: name => `${name}`
      },
      {
        title: "Type",
        dataIndex: "partner",
        render: record => (record ? "Partner" : "Advertiser")
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => (
          <Select
            onChange={val => this.handleChangeStatus(record, val)}
            value={text}
            style={{ width: 150 }}
          >
            <Option value="approved">Approved</Option>
            <Option value="rejected">Rejected</Option>
          </Select>
        )
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <Popconfirm
            title="Are you sure? All of this customer's projects and partners or advertisers will also be deleted. Cannot undo once page is saved."
            placement="left"
            okText="Yes"
            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
            onConfirm={() => this.handleDelete(record)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        )
      }
    ];

    this.state = {
      partnersAdvertisers: this.props.partners,
      pAsToBeEditted: []
    };
  }

  addNewPartner = values => {
    const parentId = this.props.client.id;
    const newPAs = [...this.state.partnersAdvertisers];
    const newPAsToAdd = [...this.state.pAsToBeEditted];
    const newId = Date.now();
    const newPA = {
      id: newId,
      parent: parentId,
      name: values.name,
      partner: values.partOrAdv === PARTNER,
      status: null,
      isNew: true
    };
    const newPAtoAdd = {
      id: newId,
      name: values.name,
      parentId: parentId,
      partner: false,
      isNew: true
    };
    newPAs.push(newPA);
    newPAsToAdd.push(newPAtoAdd);
    this.setState({ partnersAdvertisers: newPAs, pAsToBeEditted: newPAsToAdd });
  };

  handleDelete = pA => {
    const newPAs = this.state.partnersAdvertisers.filter(pa => pa.id !== pA.id);
    const newPAsToDelete = this.state.pAsToBeEditted.filter(
      pa => pa.id !== pA.id
    );
    if (pA.isNew) {
      this.setState({
        partnersAdvertisers: newPAs,
        pAsToBeEditted: newPAsToDelete
      });
    } else {
      const newPAtoDelete = {
        id: pA.id,
        toDelete: true
      };
      newPAsToDelete.push(newPAtoDelete);
      this.setState({
        partnersAdvertisers: newPAs,
        pAsToBeEditted: newPAsToDelete
      });
    }
  };

  handleChangeStatus = (pA, val) => {
    const clone = this.state.partnersAdvertisers.map(pa => {
      if (pa.id !== pA.id) return pa;
      pa.status = val;
      return pa;
    });
    if (this.state.pAsToBeEditted.some(pa => pa.id === pA.id)) {
      const newToBeEditted = this.state.pAsToBeEditted.map(pa => {
        if (pa.id !== pA.id) return pa;
        pa.status = val;
        return pa;
      });
      this.setState({
        partnersAdvertisers: clone,
        pAsToBeEditted: newToBeEditted
      });
    } else {
      const newToBeEditted = [...this.state.pAsToBeEditted];
      const newPAtoEdit = {
        id: pA.id,
        status: val,
        toEdit: true
      };
      newToBeEditted.push(newPAtoEdit);
      this.setState({
        partnersAdvertisers: clone,
        pAsToBeEditted: newToBeEditted
      });
    }
  };

  showMessage = (type, title, descr) => {
    let dur = 0;
    if (type === "success") {
      dur = 2;
    }
    notification[type]({
      message: title,
      description: descr,
      duration: dur,
      placement: "bottomRight"
    });
  };

  onSave = () => {
    this.setState({ pAsToBeEditted: [] });
    this.showMessage(
      "success",
      "Success!",
      "Your client`s partners and advertisers have been updated."
    );
  };

  onFail = () => {
    this.showMessage(
      "error",
      "Error!",
      "Something went wrong! Partners and advertisers did not save, please check info and try again."
    );
  };

  render() {
    const { client, changeTab, tabKey } = this.props;
    return (
      <div>
        <div align="right">
          <CreateNewModal
            buttonText="Add New Partner/ Advertiser"
            modalTitle="Add a New Partner or Advertiser"
            addNew={this.addNewPartner}
            mustCheck={true}
          />
        </div>
        <div>
          <Table
            columns={this.columns}
            dataSource={this.state.partnersAdvertisers}
            rowKey={record => record.id}
            size="middle"
            expandIconAsCell={false}
            expandIconColumnIndex={-1}
          />
        </div>
        <div align="right">
          <NextFinish
            client={client}
            edittedData={this.state.pAsToBeEditted}
            onSave={() => this.onSave()}
            onFail={() => this.onFail()}
            tabType={"PA"}
            changeTab={changeTab}
            tabKey={tabKey}
          />
        </div>
      </div>
    );
  }
}

export default PartnerAdvertiserTable;
