import React from 'react';

import AuthService from './AuthService';
/**
 * LogoutPage, just loges user out and redirects to /login.
 */
const LogoutPage = () => {
  const auth = new AuthService();
  auth.logout();
  window.location.replace('/login');
  return <div>Logged out!</div>;
};

export default LogoutPage;
