/* eslint no-nested-ternary: 0 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {
  getMetaListFromCommon,
  getMetaListsFromCommon
} from "../../../selectors/commonSelectors";
import { UUID } from "../../../utils/StringUtils";
import { bpServiceStatic } from "../../../pages/bp/BPService";
import {
  getAllColsBySortedByCols,
  initViews
} from "../../../pages/bp/BPViewService";
import "./MetasList.scss";

class MetaList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      rawList: {}
    };
  }

  componentDidMount() {
    this.onListStart();
  }

  onListStart = () => {
    let tmpList = [];
    this.listId = this.getListNumber(
      this.props.field.name,
      this.props.projectId
    );
    if (this.props.field.options && this.props.field.options.length > 0) {
      tmpList = getMetaListFromCommon(this.props.projectId, this.listId);
    }
    const rawList = {};
    tmpList.forEach(element => {
      //TODO make proper checking.
      if (element.type === "dynamic" + this.props.dynamicLevelInt) {
        rawList[element.id] = element;
      }
    });
    this.setState({
      rawList,
      loaded: true
    });
  };

  onSave = () => {
    this.err = false;
    const grandListObj = getMetaListsFromCommon(this.props.projectId);
    const rawArr = [];
    const rawCopy = { ...this.state.rawList };
    Object.values(rawCopy).forEach(elem => {
      /* eslint-disable */
      if (elem._isNew) {
        delete elem.id;
      }
      //TODO make proper type.
      elem.type = "dynamic" + this.props.dynamicLevelInt;
      elem.externalId = elem.externalId ? elem.externalId.toString() : null;
      elem.externalValue = elem.externalValue
        ? parseFloat(elem.externalValue, 10)
        : null;
      elem.externalValue2 = elem.externalValue2
        ? parseFloat(elem.externalValue2, 10)
        : null;
      elem.listValue = elem.listValue.trim();
      /* eslint-enable */
      rawArr.push(elem);
      // if (elem.listValue.trim().length > 0) {
      // }
    });

    grandListObj.lists[this.listId] = rawArr;

    bpServiceStatic.updateList(
      this.props.projectId,
      grandListObj,
      () => {
        bpServiceStatic.loadLists(this.props.projectId, () => {
          initViews(this.props.projectId, () => {
            this.props.onSaved();
            this.onListStart();
          });
        });
      },
      () => {
        this.props.onFailed();
      }
    );
  };

  onChange = (item, event) => {
    const rawClone = { ...this.state.rawList };
    const option = rawClone[item.id];
    const key = event.target.name;
    const val = event.target.value;
    option[key] = val;
    rawClone[item.id] = option;
    this.setState({ rawList: rawClone });
  };

  getListNumber = (fieldName, projectId) => {
    const allColsView = getAllColsBySortedByCols(projectId);
    const field = allColsView[fieldName];
    if (field && field.listId) {
      return field.listId;
    } else {
      return null;
      // const all = getMetaListsFromCommon(projectId);
      // console.info('[MetasLists.jsx] all', all);
    }
  };

  addNew = () => {
    const rawClone = { ...this.state.rawList };
    const rid = UUID();
    rawClone[rid] = {
      externalId: "",
      externalValue: 0,
      externalValue2: 0,
      listValue: "",
      listNumber: this.listId,
      id: rid,
      _rid: rid,
      _isNew: true
    };
    this.setState({ rawList: rawClone });
  };

  render() {
    //TODO: Add sorting when we have delete fn
    // const sortedList = Object.values(this.state.rawList).sort((a, b) => {
    //   const listValueA = a.listValue ? a.listValue : "";
    //   const listValueB = b.listValue ? b.listValue : "";
    //   if (listValueA === "") return 1;
    //   if (listValueB === "") return -1;
    //   return listValueA.localeCompare(listValueB);
    // });
    return (
      <div className="MetaList">
        {this.state.loaded && (
          <div className="someLongX">
            <div className="grid-x">
              <div className="cell small-1" />
              <div className="cell small-11">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th>Value 2</th>
                      <th>Value Id</th>
                    </tr>
                  </thead>

                  <tbody className="real-body">
                    {Object.values(this.state.rawList).map(item => (
                      <tr key={item.id ? item.id : item._rid}>
                        <td>
                          <input
                            type="text"
                            name="listValue"
                            value={item.listValue || ""}
                            onChange={e => {
                              this.onChange(item, e);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            step="any"
                            name="externalValue"
                            value={item.externalValue || ""}
                            onChange={e => {
                              this.onChange(item, e);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            step="any"
                            name="externalValue2"
                            value={item.externalValue2 || ""}
                            onChange={e => {
                              this.onChange(item, e);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="externalId"
                            value={item.externalId || ""}
                            onChange={e => {
                              this.onChange(item, e);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="4">
                        <Button onClick={this.addNew}>Add New</Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MetaList;

MetaList.defaultProps = {
  field: {}
};

MetaList.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onSaved: PropTypes.func.isRequired,
  onFailed: PropTypes.func.isRequired,
  dynamicLevelInt: PropTypes.number.isRequired,
  field: PropTypes.object
};
