import React from 'react';
import { Input, Modal } from 'antd';
const confirm = Modal.confirm;

const showConfirmDelete = (projectDocket, onConfirm, onFail) => {
  let value = '';
  const onChangeHandler = e => {
    value = e.target.value;
  };
  return confirm({
    title: (
      <span>
        This action cannot be undone. <br /> Please enter project code to
        confirm.
      </span>
    ),
    content: (
      <span>
        <Input onChange={onChangeHandler} placeholder="Project Code" />
      </span>
    ),
    onOk() {
      if (value === projectDocket) {
        onConfirm();
      } else {
        onFail();
      }
    },
    onCancel() {},
  });
};

export { showConfirmDelete };
