import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';

const styleRedBtn = {
  root: {
    width: '14px',
    height: '14px',
    color: red[500],
  },
};

const style14px = {
  root: {
    width: '14px',
    height: '14px',
  },
};

const styleGreenBtn = {
  root: {
    width: '14px',
    height: '14px',
    color: green[500],
  },
};

// Icons paths are inserted here manually, can't find way inserting it from file automatically.
// Reason for inserting this way that we can manipulate props without altering file.

function ErrorIconInner(props) {
  // ./svgicons/Error.svg
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </SvgIcon>
  );
}

function OkIconInner(props) {
  // ./svgicons/Ok.svg
  return (
    <SvgIcon {...props}>
      <path d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M11,16.5L6.5,12L7.91,10.59L11,13.67L16.59,8.09L18,9.5L11,16.5Z" />
    </SvgIcon>
  );
}

export const ErrorIcon = props => <ErrorIconInner {...props} />;
export const ErrorIconStyled = withStyles(styleRedBtn)(ErrorIcon);

export const OkIcon = props => <OkIconInner {...props} />;
export const OkIconStyled = withStyles(styleGreenBtn)(OkIcon);

// Icons from https://materialdesignicons.com/

const EyeOnSvg = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24">
      <path
        fill="#808080"
        d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"
      />
    </svg>
  </SvgIcon>
);
const EyeOffSvg = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24">
      <path
        fill="#808080"
        d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z"
      />
    </svg>
  </SvgIcon>
);
const ShuffleSvg = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24">
      <path
        fill="#808080"
        d="M17,3L22.25,7.5L17,12L22.25,16.5L17,21V18H14.26L11.44,15.18L13.56,13.06L15.5,15H17V12L17,9H15.5L6.5,18H2V15H5.26L14.26,6H17V3M2,6H6.5L9.32,8.82L7.2,10.94L5.26,9H2V6Z"
      />
    </svg>
  </SvgIcon>
);

const EditSvg = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24">
      <path
        fill="#808080"
        d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
      />
    </svg>
  </SvgIcon>
);

const ImportSvg = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24">
      <path
        fill="#808080"
        d="M6,2C4.89,2 4,2.9 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13,3.5L18.5,9H13M10.05,11.22L12.88,14.05L15,11.93V19H7.93L10.05,16.88L7.22,14.05"
      />
    </svg>
  </SvgIcon>
);

export const EyeOffIcon = withStyles(style14px)(EyeOffSvg);
export const EyeOnIcon = withStyles(style14px)(EyeOnSvg);
export const ShuffleIcon = withStyles(style14px)(ShuffleSvg);
export const EditIcon = withStyles(style14px)(EditSvg);
export const ImportIcon = withStyles(style14px)(ImportSvg);
