import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SSF from "ssf";

import {
  getDataBusSel,
  getDataBusFormattingSel
} from "../../../selectors/BPSelectors";
import SIGMA from "../../../shared/icons/sigma.svg";

const BPTableSumsInner = ({ valueArray, headerName, format }) => {
  if (headerName.indexOf("float") === 0 || headerName.indexOf("int") === 0) {
    if (Array.isArray(valueArray) && valueArray.length > 0) {
      let sum = valueArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue[headerName],
        0
      );
      if (format[headerName]) {
        sum = SSF.format(format[headerName], sum);
      }
      return (
        <span className="sums">
          <img src={SIGMA} alt="sum" />
          {sum}
        </span>
      );
    }
  }
  return <></>;
};

const mapStateToProps = (state, ownProps) => ({
  valueArray: getDataBusSel(state, ownProps.dynamicLevel),
  format: getDataBusFormattingSel(state, ownProps.dynamicLevel),
  headerName: ownProps.headerName
});

const BPTableSums = connect(mapStateToProps)(BPTableSumsInner);
export default BPTableSums;

BPTableSums.propTypes = {
  headerName: PropTypes.string.isRequired,
  dynamicLevel: PropTypes.string.isRequired
};

BPTableSumsInner.propTypes = {
  valueArray: PropTypes.array.isRequired
};
