import { createSelector } from 'reselect';
import { SUBTYPES } from '../actions/notificationActions';

export const getAllInfos = state => state.notifications.filter(item => item.type === SUBTYPES.INFO);
export const getAllWarns = state => state.notifications.filter(item => item.type === SUBTYPES.WARN);
export const getAllErrors = state => state.notifications.filter(item => item.type === SUBTYPES.ERROR);
export const getAllSystem = state => state.notifications.filter(item => item.type === SUBTYPES.SYSTEM);

export const getInfos = createSelector(getAllInfos, msgs => msgs.filter(msg => msg.dismissed === false));

export const getWarns = createSelector(getAllWarns, msgs => msgs.filter(msg => msg.dismissed === false));

export const getErrors = createSelector(getAllErrors, msgs => msgs.filter(msg => msg.dismissed === false));

export const getSystem = createSelector(getAllSystem, msgs => msgs.filter(msg => msg.dismissed === false));
