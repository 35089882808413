import store from "store";
import { ERRORS } from "../shared/Constants";
import { GACrashEvent } from "./GAUtils";
import Notify from "./Notify";
import * as Sentry from "@sentry/browser";

import ver from "../version-ui.json";

import StackdriverErrorReporter from "stackdriver-errors-js";

export const gcpErrorHandler = new StackdriverErrorReporter();
gcpErrorHandler.start({
  key: process.env.REACT_APP_GCP_ERROR_REPORTING_KEY,
  projectId: process.env.REACT_APP_GCP_ERROR_REPORTING_PROJECT_ID,
  service: "ui_" + process.env.REACT_APP_ENV,
  version: ver.version,
  disabled: !process.env.REACT_APP_GCP_ERROR_REPORTING
  // reportUncaughtExceptions: false
  // Set to false to prevent reporting unhandled exceptions, default: `true`.
  // reportUnhandledPromiseRejections: false
  // Set to false to prevent reporting unhandled promise rejections, default: `true`.

  // disabled: true
  // Set to true to not send error reports, this can be used when developing locally, default: `false`.

  // context: {user: 'user1'}
  // You can set the user later using setUser()
});

/**
 * Inits error tracker, sets flags and Sentry.
 */
const initErrorTracker = () => {
  const errorTrackinOn = process.env.REACT_APP_ERROR_TRACKING === "true";
  const errorTrackinToCL = process.env.REACT_APP_ERROR_CONSOLE === "true";
  console.info("errorTrackinOn", errorTrackinOn);
  console.info("errorTrackinCL", errorTrackinToCL);
  if (errorTrackinOn) {
    Sentry.init({
      dsn: process.env.REACT_APP_ERROR_TRACKING_URL,
      environment: process.env.REACT_APP_ENV
    });
  }
  store.set(ERRORS.STORE_KEY, errorTrackinToCL);
};

/**
 * Sets user context for Sentry tracking. So we know who crashed.
 * @param {object} user Loaded user
 */
const setErrorUserContext = user => {
  Sentry.setUser({ email: user.username, id: user.id });
  gcpErrorHandler.setUser(Number(user.id).toString());
};

/**
 * Logs to console if .env is set
 * @param {object} err Error
 * @param {const} type from ERRORS.TYPES
 * @param {any} extra extra info to pass
 */
const logToCL = (err, type, extra) => {
  if (store.get(ERRORS.STORE_KEY)) {
    console.log(err, type, extra);
  }
  gcpErrorHandler.report(err);
};

/**
 * Error logging to callback, logs to CL if set and to Sentry if set.
 * @param {object} err Error
 * @param {func} callbackFail callback function to pass up
 * @param {const} type from ERRORS.TYPES
 * @param {any} extra extra info to passs
 */
const errorLogWithCallback = (
  err,
  callbackFail,
  type = ERRORS.TYPES.OTHER,
  extra = "no extra"
) => {
  logToCL(err, type, extra);
  // this p1,p2 hack is used to fire events without triggering parent catch to
  // exit premature wrapping all in Promises lets do it because promises creats
  // their own error scopes, thus don't trigger main one
  const p1 = new Promise(resolve => {
    console.log("[SENTRY] sending error");
    Sentry.configureScope(function(scope) {
      scope.setExtra(extra);
      Sentry.captureException(err);
    });
    resolve();
  });
  const p3 = new Promise(resolve => {
    console.log("[GCP] sending error");
    gcpErrorHandler.report(err);
    resolve();
  });
  const p2 = new Promise(resolve => {
    GACrashEvent(type);
    resolve();
  });
  p1.catch();
  p2.catch();
  p3.catch();

  if (callbackFail) {
    callbackFail(err);
  }
};

/**
 * Error logging, logs to CL if set and to SENTRY if set.
 * @param {object} err Error
 * @param {const} type from ERRORS.TYPES
 * @param {any} extra extra info to passs
 */

const errorLog = (err, type = ERRORS.TYPES.OTHER, extra = "no extra") => {
  logToCL(err, type, extra);
  const p1 = new Promise(resolve => {
    console.log("[SENTRY] sending error");
    Sentry.configureScope(function(scope) {
      scope.setExtra(extra);
      Sentry.captureException(err);
    });
    resolve();
  });
  const p3 = new Promise(resolve => {
    console.log("[GCP] sending error");
    gcpErrorHandler.report(err);
    resolve();
  });
  const p2 = new Promise(resolve => {
    GACrashEvent(type);
    resolve();
  });
  p1.catch();
  p2.catch();
  p3.catch();
};

/**
 * Global error catcher/wrapper, has ability to parse Errors if comes in Blob or plain text format
 * @param {object} err Error
 * @param {func} callback function
 */
const globalErrorCallback = (err, callbackFail) => {
  // special case when error message is blob, usually this comes from get files api
  if (err.response && err.response.data && err.response.data instanceof Blob) {
    const reader = new FileReader();
    reader.onload = function onload() {
      const resp = JSON.parse(reader.result);
      Notify.fireError(resp.message);
    };
    reader.readAsText(err.response.data);
  } else {
    // firstly we are tring to get any error msg in case data.message is not pressent
    let errMsg = err.toString();
    if (err.response && err.response.data && err.response.data.message) {
      errMsg = err.response.data.message;
    }
    Notify.fireError(errMsg);
  }
  errorLogWithCallback(err, callbackFail, ERRORS.TYPES.CAMP);
};

export {
  initErrorTracker,
  setErrorUserContext,
  errorLogWithCallback,
  errorLog,
  globalErrorCallback
};
