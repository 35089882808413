import React from "react";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";
import Checkbox from "antd/lib/checkbox";
import classnames from "classnames";
import { BP_TABLE, ERRORS, COLORRESET } from "../../../shared/Constants";
import { errorLog } from "../../../utils/ErrorTracker";
import { Switch } from "antd";

export const selectStyles = {
  option: (provided, state) => {
    return {
      ...provided,
      borderRadius: 1,
      padding: 4,
    };
  },
  control: () => ({
    width: 180,
  }),
  valueContainer: (provided, state) => {
    const padding = 1;
    return { ...provided, padding };
  },
  menu: (provided, state) => {
    const marginTop = 0;
    const marginBottom = 0;
    return { ...provided, marginTop, marginBottom };
  },
};

export const goFocus = (elementId, ms = 10) => {
  try {
    setTimeout(() => {
      document.getElementById(elementId) &&
        document.getElementById(elementId).focus();
    }, ms);
  } catch (error) {
    errorLog(error, ERRORS.TYPES.UI);
  }
};

const loadingIconStyle = {
  fontSize: "40px",
};

export const LoadingComponent = ({
  className,
  loading,
  loadingText,
  ...rest
}) => (
  <div
    className={classnames("-loading", { "-active": loading }, className)}
    {...rest}
  >
    <div className="-loading-inner">
      {loadingText}
      <br />
      <Icon style={loadingIconStyle} type="loading" />
    </div>
  </div>
);

export const ThComponent = ({
  toggleSort,
  className,
  children,
  columnId,
  colorClass,
  ...rest
  // props added via THProps
}) => {
  const noSorting = className.indexOf("-sort-") === -1;
  const sortAsc = className.indexOf("-sort-asc") > -1;
  const sortDesc = className.indexOf("-sort-desc") > -1;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      data-col={columnId}
      className={classnames(
        "rt-th bptable-th-custom-controls",
        `col-${columnId}`,
        colorClass,
        className
      )}
      role="columnheader"
      tabIndex="-1"
      {...rest}
    >
      <span onClick={(e) => toggleSort && toggleSort(e)}>
        {sortAsc && <Icon className="icon" type="sort-ascending" />}
        {sortDesc && <Icon className="icon" type="sort-descending" />}
        {noSorting && <Icon className="icon" type="column-height" />}
      </span>
      <span className="tr-th-child-wrap">{children}</span>
    </div>
  );
};

export const THProps = (
  state,
  rowInfo,
  column,
  instance,
  iconClickHandler,
  userSettings
) => {
  const colorClass = userSettings[column.id] || COLORRESET;
  return {
    columnId: column.id,
    colorClass: colorClass,
    onClick: (e, handleOriginal) => {
      let classList = [];
      if (e.target.nodeName.toUpperCase() === "PATH") {
        classList = e.target.parentElement.parentElement.classList;
      } else {
        classList = e.target.classList;
      }

      if (classList.value.indexOf(BP_TABLE.ICON_CLICK_HIDE) > -1) {
        iconClickHandler(column.id, BP_TABLE.ICON_CLICK_HIDE);
      }

      if (classList.value.indexOf(BP_TABLE.ICON_CLICK_EDIT) > -1) {
        iconClickHandler(column.id, BP_TABLE.ICON_CLICK_EDIT);
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
  };
};

export const FooterActionsButtons = ({
  addEmptyLine,
  handleSave,
  handleReCalc,
  handleAutoSaveToggle,
  autoSaveState,
}) => {
  return (
    <div className="custom-footer">
      <div className="custom-footer-elements">
        <div className="grid-x align-justify">
          <div className="cell-3">
            <div className="button-wrapper">
              <Button onClick={addEmptyLine} type="dashed" icon="plus" />
            </div>
          </div>
          <div className="cell auto" />
          <div className="cell-3">
            <div className="button-wrapper">
              <Button onClick={handleReCalc}>Re-Calc!</Button>{" "}
              <Button onClick={handleSave}>Save</Button>{" "}
              <Switch
                size="small"
                checked={autoSaveState}
                onChange={(val) => handleAutoSaveToggle(val)}
              />{" "}
              Auto Save{" "}
              <Icon
                type="experiment"
                title="This feature is experimental, may not work as expected"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FilterComponent = ({ filter, onChange, column }) => {
  if (column._fl_meta && column._fl_meta.noFilter) return <></>;
  // TODO: implement better search, where inputs are debounced.
  return (
    <span className="flex-container">
      <input
        type="text"
        placeholder={column.Placeholder}
        value={filter ? filter.value : ""}
        onChange={(event) => onChange(event.target.value)}
      />
      <label title="Filter Empty">
        <Checkbox
          className="checkbox-empty"
          onChange={(event) => {
            if (event.target.checked) {
              onChange(BP_TABLE.FILTER_EMPTY);
            } else {
              onChange("");
            }
          }}
        >
          E
        </Checkbox>
      </label>
    </span>
  );
};

export const ListFilter = (filter, onChange, column, getListForCol) => {
  const list = getListForCol(column.id);
  const noEmptyList = list.filter((l) => l.label.trim().length > 0);
  return (
    <span className="flex-container">
      <select
        onChange={(event) => onChange(event.target.value)}
        value={filter ? filter.value : ""}
      >
        <option value={""} />
        {noEmptyList.map((ls) => {
          return (
            <option key={ls.value} value={ls.value}>
              {ls.label}
            </option>
          );
        })}
        <option value={BP_TABLE.FILTER_EMPTY}>{BP_TABLE.FILTER_EMPTY}</option>;
      </select>
      <label title="Filter Empty">
        <Checkbox
          className="checkbox-empty"
          onChange={(event) => {
            if (event.target.checked) {
              onChange(BP_TABLE.FILTER_EMPTY);
            } else {
              onChange("");
            }
          }}
        >
          E
        </Checkbox>
      </label>
    </span>
  );
};

export const customDefaultFilter = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  if (BP_TABLE.FILTER_EMPTY === filter.value) {
    return !row[id];
  }
  return row[id] !== undefined
    ? String(row[id])
        .toLowerCase()
        .includes(filter.value.toLowerCase())
    : true;
};

export const moveInArray = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

const actionOnPadRow = (e, actionFn) => {
  try {
    for (let i = 0; i < 10; i++) {
      if (e.path[i].classList.contains("-padRow")) {
        actionFn();
        break;
      }
    }
  } catch (e) {}
};

export const listenForPadRowClicks = (actionFn) => {
  const fn = (e) => actionOnPadRow(e, actionFn);
  document.addEventListener("dblclick", fn);
  return () => {
    document.removeEventListener("dblclick", fn);
  };
};
