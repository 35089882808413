import React from "react";
import PropTypes from "prop-types";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import "./Fasthooks.scss";

const { Option } = Select;
const { TextArea } = Input;

const INIT_VALUES = {
  name: "",
  method: "POST",
  uri: "",
  header: "",
  body: "",
  parentId: "",
  synchronous: true,
};

class FasthooksBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hook: {},
      hookId: 0,
      values: INIT_VALUES,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.hookId !== nextProps.hook.id) {
      let tmpValues = INIT_VALUES;
      if (Object.keys(nextProps.hook).length !== 0) {
        tmpValues = { ...nextProps.hook };
      }
      return {
        hook: nextProps.hook,
        hookId: nextProps.hook.id,
        values: tmpValues,
      };
    }
    return null;
  }
  handelInput(event) {
    const { name, value } = event.target;
    const tmpValues = { ...this.state.values };
    tmpValues[name] = value;
    this.setState(
      {
        values: tmpValues,
      },
      () => {
        this.props.valuesCallback(this.state.values);
      }
    );
  }
  handelInput2(name, value) {
    const tmpValues = { ...this.state.values };
    tmpValues[name] = value;
    this.setState(
      {
        values: tmpValues,
      },
      () => {
        this.props.valuesCallback(this.state.values);
      }
    );
  }
  render() {
    const forParents = this.props.selectedEvent.hooks
      ? this.props.selectedEvent.hooks
      : [];
    const { values } = this.state;
    return (
      <div className="FasthooksBody grid-container padding-lr-5">
        <form onSubmit={this.handleSubmit}>
          <div className="grid-x">
            <div className="cell small-2">
              <span className="header-span">Event:</span>
            </div>
            <div className="cell small-10">
              <Input
                placeholder="Name"
                name="name"
                value={values.name || ""}
                onChange={(e) => this.handelInput(e)}
              />
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-4">
              <label>
                <span>Method</span>
                <Select
                  defaultValue={"POST"}
                  value={values.method}
                  onChange={(e) => this.handelInput2("method", e)}
                >
                  <Option value="GET">GET</Option>
                  <Option value="POST">POST</Option>
                  <Option value="DELETE">DELETE</Option>
                  <Option value="PUT">PUT</Option>
                </Select>
              </label>
            </div>
            <div className="cell small-8">
              <label>
                <span>URI</span>
                <Input
                  placeholder="URI"
                  name="uri"
                  value={values.uri || ""}
                  onChange={(e) => this.handelInput(e)}
                />
              </label>
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              <label>
                <span>Headers</span>
                <TextArea
                  value={values.header || ""}
                  onChange={(e) => this.handelInput(e)}
                  placeholder="Headers"
                  name="header"
                  autosize={{ minRows: 3, maxRows: 10 }}
                />
              </label>
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              <label>
                <span>Body</span>
                <TextArea
                  name="body"
                  placeholder="Body"
                  value={values.body || ""}
                  onChange={(e) => this.handelInput(e)}
                  autosize={{ minRows: 5, maxRows: 20 }}
                />
              </label>
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              <label>
                <span>
                  Parent (<i>Parents are from same event only</i>)
                </span>
                <Select
                  defaultValue={INIT_VALUES.parentId}
                  value={values.parentId}
                  onChange={(e) => this.handelInput2("parentId", e)}
                >
                  {forParents.map((p) => {
                    if (p.id === this.state.hookId) {
                      return (
                        <Option key={0} value="">
                          No parent - default
                        </Option>
                      );
                    }
                    return (
                      <Option key={p.id} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </label>
              <div className="cell small-12 push-top">
                <span>Mode {"  "}</span>
                <Radio.Group
                  value={values.synchronous}
                  onChange={(e) =>
                    this.handelInput2("synchronous", e.target.value)
                  }
                >
                  <Radio.Button value={true}>Synchronous</Radio.Button>
                  <Radio.Button value={false}>Asynchronous</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default FasthooksBody;

FasthooksBody.propTypes = {
  hook: PropTypes.object.isRequired,
  valuesCallback: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object.isRequired,
};
