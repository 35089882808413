import React from 'react';
import OrgProfileView from './OrgProfileView';
import UserProfileView from './UserProfileView';
import ProfileMenu from './ProfileMenu';
// import { profileServiceStatic } from '../profile/ProfileService';
import './ProfilePage.scss';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: 'user' };
  }

  onClick = (ev) => {
    this.setState({ active: ev });
  };

  pageToShow = () => {
    let retVal;
    switch (this.state.active) {
      case 'user':
        retVal = <UserProfileView />;
        break;
      case 'org':
        retVal = <OrgProfileView name="okkkk" />;
        break;
      default:
        retVal = <UserProfileView />;
        break;
    }
    return retVal;
  };

  render() {
    return (
      <div className="ProfilePage">
        <div className="u-padding-both-sides-25 u-padding-top-10">
          <div>
            <ProfileMenu active={this.state.active} onClick={ev => this.onClick(ev)} />
          </div>
          <div className="u-padding-top-10">{this.pageToShow()}</div>
        </div>
      </div>
    );
  }
}

export default ProfilePage;
