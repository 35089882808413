/* eslint-disable import/prefer-default-export */
import * as types from '../types/types';

export const busFire = (key, done, uniq, rest) => (dispatch) => {
  dispatch({
    type: types.BUS_FIRE,
    payload: {
      key,
      done,
      uniq,
      rest,
    },
  });
};
