import * as types from '../types/types';
import { UUID } from '../utils/StringUtils';

const initState = [];

export default (state = initState, action) => {
  switch (action.type) {
    case types.NOTIFICATIONS_SEND_MESSAGE:
      return [
        ...state,
        {
          id: UUID(),
          text: action.payload.message,
          type: action.payload.type,
          dismissed: false,
          canDismiss: action.payload.canDismiss || false,
          created: new Date(),
        },
      ];
    case types.NOTIFICATIONS_DISMISS_MESSAGE:
      return state.map((notification) => {
        if (notification.id !== action.payload.id) {
          return notification;
        }
        return {
          ...notification,
          dismissed: true,
        };
      });
    case types.NOTIFICATIONS_DISMISS_ALL_BY_TYPE:
      return state.map((notification) => {
        if (notification.type !== action.payload.type) {
          return notification;
        }
        return {
          ...notification,
          dismissed: true,
        };
      });
    default:
      return state;
  }
};
