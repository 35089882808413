import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';

import './BPTable.scss';

class BPPopper extends React.Component {
  constructor() {
    super();
    this.domSelector = document.getElementById('body');
  }
  tracker = event => {
    try {
      // only drop down will have bptable
      if (event.target.className.indexOf('bptable') === -1) {
        this.props.onClickAway();
      }
    } catch (e) {
      console.warn('[BPPopper.js] className is not string?', e);
    }
  };
  render() {
    const { children } = this.props;
    const { anchorEl, open } = this.props;
    const id = open ? 'bptable-popper' : null;
    if (open) {
      this.domSelector.addEventListener('click', this.tracker);
    } else {
      this.domSelector.removeEventListener('click', this.tracker);
    }
    return (
      <div className="ppp">
        <div>
          {open && (
            <div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className="bptable-popper"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div className="BPPopper">
                  <div id="c1">{children}</div>
                </div>
              </Popover>
            </div>
          )}
        </div>
      </div>
    );
  }
}

BPPopper.defaultProps = {
  anchorEl: null,
};

BPPopper.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClickAway: PropTypes.func.isRequired,
};

export default BPPopper;
