import Button from "antd/lib/button";
import Icon from "antd/lib/icon";
import Checkbox from "antd/lib/checkbox";
import DatePicker from "antd/lib/date-picker";
import Input from "antd/lib/input";
import InputNumber from "antd/lib/input-number";
import message from "antd/lib/message";
import Popconfirm from "antd/lib/popconfirm";
import moment from "moment";
import PropTypes from "prop-types";
import SSF from "ssf";
import React, { Component } from "react";
import Select from "react-select2";
import ReactTable from "react-table";
import { DraggableModalProvider } from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import "react-table/react-table.css";
import {
  commonMetasEditorOpenBound,
  commonModalsOpenBound,
  listenForImportChanges,
  listenForReloadChanges,
} from "../../../actions/commonDataActionsUtils";
import { setSetBigQueryDialog } from "../../../reducers/qndActions";
import {
  BPVIEWS,
  BP_DRAWER,
  BP_LEVELS,
  BP_TABLE,
  DEFAULT_DATE_FORMAT_FOR_DB,
  COLORRESET,
} from "../../../shared/Constants";
import ContextMenu from "../../../shared/contextMenu/ContextMenu";
import { devModeStatic } from "../../../utils/DevMode";
import {
  EchoParser,
  FloatParser,
  IntParser,
} from "../../../utils/NumberParser";
import { PerfUICallTimer } from "../../../utils/Perf";
import { ViewSettings } from "../../../utils/Settings";
// import Scrollbar from 'react-scrollbars-custom'; // might be used for windows, hold it
import { handleResizeWithThrottle } from "../../../utils/WindowSizesService";
import { bpServiceStatic } from "../BPService";
import { FLHServiceStatic } from "../../../services/FLHService";
import { getListForColFactory } from "../BPServiceUtils";
import { setFiltersBound } from "../bpUtils";
import { getViewById, getViewMetaInfoByProjectAndId } from "../BPViewService";
import BPDrawer from "./BPDrawer";
import BPPopper from "./BPPopper";
import BPTableContextMenu from "./BPTableContextMenu";
import ReactTablePagination from "./BPTableCustomPagination";
import {
  appendFromImports,
  makeEmptyLineOnly,
  overrideFromImports,
  isValid,
  fireOnDataChange,
  fireHooksValidator,
  getDXIdsOnly,
} from "./BPTableDataUtils";
import BPTableDyn2 from "./BPTableDyn2";
import BPTableDyn3 from "./BPTableDyn3";
import {
  customDefaultFilter,
  FilterComponent,
  FooterActionsButtons,
  goFocus,
  listenForPadRowClicks,
  ListFilter,
  LoadingComponent,
  moveInArray,
  selectStyles,
  ThComponent,
  THProps,
} from "./BPTableUtils";
import { mediaPlanExpandedBound } from "../../../actions/MediaPlanActionsUtils";
import eventManager from "../../../shared/EventManager";
import { MEDIAPLAN_RELOAD, MEDIAPLAN_RECALC } from "../../../types/types";
import BPTableActions, { TheTip, RowActions } from "./BPTableActions";
import BPFileUploader from "./BPFileUploader";
import BPTableSums from "./BPTableSums";
import { UserSettings } from "../../../utils/UserSettings";
import { isRequired } from "../../../shared/validators/BPValid";
import { memStorage } from "../../../services/Storage";
import { exportsBPTable, exportsBPTableSelectedCols } from "./bpExports";
import { isAdmin } from "../../../auth/AuthService";
import { massImport } from "./bpMassImportsUtils";
import { notify } from "../../../shared/notifications/Notify";

const CONTEXT_MENU_CLASS1 = "context_menu_class1";
const CONTEXT_MENU_CLASS2 = "context_menu_class2";
const CONTEXT_MENU_CLASS3 = "context_menu_class3";

const TOP_NAV_MENU_ID = "BPCustomViewsNav";

function onButtonAddClick() {
  console.log("[BPTable.jsx] add new btn", true);
}

class BPTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      popperAnchorEl: null,
      popperOpen: false,
      clickedCellValue: null,
      cellOptions: [],
      cellClickedValue: "",
      windowHeight: 800,
      windowWidth: 1000,
      tableHeight: 800,
      tableWidth: 1000,
      view: [],
      columns: [],
      isLoading: true,
      showFilters: false,
      showSums: false,
      enableMassSelect: false,
      selectAll: {
        indeterminate: false,
        checkedAll: false,
      },
      fileUploaderVisible: false,
      currentFileHandlerObj: {},
      filtered: [],
      pageIndex: 0,
      pageSize: 10,
      sorted: [],
      autoSaveToggle: true,
    };
    this.massDelete = new Set();
    this.renderEditable = this.renderEditable.bind(this);
    this.renderDropDown = this.renderDropDown.bind(this);
    this.renderBool = this.renderBool.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.renderDatePicker = this.renderDatePicker.bind(this);
    this.onButtonClone = this.onButtonClone.bind(this);
    this.onButtonDelete = this.onButtonDelete.bind(this);
    this.onFileAction = this.onFileAction.bind(this);
    this.reactTableRef = null;
    this.view = [];
    this.fileCellInfo = {};
    this.dropDownInputId = {};
    this.editedIdsIndex = new Set();
    this.tabIndex = 0;
    this.viewId = 0;
    this.hidden = new Set();
    this.colVali = new Map();
    //TODO all colValiFields has to be reworked - left in the code as ref
    // this.colValiFields = new Set();
    this.getListForCol = getListForColFactory(
      this.props.projectId,
      this.props.level
    );
    this.perfTimer = new PerfUICallTimer();
    this.dynamicLevel = null;
    if (this.props.level === BP_LEVELS.DYN1) {
      this.CONTEXT_MENU_CLASS = CONTEXT_MENU_CLASS1;
      this.IMPORT_COL = BP_DRAWER.IMPORT_COL1;
      this.IMPORT_COL_MASS = BP_DRAWER.IMPORT_COL1_MASS;
      this.TABLE_NAME = BPTableDyn2;
      this.loadFn = "loadDyn1";
      this.postPutFn = "postPutDyn1";
      this.deleteFn = "deleteLine1";
      this.deleteMultiFn = "deleteLine1Multi";
      this.dynamicLevel = "dynamic1";
      this.levelInt = 1;
    }
    if (this.props.level === BP_LEVELS.DYN2) {
      this.CONTEXT_MENU_CLASS = CONTEXT_MENU_CLASS2;
      this.IMPORT_COL = BP_DRAWER.IMPORT_COL2;
      this.IMPORT_COL_MASS = BP_DRAWER.IMPORT_COL2_MASS;
      this.TABLE_NAME = BPTableDyn3;
      this.loadFn = "loadDyn2";
      this.postPutFn = "postPutDyn2";
      this.deleteFn = "deleteLine2";
      this.deleteMultiFn = "deleteLine2Multi";
      this.dynamicLevel = "dynamic2";
      this.levelInt = 2;
    }
    if (this.props.level === BP_LEVELS.DYN3) {
      this.CONTEXT_MENU_CLASS = CONTEXT_MENU_CLASS3;
      this.IMPORT_COL = BP_DRAWER.IMPORT_COL3;
      this.IMPORT_COL_MASS = BP_DRAWER.IMPORT_COL3_MASS;
      this.TABLE_NAME = null;
      this.loadFn = "loadDyn3";
      this.postPutFn = "postPutDyn3";
      this.deleteFn = "deleteLine3";
      this.deleteMultiFn = "deleteLine3Multi";
      this.dynamicLevel = "dynamic3";
      this.levelInt = 3;
    }
    this.userSettings = new UserSettings();
    this.userSettings.setContext(
      this.props.projectId,
      "bpTable:" + this.levelInt,
      this.props.idKey
    );
    this.isAdmin = isAdmin();

    // console.info(
    //   '[BPTable.jsx] constructor parentIdKey -------------------',
    //   this.props
    // );this.userSettings
  }
  componentDidMount() {
    this.iniResizing();
    this.loadHeaders();
    this.loadLines();
    setTimeout(() => {
      const autoSaveToggle = this.userSettings.getBy(
        "autoSaveToggleStatusEnabled"
      );
      this.setState({ autoSaveToggle });
    }, 300);

    this.listenForPadRowClicks = listenForPadRowClicks(this.addEmptyLine);
    this.dynamicLevel = this.props.level;
    window.reactTableRef = this.reactTableRef;

    eventManager.on(MEDIAPLAN_RELOAD, () => {
      this.loadLines();
    });

    eventManager.on(MEDIAPLAN_RECALC, (data) => {
      // if (data.dynamicLevelInt === this.levelInt) {
      //TODO now we reload all, changes metas causes tables to close,
      // need to fix that.
      this.loadLinesFromRedux();
      // }
    });

    this.listenForReloadCallChange = listenForReloadChanges(
      BP_DRAWER.UNHIDE,
      (data) => {
        if (data.unhide) {
          const unhideArr = data.unhide.rest.unhide;
          const clickedElement = data.unhide.rest.clickedElement;
          this.hidden.forEach((el) => {
            if (unhideArr.indexOf(el) > -1) {
              this.hidden.delete(el);
            }
          });
          this.userSettings.setBy("hidden", this.hidden);
          let view = [...this.view];
          const pos = view.map((el) => el.name).indexOf(clickedElement);

          unhideArr.forEach((el) => {
            const current = view.map((el) => el.name).indexOf(el);
            view = moveInArray(view, current, pos);
          });

          const newOrder = view.map((v) => v.name);

          this.userSettings = this.userSettings.setBy(
            "order/" + this.viewMetas.id,
            newOrder
          );

          this.view = view;
          this.loadHeaders(true);
        }
      }
    );

    this.listenForImportCallChange = listenForImportChanges(
      this.IMPORT_COL,
      (data) => {
        if (data[this.IMPORT_COL]) {
          console.log(
            "[BPTable.jsx] this.dynamicLevel",
            this.dynamicLevel,
            "data.import_col.rest.level",
            data[this.IMPORT_COL].rest.level
          );
          if (data[this.IMPORT_COL].rest.level === this.dynamicLevel) {
            this.insertImports(data);
          }
        }
      }
    );

    window.addEventListener("MASS_IMPORT", this.massImportFn);
  }

  massImportFn = (ev) => {
    if (ev.detail.level === this.dynamicLevel) {
      console.log("[BPTable.jsx] ev", ev.detail);

      this.setState({ isLoading: true });

      const sortedData = this.reactTableRef
        .getResolvedState()
        .sortedData.map((d) => d._original);
      const filters = this.reactTableRef.state.filtered;

      massImport(
        ev.detail,
        sortedData,
        this.dynamicLevel,
        this.editedIdsIndex,
        this.state.view,
        filters,
        this.props.projectId,
        ({ dynLevel }) => {
          console.log("[BPTable.jsx] reload!!!!!!!", dynLevel);
          window.location.reload();
        }
      );
    }
  };

  onFileAction = (action, hash, cellInfo) => {
    console.log("[BPTable.jsx] hash", hash, cellInfo);
    this.fileCellInfo = cellInfo;
    this.setState({ currentFileHandlerObj: { hash, action } });
    this.setState({ fileUploaderVisible: true });
  };

  fileUploaderToggle = (open, eventData) => {
    console.log("[BPTable.jsx] filedata", open, eventData);
    this.setState({ fileUploaderVisible: open });
    if (eventData) {
      const data = [...this.state.data];
      data[this.fileCellInfo.index][
        this.fileCellInfo.column.id
      ] = eventData.hash ? eventData.hash : "";
      this.editedIdsIndex.add(this.fileCellInfo.index);
      this.setState({ data }, () => {
        this.doSave(() => {});
      });
    }
  };
  setFilters = (filtered) => {
    this.setState({ filtered });
    console.log("[BPTable.jsx] filtered", filtered);
    setFiltersBound(this.props.level, filtered);
    this.userSettings.setBy("filters", filtered);
    // TODO: fire only for filtered date after debounce
    // setTimeout(() => {
    //   // const sortedData = this.reactTableRef
    //   //   .getResolvedState()
    //   //   .sortedData.map((d) => d._original);
    //   // fireOnDataChange(this.props.level, sortedData);
    // }, 1000);
  };

  insertImports = (data) => {
    const { importData, type, clickedElement, doSave } = data[
      this.IMPORT_COL
    ].rest;

    this.setState({ isLoading: true });

    const sortedData = this.reactTableRef
      .getResolvedState()
      .sortedData.map((d) => d._original);

    console.log("[BPTable.jsx] IMPORT DATA CALL");
    const filters = this.reactTableRef.state.filtered;
    if (type === "override") {
      const importData2 = window.datafix;
      if (importData2) {
        window.datafix = false;
        overrideFromImports(
          importData2,
          clickedElement,
          sortedData,
          this.editedIdsIndex,
          this.state.view,
          this.props.projectId,
          this.getListForCol,
          this.dynamicLevel,
          this.props.idKey,
          this.props.parentIdKey,
          filters,
          (data) => {
            this.setState({ data: data, isLoading: false }, () => {
              if (doSave) {
                if (this.props.level === BP_LEVELS.DYN1) {
                  this.doSave();
                }
                window.didMetasUpdate(true);
              }
            });
          }
        );
      }
    }
    if (type === "append") {
      appendFromImports(
        importData,
        clickedElement,
        sortedData,
        this.editedIdsIndex,
        this.state.view,
        this.props.projectId,
        this.dynamicLevel,
        this.props.idKey,
        this.props.parentIdKey,
        filters,
        (data) => {
          this.setState({ data: data }, () => {
            if (doSave) {
              this.doSave();
            }
          });
        }
      );
    }
  };

  componentWillUnmount() {
    try {
      if (this.listenForReloadCallChange) {
        this.listenForReloadCallChange();
      }
      if (this.listenForImportCallChange) {
        this.listenForImportCallChange();
      }
      if (this.listenForPadRowClicks) {
        this.listenForPadRowClicks();
      }

      window.removeEventListener("MASS_IMPORT", this.massImportFn);
    } catch (e) {}
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.viewName !== this.props.viewName) {
      this.loadHeaders();
    }
  }
  reload() {
    this.loadHeaders();
  }
  handleReCalc = () => {
    this.loadLinesFromRedux();
  };
  loadLinesFromRedux = () => {
    const dataToSave = {
      meta: {},
      lists: {},
      views: [],
    };
    this.setState({ isLoading: true });
    bpServiceStatic.saveAllDyn(this.props.projectId, dataToSave, (_) => {
      eventManager.emit(MEDIAPLAN_RELOAD);
    });
  };
  onButtonClone = (index) => {
    const data = [...this.state.data];
    const dup = { ...this.state.data[index] };
    delete dup.id;
    dup._isNew = true;
    dup[BPVIEWS.KNOWN_CONST.LINE_ID] = "";
    data.push(dup);
    this.editedIdsIndex.add(data.length - 1);

    this.setState({ data }, () => {
      this.doSave(() => {
        // console.log("[BPTable.jsx] data", data);
      });
    });
  };
  onButtonCopy = (index) => {
    const dup = { ...this.state.data[index] };
    delete dup.id;
    dup._isNew = true;
    dup[BPVIEWS.KNOWN_CONST.LINE_ID] = "";
    this.props.updateClipboard(dup);
  };
  onButtonPaste = () => {
    const data = [...this.state.data];
    const dup = this.props.getClipboard();
    if (Object.keys(dup).length !== 0) {
      data.push(dup);
      this.editedIdsIndex.add(data.length - 1);
      this.setState({ data }, () => {
        this.doSave(() => {
          console.log("[BPTable.jsx] data", data);
        });
      });
    }
  };
  onMassDeleteCheckbox = (index) => {
    const data = [...this.state.data];
    data[index]._markDelete = true;
    this.setState({ data });
  };

  onMassSelectCheckbox = (index) => {
    const data = [...this.state.data];
    data[index]._markSelected = !data[index]._markSelected;

    const selectAll = { ...this.state.selectAll };
    const filtered = data.filter((d) => d._markSelected);
    if (filtered.length === data.length) {
      selectAll.checkedAll = true;
      selectAll.indeterminate = false;
    } else {
      selectAll.indeterminate = filtered.length > 0;
    }

    this.setState({ data, selectAll });
  };
  unselectAll = () => {
    const data = [...this.state.data];
    const dd = data.map((d) => {
      d._markSelected = false;
      return d;
    });
    this.setState({ data: dd });
  };
  onButtonTraffic = (index) => {
    const line = { ...this.state.data[index] };
    const level = `DYNAMIC${this.levelInt}`;
    const eventId = FLHServiceStatic.EVENT_IDS[level];
    FLHServiceStatic.execEvent(
      eventId,
      [line.id],
      () => {
        message.info("Events Fired!");
        fireHooksValidator(this.dynamicLevel, [line]);
        eventManager.emit(MEDIAPLAN_RELOAD);
      },
      () => {
        message.error("Something went wrong try again or ask for help!");
      }
    );
  };
  onMassTraffic = () => {
    this.setState({ isLoading: true });
    const data = [...this.state.data];
    const ids = data
      .filter((line) => line._markSelected && !line._isNew)
      .map((line) => line.id);

    const level = `DYNAMIC${this.levelInt}`;
    const eventId = FLHServiceStatic.EVENT_IDS[level];
    FLHServiceStatic.execEvent(
      eventId,
      ids,
      () => {
        message.info("Events Fired!");
        this.setState({ isLoading: false });
        this.unselectAll();
        eventManager.emit(MEDIAPLAN_RELOAD);
      },
      () => {
        message.error("Something went wrong try again or ask for help!");
        this.setState({ isLoading: false });
      }
    );
  };
  onMassDelete = () => {
    console.log("[BPTable.jsx] this ", 111);
    this.setState({ isLoading: true });
    const data = [...this.state.data];
    const ids = data
      .filter((line) => line._markSelected && !line._isNew)
      .map((line) => line.id);

    bpServiceStatic[this.deleteMultiFn](ids, () => {
      const data = this.state.data.filter((line) => !line._markSelected);
      this.setState({ data, isLoading: false });
    });
  };
  onButtonDelete = (index) => {
    const data = [...this.state.data];
    if (data[index]._isNew) {
      data.splice(index, 1);
      this.setState({ data });
    } else {
      this.setState({ isLoading: true });
      const lineId = data[index].id;

      bpServiceStatic[this.deleteFn](
        lineId,
        () => {
          data.splice(index, 1);
          this.setState({ data, isLoading: false });
          this.editedIdsIndex.delete(index);
        },
        (err) => {}
      );
    }
  };
  handleSave = (cb) => {
    this.doSave();
  };
  handleAutoSaveToggle = (val) => {
    this.setState({ autoSaveToggle: val }, () => {
      notify("Auto Save", "Is " + (val ? "Enabled" : "Disabled"), "info");
      this.userSettings.setBy("autoSaveToggleStatusEnabled", val);
    });
  };
  selectAllonChange = (val) => {
    const data = [...this.state.data];

    const sortedData = this.reactTableRef
      .getResolvedState()
      .sortedData.map((d) => d._original);

    data.forEach((d) => {
      if (sortedData.find((sd) => sd.id === d.id)) {
        d._markSelected = val.target.checked;
      }
    });

    this.setState({
      data,
      selectAll: {
        indeterminate: false,
        checkedAll: val.target.checked,
      },
    });
  };
  doSave = (cb) => {
    if (this.editedIdsIndex.size === 0) {
      if (cb) cb();
      return;
    }
    const editedLines = [];
    const newLines = [];
    const data = [...this.state.data];
    this.setState({ isLoading: true });
    this.editedIdsIndex.forEach((idx) => {
      const line = { ...data[idx] };
      if (data[idx]) {
        if (data[idx]._isNew) {
          line.projectId = this.props.projectId;
          newLines.push(line);
        } else {
          editedLines.push(line);
        }
      }
    });
    bpServiceStatic[this.postPutFn](
      this.props.idKey,
      newLines,
      editedLines,
      (data) => {
        this.setState({ data, isLoading: false });
        this.editedIdsIndex.clear();
        // console.log("[BPTable.jsx] data", data);
        const sortedData = this.reactTableRef
          .getResolvedState()
          .sortedData.map((d) => d._original);
        fireOnDataChange(this.props.level, sortedData);

        if (cb) cb();
      },
      (err) => {}
    );
  };
  doSaveMassImport = (editedLines, newLines, cb) => {
    bpServiceStatic[this.postPutFn](
      this.props.idKey,
      newLines,
      editedLines,
      (data) => {
        this.setState({ data, isLoading: false });
        this.editedIdsIndex.clear();
        // console.log("[BPTable.jsx] data", data);
        const sortedData = this.reactTableRef
          .getResolvedState()
          .sortedData.map((d) => d._original);
        fireOnDataChange(this.props.level, sortedData);

        if (cb) cb();
      },
      (err) => {}
    );
  };

  addEmptyLine = () => {
    this.makeEmptyLine(true);
  };
  makeEmptyLine = (addToState = false) => {
    const retVal = makeEmptyLineOnly(this.state.view, this.props.projectId);

    if (addToState) {
      const clone = [...this.state.data];
      clone.push(retVal);
      this.setState({ data: clone });
    }

    return retVal;
  };
  loadLines = () => {
    this.setState({ isLoading: true });
    console.log("[BPTable.jsx] this.props.idKey", this.props.idKey);
    if (this.props.idKey) {
      bpServiceStatic[this.loadFn](this.props.idKey, (data) => {
        this.setState({ data: data, isLoading: false });
        this.setDataBusTimeouted();
        setTimeout(() => {
          const showFilters = this.userSettings.getBy("showFilters") || false;
          console.log("[BPTable.jsx] showFiltersshowFilters", showFilters);
          const showSums = this.userSettings.getBy("showSums") || false;
          // Stopping pageIndex feature, to test user behavior. Requested by Cornelius
          // const pageIndex = this.userSettings.getBy("pageIndex") || 0;
          this.setState({ showFilters, showSums /* pageIndex */ });
        }, 100);
        setTimeout(() => {
          // This has to be done separately, otherwise filters don't work.
          const filtered = this.userSettings.getBy("filters") || [];
          const pageSize = this.userSettings.getBy("pageSize") || 10;
          const sorted = this.userSettings.getBy("sorted") || [];
          this.setState({ filtered, pageSize, sorted });
        }, 300);
      });
    }
  };
  setDataBusTimeouted = () => {
    if (this.reactTableRef) {
      // const sortedData = this.reactTableRef
      //   .getResolvedState()
      //   .sortedData.map((d) => d._original);

      const allCurrent = this.getCurrentViewData();

      fireOnDataChange(this.props.level, allCurrent);
    } else {
      setTimeout(() => {
        this.setDataBusTimeouted();
      }, 300);
    }
  };

  loadHeaders = (isLocalView) => {
    setTimeout(() => {
      console.log("[BPTable.jsx] TRIGGER", 4);
      console.log("[BPTable.jsx] this.viewName", this.viewName);
      this.viewName = this.props.viewName || "dynamic1.default";

      if (isLocalView) {
      } else {
        this.selectedView = memStorage.get("selectedView");

        const needleId = this.selectedView.viewIds[this.dynamicLevel].id;
        this.view = getViewById(needleId);
        this.viewMetas = getViewMetaInfoByProjectAndId(
          needleId,
          this.props.projectId
        );
        this.viewId = needleId;
        if (!this.viewMetas) {
          return this.loadHeaders(isLocalView);
        }
        const settings = new ViewSettings(this.viewMetas.settings);
        const viewMetasFilter = settings.get("filter");
        if (Array.isArray(viewMetasFilter)) {
          this.viewMetasFilter = settings.get("filter");
          setFiltersBound(this.props.level, viewMetasFilter);
        } else {
          this.viewMetasFilter = [];
          setFiltersBound(this.props.level, []);
        }
      }
      if (this.view) {
        this.hidden = this.userSettings.getBy("hidden") || new Set();
        const order =
          this.userSettings.getBy("order/" + this.viewMetas.id) || [];
        let orderedView = [];
        if (order.length > 0) {
          order.forEach((ord) => {
            const line = this.view.find((v) => v.name === ord);
            orderedView.push(line);
          });
        } else {
          orderedView = [...this.view];
        }
        const columns = this.makeHeaders(orderedView);
        this.setState({
          view: this.view,
          columns,
          filtered: this.viewMetasFilter,
        });
        this.setTableSizing(10, columns.length);
      } else {
      }
    }, 1500);
  };
  makeHeaders = (view) => {
    /// console.log("[BPTable.jsx] viewview", view);
    this.view = view;
    this.formulaFields = {};
    this.formatFields = {};
    const headersArray = [];
    let cellType = this.renderEditable;
    headersArray.push({
      expander: true,
      Header: "Ex",
      width: 40,
    });
    headersArray.push({
      Header: "Actions",
      accessor: "id",
      _fl_meta: {
        noFilter: true,
      },
      Cell: ({ index }) => {
        // also have viewIndex
        return (
          <div className="custom-buttons-prefix">
            {this.state.enableMassSelect && (
              <Checkbox
                checked={this.state.data[index]._markSelected}
                onChange={() => this.onMassSelectCheckbox(index)}
              >
                {" "}
              </Checkbox>
            )}
            <>
              {!this.state.enableMassSelect && (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => this.onButtonDelete(index)}
                  onCancel={null}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" icon="delete" className="btn-delete" />
                </Popconfirm>
              )}
              <RowActions
                lineData={this.state.data[index]}
                isAdmin={this.isAdmin}
                index={index}
                dXString={this.dynamicLevel}
                onButtonClone={this.onButtonClone}
                onButtonCopy={this.onButtonCopy}
                onButtonPaste={this.onButtonPaste}
                onButtonTraffic={this.onButtonTraffic}
              />
            </>
          </div>
        );
      },
      Footer: () => <span />, //needed just to render custom footer
      width: this.isAdmin ? 190 : 160,
    });
    if (devModeStatic.isDevMode()) {
      headersArray.push({
        Header: "DB Id",
        accessor: "id",
        width: 80,
      });
    }
    view.forEach((header, idx) => {
      if (this.hidden && this.hidden.has(header.name)) return;

      const { valiRequired, valiColor } = isRequired(
        header._settings,
        header.name
      );
      this.colVali.set(header.name, {
        valiRequired,
        valiColor,
      });
      let cellAccessor = header.name;
      let label = `${header.label || "n/a"} `;
      let tooltip = `${header.tooltip || ""} `;
      if (header.formula.trim().length > 1) {
        this.formulaFields[header.name] = true;
        label = "F= " + label;
      }
      if (devModeStatic.isDevMode()) {
        label = label + "/" + header.name;
      }
      if (header.format.trim().length > 1) {
        this.formatFields[header.name] = header.format;
      }
      const additions = {};
      additions.Header = (props) => {
        return (
          <>
            <div className="col-head">
              {this.state.showSums && (
                <div>
                  <BPTableSums
                    dynamicLevel={this.dynamicLevel}
                    headerName={header.name}
                  />
                </div>
              )}
              <TheTip title={tooltip}>
                <div
                  data-name={header.name}
                  data-level={this.dynamicLevel}
                  data-required={valiRequired}
                  className={this.CONTEXT_MENU_CLASS}
                  title={header.label}
                >
                  {valiRequired && (
                    <span className="req-indicator">*&nbsp;</span>
                  )}
                  {label}
                </div>
              </TheTip>
            </div>
          </>
        );
      };
      if (header.name.indexOf("dateColumn") === 0) {
        cellType = this.renderDatePicker;
        cellAccessor = header.name;
        const sortMethod = (a, b) => {
          return moment.utc(a).diff(moment.utc(b));
        };
        additions.sortMethod = sortMethod;
        additions._fl_meta = {
          noFilter: true,
        };
        // additions.Filter = ({ filter, onChange, column }) => {
        //   return <div>hello</div>;
        // };
      } else if (header.name.indexOf("listColumn") === 0) {
        cellType = this.renderDropDown;
        cellAccessor = header.name;

        additions.Filter = ({ filter, onChange, column }) => {
          return ListFilter(filter, onChange, column, this.getListForCol);
        };
      } else if (header.name.indexOf("smfDataGroupId") === 0) {
        cellType = this.renderDropDown;
        cellAccessor = header.name;
      } else if (header.name.indexOf("file") === 0) {
        cellType = this.renderFile;
        cellAccessor = header.name;
      } else if (header.name.indexOf("bool") === 0) {
        cellType = this.renderBool;
        cellAccessor = header.name;
      } else {
        cellType = this.renderEditable;
        cellAccessor = header.name;
      }
      // TODO add proper saving of width
      let width = 130;
      if (header.name === BPVIEWS.KNOWN_CONST.LINE_ID) {
        if (this.props.level === BP_LEVELS.DYN3) {
          width = 220;
        } else {
          width = 200;
        }
      }

      const widths = this.userSettings.getBy("widths");

      if (widths && widths[header.name]) {
        width = widths[header.name];
      }

      headersArray.push({
        Header: `${header.label}`,
        accessor: cellAccessor,
        Cell: cellType,
        width: width,
        ...additions,
      });
    });
    return headersArray;
  };
  iniResizing = () => {
    handleResizeWithThrottle(() => {
      this.setWindowSizes();
      this.setTableSizing();
    });
    setTimeout(() => {
      this.setWindowSizes();
      this.setTableSizing();
    }, 300);
  };
  setTableSizing = (rows = 10, cols = 10) => {
    const singleCellHeight = 46;
    const singleCellWidth = 200;
    this.setState({
      tableHeight: rows * singleCellHeight,
      tableWidth: cols * singleCellWidth,
    });
  };
  setWindowSizes = () => {
    let magicWidth;
    this.navElement =
      this.navElement || document.getElementById(TOP_NAV_MENU_ID);

    const navClientWidth = this.navElement ? this.navElement.clientWidth : 500;

    if (this.props.level === BP_LEVELS.DYN1) {
      magicWidth = 115;
      this.windowWidth = window.innerWidth - magicWidth;
    } else if (this.props.level === BP_LEVELS.DYN2) {
      magicWidth = 35;
      this.windowWidth = navClientWidth - magicWidth;
    } else {
      magicWidth = 65;
      this.windowWidth = navClientWidth - magicWidth;
    }

    this.windowHeight = window.innerHeight - 220;

    this.setState({
      windowHeight: this.windowHeight,
      windowWidth: this.windowWidth,
    });
  };
  handleContextMenuClick = (event, target) => {
    const type = event.key;
    const elementName = target.dataset.name;
    const elementLevel = target.dataset.level;
    if (type.indexOf(BP_TABLE.ICON_CLICK_COLOR) === 0) {
      this.saveUserColors(type, elementName);
    }

    if (type === BP_TABLE.ICON_CLICK_EDIT && elementName) {
      commonMetasEditorOpenBound(
        this.props.projectId,
        this.viewName,
        elementName,
        this.levelInt,
        this.userSettings,
        this.viewId
      );
    }

    if (type === BP_TABLE.ICON_CLICK_HIDE) {
      this.hideCol(elementName);
    }
    if (type === BP_TABLE.ICON_CLICK_UNHIDE) {
      commonModalsOpenBound("BPDrawer", {
        type: BP_DRAWER.UNHIDE,
        hiddenFields: Array.from(this.hidden),
        view: this.view,
        clickedElement: elementName,
      });
    }
    if (type === BP_TABLE.ICON_CLICK_IMPORT_COL) {
      commonModalsOpenBound("BPDrawer", {
        type: this.IMPORT_COL,
        view: this.view,
        viewId: this.viewId,
        clickedElement: elementName,
        level: elementLevel,
      });
    }
  };

  handleSelectChange = (sel) => {
    if (sel) {
      const cell = this.state.activeCellInfo;
      const data = [...this.state.data];
      const backupValue = this.state.data[cell.index][cell.column.id];

      if (backupValue !== sel.value) {
        data[cell.index][cell.column.id] = sel.value;
        this.editedIdsIndex.add(cell.index);
        if (this.state.autoSaveToggle) {
          this.doSave(() => {});
        }
      }
      data[cell.index]._editMode = false;

      goFocus(this.dropDownInputId);
      this.setState(
        {
          popperAnchorEl: null,
          popperOpen: false,
          data,
        },
        () => {
          console.log("[BPTable.jsx] data", data);
        }
      );
    } else {
      this.setState({
        popperAnchorEl: null,
        popperOpen: false,
      });
    }
  };
  handleDropDownClick = (event, cellInfo) => {
    const { currentTarget, target } = event;
    this.dropDownInputId = target.id;
    const cellVal = cellInfo.original;
    const cellOptionsTmp = this.getListForCol(cellInfo.column.id);

    const cellOptions = cellOptionsTmp ? cellOptionsTmp : [];

    this.setState((state) => ({
      popperAnchorEl: currentTarget,
      popperOpen: true,
      clickedCellValue: cellVal,
      activeCellInfo: cellInfo,
      cellOptions: cellOptions,
    }));
  };
  saveUserColors(type, elementName) {
    const val = type.replace(BP_TABLE.ICON_CLICK_COLOR + ".", "");
    let col = this.userSettings.getBy("colors") || {};
    col[elementName] = val;
    this.userSettings = this.userSettings.setBy("colors", col);
    const columns = this.makeHeaders(this.view);
    this.setState({
      columns,
    });
  }

  renderEditable(cellInfo) {
    let isValiCell = true;
    this.tabIndex = this.tabIndex + 1;
    let val;
    if (this.state.data[cellInfo.index][cellInfo.column.id] === null) {
      val = "";
    } else {
      val = this.state.data[cellInfo.index][cellInfo.column.id];
    }

    let isFormula = false;
    let isReadOnly = !!!this.state.data[cellInfo.index]._editMode;
    if (this.formulaFields[cellInfo.column.id]) {
      isReadOnly = true;
      isFormula = true;
    }
    if (isReadOnly) {
      if (this.formatFields[cellInfo.column.id]) {
        let format = this.formatFields[cellInfo.column.id];
        val = SSF.format(format, val);
      }
    }

    let ComponentName = Input;
    let parserFn = EchoParser;
    if (cellInfo.column.id.indexOf("int") === 0) {
      ComponentName = InputNumber;
      parserFn = IntParser;
    } else if (cellInfo.column.id.indexOf("float") === 0) {
      ComponentName = InputNumber;
      parserFn = FloatParser;
    } else {
      ComponentName = Input;
      parserFn = EchoParser;
    }
    const onBlur = (index, id, event, onEnterKey, onEscKey) => {
      const elementId = event.target.id;

      const backupValue = this.state.data[index][id];
      const data = [...this.state.data];
      const parsedValue = parserFn(event.target.value);

      if (onEscKey) {
        data[index][id] = backupValue;
        data[index]._editMode = false;
        this.setState({ data });
        return;
      }

      //TODO make it proper name
      const backupValueForValidator = this.state.data[index][id];
      const elemName = elementId.split(":")[2];
      const isThisValid = isValid(
        parsedValue,
        data,
        elemName,
        backupValueForValidator,
        this.props.projectId
      );
      // valiColor
      const valiOb = this.colVali.get(elemName);
      if (valiOb.valiRequired) {
        if (parsedValue.trim().length === 0) {
          // this.colValiFields.add(elemName);
          // data[index]["boolColumn10"] = false;
          isValiCell = false;
        } else {
          // this.colValiFields.delete(elemName);
          // data[index]["boolColumn10"] = true;
          isValiCell = true;
        }
      } // getCellColorById

      if (isThisValid.valid) {
        if (backupValue !== parsedValue) {
          data[index][id] = parsedValue;
          this.editedIdsIndex.add(index);
          if (this.state.autoSaveToggle) {
            this.doSave(() => {});
          }
        }

        data[index]._editMode = false;
        this.setState({ data });
        if (onEnterKey) {
          goFocus(elementId);
        }
      } else {
        message.error(isThisValid.msg);
      }
    };
    const onEnter = (index, id, event) => {
      this.editedIdsIndex.add(index);
      const elementId = event.target.id;
      const data = [...this.state.data];
      data[index]._editMode = true;
      this.setState({ data });
      goFocus(elementId);
    };

    const colorClassUser = this.getCellColor(cellInfo);
    let colorClass = "";
    const valiOb = this.colVali.get(cellInfo.column.id);
    if (valiOb.valiRequired) {
      if ((val + "").trim().length === 0) {
        // this.colValiFields.add(cellInfo.column.id);
        // data[index]["boolColumn10"] = false;
        isValiCell = false;
        colorClass = valiOb.valiColor + " e";
      } else {
        // this.colValiFields.delete(cellInfo.column.id);
        // data[index]["boolColumn10"] = true;
        isValiCell = true;
        colorClass = valiOb.valiColor + " ok";
      }
    }

    const colorSums = `${colorClassUser} ${colorClass}`;

    return (
      <div
        className={`ro-${isReadOnly} ${colorSums}`}
        data-isvalicell={isValiCell}
      >
        {isReadOnly ? (
          <input
            id={`${this.props.level}:${cellInfo.index}:${cellInfo.column.id}`}
            className={`ant-input formula-${isFormula}`}
            type="text"
            value={val}
            readOnly
            tabIndex={this.tabIndex}
            onDoubleClick={(e) =>
              onEnter(cellInfo.index, cellInfo.column.id, e)
            }
            onKeyPress={(e) => {
              e.key === "Enter" &&
                onEnter(cellInfo.index, cellInfo.column.id, e);
            }}
          />
        ) : (
          <ComponentName
            defaultValue={val}
            onBlur={(event) =>
              onBlur(cellInfo.index, cellInfo.column.id, event)
            }
            id={`${this.props.level}:${cellInfo.index}:${cellInfo.column.id}`}
            tabIndex={this.tabIndex}
            onKeyDown={(event) => {
              if (event.keyCode === 27 || event.keyCode === 13) {
                const enter = event.keyCode === 13;
                const esc = event.keyCode === 27;
                onBlur(cellInfo.index, cellInfo.column.id, event, enter, esc);
              }
            }}
          />
        )}
      </div>
    );
  }
  renderBool(cellInfo) {
    const isReadOnly = !!!this.state.data[cellInfo.index]._editMode;
    let val = null;
    if (cellInfo.value) {
      val = cellInfo.value;
    }
    const onBlur = (event, index, id) => {
      const val = event.target.checked;

      const backupValue = this.state.data[index][id];
      const data = [...this.state.data];
      const parsedValue = val;

      if (backupValue !== parsedValue) {
        data[index][id] = parsedValue;
        this.editedIdsIndex.add(index);
        if (this.state.autoSaveToggle) {
          this.doSave(() => {});
        }
      }

      data[index]._editMode = false;
      this.setState({ data });
    };
    return (
      <div className={`custom-bool ro-${isReadOnly}`} tabIndex={this.tabIndex}>
        <Checkbox
          id={`${this.props.level}:${cellInfo.index}:${cellInfo.column.id}`}
          checked={!!val}
          onChange={(ev) => onBlur(ev, cellInfo.index, cellInfo.column.id)}
        ></Checkbox>
      </div>
    );
  }
  getCellColor(cellInfo) {
    return this.getCellColorById(cellInfo.column.id);
  }
  getCellColorById(columnId) {
    // const err = this.colValiFields.has(columnId) ? "e" : "ok";
    // if (this.colVali.has(columnId)) {
    //   const rules = this.colVali.get(columnId);
    //   if (rules.valiRequired) {
    //     return rules.valiColor + " " + err;
    //   }
    // }
    let col = this.userSettings.getBy("colors");
    const colorClass = (col && col[columnId]) || COLORRESET;
    return colorClass;
  }
  renderFile(cellInfo) {
    const isReadOnly = !!!this.state.data[cellInfo.index]._editMode;
    let val = null;
    if (cellInfo.value) {
      val = cellInfo.value;
    }
    return (
      <div className={`custom-file ro-${isReadOnly}`} tabIndex={this.tabIndex}>
        {val && (
          <Button onClick={(e) => this.onFileAction("view", val, cellInfo)}>
            {" "}
            View{" "}
          </Button>
        )}
        {!val && (
          <>
            <Button onClick={(e) => this.onFileAction("upload", val, cellInfo)}>
              <Icon type="upload" />{" "}
            </Button>
          </>
        )}
      </div>
    );
  }
  renderDropDown(cellInfo) {
    let isValiCell = true;
    const isReadOnly = !!!this.state.data[cellInfo.index]._editMode;
    let val = "";
    if (cellInfo.value) {
      const cellOptionsTmp = this.getListForCol(cellInfo.column.id);
      if (cellOptionsTmp && cellOptionsTmp.length > 0) {
        const needle = cellOptionsTmp.find((v) => v.value === cellInfo.value);
        if (needle) {
          val = needle.label;
        }
      }
    }
    let colorClass = "";
    const valiOb = this.colVali.get(cellInfo.column.id);
    if (valiOb.valiRequired) {
      if ((val + "").trim().length === 0) {
        // this.colValiFields.add(cellInfo.column.id);
        // data[index]["boolColumn10"] = false;
        isValiCell = false;
        colorClass = valiOb.valiColor + " e";
      } else {
        // this.colValiFields.delete(cellInfo.column.id);
        // data[index]["boolColumn10"] = true;
        isValiCell = true;
        colorClass = valiOb.valiColor + " ok";
      }
    }

    const colorClassUser = this.getCellColor(cellInfo);
    const colorSums = `${colorClassUser} ${colorClass}`;

    return (
      <div
        className={`custom-drop-down ro-${isReadOnly} ${colorSums}`}
        onDoubleClick={(e) => this.handleDropDownClick(e, cellInfo)}
        data-isvalicell={isValiCell}
      >
        <input
          id={`${this.props.level}:${cellInfo.index}:${cellInfo.column.id}`}
          className="ant-input"
          type="text"
          value={val}
          readOnly
          tabIndex={this.tabIndex}
          onKeyPress={(e) => {
            e.key === "Enter" && this.handleDropDownClick(e, cellInfo);
          }}
        />
      </div>
    );
  }
  renderDatePicker(cellInfo) {
    let isValiCell = true;
    const isReadOnly = !!!this.state.data[cellInfo.index]._editMode;
    let val = null;
    if (cellInfo.value) {
      val = moment(cellInfo.value);
    }
    const onChange = (index, id, momentDate) => {
      let date = "";
      if (momentDate) {
        date = momentDate.format(DEFAULT_DATE_FORMAT_FOR_DB);
      }
      const backupValue = this.state.data[index][id];
      if (backupValue !== date) {
        const data = [...this.state.data];
        data[index][id] = date;
        this.editedIdsIndex.add(index);
        this.setState({ data }, () => {
          if (this.state.autoSaveToggle) {
            this.doSave(() => {});
          }
          // console.log('[BPTable.jsx] st', this.state);
        });
      }
    };
    const colorClassUser = this.getCellColor(cellInfo);
    let colorClass = "";
    const valiOb = this.colVali.get(cellInfo.column.id);
    if (valiOb.valiRequired) {
      if (!val) {
        // this.colValiFields.add(cellInfo.column.id);
        // data[index]["boolColumn10"] = false;
        isValiCell = false;
        colorClass = valiOb.valiColor + " e";
      } else {
        // this.colValiFields.delete(cellInfo.column.id);
        // data[index]["boolColumn10"] = true;
        isValiCell = true;
        colorClass = valiOb.valiColor + " ok";
      }
    }

    const colorSums = `${colorClassUser} ${colorClass}`;
    return (
      <div
        className={`custom-date-picker ro-${isReadOnly} ${colorSums}`}
        tabIndex={this.tabIndex}
        data-isvalicell={isValiCell}
      >
        <DatePicker
          onChange={(ev) => onChange(cellInfo.index, cellInfo.column.id, ev)}
          value={val}
        />
      </div>
    );
  }
  biqQueryimportBtn = () => {
    setSetBigQueryDialog(true, this.props.projectId);
  };
  importDataBtn = () => {
    commonModalsOpenBound("BPDrawer", {
      type: this.IMPORT_COL_MASS,
      view: this.view,
      viewId: this.viewId,
      level: this.dynamicLevel,
      projectId: this.props.projectId,
    });
  };
  exportData = () => {
    const sortedData = this.reactTableRef
      .getResolvedState()
      .sortedData.map((d) => d._original);
    console.log("[BPTable.jsx] sortedData", sortedData);
    exportsBPTable(
      this.props.projectId,
      sortedData,
      this.viewId,
      this.dynamicLevel
    );
  };
  exportIdsBtn = () => {
    notify("Exports staring...", "It will might take a while...");
    getDXIdsOnly(this.props.projectId, this.dynamicLevel, (data) => {
      exportsBPTableSelectedCols(
        this.props.projectId,
        data,
        this.viewId,
        this.dynamicLevel,
        [BPVIEWS.KNOWN_CONST.LINE_ID]
      );
    });
  };
  hideCol = (elementName) => {
    this.hidden.add(elementName);
    this.userSettings.setBy("hidden", this.hidden);
    const columns = this.makeHeaders(this.view);
    this.setState({
      columns,
    });
  };
  renderButtonAdd(cellInfo) {
    return (
      <div className="custom-button">
        <Button
          onClick={onButtonAddClick}
          type="dashed"
          size="small"
          icon="plus"
        />
      </div>
    );
  }
  // @TODO remove this
  onExpandedChange = (allExpandedState, currentExpansion) => {
    console.log("Still being used");
    const row = this.reactTableRef.getResolvedState().sortedData[
      currentExpansion
    ];

    let isExpanded = !!allExpandedState[currentExpansion];

    if (this.props.idKey) {
      mediaPlanExpandedBound(
        this.props.idKey,
        row.id,
        isExpanded,
        this.props.level
      );
    }
  };
  enableMassSelect = () => {
    this.setState({ enableMassSelect: !this.state.enableMassSelect });
  };
  onCellResize = (dataArr) => {
    const values = {};
    dataArr.forEach((el) => {
      values[el.id] = el.value;
    });
    this.userSettings = this.userSettings.setBy("widths", values);
  };

  getCurrentViewData = () => {
    const current = this.reactTableRef;
    if (current) {
      const page = current.state.page;
      const pageSizeCurrent = current.state.pageSize;
      const allData = current.getResolvedState().sortedData;
      const pageSizeSaved = this.userSettings.getBy("pageSize");
      const pageSize = Math.max(pageSizeCurrent, pageSizeSaved);
      const startIdx = page * pageSize;
      const currentData = allData
        .slice(startIdx, startIdx + pageSize)
        .map((item) => item._original);
      return currentData;
    }
  };

  render() {
    const { data } = this.state;
    const cssClasses = "table-" + this.levelInt + " -striped -highlight";
    const TableName = this.TABLE_NAME;
    return (
      <div className="BPTable" data-level={this.props.level}>
        <div
          className="table-wrapper"
          style={{
            width: this.state.windowWidth,
          }}
        >
          <BPTableActions
            filterChange={() => {
              this.setState({ showFilters: !this.state.showFilters });
              this.userSettings.setBy("showFilters", !this.state.showFilters);
            }}
            filtersOn={this.state.showFilters}
            sumsChange={() => {
              this.setState({ showSums: !this.state.showSums });
              this.userSettings.setBy("showSums", !this.state.showSums);
            }}
            sumsOn={this.state.showSums}
            levelInt={this.levelInt}
            massSelectChange={(x) => {
              this.enableMassSelect(x);
            }}
            // massSelectState={this.state.enableMassSelect}
            multiSelectOn={this.state.enableMassSelect}
            massDelete={this.onMassDelete}
            massTraffic={this.onMassTraffic}
            selectAll={this.state.selectAll}
            selectAllonChange={this.selectAllonChange}
            exportBtn={() => this.exportData()}
            exportIdsBtn={() => this.exportIdsBtn()}
            importBtn={() => this.importDataBtn()}
            biqQueryimportBtn={() => this.biqQueryimportBtn()}
          />

          <ReactTable
            ref={(r) => {
              this.reactTableRef = r;
            }}
            loadingText={"Loading........."}
            filterable={this.state.showFilters}
            loading={this.state.isLoading}
            data={data}
            freezeWhenExpanded
            onExpandedChange={this.onExpandedChange}
            columns={this.state.columns}
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            pageSize={this.state.pageSize}
            onPageChange={(pageIndex) => {
              this.userSettings.setBy("pageIndex", pageIndex);
              this.setState({ pageIndex }, () => {
                this.setDataBusTimeouted();
              });
            }}
            onPageSizeChange={(pageSize, pageIndex) => {
              this.userSettings.setBy("pageIndex", pageIndex);
              this.userSettings.setBy("pageSize", pageSize);
              this.setState({ pageIndex, pageSize }, () => {
                this.setDataBusTimeouted();
              });
            }}
            page={this.state.pageIndex}
            className={cssClasses}
            onFilteredChange={(filtered, column) => {
              this.setFilters(filtered);
            }}
            onResizedChange={(dataArr, mouseEvent) => {
              //TODO add debounce
              this.onCellResize(dataArr);
            }}
            filtered={this.state.filtered}
            PaginationComponent={ReactTablePagination}
            FooterComponent={() => {
              return (
                <FooterActionsButtons
                  addEmptyLine={this.addEmptyLine}
                  handleSave={this.handleSave}
                  handleReCalc={this.handleReCalc}
                  handleAutoSaveToggle={this.handleAutoSaveToggle}
                  autoSaveState={this.state.autoSaveToggle}
                />
              );
            }}
            defaultFilterMethod={customDefaultFilter}
            LoadingComponent={LoadingComponent}
            FilterComponent={FilterComponent}
            getTheadFilterThProps={(state, rowInfo, column, instance) => {
              const colorClass = this.getCellColorById(column.id);
              return {
                "data-color-class": colorClass,
              };
            }}
            ThComponent={ThComponent}
            getTheadThProps={(state, rowInfo, column, instance) => {
              const userSettings = this.userSettings.getBy("colors") || {};
              return THProps(
                state,
                rowInfo,
                column,
                instance,
                this.iconHeaderClickHandler,
                userSettings
              );
            }}
            onSortedChange={(newSorted, column, shiftKey) => {
              // console.log("[BPTable.jsx] sort", newSorted, column, shiftKey);
              this.setState({ sorted: newSorted });
              this.userSettings.setBy("sorted", newSorted);
            }}
            sorted={this.state.sorted}
            SubComponent={(row) => {
              if (this.props.level === BP_LEVELS.DYN3) {
                return <></>;
              }
              return (
                <TableName
                  projectId={this.props.projectId}
                  idKey={row.row.id}
                  parentIdKey={this.props.idKey}
                  viewName={this.props.viewName}
                  ref={this.tableRef}
                  updateClipboard={this.props.updateClipboard}
                  getClipboard={this.props.getClipboard}
                />
              );
            }}
          />
        </div>
        <BPPopper
          anchorEl={this.state.popperAnchorEl}
          open={this.state.popperOpen}
          onClickAway={(sel) => this.handleSelectChange(sel)}
        >
          <Select
            styles={selectStyles}
            options={this.state.cellOptions}
            onChange={(sel) => this.handleSelectChange(sel)}
            value={this.state.cellClickedValue}
            components={{
              DropdownIndicator: null,
              IndicatorSeparator: null,
              // ValueContainer: null,
            }}
            menuIsOpen
            classNamePrefix="bptable-select"
            tabSelectsValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            autoFocus
            backspaceRemovesValue={false}
          />
        </BPPopper>
        <ContextMenu elementToAttachClass={this.CONTEXT_MENU_CLASS}>
          <BPTableContextMenu
            handleClick={(e, target) => this.handleContextMenuClick(e, target)}
          />
        </ContextMenu>
        <BPDrawer />
        <DraggableModalProvider>
          <BPFileUploader
            visible={this.state.fileUploaderVisible}
            onClose={(close, data) => this.fileUploaderToggle(false, data)}
            data={this.state.currentFileHandlerObj}
          />
        </DraggableModalProvider>
      </div>
    );
  }
}

export default BPTable;

BPTable.defaultProps = {
  viewName: "",
};

BPTable.propTypes = {
  projectId: PropTypes.number.isRequired,
  viewName: PropTypes.string,
  idKey: PropTypes.number.isRequired,
  parentIdKey: PropTypes.number.isRequired,
  level: PropTypes.oneOf([BP_LEVELS.DYN1, BP_LEVELS.DYN2, BP_LEVELS.DYN3])
    .isRequired,
  updateClipboard: PropTypes.func.isRequired,
  getClipboard: PropTypes.func.isRequired,
};
