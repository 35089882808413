import React from 'react';
import PropTypes from 'prop-types';
import './FileTypes.scss';
import { stringToColour, invertColor } from '../../../utils/ColorUtils';

const FileTypes = ({ type }) => {
  let additionaStyle = {};
  if (['pdf', 'xlsx', 'xls'].indexOf(type) === -1) {
    const backgroundColor = stringToColour(type);
    additionaStyle = {
      backgroundColor,
      color: invertColor(backgroundColor, true),
    };
  }
  return (
    <span className={`FileTypes ${type.toLowerCase()}`} style={additionaStyle}>
      {type.toUpperCase()}
    </span>
  );
};

export default FileTypes;

FileTypes.propTypes = {
  type: PropTypes.string.isRequired,
};
