import React from 'react';
import PropTypes from 'prop-types';

import './ContextMenu.scss';

class ContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      target: null,
    };
  }

  componentDidMount() {
    document.addEventListener('contextmenu', this.handleContextMenu);
    document.addEventListener('click', this.handleClick);
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    try {
      document.removeEventListener('contextmenu', this.handleContextMenu);
      document.removeEventListener('click', this.handleClick);
      document.removeEventListener('scroll', this.handleScroll);
    } catch (e) {}
  }

  handleContextMenu = event => {
    if (!event.target.classList.contains(this.props.elementToAttachClass)) {
      return;
    }
    event.preventDefault();

    this.setState({ visible: true, target: event.target });

    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const rootW = this.root.offsetWidth;
    const rootH = this.root.offsetHeight;

    const right = screenW - clickX > rootW;
    const left = !right;
    const top = screenH - clickY > rootH;
    const bottom = !top;

    if (right) {
      this.root.style.left = `${clickX + 5}px`;
    }

    if (left) {
      this.root.style.left = `${clickX - rootW - 5}px`;
    }

    if (top) {
      this.root.style.top = `${clickY + 5}px`;
    }

    if (bottom) {
      this.root.style.top = `${clickY - rootH - 5}px`;
    }
  };

  handleClick = event => {
    const { visible } = this.state;
    const wasOutside = !(event.target.contains === this.root);
    if (wasOutside && visible) this.setState({ visible: false });
  };

  handleScroll = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  };

  render() {
    const { visible, target } = this.state;
    const { children } = this.props;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { target: target })
    );
    return (
      (visible || null) && (
        <div
          ref={ref => {
            this.root = ref;
          }}
          className="contextMenu"
        >
          {childrenWithProps}
        </div>
      )
    );
  }
}

export default ContextMenu;

ContextMenu.propTypes = {
  elementToAttachClass: PropTypes.string.isRequired,
};
