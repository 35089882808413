import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import { DEFAULT_DATE_FORMAT } from "../../../shared/Constants";
import "./ProjectMilestones.scss";

const ProjectMilestones = ({ milestones, showLoading, projectId }) => {
  const stones = [...milestones];
  const filtered = stones.filter((stone) => !stone.completed);
  const next = filtered.shift();
  return (
    <div className="ProjectMilestones">
      <div className="headline">
        <h6>Dates</h6> &nbsp;&nbsp;
        <Link to={`/projects/${projectId}/settings/?section=milestones`}>
          See all
        </Link>
      </div>
      <div className={`ld-over ${showLoading ? "running" : "not-running"}`}>
        <span className="ld ld-ring ld-spin" />
        <div className="grid-x sub-title">
          <div className="cell small-12">
            <span className="u-gray">Next</span>
          </div>
        </div>
        <div className="grid-x" data-fta={next ? next.milestoneParsed : ""}>
          <div className="cell small-6 medium-3">
            {next ? format(next.dateParsed, DEFAULT_DATE_FORMAT) : ""}
          </div>
          <div className="cell small-6 medium-9">
            {next ? next.milestoneParsed : ""}
          </div>
        </div>
        <div className="grid-x sub-title">
          <div className="cell small-12">
            <span className="u-gray">Coming Up</span>
          </div>
        </div>
        {filtered.map((obj) => (
          <div
            className="grid-x"
            key={obj.uniqId}
            data-fta={obj.milestoneParsed}
          >
            <div className="cell small-6 medium-3">
              {format(obj.dateParsed, DEFAULT_DATE_FORMAT)}
            </div>
            <div className="cell small-6 medium-9">{obj.milestoneParsed}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectMilestones;

ProjectMilestones.defaultProps = {
  showLoading: false,
};

ProjectMilestones.propTypes = {
  milestones: PropTypes.array.isRequired,
  projectId: PropTypes.number.isRequired,
  showLoading: PropTypes.bool,
};
