import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import Switch from "antd/lib/switch";
import Icon from "antd/lib/icon";
import moment from "moment";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";

import { FLHServiceStatic } from "../../services/FLHService";
import { openLogsOutside } from "../../modals/fasthooks/FasthooksLogsOutside";
import { StorageStaticDefault } from "../../services/Storage";
import { DEFAULT_DATE_FORMAT_WITH_TIME } from "../../shared/Constants";

class FasthookLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      // filteredInfo: null,
      defaultPageSize: 20,
      sortedInfo: {
        columnKey: "callDate",
        field: "callDate",
        order: "descend",
      },
      namesForFilter: new Set(),
      dataSource: [],
      summaryKeys: [
        { keyName: "entity", keyTitle: "Entity" },
        { keyName: "entityId", keyTitle: "Entity Id" },
        { keyName: "responseBody", keyTitle: "Response Body" },
      ],
      autoRefresh: false,
    };
    this.autoRefreshRef = null;
    this.keys = {
      qid: { label: "Queue Id", key: "qid" },
      entity: { label: "Entity", key: "entity" },
      entityId: { label: "Entity Id", key: "entityId" },
      uri: { label: "URI", key: "uri" },
      header: { label: "Header", key: "header" },
      body: { label: "Body", key: "body" },
      // responseHeader: { label: "Response Header", key: "responseHeader" },
      responseHeader: { label: "Response Header", key: "responseHeaderParsed" },
      responseCode: { label: "Response Code", key: "responseCode" },
      responseBody: { label: "Response Body", key: "responseBody" },
      callDate: { label: "Call Date", key: "callDate" },
      hookName: { label: "Hook Name", key: "hookName" },
      method: { label: "Method", key: "method" },
    };
  }
  componentDidMount() {
    StorageStaticDefault.getCB("logsToHideTimeStamp", (ts) => {
      this.setState({ cleanTs: ts }, () => {
        this.dataLoader();
      });
    });
  }
  componentWillMount() {
    this.stopAutoRefresh();
  }

  dataLoader = () => {
    this.setState({ isLoading: true });

    FLHServiceStatic.getHooksByProject(this.props.projectId, (data) => {
      const { retVal, namesForFilter } = transformer(data, this.state.cleanTs);
      this.setState({ dataSource: retVal, namesForFilter, isLoading: false });
    });
  };

  hideLogs = () => {
    const ts = +new Date();
    StorageStaticDefault.set("logsToHideTimeStamp", ts);
    this.setState({ cleanTs: ts }, () => {
      if (this.autoRefreshRef) {
        this.stopAutoRefresh();
        this.dataLoader();
        this.startAutoRefresh();
      } else {
        this.dataLoader();
      }
    });
  };

  clearLogsFilter = () => {
    StorageStaticDefault.remove("logsToHideTimeStamp");
    this.setState({ cleanTs: null }, () => {
      if (this.autoRefreshRef) {
        this.stopAutoRefresh();
        this.dataLoader();
        this.startAutoRefresh();
      } else {
        this.dataLoader();
      }
    });
  };

  setAutoRefresh = (start) => {
    if (start) {
      this.startAutoRefresh();
      this.setState({ autoRefresh: true });
    } else {
      this.stopAutoRefresh();
      this.setState({ autoRefresh: false });
    }
  };

  stopAutoRefresh = () => {
    clearInterval(this.autoRefreshRef);
  };
  startAutoRefresh = () => {
    this.autoRefreshRef = setInterval(() => {
      this.dataLoader();
    }, 5 * 1000);
  };

  handleMenuClick = (e) => {
    console.log("[FasthookLogs.jsx] e", e);
    if (e.key === "clear") {
      this.clearLogsFilter();
    }
  };

  hideMenu = () => (
    <Menu onClick={this.handleMenuClick}>
      {this.state.cleanTs && (
        <Menu.Item disabled key="ts">
          Ignoring logs older then{" "}
          {moment(this.state.cleanTs).format(DEFAULT_DATE_FORMAT_WITH_TIME)}
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="clear">Clear logs filter</Menu.Item>
    </Menu>
  );

  header = () => {
    return (
      <div className="table-header">
        <div className="left">
          <span>Fasthook Logs</span>
          {this.props.projectName && (
            <span className="project-info">
              {this.props.projectName}{" "}
              <span className="docket">{this.props.projectDocket}</span>
            </span>
          )}
        </div>
        <div className="right">
          <Dropdown.Button
            overlay={this.hideMenu}
            icon={<Icon type="caret-down" />}
            onClick={() => {
              this.hideLogs();
            }}
            className="elem-no-top"
            type={this.state.cleanTs ? "primary" : ""}
          >
            Ignore logs from current time stamp
          </Dropdown.Button>

          <Switch
            className="elem"
            checkedChildren="Disable Auto Refresh every 5s"
            unCheckedChildren="Enable Auto Refresh every 5s"
            onChange={(e) => this.setAutoRefresh(e)}
          />
          <Button
            onClick={() => {
              this.dataLoader();
            }}
          >
            Refresh
          </Button>

          <Icon className="elem-sync" type="sync" spin={this.state.isLoading} />

          {this.props.showPopupButton && (
            <Icon
              className="elem-icon elem-sync"
              type="fullscreen"
              onClick={() => {
                openLogsOutside(Number(this.props.projectId), {
                  projectName: this.props.project.name,
                  projectDocket: this.props.project.docket,
                });
              }}
            />
          )}
        </div>
      </div>
    );
  };

  onAdd = (keyName, keyTitle) => {
    const summaryKeys = [...this.state.summaryKeys];
    const found = summaryKeys.find((k) => k.keyName === keyName);
    if (!found) {
      summaryKeys.push({ keyName, keyTitle });
      this.setState({ summaryKeys });
    }
  };

  onRemove = (keyName) => {
    const summaryKeys = [...this.state.summaryKeys].filter(
      (item) => item.keyName !== keyName
    );
    this.setState({ summaryKeys });
  };

  renderExpanded = (record) => {
    return (
      <div className="expanded-record">
        <div className="grid-x grid-margin-x-custom">
          <div className="cell small-4">
            <div>
              <label>
                {this.keys.qid.label}:{" "}
                <Icon
                  type="plus-circle"
                  onClick={() =>
                    this.onAdd(this.keys.qid.key, this.keys.qid.label)
                  }
                />
              </label>{" "}
              <pre>{record.qid || "N/A"}</pre>
            </div>
            <div>
              <label>
                {this.keys.entity.label}:{" "}
                <Icon
                  type="plus-circle"
                  onClick={() =>
                    this.onAdd(this.keys.entity.key, this.keys.entity.label)
                  }
                />
              </label>{" "}
              <pre>{record.entity || "N/A"}</pre>
            </div>
            <div>
              <label>
                {this.keys.entityId.label}:{" "}
                <Icon
                  type="plus-circle"
                  onClick={() =>
                    this.onAdd(this.keys.entityId.key, this.keys.entityId.label)
                  }
                />
              </label>{" "}
              <pre>{record.entityId || "N/A"}</pre>
            </div>
            <div>
              <label>
                {this.keys.uri.label}:{" "}
                <Icon
                  type="plus-circle"
                  onClick={() =>
                    this.onAdd(this.keys.uri.key, this.keys.uri.label)
                  }
                />
              </label>{" "}
              <pre>{record.uri || "N/A"}</pre>
            </div>
          </div>
          <div className="cell small-4">
            <div>
              <label>
                {this.keys.header.label}:{" "}
                <Icon
                  type="plus-circle"
                  onClick={() =>
                    this.onAdd(this.keys.header.key, this.keys.header.label)
                  }
                />
              </label>{" "}
              <pre>{record.header || "N/A"}</pre>
            </div>
          </div>
          <div className="cell small-4">
            <div>
              <label>
                {this.keys.responseHeader.label}:{" "}
                <Icon
                  type="plus-circle"
                  onClick={() =>
                    this.onAdd(
                      this.keys.responseHeader.key,
                      this.keys.responseHeader.label
                    )
                  }
                />
              </label>{" "}
              <pre>{record.responseHeaderParsed || "N/A"}</pre>
            </div>
          </div>
        </div>
        <div className="grid-x grid-margin-x-custom">
          <div className="cell small-12">
            <label>
              {this.keys.body.label}:{" "}
              <Icon
                type="plus-circle"
                onClick={() =>
                  this.onAdd(this.keys.body.key, this.keys.body.label)
                }
              />
            </label>{" "}
            <pre>{record.body || "N/A"}</pre>
          </div>
        </div>
        <div className="grid-x grid-margin-x-custom">
          <div className="cell small-12">
            <label>
              {this.keys.responseBody.label}:{" "}
              <Icon
                type="plus-circle"
                onClick={() =>
                  this.onAdd(
                    this.keys.responseBody.key,
                    this.keys.responseBody.label
                  )
                }
              />
            </label>{" "}
            <pre>{record.responseBody || "N/A"}</pre>
          </div>
        </div>
      </div>
    );
  };

  handleChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    this.setState({
      defaultPageSize: pagination.pageSize,
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  makeBody = (keys, record) => {
    const ret = keys.map((key) => {
      return (
        <div key={key.keyName} className="body-cell">
          {record[key.keyName]}
        </div>
      );
    });
    return ret;
  };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    sortedInfo = sortedInfo || {};

    const filterValues = [];
    this.state.namesForFilter.forEach((n) => {
      filterValues.push({
        text: n,
        value: n,
      });
    });
    const columns = [
      { title: "Status", dataIndex: "responseCode", width: "5%" },
      {
        title: "Call Date",
        dataIndex: "callDate",
        width: "12%",
        sorter: (a, b) => moment(a.callDate).isSameOrAfter(moment(b.callDate)),
        sortOrder: sortedInfo.columnKey === "callDate" && sortedInfo.order,
      },
      {
        title: "Hook Name",
        width: "10%",
        filters: filterValues,
        filteredValue: filteredInfo.hookName || null,
        onFilter: (value, record) => {
          return record.hookName === value;
        },
        ellipsis: true,
        dataIndex: "hookName",
        render: (text, record) => (
          <>
            {record.hookName} :: {record.hookId}
          </>
        ),
      },
      {
        title: () => {
          return (
            <>
              {this.state.summaryKeys.map((key) => (
                <span className="body-cell" key={key.keyName}>
                  {key.keyTitle}
                  {"  "}
                  <Icon
                    type="minus-circle"
                    onClick={() => this.onRemove(key.keyName)}
                  />
                  {"  "}
                </span>
              ))}
            </>
          );
        },
        render: (record) => (
          <div className="body-select">
            {this.makeBody(this.state.summaryKeys, record)}
          </div>
        ),
      },
    ];

    return (
      <div className="FasthookLogs">
        <Table
          dataSource={this.state.dataSource}
          columns={columns}
          onChange={(a, b, c, d) => {
            console.log("[FasthookLogs.jsx] abcd", a, b, c, d);
            this.handleChange(a, b, c, d);
          }}
          expandedRowRender={(record) => this.renderExpanded(record)}
          title={() => this.header()}
          rowKey={(record) => record.qid}
          size="small"
          pagination={{
            defaultPageSize: this.state.defaultPageSize,
            pageSizeOptions: ["20", "50", "100", "200", "300"],
            showSizeChanger: true,
          }}
        />
      </div>
    );
  }
}

export default FasthookLogs;

FasthookLogs.defaultProps = {
  showPopupButton: false,
  project: { name: "", docket: "" },
};

FasthookLogs.propTypes = {
  projectId: PropTypes.number.isRequired,
  showPopupButton: PropTypes.bool,
  project: PropTypes.object,
};

const transformer = (data, ts) => {
  const retVal = [];
  const namesForFilter = new Set();
  const tpl = {
    code: 0,
    method: "",
    hookName: "",
    callDate: "",
    body: "",
    response: "",
    entityId: 0,
    entity: "",
  };
  data.forEach((d) => {
    const t = { ...tpl };
    t.hookName = d.name;
    t.hookId = d.id;
    t.method = d.method;
    namesForFilter.add(d.name);

    let filtered = d.log;
    if (ts) {
      filtered = d.log.filter((l) => moment(l.callDate).isAfter(ts));
    }

    filtered.forEach((log) => {
      t.qid = log.qid;
      t.entity = log.entity;
      t.entityId = log.entityId;
      t.uri = log.uri;
      t.header = log.header;
      t.body = log.body;
      t.responseHeader = log.responseHeader;
      t.responseHeaderParsed = log.responseHeader;
      t.responseCode = log.responseCode;
      t.responseBody = log.responseBody;
      t.callDate = log.callDate;

      let parsed = "";
      try {
        if (t.responseHeader) {
          const header = JSON.parse(t.responseHeader);
          if (header) {
            for (let key in header) {
              parsed += `${key}: ${header[key].join(", ")}\n`;
            }
            t.responseHeaderParsed = parsed;
          }
        } else {
          t.responseHeaderParsed = parsed;
        }
      } catch (error) {
        console.log("[FasthookLogs.jsx] cannot parse header", error);
      }

      try {
        t.responseHeader = JSON.stringify(
          JSON.parse(log.responseHeader),
          null,
          4
        );
        t.responseBody = JSON.stringify(JSON.parse(log.responseBody), null, 4);
      } catch (error) {
        console.log("[FasthookLogs.jsx] no json?", error);
      }

      t.responseCode = t.responseCode ? t.responseCode : "200 Ok";

      retVal.push({ ...t });
    });
  });
  return { retVal, namesForFilter };
};
