import React, { Component } from "react";
import "antd/dist/antd.css";
import { Button, Modal, Form, Input, Select, Radio, Checkbox } from "antd";

import "../clientsPage/ClientsPage.scss";

const Option = Select.Option;

const CollectionCreateForm = Form.create({ name: "form_in_modal" })(
  class extends Component {
    constructor() {
      super();
      this.state = { acknowledged: false };
    }

    getModalForm = (
      mTitle,
      getFieldDecorator,
      platforms,
      currentConections
    ) => {
      if (
        mTitle === "Add a New Partner or Advertiser" ||
        mTitle === "Create a new Client"
      ) {
        return (
          <Form>
            <Form.Item label="Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Please input Partner/ Advertiser's name!"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Short Code">
              {getFieldDecorator("shortcode", {
                rules: [
                  {
                    max: 10,
                    min: 2,
                    required: true,
                    message:
                      "Only letters and numbers, between 2 and 10 characters long",
                    whitespace: true,
                    validator: this.shortCodeValidator
                  }
                ]
              })(<Input className="shortCode" placeholder="Short Code" />)}

              <Checkbox
                id="acknowledged"
                name="acknowledged"
                onChange={this.handleChange}
              >
                I understand that short code is final and not alterable.
              </Checkbox>
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("partOrAdv", {
                initialValue: "advertiser"
              })(
                <Radio.Group>
                  <Radio value="partner">Partner</Radio>
                  <Radio value="advertiser">Advertiser</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        );
      } else if (!!platforms) {
        return (
          <Form layout="vertical">
            <Form.Item label="Platform">
              {getFieldDecorator("platform", {
                rules: [{ required: true, message: "Please select platform!" }]
              })(
                <Select>
                  {platforms.map(platform => (
                    <Option
                      // TODO IMPLEMENT disabled={element.platform === platform.name}
                      key={platform.id}
                      value={platform.id}
                      disabled={currentConections.some(
                        c => c.platformId === platform.id
                      )}
                    >
                      {platform.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Access Token">
              {getFieldDecorator("accessToken", {
                rules: [
                  { required: true, message: "Please input an access token!" }
                ]
              })(<Input.Password />)}
            </Form.Item>
          </Form>
        );
      } else {
        return (
          <Form layout="vertical">
            <Form.Item label="First Name">
              {getFieldDecorator("firstname", {
                rules: [{ required: true, message: "Please input first name!" }]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Last Name">
              {getFieldDecorator("lastname", {
                rules: [{ required: true, message: "Please input last name!" }]
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Email">
              {getFieldDecorator("email", {
                rules: [
                  { type: "email", message: "Input is not a valid email" },
                  { required: true, message: "Please input an Email!" }
                ]
              })(<Input placeholder={this.props.secondPlaceholder} />)}
            </Form.Item>

            <Form.Item label="Phone">
              {getFieldDecorator("phone")(
                <Input placeholder={this.props.secondPlaceholder} />
              )}
            </Form.Item>
          </Form>
        );
      }
    };

    shortCodeValidator(rule, value, cb) {
      const regularExpression = new RegExp(/^([a-zA-Z0-9]){2,10}$/);
      var valid = regularExpression.test(value);
      if (valid) return cb();
      else
        return cb([
          new Error(
            "Only letters and numbers, between 2 and 10 characters long"
          )
        ]);
    }

    handleChange = e => {
      this.setState({ acknowledged: e.target.checked });
    };

    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        modalTitle,
        platforms,
        currentConections,
        mustCheck
      } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title={modalTitle}
          okText="Add"
          onCancel={onCancel}
          onOk={onCreate}
          okButtonProps={{
            disabled: !this.state.acknowledged && mustCheck
          }}
        >
          {this.getModalForm(
            modalTitle,
            getFieldDecorator,
            platforms,
            currentConections
          )}
        </Modal>
      );
    }
  }
);

export default class CreateNewModal extends Component {
  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.addNew(values);
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { modalTitle, platforms, currentConections, mustCheck } = this.props;
    return (
      <div className="add-new-button">
        <Button type="primary" onClick={this.showModal}>
          {this.props.buttonText}
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          modalTitle={modalTitle}
          currentConections={currentConections}
          platforms={platforms}
          visible={this.state.visible}
          mustCheck={mustCheck}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
      </div>
    );
  }
}
