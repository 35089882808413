import React from "react";
import { connect } from "react-redux";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";

import { setSetBigQueryDialog } from "../../reducers/qndActions";
import { bpServiceStatic } from "../../pages/bp/BPService";
import { notify } from "../../shared/notifications/Notify";

const BigQueryQueryDialogInner = ({ open, projectId, onClose }) => {
  if (!open) return <></>;

  return (
    <>
      <Modal
        visible={true}
        title="BigQuery Query"
        onCancel={onClose}
        footer={null}
      >
        <BigQueryQueryBody projectId={projectId} />
      </Modal>
    </>
  );
};

const mapState = (state) => ({
  open: state.qndui.openBigQueryDialog,
  projectId: state.qndui.openBigQueryDialogProjectId,
});

const mapDispatch = (dispatch) => ({
  onClose: () =>
    dispatch({
      type: "qndui/setSetBigQueryDialog",
      payload: { open: false, projectId: 0 },
    }),
});

const BigQueryQueryDialog = connect(
  mapState,
  mapDispatch
)(BigQueryQueryDialogInner);

export default BigQueryQueryDialog;

const CHECK_OPTIONS = [
  { label: "Import Meta Titles", value: "meta" },
  { label: "Reset/Clear existing data", value: "clear" },
  { label: "Import Data", value: "data" },
];
// meta:
// type: boolean
// description: import meta-titles (default true)
// clear:
// type: boolean
// description: reset/clear existing d1/d2/d3 data (default true)
// data:
// type: boolean
// description: import data (default true)

class BigQueryQueryBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      loading: false,
      options: {
        data: true,
        meta: true,
        clear: true,
      },
    };
  }
  onChange(val) {
    this.setState({ value: val.target.value });
  }
  onChangeCheck(val) {
    const copy = {};
    for (let v in val) {
      copy[val[v]] = true;
    }
    this.setState({ options: copy });
  }
  sendBigQuery() {
    const options = {
      meta: this.state.options.meta || false,
      clear: this.state.options.clear || false,
      data: this.state.options.data || false,
    };
    this.setState({ loading: true });
    bpServiceStatic.executeBigQueryQueru(
      this.props.projectId,
      this.state.value,
      options,
      () => {
        setSetBigQueryDialog(false, 0);
        notify("BigQuery Query executed!", "", "info");
        setTimeout(() => {
          this.setState({ loading: false });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }, 1000);
      }
    );
  }
  onClose() {
    setSetBigQueryDialog(false, 0);
  }
  render() {
    return (
      <>
        Please provide BigQuery Query:
        <Input.TextArea
          readOnly={this.state.loading}
          value={this.state.value}
          onChange={(val) => this.onChange(val)}
        ></Input.TextArea>
        <Checkbox.Group
          style={{ padding: "5px 0" }}
          options={CHECK_OPTIONS}
          defaultValue={["data", "meta", "clear"]}
          onChange={(v) => this.onChangeCheck(v)}
        />
        <div className="flex-container align-right">
          <Button onClick={() => this.onClose()}>Close</Button>{" "}
          <Button
            loading={this.state.loading}
            onClick={() => this.sendBigQuery()}
            type="primary"
          >
            Execute
          </Button>
        </div>
      </>
    );
  }
}
