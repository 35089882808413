class PasswordStrengthValidator {
  constructor() {
    this.lower = new RegExp('^(?=.*[a-z])');
    this.upper = new RegExp('^(?=.*[A-Z])');
    // it fails for ^ and \ and /. No time to investigate.
    this.special = new RegExp('^(?=.*[^A-z0-9])');
    this.number = new RegExp('^(?=.*[0-9])');
  }

  validateLower(input) {
    return this.lower.test(input);
  }

  validateUpper(input) {
    return this.upper.test(input);
  }

  validateSpecial(input) {
    return (
      this.special.test(input)
      || input.indexOf('^') > -1
      || input.indexOf('/') > -1
      || input.indexOf('\\') > -1
    );
  }

  validateNumber(input) {
    return this.number.test(input);
  }
  /* eslint-disable */
  /* stupid rule, class method should have this. but I don't need it. */
  validateLength(input, length) {
    return input.length >= length;
  }
  /* eslint-enable */
}

export default PasswordStrengthValidator;
