import React from "react";
import PropTypes from "prop-types";
import { Select, Icon, Divider, Modal, Input } from "antd";
import { SMFServiceStatic } from "../../../../services/SMFService.js";
const Option = Select.Option;

const confirm = Modal.confirm;

const styles = {
  main: {
    width: "100%",
  },
  divider: {
    margin: "4px 0",
  },
  button: {
    padding: "8px",
    cursor: "pointer",
    zIndex: "90000",
  },
  drop: {
    width: "100%",
  },
};

class SpecialInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }
  onChange(e) {
    const val = e.target.value;
    const reg = val.match(/^[a-zA-Z0-9_]+$/);
    // CLEANUP clean up after release
    // console.log("[SMFGroupSelect.jsx] reg", reg);
    if (reg) {
      this.setState({ value: val });
      this.props.onChange(val);
    } else {
      alert("Letters, numbers and _ allowed only!");
    }
  }
  render() {
    return (
      <Input
        placeholder="Name"
        onChange={(e) => this.onChange(e)}
        value={this.state.value}
      />
    );
  }
}

const showConfirm = (projectId, onAddedNewCB, goalOptions) => {
  let value = "";
  let goalVal = 0;
  const onChangeHandler = (val) => {
    value = val;
  };
  const onGoalChange = (e) => {
    goalVal = e;
  };
  return confirm({
    title: "Whats new group?",
    content: (
      <span>
        <SpecialInput onChange={onChangeHandler} />
        <Select style={styles.drop} placeholder="Goal" onChange={onGoalChange}>
          {goalOptions.map((goal) => {
            return (
              <Option key={goal.value} value={goal.value}>
                {goal.label}
              </Option>
            );
          })}
        </Select>
      </span>
    ),
    onOk() {
      return new Promise((resolve, reject) => {
        SMFServiceStatic.postGroup(
          projectId,
          value,
          goalVal,
          (data) => {
            resolve(data);
            const last = data[data.length - 1];
            onAddedNewCB(data, last);
          },
          (err) => {
            reject(err);
          }
        );
      }).catch((err) => console.log("Oops errors!", err));
    },
    onCancel() {},
  });
};

class SMFGroupSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { options } = this.props;
    return (
      <div className="SMFGroupSelect">
        <Select
          defaultValue={this.props.valueObj.id}
          style={styles.main}
          onChange={(val) => this.props.onChange(val)}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={styles.divider} />
              <div
                style={styles.button}
                onMouseDown={(e) => {
                  showConfirm(
                    this.props.projectId,
                    this.props.onAddedNewCB,
                    this.props.goalOptions
                  );
                }}
              >
                <Icon type="plus" /> New Group
              </div>
            </div>
          )}
        >
          {options.map((el) => {
            return (
              <Option key={el.id} value={el.id}>
                {el.value}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  }
}

export default SMFGroupSelect;

SMFGroupSelect.defaultProps = {
  options: [],
  valueObj: {},
};

SMFGroupSelect.propTypes = {
  options: PropTypes.array,
  goalOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onAddedNewCB: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  valueObj: PropTypes.object,
};
