/* eslint-disable import/prefer-default-export */
import * as types from '../types/types';

export const browserHistoryChanged = (historyObj, action) => (dispatch) => {
  dispatch({
    type: types.BROWSER_HISTORY_CHANGE,
    payload: {
      ...historyObj,
      action,
    },
  });
};

window.browserHistoryChanged = browserHistoryChanged;
