import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

class ProjectRoleNewLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUserOption: '',
      selectedRoleOption: '',
    };
  }

  innerHandleRoleChange = (selectedRoleOption) => {
    this.setState({ selectedRoleOption });
  };

  innerHandleUserChange = (selectedUserOption) => {
    this.setState({ selectedUserOption });
  };

  saveCallback() {
    if (this.state.selectedUserOption && this.state.selectedRoleOption) {
      const retVal = {
        userId: this.state.selectedUserOption.value,
        roleId: this.state.selectedRoleOption.value,
      };
      this.props.saveCallback(retVal);
      this.setState({
        selectedUserOption: '',
        selectedRoleOption: '',
      });
    }
  }

  cancelCallback() {
    this.props.cancelCallback();
  }

  render() {
    return (
      <div className="ProjectRoleNewLine">
        <div className="grid-x">
          <div className="cell small-5 medium-3 cell-role">
            <Select
              name="role"
              value={this.state.selectedRoleOption}
              options={this.props.roles}
              onChange={(val) => {
                this.innerHandleRoleChange(val);
              }}
              clearable={false}
            />
          </div>
          <div className="cell small-6 medium-6 cell-name">
            <Select
              name="user"
              value={this.state.selectedUserOption}
              options={this.props.users}
              onChange={(val) => {
                this.innerHandleUserChange(val);
              }}
              clearable={false}
            />
          </div>
          <div className="cell small-1 medium-3 u-vertical-middle">
            <div className="controls">
              <button onClick={() => this.cancelCallback()} className="action-btn cancel-btn">
                Cancel
              </button>
              <button onClick={() => this.saveCallback()} className="action-btn save-btn">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectRoleNewLine;

ProjectRoleNewLine.propTypes = {
  saveCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};
