/* eslint-disable */
/*jshint esversion: 6 */
/*global fetch, btoa */
import Q from "q";
import axios from "axios";
/**
 * Noise Digital Advertising Management Platform.  All objects have timestamps (dateCreated/dateModified) outputs.
 * @class CAMP
 * @param {(string|object)} [domainOrOptions] - The project domain or options object. If object, see the object's optional properties.
 * @param {string} [domainOrOptions.domain] - The project domain
 * @param {object} [domainOrOptions.token] - auth token - object with value property and optional headerOrQueryName and isQuery properties
 */
let CAMP = (function() {
  "use strict";

  function CAMP(options) {
    let domain = typeof options === "object" ? options.domain : options;
    this.domain = domain ? domain : "https://fastloop.loc";
    if (this.domain.length === 0) {
      throw new Error("Domain parameter must be specified as a string.");
    }
    this.token =
      typeof options === "object" ? (options.token ? options.token : {}) : {};
  }

  function serializeQueryParams(parameters) {
    let str = [];
    for (let p in parameters) {
      if (parameters.hasOwnProperty(p)) {
        str.push(
          encodeURIComponent(p) + "=" + encodeURIComponent(parameters[p])
        );
      }
    }
    return str.join("&");
  }

  function mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        let parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  /**
   * HTTP Request
   * @method
   * @name CAMP#request
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} parameters
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   * @param {object} form - form data object
   * @param {object} deferred - promise object
   */
  CAMP.prototype.request = function(
    method,
    url,
    parameters,
    body,
    headers,
    queryParameters,
    form,
    deferred
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? "?" + queryParams : "");

    if (body && !Object.keys(body).length) {
      body = undefined;
    }

    axios({
      method: method,
      url: urlWithParams,
      headers: headers,
      data: JSON.stringify(body),
    })
      .then((resp) => {
        return deferred.resolve(resp.data);
      })
      .catch((error) => {
        deferred.reject(error);
      });
  };

  /**
   * Set Token
   * @method
   * @name CAMP#setToken
   * @param {string} value - token's value
   * @param {string} headerOrQueryName - the header or query name to send the token at
   * @param {boolean} isQuery - true if send the token as query param, otherwise, send as header param
   */
  CAMP.prototype.setToken = function(value, headerOrQueryName, isQuery) {
    this.token.value = value;
    this.token.headerOrQueryName = headerOrQueryName;
    this.token.isQuery = isQuery;
  };
  /**
   * Set Auth headers
   * @method
   * @name CAMP#setAuthHeaders
   * @param {object} headerParams - headers object
   */
  CAMP.prototype.setAuthHeaders = function(headerParams) {
    let headers = headerParams ? headerParams : {};
    if (!this.token.isQuery) {
      if (this.token.headerOrQueryName) {
        headers[this.token.headerOrQueryName] = this.token.value;
      } else if (this.token.value) {
        headers["Authorization"] = "Bearer " + this.token.value;
      }
    }
    return headers;
  };

  /**
   * search health check
   * @method
   * @name CAMP#ping
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.ping = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/ping";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * server health check with optional passthrough
   * @method
   * @name CAMP#postPing
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - binary init container
   */
  CAMP.prototype.postPing = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/echo";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * trigger internal maintenance scripts
   * @method
   * @name CAMP#adminBin
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - binary init container
   */
  CAMP.prototype.adminBin = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/bin";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * sync defaults
   * @method
   * @name CAMP#syncDefaults
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - defaults options container
   */
  CAMP.prototype.syncDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/defaults";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * set api maintenance mode
   * @method
   * @name CAMP#updateMaintenance
   * @param {object} parameters - method options and parameters
   * @param {boolean} parameters.status - maintenance status
   */
  CAMP.prototype.updateMaintenance = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/maintenance/{status}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{status}", parameters["status"]);

    if (parameters["status"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: status"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get meta data customer defaults
   * @method
   * @name CAMP#getDynamic1MetaDefaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getDynamic1MetaDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/dynamic1/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update Dynamic1 Meta Defaults
   * @method
   * @name CAMP#putDynamic1MetaDefaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - Dynamic1 Meta Defaults
   */
  CAMP.prototype.putDynamic1MetaDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/dynamic1/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic2 meta defaults by customer ID
   * @method
   * @name CAMP#getDynamic2MetaDefaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getDynamic2MetaDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/dynamic2/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update dynamic2 meta Defaults
   * @method
   * @name CAMP#putDynamic2Meta_Defaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - dynamic2 meta Defaults
   */
  CAMP.prototype.putDynamic2Meta_Defaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/dynamic2/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic3 meta defaults by customer ID
   * @method
   * @name CAMP#getDynamic3MetaDefaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getDynamic3MetaDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/dynamic3/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update dynamic3 Meta Defaults
   * @method
   * @name CAMP#putDynamic3Meta_Defaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - Dynamic3 Meta Defaults
   */
  CAMP.prototype.putDynamic3Meta_Defaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/dynamic3/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all lists defaults by customer ID
   * @method
   * @name CAMP#getListsDefaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getListsDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/lists/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update Lists Defaults
   * @method
   * @name CAMP#putListsDefaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - Lists
   */
  CAMP.prototype.putListsDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/lists/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all views defaults by customer ID
   * @method
   * @name CAMP#getViewsDefaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getViewsDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/views/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update Views Defaults
   * @method
   * @name CAMP#putViewsDefaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - View
   */
  CAMP.prototype.putViewsDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/views/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Sync user view(s) to project
   * @method
   * @name CAMP#syncViews
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - View
   */
  CAMP.prototype.syncViews = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/views/sync/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Create a new user
   * @method
   * @name CAMP#createUser
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - project role container
   */
  CAMP.prototype.createUser = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/users";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update current logged in user
   * @method
   * @name CAMP#updateUserAdmin
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.username - The name that needs to be fetched
   * @param {} parameters.body - Updated user data
   */
  CAMP.prototype.updateUserAdmin = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/users/{username}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{username}", parameters["username"]);

    if (parameters["username"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: username"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * block user by id
   * @method
   * @name CAMP#blockUser
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - user id
   */
  CAMP.prototype.blockUser = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/users/block/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * unblock user by id
   * @method
   * @name CAMP#unblockUser
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - user id
   */
  CAMP.prototype.unblockUser = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/admin/users/unblock/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * list valid ad types
   * @method
   * @name CAMP#adtypes
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Platform ID
   */
  CAMP.prototype.adtypes = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/adtypes/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * rebuild bigquery cache tables
   * @method
   * @name CAMP#cacheBQ
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project ID
   */
  CAMP.prototype.cacheBQ = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/bigquery/cache/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all dynamic data nested by project id
   * @method
   * @name CAMP#getDynamicByProject
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project ID
   */
  CAMP.prototype.getDynamicByProject = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update nested dynamic lines
   * @method
   * @name CAMP#putDynamic
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic
   */
  CAMP.prototype.putDynamic = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get sheet status
   * @method
   * @name CAMP#getSheetStatus
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project ID
   */
  CAMP.prototype.getSheetStatus = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/status/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get sheet schema
   * @method
   * @name CAMP#getSheetSchema
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project ID
   */
  CAMP.prototype.getSheetSchema = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/schema/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Sync project to sheets and back
   * @method
   * @name CAMP#syncSheet
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project ID
   */
  CAMP.prototype.syncSheet = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/sync/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Sync sheet (one-way) from Google
   * @method
   * @name CAMP#pullSheet
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - sheet import parameters
   */
  CAMP.prototype.pullSheet = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/pull/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Export data to sheets with optional flags
   * @method
   * @name CAMP#exportSheet
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - sheet export parameters
   */
  CAMP.prototype.exportSheet = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/export/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get project's sheet queries by projectId
   * @method
   * @name CAMP#getSheetQueries
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project ID
   */
  CAMP.prototype.getSheetQueries = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/queries/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get query by query id
   * @method
   * @name CAMP#getSheetQuery
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - queryId
   */
  CAMP.prototype.getSheetQuery = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/query/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add query to project by projectId
   * @method
   * @name CAMP#postSheetQuery
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - projectId
   * @param {} parameters.body - sheet query container
   */
  CAMP.prototype.postSheetQuery = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/query/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * edit query by projectId
   * @method
   * @name CAMP#putSheetQuery
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - projectId
   * @param {} parameters.body - sheet query container
   */
  CAMP.prototype.putSheetQuery = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/query/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * remove query by query ID
   * @method
   * @name CAMP#deleteSheetQuery
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - queryId
   */
  CAMP.prototype.deleteSheetQuery = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/query/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Share sheet by email
   * @method
   * @name CAMP#shareSheet
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - emails
   */
  CAMP.prototype.shareSheet = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/sheets/share/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all lines from dynamic1 by project id
   * @method
   * @name CAMP#getDynamic1ByProject
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project ID
   */
  CAMP.prototype.getDynamic1ByProject = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic1/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post a new line to dynamic1 by project id
   * @method
   * @name CAMP#postDynamic1
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic1
   */
  CAMP.prototype.postDynamic1 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic1/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update lines in a dynamic1 by project id
   * @method
   * @name CAMP#putDynamic1
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic1
   */
  CAMP.prototype.putDynamic1 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic1/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Remove a dynamic1 by line id
   * @method
   * @name CAMP#deleteDynamic1
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - dynamic1 line id
   */
  CAMP.prototype.deleteDynamic1 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic1/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic1 by project id
   * @method
   * @name CAMP#getDynamic1Flat
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getDynamic1Flat = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic1/flat/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get meta data for a dynamic1 by project ID
   * @method
   * @name CAMP#getDynamic1Meta
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getDynamic1Meta = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic1/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update Dynamic1 Meta
   * @method
   * @name CAMP#putDynamic1Meta
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic1 Meta
   */
  CAMP.prototype.putDynamic1Meta = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic1/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post a new line to dynamic1 by project id
   * @method
   * @name CAMP#postImportDynamic1
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic1
   */
  CAMP.prototype.postImportDynamic1 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic1/importquery/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Import datanew data from query (no clear)
   * @method
   * @name CAMP#putImportDynamic1
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic1
   */
  CAMP.prototype.putImportDynamic1 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic1/importquery/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * list valid customers
   * @method
   * @name CAMP#getCustomers
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getCustomers = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * create new Fastloop client
   * @method
   * @name CAMP#postCustomer
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - dynamic1
   */
  CAMP.prototype.postCustomer = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * create new Fastloop client
   * @method
   * @name CAMP#postCustomerInvite
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - dynamic1
   */
  CAMP.prototype.postCustomerInvite = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/invite";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get customer with detail by id
   * @method
   * @name CAMP#getCustomer
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getCustomer = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update customer details
   * @method
   * @name CAMP#putCustomer
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - detailed customer input
   */
  CAMP.prototype.putCustomer = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * delete customer by ID
   * @method
   * @name CAMP#deleteCustomer
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - ID of customer to delete
   */
  CAMP.prototype.deleteCustomer = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all customer contacts by customer ID
   * @method
   * @name CAMP#getCustomerContacts
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getCustomerContacts = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/contacts/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update customer contacts
   * @method
   * @name CAMP#putCustomerContacts
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - Customer Contacts
   */
  CAMP.prototype.putCustomerContacts = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/contacts/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all customer notes by customer ID
   * @method
   * @name CAMP#getCustomerNotes
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getCustomerNotes = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/notes/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update customer notes
   * @method
   * @name CAMP#putCustomerNotes
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - Customer Notes
   */
  CAMP.prototype.putCustomerNotes = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/notes/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all customer files by customer ID
   * @method
   * @name CAMP#getCustomerFiles
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getCustomerFiles = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/files/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update customer files
   * @method
   * @name CAMP#putCustomerFiles
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - Customer Files
   */
  CAMP.prototype.putCustomerFiles = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/files/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all customer users by customer ID
   * @method
   * @name CAMP#getCustomerUsers
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getCustomerUsers = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/users/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update customer users
   * @method
   * @name CAMP#putCustomerUsers
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - Customer Users
   */
  CAMP.prototype.putCustomerUsers = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/users/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get customer credentials by customer ID
   * @method
   * @name CAMP#getCustomerCredentials
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getCustomerCredentials = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/credentials/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Add or update customer platform dc tokens
   * @method
   * @name CAMP#putCustomerCredentials
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   * @param {} parameters.body - credentials
   */
  CAMP.prototype.putCustomerCredentials = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/customers/credentials/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * delete platform dc tokens by credential Id
   * @method
   * @name CAMP#deleteCustomerCredential
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.deleteCustomerCredential = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/credentials/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * list fasthook defaults groups by customer
   * @method
   * @name CAMP#getHookDefaultGroups
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - customer id
   */
  CAMP.prototype.getHookDefaultGroups = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/fasthooks/defaults/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * event hook callback endpoint
   * @method
   * @name CAMP#postHookDefaults
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - hook group name(s)
   */
  CAMP.prototype.postHookDefaults = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/fasthooks/defaults/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all available fasthook events
   * @method
   * @name CAMP#getEvents
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getEvents = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/events";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get event hooks mapping info/status by entity
   * @method
   * @name CAMP#getEventEntityInfo
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.eventId - Event ID
   * @param {array} parameters.entityIds - entity ids
   */
  CAMP.prototype.getEventEntityInfo = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/event/{eventId}/entity/{entityIds}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{eventId}", parameters["eventId"]);

    if (parameters["eventId"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: eventId"));
      return deferred.promise;
    }

    path = path.replace("{entityIds}", parameters["entityIds"]);

    if (parameters["entityIds"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: entityIds"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * trigger event
   * @method
   * @name CAMP#eventTrigger
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - event id
   * @param {} parameters.body - entity id(s)
   */
  CAMP.prototype.eventTrigger = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/events/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * trigger event
   * @method
   * @name CAMP#eventParentTrigger
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - event id
   * @param {} parameters.body - entity id(s)
   */
  CAMP.prototype.eventParentTrigger = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/events/parents/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * trigger event
   * @method
   * @name CAMP#eventChildTrigger
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - event id
   * @param {} parameters.body - entity id(s)
   */
  CAMP.prototype.eventChildTrigger = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/events/children/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * trigger specific hooks for event
   * @method
   * @name CAMP#eventHookTrigger
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - event id
   * @param {array} parameters.hookIds - hook ids
   * @param {} parameters.body - entity id(s)
   */
  CAMP.prototype.eventHookTrigger = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/events/{id}/{hookIds}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    path = path.replace("{hookIds}", parameters["hookIds"]);

    if (parameters["hookIds"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: hookIds"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * event hook callback endpoint
   * @method
   * @name CAMP#eventCallback
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - callback uuid
   * @param {} parameters.body - Event EntityChain mapped JSON
   */
  CAMP.prototype.eventCallback = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/events/callback/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * create new hook
   * @method
   * @name CAMP#addHook
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Hook object
   */
  CAMP.prototype.addHook = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all event hooks by project ID
   * @method
   * @name CAMP#getEventHooks
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Project ID
   */
  CAMP.prototype.getEventHooks = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update an existing Hook
   * @method
   * @name CAMP#updateHook
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   * @param {} parameters.body - Hook object
   */
  CAMP.prototype.updateHook = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * delete hook by ID
   * @method
   * @name CAMP#deleteHook
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   */
  CAMP.prototype.deleteHook = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get Hook Conditions
   * @method
   * @name CAMP#getHookConditions
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   */
  CAMP.prototype.getHookConditions = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/conditions/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update Hook Conditions
   * @method
   * @name CAMP#updateHookConditions
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   * @param {} parameters.body - Hook object
   */
  CAMP.prototype.updateHookConditions = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/conditions/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get Hook Inputs Map
   * @method
   * @name CAMP#getHookMap
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   */
  CAMP.prototype.getHookMap = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/map/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update Hook Inputs Map
   * @method
   * @name CAMP#updateHookMap
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   * @param {} parameters.body - Hook object
   */
  CAMP.prototype.updateHookMap = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/map/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get Hook call log
   * @method
   * @name CAMP#getHookLog
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   */
  CAMP.prototype.getHookLog = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/logs/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get Hook call log
   * @method
   * @name CAMP#getHookLogLimit
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   * @param {integer} parameters.limit - Limit
   */
  CAMP.prototype.getHookLogLimit = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/logs/{id}/{limit}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    path = path.replace("{limit}", parameters["limit"]);

    if (parameters["limit"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: limit"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get Hook call log
   * @method
   * @name CAMP#getHookLogLimitOffset
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   * @param {integer} parameters.limit - Limit
   * @param {integer} parameters.offset - Limit
   */
  CAMP.prototype.getHookLogLimitOffset = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/logs/{id}/{limit}/{offset}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    path = path.replace("{limit}", parameters["limit"]);

    if (parameters["limit"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: limit"));
      return deferred.promise;
    }

    path = path.replace("{offset}", parameters["offset"]);

    if (parameters["offset"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: offset"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * test fasthook build
   * @method
   * @name CAMP#testHook
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   * @param {} parameters.body - Event Key/Value pairs
   */
  CAMP.prototype.testHook = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hooks/test/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * create new callback
   * @method
   * @name CAMP#addCallback
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   * @param {} parameters.body - Callback object
   */
  CAMP.prototype.addCallback = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/callbacks/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all hook callbacks by Hook ID
   * @method
   * @name CAMP#getHookCallbacks
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Hook ID
   */
  CAMP.prototype.getHookCallbacks = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/callbacks/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update an existing Callback
   * @method
   * @name CAMP#updateCallback
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Callback ID
   * @param {} parameters.body - Hook object
   */
  CAMP.prototype.updateCallback = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/callbacks/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * delete callback by ID
   * @method
   * @name CAMP#deleteCallback
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Callback ID
   */
  CAMP.prototype.deleteCallback = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/callbacks/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get Callback Outputs Map
   * @method
   * @name CAMP#getCallbackMap
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Callback ID
   */
  CAMP.prototype.getCallbackMap = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/callbacks/map/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update Callback Outputs Map
   * @method
   * @name CAMP#updateCallbackMap
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Callback ID
   * @param {} parameters.body - Hook object
   */
  CAMP.prototype.updateCallbackMap = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/callbacks/map/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * test callback handling
   * @method
   * @name CAMP#testCallback
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - callback ID
   * @param {} parameters.body - mock response body
   */
  CAMP.prototype.testCallback = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/callbacks/test/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get hook mapping info/status by entity
   * @method
   * @name CAMP#getHookEntityInfo
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.hookId - Hook ID
   * @param {array} parameters.entityIds - entity ids
   */
  CAMP.prototype.getHookEntityInfo = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/hook/{hookId}/entity/{entityIds}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{hookId}", parameters["hookId"]);

    if (parameters["hookId"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: hookId"));
      return deferred.promise;
    }

    path = path.replace("{entityIds}", parameters["entityIds"]);

    if (parameters["entityIds"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: entityIds"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * upload file
   * @method
   * @name CAMP#uploadFile
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.file - file to upload
   */
  CAMP.prototype.uploadFile = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/files";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["multipart/form-data"];

    if (parameters["file"] !== undefined) {
      form["file"] = parameters["file"];
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * returns all files that belong to the current logged in user
   * @method
   * @name CAMP#getFiles
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getFiles = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/files";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * returns full file contents by unique hash or zip for multiple files
   * @method
   * @name CAMP#getFileByID
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.ids - file ids/hashes
   */
  CAMP.prototype.getFileByID = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/files/{ids}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/octet-stream"];

    path = path.replace("{ids}", parameters["ids"]);

    if (parameters["ids"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: ids"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * returns full file contents by unique hash or zip for multiple files
   * @method
   * @name CAMP#getFileMetaByID
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.ids - file ids/hashes
   */
  CAMP.prototype.getFileMetaByID = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/files/meta/{ids}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/octet-stream"];

    path = path.replace("{ids}", parameters["ids"]);

    if (parameters["ids"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: ids"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * returns full file contents by unique hash
   * @method
   * @name CAMP#getRawFileByID
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - file hashes
   */
  CAMP.prototype.getRawFileByID = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/file/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/octet-stream"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all available tags by file hash
   * @method
   * @name CAMP#getFileTags
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - file hash
   */
  CAMP.prototype.getFileTags = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/file/tags/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add tag(s) to file by hash
   * @method
   * @name CAMP#postFileTag
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - file hash
   * @param {} parameters.body - tag container
   */
  CAMP.prototype.postFileTag = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/file/tags/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add a new note
   * @method
   * @name CAMP#addNote
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Note object
   */
  CAMP.prototype.addNote = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/notes";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all availablea/accessible notes
   * @method
   * @name CAMP#getNotes
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getNotes = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/notes";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * returns a single note
   * @method
   * @name CAMP#getNoteByID
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - ID of note to return
   */
  CAMP.prototype.getNoteByID = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/notes/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update an existing note
   * @method
   * @name CAMP#updateNote
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - ID of note to edit
   * @param {} parameters.body - new note content
   */
  CAMP.prototype.updateNote = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/notes/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * delete note by ID
   * @method
   * @name CAMP#deleteNote
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - ID of note to delete
   */
  CAMP.prototype.deleteNote = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/notes/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * request a password reset
   * @method
   * @name CAMP#requestPasswordReset
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - password reset request container
   */
  CAMP.prototype.requestPasswordReset = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/passwordreset";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * reset user password
   * @method
   * @name CAMP#passwordReset
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - password reset required fields container
   */
  CAMP.prototype.passwordReset = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/passwordreset";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all available platforms
   * @method
   * @name CAMP#getPlatforms
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getPlatforms = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/platforms";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add new project
   * @method
   * @name CAMP#addProject
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - project object
   */
  CAMP.prototype.addProject = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all available/accessible projects
   * @method
   * @name CAMP#getProjects
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getProjects = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get all available/accessible projects
   * @method
   * @name CAMP#listProjects
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.listProjects = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/list";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get project summary by id
   * @method
   * @name CAMP#getProjectSummaryById
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - ID of project that needs to be fetched
   */
  CAMP.prototype.getProjectSummaryById = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/summary/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * get project by id
   * @method
   * @name CAMP#getProjectById
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - ID of project that needs to be fetched
   */
  CAMP.prototype.getProjectById = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * update project
   * @method
   * @name CAMP#updateProject
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - ID of project that needs to be fetched
   * @param {} parameters.body - project file container
   */
  CAMP.prototype.updateProject = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * mark a project deleted, and no longer return it in responses
   * @method
   * @name CAMP#deleteProject
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - projectId
   */
  CAMP.prototype.deleteProject = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get project constants by id
   * @method
   * @name CAMP#getProjectConstants
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getProjectConstants = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/constants/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add project constants
   * @method
   * @name CAMP#postProjectConstants
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id constants will be added to
   * @param {} parameters.body - project constant container
   */
  CAMP.prototype.postProjectConstants = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/constants/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * edit constant(s) by projectId
   * @method
   * @name CAMP#putProjectConstants
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - projectConstantId
   * @param {} parameters.body - project constant container
   */
  CAMP.prototype.putProjectConstants = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/constants/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * remove constant(s) from project
   * @method
   * @name CAMP#deleteProjectConstants
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - projectConstantId
   */
  CAMP.prototype.deleteProjectConstants = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/constants/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get project fee formulas
   * @method
   * @name CAMP#getProjectFeeFormulas
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getProjectFeeFormulas = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/feeFormulas/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add project fee formulas
   * @method
   * @name CAMP#postProjectFeeFormulas
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id fee formulas ill be added to
   * @param {} parameters.body - project fee formula container
   */
  CAMP.prototype.postProjectFeeFormulas = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/feeFormulas/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * edit fee formula(s) by projectId
   * @method
   * @name CAMP#putProjectFeeFormulas
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - feeFormulaId
   * @param {} parameters.body - project fee formula container
   */
  CAMP.prototype.putProjectFeeFormulas = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/feeFormulas/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * remove fee formula from project
   * @method
   * @name CAMP#deleteProjectFeeFormula
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - feeFormulaId
   */
  CAMP.prototype.deleteProjectFeeFormula = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/feeFormulas/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all available project files
   * @method
   * @name CAMP#getProjectsFiles
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getProjectsFiles = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/files";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get Files for a specific Project
   * @method
   * @name CAMP#getProjectFiles
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - ID of Project
   */
  CAMP.prototype.getProjectFiles = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/files/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add existing file to projectId
   * @method
   * @name CAMP#postProjectFile
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - ID of Project this file will be added to
   * @param {} parameters.body - project file container
   */
  CAMP.prototype.postProjectFile = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/files/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add new note to projectId
   * @method
   * @name CAMP#postProjectNote
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - ID of project this note will be added to
   * @param {} parameters.body - note object
   */
  CAMP.prototype.postProjectNote = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/notes/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all available project tags
   * @method
   * @name CAMP#getProjectTags
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getProjectTags = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/tags";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add tag(s) to projectId
   * @method
   * @name CAMP#postProjectTag
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - ID of project this tag will be added to
   * @param {} parameters.body - project tag container
   */
  CAMP.prototype.postProjectTag = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/tags/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * remove tag(s) from project
   * @method
   * @name CAMP#deleteProjectTag
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - project/tag relation id
   */
  CAMP.prototype.deleteProjectTag = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/tags/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all available project milestones
   * @method
   * @name CAMP#getProjectMilestones
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getProjectMilestones = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/milestones";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add milestone(s) to projectId
   * @method
   * @name CAMP#postProjectMilestone
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id milestone will be added to
   * @param {} parameters.body - project milestone container
   */
  CAMP.prototype.postProjectMilestone = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/milestones/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * edit milestone(s) to projectId
   * @method
   * @name CAMP#putProjectMilestone
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project/milestone relation id
   * @param {} parameters.body - project milestone container
   */
  CAMP.prototype.putProjectMilestone = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/milestones/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * remove milestone(s) from project by relation id
   * @method
   * @name CAMP#deleteProjectMilestone
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - project/milestone relation id
   */
  CAMP.prototype.deleteProjectMilestone = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/milestones/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all available project users/roles
   * @method
   * @name CAMP#getProjectUserRoles
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - project id
   */
  CAMP.prototype.getProjectUserRoles = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/users/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all available project roles
   * @method
   * @name CAMP#getProjectRoles
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getProjectRoles = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/roles";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add user(s)/role(s) to projectId
   * @method
   * @name CAMP#postProjectRole
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id to add user to
   * @param {} parameters.body - project role container
   */
  CAMP.prototype.postProjectRole = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/roles/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * remove role(s) from project by relation id
   * @method
   * @name CAMP#deleteProjectRole
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - projectRoleId, project/user/role relation id
   */
  CAMP.prototype.deleteProjectRole = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/roles/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * add platform to projectId
   * @method
   * @name CAMP#postProjectPlatform
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id to add platform to
   * @param {} parameters.body - project platforms container
   */
  CAMP.prototype.postProjectPlatform = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/platforms/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * archive project
   * @method
   * @name CAMP#putProjectArchive
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.putProjectArchive = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/archive/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * restore archived project
   * @method
   * @name CAMP#putProjectUnArchive
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.putProjectUnArchive = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/unarchive/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * import project from S3 bucket
   * @method
   * @name CAMP#importProject
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - unique docket ID of project to import into
   * @param {} parameters.body - s3 import container
   */
  CAMP.prototype.importProject = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/import/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * export project to s3 bucket
   * @method
   * @name CAMP#exportProject
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - unique docket ID of project to export
   * @param {} parameters.body - s3 credentials
   */
  CAMP.prototype.exportProject = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/export/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json, application/csv"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * publically get project secrets by authenticationCode
   * @method
   * @name CAMP#getProjectSecrets
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.code - project authentication code
   */
  CAMP.prototype.getProjectSecrets = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/secrets/{code}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers["Accept"] = ["application/json"];

    path = path.replace("{code}", parameters["code"]);

    if (parameters["code"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: code"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Sync DS column names
   * @method
   * @name CAMP#syncDS
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project ID
   */
  CAMP.prototype.syncDS = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/projects/syncds/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * List SMF Goals by project id
   * @method
   * @name CAMP#getSmf
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getSmf = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post new SMF Goal
   * @method
   * @name CAMP#postSmf
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - smf goals
   */
  CAMP.prototype.postSmf = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update SMF Goal by SMF Goal ID
   * @method
   * @name CAMP#putSmf
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - SMF Goal
   */
  CAMP.prototype.putSmf = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Remove SMF Goal by SMF Goal ID
   * @method
   * @name CAMP#deleteSmf
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - SMF Goal ID
   */
  CAMP.prototype.deleteSmf = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Remove SMF Action by SMF Action ID
   * @method
   * @name CAMP#deleteSmfAction
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - SMF Goal ID
   */
  CAMP.prototype.deleteSmfAction = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/actions/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get smf action list
   * @method
   * @name CAMP#getSmfActions
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - Customer ID
   */
  CAMP.prototype.getSmfActions = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/actions/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post new SMF Action(s)
   * @method
   * @name CAMP#postSmfAction
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - Customer ID
   * @param {} parameters.body - smf action(s)
   */
  CAMP.prototype.postSmfAction = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/actions/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update SMF Action(s)
   * @method
   * @name CAMP#putSmfAction
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - Customer ID
   * @param {} parameters.body - SMF Action
   */
  CAMP.prototype.putSmfAction = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/actions/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Remove SMF Data Group by ID
   * @method
   * @name CAMP#deleteSmfGroup
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - SMF Data Group ID
   */
  CAMP.prototype.deleteSmfGroup = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/groups/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get smf data group list
   * @method
   * @name CAMP#getSmfGroups
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - Customer ID
   */
  CAMP.prototype.getSmfGroups = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/groups/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post new SMF Group(s)
   * @method
   * @name CAMP#postSmfGroup
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - Customer ID
   * @param {} parameters.body - smf action(s)
   */
  CAMP.prototype.postSmfGroup = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/groups/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get smf goal types list
   * @method
   * @name CAMP#getSmfGoalTypes
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getSmfGoalTypes = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/smf/goaltypes";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all dynamic2 by dynamic1
   * @method
   * @name CAMP#getDynamic2ByDynamic1
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - dynamic1 id
   */
  CAMP.prototype.getDynamic2ByDynamic1 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic2/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post a new dynamic2 by dynamic1Id
   * @method
   * @name CAMP#postDynamic2s
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - dynamic1 id
   * @param {} parameters.body - dynamic2
   */
  CAMP.prototype.postDynamic2s = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic2/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update dynamic2 by dynamic1 id
   * @method
   * @name CAMP#putDynamic2
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - dynamic1 id
   * @param {} parameters.body - dynamic2
   */
  CAMP.prototype.putDynamic2 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic2/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Remove a dynamic2 by id
   * @method
   * @name CAMP#deleteDynamic2
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - dynamic2 id
   */
  CAMP.prototype.deleteDynamic2 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic2/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic2 by project id
   * @method
   * @name CAMP#getDynamic2Flat
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getDynamic2Flat = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic2/flat/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic2 meta data by project ID
   * @method
   * @name CAMP#getDynamic2Meta
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getDynamic2Meta = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic2/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update dynamic2 meta
   * @method
   * @name CAMP#putDynamic2Meta
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - dynamic2 meta
   */
  CAMP.prototype.putDynamic2Meta = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic2/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post a new line to dynamic2 by project id
   * @method
   * @name CAMP#postImportDynamic2
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic2
   */
  CAMP.prototype.postImportDynamic2 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic2/importquery/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Import datanew data from query (no clear)
   * @method
   * @name CAMP#putImportDynamic2
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic2
   */
  CAMP.prototype.putImportDynamic2 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic2/importquery/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic3 by dynamic2 id
   * @method
   * @name CAMP#getDynamic3
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - dynamic2 id
   */
  CAMP.prototype.getDynamic3 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post a new dynamic3 by dynamic2 id
   * @method
   * @name CAMP#postDynamic3
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - dynamic2 id
   * @param {} parameters.body - dynamic3
   */
  CAMP.prototype.postDynamic3 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update dynamic3 by dynamic2 id
   * @method
   * @name CAMP#putDynamic3
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - dynamic2 id
   * @param {} parameters.body - dynamic3
   */
  CAMP.prototype.putDynamic3 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Remove dynamic3 by id
   * @method
   * @name CAMP#deleteDynamic3
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - dynamic3 id
   */
  CAMP.prototype.deleteDynamic3 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic3 by project id
   * @method
   * @name CAMP#getDynamic3Flat
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getDynamic3Flat = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/flat/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic3 meta data by project ID
   * @method
   * @name CAMP#getDynamic3Meta
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getDynamic3Meta = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update dynamic3 Meta
   * @method
   * @name CAMP#putDynamic3Meta
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic3 Meta
   */
  CAMP.prototype.putDynamic3Meta = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/meta/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic3 tags by dynamic3 id
   * @method
   * @name CAMP#listDynamic3Tags
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.listDynamic3Tags = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/tags";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get dynamic3 tags by dynamic3 id
   * @method
   * @name CAMP#getDynamic3Tags
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - dynamic3 id
   */
  CAMP.prototype.getDynamic3Tags = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/tag/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post a new dynamic3 tag by dynamic3 id
   * @method
   * @name CAMP#postDynamic3Tag
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - dynamic3 id
   * @param {} parameters.body - dynamic3 tags
   */
  CAMP.prototype.postDynamic3Tag = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/tag/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Remove dynamic3 tag by id
   * @method
   * @name CAMP#deleteDynamic3Tag
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.id - dynamic3 tag id
   */
  CAMP.prototype.deleteDynamic3Tag = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/tag/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "DELETE",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Post a new line to dynamic3 by project id
   * @method
   * @name CAMP#postImportDynamic3
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic3
   */
  CAMP.prototype.postImportDynamic3 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/importquery/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Import datanew data from query (no clear)
   * @method
   * @name CAMP#putImportDynamic3
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Dynamic3
   */
  CAMP.prototype.putImportDynamic3 = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/dynamic3/importquery/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all lists by project ID
   * @method
   * @name CAMP#getLists
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getLists = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/lists/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update Lists
   * @method
   * @name CAMP#putLists
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - Lists
   */
  CAMP.prototype.putLists = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/lists/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all views by project ID
   * @method
   * @name CAMP#getViews
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   */
  CAMP.prototype.getViews = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/views/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update Views
   * @method
   * @name CAMP#putViews
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - project id
   * @param {} parameters.body - View
   */
  CAMP.prototype.putViews = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/views/{id}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    path = path.replace("{id}", parameters["id"]);

    if (parameters["id"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: id"));
      return deferred.promise;
    }

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get all available users
   * @method
   * @name CAMP#getUsers
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getUsers = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/users";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Update current logged in user
   * @method
   * @name CAMP#updateUser
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Updated user data
   */
  CAMP.prototype.updateUser = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/users";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get user by user name
   * @method
   * @name CAMP#getUserByName
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.username - The name that needs to be fetched
   */
  CAMP.prototype.getUserByName = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/users/{username}";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    path = path.replace("{username}", parameters["username"]);

    if (parameters["username"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: username"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * invite user(s) to amp
   * @method
   * @name CAMP#postUserInvite
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - invite container
   */
  CAMP.prototype.postUserInvite = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/users/invite";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "POST",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * Get User settings key/values
   * @method
   * @name CAMP#getUserSettings
   * @param {object} parameters - method options and parameters
   */
  CAMP.prototype.getUserSettings = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/users/settings/";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "GET",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };
  /**
   * edit user settings
   * @method
   * @name CAMP#putUserSettings
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - user setting container
   */
  CAMP.prototype.putUserSettings = function(parameters) {
    if (parameters === undefined) {
      parameters = {};
    }
    let deferred = Q.defer();
    let domain = this.domain,
      path = "/users/settings/";
    let body = {},
      queryParameters = {},
      headers = {},
      form = {};

    headers = this.setAuthHeaders(headers);
    headers["Accept"] = ["application/json"];
    headers["Content-Type"] = ["application/json"];

    if (parameters["body"] !== undefined) {
      body = parameters["body"];
    }

    if (parameters["body"] === undefined) {
      deferred.reject(new Error("Missing required  parameter: body"));
      return deferred.promise;
    }

    queryParameters = mergeQueryParams(parameters, queryParameters);

    this.request(
      "PUT",
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    );

    return deferred.promise;
  };

  return CAMP;
})();

export default CAMP;

const op = {
  domain: process.env.REACT_APP_API_URI,
};
export const camp = new CAMP(op);

// VERSION: develop:92cd2f6eda37c4fd19c9402f2792bbea18e4ac53
