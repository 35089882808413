import * as types from "../types/types";
import { isLinesEdited } from "../selectors/BPSelectors";
import { tempStore } from "../utils/Utils";
import { computeAllLines } from "../utils/formulas";

export const navSubPage = subPage => dispatch => {
  dispatch({
    type: types.BP_NAV_SUBPAGE_CHANGE,
    payload: {
      subPage
    }
  });
  // This is workaround for memorized selectors, if user navigates away we want to say
  // 'nothing is expanded`, so next time it loads from clean state.
  // commnted for now, seems like unnecessary any more.
  // dispatch({
  //   type: types.BP_LINE_EXPAND,
  //   payload: 0,
  // });
  // dispatch({
  //   type: types.BP_TR_EXPAND,
  //   payload: 0,
  // });
};

// deprecated
export const navPage = page => dispatch => {
  dispatch({
    type: types.BP_NAV_PAGE_CHANGE,
    payload: {
      page
    }
  });
  navSubPage("default"); // deprecated
};

export const navCustomView = customView => dispatch => {
  dispatch({
    type: types.BP_NAV_CUSTOM_VIEW,
    payload: {
      customView
    }
  });
};

export const navAction = (page, subPage, projectId) => dispatch => {
  dispatch({
    type: types.BP_NAV_CHANGE,
    payload: {
      page,
      subPage,
      projectId
    }
  });
};

export const openTactcisPopup = (open, lineId) => dispatch => {
  dispatch({
    type: types.BP_NAV_OPEN_TACTICS_POPUP,
    payload: {
      open,
      lineId
    }
  });
};

export const adPopupOpen = (upladInfoId, platformId, adTypeId) => dispatch => {
  dispatch({
    type: types.BP_AD_POPUP_OPEN,
    payload: {
      upladInfoId,
      platformId,
      adTypeId
    }
  });
};

export const adPopupClose = () => dispatch => {
  dispatch({
    type: types.BP_AD_POPUP_CLOSE
  });
};

export const resetAllBPState = () => dispatch => {
  dispatch({
    type: types.BP_RESET_ALL
  });
};

export const expandLine = lineId => dispatch => {
  dispatch({
    type: types.BP_LINE_EXPAND,
    payload: lineId
  });
};

export const openDeleteDialog = (open, lineToDelete) => dispatch => {
  dispatch({
    type: types.BP_OPEN_DELETEDIALOG,
    payload: {
      open,
      lineToDelete
    }
  });
};

export const openSaveDialog = open => dispatch => {
  dispatch({
    type: types.BP_OPEN_SAVEDIALOG,
    payload: open
  });

  // FixMe: this is hackish and should be better.
  if (!open) {
    const lineId = tempStore.getNDel("expandNextLine") || 0;
    if (lineId > 0) {
      dispatch(expandLine(lineId));
    }
  }
};

export const setBudgets = budgets => dispatch => {
  dispatch({
    type: types.BP_BUDGET,
    payload: {
      budgets
    }
  });
};

export const addTactics = tp => dispatch => {
  dispatch({
    type: types.BP_NEW_LINE_ADDED,
    payload: tp
  });
};

export const loadTactics = tacticsArray => dispatch => {
  dispatch({
    type: types.BP_LOAD_LINES,
    payload: tacticsArray
  });
};

export const loadTraffics = trafficsArray => dispatch => {
  dispatch({
    type: types.BP_TR_LINES,
    payload: trafficsArray
  });
};

export const loadTrafficLandingPages = landingArray => dispatch => {
  dispatch({
    type: types.BP_TR_LANDING_PAGE,
    payload: landingArray
  });
};

export const addTrafficLandingPages = landingArray => dispatch => {
  dispatch({
    type: types.BP_TR_LANDING_PAGE_ADD,
    payload: landingArray
  });
};

export const lineActionCLO = line => dispatch => {
  dispatch({
    type: types.BP_LINE_ACTION,
    payload: {
      subaction: types.BP_LINE_ACTION_SUB.CLO,
      line
    }
  });
};

export const lineActionCLOReset = () => dispatch => {
  dispatch({
    type: types.BP_LINE_ACTION,
    payload: {
      subaction: types.BP_LINE_ACTION_SUB.CLORESET
    }
  });
};

export const lineActionADD = line => dispatch => {
  dispatch({
    type: types.BP_LINE_ACTION,
    payload: {
      subaction: types.BP_LINE_ACTION_SUB.ADD,
      line
    }
  });
};

export const lineActionDEL = line => dispatch => {
  dispatch({
    type: types.BP_LINE_ACTION,
    payload: {
      subaction: types.BP_LINE_ACTION_SUB.DEL,
      line
    }
  });
};

export const lineActionALT = line => dispatch => {
  dispatch({
    type: types.BP_LINE_ACTION,
    payload: {
      subaction: types.BP_LINE_ACTION_SUB.ALT,
      line
    }
  });
};

export const lineActionALTMas = lines => dispatch => {
  dispatch({
    type: types.BP_LINE_ACTION,
    payload: {
      subaction: types.BP_LINE_ACTION_SUB.ALT_MAS,
      lines
    }
  });
};

export const linesActionRESET = () => dispatch => {
  dispatch({
    type: types.BP_LINE_ACTION,
    payload: {
      subaction: types.BP_LINE_ACTION_SUB.RESET
    }
  });
};

export const linesHasErrorsAdd = (lineId, errorBody) => dispatch => {
  dispatch({
    type: types.BP_LINES_HAS_ERRORS,
    payload: {
      lineId,
      errorBody,
      status: 1
    }
  });
};

export const linesHasErrorsRemove = lineId => dispatch => {
  dispatch({
    type: types.BP_LINES_HAS_ERRORS,
    payload: {
      lineId,
      errorBody: {},
      status: 0
    }
  });
};

export const setFilters = (type, filter) => dispatch => {
  dispatch({
    type: types.BP_FILTERS,
    payload: {
      type,
      filter
    }
  });
};

export const setDataBus = (level, data) => dispatch => {
  dispatch({
    type: types.BP_DATABUS,
    payload: {
      level,
      data
    }
  });
};

export const setDataBusFormatting = (level, data) => dispatch => {
  dispatch({
    type: types.BP_DATABUS_FORMATTING,
    payload: {
      level,
      data
    }
  });
};

// /// Tunks

export const tryExpand = function tryExpand(lineId) {
  return function ok(dispatch, getState) {
    if (isLinesEdited(getState())) {
      dispatch(openSaveDialog(true));
      // FixMe: this is hackish and should be better.
      tempStore.set("expandNextLine", lineId);
    } else {
      dispatch(expandLine(lineId));
    }
  };
};

export const recalculateFormulas = function recalculateFormulas(projectId) {
  return function ok(dispatch) {
    const reCalcResult = computeAllLines(projectId);
    dispatch(loadTactics(reCalcResult.data));
    Object.keys(reCalcResult.errors).forEach(key => {
      dispatch(linesHasErrorsAdd(key, reCalcResult.errors[key]));
    });
    dispatch(linesActionRESET());
    dispatch(lineActionALTMas(reCalcResult.data));
  };
};

// Thunk exmaple, for future referecne.
// ////////////////////////////////////////
// export const requestLoad = function requestLoad() {
//   return {
//     type: 'REQUEST_LOAD',
//   };
// };
// export const receiveLoad = function receiveLoad(timestamp) {
//   return {
//     type: 'RECEIVE_LOAD',
//     payload: {
//       lastTimestamp: timestamp,
//     },
//   };
// };
// Note that it's a Side Effect Function
// export const startLoad = function startLoad() {
//   return function sideEffectFunction(dispatch) {
//     dispatch(requestLoad()); // Sub action for REQUEST_LOAD
//     return loadApi().then(timestamp =>
//       // Sub action for RECEIVE_LOAD
//       dispatch(receiveLoad(timestamp)));
//   };
// };
// ////////////////////////////////////////
