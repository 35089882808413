/* eslint-disable no-underscore-dangle */

// Left for future samples
// import { createStore, combineReducers, applyMiddleware } from "redux";
// import { combineReducers } from "redux";
import thunk from "redux-thunk";

import { init } from "@rematch/core";

import notifications from "./reducers/notificationReducer";
import versionping from "./reducers/versionAndPingReducer";
import browserHistory from "./reducers/browserHistoryReducer";
import BPStore from "./reducers/BPReducer";
import MediaPlanStore from "./reducers/MediaPlanStore";
import ProjectStore from "./reducers/projectReducer";
import commonStore from "./reducers/commonDataReducer";
import busStore from "./reducers/eventBusReducer";
import userSettings from "./reducers/UserDataReducer";

import * as qnd from "./reducers/qnd";

const middlewares = [];
middlewares.push(thunk);

if (process.env.NODE_ENV === "development") {
  /* eslint-disable global-require */
  const { createLogger } = require("redux-logger");
  const logger = createLogger({ duration: true });

  middlewares.push(logger);
}

// trying out rematch, left for future samples

// const reducer = combineReducers({
//   notifications,
//   versionping,
//   browserHistory,
//   BPStore,
//   MediaPlanStore,
//   ProjectStore,
//   commonStore,
//   busStore,
//   userSettings,
// });

// trying out rematch, left for future samples
// const reduxStore2 = createStore(
//   reducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//   applyMiddleware(...middlewares)
// );

// export default reduxStore2;

const reduxStore = init({
  models: qnd,
  redux: {
    reducers: {
      notifications,
      versionping,
      browserHistory,
      BPStore,
      MediaPlanStore,
      ProjectStore,
      commonStore,
      busStore,
      userSettings,
    },
    middlewares: [...middlewares],
  },
});

export default reduxStore;

// window.store = store;

window.reduxStore = reduxStore;
