import React from "react";
import RegistrationOrgAndUser from "./RegistrationOrgAndUser";
import CompanyAndUser from "../../shared/companyAndUser/CompanyAndUser";

import "./Registrations.scss";

const RegisterPage = () => (
  <div className="RegisterPage grid-container">
    <div className="grid-x u-padding-both-sides-25 u-padding-top-10">
      <div className="cell small-12">
        <div className="grid-x grid-padding-x align-center-middle center-center">
          <div className="cell small-12">
            <div className="grid-x">
              <div className="grid-x projects-header">
                <div className="cell small-10">
                  <h6>
                    Fastloop. <span className="u-gray">Users</span>
                  </h6>
                </div>
                <div className="cell small-2">
                  <CompanyAndUser />
                </div>
              </div>

              <div className="cell small-12">
                <RegistrationOrgAndUser />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RegisterPage;
