class ViewSettings {
  constructor(rawJson) {
    if (rawJson) {
      try {
        this.settings = JSON.parse(rawJson);
      } catch (error) {
        this.reset();
      }
    } else {
      this.reset();
    }
  }
  reset() {
    this.settings = {
      version: 1,
      filter: []
      // will be expanded more in the future.
    };
  }
  set(key, value) {
    this.settings[key] = value;
  }
  get(key) {
    if (!this.settings) {
      this.reset();
    }
    return this.settings[key] || {};
  }
  stringify() {
    return JSON.stringify(this.settings);
  }
}

export { ViewSettings };

class ColumnSettings {
  constructor(rawJson) {
    if (rawJson) {
      try {
        this.settings = JSON.parse(rawJson);
      } catch (error) {
        this.reset();
      }
    } else {
      this.reset();
    }
  }
  reset() {
    this.settings = {
      v: 1,
      rules: {},
      rules_meta: {}
    };
  }
  set(key, value) {
    this.settings[key] = value;
  }
  get(key) {
    if (!this.settings) {
      this.reset();
    }
    return { ...this.settings[key] } || {};
  }
  stringify() {
    return JSON.stringify(this.settings);
  }
}

export { ColumnSettings };
