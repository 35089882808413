import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-day-picker/lib/style.css";
import moment from "moment";
// import { parseAndFormatDate } from "../../../../utils/DateUtils";
import ProjectsService, { milestonesHelper } from "../../../ProjectsService";
import "./ProjectMilestonesSettings.scss";
import MilestonSetingsNewLine from "./MilestonSetingsNewLine";
import ProjectMilestonesSettingsLine from "./ProjectMilestonesSettingsLine";

class ProjectMilestonesSettings extends Component {
  constructor(props) {
    super(props);
    this.projectServie = new ProjectsService();
    this.state = {
      milestones: [],
      next: {},
      upcoming: [],
      completed: [],
      isApiLoaded: false,
      checked: [],
      edited: [],
      addNewLine: false,
      newMilestones: [],
      isAddNewLoading: false,
    };
  }

  componentDidMount() {
    this.projectServie.getMilestones((milestones) => {
      const allStones = milestones.filter((stone) => stone.required === false);
      this.setState({
        newMilestones: allStones,
        isAddNewLoading: false,
      });
    });
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.project || Array.isArray(nextProps.project.milestones)) {
  //     const editable = nextProps.project.milestones.map((item) => ({
  //       ...item,
  //       isEditMode: false,
  //     }));
  //     this.setState(
  //       {
  //         ...this.state,
  //         isApiLoaded: true,
  //         milestones: editable,
  //       },
  //       () => {
  //         this.constructStones();
  //       }
  //     );
  //   }
  // }

  onNewSave = (stone) => {
    this.setState({ isAddNewLoading: true });
    this.projectServie.addProjectMilestone(
      this.props.project.id,
      stone,
      () => {
        this.props.onSave();
        this.setState({ isAddNewLoading: false, addNewLine: false });
        this.componentDidMount();
      },
      () => {
        this.setState({ isAddNewLoading: false });
        this.props.onFail();
      }
    );
  };

  onNewCancel() {
    this.setState({ addNewLine: false });
  }

  onSaveLn = (stone) => {
    this.setState({ showLoading: true });
    this.projectServie.updateProjectMilestone(
      this.props.project.id,
      stone,
      () => {
        this.props.onSave();
        this.setState({ showLoading: false });
      },
      () => {
        this.setState({ showLoading: false });
        this.props.onFail();
      }
    );
  };

  updateStone = (objInput) => {
    this.setState({ showLoading: true });
    const stone = {
      milestoneEntityId: objInput.id,
      date: objInput.date,
      completed: objInput.completed,
    };
    this.projectServie.updateProjectMilestone(
      this.props.project.id,
      stone,
      () => {
        this.props.onSave();
        this.setState({ showLoading: false });
      },
      () => {
        this.setState({ showLoading: false });
        this.props.onFail();
      }
    );
  };

  checkIncompleted = (objInput) => {
    const clone = Object.assign({}, objInput);
    clone.completed = false;
    this.updateStone(clone);
  };

  addMoreMilestones() {
    this.setState({ addNewLine: true });
  }

  constructStones() {
    const milestonestmp = milestonesHelper(this.state.milestones);
    const milestones = milestonestmp.slice();
    const completed = milestones.filter((stone) => stone.completed === true);
    const upcoming = milestones.filter((stone) => stone.completed === false);
    let next;
    if (upcoming.length > 0) {
      next = upcoming.shift();
    }
    this.setState({
      ...this.state,
      isApiLoaded: true,
      milestones: milestonestmp,
      next,
      completed,
      upcoming,
    });
  }

  render() {
    // if (!this.state.isApiLoaded) {
    //   return <div>....</div>;
    // }
    return (
      <div className="ProjectMilestonesSettings">
        <div
          className={`ld-over ${
            this.state.showLoading ? "running" : "not-running"
          }`}
        >
          <span className="ld ld-ring ld-spin" />
          <div>
            <h6>Dates</h6>
          </div>
          <div className="next">
            <div className="grid-x sub-title">
              <div className="cell small-12">
                <span className="u-gray">Next</span>
              </div>
            </div>
            {this.state.next ? (
              <ProjectMilestonesSettingsLine
                stone={this.state.next}
                onSave={(stone) => this.onSaveLn(stone)}
                showLoading={false}
              />
            ) : (
              <div />
            )}
          </div>
          <div className="comming-up">
            <div className="grid-x sub-title">
              <div className="cell small-12">
                <span className="u-gray">Coming Up</span>
              </div>
            </div>
            {this.state.upcoming.map((obj, ord) => (
              <ProjectMilestonesSettingsLine
                key={obj.uniqId}
                stone={obj}
                onSave={(stone) => this.onSaveLn(stone)}
                showLoading={false}
                data-ft-order={ord}
              />
            ))}
            <div className="grid-x sub-title">
              <div
                className={`cell small-12 ${
                  this.state.addNewLine ? "show" : "hide"
                }`}
              >
                <MilestonSetingsNewLine
                  milestones={this.state.newMilestones}
                  saveCallback={(data) => this.onNewSave(data)}
                  cancelCallback={() => this.onNewCancel()}
                  showLoading={this.state.isAddNewLoading}
                />
              </div>
            </div>
            <div className="grid-x sub-title">
              <div
                className={`cell small-8 ${
                  this.state.addNewLine ? "hide" : "show"
                }`}
              >
                <div className="add-new">
                  <button onClick={() => this.addMoreMilestones()}>
                    Add another date
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="completed">
            <div className="grid-x sub-title">
              <div className="cell small-12">
                <span className="u-gray">Completed</span>
              </div>
            </div>
            {this.state.completed.map((obj, ord) => (
              <div className="grid-x" key={obj.uniqId} data-ft-order={ord}>
                <div className="cell small-12 u-gray">
                  <input
                    type="checkbox"
                    checked
                    onChange={() => this.checkIncompleted(obj)}
                  />
                  {obj.milestoneParsed} ({moment(obj.date).toString()})
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectMilestonesSettings;

ProjectMilestonesSettings.propTypes = {
  project: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};
