import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Scrollbar from 'react-scrollbars-custom';
import RegistrationOrgAndUser from '../pages/registrations/RegistrationOrgAndUser';

const RegisterDialog = ({ type, open, handleClose }) => (
  <div>
    <Dialog open={open} onClose={handleClose} disableEscapeKeyDown disableBackdropClick>
      <DialogTitle>
        <div className="grid-x align-justify">
          <div className="cell small-6">Registration</div>
          <div className="cell small-6 text-right">
            <Button onClick={handleClose} color="primary" autoFocus>
                Close
            </Button>
          </div>
        </div>
      </DialogTitle>
      <Scrollbar
        permanentTracks
        style={{
          width: '600px',
          minHeight: '800px',
        }}
      >
        <DialogContent>
          {type === 'orgNUser' ? <RegistrationOrgAndUser /> : <RegistrationOrgAndUser noUser />}
        </DialogContent>
      </Scrollbar>
    </Dialog>
  </div>
);

export default RegisterDialog;

RegisterDialog.propTypes = {
  type: PropTypes.oneOf(['orgNUser', 'org']).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
