import { createSelector } from "reselect";
import { BPVIEWS } from "../shared/Constants";
import { add } from "../utils/CurrencyUtils";

export const getNavigations = state => state.BPStore.nav;

export const getCustomViewSel = state => state.BPStore.nav.customView;

export const getBudgets = state => state.BPStore.budgets;

export const getTraffics = state => state.BPStore.trafficLines;
export const getTactics = state => state.BPStore.lines;
export const getBlockingLines = state => state.BPStore.lines;
export const getTactic = (state, id) => ({ ...state.BPStore.lines[id] });
export const getExpanded = state => state.BPStore.linesExpanded;
export const getTrafficExpanded = state => state.BPStore.trafficExpanded;
export const getOpenSaveDialog = state => state.BPStore.nav.saveDialog;
export const getOpenDeleteDialog = state => state.BPStore.nav.deleteDialog;

export const shouldRestAddedEditedLines = state =>
  !state.BPStore.linesChanged.dirty;
export const isLinesEdited = state => state.BPStore.linesChanged.dirty;

export const linesHasErrors = state =>
  Object.keys(state.BPStore.linesHasErrors).length > 0;
export const getLinesErrors = state => state.BPStore.linesHasErrors;
export const getLineErrors = (state, lineId) =>
  state.BPStore.linesHasErrors[lineId];

// Todo: rename to Sel
export const getBudget = createSelector([getBudgets], budgets => budgets);

export const getExpandedSel = createSelector([getExpanded], num => num);

export const getShowTraffic = createSelector([getTrafficExpanded], num => num);

export const getBlockingLinesByParentLvl1 = (state, projectId) => {
  const allLines = state.BPStore.lines;
  const retVal = [];
  Object.values(allLines).forEach(line => {
    if (
      line.parentId !== null &&
      Number(line.projectId) === Number(projectId)
    ) {
      retVal.push(line);
    }
  });
  return retVal;
};

export const getBPPlatformByUploadInfo = (state, uploadInfoId) => {
  const bplineId = state.BPStore.trafficLines[uploadInfoId].blockingPlanLineId;
  return state.BPStore.lines[bplineId].platformId;
};

export const getPlatformFromBlockingLine = (state, blockingPlanLineId) =>
  state.BPStore.lines[blockingPlanLineId].platformId;

export const getPlatformFromBlockingLineSel = createSelector(
  [getPlatformFromBlockingLine],
  line => line
);

export const getBPPlatformByUploadInfoSel = createSelector(
  [getBPPlatformByUploadInfo],
  line => line
);

export const getTacticsSel = createSelector([getTactics], lines => {
  const retVal = {};
  Object.keys(lines).forEach(key => {
    if (!lines[key].parentId) {
      retVal[key] = lines[key];
    }
  });

  return retVal;
});

export const getTacticsGrossMediaFromChildren = createSelector(
  [getTactics],
  lines => {
    const retVal = {};
    Object.keys(lines).forEach(key => {
      const line = lines[key];
      if (line.parentId) {
        const initVal = retVal[line.parentId] || 0.0;
        const lineVal = line[BPVIEWS.KNOWN_CONST.GROSS_MEDIA_BUDGET] || 0;
        retVal[line.parentId] = add(initVal, lineVal);
      }
    });
    return retVal;
  }
);

export const getTacticsBudgetFromChildren = createSelector(
  [getTactics],
  lines => {
    const retVal = {};
    Object.keys(lines).forEach(key => {
      const line = lines[key];
      if (!line.parentId) {
        retVal[line.id] = line[BPVIEWS.KNOWN_CONST.TACTIC_BUDGET];
      }
    });
    return retVal;
  }
);

// TODO: remove getTactis
export const getLineById = (state, id) => ({ ...state.BPStore.lines[id] });

export const getChildrenByParentId = (state, parentId) => {
  const children = {};
  Object.keys(state.BPStore.lines).forEach(lineId => {
    if (state.BPStore.lines[lineId].parentId === parentId) {
      children[lineId] = state.BPStore.lines[lineId];
    }
  });
  return children;
};

export const getChangedLines = (state, type) => {
  const retVal = [];
  const addedIds = state.BPStore.linesChanged[type];
  const allLines = state.BPStore.linesChanged.all;
  addedIds.forEach(id => {
    retVal.push(allLines[id]);
  });
  return retVal;
};

export const getFiltersSel = state => {
  return state.BPStore.filters;
};

export const getDataBusSel = (state, level) => {
  return state.BPStore.dataBus[level];
};

export const getDataBusFormattingSel = (state, level) => {
  return state.BPStore.dataBusFormatting[level];
};

export const getLines = state => state.BPStore.lines;
export const getParentId = (state, props) => props.parentId;
// when creating factory fn its important to have two different selectors:
// one for props other for objects from strore.
export const makeGetLinesByParentId = () =>
  createSelector(getParentId, getLines, (parentId, lines) => {
    const retVal = {};
    Object.keys(lines).forEach(key => {
      if (lines[key].parentId === parentId) {
        retVal[key] = lines[key];
      }
    });
    return retVal;
  });

export const getClonedLine = state => {
  const id = state.BPStore.linesChanged.cloneId;
  if (id === 0) {
    return {};
  }
  return { ...state.BPStore.linesChanged.all[id] };
};

export const getBlockingPlanId = (state, props) => props.blockingPlanLineId;
export const makeGetTrafficLinesByParentId = () =>
  createSelector(getBlockingPlanId, getTraffics, (blockingPlanId, lines) => {
    const retVal = {};
    Object.keys(lines).forEach(key => {
      if (lines[key].blockingPlanLineId === blockingPlanId) {
        retVal[key] = lines[key];
      }
    });
    return retVal;
  });

export const getActiveProjectSel = createSelector(
  [getNavigations],
  nav => nav.projectId
);

export const getTacticSel = createSelector([getTactic], line => line);

export const makeGetSingleTactic = () =>
  createSelector([getTactic], line => line);
