/**
 * Gets attrs passed from parant staring 'data-ft'
 * @param {object} props from parent
 */
const getDataFTAttrs = (props) => {
  const rest = {};
  Object.keys(props).forEach((key) => {
    if (key.startsWith('data-ft')) {
      rest[key] = props[key];
    }
  });
  return rest;
};

/* eslint-disable */
export { getDataFTAttrs };
