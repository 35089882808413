/** Lost/Found utils */

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return (...argumentsx) => {
    const context = this;
    const args = argumentsx;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const debounce = (func, delay) => {
  let inDebounce;
  return (...argumentsx) => {
    const context = this;
    const args = argumentsx;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

/** Temp Storage, for things redux store would be overkill */

/** Since this file referenced somewhere it automatically executes all code
 * so following {} works.
 */
window.tempStore = {};
export const tempStore = {
  set: (name, val) => {
    window.tempStore[name] = val;
  },
  get: (name) => window.tempStore[name],
  getNDel: (name) => {
    const val = window.tempStore[name];
    delete window.tempStore[name];
    return val;
  },
  del: (name) => {
    delete window.tempStore[name];
  },
};

// Injects css use with caution.
// str = '.some-class { color: red }'
export const addStyleString = (str, id) => {
  const node = document.createElement("style");
  node.innerHTML = str;
  node.id = `vn-${id}` || "";
  const addedAlready = document.getElementById(node.id);
  if (addedAlready) {
    addedAlready.innerHTML = str;
  } else {
    document.body.appendChild(node);
  }
};

export const replaceAll = (str, find, replace) =>
  str.replace(new RegExp(find, "g"), replace);

/* eslint-disable */
/**
 *
 * @param {string} str to check
 * @return {bool} returns true if all brackets are matching ()[]{}
 */
export const checkBrackets = (str) => {
  let s;
  str = str.replace(/[^{}[\]()]/g, "");
  while (s !== str) {
    s = str;
    str = str.replace(/{}|\[]|\(\)/g, "");
  }
  return !str;
};
window.checkBrackets = checkBrackets;
// checkBrackets( 'ab)cd(efg' );        // false
// checkBrackets( '((a)[{{b}}]c)' );    // true
// checkBrackets( 'ab[cd]efg' );        // true
// checkBrackets( 'a(b[c)d]e' );        // false

export const exponentialBackoff = (
  toTryFn,
  max,
  delay,
  callback,
  name = "",
  extraComment = ""
) => {
  console.log(
    `[exponentialBackoff:${name}] max: ${max}, delay: ${delay}; ${extraComment}`
  );
  const result = toTryFn();

  if (result) {
    callback(result);
  } else {
    if (max > 0) {
      setTimeout(() => {
        exponentialBackoff(toTryFn, --max, delay * 2, callback);
      }, delay);
    } else {
      console.log("we give up");
    }
  }
};

export const sortObjKeysAlphabetically = (obj) => {
  return Object.keys(obj)
    .sort((a, b) => a > b)
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
};

export const searchTree = (
  element,
  childrenPropName,
  comparePropName,
  compareValue
) => {
  if (element[comparePropName] == compareValue) {
    return element;
  } else if (element[childrenPropName] != null) {
    var i;
    var result = null;
    for (i = 0; result == null && i < element[childrenPropName].length; i++) {
      result = searchTree(
        element[childrenPropName][i],
        childrenPropName,
        comparePropName,
        compareValue
      );
    }
    return result;
  }
  return null;
};
