import React from 'react';
import PropTypes from 'prop-types';
import Red from './Red';
import './Registrations.scss';

class RegistrationNotes extends React.Component {
  constructor(props) {
    super(props);
    this.checked = new Set();
    this.state = {
      hasError: false,
      err: {},
      data: {
        note: '',
        'platform.dv360': false,
        'platform.search360': false,
        'platform.campaignManager': false,
        'current.dv360': '',
        'current.search360': '',
        'current.campaignManager': '',
        'forecast.dv360': '',
        'forecast.search360': '',
        'forecast.campaignManager': '',
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.goCheck) {
      this.checkErrors();
    }
  }

  onBlur = () => {
    this.checkErrors();
  };

  handleChange(event) {
    const {
      name, value, type, checked,
    } = event.target;

    const dataClone = { ...this.state.data };
    const val = type === 'checkbox' ? checked : value;
    if (type === 'checkbox') {
      if (checked) {
        this.checked.add(name);
      } else {
        this.checked.remove(name);
      }
    }
    dataClone[name] = val;
    this.setState({ data: dataClone }, () => {
      this.checkErrors();
    });
  }

  checkErrors = () => {
    const errClone = { ...this.state.err };
    let hasError = false;

    errClone['forecast.search360'] = false;
    errClone['forecast.campaignManager'] = false;
    errClone['forecast.dv360'] = false;

    if (
      this.state.data['forecast.dv360'].trim().length === 0
      && this.state.data['platform.dv360']
    ) {
      errClone['forecast.dv360'] = true;
      hasError = true;
    }
    if (
      this.state.data['forecast.search360'].trim().length === 0
      && this.state.data['platform.search360']
    ) {
      errClone['forecast.search360'] = true;
      hasError = true;
    }
    if (
      this.state.data['forecast.campaignManager'].trim().length === 0
      && this.state.data['platform.campaignManager']
    ) {
      errClone['forecast.campaignManager'] = true;
      hasError = true;
    }

    this.setState({ hasError: this.checked.size === 0 || hasError, err: errClone }, () => {
      this.fullNote = this.fullNoteGen();
      this.props.onBlurCB({ hasError: this.state.hasError, fullNote: this.fullNote });
    });
  };

  fullNoteGen() {
    const fullNote1 = `DV360: ${this.state.data['platform.dv360']}, Current: ${this.state.data[
      'current.dv360'
    ] || 'n/a'}, Forecast: ${this.state.data['forecast.dv360'] || 'n/a'} \n `;
    const fullNote2 = `Search360: ${this.state.data['platform.search360']}, Current: ${this.state
      .data['current.search360'] || 'n/a'}, Forecast: ${this.state.data['forecast.search360']
      || 'n/a'} \n `;
    const fullNote3 = `CampaignManager: ${
      this.state.data['platform.campaignManager']
    }, Current: ${this.state.data['current.campaignManager'] || 'n/a'}, Forecast: ${this.state.data[
      'forecast.campaignManager'
    ] || 'n/a'} \n `;
    const fullNote4 = `${this.state.data.note} \n`;
    const full = fullNote1 + fullNote2 + fullNote3 + fullNote4;
    return full;
  }

  render() {
    return (
      <div className="RegistrationNotes">
        <div className="block" data-invalidblock={this.state.hasError}>
          <div className="grid-x grid-padding-x">
            <div className="cell small-12">
              <h6>Additional Information</h6>
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="cell small-12">
              <label>
                Please indicate below what your intended and/or current budget is in the platforms
                specified below?
              </label>
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="cell small-3">
              <br />
              <input
                type="checkbox"
                id="platform.dv360"
                name="platform.dv360"
                checked={this.state.data['platform.dv360']}
                onChange={e => this.handleChange(e)}
                onBlur={e => this.onBlur(e)}
              />
              <label htmlFor="platform.dv360">DV360</label>
              <br />
              <input
                type="checkbox"
                id="platform.search360"
                name="platform.search360"
                checked={this.state.data['platform.search360']}
                onChange={e => this.handleChange(e)}
                onBlur={e => this.onBlur(e)}
              />
              <label htmlFor="platform.search360">Search360</label>
              <br />
              <input
                type="checkbox"
                id="platform.campaignManager"
                name="platform.campaignManager"
                checked={this.state.data['platform.campaignManager']}
                onChange={e => this.handleChange(e)}
                onBlur={e => this.onBlur(e)}
              />
              <label htmlFor="platform.campaignManager">Campaign Manager</label>
            </div>
            <div className="cell small-3">
              <label>Current monthly spend</label>
              <input
                type="text"
                id="current.dv360"
                name="current.dv360"
                value={this.state.data['current.dv360']}
                onChange={e => this.handleChange(e)}
                onBlur={e => this.onBlur(e)}
              />
              <input
                type="text"
                id="current.search360"
                name="current.search360"
                value={this.state.data['current.search360']}
                onChange={e => this.handleChange(e)}
                onBlur={e => this.onBlur(e)}
              />
              <input
                type="text"
                id="current.search360"
                name="current.campaignManager"
                value={this.state.data['current.campaignManager']}
                onChange={e => this.handleChange(e)}
                onBlur={e => this.onBlur(e)}
              />
            </div>
            <div className="cell small-6">
              <label>
                Estimated future monthly spend
                <Red />
              </label>
              <input
                data-invalid={this.state.err['forecast.dv360']}
                type="text"
                id="forecast.dv360"
                name="forecast.dv360"
                value={this.state.data['forecast.dv360']}
                onChange={e => this.handleChange(e)}
                onBlur={e => this.onBlur(e)}
              />
              <input
                data-invalid={this.state.err['forecast.search360']}
                type="text"
                id="forecast.search360"
                name="forecast.search360"
                value={this.state.data['forecast.search360']}
                onChange={e => this.handleChange(e)}
                onBlur={e => this.onBlur(e)}
              />
              <input
                data-invalid={this.state.err['forecast.campaignManager']}
                type="text"
                id="forecast.campaignManager"
                name="forecast.campaignManager"
                value={this.state.data['forecast.campaignManager']}
                onChange={e => this.handleChange(e)}
                onBlur={e => this.onBlur(e)}
              />
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="cell small-12">
              <span className="error-msg">
                {this.state.hasError ? (
                  <span>
                    Please select at least one platform and enter estimated future monthly spend.
                  </span>
                ) : null}
              </span>
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="cell small-12">
              <label>Notes</label>
              <textarea
                name="note"
                value={this.state.data.note}
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegistrationNotes;

RegistrationNotes.propTypes = {
  onBlurCB: PropTypes.func.isRequired,
  goCheck: PropTypes.bool.isRequired,
};
