import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import AntSelect from 'antd/lib/select';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import './Fasthooks.scss';

const NO_VARS = ['mapped', 'exists', '!exists'];

const isMapped = cellInfo => cellInfo.original.condition === 'mapped';

class FasthooksConditions extends React.Component {
  constructor(props) {
    super(props);
    this.renderDropDown = this.renderDropDown.bind(this);
  }
  renderDelete(cellInfo) {
    const id = cellInfo.original.id;
    return (
      <div className="custom-drop-down">
        <Button
          type="danger"
          disabled={isMapped(cellInfo)}
          onClick={() => this.deleteRow(id)}
        >
          <Icon type="delete" />
        </Button>
      </div>
    );
  }
  renderDropDown(type, cellInfo) {
    let defaultVal = cellInfo => {
      return cellInfo.original ? cellInfo.original[type] : '';
    };
    if (type === 'value' && NO_VARS.includes(cellInfo.original.condition)) {
      return (
        <div className="custom-drop-down">
          <AntSelect disabled className="custom-drop-down-w100p" />{' '}
        </div>
      );
    }
    return (
      <div className="custom-drop-down">
        <AntSelect
          disabled={isMapped(cellInfo)}
          showSearch
          className="custom-drop-down-w100p"
          onChange={val => this.onChangeVal(val, type, cellInfo)}
          defaultValue={defaultVal(cellInfo)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <AntSelect.Option value={''}>Empty</AntSelect.Option>
          {this.props.optionsDropDown}
        </AntSelect>
      </div>
    );
  }
  renderDropDownCondition = cellInfo => {
    let defaultVal = cellInfo => {
      return cellInfo.original ? cellInfo.original.condition : 'exists';
    };
    return (
      <div className="custom-drop-down">
        <AntSelect
          disabled={isMapped(cellInfo)}
          showSearch
          className="custom-drop-down-w100p"
          onChange={val => this.onChangeVal(val, 'condition', cellInfo)}
          defaultValue={defaultVal(cellInfo)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <AntSelect.Option value={''}>Empty</AntSelect.Option>
          <AntSelect.Option value={'equal'}>equal</AntSelect.Option>
          <AntSelect.Option value={'!equal'}>not equal</AntSelect.Option>
          <AntSelect.Option value={'exists'}>exists</AntSelect.Option>
          <AntSelect.Option value={'!exists'}>not exists</AntSelect.Option>
          <AntSelect.Option value={'lesser'}>lesser</AntSelect.Option>
          <AntSelect.Option value={'!lesser'}>not lesser</AntSelect.Option>
          <AntSelect.Option value={'greater'}>greater</AntSelect.Option>
          <AntSelect.Option value={'!greater'}>not greater</AntSelect.Option>
        </AntSelect>
      </div>
    );
  };
  onChangeVal = (val, type, cellInfo) => {
    let data = { ...cellInfo.original, [type]: val };
    this.props.onValuesCB({ key: 'condition', data });
  };
  addNewRow = () => {
    this.props.onValuesCB({ key: 'condition', action: 'new', data: {} });
  };
  deleteRow = id => {
    this.props.onValuesCB({ key: 'condition', action: 'delete', data: { id } });
  };
  render() {
    let data = [];
    if (Array.isArray(this.props.hook.conditions)) {
      data = this.props.hook.conditions.filter(d => !d.delete);
    }
    return (
      <div className="FasthooksConditions">
        <ReactTable
          noDataText="Select an Event."
          data={data}
          columns={[
            {
              Header: 'Variable',
              id: 'variable',
              accessor: 'variable',
              Cell: row => this.renderDropDown('variable', row),
              Footer: () => <div>fake, has to be added for footer to work</div>,
              width: '40%',
            },
            {
              Header: 'Condition',
              id: 'condition',
              accessor: 'condition',
              Cell: row => this.renderDropDownCondition(row),
              width: '15%',
            },
            {
              Header: 'Value',
              id: 'value',
              accessor: 'value',
              Cell: row => this.renderDropDown('value', row),
              width: '40%',
            },
            {
              Header: 'Delete',
              id: 'delete',
              accessor: 'id',
              Cell: row => this.renderDelete(row),
              width: '5%',
            },
          ]}
          TfootComponent={() => {
            return (
              <div className="condition-new-row">
                <Button onClick={() => this.addNewRow()}>Add New</Button>
              </div>
            );
          }}
          defaultPageSize={15}
          className="-striped"
        />
      </div>
    );
  }
}

export default FasthooksConditions;

FasthooksConditions.propTypes = {
  hook: PropTypes.object.isRequired,
  onValuesCB: PropTypes.func.isRequired,
  optionsDropDown: PropTypes.array.isRequired,
};
