import math from 'mathjs';
import SSF from 'ssf';
import { NUMBER_SFF_FORMATS, ERRORS } from '../shared/Constants';
import { errorLog } from './ErrorTracker';

window.lemath = math;
window.SSF = SSF;

/** Formats currency, if nothing provided uses 0 */
export const formatCurrency = (number) => {
  const casted = parseFloat(number || 0);
  return SSF.format(NUMBER_SFF_FORMATS.BIG_NUM, casted);
};

export const formatNumberBySSF = (number, format) => {
  let retVal = number;
  if (number === null) return '';
  try {
    const casted = parseFloat(number || 0);
    retVal = SSF.format(format, casted);
  } catch (error) {
    errorLog(error, ERRORS.TYPES.OTHER, `received format: ${format}`);
  }
  return retVal;
};

export const sumObjectVals = (obj) => {
  if (obj) {
    const vals = Object.values(obj);
    // safe check, sometimes objects comes as null
    const filtered = vals.filter((itm) => {
      if (itm) return itm;
      return 0;
    });
    return math.sum(Object.values(filtered));
  }
  return 0;
};

export const subtract = (x, y) => math.subtract(x, y);

export const add = (x, y) => math.add(x, y);

export const isEqual = (x, y) => math.equal(x, y);
