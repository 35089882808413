import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import './CustomViews.scss';

const CustomViewSelector = ({ selected, onSelect, list }) => (
  <div className="CustomViewSelector">
    <div className="flex-container">
      <Select
        name="milestone"
        value={selected}
        options={list}
        onChange={val => {
          onSelect(val);
        }}
        placeholder="Select one..."
      />
    </div>
  </div>
);

export default CustomViewSelector;

CustomViewSelector.defaultProps = {
  selected: {},
};

CustomViewSelector.propTypes = {
  selected: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
};
