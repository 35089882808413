import React from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import FasthooksMapsCallbacks from './FasthooksMapsCallbacks';

const { Option } = Select;
const { TextArea } = Input;

class FasthookCallbackPanel extends React.Component {
  onTabChange = e => {
    console.log('[FasthookCallbackPanel.jsx] e', e);
  };
  handelInput(event) {
    const { name, value } = event.target;
    this.handelInput2(name, value);
  }
  handelInput2(name, value) {
    const callback = { ...this.props.callback };
    callback[name] = value;
    callback._notSet = false;
    this.props.onValuesCB({ key: 'callback', data: callback });
  }
  render() {
    const isSync = this.props.hook ? this.props.hook.synchronous : true;
    // console.log('[FasthookCallbackPanel.jsx] callback', this.props.callback);
    const vals = this.props.callback;

    return (
      <div className="FasthookCallbackPanel">
        <div>
          <FasthooksMapsCallbacks
            viewCols={this.props.viewCols}
            event={this.props.event}
            hook={this.props.hook}
            onValuesCB={this.props.onValuesCB}
            callback={this.props.callback}
            optionsDropDown={this.props.optionsDropDown}
            callbackKey="callbackmap"
          />
        </div>

        <Form layout={'vertical'}>
          <div className="grid-x">
            <div className="cell small-12">
              <div className="grid-x">
                <div className="cell small-4 sep">
                  <Form.Item label="Name">
                    <Input
                      placeholder="Name"
                      defaultValue={vals.name || ''}
                      name="name"
                      onChange={e => this.handelInput(e)}
                    />
                  </Form.Item>
                </div>
                <div className="cell small-2 sep">
                  <Form.Item label="Method">
                    <Select
                      defaultValue={vals.method || 'GET'}
                      onChange={e => this.handelInput2('method', e)}
                      disabled={isSync}
                    >
                      <Option value="GET">GET</Option>
                      <Option value="POST">POST</Option>
                      <Option value="DELETE">DELETE</Option>
                      <Option value="PUT">PUT</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="cell small-6">
                  <Form.Item label="URI">
                    <Input
                      disabled={isSync}
                      placeholder="URI"
                      name="uri"
                      defaultValue={vals.uri || ''}
                      onChange={e => this.handelInput(e)}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="grid-x">
                <div className="cell small-4 sep">
                  <Form.Item label="Headers">
                    <TextArea
                      disabled={isSync}
                      onChange={e => this.handelInput(e)}
                      placeholder="Headers"
                      name="header"
                      autosize={{ minRows: 5, maxRows: 10 }}
                      defaultValue={vals.header || ''}
                    />
                  </Form.Item>
                </div>
                <div className="cell small-8">
                  <Form.Item label="Body">
                    <TextArea
                      defaultValue={vals.body}
                      onChange={e => this.handelInput(e)}
                      placeholder="Body"
                      name="body"
                      autosize={{ minRows: 5, maxRows: 10 }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default FasthookCallbackPanel;

FasthookCallbackPanel.propTypes = {
  event: PropTypes.object.isRequired,
  hook: PropTypes.object.isRequired,
  onValuesCB: PropTypes.func.isRequired,
  viewCols: PropTypes.object.isRequired,
  callback: PropTypes.object.isRequired,
  optionsDropDown: PropTypes.array.isRequired,
};
