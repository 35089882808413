import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Notifications from "../../../shared/notifications/Notifications";
import "./ProjectActivityMainView.scss";
import ProjectsService from "../../ProjectsService";
import {
  getParamAfterSlug,
  getQueryStringParam,
} from "../../../utils/URLUtils";
import ProjectActivitySide from "../projectActivity/ProjectActivitySide";

class ProjectActivityMainView extends Component {
  constructor(props) {
    super(props);
    this.projectsService = new ProjectsService();
    this.state = {
      section: "",
      project: {},
      projectId: 0,
      notes: [],
      files: [],
      isApiLoading: true,
      forbidden: false,
    };
  }

  componentDidMount() {
    this.projectId = getParamAfterSlug("projects");
    const section = getQueryStringParam("section") || "general";
    this.projectsService.getProject(
      this.projectId,
      (project) => {
        this.projectCopy = project;
        this.setState(
          {
            ...this.state,
            project,
            section,
            projectId: this.projectId,
            notes: project.notes,
            files: project.files,
          },
          () => {
            this.onClickHandler(null, section);
          }
        );
      },
      (err) => {
        if (err.response.status === 403) {
          this.setState({ forbidden: true });
        }
      }
    );
  }

  onClickHandler(e, target) {
    let { notes, files } = this.state.project;
    if (target === "chatter") {
      files = [];
    }
    if (target === "uploads") {
      notes = [];
    }
    this.setState({
      ...this.state,
      section: target,
      notes,
      files,
    });
  }

  reloadProject() {
    this.componentDidMount();
  }

  render() {
    if (this.state.forbidden) {
      return (
        <div className="BPPage">
          <div>
            <div className="u-padding-top-10 u-padding-both-sides-25">
              Sorry, seems you have no access to this page.
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="ProjectActivityMainView">
        <div className="filter-menu">
          <div className="u-padding-both-sides-25">
            <ul className="menu">
              <li>
                <Link
                  className={this.state.section === "general" ? "active" : ""}
                  onClick={(event) => this.onClickHandler(event, "general")}
                  to={`${this.props.match.url}`}
                >
                  General
                </Link>
              </li>
              <li>
                <Link
                  className={this.state.section === "chatter" ? "active" : ""}
                  onClick={(event) => this.onClickHandler(event, "chatter")}
                  to={`${this.props.match.url}?section=chatter`}
                >
                  Chatter
                </Link>
              </li>
              <li>
                <Link
                  className={this.state.section === "uploads" ? "active" : ""}
                  onClick={(event) => this.onClickHandler(event, "uploads")}
                  to={`${this.props.match.url}?section=uploads`}
                >
                  Uploads
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="u-padding-both-sides-25 acitivty-row">
          <div className="u-padding-top-10">
            <Notifications />
          </div>
          <div className="grid-x">
            <div className="cell small-12 medium-6">
              <ProjectActivitySide
                notes={this.state.notes}
                files={this.state.files}
                projectId={parseInt(this.state.projectId, 10)}
                notesAddedCallback={() => this.reloadProject()}
                noLimit
                isPage
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectActivityMainView;

ProjectActivityMainView.propTypes = {
  match: PropTypes.object.isRequired,
};
