export const FASTHOOKS_UI_UPDATE_HOOK = "FASTHOOKS_UI_UPDATE_HOOK";

export const updatedHook = (projectId, eventId) => {
  const event = new CustomEvent(FASTHOOKS_UI_UPDATE_HOOK, {
    detail: {
      projectId,
      eventId,
    },
  });
  window.dispatchEvent(event);
};

export const listenForUpdateEvent = (handlerFn) => {
  window.addEventListener(FASTHOOKS_UI_UPDATE_HOOK, handlerFn);

  return () => {
    window.removeEventListener(FASTHOOKS_UI_UPDATE_HOOK, handlerFn);
  };
};

export const listenForEvents = (eventName, handlerFn) => {
  window.addEventListener(eventName, handlerFn);

  return () => {
    window.removeEventListener(eventName, handlerFn);
  };
};
