import * as types from '../types/types';

const initState = [];

export default (state = initState, action) => {
  switch (action.type) {
    case types.BROWSER_HISTORY_CHANGE:
      return [...state, action.payload];
    default:
      return state;
  }
};
