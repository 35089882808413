/* eslint no-case-declarations: 0 */
import * as types from '../types/types';

const initState = {
  projects: {},
  isLoading: false,
  smfGoals: {},
  smfGoalsMeta: {},
  constants: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.PROJECT_LOAD:
      return { ...state, isLoading: true };
    case types.PROJECT_LOADED:
      const projectsCopy = { ...state.projects };
      projectsCopy[action.payload.project.id] = action.payload.project;
      return { ...state, isLoading: false, projects: projectsCopy };
    case types.SMF_GOALS_LOADED:
      const clone = { ...state.smfGoals };
      clone[action.payload.projectId] = action.payload.smfGoals;
      return { ...state, smfGoals: clone };
    case types.PROJECT_CONST_LOADED:
      const constClone = { ...state.constants };
      constClone[action.payload.projectId] = action.payload.constants;
      return { ...state, constants: constClone };
    case types.SMF_SETTINGS_LOADED:
      const clone1 = { ...state.smfGoalsMeta };
      clone1[action.payload.projectId] = {
        smfGoals: action.payload.smfGoals,
        smfActions: action.payload.smfActions,
        smfGroups: action.payload.smfGroups,
      };
      return { ...state, smfGoalsMeta: clone1 };
    case types.SMF_GOALS_ACTIONS_LOADED:
      const clone2 = { ...state.smfGoalsMeta };
      clone2[action.payload.projectId] = {
        smfGoals: action.payload.smfGoals,
        smfActions: action.payload.smfActions,
      };
      return { ...state, smfGoalsMeta: clone2 };
    case types.SMF_GOALS_ACTIONS_UPDATE:
      const clone3 = { ...state.smfGoalsMeta };
      clone3[action.payload.projectId] = {
        smfGoals:
          action.payload.smfGoals || clone3[action.payload.projectId].smfGoals,
        smfGroups:
          action.payload.smfGroups ||
          clone3[action.payload.projectId].smfGroups,
      };
      return { ...state, smfGoalsMeta: clone3 };
    default:
      return state;
  }
};
