import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import AuthService from "./auth/AuthService";

// import('antd/dist/antd.css').then(() => import('./styles.css'));

new AuthService().setTokenOnReload(() => {
  // have to set token here, because indexedb is lazy
  ReactDOM.render(React.createElement(App), document.getElementById("root"));
});

unregisterServiceWorker();
