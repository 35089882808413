import React, { Component } from "react";
import PropTypes from "prop-types";
// import Button from "antd/lib/button";
import Table from "antd/lib/table";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import InputNumber from "antd/lib/input-number";
import Button from "antd/lib/button";

class MetaListEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.edits = [];
  }
  onSave() {
    this.props.onSave(this.edits, this.props.list);
  }
  onEdit(name, record, value) {
    if (!this.edits[record.id]) this.edits[record.id] = {};
    this.edits[record.id][name] = value;
  }
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "listValue",
        key: "listValue",
        render: (text, record) => (
          <Input
            defaultValue={record.listValue}
            onChange={e => {
              this.onEdit("listValue", record, e.target.value);
            }}
          />
        )
      },
      {
        title: "Value",
        dataIndex: "externalValue",
        key: "externalValue",
        render: (text, record) => (
          <InputNumber
            defaultValue={record.externalValue}
            onChange={value => {
              this.onEdit("externalValue", record, value);
            }}
          />
        )
      },
      {
        title: "Value 2",
        dataIndex: "externalValue2",
        key: "externalValue2",
        render: (text, record) => (
          <InputNumber
            defaultValue={record.externalValue2}
            onChange={value => {
              this.onEdit("externalValue2", record, value);
            }}
          />
        )
      },
      {
        title: "Value Id",
        dataIndex: "externalId",
        key: "externalId",
        render: (text, record) => (
          <Input
            defaultValue={record.externalId}
            onChange={e => {
              this.onEdit("externalId", record, e.target.value);
            }}
          />
        )
      },
      {
        title: "Delete",
        render: (text, record) => (
          <Button
            icon="delete"
            type="danger"
            onClick={e => {
              this.props.deleteRow(record.id);
            }}
          />
        )
      }
    ];
    return (
      <Modal
        title={` ${this.props.fieldLabel} lists`}
        visible={this.props.open}
        onOk={() => this.onSave()}
        onCancel={() => this.props.onSave(null)}
        className="MetasListEditor2"
        width={"1000px"}
        confirmLoading={this.props.isLoading}
        okText="Save & Close"
      >
        <Table
          loading={this.props.isLoading}
          dataSource={this.props.list.filter(el => !el.delete)}
          columns={columns}
          rowKey={record => record.id}
          footer={() => {
            return (
              <div>
                <Button onClick={() => this.props.addRow()}>Add Row</Button>
              </div>
            );
          }}
        ></Table>
      </Modal>
    );
  }
}

export default MetaListEditor;

MetaListEditor.defaultProps = {
  list: []
};

MetaListEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  listsRaw: PropTypes.array.isRequired,
  list: PropTypes.array
};
