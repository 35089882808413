import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import EventManager from '../../../shared/EventManager';
import ProjectsService from '../../ProjectsService';
import { getParamAfterSlug } from '../../../utils/URLUtils';
import './ProjectBlockingMainView.scss';
import ProjectBlockingEmpty from './ProjectBlockingEmpty';
import BlockingFileInfo from './BlockingFileInfo';
import DownImg from '../../../shared/icons/download.gif';
import Notifications from '../../../shared/notifications/Notifications';
import Notify from '../../../utils/Notify';
import { bytesToSize } from '../../../utils/StringUtils';
import { FILE_SIZE_MAX } from '../../../shared/Constants';

class ProjectBlockingMainView extends Component {
  constructor(props) {
    super(props);
    this.dropzoneRef = null;
    this.projectsService = new ProjectsService();
    this.state = {
      isEmptyProject: false,
      showLoading: true,
      file: null,
      downloadFileDisable: false,
      showLoadingEmpty: false,
      lockDownload: false,
      lockUpload: false,
      redirectToTS: false,
    };
    this.downStyle = {
      height: '9px',
      backgroundImage: `url(${DownImg})`,
    };
  }

  componentDidMount() {
    this.projectId = getParamAfterSlug('projects');
    this.projectsService.getProject(this.projectId, (project) => {
      this.projectsService.getBlockingChartInfo(this.projectId, (bcinfo) => {
        if (Object.keys(bcinfo).length === 0 && bcinfo.constructor === Object) {
          this.setState({ isEmptyProject: true, showLoading: false, showLoadingEmpty: false });
        } else {
          this.setState({
            isEmptyProject: false,
            file: bcinfo,
            showLoading: false,
            showLoadingEmpty: false,
          });
        }
      });
      if (project.bcLock) {
        this.setState({ lockUpload: true, lockDownload: false });
      } else {
        this.setState({ lockDownload: true, lockUpload: false });
      }
    });
  }

  onGeneratTS = () => {
    this.setState({ redirectToTS: true });
  };

  onFileDrop(files) {
    this.setState({ showLoading: true });
    // if file was rejected files array is empty.
    if (files[0]) {
      if (files[0].preview) {
        window.URL.revokeObjectURL(files[0].preview);
      }
      this.uploadFile(files[0]);
    }
  }

  onDropRejected = (file) => {
    this.setState({ showLoading: false });
    if (file[0].size > FILE_SIZE_MAX) {
      const { size } = file[0];
      const max = FILE_SIZE_MAX;
      Notify.fireWarn(`File size too large (${bytesToSize(size)}), max upload file size is ${bytesToSize(max)}`);
    }
  }

  uploadFileTrigger() {
    this.dropzoneRef.open();
  }

  fileDownLoadTrigger() {
    this.setState({ downloadFileDisable: true });
    const file = {
      file: this.state.file,
    };
    this.projectsService.downloadFile(
      file,
      () => {
        this.setState({ downloadFileDisable: false });
        this.projectsService.lockBlockingChart(this.projectId, false, () => {
          this.componentDidMount();
        });
      },
      () => {
        this.setState({ downloadFileDisable: false });
      },
    );
  }

  uploadFile(file) {
    this.setState({ showLoading: true, showLoadingEmpty: true });
    this.projectsService.uploadBlockingFile(this.projectId, file, () => {
      this.setState({ showLoading: false }, () => {
        this.projectsService.lockBlockingChart(this.projectId, true, () => {
          this.componentDidMount();
        });
      });
    });
  }

  render() {
    if (this.state.redirectToTS) {
      EventManager.emit(EventManager.TYPES_MAP.NAVIGATION.RELOAD, {});
      return <Redirect to={`/projects/${this.projectId}/traffic`} />;
    }
    if (this.state.isEmptyProject) {
      return (
        <ProjectBlockingEmpty
          onFileUploaded={file => this.uploadFile(file)}
          showLoading={this.state.showLoadingEmpty}
          type="blocking"
        />
      );
    }
    return (
      <div className="ProjectBlockingMainView">
        <div className="u-padding-both-sides-25 u-padding-top-10">
          <Notifications />
        </div>
        <div className={`ld-over ${this.state.showLoading ? 'running' : 'not-running'}`}>
          <span className="ld ld-ring ld-spin" />
          <div className="u-padding-both-sides-25">
            {/* <div className="grid-x stats"> next week!
            <div className="cell small-6 medium-2">
              <span className="title">Gross budget</span>
              <span className="value">$90.00</span>
            </div>
            <div className="cell small-6 medium-2">
              <span className="title">Delivery fees</span>
              <span className="value">$90.00</span>
              <span className="value">10% Buffer: $10.00</span>
            </div>
            <div className="cell small-6 medium-2">
              <span className="title">Tracking fees</span>
              <span className="value">Onsite inpresions: $1000</span>
              <span className="value">DBC Expedia buy: $1500</span>
            </div>
            <div className="cell small-6 medium-2">
              <span className="title">Arctexy</span>
              <span className="value">$1000</span>
            </div>
            <div className="cell small-6 medium-2">
              <span className="title">Net Media</span>
              <span className="value">$989,000</span>
            </div>
          </div> */}
            <div className="grid-x files">
              <div className="cell small-12 medium-6 u-vertical-middle">
                {this.state.file ? <BlockingFileInfo file={this.state.file} /> : <span />}
              </div>
              <div className="cell small-12 medium-6 buttons-row">
                <input
                  type="button"
                  onClick={() => this.onGeneratTS()}
                  className="button generate-button"
                  value="Go To Trafficing Sheet"
                />
                <input
                  type="button"
                  className="clear button upload-button"
                  value="Upload New Blocking File"
                  onClick={() => this.uploadFileTrigger()}
                  disabled={this.state.lockUpload}
                />
                <button
                  className={`button download-button ${
                    this.state.downloadFileDisable ? '' : 'clear'
                  } `}
                  onClick={() => this.fileDownLoadTrigger()}
                  disabled={this.state.downloadFileDisable || this.state.lockDownload}
                >
                  Download to Excel
                  <div
                    className={`ld ld-over-full ld-loader ${
                      this.state.downloadFileDisable ? 'show' : 'hide'
                    }`}
                    style={this.downStyle}
                  />
                </button>
              </div>
            </div>

            {/* <div className="sheet">
              {this.state.chart.length > 0 ? (
                <ProjectBlockingTable chart={this.state.chart} />
              ) : (
                <span>laosfa</span>
              )}
            </div> */}
          </div>

          <div className="file-upload-oute">
            <Dropzone
              ref={(node) => {
                this.dropzoneRef = node;
              }}
              disablePreview
              multiple={false}
              accept=""
              maxSize={FILE_SIZE_MAX}
              className="file-dnd"
              onDrop={acceptedFile => this.onFileDrop(acceptedFile)}
              onDropRejected={e => this.onDropRejected(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectBlockingMainView;
