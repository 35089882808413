import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Creatable } from 'react-select';
import 'react-select/dist/react-select.css';
import { format } from 'date-fns';
import parse from 'date-fns/parse';
import { capitalizeFirstLetter } from '../../utils/StringUtils';
import removeImg from '../../shared/remove.svg';

/**
 * Milestone line with dropdown input and date selector.
 * @param {func} onLineChange callback when line changes,
 *  returns selectedDay and selectedMilestoneOption
 * @param {func} removeLine callback on remove
 * @param {number} currentStone holds current milestone number
 * @param {array} milestones holds data about all milestones
 * @param {object} initDate used for init value for line.
 */
class NewProjectMilestoneLine extends Component {
  constructor(props) {
    super(props);
    this.onLineChange = this.props.onLineChange.bind(this);
    this.currentStone = this.props.milestones.find(el => el.order === this.props.currentStone);
    if (!this.currentStone) {
      this.currentStone = {
        required: false,
      };
    }
    let option = '';
    if (this.currentStone.required) {
      option = this.currentStone;
    }
    this.state = {
      selectedDay: format(parse(this.props.initDate), 'YYYY-MM-DD'),
      selectedMilestoneOption: option,
    };
    this.onLineChange(this.state);
  }

  handleSelecteDay(selectedDay) {
    const formatted = format(parse(selectedDay), 'YYYY-MM-DD');
    this.setState({ selectedDay: formatted }, () => {
      this.onLineChange(this.state);
    });
  }

  innerHandleMilestoneChange = (selectedMilestoneOption) => {
    this.setState({ selectedMilestoneOption }, () => {
      this.onLineChange(this.state);
    });
  };

  render() {
    const startEndDateValidation = () => {
      if (this.props.showEndDateError) {
        return (
          <div className="validation-required">
            Cannot be before
            {' '}
            <em>Start Date</em>
          </div>
        );
      }
      return <span />;
    };
    return (
      <div className="grid-x" data-fth={this.props.currentStone}>
        <div className="cell small-12 medium-4 v-center">
          {this.currentStone.required ? (
            <span>
              <span className="u-red">• </span>
              <span className="u-gray">
                {capitalizeFirstLetter(this.currentStone.label)}
                {' '}
                <em className="smaller">(YYYY-MM-DD)</em>
              </span>
            </span>
          ) : (
            <Creatable
              name="milestone"
              value={this.state.selectedMilestoneOption}
              options={this.props.milestones.filter(elem => !elem.required)}
              onChange={(val) => {
                this.innerHandleMilestoneChange(val);
              }}
              placeholder="Type to create new..."
            />
          )}
        </div>
        <div className="cell small-12 medium-6 v-center">
          <DayPickerInput
            value={this.state.selectedDay}
            onDayChange={val => this.handleSelecteDay(val)}
          />

          {this.currentStone.required ? (
            startEndDateValidation()
          ) : (
            <div
              className="removeMilestoneLine v-center"
              role="presentation"
              onClick={this.props.removeLine}
              onKeyPress={this.props.removeLine}
            >
              <img src={removeImg} alt="remove" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default NewProjectMilestoneLine;

NewProjectMilestoneLine.defaultProps = {
  showEndDateError: false,
};

NewProjectMilestoneLine.propTypes = {
  onLineChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  currentStone: PropTypes.number.isRequired,
  milestones: PropTypes.array.isRequired,
  initDate: PropTypes.any.isRequired,
  showEndDateError: PropTypes.bool,
};
