import React from 'react';
import '../../../projects/mainNavView/MainNavView.scss';
import CompanyAndUser from '../../../shared/companyAndUser/CompanyAndUser';
import './ProfileNavView.scss';

const ProfileNavView = () => (
  <div className="MainNavView ProfileNavView">
    <div className="grid-container full">
      <div className="grid-x projects-header">
        <div className="cell small-9">
          <h6>
            Fastloop Data Systems Inc.
            {' '}
            <span className="u-gray">Profile</span>
          </h6>
        </div>
        <div className="cell small-3">
          <CompanyAndUser />
        </div>
      </div>
    </div>
  </div>
);

export default ProfileNavView;
