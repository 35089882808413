import React from "react";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import message from "antd/lib/message";
import { commonCallForReloadBound } from "../../../actions/commonDataActionsUtils";
import { BP_DRAWER, BP_LEVELS /* BPVIEWS */ } from "../../../shared/Constants";
import {
  isListSMF,
  isFloat,
  isInt,
  validateImport,
} from "../bpTable/BPTableDataUtils";
import { textToFloatStr, textToIntStr } from "../../../utils/NumberParser";
import "./BPImportCol.scss";
import { BPImportHeader } from "./BPImportHeader";

const { TextArea } = Input;

class BPImportCol extends React.Component {
  constructor() {
    super();
    this.state = {
      data: "",
      type: "override",
    };
    this.defaultType = "override";
    this.show = {};
    this.importData = [];
    this.type = this.defaultType;
  }
  handleType(e) {
    this.type = e.target.value;
  }
  save(saveBP) {
    this.importData = this.cleanUpData(this.state.data);

    const isThisValid = validateImport(
      this.importData,
      this.props.clickedElement,
      this.props.level
    );

    if (isThisValid.valid) {
      let NAME = BP_DRAWER.IMPORT_COL1;
      if (this.props.level === BP_LEVELS.DYN1) {
        NAME = BP_DRAWER.IMPORT_COL1;
      } else if (this.props.level === BP_LEVELS.DYN2) {
        NAME = BP_DRAWER.IMPORT_COL2;
      } else {
        NAME = BP_DRAWER.IMPORT_COL3;
      }

      window.datafix = this.importData;

      commonCallForReloadBound(NAME, {
        importData: this.importData,
        type: this.type,
        clickedElement: this.props.clickedElement,
        level: this.props.level,
        doSave: saveBP,
      });
      this.props.closeCall();
    } else {
      message.error(isThisValid.msg);
    }
  }

  cleanUpData = (rawInput) => {
    const raw = rawInput.split(/\r|\n/);
    const importData = raw.map((d) => {
      let cleaned = d.trim();
      if (isInt(this.props.clickedElement)) {
        cleaned = textToIntStr(cleaned);
      }
      if (isFloat(this.props.clickedElement)) {
        cleaned = textToFloatStr(cleaned);
      }
      return cleaned;
    });
    // TODO handle multiple empty lines
    const last = importData[importData.length - 1];
    if (last === "") {
      importData.pop();
    }
    return importData;
  };

  handleChange = (e) => {
    this.setState({ data: e.target.value });
  };
  render() {
    const clickedElement = this.props.view.find(
      (v) => v.name === this.props.clickedElement
    );
    // const isIDCol = BPVIEWS.KNOWN_CONST.LINE_ID === this.props.clickedElement;
    const label = clickedElement.label;
    const hasFormula = clickedElement.formula.trim().length !== 0;
    // const notSupported =
    //   isListSMF(this.props.clickedElement) || hasFormula || isIDCol;
    const notSupported = isListSMF(this.props.clickedElement) || hasFormula;
    let disableOverride = notSupported || this.props.level !== BP_LEVELS.DYN1;
    return (
      <div className="BPImportCol">
        <BPImportHeader
          clickedElement={this.props.clickedElement}
          level={this.props.level}
          label={label}
          notSupported={notSupported}
        />

        <TextArea className="text-area" onChange={this.handleChange} />

        <div className="bottom-row">
          <div className="note">Column content will be overridden! </div>

          <Button onClick={this.props.closeCall} className="btn-margin">
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => this.save(true)}
            disabled={notSupported}
            className="btn-margin"
          >
            Override And Save
          </Button>
          <Button
            type="primary"
            onClick={() => this.save(false)}
            disabled={disableOverride}
          >
            Override
          </Button>
        </div>
      </div>
    );
  }
}

BPImportCol.defaultProps = {
  closeCall: () => {},
};

BPImportCol.propTypes = {
  clickedElement: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  view: PropTypes.array.isRequired,
  viewId: PropTypes.number.isRequired,
  closeCall: PropTypes.func,
};

export default BPImportCol;
