// export const qnd = {
//     state: {

//     }
// }

export const qndui = {
  state: {
    selectedClient: {},
    openBigQueryDialog: false,
    openBigQueryDialogProjectId: 0,
  },
  reducers: {
    setSelectedClient: (state, payload) => {
      const clone = { ...state };
      clone.selectedClient = payload;
      return clone;
    },
    setSetBigQueryDialog: (state, payload) => {
      const clone = { ...state };
      clone.openBigQueryDialog = payload.open;
      clone.openBigQueryDialogProjectId = payload.projectId;
      return clone;
    },
  },
};
