import React, { Component } from "react";
import PropTypes from "prop-types";
import notification from "antd/lib/notification";
import Switch from "antd/lib/switch";
import { isAdmin } from "../../../../auth/AuthService";
import ExportSheet from "./ProjectExportSheet";

import "./ProjectGeneralSettings.scss";

import SingleSetting from "../singleSetting/SingleSetting";
import DSImg from "../../../../shared/icons/google-data-studio.png";
import { bpServiceStatic } from "../../../../pages/bp/BPService";
import { Button } from "antd";
import { projectsServiceStatic } from "../../../ProjectsService";
import { notify } from "../../../../shared/notifications/Notify";

class ProjectGeneralSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      budget: "",
      reportingUrl: "",
      reportingToken: "",
      customer: "",
      authenticationCode: "",
      binAdminLoading: false,
      sheetsLoading: false,
      dsSettingsLoading: false,
    };
    this.doShowAdmin = isAdmin();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.project) {
      this.setState({
        ...this.state,
        name: nextProps.project.name,
        budget: nextProps.project.budget.toString(),
        reportingUrl: nextProps.project.reportingUrl,
        reportingToken: nextProps.project.reportingToken,
        customer:
          (nextProps.project.customer && nextProps.project.customer.name) || "",
        authenticationCode: nextProps.project.authenticationCode,
        bqRanDate: nextProps.project.bqRanDate,
        bqAuto: nextProps.project.bqAuto,
        projectId: nextProps.project.id,
      });
    }
  }

  saveHandler(val) {
    this.props.onSave("name", val);
  }

  saveTableauHandler(val) {
    this.props.onSave("tableau", val);
  }

  saveTokenHandler(val) {
    this.props.onSave("token", val);
  }

  saveBudgetHandler(val) {
    this.props.onSave("budget", val);
  }

  updateDSCache = (val) => {
    projectsServiceStatic.bustBQCache(this.state.projectId, () => {
      notify("Success!", "BigQuery cache is being rebuilding.");
    });
  };

  onDSSwitchHandler = (val) => {
    this.props.onSaveGenNext("bqAuto", val);
    this.setState({ bqAuto: val });
  };

  exportToEmails = (data) => {
    if (data.emails === undefined) return;
    var str = data.emails;
    var modStr = "";
    if (str[0] === "-") {
      modStr = str.slice(1);
    } else {
      modStr = str;
    }
    var resArray = modStr.split(/\s+[-]*/);
    if (resArray[resArray.length - 1] === "") {
      resArray.pop();
    }
    projectsServiceStatic.exportSheet(
      this.props.project.id,
      resArray,
      (data) => {
        this.setState({ binAdminLoading: false });
        this.showMessage(data);
      }
    );
  };

  regenerateSheet = () => {
    const dataToSave = {
      meta: {},
      lists: {},
      views: [],
    };
    this.setState({ sheetsLoading: true });
    bpServiceStatic.saveAllDyn(this.props.project.id, dataToSave, (_) => {
      this.setState({ sheetsLoading: false });
      notification["success"]({
        message: "Success!",
        description: "Your project sheets has been regenerated",
        duration: 3,
      });
    });
  };

  showMessage = (data) => {
    if (Array.isArray(data) && data.length === 0) {
      notification["success"]({
        message: "Success!",
        description: "Your project's Google Sheets has been exported!",
        duration: 3,
      });
    } else {
      notification["error"]({
        message: "Something went wrong!",
        description: "Please try to re-enter emails and try again.",
        duration: 0,
      });
    }
  };

  render() {
    return (
      <div className="ProjectGeneralSettings">
        <div>
          <h6>General Project Settings</h6>
        </div>
        <div className="grid-x setting-row" aria-labelledby="Project Name">
          <div className="cell small-12 medium-2 name">Project Name</div>
          <div className="cell small-10 medium-10 value">
            <SingleSetting
              value={this.state.name}
              onSave={(e) => this.saveHandler(e)}
              showLoading={this.props.showLoading}
              valueSaved={this.props.nameSaved}
            />
          </div>
        </div>
        <div className="grid-x setting-row" aria-labelledby="Project Budget">
          <div className="cell small-12 medium-2 name">Project Budget</div>
          <div className="cell small-10 medium-10 value">
            <SingleSetting
              value={this.state.budget}
              onSave={(e) => this.saveBudgetHandler(e)}
              showLoading={this.props.showLoading}
              valueSaved={this.props.nameSaved}
              isFloat
            />
          </div>
        </div>
        <div className="grid-x setting-row" aria-labelledby="Customer">
          <div className="cell small-12 medium-2 name">Customer</div>
          <div className="cell small-10 medium-10 value">
            {this.state.customer}
          </div>
        </div>
        {/* Hidding Tableu Settings for now */}
        {/* <div className="grid-x setting-row" aria-labelledby="Reporting URL">
          <div className="cell small-12 medium-2 name">Reporting URL</div>
          <div className="cell small-10 medium-10 value">
            <SingleSetting
              value={this.state.reportingUrl}
              onSave={e => this.saveTableauHandler(e)}
              showLoading={this.props.showLoadingTableau}
              valueSaved={this.props.tableauUrlSaved}
            />
          </div>
        </div> */}
        <div
          className="grid-x setting-row"
          aria-labelledby="Data Visualizations"
        >
          <div className="cell small-12 medium-2 name">Data Visualizations</div>
          <div className="cell small-10 medium-10 value">
            <SingleSetting
              value={this.state.reportingToken}
              onSave={(e) => this.saveTokenHandler(e)}
              showLoading={this.props.showLoadingToken}
              valueSaved={this.props.tokenSaved}
            />
          </div>
        </div>
        <br />
        <div>
          <h6>DS Insights Settings</h6>
        </div>
        <div className="grid-x setting-row" aria-labelledby="Actions">
          <div className="cell small-12 medium-2 name">Actions:</div>
          <div className="cell small-10 medium-10 value">
            <div
              className={`ld-over ${
                this.state.dsSettingsLoading ? "running" : "not-running"
              }`}
            >
              <span className="ld ld-ring ld-spin" />
              <div className="display-grid">
                <div className="display-inline">
                  <label className="ds-label">
                    Auto refresh at 7am each day:{" "}
                  </label>
                  <Switch
                    className="ds-switch"
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    onChange={(val) => this.onDSSwitchHandler(val)}
                    checked={this.state.bqAuto}
                  />{" "}
                </div>
                <div className="display-inline">
                  <label className="ds-label">
                    <span>
                      Last ran:{" "}
                      {this.state.bqRanDate === "1970-01-01T00:00:00-0800"
                        ? "Never"
                        : this.state.bqRanDate}
                    </span>
                  </label>
                </div>
                <div className="display-inline">
                  <Button onClick={() => this.updateDSCache()}>
                    Refresh DS Insights Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div>
          <h6>Data Studio</h6>
        </div>
        <div className="grid-x setting-row" aria-labelledby="Customer">
          <div className="cell small-12 medium-2 name">
            Authentication Code:
          </div>
          <div className="cell small-10 medium-10 value">
            <code>{this.state.authenticationCode || "n/a"}</code>
            <br />
            <br />
            <a
              href={process.env.REACT_APP_DATASTUDIO_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={DSImg} className="gds-img" alt="google data studio" />
            </a>
          </div>
        </div>
        <br />
        <div>
          <h6>Google Sheets</h6>
        </div>
        <div className="grid-x setting-row" aria-labelledby="Google Sheets">
          <div className="cell small-12 medium-2 name">Actions:</div>
          <div className="cell small-10 medium-10 value">
            <div
              className={`ld-over ${
                this.state.sheetsLoading ? "running" : "not-running"
              }`}
            >
              <span className="ld ld-ring ld-spin" />
              <div className="display-inline">
                <ExportSheet
                  onExport={(emails) => this.exportToEmails(emails)}
                />
                <Button onClick={() => this.regenerateSheet()}>
                  Regenerate Sheet
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectGeneralSettings;

ProjectGeneralSettings.propTypes = {
  project: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  showLoading: PropTypes.bool.isRequired,
  // showLoadingTableau: PropTypes.bool,
  showLoadingToken: PropTypes.bool.isRequired,
  nameSaved: PropTypes.bool.isRequired,
  // tableauUrlSaved: PropTypes.bool,
  tokenSaved: PropTypes.bool.isRequired,
  onSaveGenNext: PropTypes.func.isRequired,
};
