import React, { Component } from "react";
import BPJoined from "./BPJoined";
import CustomViewsDialog from "../../../modals/customViews/CustomViewsDialog";
import { initViews, initCommonData } from "../../bp/BPViewService";
import { projectsServiceStatic } from "../../../projects/ProjectsService";

class BPJoinedShell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      dataSource: [],
      loaded: false,
      forbidden: false,
    };
  }
  componentDidMount() {
    projectsServiceStatic.getProject(this.props.match.params.id);
    //TODO: make accept callbacks so can be loaded in sequence.

    projectsServiceStatic.getProject(
      this.props.match.params.id,
      () => {
        initCommonData("BPPage", this.props.match.params.id);
        initViews(this.props.match.params.id, () => {
          this.setState({ loaded: true });
        });
      },
      (err) => {
        if (err.response.status === 403) {
          this.setState({ forbidden: true });
        }
      }
    );
  }

  render() {
    if (this.state.forbidden) {
      return (
        <div className="BPJoinedShell u-padding-top-10">
          <div>
            <div className="u-padding-both-sides-25">
              Sorry, seems you have no access to this page.
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="BPJoinedShell u-padding-both-sides-25 u-padding-top-10">
        {this.state.loaded && (
          <>
            <BPJoined projectId={Number(this.props.match.params.id)} />
          </>
        )}
        <CustomViewsDialog />
      </div>
    );
  }
}

export default BPJoinedShell;
