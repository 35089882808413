import React from "react";
import { Redirect } from "react-router-dom";
import NotFoundImage from "./404.png";
/**
 * Generic 404 page.
 */
const style = {
  width: "50%",
  position: "absolute",
  bottom: 0,
  right: "25%",
};
const pageStyle = {
  height: "100vh",
};
const linkStyle = {
  textDecoration: "underline",
};
const goBack = () => {
  window.history.back();
};

const NotFoundPage = () => {
  if (window.location.pathname === "/") {
    return <Redirect to="/projects/all?filter=live" />;
  }
  return (
    <div className="NotFoundPage" style={pageStyle}>
      <center>
        <h3>404 = No treat found!</h3>
        <br />
        <br />
        <br />
        <h6>
          {/* eslint-disable-next-line */}
          <a
            style={linkStyle}
            role="link"
            tabIndex="0"
            onKeyPress={goBack}
            onClick={goBack}
          >
            Back to FASTLOOP
          </a>
        </h6>
        <img src={NotFoundImage} style={style} alt="not found" />
      </center>
    </div>
  );
};

export default NotFoundPage;
