import React from "react";
import Paper from "@material-ui/core/Paper";

import "./Desktop.scss";

const Desktop = () => (
  <div className="Desktop">
    <div>
      <h6>Virtual Desktop</h6>
    </div>
    <div className="guts">
      <Paper>N/A</Paper>
      <Iframe url={url} />
    </div>
  </div>
);

export default Desktop;

const url =
  "https://fastloop.cloud.looker.com/login/embed/%2Fembed%2Flooks%2F2?nonce=%22f0c69676f85caefd3f7caa1a1dedf4f0%22&time=1595632680&session_length=900&external_user_id=%22test-id-123%22&permissions=%5B%22see_looks%22%2C+%22access_data%22%5D&models=%5B%22demo_model%22%5D&group_ids=%5B%5D&external_group_id=null&user_attributes=%7B%22allowed_dataset%22%3A+%22VGA%2C+DBC%22%2C+%22my_number_attribute%22%3A+%2242%22%7D&access_filters=%7B%7D&signature=GE8f%2Br8jQIFStYpYqq8nzAXswvY%3D&first_name=%22Testy%22&last_name=%22McTestFace%22&force_logout_login=true";

const Iframe = ({ url }) => {
  return (
    <div>
      <div className="iframe-container">
        <iframe
          frameBorder="0"
          width="1600"
          height="900"
          title="looker"
          src={url}
        />
      </div>
    </div>
  );
};
