import { camp } from '../camp';
import { globalErrorCallback } from '../utils/ErrorTracker';
import { commonDataPlatformsBound } from '../actions/commonDataActionsUtils';
import { projectSMFGoalsLoadedBound } from '../projects/ProjectReduxUtils';

class CommonDataService {
  constructor() {
    this.camp = camp;
  }

  getPlatforms = () => {
    this.camp
      .getPlatforms()
      .then((platofrms) => {
        const byId = {};
        platofrms.forEach((elem) => {
          byId[elem.id] = elem;
        });
        commonDataPlatformsBound(byId);
      })
      .catch(globalErrorCallback);
  };

  getSMFGoals = (projectId) => {
    this.camp
      .getSmf({ id: projectId })
      .then(smfGoals => projectSMFGoalsLoadedBound(projectId, smfGoals))
      .catch(globalErrorCallback);
  };
}

export default CommonDataService;

export const commonDataServiceStatic = new CommonDataService();
