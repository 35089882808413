import React, { Component } from "react";
import PropTypes from "prop-types";
import localForage from "localforage";
import { GOOGLE_SHEETS_HELP_STORAGE_KEY } from "../Constants";
import { Icon, Popover } from "antd";
import "antd/dist/antd.css";
import "./Help.scss";

export class HelpIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      documentation: {}
    };
  }

  componentDidMount() {
    var that = this;
    localForage
      .getItem(GOOGLE_SHEETS_HELP_STORAGE_KEY)
      .then(function(value) {
        that.setState({ documentation: value[that.props.code - 1] });
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  handleHoverChange = visible => {
    this.setState({
      hovered: visible
    });
  };

  getContent = type => {
    if (!this.state.documentation) return;
    if (type === "link") return this.state.documentation[type];
    else return <div>{this.state.documentation[type]}</div>;
  };

  render() {
    const content = this.getContent("content");
    const documentationLink = this.getContent("link");
    return (
      <div className="HelpIcon">
        <Popover
          placement={this.props.placement}
          content={
            <div className="Help-link Help-popover">
              {content}
              <a
                href={documentationLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Help
              </a>
            </div>
          }
          trigger="hover"
          visible={this.state.hovered}
          onVisibleChange={this.handleHoverChange}
        >
          <Icon type="question-circle" theme="twoTone" />
        </Popover>
      </div>
    );
  }
}

export default HelpIcon;

HelpIcon.defaultProps = {};

HelpIcon.propTypes = {
  code: PropTypes.string.isRequired
};
