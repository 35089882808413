import React from "react";
import PropTypes from "prop-types";
import Modal from "antd/lib/modal";
import Select from "react-select";
import "react-select/dist/react-select.css";

import "./CustomViews.scss";

class CustomViewsNewField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "", selected: "", options: [], disabled: false };
  }

  componentWillReceiveProps() {
    this.setState({ selected: "", value: "", disabled: false });
  }

  onInput(event) {
    const val = event.target.value;
    const clean = val.replace(".", "").replace(",", "");
    this.setState({ value: clean });
  }

  onKeyDown = event => {
    if (event.key === "Enter") {
      this.onSave();
    }
  };

  onSave = () => {
    this.props.onSave(this.state.value, this.state.selected.value);
    this.setState({ disabled: true });
  };

  onChange(event) {
    let name = this.state.value;
    if (this.state.value.trim().length === 0) {
      name = event.label;
    }
    this.setState({ selected: event, value: name });
  }

  getHumanFriendlyLabel = field => {
    const names = {
      text: "Text",
      float: "Decimal Number",
      int: "Whole Number",
      date: "Date",
      bool: "Checkbox",
      list: "List",
      file: "File"
    };
    let name = "";
    Object.keys(names).forEach(nameKey => {
      if (field.indexOf(nameKey) === 0) {
        name = names[nameKey];
      }
    });
    const number = field.split("Column")[1];
    return `${name} ${number}`;
  };

  createOptions = () => {
    const left = this.props.freeFields;
    const retVal = [];

    left.forEach(field => {
      const label = this.getHumanFriendlyLabel(field);
      // @TODO fix it right, this happens when we have new field types like - platformId
      // @TODO yolo
      if (label.indexOf("undefined") === -1) {
        retVal.push({
          value: field,
          label: label
        });
      }
    });
    return retVal;
  };

  render() {
    const options = this.createOptions();
    return (
      <div className="CustomViewsNewFieldDialog">
        <Modal
          visible={this.props.open}
          width={600}
          onCancel={() => this.props.onCancel()}
          onOk={() => this.onSave()}
          okText="Add"
          title="New Field"
          confirmLoading={this.state.disabled}
        >
          <div className="CustomViewsNewField">
            <div className="grid-x">
              <div className="cell small-6">Type</div>
              <div className="cell small-6">Name</div>
            </div>
            <div className="grid-x">
              <div className="cell small-6">
                <Select
                  name="type"
                  clearable={false}
                  placeholder="Type"
                  options={options}
                  onChange={e => this.onChange(e)}
                  value={this.state.selected}
                />
              </div>
              <div className="cell small-6">
                <input
                  type="text"
                  name="fieldName"
                  onChange={event => this.onInput(event)}
                  onKeyDown={event => this.onKeyDown(event)}
                  value={this.state.value}
                  placeholder="Name"
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CustomViewsNewField;

CustomViewsNewField.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  freeFields: PropTypes.array.isRequired
};
