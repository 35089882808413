import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getSystem } from '../../selectors/notificationsSelectors';
import { dismissNotification } from '../../actions/notificationActions';
import './SystemNotifications.scss';
import CloseImg from '../icons/times-circle-black.svg';
import { parseAndFormatDate } from '../../utils/DateUtils';
import { DEFAULT_DATE_FORMAT_WITH_TIME } from '../Constants';

const SystemNotificationsInner = ({ messages, dismiss }) => {
  const showClassTop = classnames({ SystemNotifications: true, show: messages.length > 0 });
  const showClassEach = classnames({ messages: true, show: messages.length > 0 });
  return (
    <div className={showClassTop} data-count={messages.length - 1}>
      {messages.map((message, count) => (
        <div className={showClassEach} key={message.id} data-count={count}>
          <div>
            {message.text}
            {' '}
            <span className="date">
              (
              {parseAndFormatDate(message.created, DEFAULT_DATE_FORMAT_WITH_TIME)}
)
            </span>
            {message.canDismiss ? (
              <button onClick={() => dismiss(message.id)}>
                <img src={CloseImg} alt="close" className="close-img u-clickable" />
              </button>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  messages: getSystem(state),
});

const mapDispatchToProps = dispatch => ({
  dismiss: (id) => {
    dispatch(dismissNotification(id));
  },
});

const SystemNotifications = connect(mapStateToProps, mapDispatchToProps)(SystemNotificationsInner);

export default SystemNotifications;

SystemNotificationsInner.propTypes = {
  messages: PropTypes.array.isRequired,
  dismiss: PropTypes.func.isRequired,
};
