import React from "react";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";
import Tooltip from "antd/lib/tooltip";
import Menu from "antd/lib/menu";
import Icon from "antd/lib/icon";
import Dropdown from "antd/lib/dropdown";
import Checkbox from "antd/lib/checkbox";
import { connect } from "react-redux";
import { SigmaIcon } from "../../../shared/iconComponents/SigmaComp";
import { isRoot } from "../../../auth/AuthService";

import { BP_LEVELS_FOR_HUMANS_BY_INT } from "../../../shared/Constants";

import {
  getHookDataByLineIdSel,
  getHookDataByLineIdSummarySel,
} from "../../../selectors/MediaPlanSelectros";

import {
  PlanIconComp,
  PlacementIconComp,
  CreativeIconComp,
} from "../../../shared/iconComponents/IconComp";

import { fireSingleHookSingleEntity } from "./BPTableDataUtils";

import "./BPTable.scss";

export const TheTip = ({ title = "", children }) => {
  if (title.trim().length > 1) {
    return <Tooltip title={title}>{children}</Tooltip>;
  }
  return <>{children}</>;
};

const genCSVMenu = ({
  levelInt,
  exportBtn,
  exportIdsBtn,
  importBtn,
  biqQueryimportBtn,
  sumsChange,
  filterChange,
  massSelectChange,
  sumsOn,
  multiSelectOn,
  filtersOn,
}) => {
  const handleMenuClick = (e) => {
    switch (e.key) {
      case "sums":
        return sumsChange();
      case "multiselect":
        return massSelectChange();
      case "filters":
        return filterChange();
      case "export":
        return exportBtn();
      case "exportIds":
        return exportIdsBtn();
      case "import":
        return importBtn();
      case "bigqueryconnection":
        return biqQueryimportBtn();
      default:
        return;
    }
  };

  const isRootShow = isRoot();

  return (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="sums">
        <SigmaIcon />
        Show Sums - {sumsOn ? "Off" : "On"}
      </Menu.Item>
      <Menu.Item key="multiselect">
        <Icon type="select" />
        Multi Select - {multiSelectOn ? "Off" : "On"}
      </Menu.Item>
      <Menu.Item key="filters">
        <Icon type="filter" />
        Filters - {filtersOn ? "Off" : "On"}
      </Menu.Item>
      <Menu.Item key="export">
        <Icon type="export" />
        CSV Export
      </Menu.Item>
      <Menu.Item key="exportIds">
        <Icon type="bars" />
        IDs Export (Beta)
      </Menu.Item>
      <Menu.Item key="import">
        <Icon type="import" />
        CSV Import
      </Menu.Item>
      {levelInt === 1 && isRootShow && (
        <Menu.Item key="bigqueryconnection">
          <Icon type="pull-request" />
          BigQuery Imports
        </Menu.Item>
      )}
    </Menu>
  );
};

const BPTableActions = ({
  filterChange,
  sumsChange,
  levelInt,
  massSelectChange,
  massDelete,
  massTraffic,
  // massSelectState,
  selectAll,
  selectAllonChange,
  exportBtn,
  importBtn,
  biqQueryimportBtn,
  exportIdsBtn,
  sumsOn,
  multiSelectOn,
  filtersOn,
}) => (
  <div className="BPTableActions">
    <div className="actions-row">
      <div className="left">
        <div className="actions-outer">
          {multiSelectOn && (
            <div className="actions">
              <Checkbox
                indeterminate={selectAll.indeterminate}
                onChange={selectAllonChange}
                checked={selectAll.checkedAll}
              >
                Select All
              </Checkbox>
              <Popconfirm
                title="Are you sure delete selected lines?"
                onConfirm={massDelete}
                onCancel={null}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="danger"
                  icon="delete"
                  className="btn btn-delete"
                />
              </Popconfirm>
              <Button
                className="btn"
                onClick={massTraffic}
                icon="thunderbolt"
                style={{ color: "red" }}
              />
            </div>
          )}
        </div>
        <div className="names">
          {iconGen(levelInt)}
          {BP_LEVELS_FOR_HUMANS_BY_INT[levelInt]}
        </div>
      </div>
      <div className="toggles">
        <div className="cellx">* - required</div>
        <div className="expo">
          <Dropdown
            placement="bottomLeft"
            overlay={genCSVMenu({
              levelInt,
              exportBtn,
              exportIdsBtn,
              importBtn,
              biqQueryimportBtn,
              sumsChange,
              filterChange,
              massSelectChange,
              sumsOn,
              multiSelectOn,
              filtersOn,
            })}
          >
            <Button icon="tool" type="link" className="tool-all" />
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
);

export default BPTableActions;

BPTableActions.propTypes = {
  filterChange: PropTypes.func.isRequired,
  sumsChange: PropTypes.func.isRequired,
  massSelectChange: PropTypes.func.isRequired,
  massTraffic: PropTypes.func.isRequired,
  massDelete: PropTypes.func.isRequired,
  levelInt: PropTypes.number.isRequired,
  selectAll: PropTypes.object.isRequired,
  selectAllonChange: PropTypes.func.isRequired,
  exportBtn: PropTypes.func.isRequired,
  importBtn: PropTypes.func.isRequired,
  biqQueryimportBtn: PropTypes.func.isRequired,
  exportIdsBtn: PropTypes.func.isRequired,
  sumsOn: PropTypes.bool.isRequired,
  multiSelectOn: PropTypes.bool.isRequired,
  filtersOn: PropTypes.bool.isRequired,
  // massSelectState: PropTypes.bool.isRequired,
};

const iconGen = (levelInt) => {
  switch (levelInt) {
    case 1:
      return <PlanIconComp fill="black" w={14} h={14} />;
    case 2:
      return <PlacementIconComp fill="black" w={14} h={14} />;
    case 3:
      return <CreativeIconComp fill="black" w={14} h={14} />;
    default:
      return <></>;
  }
};

export const RowActions = ({
  lineData,
  isAdmin,
  index,
  dXString,
  onButtonClone,
  onButtonCopy,
  onButtonPaste,
  onButtonTraffic,
}) => {
  return (
    <>
      <Tooltip title="Clone">
        <Button
          onClick={() => onButtonClone(index)}
          type="dashed"
          icon="arrow-down"
        />
      </Tooltip>
      <Tooltip title="Copy">
        <Button onClick={() => onButtonCopy(index)} type="dashed" icon="copy" />
      </Tooltip>
      <Tooltip title="Paste">
        <Button onClick={() => onButtonPaste()} type="dashed" icon="snippets" />
      </Tooltip>
      {!isAdmin && (
        <Tooltip title="Traffic">
          <HookButtonBasic
            lineId={lineData.id}
            onButtonTraffic={() => onButtonTraffic(index)}
          />
        </Tooltip>
      )}
      {isAdmin && (
        <div className="admin-hooks-drop">
          <>
            <Dropdown.Button
              placement="bottomCenter"
              overlay={genMenu(lineData, dXString)}
              icon={<Icon type="caret-down" />}
              onClick={() => onButtonTraffic(index)}
            >
              <TrafficIcon lineId={lineData.id} />
            </Dropdown.Button>
          </>
        </div>
      )}
    </>
  );
};

const genMenu = (lineData, dXString) => {
  function handleMenuClick(e) {
    const hookName = e.item.props["data-hook-name"];
    const hookId = e.key.split("_")[1];
    fireSingleHookSingleEntity(dXString, hookId, lineData.id, hookName);
  }
  return (
    <HooksAdminMenu lineId={lineData.id} handleMenuClick={handleMenuClick} />
  );
};

const HookButtonBasicInner = ({ iconState, onButtonTraffic }) => {
  const theIcon = getIconClassFromIconState(iconState);
  return (
    <Button
      onClick={onButtonTraffic}
      type="dashed"
      icon={theIcon}
      className={`thunder-icon hooks-state${iconState}`}
    />
  );
};

const mapStateToPropsBasic = (state, ownProps) => {
  const { iconState } = getHookDataByLineIdSummarySel(state, ownProps.lineId);

  return {
    iconState,
    onButtonTraffic: ownProps.onButtonTraffic,
  };
};

const HookButtonBasic = connect(mapStateToPropsBasic)(HookButtonBasicInner);

const TrafficIconInner = ({ iconState }) => {
  const theIcon = getIconClassFromIconState(iconState);
  return (
    <Icon type={theIcon} className={`thunder-icon hooks-state${iconState}`} />
  );
};

const mapStateToPropsTrafficIcon = (state, ownProps) => {
  const { iconState } = getHookDataByLineIdSummarySel(state, ownProps.lineId);
  return {
    iconState,
  };
};

const TrafficIcon = connect(mapStateToPropsTrafficIcon)(TrafficIconInner);

const HooksAdminMenuInner = ({ lineId, hookData, handleMenuClick }) => {
  // This happens when there are no hooks.
  if (hookData === undefined)
    return (
      <Menu>
        <Menu.Item disabled>Seems there are no hooks declared.</Menu.Item>
      </Menu>
    );

  return (
    <Menu onClick={handleMenuClick}>
      {Object.keys(hookData).map((hdKey) => {
        return (
          <Menu.Item
            key={`hookId_${hookData[hdKey].hookId}`}
            data-hook-name={hookData[hdKey].hookName}
          >
            <Icon
              type={
                hookData[hdKey].maps.complete ? "check-circle" : "close-circle"
              }
              style={{ color: hookData[hdKey].maps.complete ? "green" : "red" }}
            />
            {hookData[hdKey].hookName}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const mapStateToProps = (state, ownProps) => ({
  lineId: ownProps.lineId,
  hookData: getHookDataByLineIdSel(state, ownProps.lineId),
  summary: getHookDataByLineIdSummarySel(state, ownProps.lineId),
  handleMenuClick: ownProps.handleMenuClick,
});

const HooksAdminMenu = connect(mapStateToProps)(HooksAdminMenuInner);

function getIconClassFromIconState(iconState) {
  const iconMap = {
    "s-2": "question", // unknown state
    "s-1": "stop", // all errors
    s0: "warning", // some errors
    s1: "thunderbolt", // all good
  };
  const theIcon = iconMap["s" + iconState];
  return theIcon;
}
