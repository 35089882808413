import React from "react";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import Tabs from "antd/lib/tabs";
import Table from "antd/lib/table";
import Tooltip from "antd/lib/tooltip";
import Popover from "antd/lib/popover";
import InputNumber from "antd/lib/input-number";
import Input from "antd/lib/input";

import "./ClientDefaults.scss";

import { getHumanFriendlyLabel } from "../../utils/StringUtils";
import { bpServiceStatic } from "../../pages/bp/BPService";
import MetaListEditor from "../../projects/projectView/projectSettings/MetaSettings/MetaListEditor2";
import { ColumnSettings } from "../../utils/Settings";
import ValidatorWrapper from "./ValidatorWrapper";
import { notify } from "../../shared/notifications/Notify";

const { TabPane } = Tabs;

const DXInt = {
  D0: 0,
  D1: 1,
  D2: 2
};

class ClientDefaults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamic0: [],
      dynamic1: [],
      dynamic2: [],
      isLoading: true,
      isSaving: false,
      openLists: false,
      listsIsLoading: false,
      listToEdit: [],
      listToEditRecord: {},
      listName: "",
      defaultPageSize: 20
    };
    this.edits = {
      dynamic0: this.resetEdits(),
      dynamic1: this.resetEdits(),
      dynamic2: this.resetEdits()
    };
    this.viewWeights = {
      dynamic0: {},
      dynamic1: {},
      dynamic2: {}
    };
    this.views = {
      dynamic0: {},
      dynamic1: {},
      dynamic2: {}
    };
    this.viewDetailsByName = {
      dynamic0: {},
      dynamic1: {},
      dynamic2: {}
    };
    this.didEdit = {
      dynamic0: false,
      dynamic1: false,
      dynamic2: false
    };
    this.lists = [];
    this.listsRaw = {};
    this.metasDX = [];
  }
  extractViews = views => {
    const dynamic0 = views.find(
      v => v.type === "dynamic1" && v.viewName === "AllColumns"
    );
    const dynamic1 = views.find(
      v => v.type === "dynamic2" && v.viewName === "AllColumns"
    );
    const dynamic2 = views.find(
      v => v.type === "dynamic3" && v.viewName === "AllColumns"
    );
    return { dynamic0, dynamic1, dynamic2 };
  };
  extractDetailsByName = view => {
    const retVal = {};
    view.details.forEach(d => {
      retVal[d.field] = d;
    });
    return retVal;
  };

  /**
   * Does *NOT* return
   * @param {array} views all vies
   */
  doListsCalcs(views) {
    console.log("[ClientDefaults.jsx] views", views);
    this.views = this.extractViews(views);
    this.viewDetailsByName.dynamic0 = this.extractDetailsByName(
      this.views.dynamic0
    );
    this.viewDetailsByName.dynamic1 = this.extractDetailsByName(
      this.views.dynamic1
    );
    this.viewDetailsByName.dynamic2 = this.extractDetailsByName(
      this.views.dynamic2
    );

    console.log(
      "[ClientDefaults.jsx] this.viewDetailsByName",
      this.viewDetailsByName
    );
  }

  componentDidMount() {
    bpServiceStatic.loadDefaultMetas(
      this.props.clientId,
      (views, metasDX, lists, listsRaw) => {
        this.doListsCalcs(views);

        this.lists = lists;
        this.listsRaw = listsRaw;
        this.metasDX = metasDX;

        const dynamic0 = this.makeEntrySingle(
          metasDX,
          lists,
          this.viewDetailsByName,
          DXInt.D0
        );

        const dynamic1 = this.makeEntrySingle(
          metasDX,
          lists,
          this.viewDetailsByName,
          DXInt.D1
        );
        const dynamic2 = this.makeEntrySingle(
          metasDX,
          lists,
          this.viewDetailsByName,
          DXInt.D2
        );

        this.setState({ dynamic0, dynamic1, dynamic2, isLoading: false });
      }
    );
  }
  resetEdits = () => {
    return {
      titles: {},
      formulas: {},
      tooltips: {},
      json: {},
      formats: {},
      weight: {}
    };
  };
  onListSave = (vals, old) => {
    if (!vals && !old) {
      return;
    }
    this.setState({ listsIsLoading: true });
    const update = { ...this.listsRaw };
    const listNumber = old[0].listNumber;
    let newList = old.map(elem => {
      if (elem._isNew) {
        let dup = { ...elem, ...vals[elem.id] };
        delete dup.id;
        delete dup._isNew;
        return dup;
      }
      return { ...elem, ...vals[elem.id] };
    });

    update.lists[listNumber] = newList;

    bpServiceStatic.updateDefaultList(
      this.props.clientId,
      update,
      (listsArray, listsRaw) => {
        this.listsRaw = listsRaw;
        const Xnum = parseInt(this.state.listToEditRecord.dynamicX.substr(-1));

        const dynamicXGen = this.makeEntrySingle(
          this.metasDX,
          listsArray,
          this.viewDetailsByName,
          Xnum
        );

        if (Xnum === DXInt.D0) {
          this.setState({ dynamic0: dynamicXGen });
        }
        if (Xnum === DXInt.D1) {
          this.setState({ dynamic1: dynamicXGen });
        }
        if (Xnum === DXInt.D2) {
          this.setState({ dynamic2: dynamicXGen });
        }

        this.setState({
          isLoading: false,
          listsIsLoading: false,
          openLists: false
        });
      }
    );
  };
  saveViews(next) {
    // console.log("[ClientDefaults.jsx] this.views", this.views);
    // console.log("[ClientDefaults.jsx] this.viewWeights", this.viewWeights);

    this.updateSingle("dynamic0");
    this.updateSingle("dynamic1");
    this.updateSingle("dynamic2");

    // console.log("[ClientDefaults.jsx] this.views updated", this.views);

    // console.log("[ClientDefaults.jsx] edtis", this.edits);
    // console.log("[ClientDefaults.jsx] dynamic1", this.state.dynamic1);

    // console.log(
    //   "[ClientDefaults.jsx] findMaxWeight1",
    //   this.findMaxWeight("dynamic1")
    // );
    // console.log(
    //   "[ClientDefaults.jsx] findMaxWeight2",
    //   this.findMaxWeight("dynamic2")
    // );
    // console.log(
    //   "[ClientDefaults.jsx] findMaxWeight3",
    //   this.findMaxWeight("dynamic3")
    // );

    const data = [
      { ...this.views.dynamic0 },
      { ...this.views.dynamic1 },
      { ...this.views.dynamic2 }
    ];

    bpServiceStatic.saveDefaultViews(this.props.clientId, data, dataBack => {
      // console.log("[ClientDefaults.jsx] dataBack", dataBack);
      this.doListsCalcs(dataBack);
      next();
    });
  }
  /**
   * Does in place, returns nothing!!
   */
  updateSingle = dynamicX => {
    Object.keys(this.edits[dynamicX].weight).forEach(key => {
      const weight = this.edits[dynamicX].weight[key];
      const idx = this.views[dynamicX].details.findIndex(x => x.field === key);
      if (idx === -1) {
        // Need to check if title is set so no empty vals would be added
        // can come from edits, or from initial values
        let title = this.edits[dynamicX].titles[key] || "";
        if (!title) {
          const needle = this.state[dynamicX].find(k => k.name === key);
          if (needle) {
            title = needle.title;
          }
        }

        if (title.trim().length > 0 && weight >= 0) {
          const copy = { ...this.views[dynamicX].details[0] };
          delete copy.id;
          copy.field = key;
          copy.weight = weight;
          this.views[dynamicX].details.push(copy);
        }
      } else {
        const copy = this.views[dynamicX].details[idx]; // copy of details whole single row object
        copy.weight = weight; // here is the weight in number
        if (weight === -1) {
          copy.delete = true;
          copy.weight = 0;
        }
        this.views[dynamicX].details[idx] = copy;
      }
    });
  };

  findMaxWeight(dynamicX) {
    return Math.max(...this.views[dynamicX].details.map(o => o.weight), 0);
  }

  addListRow = () => {
    this.setState({ listsIsLoading: true });
    const type = this.state.listToEditRecord.dynamicX;
    const typeIdx = type.substr(-1);
    const typeIdxInt = parseInt(typeIdx) + 1;
    const typeNew = "dynamic" + typeIdxInt;

    const name = this.state.listToEditRecord.name;
    const meta1 = this.lists.find(ls => ls.type === typeNew);
    const listNumber = meta1.meta[name];

    const zero = { ...this.state.listToEdit[0] };
    zero.id = +new Date();
    zero._isNew = true;
    zero.externalId = "";
    zero.externalValue = 0;
    zero.externalValue2 = 0;
    zero.listValue = "";
    zero.listNumber = listNumber;
    zero.type = typeNew;

    const listToEdit = [...this.state.listToEdit];
    listToEdit.push(zero);
    this.setState({ listsIsLoading: false, listToEdit });
  };
  deleteListRow = id => {
    this.setState({ listsIsLoading: true });
    const listToEditClone = [...this.state.listToEdit];

    const listToEdit = listToEditClone.map(ls => {
      if (ls.id === id) {
        return { ...ls, delete: true };
      }
      return { ...ls };
    });

    this.setState({ listsIsLoading: false, listToEdit });
  };
  enableSaveButton() {
    return (
      this.didEdit.dynamic1 || this.didEdit.dynamic2 || this.didEdit.dynamic3
    );
  }
  onSave = () => {
    this.setState({ isLoading: true });
    const YES_ADMIN = true;
    this.saveViews(() => {
      if (
        ![
          this.didEdit.dynamic0,
          this.didEdit.dynamic1,
          this.didEdit.dynamic2
        ].includes(true)
      ) {
        this.setState({ isLoading: false });
        return;
      }

      if (this.didEdit.dynamic0) {
        const toUpFin = this.mergeData(
          this.state.dynamic0,
          this.edits.dynamic0,
          0
        );
        bpServiceStatic.updateMetasDX(
          this.props.clientId,
          toUpFin,
          1, // diff index
          dataBack => {
            this.metasDX[DXInt.D0] = dataBack;
            const dynamicXGen = this.makeEntrySingle(
              this.metasDX,
              this.lists,
              this.viewDetailsByName,
              DXInt.D0
            );

            notify("Creative level meta data was updated");
            this.setState({ dynamic0: dynamicXGen, isLoading: false });
            this.didEdit.dynamic0 = false;
            this.edits.dynamic0 = this.resetEdits();
          },
          err => {
            console.log("[ClientDefaults.jsx] err", err);
          },
          YES_ADMIN
        );
      }

      if (this.didEdit.dynamic1) {
        const toUpFin = this.mergeData(
          this.state.dynamic1,
          this.edits.dynamic1,
          1
        );
        bpServiceStatic.updateMetasDX(
          this.props.clientId,
          toUpFin,
          2, // diff index
          dataBack => {
            this.metasDX[DXInt.D1] = dataBack;
            const dynamicXGen = this.makeEntrySingle(
              this.metasDX,
              this.lists,
              this.viewDetailsByName,
              DXInt.D1
            );

            notify("Plan level meta data was updated");
            this.setState({ dynamic1: dynamicXGen, isLoading: false });
            this.didEdit.dynamic1 = false;
            this.edits.dynamic1 = this.resetEdits();
          },
          err => {
            console.log("[ClientDefaults.jsx] err", err);
          },
          YES_ADMIN
        );
      }
      if (this.didEdit.dynamic2) {
        const toUpFin = this.mergeData(
          this.state.dynamic2,
          this.edits.dynamic2,
          2
        );
        bpServiceStatic.updateMetasDX(
          this.props.clientId,
          toUpFin,
          3, // diff index
          dataBack => {
            this.metasDX[DXInt.D2] = dataBack;
            const dynamicXGen = this.makeEntrySingle(
              this.metasDX,
              this.lists,
              this.viewDetailsByName,
              DXInt.D2
            );

            notify("Placement level meta data was updated");
            this.setState({ dynamic2: dynamicXGen, isLoading: false });
            this.didEdit.dynamic2 = false;
            this.edits.dynamic2 = this.resetEdits();
          },
          err => {
            console.log("[ClientDefaults.jsx] err", err);
          },
          YES_ADMIN
        );
      }
    });
  };
  /**
   * Returns `-1` if field is not in the view.
   */
  getSingleWeight = (key, viewDetails, dynamicX) => {
    let dynPlus = dynamicX;
    let weight = -1;
    if (viewDetails[dynPlus] && viewDetails[dynPlus][key]) {
      const det = viewDetails[dynPlus][key];
      if (det) {
        weight = det.weight;
      }
    }
    return weight;
  };

  makeEntrySingle = (metasDX, lists, viewDetails, dynamicX) => {
    const dynamicXReturn = [];
    const dynamicWord = "dynamic" + dynamicX;
    const allowedPrefixes = [
      "text",
      "int",
      "float",
      "list",
      "date",
      "bool",
      "file",
      "smf"
    ];

    Object.keys(metasDX[dynamicX].titles).forEach(key => {
      if (
        allowedPrefixes.includes(key.split("Column")[0]) ||
        key === "smfDataGroupId"
      ) {
        let list = [];
        const listId = lists[dynamicX].meta[key];
        if (listId) {
          list = lists[dynamicX].lists[listId];
        }
        let _settings;
        if (metasDX[dynamicX].json) {
          _settings = new ColumnSettings(metasDX[dynamicX].json[key]);
        } else {
          _settings = new ColumnSettings();
        }

        const weight = this.getSingleWeight(key, viewDetails, dynamicWord);

        dynamicXReturn.push({
          dynamicX: dynamicWord,
          key: key,
          name: key,
          weight: weight,
          title: metasDX[dynamicX].titles[key],
          label: metasDX[dynamicX].titles[key],
          niceLabel: getHumanFriendlyLabel(key),
          format: metasDX[dynamicX].formats[key],
          formula: metasDX[dynamicX].formulas[key],
          tooltip: metasDX[dynamicX].tooltips[key],
          isList: key.indexOf("list") === 0,
          _settings,
          list: list
        });
      }
    });

    return dynamicXReturn;
  };

  // makeEntry = (metasDX, lists, viewDetails, dynamicX = 0) => {
  //   const dynamic1 = [];
  //   const dynamic2 = [];
  //   const dynamic3 = [];
  //   const allowedPrefixes = [
  //     "text",
  //     "int",
  //     "float",
  //     "list",
  //     "date",
  //     "bool",
  //     "file",
  //     "smf"
  //   ];
  //   // if (dynamicX === 0 || dynamicX === 1) {
  //   if (dynamicX === DXInt.D1) {
  //     Object.keys(metasDX[DXInt.D1].titles).forEach(key => {
  //       if (
  //         allowedPrefixes.includes(key.split("Column")[0]) ||
  //         key === "smfDataGroupId"
  //       ) {
  //         let list = [];
  //         const listId = lists[0].meta[key];
  //         if (listId) {
  //           list = lists[0].lists[listId];
  //         }
  //         let _settings;
  //         if (metasDX[DXInt.D1].json) {
  //           _settings = new ColumnSettings(metasDX[DXInt.D1].json[key]);
  //         } else {
  //           _settings = new ColumnSettings();
  //         }

  //         const weight = this.getSingleWeight(key, viewDetails, "dynamic1");

  //         dynamic1.push({
  //           dynamicX: "dynamic1",
  //           key: key,
  //           name: key,
  //           weight: weight,
  //           title: metasDX[DXInt.D1].titles[key],
  //           label: metasDX[DXInt.D1].titles[key],
  //           niceLabel: getHumanFriendlyLabel(key),
  //           format: metasDX[DXInt.D1].formats[key],
  //           formula: metasDX[DXInt.D1].formulas[key],
  //           tooltip: metasDX[DXInt.D1].tooltips[key],
  //           isList: key.indexOf("list") === 0,
  //           _settings,
  //           list: list
  //         });
  //       }
  //     });
  //   }
  //   // if (dynamicX === 0 || dynamicX === 2) {
  //   if (dynamicX === DXInt.D2) {
  //     Object.keys(metasDX[DXInt.D2].titles).forEach(key => {
  //       if (
  //         allowedPrefixes.includes(key.split("Column")[0]) ||
  //         key === "smfDataGroupId"
  //       ) {
  //         let list = [];
  //         const listId = lists[0].meta[key];
  //         if (listId) {
  //           list = lists[0].lists[listId];
  //         }
  //         let _settings;
  //         if (metasDX[DXInt.D2].json) {
  //           _settings = new ColumnSettings(metasDX[DXInt.D2].json[key]);
  //         } else {
  //           _settings = new ColumnSettings();
  //         }

  //         const weight = this.getSingleWeight(key, viewDetails, "dynamic2");
  //         dynamic2.push({
  //           dynamicX: "dynamic2",
  //           key: key,
  //           name: key,
  //           weight: weight,
  //           title: metasDX[DXInt.D2].titles[key],
  //           label: metasDX[DXInt.D2].titles[key],
  //           niceLabel: getHumanFriendlyLabel(key),
  //           format: metasDX[DXInt.D2].formats[key],
  //           formula: metasDX[DXInt.D2].formulas[key],
  //           tooltip: metasDX[DXInt.D2].tooltips[key],
  //           isList: key.indexOf("list") === 0,
  //           _settings,
  //           list: list
  //         });
  //       }
  //     });
  //   }
  //   // if (dynamicX === 0 || dynamicX === 3) {
  //   if (dynamicX === DXInt.D3) {
  //     Object.keys(metasDX[DXInt.D3].titles).forEach(key => {
  //       if (
  //         allowedPrefixes.includes(key.split("Column")[0]) ||
  //         key === "smfDataGroupId"
  //       ) {
  //         let list = [];
  //         const listId = lists[0].meta[key];
  //         if (listId) {
  //           list = lists[0].lists[listId];
  //         }
  //         let _settings;
  //         if (metasDX[DXInt.D3].json) {
  //           _settings = new ColumnSettings(metasDX[DXInt.D3].json[key]);
  //         } else {
  //           _settings = new ColumnSettings();
  //         }

  //         const weight = this.getSingleWeight(key, viewDetails, "dynamic3");
  //         dynamic3.push({
  //           dynamicX: "dynamic3",
  //           key: key,
  //           name: key,
  //           weight: weight,
  //           title: metasDX[DXInt.D3].titles[key],
  //           label: metasDX[DXInt.D3].titles[key],
  //           niceLabel: getHumanFriendlyLabel(key),
  //           format: metasDX[DXInt.D3].formats[key],
  //           formula: metasDX[DXInt.D3].formulas[key],
  //           tooltip: metasDX[DXInt.D3].tooltips[key],
  //           isList: key.indexOf("list") === 0,
  //           _settings,
  //           list: list
  //         });
  //       }
  //     });
  //   }
  //   return { dynamic1, dynamic2, dynamic3 };
  // };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      defaultPageSize: pagination.pageSize,
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  onTabActivate = () => {};
  onEdit = (table, record, value) => {
    if (table === "titles") {
      value = value.replace(".", "").replace(",", "");
    }
    //TODO: add this to check for % symbols
    // if (table === "formats") {
    //   if (value.indexOf("%") === 0) {
    //     value = value.replace(".", "").replace(",", "");
    //   }
    // }
    // if (table === "weight") {
    //   this.viewWeights[record.dynamicX][record.name] = value;
    // }
    this.edits[record.dynamicX][table][record.name] = value;
    this.didEdit[record.dynamicX] = true;

    //TODO: update state something like this on blur event
    // const st = [...this.state.dynamic1];
    // const idx = st.findIndex(x => x.key === record.name);
    // st[idx] = { ...st[idx], title: value };
    // this.setState({ dynamic1: st });
  };
  blurEvent = () => {
    console.log("[ClientDefaults.jsx] blur", 1);
  };
  onEditVali = (record, values) => {
    console.log("[ClientDefaults.jsx] record valiasd", record, values);

    const settings = record._settings;
    if (values.checked) {
      settings.set("rules", {
        [record.name]: ["required"]
      });
      settings.set("rules_meta", {
        [record.name]: values.color
      });
    } else {
      settings.set("rules", {});
      settings.set("rules_meta", {});
    }
    console.log("[ClientDefaults.jsx] record.dynamicX", record.dynamicX);
    this.edits[record.dynamicX]["json"][record.name] = settings.stringify();
    this.didEdit[record.dynamicX] = true;
  };
  noEdit = key => {
    const no = ["list", "bool", "file"];
    return no.includes(key.split("Column")[0]);
  };
  openLists = record => {
    let filteredLists = [];
    const typeFix = "dynamic" + (parseInt(record.dynamicX.substr(-1)) + 1);
    if (record.list) {
      filteredLists = record.list.filter(l => l.type === typeFix);
    }

    this.setState({
      openLists: true,
      listToEdit: filteredLists,
      listToEditRecord: record,
      listName: record.title
    });
  };
  mergeData(stateDynamicX, editsDynamicX, levelInt) {
    const toUp1 = this.resetEdits();
    const oldJson = this.metasDX[levelInt].json;
    stateDynamicX.forEach(line => {
      toUp1.titles[line.key] = line.title;
      toUp1.formulas[line.key] = line.formula;
      toUp1.tooltips[line.key] = line.tooltip;
      toUp1.formats[line.key] = line.format;
      toUp1.json[line.key] = line.json || oldJson[line.key] || "{}";
    });
    const toUp1Fin = {
      titles: { ...toUp1.titles, ...editsDynamicX.titles },
      formulas: { ...toUp1.formulas, ...editsDynamicX.formulas },
      tooltips: { ...toUp1.tooltips, ...editsDynamicX.tooltips },
      formats: { ...toUp1.formats, ...editsDynamicX.formats },
      json: { ...toUp1.json, ...editsDynamicX.json }
    };
    return toUp1Fin;
  }

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "Name",
        dataIndex: "niceLabel",
        key: "name",
        width: "10%",
        filters: [
          { text: "Text", value: "text" },
          { text: "List", value: "list" },
          { text: "Whole No", value: "int" },
          { text: "Decimal No", value: "float" },
          { text: "File", value: "file" },
          { text: "Checkbox", value: "boo" },
          { text: "KPI", value: "smfDataGroupId" }
        ],
        filteredValue: filteredInfo.key || null,
        onFilter: (value, record) => {
          // console.log("[ClientDefaults.jsx] on filter", record, value);
          return record.key.indexOf(value) === 0;
        }
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (text, record) => (
          <Input
            defaultValue={record.title}
            onChange={e => {
              this.onEdit("titles", record, e.target.value);
            }}
            onBlur={() => {
              this.blurEvent();
            }}
          />
        ),
        filteredValue: filteredInfo.title || null,
        filters: [
          { text: "Show only used", value: 1 },
          { text: "Show empty", value: 0 }
        ],
        onFilter: (value, record) => {
          const title = record.title ? record.title : "";
          if (value === 1) {
            return title.trim().length !== 0;
          }
          if (value === 0) {
            return title.trim().length === 0;
          }
        },
        sorter: (a, b) => {
          const titleA = a.title ? a.title : "";
          const titleB = b.title ? b.title : "";
          if (titleA === "") return 1;
          if (titleB === "") return -1;
          return titleA.localeCompare(titleB);
        },
        sortDirections: ["descend", "ascend"],
        defaultSortOrder: "ascend"
      },
      {
        title: "Formula",
        dataIndex: "formula",
        key: "formula",
        render: (text, record) => (
          <Input
            defaultValue={record.formula}
            onChange={e => {
              this.onEdit("formulas", record, e.target.value);
            }}
            onBlur={() => {
              this.blurEvent();
            }}
            disabled={this.noEdit(record.name)}
          />
        )
      },
      {
        title: "Format",
        dataIndex: "format",
        key: "format",
        width: "20%",
        render: (text, record) => (
          <Input
            defaultValue={record.format}
            onChange={e => {
              this.onEdit("formats", record, e.target.value);
            }}
            onBlur={() => {
              this.blurEvent();
            }}
            disabled={this.noEdit(record.name)}
          />
        )
      },
      {
        title: "Views Weight",
        key: "views",
        width: "10%",
        sorter: (a, b) => {
          if (a.weight === -1) return 1;
          if (b.weight === -1) return -1;
          return a.weight - b.weight;
        },
        sortDirections: ["descend", "ascend"],
        defaultSortOrder: "descend",
        render: (text, record) => (
          <Tooltip title="Deprecated, use view editor to reorder/add/remove operations.">
            <InputNumber
              disabled
              defaultValue={record.weight}
              min={-1}
              max={1000000}
              step={10}
              onChange={value => {
                this.onEdit("weight", record, value);
              }}
              onBlur={() => {
                this.blurEvent();
              }}
            />
          </Tooltip>
        )
      },
      {
        title: "List",
        dataIndex: "list",
        key: "list",
        render: (text, record) => {
          if (record.isList) {
            // need to filter lists to only show it's lists.
            let filteredLists = [];
            const typeFix =
              "dynamic" + (parseInt(record.dynamicX.substr(-1)) + 1);
            if (record.list) {
              filteredLists = record.list.filter(l => l.type === typeFix);
            }

            const listVal = (
              <ul className="ClientDefaults-lists-ul">
                {record.list &&
                  filteredLists.map(ls => <li key={ls.id}>{ls.listValue}</li>)}
              </ul>
            );
            return (
              <>
                {" "}
                <Popover content={listVal}>
                  <Button onClick={() => this.openLists(record)}>
                    Open List
                  </Button>
                </Popover>{" "}
              </>
            );
          }

          return <></>;
        },
        width: "10%"
      },
      {
        title: "Validation",
        dataIndex: "_settings",
        key: "_settings",
        render: (text, record) => {
          const rules = record._settings.get("rules");
          const rulesMeta = record._settings.get("rules_meta");
          let valiColor = "";
          let valiRequired = false;
          if (Array.isArray(rules[record.name])) {
            const rulesArray = rules[record.name];
            if (rulesArray[0] === "required") {
              valiRequired = true;
              valiColor = rulesMeta[record.name];
            }
          }

          return (
            <div className="flex">
              <ValidatorWrapper
                color={valiColor}
                isChecked={valiRequired}
                onCheck={(checked, color) => {
                  this.onEditVali(record, { checked, color });
                }}
              />
            </div>
          );
        }
      }
      // , Do we need this?
      // {
      //   title: "Tooltip",
      //   dataIndex: "tooltip",
      //   key: "tooltip",
      //   render: (text, record) => (
      //     <Input
      //       defaultValue={record.tooltip}
      //       onChange={e => {
      //         this.onEdit("tooltip", record.name, e.target.value);
      //       }}
      //     />
      //   )
      // }
    ];
    return (
      <div className="ClientDefaults">
        <div className="grid-x">
          <div className="cell small-12">
            <div className="tabs">
              <Tabs
                defaultActiveKey="dynamic0"
                onChange={this.onTabActivate}
                tabPosition="left"
              >
                <TabPane tab="Plan" key="dynamic0">
                  <Table
                    loading={this.state.isLoading}
                    dataSource={this.state.dynamic0}
                    columns={columns}
                    onChange={this.handleChange}
                    footer={() => {
                      return (
                        <div className="save-footer">
                          <Button
                            loading={this.state.isSaving}
                            onClick={() => this.onSave()}
                            size="large"
                          >
                            Save
                          </Button>
                        </div>
                      );
                    }}
                    pagination={{
                      defaultPageSize: this.state.defaultPageSize,
                      pageSizeOptions: ["20", "50", "100", "200", "300"],
                      showSizeChanger: true
                    }}
                  />
                </TabPane>
                <TabPane tab="Placement" key="dynamic1">
                  <Table
                    loading={this.state.isLoading}
                    dataSource={this.state.dynamic1}
                    columns={columns}
                    footer={() => {
                      return (
                        <div className="save-footer">
                          <Button
                            loading={this.state.isSaving}
                            onClick={() => this.onSave()}
                            size="large"
                          >
                            Save
                          </Button>
                        </div>
                      );
                    }}
                    pagination={{
                      defaultPageSize: this.state.defaultPageSize,
                      pageSizeOptions: ["20", "50", "100", "200", "300"],
                      showSizeChanger: true
                    }}
                  />
                </TabPane>
                <TabPane tab="Creative" key="dynamic2">
                  <Table
                    loading={this.state.isLoading}
                    dataSource={this.state.dynamic2}
                    columns={columns}
                    footer={() => {
                      return (
                        <div className="save-footer">
                          <Button
                            loading={this.state.isSaving}
                            onClick={() => this.onSave()}
                            size="large"
                          >
                            Save
                          </Button>
                        </div>
                      );
                    }}
                    pagination={{
                      defaultPageSize: this.state.defaultPageSize,
                      pageSizeOptions: ["20", "50", "100", "200", "300"],
                      showSizeChanger: true
                    }}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <MetaListEditor
          fieldLabel={this.state.listName}
          open={this.state.openLists}
          list={this.state.listToEdit}
          listsRaw={this.lists}
          isLoading={this.state.listsIsLoading}
          onSave={(vals, old) => {
            if (!vals) this.setState({ openLists: false });
            this.onListSave(vals, old);
          }}
          addRow={() => {
            this.addListRow();
          }}
          deleteRow={id => {
            this.deleteListRow(id);
          }}
        />
      </div>
    );
  }
}

export default ClientDefaults;

ClientDefaults.props = {
  clientId: PropTypes.number.isRequired
};
