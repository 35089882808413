import React from 'react';
import Select from 'react-select2';
import PropTypes from 'prop-types';
import {
  formatGroupLabel,
  SingleValue,
  Option,
  makeOptions,
} from './FasthooksUtils';
import FasthooksBody from './FasthooksBody';
import { hash } from '../../utils/hash';

class FasthooksSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
      groupedOptions: [],
      eventsAndHooks: [],
      selectedHook: {},
      selectedEvent: {},
    };
    this.backup = {};
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (hash(prevState.eventsAndHooks) !== hash(nextProps.eventsAndHooks)) {
      return {
        eventsAndHooks: nextProps.eventsAndHooks,
      };
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (hash(prevState.eventsAndHooks) !== hash(this.state.eventsAndHooks)) {
      const options = makeOptions(this.state.eventsAndHooks);
      this.setState({ groupedOptions: options });
    }
  }
  doSelect(obj) {
    const needle1 = this.state.groupedOptions.find(
      op => op.eventId === obj.eventId
    );
    const needle2 = needle1.options.find(op => op.label === obj.name);
    this.setState({ selectedOption: needle2 });
    this.handleChange(needle2);
  }
  doReset() {
    this.setState({ selectedOption: '', selectedHook: {} });
  }
  handleChange(selected) {
    let willBeLabel = selected.label;
    if (selected) {
      const event = selected._meta.event;
      const hook = selected._meta.hook;
      this.setState(
        {
          selectedOption: selected,
          eventnameDirty: false,
          eventname: willBeLabel,
          selectedHook: hook,
          selectedEvent: event,
        },
        () => {
          this.props.onSelected(event, hook);
        }
      );
    }
  }
  onValuesChange(data) {
    this.props.onValuesCB(data);
  }
  render() {
    return (
      <div className="FasthooksSelection">
        <div className="grid-x">
          <div className="cell small-12">
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isSearchable={true}
              options={this.state.groupedOptions}
              formatGroupLabel={formatGroupLabel}
              value={this.state.selectedOption}
              onChange={sel => this.handleChange(sel)}
              components={{ SingleValue, Option }}
              noOptionsMessage={() => {
                return 'Loading...';
              }}
            />
            <FasthooksBody
              hook={this.state.selectedHook}
              valuesCallback={data => this.onValuesChange(data)}
              selectedEvent={this.state.selectedEvent}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FasthooksSelection;

FasthooksSelection.propTypes = {
  eventsAndHooks: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  onValuesCB: PropTypes.func.isRequired,
};
