import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import logoImg from '../logo.svg';
import "./Footer.scss";
import { SPECIAL_CLASSES } from "../Constants";
import Version from "../version/Version";
import initVersions from "../../utils/VersionUtils";

class Footer extends Component {
  componentDidMount() {
    if (!this.props.logged) {
      initVersions();
    }
  }

  render() {
    return (
      <div
        className={`Footer ${SPECIAL_CLASSES.FOOTER} is-logged-${this.props.logged}`}
      >
        <div className="tnc-links">
          <ul className="menu simple align-center ">
            <li>
              <Link
                to={`${this.props.logged ? "/ampinfo" : "/info"}?section=tnc`}
              >
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link
                to={`${this.props.logged ? "/ampinfo" : "/info"}?section=pp`}
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to={`${this.props.logged ? "/ampinfo" : "/info"}?section=su`}
              >
                Support
              </Link>
            </li>
            <li>
              <Link
                to={`${this.props.logged ? "/ampinfo" : "/info"}?section=cu`}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        {this.props.logged ? (
          <div className="logo align-center">
            {/* <ul className="menu align-center">
            <li>
              <img alt="logo" src={logoImg} />
            </li>
          </ul> */}
          </div>
        ) : (
          <div className="all-rights">
            ©2019 Fastloop Inc. All rights reserved.{" "}
            <Version tiny={!this.props.logged} />{" "}
          </div>
        )}
        {this.props.logged ? (
          <div className="version-outer">
            <Version />
          </div>
        ) : null}
      </div>
    );
  }
}

export default Footer;

Footer.defaultProps = {
  logged: false
};

Footer.propTypes = {
  logged: PropTypes.bool
};
