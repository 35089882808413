import React from "react";
import PropTypes from "prop-types";
import "./ProjectAdvertiserNotes.scss";

const ProjectAdvertiserNotes = ({ notes, showLoading, projectId }) => (
  <div className="ProjectAdvertiserNotes">
    <div className="headline">
      <h6>Advertiser Notes</h6> &nbsp;&nbsp;
    </div>
    <div className={`ld-over ${showLoading ? "running" : "not-running"}`}>
      <span className="ld ld-ring ld-spin" />
      {notes.map((obj) => (
        <div className="grid-x line" key={obj.note.id}>
          <div className="cell small-12">{obj.note.note}</div>
        </div>
      ))}
    </div>
  </div>
);

export default ProjectAdvertiserNotes;

ProjectAdvertiserNotes.defaultProps = {
  showLoading: false,
};

ProjectAdvertiserNotes.propTypes = {
  notes: PropTypes.array.isRequired,
  projectId: PropTypes.number.isRequired,
  showLoading: PropTypes.bool,
};
