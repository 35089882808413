import React from "react";
import PropTypes from "prop-types";
import Tooltip from "antd/lib/tooltip";
import Icon from "antd/lib/icon";
import Checkbox from "antd/lib/checkbox";

import Button from "@material-ui/core/Button";
import {
  getViewById,
  updateMetaSingeDX,
  getViewsByIdMapped,
  getAllCols
} from "../../../pages/bp/BPViewService";
import SaveButton from "../../../shared/saveWithLoadingBtn/SaveWithLoadingBtn";
import MetaFieldList from "./MetaFieldList";
import SupportedFormulasList from "./SupportedFormulasList";
import { commonMetasEditorCloseBound } from "../../../actions/commonDataActionsUtils";
import MetaList from "./MetasLists";
import ConstantsList from "./ConstantsList";
import { checkBrackets } from "../../../utils/Utils";
import { translateFormulaDX } from "./MetasEditorTranslator";
import "./MetasEditor.scss";
import eventManager from "../../../shared/EventManager";
import { MEDIAPLAN_RECALC } from "../../../types/types";
import { notify } from "../../../shared/notifications/Notify";
import { BP_LEVELS_FOR_HUMANS_BY_INT } from "../../../shared/Constants";
import { IconByLevel } from "../../../shared/iconComponents/IconComp";
import { isAdmin } from "../../../auth/AuthService";
import { ColumnSettings } from "../../../utils/Settings";

class MetasEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      saveSuccess: false,
      saveLoading: false,
      errorInFormula: false,
      dotDetected: false,
      valiColor: "c1_vali",
      valiRequired: false
    };
    this.projectId = this.props.projectId;
    this.viewName = this.props.viewName;
    this.fieldName = this.props.fieldName;
    this.lastCursorPosition = { start: 0, end: 0 };
    this.isList = this.props.fieldName.indexOf("list") === 0;

    this.noEdit =
      this.props.fieldName.indexOf("list") === 0 ||
      this.props.fieldName.indexOf("bool") === 0 ||
      this.props.fieldName.indexOf("date") === 0;
    this.metaListInstance = null;
    this.isAdmin = isAdmin();
  }

  componentDidMount() {
    console.log("[MetasEditor.jsx] fieldName", this.props);
    // this.view = getView(this.viewName, this.projectId);
    this.view = getViewById(this.props.viewId);
    console.log("the.view", this.view);
    this.viewByName = getViewsByIdMapped(this.props.viewId);
    this.allColsD1 = getAllCols(this.projectId, "dynamic1");
    this.allColsD2 = getAllCols(this.projectId, "dynamic2");
    this.allColsD3 = getAllCols(this.projectId, "dynamic3");
    this.allColsObj = {
      D1: this.allColsD1,
      D2: this.allColsD2,
      D3: this.allColsD3
    };
    console.log("[MetasEditor.jsx] this.allColsObj", this.allColsObj);
    console.log("[MetasEditor.jsx] this.viewByName ", this.viewByName);
    const fieldInView = this.viewByName[this.fieldName] || {};
    this.fieldInView = fieldInView;

    if (fieldInView.formula) {
      fieldInView.formulaNice = this.translateFormula(
        true,
        fieldInView.formula
      );
    }
    this.viewSorted = this.sortViewFields(this.view);

    fieldInView._settings = new ColumnSettings(fieldInView.json);
    const rules = fieldInView._settings.get("rules");
    const rulesMeta = fieldInView._settings.get("rules_meta");
    let valiColor = "";
    let valiRequired = false;
    if (Array.isArray(rules[this.fieldName])) {
      const rulesArray = rules[this.fieldName];
      if (rulesArray[0] === "required") {
        valiRequired = true;
        valiColor = rulesMeta[this.fieldName];
      }
    }

    this.setState({ fieldInView, loaded: true, valiRequired, valiColor });
  }

  onChangeHandler(item, event) {
    const rawClone = { ...this.state.fieldInView };
    const key = event.target.name;
    let val = event.target.value;
    let dotDetected = false;
    if (key === "label") {
      if (val.indexOf(".") > -1) {
        dotDetected = true;
      }
      val = val.replace(".", "");
    }
    rawClone[key] = val;
    let isBracketValid = true;
    if (key === "formulaNice") {
      rawClone.formula = this.translateFormula(false, val);
      isBracketValid = checkBrackets(rawClone.formula);
    }
    this.setState({
      fieldInView: rawClone,
      errorInFormula: !isBracketValid,
      dotDetected
    });
  }

  onSave(calc, close) {
    this.closeForLists = close;
    let vals = this.state.fieldInView;
    // We don't need = in front, backend adds it
    if (vals.formula[0] === "=") {
      vals.formula = vals.formula.substr(1);
    }
    // Sheets don't allow single quotations, so we replacing all to double.
    vals.formula = vals.formula.replace(/'/g, '"');

    const settings = vals._settings;
    if (this.state.valiRequired) {
      settings.set("rules", {
        [vals.name]: ["required"]
      });
      settings.set("rules_meta", {
        [vals.name]: "c1_vali"
      });
    } else {
      settings.set("rules", {});
      settings.set("rules_meta", {});
    }
    vals.json = settings.stringify();

    this.setState({ saveLoading: true });
    updateMetaSingeDX(
      this.projectId,
      vals,
      this.props.dynamicLevelInt,
      () => {
        notify(`Data was saved!`);
        if (this.isList) {
          this.metaListInstance.onSave();
        } else {
          this.setState({ saveLoading: false, saveSuccess: true });
          if (calc) {
            eventManager.emit(MEDIAPLAN_RECALC, {
              dynamicLevelInt: this.props.dynamicLevelInt
            });
          }
          if (close) {
            console.log("[MetasEditor.jsx] do close", 1);
            commonMetasEditorCloseBound();
          }
        }
      },
      () => {
        this.setState({ saveLoading: false, saveSuccess: false });
      }
    );
  }

  onSavedList() {
    this.setState({ saveLoading: false, saveSuccess: true }, () => {
      if (this.closeForLists) {
        console.log("[MetasEditor.jsx] do close", this.closeForLists);
        commonMetasEditorCloseBound();
      }
    });
  }

  onFailedList() {
    this.setState({ saveLoading: false, saveSuccess: false });
  }

  onCancel = () => {
    commonMetasEditorCloseBound();
  };

  onCursor(event) {
    if (event && event.target) {
      this.lastCursorPosition = {
        start: event.target.selectionStart,
        end: event.target.selectionEnd
      };
    }
  }

  insertField = (dx, field) => {
    const rawClone = { ...this.state.fieldInView };
    const formulaClone = rawClone.formulaNice || "";
    const cutter0 = formulaClone.substr(0, this.lastCursorPosition.start);
    const cutter1 = formulaClone.substr(
      this.lastCursorPosition.end,
      formulaClone.length + 1
    );
    rawClone.formulaNice = `${cutter0}[${dx.name}.${field.label}]${cutter1}`;
    rawClone.formula = this.translateFormula(false, rawClone.formulaNice);
    const isBracketValid = checkBrackets(rawClone.formula);
    this.setState({
      fieldInView: rawClone,
      errorInFormula: !isBracketValid
    });
  };

  translateFormula(forHumans, formula) {
    const ret = translateFormulaDX(
      forHumans,
      formula,
      this.allColsObj,
      this.props.dynamicLevelInt
    );
    this.setState({
      errorInFormula: ret.hasError
    });
    return forHumans ? ret.human : ret.robot;
  }

  openSupportedFormulas(e) {
    this.setState({ supportedFormulasAnchorEl: e.currentTarget });
  }

  closeSupportedFormulas() {
    this.setState({ supportedFormulasAnchorEl: null });
  }

  sortViewFields = view => {
    const retVal = view.sort((a, b) => a.label.localeCompare(b.label));
    return retVal;
  };

  showDotTooltip = active => {
    return (
      <span style={{ color: active ? "red" : "blue" }}>
        <Tooltip
          title="Dots '.' are not allowed!"
          getPopupContainer={trigger => trigger.parentNode}
        >
          <Icon type="notification" color="red" />
        </Tooltip>
      </span>
    );
  };

  showFormatLink = () => {
    return (
      <span className="format-icon">
        <a target="_new" href="https://customformats.com/">
          <Icon type="question-circle" color="rebeccapurple" />
        </a>
      </span>
    );
  };
  onCheckbox(name, e) {
    console.log("[MetasEditor.jsx] checkbo", name, e.target.checked);
    this.setState({ valiRequired: e.target.checked });
  }
  onColorSelected(color) {
    console.log("[MetasEditor.jsx] color", color);
    this.setState({ valiColor: color });
  }
  render() {
    const name =
      BP_LEVELS_FOR_HUMANS_BY_INT[this.props.dynamicLevelInt.toString()];
    // const errorColor = this.state.valiColor;
    const requiredChecked = this.state.valiRequired;
    return (
      <div data-loaded={this.state.loaded} className="MetasEditor">
        {this.state.loaded && (
          <div>
            <div className="grid-x">
              <div className="cell small-1">
                Label: {this.showDotTooltip(this.state.dotDetected)}
              </div>
              <div className="cell small-5 cell-label">
                <span className="level">
                  {" "}
                  <IconByLevel
                    level={Number(this.props.dynamicLevelInt)}
                    w={16}
                    h={16}
                  />{" "}
                  {name}
                </span>
                <input
                  type="text"
                  name="label"
                  value={this.state.fieldInView.label}
                  onChange={e => this.onChangeHandler("label", e)}
                />
              </div>
              <div className="cell small-1">
                <div className="align-center flex-container">
                  Format: {this.showFormatLink()}{" "}
                </div>
              </div>
              <div className="cell small-5">
                <input
                  disabled={this.noEdit}
                  type="text"
                  name="format"
                  value={this.state.fieldInView.format}
                  onChange={e => this.onChangeHandler("format", e)}
                />
              </div>
            </div>
            <div className="grid-x">
              <div className="cell small-1"> Tooltip:</div>
              <div className="cell small-5">
                <input
                  type="text"
                  name="tooltip"
                  value={this.state.fieldInView.tooltip}
                  onChange={e => this.onChangeHandler("tooltip", e)}
                />
              </div>
              <div className="cell small-1">
                <div className="align-center flex-container validation">
                  Validation:
                </div>
              </div>
              <div className="cell small-5">
                <Checkbox
                  onChange={e => this.onCheckbox("required", e)}
                  defaultChecked={requiredChecked}
                >
                  Required
                </Checkbox>
                {/* Removed for release, will be added later */}
                {/* <ErrorColorsSelector
                  onColorSelected={c => this.onColorSelected(c)}
                  selectedValue={errorColor}
                  disabled={!this.state.valiRequired}
                /> */}
              </div>
            </div>
            {!this.noEdit && (
              <div className="formulas-area">
                <div className="grid-x">
                  <div className="cell small-1"> Formula:</div>
                  <div className="cell small-11">
                    <textarea
                      data-has-error={this.state.errorInFormula}
                      rows={5}
                      name="formulaNice"
                      value={this.state.fieldInView.formulaNice}
                      onChange={e => this.onChangeHandler("formulaNice", e)}
                      onKeyUp={e => this.onCursor(e)}
                      onClick={e => this.onCursor(e)}
                    />
                  </div>
                </div>
                <div className="grid-x">
                  <div className="cell small-1"> Fields:</div>
                  <div className="cell small-11">
                    <div>
                      <MetaFieldList
                        allColsObj={this.allColsObj}
                        onClick={(dx, f) => this.insertField(dx, f)}
                        dynamicLevelInt={this.props.dynamicLevelInt}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="cell small-1"> Formula For Robots:</div>
                  <div className="cell small-11">
                    <textarea
                      rows={5}
                      name="formula"
                      readOnly
                      value={this.state.fieldInView.formula}
                    />
                  </div>
                </div>
                <div className="grid-x">
                  <div className="cell small-1">Constants</div>
                  <div className="cell small-11">
                    <ConstantsList projectId={Number(this.projectId)} />
                  </div>
                </div>
                <div className="grid-x">
                  <div className="cell small-1" />
                  <div className="cell small-11">
                    <Button onClick={e => this.openSupportedFormulas(e)}>
                      Show Supported Formulas
                    </Button>
                    {this.state.supportedFormulasAnchorEl && (
                      <SupportedFormulasList
                        handleClose={() => this.closeSupportedFormulas()}
                        anchorEl={this.state.supportedFormulasAnchorEl}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {this.isList && (
              <MetaList
                projectId={this.projectId}
                field={this.fieldInView}
                onSave={() => {}}
                onSaved={() => this.onSavedList()}
                onFailed={() => this.onFailedList()}
                dynamicLevelInt={this.props.dynamicLevelInt}
                ref={instance => {
                  this.metaListInstance = instance;
                }}
              />
            )}
            <div className="grid-x align-right">
              <div className="cell small-12">
                <div className="align-right flex-container">
                  {this.isAdmin && (
                    <>
                      <SaveButton
                        label="Save Calculate Close!"
                        onSave={() => this.onSave(true, true)}
                        success={this.state.saveSuccess}
                        disable={this.state.errorInFormula}
                        loading={this.state.saveLoading}
                      />
                      <SaveButton
                        label="Save"
                        onSave={() => this.onSave(false, false)}
                        success={this.state.saveSuccess}
                        disable={this.state.errorInFormula}
                        loading={this.state.saveLoading}
                      />
                    </>
                  )}
                  <Button onClick={() => this.onCancel()}>Close</Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MetasEditor;

MetasEditor.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  viewName: PropTypes.string.isRequired,
  viewId: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  dynamicLevelInt: PropTypes.number.isRequired
};
