import React from "react";
import PropTypes from "prop-types";
import Switch from "antd/lib/switch";
import Radio from "antd/lib/radio";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";
import "./BPJoinedControls.scss";

import {
  PlanIconComp,
  PlacementIconComp,
  CreativeIconComp,
} from "../../../shared/iconComponents/IconComp";

import { BP_LEVELS_FOR_HUMANS_BY_INT } from "../../../shared/Constants";

const BPJoinedControls = ({
  filterChange,
  massDeleteChange,
  fullTextChange,
  onDXChange,
  dxSelected,
  onExport,
}) => (
  <div className="BPJoinedControls">
    <div className="actions-row">
      <div className="names"></div>
      <div className="middle"></div>
      <div className="toggles">
        <div className="expo"></div>
        <div className="dx-selector">
          <Radio.Group
            size="default"
            defaultValue="1"
            buttonStyle="solid"
            onChange={(e) => onDXChange(e.target.value)}
          >
            <Radio.Button value="1">
              {BP_LEVELS_FOR_HUMANS_BY_INT[1]}{" "}
              <PlanIconComp
                w={14}
                h={14}
                fill={dxSelected === 1 ? "white" : "black"}
              />
            </Radio.Button>
            <Radio.Button value="2">
              {BP_LEVELS_FOR_HUMANS_BY_INT[2]}
              <PlacementIconComp
                w={14}
                h={14}
                fill={dxSelected === 2 ? "white" : "black"}
              />
            </Radio.Button>
            <Radio.Button value="3">
              {BP_LEVELS_FOR_HUMANS_BY_INT[3]}
              <CreativeIconComp
                w={14}
                h={14}
                fill={dxSelected === 3 ? "white" : "black"}
              />
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="actions">
          <Button onClick={() => onExport()}>
            CSV Export
            <Icon type="file-excel" />
          </Button>
        </div>
        <div className="cellx switches">
          <Switch size="small" onChange={(val) => fullTextChange(val)} /> Full
          Text
        </div>
      </div>
    </div>
  </div>
);

export default BPJoinedControls;

BPJoinedControls.defaultProps = {
  filterChange: () => {},
  massDeleteChange: () => {},
  dxSelected: 1,
};

BPJoinedControls.propTypes = {
  filterChange: PropTypes.func,
  massDeleteChange: PropTypes.func,
  fullTextChange: PropTypes.func.isRequired,
  onDXChange: PropTypes.func.isRequired,
  dxSelected: PropTypes.number,
};
