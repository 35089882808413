import reduxStore from '../reduxStore';
import { sendNotification, SUBTYPES } from '../actions/notificationActions';

const Notify = {
  fireError: (msg) => {
    reduxStore.dispatch(sendNotification(msg, SUBTYPES.ERROR));
  },
  fireWarn: (msg) => {
    reduxStore.dispatch(sendNotification(msg, SUBTYPES.WARN));
  },
  fireInfo: (msg) => {
    reduxStore.dispatch(sendNotification(msg, SUBTYPES.INFO));
  },
};

export default Notify;
