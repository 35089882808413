import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "../MainNavView.scss";
import CompanyAndUser from "../../../shared/companyAndUser/CompanyAndUser";
import { getParamAfterSlug } from "../../../utils/URLUtils";
import ProjectsService, { getClientHelper } from "../../ProjectsService";
import ProjectViewMetricHolder from "../../projectView/projectViewMetric/ProjectViewMetricHolder";
import EventManager from "../../../shared/EventManager";
import { navigationActionBound } from "../../../pages/bp/bpUtils";

class ProjectNavView extends Component {
  constructor(props) {
    super(props);
    this.projectsService = new ProjectsService();
    this.state = {
      projectId: 0,
      apiLoaded: false,
      client: "Loading...",
      name: "",
      docket: "Loading...",
      project: {},
      section: "overview",
      statsClass: "",
    };
  }

  componentDidMount() {
    /** this is kind of dumb, but can't thinkg of better way to get selected section,
     * cuz this compoenent is not reloaded on navigation, only reloeded on refresh page. */
    let section = getParamAfterSlug("projects", 2) || this.state.section;
    this.projectId = getParamAfterSlug("projects");
    navigationActionBound(null, null, this.projectId);
    this.setState({ section, projectId: this.projectId });

    // if navigation was triggered by <Redirect> directive, we need to highlight new
    // route
    EventManager.on(EventManager.TYPES_MAP.NAVIGATION.RELOAD, () => {
      section = getParamAfterSlug("projects", 2);
      this.setState({ section });
    });
    EventManager.on(EventManager.TYPES_MAP.PROJECT.ONLOAD, (data) => {
      const client = getClientHelper(data);
      let statsClass = "";
      if (data.reportingToken.length > 0) {
        statsClass = "";
      }
      this.setState({
        project: data,
        client,
        name: data.name,
        docket: data.docket,
        apiLoaded: true,
        statsClass,
      });
    });
  }

  onClickHandler = (section) => {
    this.setState({ section });
  };

  render() {
    const isActive = (section) => {
      if (this.state.section === section) {
        return "active";
      }
      return "";
    };
    // const showMetris = !this.props.forBP;
    const showMetris = false;
    return (
      <div
        className="MainNavView ProjectNavView"
        data-api-loaded={this.state.apiLoaded}
      >
        <div className="grid-container full">
          <div className="grid-x projects-header">
            <div className="cell small-9">
              <h6>
                <span className="u-gray">{this.state.client}</span>
                {"  "}
                {this.state.name}{" "}
                <span className="docket-code">{this.state.docket}</span>
              </h6>
            </div>
            <div className="cell small-3">
              <CompanyAndUser />
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              <div className="top-bar">
                <div className="top-bar-left">
                  <ul className="menu">
                    <li className="overview">
                      <Link
                        className={isActive("overview")}
                        onClick={() => this.onClickHandler("overview")}
                        to={`/projects/${this.state.projectId}/`}
                      >
                        Overview
                      </Link>
                    </li>
                    <li className="bp">
                      <Link
                        className={isActive("bp")}
                        onClick={() => this.onClickHandler("bp")}
                        to={`/projects/${this.state.projectId}/bp/`}
                      >
                        Media Plan
                      </Link>
                    </li>
                    <li className="m">
                      <Link
                        className={isActive("flat")}
                        onClick={() => this.onClickHandler("flat")}
                        to={`/projects/${this.state.projectId}/flat/`}
                      >
                        Media Plan Flat View
                      </Link>
                    </li>
                    <li className="reports">
                      <Link
                        className={`${isActive("reports")}`}
                        onClick={() => this.onClickHandler("reports")}
                        to={`/projects/${this.state.projectId}/reports/`}
                      >
                        Files
                      </Link>
                    </li>
                    <li className="activity">
                      <Link
                        className={isActive("activity")}
                        onClick={() => this.onClickHandler("activity")}
                        to={`/projects/${this.state.projectId}/activity/`}
                      >
                        Activity
                      </Link>
                    </li>
                    <li className="stats">
                      <Link
                        className={isActive("stats")}
                        onClick={() => this.onClickHandler("stats")}
                        to={`/projects/${this.state.projectId}/stats/`}
                      >
                        Data Visualizations
                      </Link>
                    </li>
                    <li className="settings">
                      <Link
                        className={isActive("settings")}
                        onClick={() => this.onClickHandler("settings")}
                        to={`/projects/${this.state.projectId}/settings/`}
                      >
                        Settings
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="top-bar-right">
                  {/* <span className="docket-code docket-code-right">{this.state.docket}</span> */}
                </div>
              </div>
            </div>
          </div>
          {showMetris ? (
            <ProjectViewMetricHolder project={this.state.project} />
          ) : null}
        </div>
      </div>
    );
  }
}

export default ProjectNavView;

ProjectNavView.defaultProps = {
  forBP: false,
};

ProjectNavView.propTypes = {
  forBP: PropTypes.bool,
};
