/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React from "react";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import Tabs from "antd/lib/tabs";
import Transfer from "antd/lib/transfer";
import Icon from "antd/lib/icon";
import Popconfirm from "antd/lib/popconfirm";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import Spin from "antd/lib/spin";
import ReactDragListView from "react-drag-listview";
import message from "antd/lib/message";
import { devModeStatic } from "../../utils/DevMode";
import { errorLog } from "../../utils/ErrorTracker";
import CustomViewSelector from "./CustomViewSelector";
import { isAdmin } from "../../auth/AuthService";
// import CustomViewSingleCell from './CustomViewSingleCell';
// import CustomViewMoveArrows from './CustomViewMoveArrows';
import {
  initViews,
  getViewBySortedByCols,
  getViewsMetaInfo,
  updateViewsRaw,
  updateMetaSingeDX,
  deleteMetaSingeDX,
  deleteViewsRaw,
  checkIfFieldReferencedInFormula,
} from "../../pages/bp/BPViewService";
import CustomViewsNewField from "./CustomViewsNewField";
import { BPVIEWS } from "../../shared/Constants";
import { getFilters } from "../../pages/bp/bpUtils";
import { bpServiceStatic } from "../../pages/bp/BPService";
import "./CustomViews.scss";
import {
  createNewViewsD123,
  sortByLabel,
  getViewsByName,
  viewSearchFn,
  filterOption,
  calcFields,
} from "./CustomViewsUtils";

import CustomViewsName from "./CustomViewsName";
import { UserSettings } from "../../utils/UserSettings";
import { notify } from "../../shared/notifications/Notify";

const { TabPane } = Tabs;
const confirm = Modal.confirm;

const STYLES = {
  list: {
    width: 350,
    height: 650,
  },
  editIcon: { float: "right", paddingRight: "10px" },
};

const TITLES = ["Available", "In View"];

class CustomViews extends React.Component {
  constructor(props) {
    super(props);
    this.isAdmin = isAdmin();
    this.state = {
      projectId: this.props.projectId,
      loading: true,
      viewsList: [],
      selectedViewObject: {},
      viewDetailsToShow: [],
      viewDetailsToChoose: [],
      showNewViewDialog: false,
      showNewFieldDialog: false,
      viewName: "",
      viewNameEdited: "",
      d1: [],
      d2: [],
      d3: [],
      d1Selected: [],
      d2Selected: [],
      d3Selected: [],
      showNewFieldDialogFreeFields: [],
      isDevMode: devModeStatic.isDevMode(),
      editNameModal: false,
      nameListCleanArray: [],
      showPublicButton: this.isAdmin,
    };
    this.viewInProgress = {};
    this.allColumnsByKey = {};
    this.views = {};
    this.notUsed = [];
    this.selectedMarker = {
      removeSide: [],
      addSide: [],
    };
    this.brandNewFields = {};
    this.activeTab = "dynamic1";
    this.userSettings = new UserSettings();
  }

  componentDidMount() {
    this.onInitViews();
  }
  onCloseView = () => {
    this.props.onClose();
  };

  onTabActivate = (tab) => {
    this.activeTab = tab;
  };

  onInitViews = (cb, selectedNameForce = false) => {
    this.setState({ loading: true });
    initViews(this.props.projectId, () => {
      this.reloadValues(this.props.projectId);

      let selectedViewObject = {};
      if (this.props.selectedViewName.length > 0) {
        selectedViewObject.value = this.props.selectedViewName;
        selectedViewObject.label = this.props.selectedViewName;
      }
      if (selectedNameForce) {
        selectedViewObject.value = selectedNameForce;
        selectedViewObject.label = selectedNameForce;
      }

      this.setState({ selectedViewObject });
      if (cb) {
        cb();
      }
      setTimeout(() => {
        this.onListSelect(selectedViewObject);
      }, 1000);
    });
  };

  reloadValues = () => {
    this.setState({ loading: false });
    this.views = getViewsMetaInfo(this.props.projectId);
    this.allViewsD1 = getViewBySortedByCols(
      BPVIEWS.VIEWS.ALL1COLUMNS,
      this.props.projectId
    );
    this.allViewsD2 = getViewBySortedByCols(
      BPVIEWS.VIEWS.ALL2COLUMNS,
      this.props.projectId
    );
    this.allViewsD3 = getViewBySortedByCols(
      BPVIEWS.VIEWS.ALL3COLUMNS,
      this.props.projectId
    );

    this.allViewsD1Sorted = sortByLabel(this.allViewsD1);
    this.allViewsD2Sorted = sortByLabel(this.allViewsD2);
    this.allViewsD3Sorted = sortByLabel(this.allViewsD3);
    this.setViewsList();
    // this.getViewByName('default');
  };
  setViewsList() {
    const uniqViews = new Set();
    this.views.forEach((v) => {
      uniqViews.add(v.viewName);
    });

    const viewsList = [];
    const nameListCleanArray = [];

    uniqViews.forEach((v) => {
      const vClean = v
        .trim()
        .replace("(private)", "")
        .trim();
      nameListCleanArray.push(vClean);
      if (!BPVIEWS.DO_NOT_SHOW_VIEWS.includes(v)) {
        viewsList.push({
          label: v,
          value: v,
        });
      }
    });
    this.setState({ viewsList, nameListCleanArray });
  }

  getViewByName = (name) => {
    this.setState({ loading: true });
    const allViewsSortedArr = [
      this.allViewsD1Sorted,
      this.allViewsD2Sorted,
      this.allViewsD3Sorted,
    ];
    const {
      d1,
      d1Selected,
      inSelectedViewD1,
      d2,
      d2Selected,
      inSelectedViewD2,
      d3,
      d3Selected,
      inSelectedViewD3,
    } = getViewsByName(name, this.views, allViewsSortedArr);

    if (inSelectedViewD1._new) {
      this.views.push(inSelectedViewD1);
    }
    if (inSelectedViewD2._new) {
      this.views.push(inSelectedViewD2);
    }
    if (inSelectedViewD3._new) {
      this.views.push(inSelectedViewD3);
    }

    this.setState({
      d1,
      d1Selected,
      inSelectedViewD1,
      d2,
      d2Selected,
      inSelectedViewD2,
      d3,
      d3Selected,
      inSelectedViewD3,
      viewName: name,
      viewNameEdited: name,
      loading: false,
    });
  };

  onListSelect(val) {
    this.getViewByName(val.value);
    console.log("[CustomViews2.jsx] selectedViewObject", val);

    const cloneD1 = this.views.find((v) =>
      viewSearchFn(v, val.value, "dynamic1")
    );

    const cloneD2 = this.views.find((v) =>
      viewSearchFn(v, val.value, "dynamic2")
    );

    const cloneD3 = this.views.find((v) =>
      viewSearchFn(v, val.value, "dynamic3")
    );

    this.setState({
      selectedViewObject: val,
      cloneD1Id: cloneD1.id,
      cloneD2Id: cloneD2.id,
      cloneD3Id: cloneD3.id,
    });
  }

  handleChangeD1 = (targetKeys, direction, moveKeys) => {
    this.handleChangeD123(targetKeys, direction, moveKeys, "d1Selected");
  };
  handleChangeD2 = (targetKeys, direction, moveKeys) => {
    this.handleChangeD123(targetKeys, direction, moveKeys, "d2Selected");
  };
  handleChangeD3 = (targetKeys, direction, moveKeys) => {
    this.handleChangeD123(targetKeys, direction, moveKeys, "d3Selected");
  };

  handleChangeD123 = (targetKeys, direction, moveKeys, varName) => {
    // if we just use targetKeys it would prepend array.
    let merged = [];
    if (direction === "right") {
      merged = [...this.state[varName], ...moveKeys];
    } else {
      merged = [...targetKeys];
    }

    this.setState({ [varName]: merged }, () => {
      console.log(
        "[CustomViews2.jsx] this.state.d1Selected",
        this.state.d1Selected
      );
    });
  };

  onDeleteView = () => {
    this.setState({ loading: true });
    const cloneD1 = this.views.find((v) =>
      viewSearchFn(v, this.state.viewName, "dynamic1")
    );

    const cloneD2 = this.views.find((v) =>
      viewSearchFn(v, this.state.viewName, "dynamic2")
    );

    const cloneD3 = this.views.find((v) =>
      viewSearchFn(v, this.state.viewName, "dynamic3")
    );

    cloneD1.delete = true;
    cloneD2.delete = true;
    cloneD3.delete = true;

    deleteViewsRaw(
      this.props.projectId,
      [cloneD1, cloneD2, cloneD3],
      () => {
        notify(`View '${cloneD1.viewName}' was deleted!`);
        this.props.onClose();
      },
      () => {}
    );
  };
  onSaveAsPublic = () => {
    this.setState({ loading: true });

    console.log(
      "[CustomViews2.jsx] this.state.inSelectedViewD1;",
      this.state.inSelectedViewD1.id,
      this.state.inSelectedViewD2.id,
      this.state.inSelectedViewD3.id
    );
    const data = [
      this.state.inSelectedViewD1.id,
      this.state.inSelectedViewD2.id,
      this.state.inSelectedViewD3.id,
    ];

    bpServiceStatic.saveAsPublicViews(
      this.props.projectId,
      data,
      (dataBack) => {
        notify(`View '${this.state.inSelectedViewD1.viewName}' made public!`);
        this.setState({
          loading: false,
        });
        this.onInitViews(() => {
          this.props.onClose();
        });
      }
    );
  };
  onSaveView = (saveFilter, closeAfterSave = false) => {
    this.setState({ loading: true });
    const currentFilters = getFilters();

    const cloneD1 = this.views.find((v) =>
      viewSearchFn(v, this.state.viewName, "dynamic1")
    );

    const cloneD2 = this.views.find((v) =>
      viewSearchFn(v, this.state.viewName, "dynamic2")
    );

    const cloneD3 = this.views.find((v) =>
      viewSearchFn(v, this.state.viewName, "dynamic3")
    );

    if (!cloneD1 || !cloneD2 || !cloneD3) {
      const ser = JSON.stringify(this.views);
      errorLog("views are failing" + ser);
      console.log(
        "[CustomViews2.jsx] this.state.d1Selected ",
        this.state.d1Selected,
        this.state.d2Selected,
        this.state.d3Selected
      );

      notify(
        `View failed to save`,
        "Something went wrong, view is not saved please reload the page and try again. Details sent to Administrator",
        "error"
      );
    }

    const newD1Details = calcFields(cloneD1, this.state.d1Selected, "dynamic1");
    const newD2Details = calcFields(cloneD2, this.state.d2Selected, "dynamic2");
    const newD3Details = calcFields(cloneD3, this.state.d3Selected, "dynamic3");

    const viewOutD1 = this.state.inSelectedViewD1;
    viewOutD1.details = newD1Details;
    const viewOutD2 = this.state.inSelectedViewD2;
    viewOutD2.details = newD2Details;
    const viewOutD3 = this.state.inSelectedViewD3;
    viewOutD3.details = newD3Details;
    const newName = this.state.viewNameEdited.replace("(private)", "");

    viewOutD1.viewName = `${newName} (private)`;
    viewOutD2.viewName = `${newName} (private)`;
    viewOutD3.viewName = `${newName} (private)`;

    if (saveFilter) {
      const filterD1 = currentFilters["dynamic1"];
      const filterD2 = currentFilters["dynamic2"];
      const filterD3 = currentFilters["dynamic3"];
      viewOutD1.settings = JSON.stringify({ filter: filterD1 });
      viewOutD2.settings = JSON.stringify({ filter: filterD2 });
      viewOutD3.settings = JSON.stringify({ filter: filterD3 });
    }

    const viewsOutArray = [viewOutD1, viewOutD2, viewOutD3];

    this.userSettings.resetViewsOrder(
      this.props.projectId,
      viewsOutArray[0].id
    );

    updateViewsRaw(
      this.props.projectId,
      viewsOutArray,
      (data) => {
        this.onInitViews(() => {
          notify(`View '${viewOutD1.viewName}' saved!`);
          if (closeAfterSave) {
            setTimeout(() => {
              this.onCloseView();
            }, 10);
          } else {
            console.log("[CustomViews2.jsx] realod", 111);
            this.reloadValues();
          }
        }, viewOutD1.viewName);
      },
      () => {}
    );
  };

  onEdit = (e, it) => {
    e.stopPropagation();

    return showEditModal(it, (newName) => {
      this.setState({ loading: true });
      this.onNewField(newName, it.key);
    });
  };

  onDelete = (e, it) => {
    e.stopPropagation();
    let levelInt = 1;
    if (this.activeTab === "dynamic1") {
      levelInt = 1;
    }
    if (this.activeTab === "dynamic2") {
      levelInt = 2;
    }
    if (this.activeTab === "dynamic3") {
      levelInt = 3;
    }
    const found = checkIfFieldReferencedInFormula(
      this.props.projectId,
      it.key,
      levelInt
    );
    if (it.rest.formula.trim().length > 1 || found) {
      message.error(
        "Field has or is used in the formula. Please remove all references before using deleting.",
        5 // seconds
      );
    } else {
      this.setState({ loading: true });
      deleteMetaSingeDX(this.props.projectId, it.key, levelInt, () => {
        this.onInitViews();
        this.toggleNewFieldDialog(false);
      });
    }
  };

  renderLine = (item) => {
    return (
      <span>
        <span> {item.title} </span>
        <Icon
          type="delete"
          style={STYLES.editIcon}
          onClick={(e) => this.onDelete(e, item)}
        />
        <Icon
          style={STYLES.editIcon}
          onClick={(e) => this.onEdit(e, item)}
          type="edit"
        />
      </span>
    );
  };
  toggleNewViewDialog(open) {
    this.setState({ showNewViewDialog: open });
  }

  toggleNewFieldDialog(open) {
    const freeFields = [];
    let stack = {};

    if (this.activeTab === "dynamic1") {
      stack = this.allViewsD1;
    }
    if (this.activeTab === "dynamic2") {
      stack = this.allViewsD2;
    }
    if (this.activeTab === "dynamic3") {
      stack = this.allViewsD3;
    }

    Object.values(stack).forEach((s) => {
      if (s.label.trim() === "") {
        freeFields.push(s.name);
      }
    });

    this.setState({
      showNewFieldDialog: open,
      showNewFieldDialogFreeFields: freeFields,
    });
  }

  onNewField = (label, fieldName) => {
    let levelInt = 1;
    if (this.activeTab === "dynamic1") {
      levelInt = 1;
    }
    if (this.activeTab === "dynamic2") {
      levelInt = 2;
    }
    if (this.activeTab === "dynamic3") {
      levelInt = 3;
    }
    let metaField = {
      name: fieldName,
      label: label,
    };
    updateMetaSingeDX(this.props.projectId, metaField, levelInt, () => {
      this.onInitViews();
      this.toggleNewFieldDialog(false);
    });
  };

  onNewName(name) {
    if (name === null) {
      this.toggleNewViewDialog(false);
    } else {
      this.makeNewView(name);
      this.toggleNewViewDialog(false);
    }
  }

  makeNewView(name) {
    const newViews = createNewViewsD123(name, 1);
    this.views = [...this.views, ...newViews];
    this.getViewByName(name);

    let selectedViewObject = {};
    selectedViewObject.value = name;
    selectedViewObject.label = name;
    this.setState({
      selectedViewObject,
      cloneD1Id: null,
      cloneD2Id: null,
      cloneD3Id: null,
    });
  }

  getDragProps = (level) => ({
    onDragEnd: (fromIndex, toIndex) => {
      const dXSelected = [...this.state[level]];
      const item = dXSelected.splice(fromIndex, 1)[0];
      dXSelected.splice(toIndex, 0, item);

      this.handleChangeD123(dXSelected, "left", null, level);
    },
    nodeSelector:
      ".ant-transfer-list:last-child .ant-transfer-list-content > div",
  });

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  editName() {
    this.setState({ editNameModal: true });
  }
  renameTheView(name) {
    console.log("[CustomViews2.jsx] save name", name);
    if (name === null) {
      this.setState({ editNameModal: false });
    } else {
      this.setState({ viewNameEdited: name, editNameModal: false });
    }
  }

  render() {
    let cannotBePublic =
      (this.state.inSelectedViewD1 && this.state.inSelectedViewD1._new) ||
      (this.state.inSelectedViewD1 && !this.state.inSelectedViewD1.userId);

    return (
      <div className="CustomViews" id="CustomViews">
        <CustomViewsName
          name={this.state.viewNameEdited}
          rename
          show={this.state.editNameModal}
          nameList={this.state.nameListCleanArray}
          save={(val) => {
            this.renameTheView(val);
          }}
          destroyOnClose
        />
        {/* looks like dupe, but it's for different purpose */}
        <CustomViewsName
          rename={false}
          show={this.state.showNewViewDialog}
          nameList={this.state.nameListCleanArray}
          save={(val) => {
            this.onNewName(val);
          }}
          destroyOnClose
        />
        <Spin tip="Loading..." spinning={this.state.loading}>
          <CustomViewsNewField
            open={this.state.showNewFieldDialog}
            onSave={(fieldName, name) => this.onNewField(fieldName, name)}
            onCancel={() => {
              this.toggleNewFieldDialog(false);
            }}
            freeFields={this.state.showNewFieldDialogFreeFields}
          />
          <div className="grid-x">
            <div className="cell small-7">
              <h6>
                View:
                <span className="view-name">{this.state.viewNameEdited}</span>
                <Button
                  icon="edit"
                  onClick={() => this.editName()}
                  title="Rename"
                />
              </h6>
            </div>
            <div className="cell small-5 ">
              <div className="flex-container align-right">
                <Button onClick={() => this.toggleNewViewDialog(true)}>
                  Create New View
                </Button>
                <CustomViewSelector
                  list={this.state.viewsList}
                  onSelect={(val) => this.onListSelect(val)}
                  selected={this.state.selectedViewObject}
                />
              </div>
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              <div className="tabs">
                <Tabs
                  defaultActiveKey="dynamic1"
                  onChange={this.onTabActivate}
                  tabPosition="left"
                >
                  <TabPane tab="Plan" key="dynamic1">
                    <ReactDragListView {...this.getDragProps("d1Selected")}>
                      <Transfer
                        dataSource={this.state.d1}
                        targetKeys={this.state.d1Selected}
                        showSearch
                        filterOption={filterOption}
                        listStyle={STYLES.list}
                        onChange={this.handleChangeD1}
                        onSelectChange={this.handleSelectChange}
                        render={(item) => this.renderLine(item)}
                        titles={TITLES}
                      />
                    </ReactDragListView>
                  </TabPane>
                  <TabPane tab="Placement" key="dynamic2">
                    <ReactDragListView {...this.getDragProps("d2Selected")}>
                      <Transfer
                        dataSource={this.state.d2}
                        targetKeys={this.state.d2Selected}
                        showSearch
                        filterOption={filterOption}
                        listStyle={STYLES.list}
                        onChange={this.handleChangeD2}
                        onSelectChange={this.handleSelectChange}
                        render={(item) => this.renderLine(item)}
                        titles={TITLES}
                      />
                    </ReactDragListView>
                  </TabPane>
                  <TabPane tab="Creative" key="dynamic3">
                    <ReactDragListView {...this.getDragProps("d3Selected")}>
                      <Transfer
                        dataSource={this.state.d3}
                        targetKeys={this.state.d3Selected}
                        showSearch
                        filterOption={filterOption}
                        listStyle={STYLES.list}
                        onChange={this.handleChangeD3}
                        onSelectChange={this.handleSelectChange}
                        render={(item) => this.renderLine(item)}
                        titles={TITLES}
                      />
                    </ReactDragListView>
                  </TabPane>
                </Tabs>
                <div className="tabs-footer">
                  <Button onClick={() => this.toggleNewFieldDialog(true)}>
                    Add New Field
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-x footer">
            <div className="cell small-7 align-self-left">
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.onDeleteView()}
              >
                <Button href="#" type="danger">
                  Delete View
                </Button>
              </Popconfirm>
            </div>
            <div className="cell small-5 align-self-right">
              <div className="flex-container align-right">
                {this.state.showPublicButton && (
                  <Button
                    type="dashed"
                    onClick={() => this.onSaveAsPublic()}
                    disabled={cannotBePublic}
                  >
                    Save as Public View
                  </Button>
                )}
                <Button onClick={() => this.onSaveView(true)}>
                  Save Filtered View
                </Button>
                <Button onClick={() => this.onSaveView(false, true)}>
                  Save & Close
                </Button>
                <Button
                  title="Save"
                  icon="save"
                  className="icon-btn"
                  onClick={() => this.onSaveView(false)}
                ></Button>
                <Button
                  title="Close"
                  icon="close"
                  className="icon-btn"
                  onClick={() => this.onCloseView()}
                ></Button>
              </div>
            </div>
          </div>

          {this.state.isDevMode && (
            <Button onClick={() => this.componentDidMount()}>reload</Button>
          )}
        </Spin>
      </div>
    );
  }
}

export default CustomViews;

CustomViews.defaultProps = {
  selectedViewName: "",
};

CustomViews.propTypes = {
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  selectedViewName: PropTypes.string,
};

const showEditModal = (field, onConfirm) => {
  let value = field.title;
  const oldTitle = field.title;
  const onChangeHandler = (e) => {
    value = e.target.value;
  };
  return confirm({
    title: <span>Rename "{oldTitle}".</span>,
    zIndex: 2000,
    content: (
      <span>
        <Input
          defaultValue={value}
          onChange={onChangeHandler}
          placeholder="New Name"
        />
      </span>
    ),
    onOk() {
      onConfirm(value);
    },
    onCancel() {},
  });
};
