function IntParser(value) {
  let retVal = "";
  if (value.toLowerCase() === "n/a") {
    return "";
  }

  if (value[0] === "-" && value.length === 1) {
    retVal = "-";
  } else if (value.length === 0) {
    retVal = "";
  } else if (value[0] === "-") {
    retVal = "-" + parseInt(value.substr(1, value.length)).toString();
  } else {
    retVal = parseInt(value).toString();
  }
  if (retVal === "NaN") {
    retVal = "";
  }
  return retVal;
}

function FloatParser(value) {
  let retVal = "";

  if (value === "-.") {
    return "-.";
  }
  if (value === "-") {
    return "-";
  }
  if (value === ".") {
    return "0.";
  }

  if (value.toLowerCase() === "n/a") {
    return "";
  }

  const starts = value.substr(0, 1);
  const ends = value.substr(-1);
  let prefix = "";
  let suffix = "";
  if (starts === "-") {
    value = value.substr(1, value.length);
    prefix = "-";
  }
  if (ends === ".") {
    value = value.substr(0, value.length - 1);
    suffix = ".";
  }
  retVal = parseFloat(value);

  if (isNaN(retVal)) {
    retVal = "";
  }

  return prefix + retVal.toString() + suffix;
}

/**
 * Used to return same value, some components has parser,
 * but it's easer to use this than have IFs.
 * @param {any} value
 */
const EchoParser = (value) => value;
const EchoParserTrim = (value) => {
  try {
    return String(value).trim();
  } catch (error) {
    console.log("[NumberParser.js] cannot trim, not string", value);
  }
  return value;
};

const BoolParser = (value) => {
  const st = String(value).trim();
  if (st.toLowerCase() === "false") {
    return false;
  }
  if (st.toLowerCase() === "0") {
    return false;
  }
  if (st.toLowerCase() === "") {
    return false;
  }
  return true;
}

export { IntParser, FloatParser, EchoParser, EchoParserTrim, BoolParser };

function textToFloatStr(value) {
  return value.replace(/[^\d.-]/g, "");
}

function textToIntStr(value) {
  return value.replace(/[^\d-]/g, "");
}

export { textToFloatStr, textToIntStr };
