import * as types from "../types/types";
import { MAINTENANCE_MODE } from "../shared/Constants";
import { errorLog } from "../utils/ErrorTracker";

const initState = {
  ui: "",
  api: "",
  db: "",
  maintenance: MAINTENANCE_MODE.LOADING
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.VERSION_PING_API_READ:
      let mainStr = "false";
      try {
        mainStr = action.payload.maintenance.toString();
      } catch (error) {
        errorLog(error);
        mainStr = "true";
      }
      // "true" === mainStr, is because we wanna be sure it's really "true"
      const mainMode =
        "true" === mainStr
          ? MAINTENANCE_MODE.ENABLED
          : MAINTENANCE_MODE.DISABLED;
      return {
        ...state,
        api: action.payload.version,
        db: action.payload.db,
        maintenance: mainMode
      };
    case types.VERSION_PING_UI_READ:
      return { ...state, ui: action.payload.version };
    default:
      return state;
  }
};
