import React from 'react';
import './MaintenancePage.scss';
import DogImg from './dog-shower-min.jpg';

const MaintenancePage = () => (
  <div className="MaintenancePage">
    <div>
      <div className="msg">
        <h2>
          The server is under maintenance, please visit again shortly
          <span className="blink">.</span>
          <span className="blink3">.</span>
          <span className="blink2">.</span>
        </h2>
      </div>
      <img src={DogImg} alt="Dog under Maintenance" />
    </div>
  </div>
);
export default MaintenancePage;
