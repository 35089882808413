import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './RecoverPassword.scss';

/**
 * RecoverPasswordForm Component, takes password and returns on submit handler.
 */
class RecoverPasswordForm extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
    };
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
    this.props.innerValue(event.target.value);
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <div className="RecoverPasswordForm">
        <form className="log-in-form">
          <h4 className="text-left">Password Recovery</h4>
          <br />
          <p className="text-left">
            <small className="not-smaller">
              {`Enter the email address associated with this account and we'll send you
              a password recovery by email right away.`}
            </small>
          </p>
          <label htmlFor="email">
            Email
            <input
              type="email"
              name="email"
              id="email"
              value={this.state.email}
              onChange={this.handleEmailChange}
              placeholder="somebody@example.com"
            />
          </label>
          <div className="grid-x">
            <div className="small-12 medium-6 cell">
              <input
                type="button"
                className="button expanded login-btn"
                onClick={this.props.handleSubmit}
                value="Send Recovery Email"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RecoverPasswordForm;

RecoverPasswordForm.propTypes = {
  /**
   * Handler for submit.
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * Input value (email) handler.
   */
  innerValue: PropTypes.func.isRequired,
};
