import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "antd/lib/icon";
import Input from "antd/lib/input";

class NameEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { inEdit: false };
  }
  onDo() {
    this.setState({ inEdit: !this.state.inEdit });
  }
  onChange(value) {
    this.setState({ value });
  }
  onSave() {
    this.props.onSave(this.state.value);
    this.setState({ inEdit: false });
  }
  render() {
    return (
      <span className="NameEdit">
        <span className="name">
          {!this.state.inEdit && this.props.viewName}

          {this.state.inEdit && (
            <Input
              defaultValue={this.props.viewName}
              onPressEnter={() => this.onSave()}
              onChange={e => this.onChange(e.target.value)}
            />
          )}
        </span>
        <Icon
          type={this.state.inEdit ? "save" : "edit"}
          onClick={() => {
            if (this.state.inEdit) {
              this.onSave();
            } else {
              this.onDo();
            }
          }}
        />
      </span>
    );
  }
}

export default NameEdit;

NameEdit.propTypes = {
  viewName: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired
};
