import React from "react";
import PropTypes from "prop-types";
import Tag from "antd/lib/tag";
import Icon from "antd/lib/icon";
import Input from "antd/lib/input";
import invert from "invert-color";

import { bpServiceStatic } from "../BPService";
import { notify } from "../../../shared/notifications/Notify";

import "./BPFileTags.scss";

const TIMEOUT = 10000;
const TRY_MAX = 6; // 1 min

class BPFileTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tags: [], isLoading: true, inputVisible: false };
    this.tryCount = 0;
  }

  componentDidMount() {
    this.loadTags();
  }
  loadTags() {
    console.log("[BPFileTags.jsx] loading tags: ", this.tryCount);
    if (this.props.hash.trim().length > 0) {
      bpServiceStatic.getFileTags(this.props.hash, data => {
        if (Array.isArray(data) && data.length > 0) {
          this.setState({ tags: data, isLoading: false });
        } else {
          if (this.tryCount <= TRY_MAX) {
            this.tryCount = this.tryCount + 1;
            setTimeout(() => {
              this.loadTags();
            }, TIMEOUT); //10s
          } else {
            notify(
              "Tagging took longer than expected.",
              "Please come back later",
              "warning"
            );
          }
        }
      });
    }
  }
  onRM(tag) {
    const rmTag = {
      ...tag,
      delete: true
    };
    bpServiceStatic.postFileTags(this.props.hash, rmTag, () => {});
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    let inputValueTmp = inputValue.trim();
    const found = tags.findIndex(t => t.tag === inputValueTmp);
    if (found === -1) {
      const newTag = { tag: inputValue, category: "manual" };
      if (inputValueTmp && tags.indexOf(inputValueTmp) === -1) {
        tags = [...tags, newTag];
      }
      bpServiceStatic.postFileTags(this.props.hash, [newTag], () => {
        this.setState({
          tags,
          inputVisible: false,
          inputValue: ""
        });
      });
    } else {
      notify("Tag already exists!", "", "warning");
      this.setState({
        tags,
        inputVisible: false,
        inputValue: ""
      });
    }
  };

  saveInputRef = input => (this.input = input);

  render() {
    const { tags, inputVisible, inputValue, isLoading } = this.state;
    return (
      <div className="BPFileTags">
        Image tags:{" "}
        {isLoading && (
          <>
            <Icon type="loading" /> AI in progress, please wait...
          </>
        )}
        {tags.map(tag => {
          let color = "";
          let text = tag.tag;
          let extra = "";
          let textColor = "black";
          try {
            const meta = JSON.parse(tag.meta);
            if (tag.category === "via_colors") {
              color = meta.rgb;
              const tmp = color.replace("rgb(", "").replace(")", "");
              const tmpArr = tmp.split(",");
              textColor = invert(tmpArr, true);
              extra = meta.score;
            }
            if (tag.category === "via_web") {
              extra = meta.score;
              text = tag.tag;
            }
          } catch (error) {
            console.warn("cannot parse json", error);
          }

          return (
            <Tag
              key={tag.tag}
              onClose={() => this.onRM(tag)}
              style={{ backgroundColor: color, color: textColor }}
              closable
            >
              {text} {extra}
            </Tag>
          );
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            className="tag-input"
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} className="new">
            <Icon type="plus" /> New Tag
          </Tag>
        )}
      </div>
    );
  }
}

export default BPFileTags;

BPFileTags.defaultProps = {
  hash: ""
};

BPFileTags.propTypes = {
  hash: PropTypes.string
};
