/* eslint-disable import/prefer-default-export */
import reduxStore from '../reduxStore';
import { busFire } from './eventBusActions';
import { UUID } from '../utils/StringUtils';
/**
 *
 * @param {string} key name of event
 * @param {bool} done start with 0, ends with 1, 2 -err
 * @param {string} uniq id for the event
 */
export const busFireBound = (key, done, uniq, rest = {}) => reduxStore.dispatch(busFire(key, done, uniq, rest));

export const KEYS = {
  PROJECT_CONSTANTS_GET: 'PROJECT_CONSTANTS_GET',
  PROJECT_CONSTANTS_POST: 'PROJECT_CONSTANTS_POST',
  PROJECT_CONSTANTS_DELETE: 'PROJECT_CONSTANTS_DELETE',
};

export const UUIDGen = () => UUID();

export const EvenBusListener = () => {
  const selectBus = state => state.busStore.list.slice(-1)[0];

  let currentValue;
  function handleChange() {
    const previousValue = currentValue;
    currentValue = selectBus(reduxStore.getState());

    if (previousValue !== currentValue) {
      console.log('Some deep nested property changed from', previousValue, 'to', currentValue);
    }
  }
  // const unsubscribe = reduxStore.subscribe(handleChange);
  reduxStore.subscribe(handleChange);
};

// EvenBusListener();
// unsubscribe()
