import React from "react";
import PropTypes from "prop-types";
import Upload from "antd/lib/upload";
import Icon from "antd/lib/icon";
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";
import { DraggableModal } from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import { bpServiceStatic } from "../../bp/BPService";
import BPFileViewer from "./BPFileViewer";
import BPFileTags from "./BPFileTags";
import "./BPFileUploader.scss";

class BPFileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: {}, src: null, uploaded: {} };
  }
  getBtn(viewOnly) {
    if (viewOnly) {
      return (
        <div className="custom-footer">
          <div className="left">
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => this.onOk("delete")}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </div>
          <div className="right"></div>
        </div>
      );
    } else {
      return (
        <div className="custom-footer">
          <div className="left"></div>
          <div className="right">
            <Button onClick={() => this.onOk("save")}>Save</Button>
          </div>
        </div>
      );
    }
  }
  onOk(action) {
    let hash = this.state.uploaded.hash;
    if (action === "delete") {
      hash = "";
    }
    this.setState({ src: "" });
    this.props.onClose(true, { action, hash });
  }
  onCancel() {
    this.setState({ src: "" });
    this.props.onClose(true);
  }
  render() {
    const viewOnly = this.props.data.action === "view";
    return (
      <DraggableModal
        visible={this.props.visible}
        onOk={() => this.onOk()}
        onCancel={() => this.onCancel()}
        title="File Handler"
        footer={this.getBtn(viewOnly)}
        className="BPFileUploader"
      >
        {viewOnly && (
          <>
            <BPFileViewer hash={this.props.data.hash} />
          </>
        )}
        {!viewOnly && (
          <>
            <Upload
              customRequest={crData => {
                this.setState({ file: crData.file });
                bpServiceStatic.uploadFile(
                  crData.file,
                  data => {
                    let src = null;
                    if (crData.file.type.indexOf("image") === 0) {
                      src = URL.createObjectURL(crData.file);
                    }
                    this.setState({ src, uploaded: data });
                    crData.onSuccess("success", crData.file);
                  },
                  err => {
                    console.log("[BPFileUploader.jsx] err", err);
                  }
                );
              }}
            >
              <div>
                <Button>
                  <Icon type="upload" /> Upload
                </Button>
              </div>
            </Upload>
            {this.state.src && <img src={this.state.src} alt="" />}
          </>
        )}
        {this.props.data.hash && <BPFileTags hash={this.props.data.hash} />}
      </DraggableModal>
    );
  }
}

export default BPFileUploader;

BPFileUploader.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};
