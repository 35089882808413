import React from "react";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Icon from "antd/lib/icon";
import Table from "antd/lib/table";
import Alert from "antd/lib/alert";
import Radio from "antd/lib/radio";
import Spin from "antd/lib/spin";

import message from "antd/lib/message";
import "./BPImportCol.scss";
import { BPImportHeader } from "./BPImportHeader";
import {
  formatDataFile,
  makeTableData,
  PARENT_ID,
  PARENT_ID_KEY,
} from "./bpMassImportsUtils";
import { getListForColFactory3 } from "../BPServiceUtils";
import BPImportsUploadCSV from "./BPImportsUploadCSV";
import {
  getDXIdsOnly,
  doIgnoreProjectValidation,
} from "../bpTable/BPTableDataUtils";
import { getProjectByIdSelBound } from "../../../projects/ProjectReduxUtils";

import { getAllCols } from "../../../pages/bp/BPViewService";

import { notify } from "../../../shared/notifications/Notify";
const { TextArea } = Input;

class BPImportMass extends React.Component {
  constructor() {
    super();
    this.state = {
      data1: "",
      type: "override",
      dataSource: [],
      cols: [],
      ready: false,
      missedCounter: 0,
      invalidKeys: new Set(),
      invalidLabels: new Set(),
      invalidLabelColumns: new Set(),
      invalidIds: new Set(),
      theDupes: [],
      delimiter: "auto",
      manualDelimiter: "",
      parsingError: "",
      height: 600,
      idsSetReady: false,
      differentDocketAndIdList: [],
    };
    this.defaultType = "override";
    this.show = {};
    this.importData = [];
    this.type = this.defaultType;
    this.listFn = null;
    this.idsSet = new Set();
    this.isIdsReady = false;
    this.ignoreMalformedIds = false;
  }

  componentDidMount() {
    this.listFn = getListForColFactory3(this.props.projectId, this.props.level);
    this.ignoreMalformedIds = doIgnoreProjectValidation(this.props.projectId);

    getDXIdsOnly(this.props.projectId, this.props.level, (data) => {
      this.idsSet = new Set(data.map((d) => d.textColumn1));
      console.log("[BPImportMass.jsx] data ids", this.idsSet);
      this.isIdsReady = true;
      this.setState({ idsSetReady: true });
    });

    this.allColsView = getAllCols(this.props.projectId, this.props.level);
  }

  save(saveBP) {
    if (this.state.theDupes.length === 0) {
      // TODO: remove here and on bptable
      window.datafix = {
        dataSource: this.state.dataSource,
        cols: this.state.cols,
      };

      const event = new CustomEvent("MASS_IMPORT", {
        detail: {
          level: this.props.level,
          doSave: saveBP,
          data: { dataSource: this.state.dataSource, cols: this.state.cols },
        },
      });
      window.dispatchEvent(event);

      this.props.closeCall();
    } else {
      notify("Error!", "Please duplicated ID(s)!", "error");
    }
  }

  format = () => {
    this.setState({ height: window.innerHeight - 400 });
    if (!this.state.data) return;
    const deli =
      this.state.delimiter === "auto" ? "" : this.state.manualDelimiter;
    const { matrix, errors, meta, infoEmptyColCount } = formatDataFile(
      this.state.data,
      deli
    );
    console.log("[BPImportMass.jsx] matrix", matrix, errors, meta);

    notify(
      "Info",
      `There was ${infoEmptyColCount} column(s) without header. Those will be ignored`,
      "info"
    );

    if (meta.aborted) {
      this.setState({ parsingError: "Import Errors", ready: false });
      return;
    }

    if (errors.length === 1) {
      if (errors[0].code === "UndetectableDelimiter") {
        notify("Note", errors[0].message, "info");
      }
    } else if (errors.length > 1) {
      this.setState({ parsingError: "Import Errors", ready: false });
      return;
    }

    if (matrix.length <= 1) {
      message.error("Please paste at least 2 lines of data");
    } else {
      const {
        error,
        dataSource,
        cols,
        missedCounter,
        invalidKeys,
        invalidLabels,
        invalidLabelColumns,
        invalidIds,
        theDupes,
        differentDocketAndIdList,
      } = makeTableData(
        [...matrix],
        this.allColsView,
        this.listFn,
        this.idsSet,
        this.ignoreMalformedIds,
        this.props.projectId
      );
      this.setState({
        invalidKeys,
        invalidLabels,
        invalidLabelColumns,
        invalidIds,
        theDupes,
        differentDocketAndIdList,
      });

      if (differentDocketAndIdList.length !== 0) {
        const project = getProjectByIdSelBound(this.props.projectId);
        const docket = project.docket;

        notify(
          "Docket and ID Mismatch",
          "Seems that some line ID's do not match the Docket. Docket Short code is " +
            docket,
          "error",
          30
        );
        this.setState({ ready: false });
        return;
      }

      if (error) {
        this.setState({ parsingError: error });
      } else {
        this.setState({ parsingError: "" });

        if (this.props.level === "dynamic1") {
          this.setState({ dataSource, cols, missedCounter, ready: true });
        } else {
          if (
            cols[0].mpKey.trim().toLowerCase() !== PARENT_ID_KEY.toLowerCase()
          ) {
            message.error(
              `For Dynamic2 and Dynamic3 make sure that you have '${PARENT_ID}' as first column`
            );
            this.setState({ ready: false });
          } else {
            this.setState({ dataSource, cols, missedCounter, ready: true });
          }
        }
      }
    }
  };

  handleChange = (e) => {
    this.setState({ data: e.target.value }, () => {
      if (this.didPaste) {
        this.didPaste = false;
        setTimeout(() => {
          this.format();
        }, 300);
      }
    });
  };
  setDelimiter = (e) => {
    this.setState({ delimiter: e });
  };
  setManualDelimiter(manualDelimiter) {
    this.setState({ manualDelimiter });
  }
  onPaste(e) {
    this.didPaste = true;
  }
  onFileUploaded(fileContent) {
    this.setState({ data: fileContent });
    this.setDelimiter("auto");
    setTimeout(() => {
      this.format();
    }, 300);
  }
  render() {
    return (
      <div className="BPImportMass">
        <BPImportHeader
          clickedElement={"multi"}
          level={this.props.level}
          label={"Multi Columns and Lines"}
          notSupported={false}
        />
        {this.props.level === "dynamic1" && (
          <em>
            For D2 imports, remember to make use of the ID column from D1,
            relabeled as "Parent ID". If you want new id generated for the row
            put [gen] as Id.
          </em>
        )}
        <TextArea
          className="text-area"
          onChange={this.handleChange}
          value={this.state.data}
          onPaste={(e) => this.onPaste(e)}
        />
        <div className="top-actions">
          <div className="detector">
            <div>
              <Button
                onClick={this.format}
                type="primary"
                className="btn-margin"
                size="default"
                icon="table"
              >
                Detect and Format
              </Button>
            </div>
            <div className="radio">
              <span>CSV Delimiter:{"  "}</span>
              <Radio.Group
                className="radio-group"
                onChange={(v) => this.setDelimiter(v.target.value)}
                value={this.state.delimiter}
              >
                <Radio value={"auto"}>Auto</Radio>
                <Radio value={"manual"}>
                  Manual{" "}
                  <Input
                    className="manual-delimiter"
                    disabled={this.state.delimiter === "auto"}
                    value={this.state.manualDelimiter}
                    onChange={(e) => this.setManualDelimiter(e.target.value)}
                    maxLength={5}
                  />
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div>
            {this.state.parsingError && (
              <Alert
                message={<>{this.state.parsingError} Please check your data.</>}
                type="error"
                showIcon
              />
            )}
            {(this.state.missedCounter !== 0 ||
              this.state.theDupes.length !== 0 ||
              this.state.invalidKeys.size !== 0 ||
              this.state.invalidIds.size !== 0) &&
              this.state.ready && (
                <Alert
                  message={
                    <>
                      {this.state.missedCounter > 0 && (
                        <>
                          {this.state.missedCounter} column(s) were not mapped!
                          Columns marked with <Icon type="close-circle" /> have
                          unknown values marked in{" "}
                          <span style={{ color: "red" }}>red</span>.
                        </>
                      )}
                      {this.state.invalidKeys.size > 0 && (
                        <>
                          <br />
                          Please rectify the lists in the following columns:{" "}
                          {Array.from(this.state.invalidLabelColumns).map(
                            (c) => (
                              <span key={c}>{c}, </span>
                            )
                          )}{" "}
                          Please add the following values found:{" "}
                          {Array.from(this.state.invalidLabels).map((lbl) => (
                            <span key={lbl}>"{lbl}", </span>
                          ))}{" "}
                          . List values in columns with{" "}
                          <span style={{ color: "darkorange" }}>orange</span>{" "}
                          icons will be skipped.
                        </>
                      )}
                      {this.state.invalidIds.size > 0 && (
                        <>
                          <br />
                          Following Id(s) is not valid{" "}
                          {Array.from(this.state.invalidIds).map((lbl) => (
                            <span key={lbl}>"{lbl}", </span>
                          ))}{" "}
                          <br />
                          Id(s) will be ignored and new generated.
                        </>
                      )}
                      {this.state.theDupes.length > 0 && (
                        <>
                          <br />
                          Following Id(s) are duplicated{" "}
                          {Array.from(this.state.theDupes).map((lbl) => (
                            <span key={lbl}>"{lbl}", </span>
                          ))}{" "}
                          <br />
                          Please fix data before importing.
                        </>
                      )}
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
            {this.state.missedCounter === 0 &&
              this.state.theDupes.length === 0 &&
              this.state.invalidKeys.size === 0 &&
              this.state.invalidIds.size === 0 &&
              this.state.ready && (
                <Alert
                  message={<>Success! All columns mapped.</>}
                  type="success"
                  showIcon
                />
              )}
          </div>
          <div>
            <Spin spinning={!this.state.idsSetReady} tip="Just a sec..">
              <BPImportsUploadCSV onDone={(fc) => this.onFileUploaded(fc)} />
            </Spin>
          </div>
        </div>
        <Table
          dataSource={this.state.dataSource}
          columns={this.state.cols}
          bordered
          size="small"
          tableLayout="fixed"
          pagination={{ pageSize: 100 }}
          scroll={{
            y: this.state.height,
            x: this.state.cols.length * 200 + 100,
          }}
        />

        <div className="bottom-row">
          <div className="note">Column content will be overridden! </div>

          <Button onClick={this.props.closeCall} className="btn-margin">
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => this.save(true)}
            disabled={!this.state.ready}
            className="btn-margin"
          >
            Override And Save
          </Button>
        </div>
      </div>
    );
  }
}

BPImportMass.defaultProps = {
  closeCall: () => {},
};

BPImportMass.propTypes = {
  level: PropTypes.string.isRequired,
  view: PropTypes.array.isRequired,
  viewId: PropTypes.number.isRequired,
  closeCall: PropTypes.func,
  projectId: PropTypes.number.isRequired,
};

export default BPImportMass;
