import React from 'react';
import PropTypes from 'prop-types';

const OrgProfileView = ({ name }) => (
  <div className="OrgProfileView">
    <div>
profile view
      {name}
    </div>
  </div>
);

export default OrgProfileView;

OrgProfileView.propTypes = {
  name: PropTypes.string.isRequired,
};
