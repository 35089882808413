import axios from "axios";
import FileSaver from "file-saver";
import localforage from "localforage";
import {
  commonDataListsBound,
  commonDataListsReloadedBound,
  commonDataMetasBound,
  commonDataViewsBound,
  commonDataViewsReloadedBound,
  commonDataLoadFlagsBound,
} from "../../actions/commonDataActionsUtils";
import { camp } from "../../camp";
import { globalErrorCallback } from "../../utils/ErrorTracker";
import { adjustLandingPagesDataIds } from "./BPServiceUtils";
import { loadTacticsBound, loadTrafficsBound } from "./bpUtils";
import { initViews } from "./BPViewService";
import { mediaPlanLoadedBound } from "../../actions/MediaPlanActionsUtils";

class BPService {
  constructor() {
    this.camp = camp;
    this.cache = localforage.createInstance({
      name: "BPStore",
    });
  }

  downloadExport = (projectDocketId, type, callbackOk, callbackFail) => {
    const fd = axios.interceptors.request.use((config) => {
      if (config.headers.Accept[0] === "application/json, application/csv") {
        /* eslint-disable */
        // because [no-param-reassign]
        config.responseType = "blob";
        if (type === "CSV") {
          config.headers.Accept[0] = "application/csv";
        } else {
          config.headers.Accept[0] = "application/json";
        }
        /* eslint-enable */
      }
      return config;
    });

    const params = {
      id: projectDocketId,
      body: {}, // empyt cuz is empty
    };

    this.camp
      .exportProject(params)
      .then((data) => {
        axios.interceptors.request.eject(fd);

        const reader = new FileReader();
        try {
          reader.onerror = function onerror(error) {
            globalErrorCallback(error, callbackFail);
          };
          reader.onload = function onload() {
            const resp = JSON.parse(reader.result);
            const blob = new Blob([Buffer.from(resp.contents, "base64")], {
              type: resp.type,
            });
            FileSaver.saveAs(blob, resp.name);
          };
          reader.readAsText(data);
          callbackOk();
        } catch (error) {
          globalErrorCallback(error, callbackFail);
        }
      })
      .catch((err) => {
        axios.interceptors.request.eject(fd);
        globalErrorCallback(err, callbackFail);
      });
  };
  filterList(lists, type) {
    const retList = {};
    Object.keys(lists).forEach((lsKey) => {
      retList[lsKey] = lists[lsKey].filter((ls) => ls.type === type);
    });
    return retList;
  }

  /**
   * Makes views Public
   * @param {number} projectId
   * @param {array} viewIds views to sync, should be d1,2,3
   * @param {func} callbackOk
   * @param {func} callbackFail
   */
  saveAsPublicViews(projectId, viewIds, callbackOk, callbackFail) {
    this.camp
      .syncViews({
        id: projectId,
        body: viewIds,
      })
      .then((data) => {
        commonDataLoadFlagsBound("bpView", 1);
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  saveDefaultViews(clientId, data, callbackOk, callbackFail) {
    this.camp
      .putViewsDefaults({ id: clientId, body: data })
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  loadDefaultViews(clientId, callbackOk, callbackFail) {
    const param = { id: clientId };
    this.camp
      .getViewsDefaults(param)
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  /**
   * Returns views, metas, [lists by type, filtered], rawLists
   * @param {*} clientId
   * @param {*} cb
   */
  loadDefaultMetas(clientId, cb) {
    const param = { id: clientId };
    const viewsPromise = this.camp.getViewsDefaults(param);
    const listsPromise = this.camp.getListsDefaults(param);
    const metaPromise1 = this.camp.getDynamic1MetaDefaults(param);
    const metaPromise2 = this.camp.getDynamic2MetaDefaults(param);
    const metaPromise3 = this.camp.getDynamic3MetaDefaults(param);

    Promise.all([
      viewsPromise,
      listsPromise,
      metaPromise1,
      metaPromise2,
      metaPromise3,
    ]).then((vals) => {
      const metas = [vals[2], vals[3], vals[4]];

      const {
        bpListsMerged1,
        bpListsMerged2,
        bpListsMerged3,
      } = this.listsGenerator(vals[1]);

      if (cb)
        cb(
          vals[0],
          metas,
          [bpListsMerged1, bpListsMerged2, bpListsMerged3],
          vals[1] //raw lists
        );
    });
  }

  listsGenerator(valsRaw) {
    const bpListsMeta1 = valsRaw.meta.find((vl) => vl.type === "dynamic1");
    const bpListsMeta2 = valsRaw.meta.find((vl) => vl.type === "dynamic2");
    const bpListsMeta3 = valsRaw.meta.find((vl) => vl.type === "dynamic3");
    const lists = sortListsOnly(valsRaw.lists);
    const filteredLists1 = this.filterList(lists, "dynamic1");
    const filteredLists2 = this.filterList(lists, "dynamic2");
    const filteredLists3 = this.filterList(lists, "dynamic3");
    const bpListsMerged1 = {
      meta: bpListsMeta1 || {},
      lists: filteredLists1,
      type: "dynamic1",
    };
    const bpListsMerged2 = {
      meta: bpListsMeta2 || {},
      lists: filteredLists2,
      type: "dynamic2",
    };
    const bpListsMerged3 = {
      meta: bpListsMeta3 || {},
      lists: filteredLists3,
      type: "dynamic3",
    };
    return { bpListsMerged1, bpListsMerged2, bpListsMerged3 };
  }

  /**
   *
   * @param {*} clientId
   * @param {*} listObj
   * @param {*} callbackOk returns array of lists, filtered by type and rawLists
   * @param {*} callbackFail
   */
  updateDefaultList(clientId, listObj, callbackOk, callbackFail) {
    const params = { id: clientId, body: listObj };
    this.camp
      .putListsDefaults(params)
      .then((data) => {
        const {
          bpListsMerged1,
          bpListsMerged2,
          bpListsMerged3,
        } = this.listsGenerator(data);
        if (callbackOk)
          callbackOk([bpListsMerged1, bpListsMerged2, bpListsMerged3], data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  /**
   * Loads all meta data about blocking plan
   * @param {number|string} projectId
   * @param {func} callback function, @returns {viewsObj, metaObj, listsObj}
   */
  loadMetas(projectId, cb) {
    const param = { id: projectId };
    const viewsPromise = this.camp.getViews(param);
    const listsPromise = this.camp.getLists(param);
    const metaPromise1 = this.camp.getDynamic1Meta(param);
    const metaPromise2 = this.camp.getDynamic2Meta(param);
    const metaPromise3 = this.camp.getDynamic3Meta(param);

    Promise.all([
      viewsPromise,
      listsPromise,
      metaPromise1,
      metaPromise2,
      metaPromise3,
    ]).then((vals) => {
      const metas = [vals[2], vals[3], vals[4]];
      const bpListsMeta1 = vals[1].meta.find((vl) => vl.type === "dynamic1");
      const bpListsMeta2 = vals[1].meta.find((vl) => vl.type === "dynamic2");
      const bpListsMeta3 = vals[1].meta.find((vl) => vl.type === "dynamic3");
      const lists = sortListsOnly(vals[1].lists);
      const bpListsMerged1 = {
        meta: bpListsMeta1 || {},
        lists: lists,
        type: "dynamic1",
      };
      const bpListsMerged2 = {
        meta: bpListsMeta2 || {},
        lists: lists,
        type: "dynamic2",
      };
      const bpListsMerged3 = {
        meta: bpListsMeta3 || {},
        lists: lists,
        type: "dynamic3",
      };

      // const sortedLists = sortLists(bpListsMerged);

      commonDataViewsBound(projectId, vals[0]);
      commonDataMetasBound(projectId, metas);
      commonDataListsBound(projectId, bpListsMerged1);
      if (cb)
        cb(vals[0], metas, [bpListsMerged1, bpListsMerged2, bpListsMerged3]);
    });
  }

  loadLists(projectId, cb) {
    const param = { id: projectId };
    // const listsPromise = this.camp.getBlockingPlanLists(param);
    const listsPromise = this.camp.getLists(param);
    Promise.all([listsPromise]).then((vals) => {
      const bpListsMeta = vals[0].meta.find((vl) => vl.type === "dynamic1");
      const bpListsMerged = {
        meta: bpListsMeta,
        lists: vals[0].lists,
      };
      const sortedLists = sortLists(bpListsMerged);
      commonDataListsBound(projectId, sortedLists);
      if (cb) cb(sortedLists);
    });
  }

  /**
   * Loads projects blocking plan. Dispatches data to redux for future consumption
   * @param {string|number} blocking plan by project id
   * @deprecated will be removed, when new table takes place
   * //TODO delete
   */
  loadTactis(id) {
    this.camp
      .getDynamic1ByProject({ id })
      .then((data) => {
        const dataCopy = {};
        data.forEach((item) => {
          dataCopy[item.id] = remapNames(item, TYPES.BLOCKING);
        });
        return loadTacticsBound(dataCopy);
      })
      .catch((err) => {
        globalErrorCallback(err);
      });
  }

  saveAllDyn(projectId, dataToSave, cbOk = () => {}, cbFail = () => {}) {
    const params = { id: projectId, body: dataToSave };
    this.camp
      .putDynamic(params)
      .then((data) => {
        mediaPlanLoadedBound(data);
        cbOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, cbFail);
      });
  }

  /**
   * Gets flat view data
   * @param {int} projectId
   * @param {int} level 1,2,3 for each level
   * @param {*} cbOk
   * @param {*} cbFail
   */
  loadDynFlat(projectId, level, cbOk, cbFail = () => {}) {
    const params = { id: projectId };
    const lvs = {
      1: "getDynamic1Flat",
      2: "getDynamic2Flat",
      3: "getDynamic3Flat",
    };
    this.camp[lvs[level]](params)
      .then((data) => {
        cbOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, cbFail);
      });
  }

  loadDynAll(projectId, cbOk = () => {}, cbFail = () => {}) {
    const params = { id: projectId };
    //FIXME: make it work again, commented, because calls cb twice
    // this.cache.getItem("loadDynAll", (err, value) => {
    //   if (err) return cbFail();
    //   if (value) return cbOk(value);
    // });
    this.camp
      .getDynamicByProject(params)
      .then((data) => {
        this.cache.setItem("loadDynAll", data, () => {
          mediaPlanLoadedBound(data);
          console.log("[BPService.js] CBOK", 22222);
          cbOk(data);
        });
      })
      .catch((err) => {
        globalErrorCallback(err, cbFail);
      });
  }

  loadDyn3(lineId, cbOk = () => {}, cbFail = () => {}) {
    const params = { id: lineId };
    this.camp
      .getDynamic3(params)
      .then((data) => {
        const dataCopy = {};
        data.forEach((item) => {
          dataCopy[item.id] = remapNames(item, TYPES.TRAFFIC);
        });
        cbOk(data);
        return loadTrafficsBound(dataCopy);
      })
      .catch((err) => {
        globalErrorCallback(err, cbFail);
      });
  }

  loadDyn2(blockingLineId, cbOk = () => {}, cbFail = () => {}) {
    const params = { id: blockingLineId };
    this.camp
      .getDynamic2ByDynamic1(params)
      .then((data) => {
        const dataCopy = {};
        data.forEach((item) => {
          dataCopy[item.id] = remapNames(item, TYPES.TRAFFIC);
        });
        cbOk(data);
        return loadTrafficsBound(dataCopy);
      })
      .catch((err) => {
        globalErrorCallback(err, cbFail);
      });
  }

  loadDyn1(projectId, cbOk = () => {}, cbFail = () => {}) {
    const params = { id: projectId };
    this.camp
      .getDynamic1ByProject(params)
      .then((data) => {
        const dataCopy = {};
        data.forEach((item) => {
          dataCopy[item.id] = remapNames(item, TYPES.BLOCKING);
        });
        cbOk(data);
        return loadTacticsBound(dataCopy);
      })
      .catch((err) => {
        globalErrorCallback(err, cbFail);
      });
  }

  /**
   * Loads projects blocking plan. Dispatches data to redux for future consumption
   * @param {string|number} blocking plan by project id
   */
  loadBlockingPlan(projectId, cbOk = () => {}, cbFail = () => {}) {
    const params = { id: projectId };
    this.camp
      .getDynamic1ByProject(params)
      .then((data) => {
        const dataCopy = {};
        data.forEach((item) => {
          dataCopy[item.id] = remapNames(item, TYPES.BLOCKING);
        });
        cbOk(data);
        return loadTacticsBound(dataCopy);
      })
      .catch((err) => {
        globalErrorCallback(err, cbFail);
      });
  }

  /**
   * Used to post tactics, has ability to determine if it's new or edited
   * thus uses different end points
   * @param {string|number} projectId
   * @param {object} line blocking plan plain object
   * @param {func} callbackOk
   * @param {func} callbackFail
   */
  postTactic(projectId, line, callbackOk, callbackFail) {
    const body = [];
    body.push(line);
    const params = { id: projectId, body };
    let action = "postDynamic1";
    if (line.id) {
      action = "putDynamic1";
    }
    this.camp[action](params)
      .then((data) => {
        if (callbackOk) callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  /**
   * Posts new project lines.
   * @param {string|number} projectId
   * @param {array} lines
   * @param {*} callbackOk
   * @param {*} callbackFail
   */
  postLines(projectId, lines, callbackOk, callbackFail) {
    const body = lines;
    const params = { id: projectId, body };
    this.camp
      .postDynamic1(params)
      .then((data) => {
        if (callbackOk) callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  /**
   * Puts edited project lines.
   * @param {string|number} projectId
   * @param {array} lines
   * @param {*} callbackOk
   * @param {*} callbackFail
   */
  putLines(projectId, lines, callbackOk, callbackFail) {
    const body = lines;
    const params = { id: projectId, body };
    this.camp
      .putDynamic1(params)
      .then((data) => {
        if (callbackOk) callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  /**
   * Used to post/put all lines, uses single promise to return all data or fail.
   * @param {string|number} projectId
   * @param {array} postLines new lines
   * @param {array} putLines edited lines
   * @param {func} callbackOk
   * @param {func} callbackFail
   */
  postPutLines(projectId, postLines, putLines, callbackOk, callbackFail) {
    //TODO create filter to delete some lines like platformId, seems to be troublesome
    const filteredPostLines = [];
    postLines.forEach((ln) => {
      const copy = { ...ln };
      delete copy.platformId;
      filteredPostLines.push(copy);
    });

    const filteredPutLines = [];
    putLines.forEach((ln) => {
      const copy = { ...ln };
      delete copy.platformId;
      filteredPutLines.push(copy);
    });

    const postParams = { id: projectId, body: filteredPostLines };
    const putParams = { id: projectId, body: filteredPutLines };
    const post =
      filteredPostLines.length > 0
        ? this.camp.postDynamic1(postParams)
        : Promise.resolve([]);
    const put =
      filteredPutLines.length > 0
        ? this.camp.putDynamic1(putParams)
        : Promise.resolve([]);

    Promise.all([post, put])
      .then((vals) => {
        const postVals = vals[0];
        const putVals = vals[1];
        // both returns same data
        let merged = [];
        if (postVals.length > 0) {
          merged = [...postVals];
        } else {
          merged = [...putVals];
        }
        callbackOk(merged);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  // @TODO remove postPutLines
  postPutDyn1(projectId, postLines, putLines, callbackOk, callbackFail) {
    //TODO create filter to delete some lines like platformId, seems to be troublesome
    const filteredPostLines = [];
    postLines.forEach((ln) => {
      const copy = { ...ln };
      delete copy.platformId;
      filteredPostLines.push(copy);
    });

    const filteredPutLines = [];
    putLines.forEach((ln) => {
      const copy = { ...ln };
      delete copy.platformId;
      filteredPutLines.push(copy);
    });

    const postParams = { id: projectId, body: filteredPostLines };
    const putParams = { id: projectId, body: filteredPutLines };
    const post =
      filteredPostLines.length > 0
        ? this.camp.postDynamic1(postParams)
        : Promise.resolve([]);
    const put =
      filteredPutLines.length > 0
        ? this.camp.putDynamic1(putParams)
        : Promise.resolve([]);

    Promise.all([post, put])
      .then((vals) => {
        const postVals = vals[0];
        const putVals = vals[1];
        // both returns same data
        let merged = [];
        if (postVals.length > 0) {
          merged = [...postVals];
        } else {
          merged = [...putVals];
        }
        callbackOk(merged);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  postPutDyn2(projectId, postLines, putLines, callbackOk, callbackFail) {
    const postParams = { id: projectId, body: postLines };
    const putParams = { id: projectId, body: putLines };
    const post =
      postLines.length > 0
        ? this.camp.postDynamic2s(postParams)
        : Promise.resolve([]);
    const put =
      putLines.length > 0
        ? this.camp.putDynamic2(putParams)
        : Promise.resolve([]);

    Promise.all([post, put])
      .then((vals) => {
        const postVals = vals[0];
        const putVals = vals[1];
        let merged = [];
        if (postVals.length > 0) {
          merged = [...postVals];
        } else {
          merged = [...putVals];
        }
        callbackOk(merged);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  postPutDyn3(projectId, postLines, putLines, callbackOk, callbackFail) {
    const postParams = { id: projectId, body: postLines };
    const putParams = { id: projectId, body: putLines };
    const post =
      postLines.length > 0
        ? this.camp.postDynamic3(postParams)
        : Promise.resolve([]);
    const put =
      putLines.length > 0
        ? this.camp.putDynamic3(putParams)
        : Promise.resolve([]);

    Promise.all([post, put])
      .then((vals) => {
        const postVals = vals[0];
        const putVals = vals[1];
        let merged = [];
        if (postVals.length > 0) {
          merged = [...postVals];
        } else {
          merged = [...putVals];
        }
        callbackOk(merged);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  postPutTrafficLines(
    projectId,
    postLines,
    putLines,
    callbackOk,
    callbackFail
  ) {
    const postParams = { id: projectId, body: postLines };
    const putParams = { id: projectId, body: putLines };
    const post =
      postLines.length > 0
        ? this.camp.postDynamic2s(postParams)
        : Promise.resolve([]);
    const put =
      putLines.length > 0
        ? this.camp.putDynamic2(putParams)
        : Promise.resolve([]);

    Promise.all([post, put])
      .then((vals) => {
        const postVals = vals[0];
        const putVals = vals[1];
        callbackOk(postVals, putVals);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  deleteLineDynAll(lineId, callbackOk, callbackFail) {
    const params = { id: lineId };
    this.camp
      .deleteDynamic1(params)
      .then(() => {
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  deleteLine1Multi(ids, callbackOk, callbackFail) {
    const promises = [];
    ids.forEach((id) => {
      promises.push(this.camp.deleteDynamic1({ id }));
    });
    Promise.all(promises)
      .then((vals) => {
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  deleteLine2Multi(ids, callbackOk, callbackFail) {
    const promises = [];
    ids.forEach((id) => {
      promises.push(this.camp.deleteDynamic2({ id }));
    });
    Promise.all(promises)
      .then((vals) => {
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  deleteLine3Multi(ids, callbackOk, callbackFail) {
    const promises = [];
    ids.forEach((id) => {
      promises.push(this.camp.deleteDynamic3({ id }));
    });
    Promise.all(promises)
      .then((vals) => {
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  deleteLine1(lineId, callbackOk, callbackFail) {
    const params = { id: lineId };
    this.camp
      .deleteDynamic1(params)
      .then(() => {
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  deleteLine2(lineId, callbackOk, callbackFail) {
    const params = { id: lineId };
    this.camp
      .deleteDynamic2(params)
      .then(() => {
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  deleteLine3(lineId, callbackOk, callbackFail) {
    const params = { id: lineId };
    this.camp
      .deleteDynamic3(params)
      .then(() => {
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  loadTrafficMetas(projectId, cb) {
    const param = { id: projectId };
    const metaPromise = this.camp.getDynamic2Meta(param);
    Promise.all([metaPromise]).then((vals) => {
      if (cb) cb(vals[0]);
    });
  }

  loadTraffics(blockingLineId) {
    const params = { id: blockingLineId };
    this.camp
      .getTrafficByBlockingLine(params)
      .then((data) => {
        const dataCopy = {};
        data.forEach((item) => {
          dataCopy[item.id] = remapNames(item, TYPES.TRAFFIC);
        });
        loadTrafficsBound(dataCopy);
      })
      .catch((err) => {
        globalErrorCallback(err);
      });
  }

  laodLandingPages(uploadInfoId, callbackOk) {
    const params = { id: uploadInfoId };
    this.camp
      .getDynamic3(params)
      .then((data) => {
        const dataCopy = adjustLandingPagesDataIds(data, uploadInfoId);
        callbackOk(dataCopy);
      })
      .catch(globalErrorCallback);
  }

  deleteLandingPage(lineId, callbackOk, callbackFail) {
    const params = { id: lineId };
    this.camp
      .deleteDynamic3(params)
      .then(callbackOk)
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  saveLandingPages(uploadInfoId, linesAlt, linesNew, callbackOk, callbackFail) {
    const putData = { id: uploadInfoId, body: linesAlt };
    const postData = { id: uploadInfoId, body: linesNew };

    const putParams =
      linesAlt.length > 0
        ? this.camp.putDynamic3(putData)
        : Promise.resolve([]);
    const postParams =
      linesNew.length > 0
        ? this.camp.postDynamic3(postData)
        : Promise.resolve([]);

    Promise.all([putParams, postParams])
      .then((vals) => {
        callbackOk([...vals[0], ...vals[1]]);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  postPutImportsD1(projectId, combinedLinesObject, callbackOk, callbackFail) {
    const promises = [];
    for (let id in combinedLinesObject) {
      const newLines = combinedLinesObject[id].newLines;
      if (newLines.length !== 0) {
        const newLinesBody = {
          id: id,
          body: newLines,
        };
        const n = this.camp.postDynamic1(newLinesBody);
        promises.push(n);
      }

      const editedLines = combinedLinesObject[id].editedLines;
      if (editedLines.length !== 0) {
        const editedLinesBody = {
          id: id,
          body: editedLines,
        };
        const e = this.camp.putDynamic1(editedLinesBody);
        promises.push(e);
      }
    }
    Promise.all(promises)
      .then((vals) => {
        console.log("[BPService.js] vals", vals);
        callbackOk(vals);
      })
      .catch((err) => {
        console.log("[BPService.js] errrr ", err);
        callbackFail(err);
      });
  }

  postPutImportsD2(projectId, combinedLinesObject, callbackOk, callbackFail) {
    const promises = [];
    for (let id in combinedLinesObject) {
      const newLines = combinedLinesObject[id].newLines;
      if (newLines.length !== 0) {
        const newLinesBody = {
          id: id,
          body: newLines,
        };
        const n = this.camp.postDynamic2s(newLinesBody);
        promises.push(n);
      }

      const editedLines = combinedLinesObject[id].editedLines;
      if (editedLines.length !== 0) {
        const editedLinesBody = {
          id: id,
          body: editedLines,
        };
        const e = this.camp.putDynamic2(editedLinesBody);
        promises.push(e);
      }
    }
    Promise.all(promises)
      .then((vals) => {
        console.log("[BPService.js] vals", vals);
        callbackOk(vals);
      })
      .catch((err) => {
        console.log("[BPService.js] errrr ", err);
        callbackFail(err);
      });
  }

  postPutImportsD3(projectId, combinedLinesObject, callbackOk, callbackFail) {
    const promises = [];
    for (let id in combinedLinesObject) {
      const newLines = combinedLinesObject[id].newLines;
      if (newLines.length !== 0) {
        const newLinesBody = {
          id: id,
          body: newLines,
        };
        const n = this.camp.postDynamic3(newLinesBody);
        promises.push(n);
      }

      const editedLines = combinedLinesObject[id].editedLines;
      if (editedLines.length !== 0) {
        const editedLinesBody = {
          id: id,
          body: editedLines,
        };
        const e = this.camp.putDynamic3(editedLinesBody);
        promises.push(e);
      }
    }
    Promise.all(promises)
      .then((vals) => {
        console.log("[BPService.js] vals", vals);
        callbackOk(vals);
      })
      .catch((err) => {
        console.log("[BPService.js] errrr ", err);
        callbackFail(err);
      });
  }

  postTrafficLine(projectId, lines, callbackOk, callbackFail) {
    const body = lines;
    const params = { id: projectId, body };
    this.camp
      .putDynamic2(params)
      .then((data) => {
        if (callbackOk) callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  deleteTrafficLine(lineId, callbackOk, callbackFail) {
    const params = { id: lineId };
    this.camp
      .deleteDynamic2(params)
      .then(() => {
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  updateView(projectId, viewObj, callbackOk, callbackFail) {
    const params = { id: projectId, body: [viewObj] };
    this.camp
      .putViews(params)
      // .putBlockingPlanViews(params)
      .then((data) => {
        commonDataViewsReloadedBound(projectId, data);
        // TODO: Legacy, remove this and use commonListReload FS
        initViews(projectId);

        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  updateViews(projectId, viewArray, callbackOk, callbackFail) {
    const paramsD1 = { id: projectId, body: [viewArray[0]] };
    const d1 = this.camp.putViews(paramsD1);
    const paramsD2 = { id: projectId, body: [viewArray[1]] };
    const d2 = this.camp.putViews(paramsD2);
    const paramsD3 = { id: projectId, body: [viewArray[2]] };
    const d3 = this.camp.putViews(paramsD3);

    Promise.all([d1, d2, d3])
      .then((data) => {
        commonDataViewsReloadedBound(projectId, data);
        // TODO: Legacy, remove this and use commonListReload FS
        initViews(projectId);
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  updateList(projectId, listObj, callbackOk, callbackFail) {
    const params = { id: projectId, body: listObj };
    this.camp
      // .putBlockingPlanLists(params)
      .putLists(params)
      .then((data) => {
        // this is done because put is not returning new id, so need to call again to get full data.
        commonDataListsReloadedBound(data);
        this.loadLists(projectId, () => {
          if (callbackOk) callbackOk(data);
        });
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  //**
  //  *
  //  * @param {*} projectId
  //  * @param {*} metasObj
  //  * @param {*} dynamicLevelInt 1, 2, 3
  //  * @param {*} callbackOk
  //  * @param {*} callbackFail
  //  * @param {*} isAdmin
  //  */
  updateMetasDX(
    projectId,
    metasObj,
    dynamicLevelInt,
    callbackOk,
    callbackFail,
    isAdmin = false
  ) {
    let fn = "putDynamic1Meta";
    if (dynamicLevelInt === 2) {
      fn = "putDynamic2Meta";
    } else if (dynamicLevelInt === 3) {
      fn = "putDynamic3Meta";
    }

    if (isAdmin) {
      fn = "putDynamic1MetaDefaults";
      if (dynamicLevelInt === 2) {
        fn = "putDynamic2Meta_Defaults";
      } else if (dynamicLevelInt === 3) {
        fn = "putDynamic3Meta_Defaults";
      }
    }

    const params = { id: projectId, body: metasObj };
    this.camp[fn](params)
      .then((dataBack) => {
        if (!isAdmin) initViews(projectId);
        if (callbackOk) callbackOk(dataBack);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  updateMetas(projectId, metasObj, callbackOk, callbackFail) {
    const params = { id: projectId, body: metasObj };
    this.camp
      .putDynamic1Meta(params)
      .then(() => {
        initViews(projectId);
        if (callbackOk) callbackOk();
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  getFileTags(fileHash, callbackOk, callbackFail) {
    this.camp
      .getFileTags({ id: fileHash })
      .then((data) => {
        if (callbackOk) callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  postFileTags(fileHash, data, callbackOk, callbackFail) {
    this.camp
      .postFileTag({ id: fileHash, body: data })
      .then((data) => {
        if (callbackOk) callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }

  uploadFile(file, callbackOk, callbackFail) {
    const params = { file };
    const formData = new FormData();
    formData.append("file", file);
    const fd = axios.interceptors.request.use((config) => {
      if (config.url.endsWith("files") && config.method === "post") {
        /* eslint-disable */
        // generated lib is stupid for uploading files, so need interceptors to do the job.
        // because [no-param-reassign]
        config.data = formData;
        /* eslint-enable */
      }
      return config;
    });
    this.camp
      .uploadFile(params)
      .then((data) => {
        axios.interceptors.request.eject(fd);
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
        axios.interceptors.request.eject(fd);
      });
  }
  getFileJson(hash, callbackOk, callbackFail) {
    this.camp
      .getFileByID({ ids: hash })
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  getFileMeta(hash, callbackOk, callbackFail) {
    this.camp
      .getFileMetaByID({ ids: hash })
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
  executeBigQueryQueru(projectId, query, options, callbackOk, callbackFail) {
    const realBody = {
      query,
      meta: options.meta,
      clear: options.clear,
      data: options.data,
    };

    this.camp
      .postImportDynamic1({ id: projectId, body: realBody })
      .then((data) => {
        callbackOk(data);
      })
      .catch((err) => {
        globalErrorCallback(err, callbackFail);
      });
  }
}

export default BPService;

export const bpServiceStatic = new BPService();

const remapNames = (line, type) => {
  const clone = { ...line };
  clone._meta = { type };
  if (TYPES[type]) {
    clone._meta = { type };
  } else {
    clone._meta = { type: TYPES.NA };
  }
  return clone;
};

const sortLists = (raw) => {
  const retVal = { lists: {}, meta: raw.meta };
  const rawLists = raw.lists;
  Object.keys(rawLists).forEach((singleListKey) => {
    const singleList = rawLists[singleListKey];
    const sorted = singleList.sort((a, b) =>
      a.listValue.toLowerCase().localeCompare(b.listValue.toLowerCase())
    );
    retVal.lists[singleListKey] = sorted;
  });
  return retVal;
};

const sortListsOnly = (rawLists) => {
  const lists = {};
  Object.keys(rawLists).forEach((singleListKey) => {
    const singleList = rawLists[singleListKey];
    const sorted = singleList.sort((a, b) =>
      a.listValue.toLowerCase().localeCompare(b.listValue.toLowerCase())
    );
    lists[singleListKey] = sorted;
  });
  return lists;
};

const TYPES = {
  BLOCKING: "BLOCKING",
  TRAFFIC: "TRAFFIC",
  NA: "NA",
};
