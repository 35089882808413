import React, { Component } from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { format } from "date-fns";
import { parseAndFormatDate } from "../../../../utils/DateUtils";
import { getDataFTAttrs } from "../../../../utils/FTUtils";
class ProjectMilestonesSettingsLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      milestone: this.props.stone,
      completed: false,
      newDate: this.props.stone.date,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.stone) {
      this.setState({
        milestone: nextProps.stone,
        newDate: nextProps.stone.date,
      });
    }
  }

  onSaveClickHandler() {
    const milestone = {
      milestoneEntityId: this.state.milestone.id,
      date: this.state.newDate,
      completed: this.state.completed,
    };
    this.props.onSave(milestone);
    this.setState({ isEditMode: false });
  }

  editHandler() {
    this.setState({ isEditMode: true });
  }

  cancelHandler() {
    this.setState({ isEditMode: false, newDate: this.state.milestone.date });
  }

  handleSelecteDay(newDate) {
    const formatted = format(newDate, "YYYY-MM-DD");
    this.setState({ newDate: formatted });
  }

  checkboxHandler() {
    this.setState({ completed: true }, () => {
      const milestone = {
        milestoneEntityId: this.state.milestone.id,
        milestoneId: this.state.milestone.milestone.id,
        date: this.state.newDate,
        completed: this.state.completed,
      };
      this.props.onSave(milestone);
    });
  }

  render() {
    return (
      <div className="ProjectMilestonesSettingsLine">
        <div
          style={{ marginLeft: 0, paddingLeft: 0 }}
          className={`ld-over ${
            this.props.showLoading ? "running" : "not-running"
          }`}
          {...getDataFTAttrs(this.props)}
        >
          <span className="ld ld-ring ld-spin" />
          <div className="grid-x">
            <div className="cell small-5 medium-2">
              <div
                className={`mode-view ${
                  this.state.isEditMode ? "hide" : "show"
                }`}
              >
                <input
                  type="checkbox"
                  checked={false}
                  onChange={(event) => this.checkboxHandler(event)}
                />
                {parseAndFormatDate(this.state.milestone.date)}
              </div>
              <div
                className={`mode-view ${
                  this.state.isEditMode ? "show" : "hide"
                }`}
              >
                <DayPickerInput
                  value={format(this.state.milestone.date, "YYYY-MM-DD")}
                  onDayChange={(val) => this.handleSelecteDay(val)}
                />
              </div>
            </div>
            <div className="cell small-7 medium-4">
              <div className="name">{this.state.milestone.milestoneParsed}</div>
              <div className="controls">
                <div
                  className={`mode-view ${
                    this.state.isEditMode ? "hide" : "show"
                  }`}
                >
                  <button
                    onClick={(e) => this.editHandler(e)}
                    className="action-btn edit-btn"
                  >
                    Edit
                  </button>
                </div>
                <div
                  className={`mode-edit ${
                    this.state.isEditMode ? "show" : "hide"
                  }`}
                >
                  <button
                    onClick={(e) => this.cancelHandler(e)}
                    className="action-btn cancel-btn"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => this.onSaveClickHandler(e)}
                    className="action-btn save-btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectMilestonesSettingsLine;

ProjectMilestonesSettingsLine.propTypes = {
  stone: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  showLoading: PropTypes.bool.isRequired,
};
