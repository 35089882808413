import React from "react";
import PropTypes from "prop-types";
import ClientViews from "../clientViews/ClientViews";

import "./ClientDefaultsCC.scss";

class ClientDefaultsCC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="ClientDefaultsCC u-padding-both-sides-25 u-padding-top-10">
        <ClientViews clientId={this.props.clientId} />
      </div>
    );
  }
}

export default ClientDefaultsCC;

ClientDefaultsCC.propTypes = {
  clientId: PropTypes.number.isRequired
};
