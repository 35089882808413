import React from "react";
import PropTypes from "prop-types";
import Select from "antd/lib/select";

import { projectsServiceStatic } from "../../ProjectsService";

class ProjectStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  onStatusChange(val) {
    this.setState({ isLoading: true });
    projectsServiceStatic.setStatus(this.props.projectId, val, () => {
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div className="ProjectStatus">
        <Select
          loading={this.state.isLoading}
          size="small"
          defaultValue={this.props.status}
          onChange={(val) => this.onStatusChange(val)}
          className="selector"
        >
          <Select.Option value={"InPlanning"}>In Planning</Select.Option>
          <Select.Option value={"Live"}>Live</Select.Option>
          <Select.Option value={"Completed"}>Completed</Select.Option>
          <Select.Option value={"Paused"}>Paused</Select.Option>
        </Select>
      </div>
    );
  }
}

export default ProjectStatus;

ProjectStatus.defaultTypes = {
  status: "InProgress",
};

ProjectStatus.propTypes = {
  status: PropTypes.string,
  projectId: PropTypes.number.isRequired,
};
