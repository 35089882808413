import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { PROJECT_OVERVIEW_ACTIVITY_LINES_TO_SHOW } from "../../../shared/Constants";

import "./ProjectActivitySide.scss";
import ProjectsService, {
  mergeNotesAndFilesforActivity
} from "../../ProjectsService";
import { getCurrentUserStaticCB } from "../../../auth/AuthService";

import ProjectNotes from "../projectNotes/ProjectNotes";
import ActivityLog from "../activityLog/ActivityLog";

class ProjectActivitySide extends Component {
  constructor(props) {
    super(props);

    this.projectsService = new ProjectsService();
    this.state = {
      notesApiLoading: false,
      notesWipe: false,
      activities: [],
      notesListApiLoading: true,
      user: {}
    };
  }
  componentDidMount() {
    getCurrentUserStaticCB(user => {
      this.setState({ user });
    });
  }

  componentWillReceiveProps(nextProps) {
    const { notes, files } = nextProps;
    const sorted = mergeNotesAndFilesforActivity(files, notes);
    let cut;
    if (this.props.noLimit) {
      cut = sorted;
    } else {
      cut = sorted.slice(0, PROJECT_OVERVIEW_ACTIVITY_LINES_TO_SHOW);
    }
    this.setState({
      ...this.state,
      activities: cut,
      notesListApiLoading: false
    });
  }

  activites() {
    this.props.notesAddedCallback();
    // this.setState({ ...this.state, notesListApiLoading: false });
  }

  noteCallback = note => {
    this.setState({
      ...this.state,
      notesApiLoading: true,
      notesWipe: false,
      notesListApiLoading: true
    });
    this.projectsService.createNote(
      this.props.projectId,
      note,
      () => {
        this.setState(
          { ...this.state, notesApiLoading: false, notesWipe: true },
          () => {
            this.activites();
          }
        );
      },
      () => {
        this.setState({
          ...this.state,
          notesApiLoading: false,
          notesWipe: false
        });
        this.activites();
      }
    );
  };

  render() {
    return (
      <div className={`ProjectActivitySide is-page-${this.props.isPage}`}>
        <div className="headline">
          {this.props.isPage ? (
            <div>
              <h6>Activity</h6>
              <p>File uploads, project activity and stuff that got said.</p>
            </div>
          ) : (
            <div>
              <h6>Activity</h6> &nbsp;&nbsp;
              <Link to={`/projects/${this.props.projectId}/activity/`}>
                See All
              </Link>
            </div>
          )}
        </div>
        <ProjectNotes
          user={this.state.user}
          onSubmit={this.noteCallback}
          showLoading={this.state.notesApiLoading}
          wipe={this.state.notesWipe}
        />
        <ActivityLog
          activities={this.state.activities}
          showLoading={this.state.notesListApiLoading}
        />
      </div>
    );
  }
}

export default ProjectActivitySide;

ProjectActivitySide.defaultProps = {
  noLimit: false,
  isPage: false
};

ProjectActivitySide.propTypes = {
  notes: PropTypes.array.isRequired,
  files: PropTypes.array.isRequired,
  projectId: PropTypes.number.isRequired,
  notesAddedCallback: PropTypes.func.isRequired,
  noLimit: PropTypes.bool,
  isPage: PropTypes.bool
};
