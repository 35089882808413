import differenceInWeeks from 'date-fns/difference_in_weeks';
import differenceInDays from 'date-fns/difference_in_days';
import { isNull, isNumber } from 'util';
import { CONCATENATE } from '@lfx/formulajs';
import { formatCurrency } from './CurrencyUtils';
import { getViewBySortedByCols } from '../pages/bp/BPViewService';
import { BPVIEWS } from '../shared/Constants';
import { getAllPlatformsBound } from '../selectors/commonSelectors';
import FormulasEngine from './formulasEngine/formulasEngine';
import { getBlockingLinesByParentLvl1Bond, getActiveProjectId } from '../pages/bp/bpUtils';
import { getProjectConstantsBound } from '../projects/ProjectReduxUtils';

const listValue = (...params) => params[0].meta.externalValue || 0;
const listValue2 = (...params) => params[0].meta.externalValue2 || 0;
const listExternalId = (...params) => params[0].meta.externalId || 0;
const listLabel = (...params) => params[0].label || '';

const listPlatformLabel = (...params) => {
  const platforms = getAllPlatformsBound();
  return platforms[params[0]].name;
};

const isBlank = (...params) => !params[0];

const getProjectConstantsValue = (constName) => {
  const projectId = getActiveProjectId();
  const constants = getProjectConstantsBound(projectId);
  const needle = Object.values(constants).find(single => toUp(single.name) === toUp(constName));
  return needle.value || '';
};

// to make line shorter for linter
const toUp = str => str.toUpperCase();

/**
 * Custom functions
 */
const customFormulas = {
  C_FORMAT_CURRENCY: (...params) => formatCurrency(params[0]),
  C_DIFF_IN_WEEKS: (...params) => differenceInWeeks(params[0], params[1]),
  C_DIFF_IN_DAYS: (...params) => differenceInDays(params[0], params[1]),
  CONCAT: (...params) => CONCATENATE(params),
  C_ROUND: (...params) => Math.round(params[0]),
  C_LIST_VALUE: (...params) => listValue(params[0]),
  C_LIST_VALUE2: (...params) => listValue2(params[0]),
  C_LIST_LABEL: (...params) => listLabel(params[0]),
  C_LIST_EXTERNAL_ID: (...params) => listExternalId(params[0]),
  C_PLATFORM_LABEL: (...params) => listPlatformLabel(params[0]),
  ISBLANK: (...params) => isBlank(params[0]),
  CONST: (...params) => getProjectConstantsValue(params[0]),
  C: (...params) => getProjectConstantsValue(params[0]),
};

export default customFormulas;

const viewCache = {};
export const formulaForHumans = (formula, projectId) => {
  const view = BPVIEWS.VIEWS.ALLCOLUMNS;
  let viewData;
  if (viewCache[view]) {
    viewData = viewCache[view];
  } else {
    viewData = getViewBySortedByCols(view, projectId);
    viewCache[view] = viewData;
  }
  let human = formula;
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  const ar = Object.keys(viewData);
  const resort = ar.sort(collator.compare).reverse();

  resort.forEach((elem) => {
    human = replaceAll(human, elem, viewData[elem].label);
  });
  return human;
};

const replaceAll = (str, find, replace) => str.replace(new RegExp(find, 'g'), replace);

export const computeLine = (dataLine, view) => {
  const data = {};

  Object.keys(dataLine).forEach((keyName) => {
    data[keyName] = {};
    const rawVal = dataLine[keyName];
    if (keyName.indexOf('list') === 0 || keyName.indexOf('platformId') === 0) {
      if ((isNull(rawVal) || isNumber(rawVal)) && view[keyName]) {
        data[keyName].value = view[keyName].options.find(el => el.value === rawVal);
      }
    } else {
      data[keyName].value = rawVal;
    }
    data[keyName].name = keyName;
    data[keyName].formula = view[keyName] ? view[keyName].formula : '';
  });

  const formulasEngineInstance = new FormulasEngine();
  this.formulasEngine = formulasEngineInstance.init(null, customFormulas);

  this.formulasEngine.setData(data);
  this.formulasEngine.scan();

  return this.formulasEngine.data;
};

export const computeAllLines = (projectId) => {
  const view = getViewBySortedByCols(BPVIEWS.VIEWS.ALLCOLUMNS, projectId);
  const allLines = getBlockingLinesByParentLvl1Bond(projectId);
  const retVal = {};
  const errors = {};
  const errRet = {};
  allLines.forEach((line) => {
    const computed = computeLine(line, view);
    retVal[line.id] = line;
    errors[line.id] = {};
    computed.forEach((comp) => {
      retVal[line.id][comp.name] = comp.value;
      if (comp.error) {
        errors[line.id][comp.name] = comp.error;
        retVal[line.id][BPVIEWS.KNOWN_CONST.FORMULA_ERROR] = true;
      }
    });

    Object.keys(errors).forEach((key) => {
      if (Object.keys(errors[key]).length !== 0) {
        errRet[key] = errors[key];
      }
    });
  });
  return {
    data: retVal,
    errors: errRet,
  };
};

window.compute = computeLine;

window.computeAllLines = computeAllLines;
