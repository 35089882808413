import React from "react";
import PropTypes from "prop-types";
import Checkbox from "antd/lib/checkbox";

class ValidatorWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: false, color: "c1_vali" };
  }
  componentDidMount() {
    const { isChecked, color } = this.props;
    if (isChecked) {
      this.setState({ checked: isChecked, color });
    }
  }
  onChange(checked) {
    this.setState({ checked }, () => {
      this.props.onCheck(checked, this.state.color);
    });
  }
  onColorSelected(color) {
    this.setState({ color }, () => {
      this.props.onCheck(this.state.checked, color);
    });
  }
  render() {
    return (
      <div className="flex">
        <Checkbox
          onChange={e => this.onChange(e.target.checked)}
          defaultChecked={this.props.isChecked}
        >
          Required
        </Checkbox>

        {/* Hiding for future releases */}
        {/* <ErrorColorsSelector
          onColorSelected={c => this.onColorSelected(c)}
          selectedValue={this.state.color}
          disabled={this.state.disabled}
        /> */}
      </div>
    );
  }
}

export default ValidatorWrapper;

ValidatorWrapper.propTypes = {
  onCheck: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired
};
