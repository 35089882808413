/* eslint import/prefer-default-export: 0 */

import reduxStore from "../reduxStore";

import {
  commonDataPlatforms,
  commonDataLoadFlags,
  commonDataViews,
  commonDataLists,
  commonDataMetas,
  commonMetasEditorOpen,
  commonMetasEditorClose,
  commonModalsOpenClose,
  commonCallForReload,
  commonDataViewsReloaded,
  commonDataReloadedLists,
  commonDataUsers,
} from "./commonDataActions";

export const commonDataUsersBound = (users) =>
  reduxStore.dispatch(commonDataUsers(users));

export const commonDataPlatformsBound = (platforms) =>
  reduxStore.dispatch(commonDataPlatforms(platforms));

export const commonDataLoadFlagsBound = (flag, flagState) =>
  reduxStore.dispatch(commonDataLoadFlags(flag, flagState));

export const commonDataViewsBound = (projectId, views) =>
  reduxStore.dispatch(commonDataViews(projectId, views));

export const commonDataViewsReloadedBound = (projectId, views) =>
  reduxStore.dispatch(commonDataViewsReloaded(projectId, views));

export const commonDataListsBound = (projectId, lists) =>
  reduxStore.dispatch(commonDataLists(projectId, lists));

export const commonDataListsReloadedBound = (projectId, lists) =>
  reduxStore.dispatch(commonDataReloadedLists(projectId, lists));

export const commonDataMetasBound = (projectId, metas) =>
  reduxStore.dispatch(commonDataMetas(projectId, metas));

export const commonMetasEditorOpenBound = (
  projectId,
  viewName,
  fieldName,
  dynamicLevelInt,
  userSettings,
  viewId
) =>
  reduxStore.dispatch(
    commonMetasEditorOpen(
      projectId,
      viewName,
      fieldName,
      dynamicLevelInt,
      userSettings,
      viewId
    )
  );

export const commonMetasEditorCloseBound = (projectId) =>
  reduxStore.dispatch(commonMetasEditorClose(projectId));

export const commonModalsOpenBound = (name, rest) =>
  reduxStore.dispatch(commonModalsOpenClose(name, true, rest));

export const commonModalsCloseBound = (name) =>
  reduxStore.dispatch(commonModalsOpenClose(name, false, {}));

export const commonCallForReloadBound = (name, rest) =>
  reduxStore.dispatch(commonCallForReload(name, rest));

export const commonCallForImportBound = (name, rest) =>
  reduxStore.dispatch(commonCallForReload(name, rest));

export const listenForCustomMenuChanges = (fn) => {
  const selectBus = (state) => state.commonStore.dataLoadedFlags.bpView;
  function handleChange() {
    const rawVal = selectBus(reduxStore.getState());
    if (Number(rawVal) === 1) {
      fn();
    }
  }
  return reduxStore.subscribe(handleChange);
};

let listenForMetasCloseChangesLastVal = false;
export const listenForMetasCloseChanges = (fn) => {
  const selectBus = (state) => state.commonStore.metasEditor.dialogOpen;
  function handleChange() {
    const rawVal = selectBus(reduxStore.getState());
    if (listenForMetasCloseChangesLastVal !== rawVal) {
      listenForMetasCloseChangesLastVal = rawVal;
      fn(rawVal);
    }
  }
  return reduxStore.subscribe(handleChange);
};

let listenForMetasReorderCloseChangesLastVal = false;
export const listenForMetasReorderCloseChanges = (fn) => {
  const selectBus = (state) => state.commonStore.metasReorder.dialogOpen;
  function handleChange() {
    const rawVal = selectBus(reduxStore.getState());
    if (listenForMetasReorderCloseChangesLastVal !== rawVal) {
      listenForMetasReorderCloseChangesLastVal = rawVal;
      fn(rawVal);
    }
  }
  return reduxStore.subscribe(handleChange);
};

let listenForModalsCloseChangesLastVal = false;
export const listenForModalsCloseChanges = (modalName, fn) => {
  const selectBus = (state) => {
    if (state.commonStore.modals[modalName]) {
      return state.commonStore.modals[modalName].open;
    }
    return false;
  };
  function handleChange() {
    const rawVal = selectBus(reduxStore.getState());
    if (listenForModalsCloseChangesLastVal !== rawVal) {
      listenForModalsCloseChangesLastVal = rawVal;
      fn(rawVal);
    }
  }
  return reduxStore.subscribe(handleChange);
};

let listenForReloadLastVal = false;
export const listenForReloadChanges = (name, fn) => {
  const selectBus = (state) => state.commonStore.callForReload;
  function handleChange() {
    const rawVal = selectBus(reduxStore.getState());
    const retName = rawVal[name];
    if (listenForReloadLastVal !== retName) {
      listenForReloadLastVal = retName;
      fn(rawVal);
    }
  }
  return reduxStore.subscribe(handleChange);
};

let listenForImportLastVal = false;
export const listenForImportChanges = (name, fn) => {
  const selectBus = (state) => state.commonStore.callForReload;
  function handleChange() {
    const rawVal = selectBus(reduxStore.getState());
    const retName = rawVal[name];
    if (retName === undefined) return;
    if (listenForImportLastVal !== retName) {
      listenForImportLastVal = retName;
      fn(rawVal);
    }
  }
  return reduxStore.subscribe(handleChange);
};

// export const listenForUpdate = (parentId, rowId, fn) => {
//   const selectBus = state => state.MediaPlanStore
// }

window.commonModalsOpenBound = commonModalsOpenBound;
window.commonModalsCloseBound = commonModalsCloseBound;
window.listenForCustomMenuChanges = listenForCustomMenuChanges;
window.listenForMetasCloseChanges = listenForMetasCloseChanges;
window.listenForReloadChanges = listenForReloadChanges;
window.listenForModalsCloseChanges = listenForModalsCloseChanges;
