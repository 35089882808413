import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_FOR_DB } from '../shared/Constants';

/**
 * Trys to parse and foramt date from db.
 * @param {string} dateStr input date from camp
 * @param {string} [outFormat=Constants.DEFAULT_DATE_FORMAT]
 * @return {string} returns formated, if fails - same as dateStr
 */
const parseAndFormatDate = (dateStr, outFormat = DEFAULT_DATE_FORMAT) => {
  let retVal = dateStr ? dateStr.toString() : dateStr;
  try {
    const parsed = parse(dateStr);
    retVal = format(parsed, outFormat);
  } catch (error) {
    console.warn('Unparsable date! - ', dateStr, error);
  }
  return retVal;
};

const formatForDb = dateObj => format(dateObj, DEFAULT_DATE_FORMAT_FOR_DB);

export { parseAndFormatDate, formatForDb };
