import React, { Component } from 'react';
import ResetPasswordLogin from './ResetPasswordLogin';
import PasswordStrengthValidator from '../utils/PasswordStrengthValidator';
import { getQueryStringParam } from '../utils/URLUtils';
import './RecoverPassword.scss';
import Footer from '../shared/footer/Footer';
import AuthService from './AuthService';

/**
 * ResetPassword Component, whole page.
 */
class ResetPassword extends Component {
  constructor() {
    super();
    this.auth = new AuthService();
    this.scssOk = 'ok';
    this.scssFail = '';
    this.pwdMinLength = 10;
    this.state = {
      password: '',
      password2: '',
      pwd: {
        lower: '',
        upper: '',
        special: '',
        number: '',
        length: '',
      },
      pwdAll: false,
      notMatch: false,
      buttonState: false,
      loginAgain: false,
      updateFailed: 'hide',
    };
    this.passValidator = new PasswordStrengthValidator();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ buttonState: false });
    const resetCode = getQueryStringParam('token');
    this.auth.resetPassword(
      this.state.password,
      resetCode,
      () => {
        this.setState({ loginAgain: true });
      },
      () => {
        this.setState({ buttonState: true, updateFailed: 'show' });
      },
    );
  };

  handlePassword = (event) => {
    const val = event.target.value.trim();
    const lower = this.passValidator.validateLower(val);
    const upper = this.passValidator.validateUpper(val);
    const special = this.passValidator.validateSpecial(val);
    const number = this.passValidator.validateNumber(val);
    const len = this.passValidator.validateLength(val, this.pwdMinLength);
    const all = lower && upper && special && number && len;
    this.setState(state => ({
      password: val,
      pwd: {
        lower: lower ? this.scssOk : this.scssFail,
        upper: upper ? this.scssOk : this.scssFail,
        special: special ? this.scssOk : this.scssFail,
        number: number ? this.scssOk : this.scssFail,
        length: len ? this.scssOk : this.scssFail,
      },
      pwdAll: all,
      buttonState: all && !state.notMatch,
    }));
  };

  handlePassword2 = (event) => {
    const val = event.target.value.trim();
    this.setState({ password2: val });
    if (val.trim().length > 0 && val !== this.state.password) {
      this.setState({ notMatch: true, buttonState: false });
    } else {
      this.setState(state => ({
        notMatch: false,
        buttonState: state.pwdAll,
      }));
    }
  };

  render() {
    return (
      <div className="ResetPassword">
        <div className="grid-container ">
          <div className="ResetPassword">
            <div className="grid-y v-grid-height">
              <div className="cell small-3">
                <div className="grid-x grid-padding-x">
                  <div className="small-12 cell" />
                </div>
              </div>
              <div className="cell small-9">
                <div className="grid-x grid-padding-x main-row-padding">
                  <div className="small-12 medium-6 cell">
                    <div className="ResetPassword">
                      <h4 className="text-left">Hello!</h4>
                      <br />
                      {!this.state.loginAgain ? (
                        <form className="log-in-form">
                          <div className={`callout warning small ${this.state.updateFailed}`}>
                            <small>Something went wrong, please try again.</small>
                          </div>
                          <p className="text-left">
                            {"Let's update your password. Your new password must:"}
                          </p>
                          <ul className={`password-rules pwd-all-${this.state.pwdAll}`}>
                            <li className={`lower ${this.state.pwd.lower}`}>
                              Contain at least 1 lowercase
                            </li>
                            <li className={`upper ${this.state.pwd.upper}`}>
                              Contain at least 1 uppercase letter
                            </li>
                            <li className={`lower ${this.state.pwd.special}`}>
                              Contain at least 1 special character (!@#$%^&*)
                            </li>
                            <li className={`lower ${this.state.pwd.number}`}>
                              Contain at least 1 number (0-9)
                            </li>
                            <li className={`lower ${this.state.pwd.length}`}>
                              Be at least 10 characters in length
                            </li>
                          </ul>
                          <label htmlFor="password">
                            Password
                            <input
                              type="password"
                              name="password"
                              id="password"
                              value={this.state.password}
                              onChange={this.handlePassword}
                              placeholder="***************"
                            />
                          </label>
                          <label htmlFor="password2">
                            Retype Password
                            <small
                              className={`dont-match ${this.state.notMatch ? 'show' : 'hide'}`}
                            >
                              &nbsp;Do not match!
                            </small>
                            <input
                              type="password"
                              name="password2"
                              id="password2"
                              value={this.state.password2}
                              onChange={this.handlePassword2}
                              placeholder="***************"
                            />
                          </label>
                          {/* <label htmlFor="username">
                            Username
                            <input
                              type="text"
                              name="username"
                              id="username"
                              value={this.state.username}
                              onChange={this.handleUsername}
                              placeholder="user@name.com"
                            />
                          </label> */}
                          <div className="grid-x">
                            <div className="small-12 medium-6 cell">
                              <input
                                type="button"
                                disabled={this.state.buttonState ? '' : 'disabled'}
                                className={`button expanded login-btn ${
                                  this.state.buttonState ? '' : 'disabled'
                                }`}
                                onClick={this.handleSubmit}
                                value="Update Password"
                              />
                            </div>
                          </div>
                        </form>
                      ) : (
                        <ResetPasswordLogin />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid-container full">
          <div className="grid-x">
            <div className="small-12 cell">
              <div className="footer">
                <Footer logged />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
