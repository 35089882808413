import React from 'react';
import { getQueryStringParam } from '../../utils/URLUtils';

const InfoPages = () => {
  const section = getQueryStringParam('section');
  let name;
  switch (section) {
    case 'tnc':
      name = 'Terms & Conditions';
      break;
    case 'pp':
      name = 'Privacy Policy';
      break;
    case 'su':
      name = 'Support';
      break;
    case 'cu':
      name = 'Contact Us';
      break;
    default:
      name = 'nobody home';
      break;
  }
  return (
    <div className="InfoPages">
      <div className="grid-container">
        <div className="grid-x">
          <div className="cell">
            <h5>{name}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPages;
