/* eslint no-case-declarations: 0 */
import * as types from "../types/types";

const initState = {
  filters: {
    dynamic1: [],
    dynamic2: [],
    dynamic3: [],
  },
  mediaPlan: {},
  exp: {
    dynamic1: {},
    dynamic2: {},
    dynamic3: {},
  },
  reload: false,
  view: "dynamic1.default",
  hooksValidation: [],
  hooksByLineId: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.MEDIAPLAN_LOADED:
      return {
        ...state,
        mediaPlan: action.payload.mediaPlan,
      };
    case types.MEDIAPLAN_RELOAD:
      return {
        ...state,
        reload: action.payload.reload,
      };
    case types.MEDIAPLAN_EXPANDED:
      const expCopy = { ...state.exp };
      const { parentId, rowId, expanded, level } = action.payload;
      const key = `k/${parentId}/${rowId}`;
      expCopy[level][key] = expanded;
      return {
        ...state,
        exp: expCopy,
      };
    case types.MEDIAPLAN_FILTERS:
      const filters = { ...state.filters };
      const { type, filter } = action.payload;
      filters[type] = filter;
      return { ...state, filters };
    case types.MEDIAPLAN_VIEW:
      let viewCopy = { ...state.view };
      viewCopy = action.payload.view;
      return { ...state, view: viewCopy };
    case types.MEDIAPLAN_HOOKS_VALIDATION:
      const hooksValidationCopy = action.payload.hooksValidation;
      const hooksByLineId = { ...state.hooksByLineId };
      const hooksNew = dissect(action.payload.hooksValidation);
      const hooksMerged = { ...hooksByLineId, ...hooksNew };
      return {
        ...state,
        hooksValidation: hooksValidationCopy,
        hooksByLineId: hooksMerged,
      };
    default:
      return state;
  }
};

export const dissect = (hooksRaw) => {
  const retValIds = {};
  hooksRaw.forEach((el) => {
    el.entities.forEach((ent) => {
      const c = { ...ent };
      if (!retValIds[ent.entityId]) retValIds[ent.entityId] = {};
      retValIds[ent.entityId][el.hookId] = {
        hookId: el.hookId,
        hookName: el.hookName,
        maps: {
          ...c,
        },
      };
    });
  });
  return retValIds;
};
