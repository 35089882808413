import React, { Component } from "react";
import PropTypes from "prop-types";
import { DEFAULT_ROLE_FOR_NEW_ROLE } from "../../shared/Constants";
import NewProjectSubTitle from "./NewProjectSubTitle";
import NewPeopleLine from "./NewPeopleLine";
import ProjectsService from "../ProjectsService";
import { getCurrentUserStaticCB } from "../../auth/AuthService";

import FireImg from "../../shared/icons/fire.gif";

/**
 * Handles people addition and removal
 * @param {func} onRoleChange @returns {role, username}
 */
class NewProjectPeople extends Component {
  constructor(props) {
    super(props);
    this.projectServie = new ProjectsService();
    this.fireStyle = {
      backgroundImage: `url(${FireImg})`,
      fontSize: "2em"
    };
    this.key = 1;
    this.apiLoaded = { users: false, roles: false };
    // const auth = new AuthService();
    // const currentUser = auth.getCurrentUser();
    // this.initVal = {
    //   // TODO: make it empty and validate.
    //   selectedRoleOption: DEFAULT_ROLE_FOR_NEW_ROLE,
    //   selectedUserOption: {
    //     value: currentUser.id,
    //     label: `${currentUser.contact.firstname} ${currentUser.contact.lastname}`,
    //   },
    // };
    this.state = {
      line: [],
      // selectedRoleOption: this.initVal.selectedRoleOption,
      // selectedUserOption: this.initVal.selectedUserOption,
      // roles: [],
      // users: [],
      // line: [
      //   {
      //     key: 1,
      //     val: this.initVal,
      //     canRemoveLine: false,
      //     isInvalid: false
      //   }
      // ],
      apiLoaded: false,
      apiFail: false
    };
    this.callbackVals = [];
  }

  componentDidMount() {
    getCurrentUserStaticCB(currentUser => {
      this.initVal = {
        // TODO: make it empty and validate.
        selectedRoleOption: DEFAULT_ROLE_FOR_NEW_ROLE,
        selectedUserOption: {
          value: currentUser.id,
          label: `${currentUser.contact.firstname} ${currentUser.contact.lastname}`
        }
      };

      this.setState({
        selectedRoleOption: this.initVal.selectedRoleOption,
        selectedUserOption: this.initVal.selectedUserOption,
        roles: [],
        users: [],
        line: [
          {
            key: 1,
            val: this.initVal,
            canRemoveLine: false,
            isInvalid: false
          }
        ]
      });
      this.constructAndExportVals();
    });

    this.projectServie.getUsers(
      users => {
        this.apiLoaded.users = true;
        this.setState({
          ...this.state,
          users,
          apiLoaded: this.apiLoaded.roles && this.apiLoaded.users
        });
      },
      () => {
        this.setState({
          ...this.state,
          apiFail: true
        });
      }
    );
    this.projectServie.getRoles(
      roles => {
        this.apiLoaded.roles = true;
        this.setState({
          ...this.state,
          roles,
          apiLoaded: this.apiLoaded.roles && this.apiLoaded.users
        });
      },
      () => {
        this.setState({
          ...this.state,
          apiFail: true
        });
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.valid.roles === false) {
      const newLines = [...this.state.line];
      newLines.forEach(elem => {
        nextProps.valid.rolesLines.forEach(invalid => {
          if (elem.key === invalid.metaKey) {
            /* eslint-disable */
            // [no-param-reassign] - does not apply here, you stupid linter.
            elem.isInvalid = true;
            /* eslint-enable */
          }
        });
      });
    }
  }

  onLineChange = (val, key) => {
    const upLine = [...this.state.line];
    const needle = upLine.find(el => el.key === key);
    needle.val = val;
    this.setState({ line: upLine }, () => {
      this.constructAndExportVals();
    });
  };

  getNextKey = () => {
    this.key = this.key + 1;
    return this.key;
  };

  removeLine = key => {
    const upLine = [...this.state.line];
    const needle = upLine.filter(el => el.key !== key);
    this.setState({ line: needle }, () => {
      this.constructAndExportVals();
    });
  };

  addMorePeople = () => {
    const upLine = [...this.state.line];
    upLine.push({
      key: this.getNextKey(),
      canRemoveLine: true,
      isInvalid: false
    });
    this.setState({ line: upLine }, () => {
      this.constructAndExportVals();
    });
  };

  constructAndExportVals = () => {
    this.callbackVals = this.state.line.map(elem => {
      if (elem.val) {
        const retVal = {
          roleId: elem.val.selectedRoleOption.value,
          userId: elem.val.selectedUserOption.value,
          metaKey: elem.key
        };
        return retVal;
      }
      return {};
    });
    this.props.onRoleChange(this.callbackVals);
  };

  render() {
    return (
      <div className="NewProjectPeople">
        <div
          className={`grid-container ld-over ${
            this.state.apiLoaded ? "not-running" : "running"
          }`}
        >
          <span className="ld ld-ring ld-spin" />
          <span
            className={`ld ld-loader ${this.state.apiFail ? "show" : "hide"}`}
            style={this.fireStyle}
          />
          <span
            className={`ld ld-text ${this.state.apiFail ? "show" : "hide"}`}
          >
            API fail
          </span>
          <div className="mid grid-x">
            <div className="cell small-12 medium-8">
              <NewProjectSubTitle
                title="People"
                desc="Assign project roles so everyone knows what they’re doing. New people will get an email invite."
              />
              {this.state.line.map((obj, idx) => (
                <NewPeopleLine
                  key={obj.key}
                  idx={idx}
                  users={this.state.users}
                  roles={this.state.roles}
                  initUser={
                    idx === 0 ? this.state.selectedUserOption : { empty: true }
                  }
                  initRole={
                    idx === 0 ? this.state.selectedRoleOption : { empty: true }
                  }
                  canRemove={obj.canRemoveLine}
                  removeLine={() => {
                    this.removeLine(obj.key);
                  }}
                  onLineChange={val => {
                    this.onLineChange(val, obj.key);
                  }}
                  isInvalid={obj.isInvalid}
                />
              ))}
              <button className="add-more" onClick={this.addMorePeople}>
                Add another role
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewProjectPeople;

NewProjectPeople.propTypes = {
  onRoleChange: PropTypes.func.isRequired,
  valid: PropTypes.any.isRequired
};
