import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Menu from "antd/lib/menu";
import Icon from "antd/lib/icon";
import Layout from "antd/lib/layout";
import eventManager from "../EventManager";
import { devModeStatic } from "../../utils/DevMode";
import initVersions from "../../utils/VersionUtils";
import "./Sidebar.scss";
import { isAdmin, isRoot } from "../../auth/AuthService";

const googleFormURL = process.env.REACT_APP_BUG_FORM;
const wikiURL = process.env.REACT_APP_WIKI_URL;

const STYLES = {
  main: {
    backgroundColor: "#3e4c5c",
  },
  divider: { height: "5px", backgroundColor: "#556373" },
  bottom: {
    position: "absolute",
    bottom: "0",
    backgroundColor: "#3e4c5c",
  },
  infoBottom: {
    height: "60px",
    marginBottom: 0,
  },
};

class Sidebar extends Component {
  constructor() {
    super();
    this.doShowAdmin = isAdmin();
    this.doShowRoot = isRoot();
  }
  componentWillUnmount() {
    eventManager.off(eventManager.TYPES_MAP.WINDOW.SCROLL);
  }
  componentDidMount() {
    initVersions();
  }

  onClickMenuFooter = ({ item, key }) => {
    if (key === "footer-info") {
      this.props.onShowFooter();
    }
    if (key === "footer-help") {
      window.open(wikiURL, "_blank");
    }
    if (key === "footer-bug") {
      window.open(googleFormURL, "_blank");
    }
  };

  render() {
    return (
      <>
        <Layout.Sider collapsed style={STYLES.main} className="Sidebar">
          <Menu
            theme="dark"
            defaultSelectedKeys={["home"]}
            mode="inline"
            style={STYLES.main}
          >
            <Menu.Item key="home">
              <NavLink to="/" className="first">
                <Icon type="home" />
                <span>Home</span>
              </NavLink>
            </Menu.Item>
            {(this.doShowAdmin || this.doShowRoot) && (
              <Menu.Item key="users">
                <NavLink to="/users">
                  <Icon type="team" />
                  <span>Users</span>
                </NavLink>
              </Menu.Item>
            )}

            <Menu.Item key="clients">
              <NavLink to="/clients">
                <Icon type="contacts" />
                <span>Clients</span>
              </NavLink>
            </Menu.Item>
            <Menu.Divider style={STYLES.divider} />
            <Menu.SubMenu
              key="sub2"
              title={
                <span>
                  <Icon type="plus-circle" />
                  <span>Actions</span>
                </span>
              }
            >
              <Menu.Item key="6">
                <NavLink to="/projects/new">
                  <Icon type="plus" />
                  New Plan
                </NavLink>
              </Menu.Item>
              {/* <Menu.Item key="8">
                <Icon type="plus" />
                New Client // hiding for now
              </Menu.Item>  */}
            </Menu.SubMenu>
            {devModeStatic.isDevMode() && (
              <Menu.Item key="dev">
                <NavLink to="/desktop">
                  <Icon type="desktop" />
                  <span>][</span>
                </NavLink>
              </Menu.Item>
            )}
          </Menu>
          <Menu.Divider style={STYLES.divider} />
          <Menu
            className="bottom-sidebar"
            theme="dark"
            defaultSelectedKeys={["home"]}
            mode="inline"
            style={STYLES.bottom}
            onClick={this.onClickMenuFooter}
          >
            <Menu.Item
              key="footer-bug"
              style={STYLES.helpBottom}
              className="footer-bug-btn"
            >
              <Icon type="message" />
              <span>Feedback</span>
            </Menu.Item>
            <Menu.Item
              key="footer-help"
              style={STYLES.helpBottom}
              className="footer-help-btn"
            >
              <Icon type="question-circle" />
              <span>Help Wiki</span>
            </Menu.Item>
            <Menu.Item
              key="footer-info"
              style={STYLES.infoBottom}
              className="footer-info-btn"
            >
              <Icon type="info-circle" />
              <span>Info</span>
            </Menu.Item>
          </Menu>
          <Menu.Divider />
        </Layout.Sider>
      </>
    );
  }
}

export default Sidebar;
