import React from 'react';
import PropTypes from 'prop-types';
import ExclamationIcon from '../icons/exclamation-circle.svg';
import CheckImg from '../icons/check-circle-white.svg';
import WarnImg from '../icons/warning-white.svg';
import CloseImg from '../icons/times-circle-white.svg';

const WarnLine = (props) => {
  let titleOut = '';
  let titleFinal = '';
  let icon;
  switch (props.type) {
    case 'WARN':
    case 'WARNS':
      titleOut = 'Warning';
      icon = WarnImg;
      break;
    case 'ERROR':
    case 'ERRORS':
      titleOut = 'Error';
      icon = ExclamationIcon;
      break;
    case 'INFO':
    case 'INFOS':
      titleOut = 'Info';
      icon = CheckImg;
      break;
    default:
      break;
  }
  if (props.title) {
    titleFinal = props.title;
  } else {
    titleFinal = titleOut;
  }
  return (
    <div className="line">
      <img src={icon} alt={props.type} />
      <strong>
        {titleFinal}
:
      </strong>
      {' '}
      {props.msg}
      <span
        role="button"
        tabIndex="0"
        className="close u-clickable"
        onClick={() => props.onClose()}
        onKeyDown={() => props.onClose()}
      >
        <img src={CloseImg} alt="close" className="close-img" />
      </span>
    </div>
  );
};

export default WarnLine;

WarnLine.defaultProps = {
  type: 'INFO',
  title: null,
};

WarnLine.propTypes = {
  type: PropTypes.oneOf(['WARN', 'ERROR', 'INFO', 'WARNS', 'ERRORS', 'INFOS']),
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
};
