import React from 'react';
import { Link } from 'react-router-dom';
import './RecoverPassword.scss';

/**
 * PasswordSent Component, just shows and asks user to come back to login.
 */
const PasswordSent = () => (
  <div className="PasswordSent">
    <h4 className="text-left">Recovery Email Sent</h4>
    <p className="text-left">
      <small>Check your email for a message with instructions to reset your password.</small>
    </p>
    <div className="grid-x">
      <div className="small-12 medium-6 cell">
        <Link to="/login">
          <input type="button" className="button expanded login-btn" value="Login Again" />
        </Link>
      </div>
    </div>
  </div>
);

export default PasswordSent;
