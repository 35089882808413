/* eslint no-use-before-define: 0 */
import reduxStore from "../../reduxStore";
import {
  navSubPage,
  navPage,
  navAction,
  setBudgets,
  addTactics,
  loadTactics,
  openTactcisPopup,
  openSaveDialog,
  openDeleteDialog,
  lineActionADD,
  lineActionDEL,
  lineActionALT,
  lineActionALTMas,
  lineActionCLO,
  lineActionCLOReset,
  linesActionRESET,
  resetAllBPState,
  loadTraffics,
  adPopupClose,
  adPopupOpen,
  loadTrafficLandingPages,
  linesHasErrorsAdd,
  linesHasErrorsRemove,
  recalculateFormulas,
  navCustomView,
  setFilters,
  setDataBus,
  setDataBusFormatting,
} from "../../actions/BPActions";
import {
  getTacticSel,
  getActiveProjectSel,
  getLineById,
  getChangedLines,
  isLinesEdited,
  getTacticsGrossMediaFromChildren,
  getChildrenByParentId,
  getBPPlatformByUploadInfoSel,
  getPlatformFromBlockingLineSel,
  getLinesErrors,
  getBlockingLinesByParentLvl1,
  getCustomViewSel,
  getBlockingLines,
  getFiltersSel,
  getDataBusSel,
  getDataBusFormattingSel,
} from "../../selectors/BPSelectors";
import { bpServiceStatic } from "./BPService";
import {
  updateProjectBudget,
  getProjectsBudgetSelBound,
} from "../../projects/ProjectReduxUtils";
import { formatForDb } from "../../utils/DateUtils";
// Todo Rename to Bound

export const resetAllBPStateBound = () =>
  reduxStore.dispatch(resetAllBPState());

export const navSubPageUtil = (page) => reduxStore.dispatch(navSubPage(page));

// deprecated
export const navSubUtil = (page) => reduxStore.dispatch(navPage(page));

export const navCustomViewBound = (customView) =>
  reduxStore.dispatch(navCustomView(customView));

export const navigationActionBound = (page, subPage, projectId) =>
  reduxStore.dispatch(navAction(page, subPage, projectId));

export const alteredLineBound = (line) =>
  reduxStore.dispatch(lineActionALT(line));
export const addedLineBound = (line) =>
  reduxStore.dispatch(lineActionADD(line));
export const deletedLineBound = (line) =>
  reduxStore.dispatch(lineActionDEL(line));
export const cloneLineBound = (line) =>
  reduxStore.dispatch(lineActionCLO(line));
export const cloneLineResetBound = (line) =>
  reduxStore.dispatch(lineActionCLOReset(line));

export const openTacticsPopupBound = (open, lineId) =>
  reduxStore.dispatch(openTactcisPopup(open, lineId));

export const adPopupCloseBound = () => reduxStore.dispatch(adPopupClose());
export const adPopupCloseOpen = (lineId, typeId) =>
  reduxStore.dispatch(adPopupOpen(lineId, typeId));
export const openSaveDialogBound = (open) =>
  reduxStore.dispatch(openSaveDialog(open));
export const openDeleteDialogBound = (open, lineToDelete) =>
  reduxStore.dispatch(openDeleteDialog(open, lineToDelete));

export const getTacticsGrossMediaFromChildrenBound = () =>
  getTacticsGrossMediaFromChildren(reduxStore.getState());

export const linesHasErrorsAddBound = (lineId, errorBody) =>
  reduxStore.dispatch(linesHasErrorsAdd(lineId, errorBody));

export const linesHasErrorsRemoveBound = (lineId) =>
  reduxStore.dispatch(linesHasErrorsRemove(lineId));

export const getLineByIdBound = (lineId) =>
  getLineById(reduxStore.getState(), lineId);

export const getBlockingLinesByParentLvl1Bond = (parentId) =>
  getBlockingLinesByParentLvl1(reduxStore.getState(), parentId);

export const recalculateFormulasBound = (projectId) =>
  reduxStore.dispatch(recalculateFormulas(projectId));

export const getCustomViewSelBound = () => {
  return getCustomViewSel(reduxStore.getState());
};

window.recalculateFormulasBound = recalculateFormulasBound;

export const getBlockingLinesSelBound = () => {
  return getBlockingLines(reduxStore.getState);
};

export const setFiltersBound = (type, filter) => {
  reduxStore.dispatch(setFilters(type, filter));
};

export const getFilters = () => {
  return getFiltersSel(reduxStore.getState());
};

export const setDataBusBound = (level, data) => {
  reduxStore.dispatch(setDataBus(level, data));
};

export const getDataBus = (level) => {
  return getDataBusSel(reduxStore.getState(), level);
};

export const setDataBusFormattingBound = (level, data) => {
  reduxStore.dispatch(setDataBusFormatting(level, data));
};

export const getDataFormattingBus = (level) => {
  return getDataBusFormattingSel(reduxStore.getState(), level);
};

/**
 * If user touched drop down component creates value,label pair, if not it just a int.
 * @param {object} obj
 */
const getListVal = (obj) => {
  if (obj.value) {
    return obj.value;
  }
  return obj;
};

/**
 * Parses data to db friendly format.
 * @param {object} obj
 */
const getDateVal = (obj) => formatForDb(obj);

/**
 * Returns parsed-normalized value from object
 * @param {object} val
 * @param {string} nameOfField
 */
const normalizeValue = (val, nameOfField) => {
  let retVal = val;
  if (nameOfField.indexOf("list") === 0) {
    retVal = getListVal(val);
  }
  if (nameOfField.indexOf("platformId") === 0) {
    retVal = getListVal(val);
  }
  if (nameOfField.endsWith("Id")) {
    retVal = getListVal(val);
  }
  if (nameOfField.indexOf("date") === 0) {
    retVal = getDateVal(val);
  }
  return retVal;
};

const nullOutFormulaErrorValues = (lineFormulaErrors, val, nameOfField) => {
  if (lineFormulaErrors.errs[nameOfField]) {
    return null;
  }
  return val;
};
/**
 * Fixes list (drop down), from object returned from component makes only numeric value.
 * @param {object} mergedLine whole line.
 */
const fixDropValues = (mergedLine, lineFormulaErrors) => {
  const retVal = {};
  Object.keys(mergedLine).forEach((key) => {
    // some values are nulls, and should stay nulls
    if (mergedLine[key]) {
      // const v = mergedLine[key];
      // retVal[key] = getValueFromListObj(key, mergedLine, v);
      retVal[key] = normalizeValue(mergedLine[key], key);
      if (lineFormulaErrors) {
        retVal[key] = nullOutFormulaErrorValues(
          lineFormulaErrors,
          retVal[key],
          key
        );
      }
    } else {
      retVal[key] = null;
    }
  });
  return retVal;
};

const updateChildren = (parentId, onOk, onFail) => {
  const children = getChildrenByParentId(reduxStore.getState(), parentId);
  reduxStore.dispatch(lineActionALTMas(children));
  return saveSmartLines(onOk, onFail);
};

/**
 * Used to save tactic
 * @param {object} dataObj tactics line
 * @param {func} onOk callback on success
 * @param {func} onFail callback on fail
 */
export const addUpdateTacticsBound = (dataObj, onOk, onFail) => {
  const body = fixDropValues(dataObj);
  bpServiceStatic.postTactic(
    dataObj.projectId,
    body,
    (dataOut) => {
      reduxStore.dispatch(addTactics(dataOut));
      updateChildren(
        dataOut[0].id,
        () => {
          if (onOk) onOk();
        },
        () => {
          if (onFail) onFail();
        }
      );
    },
    () => {
      if (onFail) onFail();
    }
  );
};

export const dismissSmartLineChanged = () => {
  reduxStore.dispatch(linesActionRESET());
};

/**
 * Saves all new and edited lines, get data from redux store, combines and makes call.
 * Removes ids if needed, fixes drop down object to have only plain ids.
 * @param {func} onOk callback on success
 * @param {func} onFail callback on fail
 */
export const saveSmartLines = (onOk, onFail) => {
  const addedLines = getChangedLines(reduxStore.getState(), "added");
  const alteredLines = getChangedLines(reduxStore.getState(), "altered");
  const formulaErrors = getLinesErrors(reduxStore.getState());
  const postMerged = [];
  let alteredMerged = [];
  let pId;

  const blocking = "postPutLines";
  const traffic = "postPutTrafficLines";
  let fn = blocking;
  let idForTraffic = 0;
  if (alteredLines.length > 0 && alteredLines[0].blockingPlanLineId) {
    idForTraffic = alteredLines[0].blockingPlanLineId;
  } else if (addedLines.length > 0 && addedLines[0].blockingPlanLineId) {
    idForTraffic = addedLines[0].blockingPlanLineId;
  }
  if (idForTraffic > 0) {
    fn = traffic;
    pId = idForTraffic;
    // postMerged = addedLines;
    addedLines.forEach((line) => {
      // eslint-disable-next-line no-param-reassign
      delete line.id;
      postMerged.push(line);
    });
    alteredMerged = alteredLines;
  } else {
    if (addedLines.length > 0) {
      addedLines.forEach((line) => {
        const lineFormulaErrors = formulaErrors[line.id];
        const { mergedLine, fixedMergedLine, parent } = mergeParentAndChild(
          line,
          lineFormulaErrors
        );
        // id is nulled because its uuid for new
        mergedLine.id = null;
        pId = parent.projectId;
        postMerged.push(fixedMergedLine);
      });
    }
    if (alteredLines.length > 0) {
      alteredLines.forEach((line) => {
        const lineFormulaErrors = formulaErrors[line.id];
        const { mergedLine, fixedMergedLine } = mergeParentAndChild(
          line,
          lineFormulaErrors
        );
        pId = mergedLine.projectId;
        alteredMerged.push(fixedMergedLine);
      });
    }
  }
  bpServiceStatic[fn](
    pId,
    postMerged,
    alteredMerged,
    (valPost, valPut) => {
      const merged = [...valPost, ...valPut];
      if (idForTraffic > 0) {
        const idsFix = {};
        merged.forEach((line) => {
          idsFix[line.id] = line;
        });
        reduxStore.dispatch(loadTraffics(idsFix));
      } else {
        reduxStore.dispatch(addTactics(merged));
      }
      reduxStore.dispatch(linesActionRESET());
      if (onOk) onOk(merged);
    },
    (err) => {
      if (onFail) onFail(err);
    }
  );
};

export const deleteSmartLine = (line, onOk, onFail) => {
  const traffic = "deleteDynamic2";
  const blocking = "deleteLine";
  let fn = blocking;
  if (line.blockingPlanLineId) {
    fn = traffic;
  }

  bpServiceStatic[fn](
    line.id,
    () => {
      reduxStore.dispatch(lineActionDEL(line));
      reduxStore.dispatch(linesActionRESET());
      if (onOk) onOk();
    },
    (err) => {
      if (onFail) onFail(err);
    }
  );
};

export const loadTacticsBound = (tacticsArray) => {
  reduxStore.dispatch(loadTactics(tacticsArray));
};

export const loadTrafficsBound = (trafficsArray) => {
  reduxStore.dispatch(loadTraffics(trafficsArray));
};

export const loadLandingPageBound = (landingPages) => {
  reduxStore.dispatch(loadTrafficLandingPages(landingPages));
};

export const getBPPlatformByUploadInfoBound = (upladInfoId) =>
  getBPPlatformByUploadInfoSel(reduxStore.getState(), upladInfoId);

export const getPlatformFromBlockingLineBound = (blockingLineId) =>
  getPlatformFromBlockingLineSel(reduxStore.getState(), blockingLineId);

export const getSingleTactic = (id) => getTacticSel(reduxStore.getState(), id);

export const getActiveProjectId = () =>
  getActiveProjectSel(reduxStore.getState());
export const isLinesEditedBound = () => isLinesEdited(reduxStore.getState());

export const setBudgetsBound = (data) => {
  reduxStore.dispatch(setBudgets(data));
};

export const setBudgetsUtil = (budgets, callbackOk) => {
  const tmp = {
    total: budgets,
    left: budgets,
  };
  const pid = getActiveProjectId();
  updateProjectBudget(pid, Number(budgets), () => {
    tmp.total = getProjectsBudgetSelBound(pid);
    setBudgetsBound(tmp);
    if (callbackOk) callbackOk();
  });
};

function mergeParentAndChild(line, lineFormulaErrors) {
  const parent = getLineById(reduxStore.getState(), line.parentId);
  const mergedLine = {};

  const parentKeys = [];
  Object.keys(parent).forEach((key) => {
    parentKeys.push(key);
  });

  const lineKeys = [];
  Object.keys(line).forEach((key) => {
    lineKeys.push(key);
  });

  const missingKeys = parentKeys.filter(
    (pk) => !lineKeys.find((lk) => pk === lk)
  );

  Object.keys(line).forEach((elem) => {
    if (
      parent[elem] !== null &&
      (elem.indexOf("Column") > 0 || elem.indexOf("platformId") === 0)
    ) {
      mergedLine[elem] = parent[elem];
    } else {
      mergedLine[elem] = line[elem];
    }
  });

  missingKeys.forEach((elem) => {
    if (
      parent[elem] !== null &&
      (elem.indexOf("Column") > 0 || elem.indexOf("platformId") === 0)
    ) {
      mergedLine[elem] = parent[elem];
    } else {
      mergedLine[elem] = line[elem];
    }
  });

  const fixedMergedLine = fixDropValues(mergedLine, lineFormulaErrors);
  return { mergedLine, fixedMergedLine, parent };
}
