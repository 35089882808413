import { isArray } from "util";

import { getProjectSMFGoalsBound } from "../../projects/ProjectReduxUtils";
import { getMetaListsFromCommon } from "../../selectors/commonSelectors";
import { ColumnSettings } from "../../utils/Settings";

export const getListForColFactory = (projectId, dynamicLevel) => {
  return (name) => {
    const allLists = getMetaListsFromCommon(projectId);
    if (allLists.meta) {
      let listData = [];
      if (name.indexOf("smfDataGroupId") === 0) {
        listData = getSMFList(projectId);
      } else {
        listData = getListData(name, allLists, dynamicLevel);
      }
      return listData;
    } else {
      return [];
    }
  };
};

export const getListForColFactory2 = (projectId) => {
  return (name, id) => {
    const allLists = getMetaListsFromCommon(projectId);
    const idnum = parseInt(id, 10);
    if (allLists.meta) {
      let listData = [];
      if (name.indexOf("smfDataGroupId") === 0) {
        listData = getSMFList(projectId);
      } else {
        listData = getListData(name, allLists);
      }
      const found = listData.find((l) => l.value === idnum) || "";
      if (found) {
        return found.label;
      }
      return "N/A";
    } else {
      return "N/A";
    }
  };
};

/**
 * Returns singe list object from list label, ready for the imports
 *
 * Returns null not found
 *
 * @param {number} projectId
 */
export const getListForColFactory3 = (projectId) => {
  return (fieldName, label) => {
    const allLists = getMetaListsFromCommon(projectId);
    if (allLists.meta) {
      let listData = [];
      if (fieldName.indexOf("smfDataGroupId") === 0) {
        listData = getSMFList(projectId);
      } else {
        listData = getListData(fieldName, allLists);
      }
      const found = listData.find((l) => safeCompare(l.label, label)) || null;
      if (found) {
        return found;
      }
      return null;
    } else {
      return null;
    }
  };
};

export const safeCompare = (v1, v2) => {
  return v1.trim().toLowerCase() === v2.trim().toLowerCase();
};

export const isNAList = (val) => {
  return safeCompare(val, "N/A") || safeCompare(val, "");
};

export const getListData = (name, lists, dynamicLevel) => {
  const retVal = [];
  if (lists.meta) {
    const listId = lists.meta[name];
    if (lists.lists[listId]) {
      lists.lists[listId].forEach((ls) => {
        if (dynamicLevel) {
          if (dynamicLevel === ls.type) {
            //FIXME: remove this check when we don't have empty vals
            if (ls.listValue.trim().length > 0) {
              retVal.push({
                value: ls.id,
                label: ls.listValue,
                meta: {
                  externalId: ls.externalId || "",
                  externalValue: ls.externalValue,
                  externalValue2: ls.externalValue2,
                },
                _meta: ls,
              });
            }
          }
        } else {
          //FIXME: remove this check when we don't have empty vals
          if (ls.listValue.trim().length > 0) {
            retVal.push({
              value: ls.id,
              label: ls.listValue,
              meta: {
                externalId: ls.externalId || "",
                externalValue: ls.externalValue,
                externalValue2: ls.externalValue2,
              },
              _meta: ls,
            });
          }
        }
      });
    }
    return retVal;
  }
  return [];
};

export const getAvailablePlaformData = (name, projectPlatforms) => {
  const retVal = [];
  projectPlatforms.forEach((ls) => {
    retVal.push({
      value: ls.id,
      label: ls.name,
    });
  });
  return retVal;
};

export const getSMFList = (projectId) => {
  const smfGoals = getProjectSMFGoalsBound(projectId) || [];
  return getAvailableSMFData(null, smfGoals);
};

export const getAvailableSMFData = (name, smf) => {
  if (!isArray(smf)) {
    return [];
  }
  const retObj = {};
  smf.forEach((ls) => {
    retObj[ls.smfDataGroup.id] = {
      value: ls.smfDataGroup.id,
      label: ls.smfDataGroup.name,
    };
  });
  const retValUnsorted = Object.values(retObj);
  const retVal = retValUnsorted.sort((a, b) => a.label.localeCompare(b.label));
  return retVal;
};

export const getListValue = (name, value, lists) => {
  const listId = lists.meta[name];
  return lists.lists[listId].find((val) => val.id === value);
};

export const generateViewMap = (view, meta, lists, projectPlatforms, smf) => {
  const retVal = [];
  if (lists.meta) {
    const sortedView = view.details.sort(
      (a, b) => Number(a.weight) - Number(b.weight)
    );
    sortedView.forEach((item) => {
      let optionsList = null;
      let listId = null;
      if (item.field.indexOf("list") === 0) {
        optionsList = getListData(item.field, lists);
        listId = lists.meta[item.field];
      }
      if (item.field.indexOf("platformId") === 0) {
        optionsList = getAvailablePlaformData(item.field, projectPlatforms);
      }

      if (item.field.indexOf("smfDataGroupId") === 0) {
        optionsList = getAvailableSMFData(item.field, smf);
      }

      if (
        [
          "text",
          "list",
          "float",
          "int",
          "date",
          "bool",
          "file",
          "smfDataGroupId",
          "platformId",
        ].includes(item.field.split("Column")[0])
      ) {
        const cs = new ColumnSettings(
          (meta.json && meta.json[item.field]) || null
        );

        retVal.push({
          name: item.field,
          label: (meta.titles && meta.titles[item.field]) || "",
          format: (meta.formats && meta.formats[item.field]) || "",
          formula: (meta.formulas && meta.formulas[item.field]) || "",
          tooltip: (meta.tooltips && meta.tooltips[item.field]) || "",
          json: (meta.json && meta.json[item.field]) || "",
          _settings: cs,
          options: optionsList,
          listId,
          _meta: item,
        });
      }
    });
  }
  return retVal;
};

export const addUploadInfoIdToObj = (obj, uploadInfoId) => ({
  ...obj,
  uploadInfoId,
});

export const adjustLandingPagesDataIds = (data, uploadInfoId) => {
  const dataCopy = {};
  data.forEach((item) => {
    dataCopy[item.id] = addUploadInfoIdToObj(item, uploadInfoId);
  });
  return dataCopy;
};
