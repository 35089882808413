import React from "react";
import PropTypes from "prop-types";
import Alert from "antd/lib/alert";
import {
  DEFAULT_DATE_FORMAT_FOR_DB,
  BP_LEVELS_FOR_HUMANS,
  BP_LEVELS,
} from "../../../shared/Constants";
import {
  isDate,
  isList,
  isFloat,
  isInt,
  isText,
  isMulti,
} from "../bpTable/BPTableDataUtils";

export const BPImportHeader = ({
  clickedElement,
  level,
  label,
  notSupported,
}) => {
  const dyn23 = level !== BP_LEVELS.DYN1;
  return (
    <div>
      <div className="header">
        <h5>
          Import Column Data for{" "}
          <em>
            {BP_LEVELS_FOR_HUMANS[level]} :: {label}
          </em>
        </h5>
        <small>
          {isMulti(clickedElement) && (
            <em>
              Type: <code>multi</code>
            </em>
          )}
          {isText(clickedElement) && (
            <em>
              Type: <code>text</code>
            </em>
          )}
          {isInt(clickedElement) && (
            <em>
              Type: <code>int</code>
            </em>
          )}
          {isFloat(clickedElement) && (
            <em>
              Type: <code>float</code>
            </em>
          )}
          {isList(clickedElement) && (
            <em>
              Type: <code>list</code>
            </em>
          )}
          {isDate(clickedElement) && (
            <em>
              Format: <code>{DEFAULT_DATE_FORMAT_FOR_DB}</code>
            </em>
          )}
        </small>
      </div>
      {dyn23 && (
        <div>
          For this import please use following format:
          <code>ID &lt;tab&gt; Value</code> <br /> Where ID is parent level ID.
          This is default Excel format for copping two columns.
        </div>
      )}
      {notSupported && <Alert message="Field not supported" type="warning" />}
    </div>
  );
};

BPImportHeader.propTypes = {
  clickedElement: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  notSupported: PropTypes.bool.isRequired,
};
