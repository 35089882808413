import moment from "moment";
import message from "antd/lib/message";
import { bpServiceStatic } from "../../bp/BPService";
import { FLHServiceStatic, EVENT_IDS } from "../../../services/FLHService";
import {
  DEFAULT_DATE_FORMAT_FOR_DB,
  BP_LEVELS,
  BPVIEWS,
  BP_TABLE,
} from "../../../shared/Constants";
import { setDataBusBound } from "../bpUtils";
import { setHooksValidationBound } from "../../../actions/MediaPlanActionsUtils";
import { errorLog } from "../../../utils/ErrorTracker";
import eventManager from "../../../shared/EventManager";
import { MEDIAPLAN_RELOAD } from "../../../types/types";

const KEY_NAME = "textColumn1";

const PROJECTS_IGNORE_LINE_VALIDATION =
  process.env.REACT_APP_PROJECTS_IGNORE_LINE_VALIDATION;

const PROJECTS_IGNORE_LINE_VALIDATION_ARR = PROJECTS_IGNORE_LINE_VALIDATION.split(
  ","
).map((id) => Number(parseInt(id, 10)));

export const doIgnoreProjectValidation = (projectId) => {
  console.log(
    "[BPTableDataUtils.js] PROJECTS_IGNORE_LINE_VALIDATION X",
    PROJECTS_IGNORE_LINE_VALIDATION
  );
  console.log(
    "[BPTableDataUtils.js] PROJECTS_IGNORE_LINE_VALIDATION_ARR",
    PROJECTS_IGNORE_LINE_VALIDATION_ARR,
    projectId
  );
  return PROJECTS_IGNORE_LINE_VALIDATION_ARR.includes(projectId);
};

export const overrideFromImports = (
  importArr,
  element,
  dataIn,
  editedIdsIndex,
  view,
  projectId,
  getListForColFn,
  dynamicLevel,
  idKey,
  parentIdKey,
  filter,
  cbState
) => {
  // BP_LEVELS.DYN2, BP_LEVELS.DYN3
  console.log("[BPTableDataUtils.js] ----- IMPORT START", 1);
  const data = [...dataIn];
  let listVals = [];
  if (isList(element)) {
    listVals = getListForColFn(element);
    console.log("[BPTableDataUtils.js] listVals", listVals);
  }

  if (data.length < importArr.length) {
    const diff = importArr.length - data.length;
    for (let i = 0; i <= diff; i++) {
      const newLine = makeEmptyLineOnly(view, projectId);
      data.push(newLine);
    }
  }

  if (dynamicLevel !== BP_LEVELS.DYN1) {
    console.log("[BPTableDataUtils.js] === START Dyn2/3");
    bpServiceStatic.loadDynAll(projectId, (lookupData) => {
      console.log("[BPTableDataUtils.js] WTF", 11111);
      const importObj = {};
      lookupData.meta.dynamic2.formats = lookupData.meta.dynamic2.formats || {};
      lookupData.meta.dynamic3.formats = lookupData.meta.dynamic3.formats || {};
      lookupData.meta.dynamic2.formulas =
        lookupData.meta.dynamic2.formulas || {};
      lookupData.meta.dynamic3.formulas =
        lookupData.meta.dynamic3.formulas || {};
      lookupData.meta.dynamic2.tooltips =
        lookupData.meta.dynamic2.tooltips || {};
      lookupData.meta.dynamic3.tooltips =
        lookupData.meta.dynamic3.tooltips || {};
      lookupData.meta.dynamic2.titles = lookupData.meta.dynamic2.titles || {};
      lookupData.meta.dynamic3.titles = lookupData.meta.dynamic3.titles || {};

      importArr.forEach((imp, idx) => {
        const candidate = imp.split("\t");
        const key = candidate[0];
        const val = candidate[1];
        if (!importObj[key]) {
          importObj[key] = [];
        }
        importObj[key].push(val);
      });

      if (dynamicLevel === BP_LEVELS.DYN2) {
        console.log("[BPTableDataUtils.js] ---- START --- lvl:", dynamicLevel);
        lookupData.dynamic1.forEach((d1Elem, d1Idx) => {
          for (const key in importObj) {
            if (comp(d1Elem[KEY_NAME], key)) {
              if (d1Elem.dynamic2.length < importObj[key].length) {
                const diff = importObj[key].length - d1Elem.dynamic2.length;
                console.log("[BPTableDataUtils.js] diff", diff);
                for (let i = 0; i < diff; i++) {
                  const newLine = makeEmptyLineOnly(view, projectId);
                  d1Elem.dynamic2.push(newLine);
                }
              }
              d1Elem.dynamic2.forEach((d2Elem, d2Idx) => {
                if (isList(element)) {
                  const comp = importObj[key][d2Idx]
                    ? importObj[key][d2Idx].toLowerCase().trim()
                    : "";
                  const needle = listVals.find(
                    (ls) => ls.label.toLowerCase().trim() === comp
                  );
                  if (needle) {
                    d2Elem[element] = needle.value;
                  }
                } else {
                  d2Elem[element] = importObj[key][d2Idx];
                }

                filter.forEach((f) => {
                  if (f.id !== element) {
                    let val = f.value;
                    if (f.value === BP_TABLE.FILTER_EMPTY) {
                      val = "";
                    }
                    d2Elem[f.id] = val;
                  }
                });
              });
            }
          }
        });

        console.log("lookupData", lookupData);
        console.log("[BPTableDataUtils.js] ---- END --- lvl:", dynamicLevel);
        bpServiceStatic.saveAllDyn(projectId, lookupData, (dataBack) => {
          const dataToShow = dataBack.dynamic1.find((db) => db.id === idKey);
          return cbState(dataToShow.dynamic2);
        });
      }
      if (dynamicLevel === BP_LEVELS.DYN3) {
        console.log("[BPTableDataUtils.js] ---- START --- lvl:", dynamicLevel);
        lookupData.dynamic1.forEach((d1Elem, d1Idx) => {
          d1Elem.dynamic2.forEach((d2Elem) => {
            for (const key in importObj) {
              if (comp(d2Elem[KEY_NAME], key)) {
                if (d2Elem.dynamic3.length < importObj[key].length) {
                  const diff = importObj[key].length - d2Elem.dynamic3.length;
                  for (let i = 0; i < diff; i++) {
                    const newLine = makeEmptyLineOnly(view, projectId);
                    d2Elem.dynamic3.push(newLine);
                  }
                }
                d2Elem.dynamic3.forEach((d3Elem, d3Idx) => {
                  if (isList(element)) {
                    const comp = importObj[key][d3Idx]
                      ? importObj[key][d3Idx].toLowerCase().trim()
                      : "";
                    const needle = listVals.find(
                      (ls) => ls.label.toLowerCase().trim() === comp
                    );
                    if (needle) {
                      d3Elem[element] = needle.value;
                    }
                  } else {
                    d3Elem[element] = importObj[key][d3Idx];
                  }
                  // d3Elem[element] = importObj[key][d3Idx];

                  filter.forEach((f) => {
                    if (f.id !== element) {
                      let val = f.value;
                      if (f.value === BP_TABLE.FILTER_EMPTY) {
                        val = "";
                      }
                      d3Elem[f.id] = val;
                    }
                  });
                });
              }
            }
          });
        });

        console.log("lookupData", lookupData);
        console.log("[BPTableDataUtils.js] ---- END --- lvl:", dynamicLevel);
        bpServiceStatic.saveAllDyn(projectId, lookupData, (dataBack) => {
          const dataToShow = dataBack.dynamic1.find(
            (db) => db.id === parentIdKey
          );
          const dataToShow2 = dataToShow.dynamic2.find((db) => (db.id = idKey));
          return cbState(dataToShow2.dynamic3);
        });
      }
    });
    console.log("[BPTableDataUtils.js] === END Dyn2/3");
  } else {
    importArr.forEach((imp, idx) => {
      if (isInt(element)) {
        const intVal = parseInt(imp);
        numberValSet(intVal, data, idx, element);
      } else if (isFloat(element)) {
        const intVal = parseFloat(imp);
        numberValSet(intVal, data, idx, element);
      } else if (isList(element) || isListSMF(element)) {
        // TODO add smf support
        listValSet(listVals, imp, data, idx, element);
      } else if (isDate(element)) {
        dateValSet(imp, data, idx, element);
      } else {
        data[idx][element] = imp;
      }
      filter.forEach((f) => {
        if (f.id !== element) {
          let val = f.value;
          if (f.value === BP_TABLE.FILTER_EMPTY) {
            val = "";
          }
          data[idx][f.id] = val;
        }
      });
      editedIdsIndex.add(idx);
    });
    return cbState(data);
  }
  console.log("[BPTableDataUtils.js] ----- IMPORT END", 1);
};

export const makeEmptyLineOnly = (view, projectId) => {
  const retVal = {};
  view.forEach((el) => {
    retVal[el.name] = null;
  });
  retVal.projectId = projectId;
  retVal._isNew = true;
  return retVal;
};

export const appendFromImports = () => { };

export const isBool = (name) => {
  return name.indexOf("bool") === 0;
};
export const isFloat = (name) => {
  return name.indexOf("float") === 0;
};
export const isInt = (name) => {
  return name.indexOf("int") === 0;
};
export const isText = (name) => {
  return name.indexOf("text") === 0;
};
export const isMulti = (name) => {
  return name.indexOf("multi") === 0;
};
export const isList = (name) => {
  return name.indexOf("list") === 0;
};
export const isListSMF = (name) => {
  return name.indexOf("smfDataGroupId") === 0;
};

export const isListOrSMF = (name) => {
  return isList(name) || isListSMF(name);
};

export const isDate = (name) => {
  return name.indexOf("date") === 0;
};
function dateValSet(imp, data, idx, element) {
  const momentDate = moment(imp);
  data[idx][element] = momentDate.isValid()
    ? momentDate.format(DEFAULT_DATE_FORMAT_FOR_DB)
    : null;
}

function listValSet(listVals, imp, data, idx, element) {
  const needle =
    listVals.find((lv) => {
      try {
        return lv.label.toLowerCase() === imp.toLowerCase();
      } catch (e) {
        return false;
      }
    }) || null;
  data[idx][element] = needle ? needle.value : null;
}

function numberValSet(intVal, data, idx, element) {
  if (isNaN(intVal)) {
    data[idx][element] = 0;
  } else {
    data[idx][element] = intVal;
  }
}

const comp = (a, b) => {
  return (a || "").trim().toLowerCase() === (b || "").trim().toLowerCase();
};

/**
 * Validates data, returns true or false
 * @param {any} val new value
 * @param {array} allData data from state
 * @param {string} fieldName like textColumn1
 * @param {string} backupValue backupValue
 * @param {number} projectId for some excludes checks
 */
export const isValid = (val, allData, fieldName, backupValue, projectId) => {
  let valid = true;
  let msg = "Data is not valid!";

  const ignore = doIgnoreProjectValidation(projectId);

  if (fieldName === BPVIEWS.KNOWN_CONST.LINE_ID) {
    if (val.trim().length === 0) {
      msg = "Line ID has to be always present!";
      valid = false;
    } else {
      const reg = val.match(/^[a-zA-Z0-9-]+$/);
      if (!ignore && !reg) {
        msg = "Letters, numbers and - allowed only!";
        valid = false;
      } else {
        if (val !== backupValue) {
          msg = "Duplicated ID numbers are not allowed";

          const f = allData.find(
            (ad) => ad[BPVIEWS.KNOWN_CONST.LINE_ID] === val
          );
          valid = !!!f;
        } else {
          // if we caching back to backup value we assume that is not dupe
          valid = true;
        }
      }
    }
  }

  return { valid, msg };
};

export const validateImport = (data, fieldName, dynLevel) => {
  let valid = true;
  let msg = "Data is not valid!";
  // NOTE for now we disable imports for id cols, leaving as example
  // if (fieldName === BPVIEWS.KNOWN_CONST.LINE_ID) {
  //   msg = "Duplicated ID numbers are not allowed";

  //   let seen = new Set();
  //   let store = [];
  //   let cleanData = [];
  //   if (dynLevel === BP_LEVELS.DYN1) {
  //     cleanData = data.map((line) => {
  //       const sp = line.split(/\t/);
  //       return sp.pop();
  //     });
  //   } else {
  //     cleanData = data;
  //   }

  //   let regSeen = new Set();

  //   cleanData.forEach(item => {
  //     const reg = item.match(/^[a-zA-Z0-9-]+$/);
  //     if(!reg) regSeen.add(item);
  //   })

  //   cleanData.filter(
  //     (item) =>
  //       seen.size === seen.add(item.toLowerCase()).size &&
  //       !store.includes(item.toLowerCase()) &&
  //       store.push(item.toLowerCase())
  //   );

  //   valid = store.length === 0;
  // }
  return { valid, msg };
};

export const fireOnDataChange = (DXString, sortedData) => {
  setDataBusBound(DXString, sortedData);
  fireHooksValidator(DXString, sortedData);
};

/**
 *
 * @param {string} DXString dxstring
 * @param {array} data array of objects, looking for id.
 */
export const fireHooksValidator = (DXString, data) => {
  const ids = data.map((d) => d.id);
  if (ids.length === 0) return;

  console.log("[BPTableDataUtils.js] fire", ids, DXString);
  const eventId = EVENT_IDS[DXString.toUpperCase()];
  if (eventId) {
    FLHServiceStatic.getHookReadinessInfo(0, eventId, ids, (dataBack) => {
      setHooksValidationBound(dataBack);
    });
  } else {
    errorLog(
      `Event Id for Event Hook Readiness cannot be decoded, got: "${DXString}"`
    );
  }
};

export const fireSingleHookSingleEntity = (
  DXString,
  hookId,
  entityId,
  hookName
) => {
  const eventId = EVENT_IDS[DXString.toUpperCase()];
  if (eventId) {
    FLHServiceStatic.execEventByHookIds(eventId, [hookId], [entityId], () => {
      message.info(`Event Fired for hook '${hookName}'`);
      eventManager.emit(MEDIAPLAN_RELOAD);
    });
  } else {
    errorLog(
      `Event Id for Event Hook Readiness cannot be decoded, got: "${DXString}"`
    );
  }
};

export const getDXIdsOnly = (projectId, DXString, dataBackFn) => {
  bpServiceStatic.loadDynAll(projectId, (data) => {
    let retVal = [];
    if (DXString === "dynamic1") {
      retVal = [...data.dynamic1];
    } else if (DXString === "dynamic2") {
      data.dynamic1.forEach((d1line) => {
        retVal = [...retVal, ...d1line.dynamic2];
      });
    } else if (DXString === "dynamic3") {
      data.dynamic1.forEach((d1line) => {
        d1line.dynamic2.forEach((d2line) => {
          retVal = [...retVal, ...d2line.dynamic3];
        });
      });
    }
    dataBackFn(retVal);
  });
};
