import React from "react";
import PropTypes from "prop-types";
import Select from "antd/lib/select";
import Icon from "antd/lib/icon";
import "./ClientSelector.scss";
import { projectsServiceStatic } from "../../projects/ProjectsService";
import { history } from "../../utils/HistoryUtils";
import store from "store";
import { setSelectedClient } from "../../reducers/qndActions";

const CLIENTSELECTOR_KEY = "CLIENTSELECTOR_KEY";

class ClientSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuItems: [], isLoading: true, selected: 0 };
    this.menu = {};
    this.clientsById = {};
  }
  componentDidMount() {
    projectsServiceStatic.getCustomers((data) => {
      console.log("[ClientSelector.jsx] data", data);
      this.setState({ data });
      this.constructMenu(data);
      const saved = store.get(CLIENTSELECTOR_KEY);
      if (saved) {
        this.onSelect(saved);
        if (this.state.isLoading) {
          setTimeout(() => {
            this.setState({ selected: saved });
          }, 300);
        }
      }
    });
  }
  constructMenu = (data) => {
    const menuItems = [];
    data.forEach((element) => {
      menuItems.push({
        id: element.id,
        value: element.id,
        label: element.name,
      });
      this.clientsById[element.id] = element;
      element.children.forEach((el2) => {
        menuItems.push({ id: el2.id, value: el2.id, label: `- ${el2.name}` });
        this.clientsById[el2.id] = el2;
      });
    });
    this.setState({ menuItems: menuItems, isLoading: false });
  };

  onSelect(item) {
    this.setState({ selected: item });
    this.props.onSelect(item);
    const client = this.clientsById[item];
    setSelectedClient(client);
    store.set(CLIENTSELECTOR_KEY, item);
  }
  goToSettings() {
    if (Number(this.state.selected) !== Number(0)) {
      history.push(`/client/${this.state.selected}/edit`);
    }
  }

  render() {
    return (
      <div className="ClientSelector">
        <>
          <Select
            loading={this.state.isLoading}
            onSelect={(item) => this.onSelect(item)}
            className="selector"
            showSearch
            value={this.state.selected}
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLocaleLowerCase()) >= 0
              );
            }}
          >
            <Select.Option key={"0"} value={0}>
              All
            </Select.Option>
            {this.state.menuItems.map((it) => {
              return (
                <Select.Option key={it.id} value={it.value}>
                  {it.label}
                </Select.Option>
              );
            })}
          </Select>
          <span className="settings-suffix">
            <Icon
              className={`disabled-${this.state.selected === 0}`}
              type="setting"
              onClick={() => this.goToSettings()}
            />
          </span>
        </>
      </div>
    );
  }
}

export default ClientSelector;

ClientSelector.defaultProps = {};

ClientSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
};
