/** Place for performance related utils */

import axios from 'axios';
import { GAPerfAPI, GAPerfUI } from './GAUtils';

export const PerfAxiosTimer = () => {
  axios.interceptors.request.use(request => {
    request.ts = performance.now();
    return request;
  });

  axios.interceptors.response.use(response => {
    setTimeout(() => {
      GAPerfAPI(
        Math.round(Number(performance.now() - response.config.ts)),
        `${response.config.method.toUpperCase()} ${
          response.request.responseURL
        }`
      );
    }, 250);
    return response;
  });
};

/** UI Perf fn, it starts automtically on new, or with start(), call done() to mesure */
export const PerfUICallTimer = name => {
  let nameToShow = name;
  let startedDate = performance.now();
  return {
    start: () => {
      startedDate = performance.now();
    },
    done: newName => {
      nameToShow = newName || name;
      const calc = Math.round(Number(performance.now() - startedDate));
      setTimeout(() => {
        GAPerfUI(calc, nameToShow);
        // console.info('Perf::', nameToShow, calc, 'ms');
      }, 250);
    },
  };
};
