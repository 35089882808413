import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FasthooksCallback from "../../modals/fasthooks/FasthooksCallback";
import FasthooksMapsCallbacks from "./FasthooksMapsCallbacks";
import FasthooksConditions from "./FasthooksConditions";
import { tabsStyles, TabContainer } from "./FasthooksUtils";

class FasthooksTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  handleChange = (event, selectedTab) => {
    this.setState({ selectedTab });
  };
  render() {
    const { classes } = this.props;
    const { selectedTab } = this.state;
    return (
      <div className="FasthooksTabs">
        <div>
          <div>
            <Tabs
              value={selectedTab}
              onChange={this.handleChange}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label="Fasthook to Platform Map"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label="Callback to Fasthook Map"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label="Conditions"
              />
            </Tabs>
          </div>
          {selectedTab === 0 && (
            <TabContainer>
              <FasthooksMapsCallbacks
                event={this.props.event}
                hook={this.props.hook}
                viewCols={this.props.viewCols}
                onValuesCB={(data) => this.props.onValuesCB(data)}
                optionsDropDown={this.props.optionsDropDown}
                callbackKey="hookmap"
              />
            </TabContainer>
          )}
          {selectedTab === 1 && (
            <TabContainer>
              <FasthooksCallback
                event={this.props.event}
                hook={this.props.hook}
                onValuesCB={(data) => this.props.onValuesCB(data)}
                viewCols={this.props.viewCols}
                addNewCallBack={this.props.addNewCallBack}
                optionsDropDown={this.props.optionsDropDown}
              />
            </TabContainer>
          )}
          {selectedTab === 2 && (
            <TabContainer>
              <FasthooksConditions
                hook={this.props.hook}
                onValuesCB={(data) => this.props.onValuesCB(data)}
                optionsDropDown={this.props.optionsDropDown}
              />
            </TabContainer>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(tabsStyles)(FasthooksTabs);

FasthooksTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  hook: PropTypes.object.isRequired,
  viewCols: PropTypes.object.isRequired,
  onValuesCB: PropTypes.func.isRequired,
  addNewCallBack: PropTypes.func.isRequired,
  optionsDropDown: PropTypes.array.isRequired,
};
