import React from "react";
import PropTypes from "prop-types";
import Tabs from "antd/lib/tabs";
import Spin from "antd/lib/spin";
import Popconfirm from "antd/lib/popconfirm";
import ClientViewEditor from "./ClientViewEditor";
import moment from "moment";

import { bpServiceStatic } from "../../../pages/bp/BPService";
import { calcFields } from "../../../modals/customViews/CustomViewsUtils";
import { notify } from "../../../shared/notifications/Notify";

import CustomViewsName from "../../../modals/customViews/CustomViewsName";

import "./ClientViews.scss";
import Button from "antd/lib/button";

const ALLOWED_COLS = [
  "smfDataGroupId",
  "text",
  "float",
  "int",
  "list",
  "date",
  "bool",
  "file"
];

const viewSearchFn = (v, name, type) => {
  return v.viewName.toUpperCase() === name.toUpperCase() && v.type === type;
};

class ClientViews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newNameModal: false,
      theNewName: "",
      isLoading: false,
      isLoadingAll: true,
      activeKey: "",
      viewsToShow: [],
      DXData: {
        d1: [],
        d1Selected: [],
        d2: [],
        d2Selected: [],
        d3: [],
        d3Selected: [],
        d1Id: 0,
        d2Id: 0,
        d3Id: 0
      },
      d1IdEdited: 0,
      isDirty: false
    };
    this.views = [];
    this.metasDX = [];
    this.newViewNamePrefix = moment().format("YYMMDD hhmm");
    this.newViewNameCounter = 1;
  }
  componentDidMount() {
    bpServiceStatic.loadDefaultMetas(
      this.props.clientId,
      (views, metasDX, x, xx) => {
        this.metasDX = metasDX;

        this.views = views;

        const viewsToShow = views.filter(
          v => v.type === "dynamic1" && v.viewName !== "AllColumns"
        );
        this.calcViews(viewsToShow[0].viewName, views, metasDX);

        this.setState({
          viewsToShow,
          activeKey: Number(viewsToShow[0].id).toString(),
          isLoadingAll: false
        });
      }
    );
  }

  calcViews = (viewName, views, metasDX) => {
    const dTmp1 = this.calcSingle(viewName, views, metasDX, "1");
    const dTmp2 = this.calcSingle(viewName, views, metasDX, "2");
    const dTmp3 = this.calcSingle(viewName, views, metasDX, "3");
    const DXData = {
      d1: dTmp1.dX,
      d1Selected: dTmp1.dXSelected,
      d2: dTmp2.dX,
      d2Selected: dTmp2.dXSelected,
      d3: dTmp3.dX,
      d3Selected: dTmp3.dXSelected,
      d1Id: dTmp1.dXId,
      d2Id: dTmp2.dXId,
      d3Id: dTmp3.dXId
    };
    this.setState({ DXData });
  };

  calcSingle = (viewName, views, metasDX, DXStrNum) => {
    const DXIdx = parseInt(DXStrNum) - 1;

    const inSelectedViewDX = views.find(v =>
      viewSearchFn(v, viewName, "dynamic" + DXStrNum)
    );
    const dX = [];
    Object.keys(metasDX[DXIdx].titles).forEach(key => {
      if (ALLOWED_COLS.includes(key.split("Column")[0])) {
        const title = metasDX[DXIdx].titles[key];
        if (title !== null) {
          if (title.trim().length > 0) {
            dX.push({
              key: key,
              title: title
            });
          }
        }
      }
    });
    const dXSelected = [];
    inSelectedViewDX.details.sort((a, b) => a.weight - b.weight);
    inSelectedViewDX.details.forEach(d => {
      dXSelected.push(d.field);
    });

    const dXId = inSelectedViewDX.id;

    return { dX, dXSelected, dXId };
  };

  handleChange123 = (
    targetKeys,
    direction,
    moveKeys,
    varName,
    dxNumber,
    ids
  ) => {
    let merged = [];
    if (direction === "right") {
      merged = [...this.state.DXData[varName], ...moveKeys];
    } else {
      merged = [...targetKeys];
    }

    const DXData = { ...this.state.DXData };
    DXData[varName] = merged;

    this.setState({ DXData, d1IdEdited: ids.d1Id, isDirty: true }, () => {});
  };

  onDragEdit = (varName, fromIndex, toIndex, dxNumber, ids) => {
    const dXSelected = [...this.state.DXData[varName]];
    const item = dXSelected.splice(fromIndex, 1)[0];
    dXSelected.splice(toIndex, 0, item);

    this.handleChange123(dXSelected, "left", null, varName, dxNumber, ids);
  };

  onSelectView = activeKey => {
    const viewNameTmp = this.state.viewsToShow.find(
      v => Number(v.id) === Number(activeKey)
    );

    this.calcViews(viewNameTmp.viewName, this.views, this.metasDX);
    this.setState({ activeKey: Number(activeKey).toString() });
  };

  onRename = (ids, newName) => {
    this.views.find(v => v.id === ids.d1Id).viewName = newName;
    this.views.find(v => v.id === ids.d2Id).viewName = newName;
    this.views.find(v => v.id === ids.d3Id).viewName = newName;

    const viewsToShow = [...this.state.viewsToShow];
    viewsToShow.find(v => v.id === ids.d1Id).viewName = newName;
    this.setState({ viewsToShow, isDirty: true });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  add = () => {
    const { viewsToShow } = this.state;
    if (viewsToShow.find(v => v._isNew)) {
      notify(
        "No can do",
        "Please save new view before adding another one",
        "warning"
      );
    } else {
      const activeKey1 = +new Date();
      const activeKey2 = activeKey1 + 1;
      const activeKey3 = activeKey1 + 2;

      const theName = `New View ${this.newViewNamePrefix} ${this
        .newViewNameCounter++}`;

      viewsToShow.push({
        viewName: theName,
        id: activeKey1,
        _isNew: true
      });

      this.views.push({
        id: activeKey1,
        viewName: theName,
        details: [],
        customerId: this.props.clientId,
        type: "dynamic1",
        _isNew: true
      });
      this.views.push({
        id: activeKey2,
        viewName: theName,
        details: [],
        customerId: this.props.clientId,
        type: "dynamic2",
        _isNew: true
      });
      this.views.push({
        id: activeKey3,
        viewName: theName,
        details: [],
        customerId: this.props.clientId,
        type: "dynamic3",
        _isNew: true
      });
      this.setState({ theNewName: theName, newNameModal: true, isDirty: true });
      this.onSelectView(activeKey1);
    }
  };

  canDelete = viewName => {
    // can only delete if there is 1 view public,
    // so we have all_cols view (d123 = 3) and one more (d123 = 3)
    const ALL_COLS = 3;
    const ONE_MORE = 3;
    return this.views.length > ALL_COLS + ONE_MORE;
  };
  onDeleteView() {
    const d1Prep = {
      ...this.views.find(v => v.id === this.state.DXData.d1Id)
    };
    const d2Prep = {
      ...this.views.find(v => v.id === this.state.DXData.d2Id)
    };
    const d3Prep = {
      ...this.views.find(v => v.id === this.state.DXData.d3Id)
    };
    if (this.canDelete(d1Prep.viewName)) {
      d1Prep.delete = true;
      d2Prep.delete = true;
      d3Prep.delete = true;
      const viewsArray = [d1Prep, d2Prep, d3Prep];
      this.setState({ isLoading: true });
      bpServiceStatic.saveDefaultViews(
        this.props.clientId,
        viewsArray,
        dataOk => {
          notify("View deleted!", "Carry on");
          this.views = dataOk;
          const viewsToShow = this.views.filter(
            v => v.type === "dynamic1" && v.viewName !== "AllColumns"
          );

          this.calcViews(viewsToShow[0].viewName, this.views, this.metasDX);

          this.setState({
            viewsToShow,
            activeKey: Number(viewsToShow[0].id).toString(),
            isLoading: false,
            d1IdEdited: 0,
            isDirty: false
          });
        },
        err => {
          notify("Error!", "Please try again later or contact admins", "error");
          this.setState({ isLoading: false });
        }
      );
    } else {
      notify("Sorry!", "One view has to be always available!", "warning");
    }
  }
  onSaveView() {
    const cf1 = calcFields(
      this.views[0],
      this.state.DXData.d1Selected,
      "dynamic1"
    );
    const cf2 = calcFields(
      this.views[1],
      this.state.DXData.d2Selected,
      "dynamic2"
    );
    const cf3 = calcFields(
      this.views[2],
      this.state.DXData.d3Selected,
      "dynamic3"
    );

    const d1Prep = { ...this.views.find(v => v.id === this.state.DXData.d1Id) };
    const d2Prep = { ...this.views.find(v => v.id === this.state.DXData.d2Id) };
    const d3Prep = { ...this.views.find(v => v.id === this.state.DXData.d3Id) };
    d1Prep.details = cf1;
    d2Prep.details = cf2;
    d3Prep.details = cf3;
    const viewsArray = [d1Prep, d2Prep, d3Prep];
    this.setState({ isLoading: true });

    bpServiceStatic.saveDefaultViews(
      this.props.clientId,
      viewsArray,
      dataOk => {
        notify("View Saved!");

        this.views = dataOk;
        const viewsToShow = this.views.filter(
          v => v.type === "dynamic1" && v.viewName !== "AllColumns"
        );
        this.calcViews(d1Prep.viewName, this.views, this.metasDX);
        const newView = viewsToShow.find(v => v.viewName === d1Prep.viewName);

        this.setState({
          viewsToShow,
          isLoading: false,
          activeKey: Number(newView.id).toString(),
          d1IdEdited: 0,
          isDirty: false
        });
      },
      err => {
        notify("Error!", "Please try again later or contact admins", "error");
        this.setState({ isLoading: false });
      }
    );
  }
  addNewName(DXData, newName) {
    this.setState({ newNameModal: false });
    const ids = {
      d1Id: DXData.d1Id,
      d2Id: DXData.d2Id,
      d3Id: DXData.d3Id
    };
    this.onRename(ids, newName);
  }
  getUsedNames = (viewsToShow, theNewName) => {
    return viewsToShow
      .filter(n => n.viewName !== theNewName)
      .map(n => n.viewName);
  };
  render() {
    return (
      <div className="ClientViews">
        {this.state.newNameModal && (
          <CustomViewsName
            rename={false}
            name={this.state.theNewName}
            show
            save={name => this.addNewName(this.state.DXData, name)}
            nameList={this.getUsedNames(
              this.state.viewsToShow,
              this.state.theNewName
            )}
          />
        )}
        <Spin tip="Loading..." spinning={this.state.isLoadingAll}>
          <Tabs
            tabPosition="top"
            onChange={this.onSelectView}
            activeKey={this.state.activeKey}
            type="editable-card"
            onEdit={this.onEdit}
            className="name-tabs"
          >
            {this.state.viewsToShow.map(view => (
              <Tabs.TabPane
                tab={<span>{view.viewName}</span>}
                key={Number(view.id).toString()}
                closable={false}
                disabled={
                  this.state.d1IdEdited > 0 && this.state.d1IdEdited !== view.id
                }
              >
                <ClientViewEditor
                  view={view}
                  metasDX={this.state.metasDX}
                  DXData={this.state.DXData}
                  handleChange123={(
                    targetKeys,
                    direction,
                    moveKeys,
                    varName,
                    dxNumber,
                    ids
                  ) =>
                    this.handleChange123(
                      targetKeys,
                      direction,
                      moveKeys,
                      varName,
                      dxNumber,
                      ids
                    )
                  }
                  onDragEdit={(varName, fromIndex, toIndex, dxNumber, ids) =>
                    this.onDragEdit(varName, fromIndex, toIndex, dxNumber, ids)
                  }
                  onRename={(ids, newName) => this.onRename(ids, newName)}
                />
                <div className="footer">
                  <div className="left">
                    <Popconfirm
                      title="Are you sure?"
                      okText="I know what I'm doing"
                      onConfirm={() => this.onDeleteView()}
                    >
                      <Button
                        icon="delete"
                        type="danger"
                        loading={this.state.isLoading}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                  <div className="right">
                    <Button
                      className={this.state.isDirty ? "btn-dirty" : ""}
                      icon="save"
                      loading={this.state.isLoading}
                      onClick={() => this.onSaveView()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Spin>
      </div>
    );
  }
}

export default ClientViews;

ClientViews.propTypes = {
  clientId: PropTypes.number.isRequired
};
