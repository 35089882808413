import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Notifications from '../../shared/notifications/Notifications';
import notification from 'antd/lib/notification';
import Table from 'antd/lib/table';
import Button from 'antd/lib/button';
import CreateNewModal from '../clientPageComponents/CreateNewModal';
import ProjectsService from '../../projects/ProjectsService';
import './ClientsPage.scss';

class ClientsPage extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectsService();
    this.state = {
      clients: [],
      showIds: true,
      expandedKeys: [],
      newClient: {},
      autoExpandParent: true,
      redirect: false,
      toPage: '',
      clientID: 0,
    };
  }

  componentDidMount() {
    this.projectService.getCustomers(
      data => {
        this.setState({ clients: data });
      },
      () => {
        // something for failCallBack
      }
    );
  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  createNewAccount = values => {
    const newCustToCreate = {
      name: values.name,
      shortcode: values.shortcode,
      partner: values.partOrAdv,
    };
    this.projectService.createCustomer(
      newCustToCreate,
      () => this.onSave(),
      () => this.onFail()
    );
  };

  showMessage = (type, title, descr) => {
    let dur = 0;
    if (type === 'success') {
      dur = 2;
    }
    notification[type]({
      message: title,
      description: descr,
      duration: dur,
      placement: 'bottomRight',
    });
  };

  onSave = () => {
    this.showMessage('success', 'Success!', 'You have created a new client.');
    this.projectService.getCustomers(
      data => {
        this.setState({ clients: data });
      },
      () => {
        // something for failCallBack
      }
    );
  };

  onFail = () => {
    this.showMessage(
      'error',
      'Error!',
      'Something went wrong! Possibly pick a more unique shortcode and please try again.'
    );
  };

  handleSelect = selected => {
    this.setState({ redirect: true, clientID: selected });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={`/client/${this.state.clientID}/edit`} />;
    }
    const removeChildrenProp = data => {
      if (Array.isArray(data)) {
        return data.map(d => {
          if (d.children && d.children.length === 0) {
            delete d.children;
            return d;
          } else {
            d.children = removeChildrenProp(d.children);
            return d;
          }
        });
      } else {
        return [];
      }
    };

    let dataFix = [];
    if (this.state.clients.length !== 0) {
      dataFix = removeChildrenProp(this.state.clients);
    }

    return (
      <div className="ClientsPage">
        <div className="menu-row">
          <div className="u-padding-both-sides-25">
            <Notifications />
          </div>
          <div className="u-padding-both-sides-25">
            <div className="grid-x">
              <div className="cell small-12">
                <div className="top-bar">
                  <div className="top-bar-left">
                    <ul className="dropdown menu">
                      <li>
                        <Link to="/clients/" className="acitve">
                          Clients
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="create-new-button">
          <CreateNewModal
            buttonText="Create New Client"
            modalTitle="Create a new Client"
            addNew={this.createNewAccount}
            mustCheck={true}
          />
        </div>
        <div className="grid-x list">
          <Table
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => (
                  <span>
                    <Button
                      type="link"
                      onClick={() => this.handleSelect(record.id)}
                    >
                      {text}
                    </Button>
                  </span>
                ),
              },
              { title: 'Contact Person', dataIndex: 'contactPerson' },
              { title: 'Contact Number', dataIndex: 'contactNumber' },
              { title: 'Contact Email', dataIndex: 'contactEmail' },
            ]}
            dataSource={dataFix}
            rowKey={record => record.code}
            pagination={{
              defaultPageSize: 20,
              pageSizeOptions: ['20', '40', '60'],
              showSizeChanger: true,
            }}
          />
        </div>
      </div>
    );
  }
}

export default ClientsPage;
