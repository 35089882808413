import React from "react";
import PropTypes from "prop-types";
import "./IconComp.scss";

const CreativeIconComp = ({ w, h, fill }) => (
  <div className="CreativeIconComp IconComp">
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        // fill="#000000"
        stroke="none"
      >
        <path
          fill={fill}
          d="M332 4719 c-112 -15 -233 -102 -285 -205 -32 -63 -51 -155 -42 -209
3 -24 46 -126 95 -226 50 -101 97 -209 106 -239 27 -91 14 -179 -74 -535 -107
-428 -125 -536 -125 -745 0 -210 19 -321 125 -740 91 -360 104 -457 73 -551
-10 -30 -58 -134 -106 -231 -49 -99 -91 -197 -94 -221 -4 -25 -1 -71 5 -103
43 -206 234 -344 434 -312 29 4 158 41 285 83 287 92 433 126 547 126 112 0
183 -24 329 -112 141 -86 181 -101 267 -101 84 0 134 16 328 107 184 86 252
106 360 106 107 0 180 -25 317 -108 143 -88 191 -106 278 -105 86 1 159 23
358 109 212 91 249 103 328 103 85 0 143 -13 454 -105 339 -100 370 -107 446
-107 140 1 270 85 337 217 27 55 45 149 37 200 -3 24 -46 126 -95 226 -155
318 -155 287 -16 846 88 354 109 482 109 668 1 208 -17 312 -139 797 -76 305
-89 409 -59 499 10 30 58 134 106 231 49 99 91 197 94 221 8 53 -9 146 -37
202 -68 135 -198 216 -342 216 -61 0 -107 -11 -326 -81 -387 -123 -533 -153
-650 -130 -61 11 -178 66 -285 133 -102 64 -149 80 -230 79 -84 -1 -133 -17
-315 -105 -159 -77 -207 -94 -303 -108 -132 -19 -210 3 -388 109 -153 91 -188
105 -276 104 -84 -1 -178 -31 -418 -136 -83 -36 -177 -71 -208 -76 -82 -16
-181 4 -497 99 -386 115 -418 122 -508 110z m4038 -590 c62 -13 115 -57 141
-114 18 -38 19 -108 19 -1455 0 -1347 -1 -1417 -19 -1455 -26 -57 -79 -101
-141 -114 -72 -16 -3548 -16 -3620 0 -62 13 -115 57 -141 114 -18 38 -19 108
-19 1455 0 1356 1 1417 19 1455 26 56 72 96 128 111 64 17 3553 20 3633 3z"
        />
        <path
          fill={fill}
          d="M3122 3700 c-24 -11 -87 -63 -145 -119 l-102 -100 303 -303 303 -303
100 105 c122 129 133 148 134 225 0 48 -6 69 -27 105 -40 68 -344 364 -398
389 -57 26 -113 26 -168 1z"
        />
        <path
          fill={fill}
          d="M2138 2762 c-290 -290 -531 -538 -536 -552 -26 -69 -212 -719 -212
-739 0 -29 52 -81 82 -81 23 0 730 205 755 218 21 12 991 971 1030 1019 l37
44 -310 310 c-170 170 -311 309 -314 309 -3 0 -242 -238 -532 -528z m-203
-730 c57 -34 65 -42 127 -154 l66 -118 -72 -21 c-39 -11 -123 -36 -187 -55
-64 -19 -124 -34 -135 -34 -24 0 -84 59 -84 83 0 11 15 72 34 136 19 64 44
149 56 189 l21 73 57 -32 c31 -17 84 -48 117 -67z"
        />
      </g>
    </svg>
  </div>
);

export default CreativeIconComp;

CreativeIconComp.defaultProps = {
  fill: "black"
};

CreativeIconComp.propTypes = {
  fill: PropTypes.string,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired
};
