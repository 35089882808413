import React, { Component } from 'react';
import format from 'date-fns/format';
import compareAsc from 'date-fns/compare_asc';
import compareDesc from 'date-fns/compare_desc';
import Notifications from '../../shared/notifications/Notifications';
import { DEFAULT_DATE_FORMAT, FILE_TYPES } from '../../shared/Constants';
import ProjectsService, {
  getClientHelper,
} from '../../projects/ProjectsService';
import FileTypes from '../../projects/projectView/fileTypes/FileTypes';
import SortImg from '../../shared/icons/sort.svg';
import DownImg from '../../shared/icons/download.gif';
import ProfilePicture from '../../shared/profilepictures/ProfilePicture';
import '../../projects/projectView/projectReportsMainView/ProjectReportsMainView.scss';

class ReportsPage extends Component {
  constructor(props) {
    super(props);
    this.projectsService = new ProjectsService();
    this.state = {
      sortedBy: 0,
      reports: [],
      inProgress: false,
      isLoading: true,
    };
    this.downStyle = {
      height: '9px',
      backgroundImage: `url(${DownImg})`,
    };
  }

  componentDidMount() {
    this.projectsService.getAllFiles(files => {
      const filtered = files.filter(file => file.type === FILE_TYPES.REPORT);
      const reports = filtered.map(repo => ({
        checked: false,
        ...repo,
      }));
      this.setState({
        ...this.state,
        reports,
        isLoading: false,
      });
    });
  }

  selectedForDownload(file) {
    const copy = [...this.state.reports];
    copy.forEach(repo => {
      /* eslint-disable */
      if (repo.id === file.id) {
        repo.checked = !repo.checked;
      }
      /* eslint-enable */
      return repo;
    });
    this.setState({ file, reports: copy });
  }

  sort(by) {
    const clone = [...this.state.reports];
    const prev = this.state.sortedBy;
    let prevNew = 0;
    let reports;
    switch (by) {
      case 'name':
        if (prev === 1) {
          reports = clone.sort((a, b) =>
            a.file.name.localeCompare(b.file.name)
          );
          prevNew = 0;
        } else {
          reports = clone.sort((b, a) =>
            a.file.name.localeCompare(b.file.name)
          );
          prevNew = 1;
        }
        break;
      case 'user':
        if (prev === 1) {
          reports = clone.sort((a, b) =>
            a.user.contact.firstname.localeCompare(b.user.contact.firstname)
          );
          prevNew = 0;
        } else {
          reports = clone.sort((b, a) =>
            a.user.contact.firstname.localeCompare(b.user.contact.firstname)
          );
          prevNew = 1;
        }
        break;
      case 'client':
        if (prev === 1) {
          reports = clone.sort((a, b) => {
            const ac = getClientHelper(a.project);
            const bc = getClientHelper(b.project);
            return ac.localeCompare(bc);
          });
          prevNew = 0;
        } else {
          reports = clone.sort((a, b) => {
            const ac = getClientHelper(a.project);
            const bc = getClientHelper(b.project);
            return bc.localeCompare(ac);
          });
          prevNew = 1;
        }
        break;
      case 'project':
        if (prev === 1) {
          reports = clone.sort((a, b) =>
            a.project.name.localeCompare(b.project.name)
          );
          prevNew = 0;
        } else {
          reports = clone.sort((b, a) =>
            a.project.name.localeCompare(b.project.name)
          );
          prevNew = 1;
        }
        break;
      case 'date':
        if (prev === 1) {
          reports = clone.sort((a, b) =>
            compareAsc(a.file.dateCreated, b.file.dateCreated)
          );
          prevNew = 0;
        } else {
          reports = clone.sort((a, b) =>
            compareDesc(a.file.dateCreated, b.file.dateCreated)
          );
          prevNew = 1;
        }
        break;
      default:
        break;
    }
    this.setState({ reports, sortedBy: prevNew });
  }

  handleDownload() {
    this.setState({ isDownloading: true });
    const down = this.state.reports.filter(file => file.checked);
    this.projectsService.downloadFile(
      down,
      () => {
        this.setState({ isDownloading: false });
      },
      () => {
        this.setState({ isDownloading: false });
      }
    );
  }

  reportsLoop() {
    return (
      <div>
        {this.state.reports.map(obj => (
          <div className="grid-x file-row" key={obj.id}>
            <div
              className="cell small-12 medium-5 u-vertical-middle u-clickable"
              role="link"
              tabIndex={0}
              onKeyPress={() => this.selectedForDownload(obj)}
              onClick={() => this.selectedForDownload(obj)}
            >
              <input
                checked={obj.checked}
                type="checkbox"
                name="download"
                className="download-checkbox"
                onChange={() => this.selectedForDownload(obj)}
              />
              <FileTypes type={obj.file.ext} />
              {obj.file.name}
            </div>
            <div className="cell small-6 medium-3 u-vertical-middle project-cell">
              {obj.project.name}
            </div>
            <div className="cell small-6 medium-2 u-vertical-middle date-cell">
              {format(obj.file.dateCreated, DEFAULT_DATE_FORMAT)}
            </div>
            <div className="cell small-6 medium-1 u-vertical-middle client-cell">
              {getClientHelper(obj.project)}
            </div>
            <div className="cell small-6 medium-1 u-vertical-middle align-center">
              <ProfilePicture user={obj.user} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className="ReportsPage">
        <div className="filter-menu">
          <div className="u-padding-both-sides-25">
            <ul className="menu">
              <li>
                {/* eslint-disable-next-line */}
                <a className="active">All Files</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="u-padding-both-sides-25 u-padding-top-10">
          <Notifications />
        </div>
        <div
          className={`ld-over ${
            !this.state.isLoading ? 'not-running' : 'running'
          }`}
        >
          <span className="ld ld-ring ld-spin" />
          <div className="u-padding-both-sides-25">
            <div>
              <h6>Files</h6>
            </div>
            <div className="grid-x title-row hide-for-small-only">
              <div className="cell small-6 medium-5 name-cell-header">
                Document
                <button
                  onClick={() => this.sort('name')}
                  onKeyPress={() => this.sort('name')}
                >
                  <img src={SortImg} className="sort-img" alt="sort" />
                </button>
              </div>
              <div className="cell small-6 medium-3 project-cell-header">
                Project
                <button
                  onClick={() => this.sort('project')}
                  onKeyPress={() => this.sort('project')}
                >
                  <img src={SortImg} className="sort-img" alt="sort" />
                </button>
              </div>
              <div className="cell small-6 medium-2 date-cell-header">
                Published
                <button
                  onClick={() => this.sort('date')}
                  onKeyPress={() => this.sort('date')}
                >
                  <img src={SortImg} className="sort-img" alt="sort" />
                </button>
              </div>
              <div className="cell small-6 medium-1 client-cell-header">
                Client
                <button
                  onClick={() => this.sort('client')}
                  onKeyPress={() => this.sort('client')}
                >
                  <img src={SortImg} className="sort-img" alt="sort" />
                </button>
              </div>
              <div className="cell small-6 medium-1 by-cell-header">
                By
                <button
                  onClick={() => this.sort('user')}
                  onKeyPress={() => this.sort('user')}
                >
                  <img src={SortImg} className="sort-img" alt="sort" />
                </button>
              </div>
            </div>
            <div className="acitivty-row">
              {this.state.reports.length > 0 ? this.reportsLoop() : <span />}
            </div>
          </div>
          <div className="u-padding-both-sides-25">
            <div className="grid-x bottom-row">
              <div className="cell small-12">
                <button
                  className="button download-button"
                  onClick={() => this.handleDownload()}
                  disabled={!this.state.file || this.state.isDownloading}
                >
                  Download
                  <div
                    className={`ld ld-over-full ld-loader ${
                      this.state.isDownloading ? 'show' : 'hide'
                    }`}
                    style={this.downStyle}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportsPage;
