/* eslint prefer-destructuring: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import Button from 'antd/lib/button';
import { TextButtonSmall } from '../../../../pages/bp/bpNav/bpSubNavItem/Button';
import './SMFSettings.scss';
import { SMFServiceStatic } from '../../../../services/SMFService';
import { UUID } from '../../../../utils/StringUtils';
import { sortObjKeysAlphabetically } from '../../../../utils/Utils';
import SMFGroupSelect from './SMFGroupSelect';

class SMFSettingsBPLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smfGoals: null,
      smfGoalsMeta: null,
      groupOptions: [],
      isLoadedGoals: false,
      isLoadedMeta: false,
      noActions: false,
    };
    this.edited = {};
    this.SMFService = SMFServiceStatic;
    this.projectId = this.props.projectId;
  }
  componentDidMount() {
    SMFServiceStatic.getSMFGoals(this.projectId, smfGoals => {
      this.setState({ smfGoals, isLoadedGoals: true });
    });
    SMFServiceStatic.getSMFSettings(this.projectId, smfGoalsMeta => {
      const noActions = smfGoalsMeta.actions.length === 0;
      this.setState({ smfGoalsMeta, isLoadedMeta: true, noActions });
    });
  }

  onChangeTmp = (id, action) => {
    this.edited[id] = true;
    const clone = { ...this.state.smfGoals };
    clone[id]['statsAction'].id = action.value;
    this.setState({ smfGoals: clone });
  };

  onSave = goalEntityId => {
    const ob = this.state.smfGoals[goalEntityId];
    const actionId = ob.statsAction.id;
    const value = ob.value;
    const smfDataGroupId = ob.smfDataGroupId
      ? ob.smfDataGroupId
      : ob.smfDataGroup.id;

    if (ob.isNew) {
      this.SMFService.postSMF(
        this.projectId,
        value,
        smfDataGroupId,
        actionId,
        data => {
          this.edited[goalEntityId] = false;
          const clone = { ...this.state.smfGoals };
          delete clone[goalEntityId];
          clone[data[0].id] = data[0];
          this.setState({ smfGoals: clone });
        }
      );
    } else {
      this.SMFService.putSMF(
        this.projectId,
        goalEntityId,
        value,
        smfDataGroupId,
        actionId,
        dataBack => {
          this.edited[goalEntityId] = false;
          const smfGoals = { ...this.state.smfGoals };
          smfGoals[dataBack[0].id] = dataBack[0];
          this.setState({ smfGoals });
        }
      );
    }
  };

  onValChange = (id, event) => {
    this.edited[id] = true;
    const clone = { ...this.state.smfGoals };
    clone[id].value = event.target.value;
    this.setState({ smfGoals: clone });
  };
  onValChange3 = (id, selectedVal) => {
    this.edited[id] = true;
    const clone = { ...this.state.smfGoals };
    clone[id].smfDataGroupId = selectedVal;

    const groupObj = this.state.smfGoalsMeta.groups.find(
      g => g.id === selectedVal
    );

    clone[id].smfDataGroup = groupObj;
    this.setState({ smfGoals: clone });
  };
  onAddedNewCB = (newOptions, selectedVal, elementId) => {
    const smfGoalsMeta = { ...this.state.smfGoalsMeta };
    smfGoalsMeta.groups = newOptions;

    this.edited[elementId] = true;
    const clone = { ...this.state.smfGoals };
    clone[elementId].smfDataGroupId = selectedVal;
    this.setState({ smfGoals: clone, smfGoalsMeta });
  };

  addNewBtn = () => {
    const uuid = UUID();
    const bp = {
      goalType: {
        id: 0,
      },
      statsAction: {
        id: 0,
      },
      id: uuid,
      value: 0,
      smfDataGroupId: 0,
      isNew: true,
    };
    const clone = { ...this.state.smfGoals };
    clone[uuid] = bp;
    this.edited[uuid] = true;
    this.setState({ smfGoals: clone });
  };

  goalsToList = smfGoals => {
    const retVal = [];
    Object.values(smfGoals).forEach(element => {
      retVal.push({
        value: element.id,
        label: element.type,
      });
    });
    return retVal;
  };

  actionsToList = (smfActions, goals) => {
    const retVal = [];
    const usedActionsIds = [];
    for (let g in goals) {
      if (goals[g].statsAction && goals[g].statsAction.id) {
        usedActionsIds.push(goals[g].statsAction.id);
      }
    }
    Object.values(smfActions).forEach(element => {
      let disabled = false;
      if (usedActionsIds.includes(element.id)) disabled = true;
      retVal.push({
        value: element.id,
        label: element.name,
        disabled,
      });
    });
    const retValSorted = retVal.sort((a, b) => a.label.localeCompare(b.label));
    return retValSorted;
  };

  groupsToList = groups => {
    const retVal = [];
    Object.values(groups).forEach(elem => {
      retVal.push({
        id: elem.id,
        value: `${elem.name} - ${elem.goalType.type}`,
      });
    });

    return retVal;
  };

  countValues = goals => {
    const retVal = {};
    Object.values(goals).forEach(go => {
      if (retVal[go.smfDataGroup]) {
        if (retVal[go.smfDataGroup.goalType.type]) {
          retVal[go.smfDataGroup.goalType.type] += parseInt(go.value, 10);
        } else {
          retVal[go.smfDataGroup.goalType.type] = parseInt(go.value, 10);
        }
      }
    });
    const sorted = sortObjKeysAlphabetically(retVal);
    return sorted;
  };
  render() {
    if (this.state.isLoadedGoals && this.state.isLoadedMeta) {
      const goalOptions = this.goalsToList(this.state.smfGoalsMeta.goals || {});
      const actionsOptions = this.actionsToList(
        this.state.smfGoalsMeta.actions || {},
        this.state.smfGoals
      );

      const groupOptions = this.groupsToList(this.state.smfGoalsMeta.groups);
      const counters = this.countValues(this.state.smfGoals);

      return (
        <div className="SMFSettingsBPLists">
          <table>
            <thead>
              <tr>
                <td>
                  <label>Action</label>
                </td>
                <td>
                  <label>Group</label>
                </td>
                <td>
                  <label>Value</label>
                </td>
              </tr>
            </thead>
            <tbody>
              {this.state.smfGoals &&
                Object.values(this.state.smfGoals).map(elem => (
                  <tr key={elem.id}>
                    <td className="action">
                      <Select
                        value={elem.statsAction.id}
                        options={actionsOptions}
                        onChange={e => this.onChangeTmp(elem.id, e)}
                        clearable={false}
                        searchable
                      />
                    </td>
                    <td className="group">
                      <SMFGroupSelect
                        projectId={this.props.projectId}
                        onChange={selectedVal =>
                          this.onValChange3(elem.id, selectedVal)
                        }
                        options={groupOptions}
                        valueObj={elem.smfDataGroup}
                        goalOptions={goalOptions}
                        onAddedNewCB={(newOptions, selectedVal) =>
                          this.onAddedNewCB(newOptions, selectedVal, elem.id)
                        }
                      />
                    </td>
                    <td className="value">
                      <input
                        type="number"
                        value={elem.value}
                        onChange={event => this.onValChange(elem.id, event)}
                        className="numberInput"
                      />
                    </td>
                    <td>
                      <TextButtonSmall
                        disabled={!this.edited[elem.id] || false}
                        onClick={() => this.onSave(elem.id)}
                        variant="contained"
                        size="small"
                      >
                        Save
                      </TextButtonSmall>
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <Button type="primary" onClick={() => this.addNewBtn()}>
                    Add new KPI Mapping
                  </Button>
                </td>
                <td colSpan={3}>
                  <div className="sums">Sums:</div>
                  <ul>
                    {Object.keys(counters).map(key => {
                      return (
                        <li key={key}>
                          {key} = {counters[key]}{' '}
                        </li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
            </tfoot>
          </table>
          {this.state.noActions && (
            <em>
              If actions are missing please do 'Do DS Sync' in General settings
              tab.
            </em>
          )}
        </div>
      );
    } else {
      return <span>loading</span>;
    }
  }
}

export default SMFSettingsBPLists;

SMFSettingsBPLists.propTypes = {
  projectId: PropTypes.number.isRequired,
};
