import FileSaver from "file-saver";
import moment from "moment";
import Papa from "papaparse";
import { getListForColFactory2 } from "../BPServiceUtils";
import { getViewsMetaInfo } from "../BPViewService";
import { memStorage } from "../../../services/Storage";
import {
  BP_LEVELS_FOR_HUMANS,
  DEFAULT_DATE_FORMAT_FOR_DB,
} from "../../../shared/Constants";
import { getProjectByIdSelBound } from "../../../projects/ProjectReduxUtils";

const IMG_GET_URL = `${process.env.REACT_APP_API_URI}/file/`;

export const exportsJoinedTable = (
  projectId,
  dataArray,
  viewArrArr,
  selectedX,
  viewId,
  columns
) => {
  let viewArr = viewArrArr[0].map((c) => "d1_" + c.name);
  let viewHeaders = viewArrArr[0].map((c) => `${c.label}`);

  if (Number(selectedX) === 2 || Number(selectedX) === 3) {
    let viewArrTmp = viewArrArr[1].map((c) => "d2_" + c.name);
    viewArr = [...viewArr, ...viewArrTmp];
    viewArrArr[1]
      .map((c) => `${c.label}`)
      .forEach((v) => {
        viewHeaders.push(v);
      });
  }
  if (Number(selectedX) === 3) {
    let viewArrTmp = viewArrArr[2].map((c) => "d3_" + c.name);
    viewArr = [...viewArr, ...viewArrTmp];
    viewArrArr[2]
      .map((c) => `${c.label}`)
      .forEach((v) => {
        viewHeaders.push(v);
      });
  }
  const fileName = genFileName(projectId, viewId);

  const getListForColFn = getListForColFactory2(projectId);

  const { retValMass } = genCSVValsPapa(
    dataArray,
    viewArr,
    getListForColFn,
    selectedX
  );

  const csv = Papa.unparse({
    fields: viewHeaders,
    data: retValMass,
  });
  toFileDownloadPapa(csv, fileName);
};

function genCSVValsPapa(dataArray, viewArr, getListForColFn, selectedX) {
  const retValMass = [];
  dataArray.forEach((line) => {
    const retValArr = [];
    viewArr.forEach((elemName) => {
      let val = line[elemName];
      const sp = elemName.split("_");
      let elemNameFix = elemName;
      if (sp.length === 2) {
        elemNameFix = sp[1];
      }
      if (elemNameFix.startsWith("list") || elemNameFix.startsWith("smf")) {
        const listValueId = line[elemName];
        const listVal = getListForColFn(
          elemNameFix,
          listValueId,
          "dynamic" + selectedX
        );
        val = `${listVal}`;
      }
      if (elemNameFix.startsWith("text")) {
        val = `${line[elemName] || ""}`;
      }
      if (elemNameFix.startsWith("bool")) {
        if (line[elemName] === true) {
          val = "true";
        } else if (line[elemName] === false) {
          val = "false";
        } else {
          val = "";
        }
      }
      if (elemNameFix.startsWith("int") || elemNameFix.startsWith("float")) {
        if (line[elemName] === null) {
          val = "";
        } else {
          const candidate = parseFloat(line[elemName]); // it parses int as well, outcome 0 or NaN.
          if (candidate === 0) {
            val = "0";
          } else if (isNaN(candidate)) {
            val = "";
          } else {
            val = Number(candidate).toString();
          }
        }
      }

      if (elemNameFix.startsWith("date")) {
        if (line[elemName]) {
          val = `${moment(line[elemName]).format(DEFAULT_DATE_FORMAT_FOR_DB)}`;
        } else {
          val = "";
        }
      }
      if (elemNameFix.startsWith("file")) {
        if (line[elemName]) {
          val = IMG_GET_URL + line[elemName];
        } else {
          val = "";
        }
      }
      retValArr.push(val);
    });
    retValMass.push(retValArr);
  });
  return { retValMass };
}

/**
 * Experimental, used to export only ids for now.
 *
 * @param {int} projectId
 * @param {array} dataArray
 * @param {int} viewId
 * @param {string} dynamicXStr1Based
 * @param {array} cols array of cols like [textColumn1, intColumn1, etc]
 */
export const exportsBPTableSelectedCols = (
  projectId,
  dataArray,
  viewId,
  dynamicXStr1Based,
  cols
) => {
  const fileName = genFileName(projectId, viewId, dynamicXStr1Based, "_Ids");

  const view = memStorage.get("viewsById")[viewId];
  const selectedViewOnly = view.filter((vv) => cols.includes(vv.name));

  const viewArr = Object.values(selectedViewOnly).map((m) => m.name);
  const viewHeaders = Object.values(selectedViewOnly).map((m) => `${m.label}`);

  const getListForColFn = getListForColFactory2(projectId);

  const selectedX = dynamicXStr1Based.substr(-1);

  const { retValMass } = genCSVValsPapa(
    dataArray,
    viewArr,
    getListForColFn,
    selectedX
  );

  const csv = Papa.unparse({
    fields: viewHeaders,
    data: retValMass,
  });
  toFileDownloadPapa(csv, fileName);
};

export const exportsBPTable = (
  projectId,
  dataArray,
  viewId,
  dynamicXStr1Based
) => {
  const fileName = genFileName(projectId, viewId, dynamicXStr1Based);

  const view = memStorage.get("viewsById")[viewId];
  const viewArr = Object.values(view).map((m) => m.name);
  const viewHeaders = Object.values(view).map((m) => `${m.label}`);

  const getListForColFn = getListForColFactory2(projectId);

  const selectedX = dynamicXStr1Based.substr(-1);

  const { retValMass } = genCSVValsPapa(
    dataArray,
    viewArr,
    getListForColFn,
    selectedX
  );

  const csv = Papa.unparse({
    fields: viewHeaders,
    data: retValMass,
  });
  toFileDownloadPapa(csv, fileName);
};

function toFileDownloadPapa(retVal, fileName) {
  var blob = new Blob([retVal], {
    type: "text/plain;charset=utf-8",
  });
  FileSaver.saveAs(blob, fileName);
}

function genFileName(projectId, viewId, dynamicXStr1Based, extra = "") {
  const metaInfo = getViewsMetaInfo(projectId);
  const viewNameTmp = metaInfo.find((m) => Number(m.id) === Number(viewId)) || {
    viewName: "default",
  };
  const viewName = viewNameTmp.viewName;
  const project = getProjectByIdSelBound(projectId);
  let level = "";
  if (dynamicXStr1Based) {
    level = BP_LEVELS_FOR_HUMANS[dynamicXStr1Based];
  } else {
    level = "flat";
  }
  const name = `${project.docket}_${viewName}_${level}${extra}.csv`;
  const namesNoSpaces = name.replace(/ /g, "_");
  return namesNoSpaces;
}
