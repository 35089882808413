import axios from "axios";
import store from "store";
import reduxStore from "../reduxStore";
import { camp } from "../camp";
import { errorLog } from "./ErrorTracker";
import {
  readUIVersion,
  readAPIVersion
} from "../actions/versionAndPingActions";

const DELAY_MINS = 2 * 60 * 1000;

// TODO: check when backend is ready.
const RICH_PING_ENABLED = false;

const getUIVersionInfo = callbackOk => {
  const instance = axios.create();
  instance
    .get("/version-ui.json")
    .then(callbackOk)
    .catch(errorLog);
};

const pingRich = (ver, callbackOk) => {
  axios({
    method: "GET",
    url: process.env.REACT_APP_API_URI + "/ping?v=" + encodeURIComponent(ver)
  })
    .then(resp => {
      callbackOk(resp.data);
    })
    .catch(error => {
      errorLog(error);
    });
};

const getAPIVersionInfo = callbackOk => {
  camp
    .ping()
    .then(callbackOk)
    .catch(errorLog);
};

let uiVersion = "empty";

const initVersions = () => {
  if (RICH_PING_ENABLED) {
    pingRich(uiVersion, data => {
      reduxStore.dispatch(
        readAPIVersion(data.version, data.migration, data.maintenance)
      );
    });
  } else {
    getAPIVersionInfo(data => {
      reduxStore.dispatch(
        readAPIVersion(data.version, data.migration, data.maintenance)
      );
    });
  }

  getUIVersionInfo(data => {
    let id = store.get("id") || 0;
    uiVersion = data.data.version + "&u=" + id;
    reduxStore.dispatch(readUIVersion(data.data.version));
  });
  setTimeout(() => {
    initVersions();
  }, DELAY_MINS); // 3mins
};

export default initVersions;
export { readUIVersion };
