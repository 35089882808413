import * as types from '../types/types';

export const SUBTYPES = {
  ERROR: 'error',
  WARN: 'warn',
  INFO: 'info',
  SYSTEM: 'system',
};

export const sendNotification = (message, type = SUBTYPES.INFO) => (dispatch) => {
  dispatch({
    type: types.NOTIFICATIONS_SEND_MESSAGE,
    payload: {
      message,
      type,
    },
  });
};

export const sendSystemNotification = (message, canDismiss = false) => (dispatch) => {
  dispatch({
    type: types.NOTIFICATIONS_SEND_MESSAGE,
    payload: {
      message,
      type: SUBTYPES.SYSTEM,
      canDismiss,
    },
  });
};

export const dismissNotification = id => (dispatch) => {
  dispatch({
    type: types.NOTIFICATIONS_DISMISS_MESSAGE,
    payload: {
      id,
    },
  });
};

export const dismissNotificationByType = type => (dispatch) => {
  dispatch({
    type: types.NOTIFICATIONS_DISMISS_ALL_BY_TYPE,
    payload: {
      type,
    },
  });
};

window.sendNotification = sendNotification;
window.dismissNotification = dismissNotification;
window.dismissNotificationByType = dismissNotificationByType;
window.sendSystemNotification = sendSystemNotification;
