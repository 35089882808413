import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import "./LoginPage.scss";
import Footer from "../shared/footer/Footer";
import AuthService from "./AuthService";
import asyncComponent from "../shared/AsyncComponent";
import { getMaintenanceMode } from "../actions/versionAndPingActionsUtils";
import {
  MaintenanceEnabledContainer,
  MaintenanceMessage,
} from "../pages/maintenanceage/MaintenanceComp";

const RegisterDialog = asyncComponent(() => import("./RegisterDialog"));

/**
 * Login page, handles login and unsuccessfully attempt.
 */
class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      button: "enabled",
      inputs: "enabled",
      warn: "hide",
      registerOpen: false,
      maintenanceMode: false,
    };
    document.body.classList.add("login-screen");
  }

  componentWillUnmount() {
    document.body.classList.remove("login-screen");
  }
  componentDidMount() {
    const maintenanceMode = getMaintenanceMode();
    this.setState({ maintenanceMode });
  }

  updateButton = () => {
    if (
      this.state.email.trim().length > 3 &&
      this.state.password.trim().length > 3
    ) {
      this.setState({ button: "enabled" });
    }
  };

  updateInputs = (setDisabled) => {
    if (setDisabled) {
      this.setState({ inputs: "disabled" });
    } else {
      this.setState({ inputs: "enabled" });
    }
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
    this.updateButton();
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
    this.updateButton();
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (
      this.state.email.trim().length > 3 &&
      this.state.password.trim().length > 3
    ) {
      const auth = new AuthService();
      this.setState({ button: "disabled" });
      auth.login(
        this.state.email,
        this.state.password,
        this.loggedIn,
        this.failed
      );
    }
  };

  handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  loggedIn = () => {
    this.props.history.push("/projects/all?filter=live");
  };

  failed = (err) => {
    this.err = err; // Todo: Add some message from backend?
    this.setState({ button: "enabled" });
    this.setState({ warn: "show" });
  };

  register = (type) => {
    console.log("type", type);
    this.setState({ registerOpen: true, registerType: type });
  };

  registerClose() {
    this.setState({ registerOpen: false });
  }

  render() {
    return (
      <div className="grid-container full">
        <MaintenanceEnabledContainer>
          <MaintenanceMessage />
        </MaintenanceEnabledContainer>

        {this.state.registerOpen && (
          <RegisterDialog
            type={this.state.registerType}
            open={this.state.registerOpen}
            handleClose={() => this.registerClose()}
          />
        )}
        <div className="LoginPage">
          <div className="grid-x grid-padding-x">
            <div className="large-offset-4 medium-offset-2 small-12 medium-8 large-4 cell">
              <form className="log-in-form">
                <h4 className="text-center">Welcome back to Fastloop.</h4>

                <div className={`callout warning small ${this.state.warn}`}>
                  <small>User is not recognized.</small>
                </div>
                <label htmlFor="email">
                  Email
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    onKeyPress={this.handleOnKeyPress}
                    className={`${this.state.inputs}`}
                    disabled={!this.state.button}
                    placeholder="somebody@example.com"
                  />
                </label>
                <label htmlFor="password">
                  Password
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    onKeyPress={this.handleOnKeyPress}
                    className={`${this.state.inputs}`}
                    placeholder="***********"
                  />
                </label>
                <br />
                <div className="grid-x grid-padding-x">
                  <p className="small-12 medium-12 cell">
                    <input
                      type="button"
                      className={`button expanded login-btn ${this.state.button}`}
                      onClick={this.handleSubmit}
                      value="Log in"
                    />
                  </p>

                  {/* <p className="small-12 medium-12 cell space-top">
                    <input
                      type="button"
                      className="button expanded register-btn"
                      onClick={() => this.register('user')}
                      value="Register as an User"
                    />
                  </p> */}

                  {/* <p className="small-12 medium-12 cell">
                    <Link
                      className="button expanded register-btn"
                      to="/register"
                    >
                      Register as an Organization
                    </Link>
                  </p> */}
                </div>
                <p className="text-right forgot-password">
                  <Link to="/recoverpassword">Forgot your password?</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
        <div className="grid-x grid-padding-x">
          <div className="small-12 cell">
            <div className="footer">
              <Footer logged={false} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginPage);

LoginPage.propTypes = {
  /**
   * User for react-router-dom, for navigating user to 'logged' router
   */
  history: PropTypes.object,
};

LoginPage.defaultProps = {
  history: [],
};
