import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { titleCaseString } from '../../../../utils/StringUtils';
import UserCard from '../../../../shared/userCard/UserCard';
import { getDataFTAttrs } from '../../../../utils/FTUtils';
import './RoleLine.scss';

class RoleLine extends Component {
  constructor(props) {
    super(props);
    this.state = { isEditMode: false };
  }

  onEditClickHandler() {
    this.setState({ ...this.state, isEditMode: true });
  }

  onCancelClickHandler() {
    this.setState({ ...this.state, isEditMode: false });
  }

  onRemoveClickHandler() {
    this.setState({ ...this.state, isEditMode: false });
    const retVal = {
      roleId: this.props.role.role.id,
      userId: this.props.role.user.id,
      roleMainId: this.props.role.id,
    };
    this.props.onRemove(retVal);
  }

  render() {
    const modeViewClasses = classNames({ 'mode-view': true, hide: this.state.isEditMode });
    const modeEditClasses = classNames({ 'mode-edit': true, hide: !this.state.isEditMode });
    return (
      <div className="RoleLine">
        <div className="grid-x people-row" {...getDataFTAttrs(this.props)}>
          <div className="cell small-3 medium-2">{titleCaseString(this.props.role.role.role)}</div>
          {/* the boat */}
          <div className="cell small-9 medium-6">
            <UserCard user={this.props.role.user} />
            <div className={modeViewClasses}>
              <button onClick={e => this.onEditClickHandler(e)} className="action-btn">
                Edit
              </button>
            </div>
            <div className={modeEditClasses}>
              <button onClick={e => this.onCancelClickHandler(e)} className="action-btn">
                Cancel
              </button>
              <button onClick={e => this.onRemoveClickHandler(e)} className="action-btn danger">
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RoleLine;

RoleLine.propTypes = {
  role: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};
