import React from "react";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./AccountMenu.scss";
import { getCurrentUserStaticCB } from "../../auth/AuthService";
import ProfilePicture from "../profilepictures/ProfilePicture";

class AccountMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: {}, anchorEl: null };
  }

  componentDidMount() {
    getCurrentUserStaticCB(user => {
      this.setState({ user });
    });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, user } = this.state;
    if (!user) {
      return <></>;
    }
    const firstLast =
      (user.contact && `${user.contact.firstname} ${user.contact.lastname}`) ||
      "N/A";
    return (
      <div className="AccountMenu2">
        <div className="flex-container flex-dir-column">
          <button
            className="u-clickable"
            aria-owns={anchorEl ? "account-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <ProfilePicture user={this.state.user} bigger />
            <br />
            {user.contact ? user.contact.firstname : ""}
          </button>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem
              disabled
              onClick={this.handleClose}
              disableGutters={false}
              className="name"
            >
              {firstLast}
            </MenuItem>
            {/* <Link className="a-logout" to="/profile">
              <MenuItem onClick={this.handleClose} disableGutters={false} className="link">
                Profile
              </MenuItem>
            </Link> */}
            <Link className="a-logout" to="/logout">
              <MenuItem
                onClick={this.handleClose}
                disableGutters={false}
                className="link"
              >
                Logout
              </MenuItem>
            </Link>
          </Menu>
        </div>
      </div>
    );
  }
}

export default AccountMenu;
