import * as types from "../types/types";

export const readUIVersion = version => dispatch => {
  dispatch({
    type: types.VERSION_PING_UI_READ,
    payload: {
      version
    }
  });
};

export const readAPIVersion = (version, db, maintenance) => dispatch => {
  dispatch({
    type: types.VERSION_PING_API_READ,
    payload: {
      version,
      db,
      maintenance
    }
  });
};

window.readUIVersion = readUIVersion;
window.readAPIVersion = readAPIVersion;
