import reduxStore from "../reduxStore";
import {
  getFiltersSel,
  getMediaPlanSel,
  getMediaPlanViewSel,
} from "../selectors/MediaPlanSelectros";
import {
  mediaPlanLoaded,
  setFilters,
  mediaPlanExpanded,
  mediaPlanReload,
  setView,
  setHooksValidation,
} from "./MediaPlanActions";

// TODO add project id for key! read from data it self

export const mediaPlanLoadedBound = (mediaPlan) =>
  reduxStore.dispatch(mediaPlanLoaded(mediaPlan));

export const setFiltersBound = (type, filter) => {
  reduxStore.dispatch(setFilters(type, filter));
};

export const mediaPlanExpandedBound = (parentId, rowId, expanded, level) => {
  reduxStore.dispatch(mediaPlanExpanded(parentId, rowId, expanded, level));
};

export const mediaPlanReloadBound = (reload) => {
  reduxStore.dispatch(mediaPlanReload(reload));
};

export const getFilters = () => {
  return getFiltersSel(reduxStore.getState());
};

export const getMediaPlan = () => {
  return getMediaPlanSel(reduxStore.getState());
};

export const setViewBound = (view) => {
  reduxStore.dispatch(setView(view));
};

export const setHooksValidationBound = (view) => {
  reduxStore.dispatch(setHooksValidation(view));
};

export const getViewSel = () => {
  return getMediaPlanViewSel(reduxStore.getState());
};

/**
 * Needs testing!
 * @param {int|null} parentId if null - returns d1
 */
// export const getMediaPlanByParentBound = (dynamicLevelInt, parentId) => {
//   const all = getMediaPlanSel(reduxStore.getState());
//   let retVal = [];
//   if (!parentId) {
//     retVal = all.dynamic1;
//     return retVal;
//   }
//   if (dynamicLevelInt === 2) {
//     const needle = all.dynamic1.find(d => d.id === parentId);
//     retVal = needle.dynamic2;
//     return retVal;
//   }
//   if (dynamicLevelInt === 3) {
//     for (let i = 0; i < all.dynamic1.length; i++) {
//       const needle = all.dynamic1[i].find(d => d.id === parentId);
//       if (needle) {
//         retVal = needle.dynamic3;
//         break;
//       }
//     }
//     return retVal;
//   }
//   return retVal;
// };

// window.getMediaPlanByParentBound = getMediaPlanByParentBound;
