import * as types from '../types/types';

export const projectLoaded = project => dispatch => {
  dispatch({
    type: types.PROJECT_LOADED,
    payload: {
      project,
    },
  });
};

export const projectLoad = () => dispatch => {
  dispatch({
    type: types.PROJECT_LOAD,
  });
};

export const projectSave = () => dispatch => {
  dispatch({
    type: types.PROJECT_LOAD,
  });
};

export const smfGoalsLoaded = (projectId, smfGoals) => dispatch => {
  dispatch({
    type: types.SMF_GOALS_LOADED,
    payload: {
      projectId,
      smfGoals,
    },
  });
};

export const constantsLoaded = (projectId, constants) => dispatch => {
  dispatch({
    type: types.PROJECT_CONST_LOADED,
    payload: {
      projectId,
      constants,
    },
  });
};

export const smfSettingsData = (
  projectId,
  smfGoals,
  smfActions,
  smfGroups
) => dispatch => {
  dispatch({
    type: types.SMF_SETTINGS_LOADED,
    payload: {
      projectId,
      smfGoals,
      smfActions,
      smfGroups,
    },
  });
};

export const smfGoalsAndActions = (
  projectId,
  smfGoals,
  smfActions
) => dispatch => {
  dispatch({
    type: types.SMF_GOALS_ACTIONS_LOADED,
    payload: {
      projectId,
      smfGoals,
      smfActions,
    },
  });
};

export const smfGoalsAndActionsUpdate = (
  projectId,
  smfGoals,
  smfActions
) => dispatch => {
  dispatch({
    type: types.SMF_GOALS_ACTIONS_UPDATE,
    payload: {
      projectId,
      smfGoals,
      smfActions,
    },
  });
};

export const smfGoalsAndGroupsUpdate = (
  projectId,
  smfGoals,
  smfActions,
  smfGroups
) => dispatch => {
  dispatch({
    type: types.SMF_GOALS_ACTIONS_UPDATE,
    payload: {
      projectId,
      smfGoals,
      smfActions,
      smfGroups,
    },
  });
};
