/* eslint no-use-before-define:0, no-case-declarations:0 */
import * as types from '../types/types';

const initState = {
  list: [], // payload {key, ts/uniqkey}
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.BUS_FIRE:
      const copyList = [...state.list];
      copyList.push(action.payload);
      return {
        ...state,
        list: copyList,
      };
    default:
      return state;
  }
};
