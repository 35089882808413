import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import removeImg from '../../shared/remove.svg';

/**
 * Handles Roles and Peoples line
 * @param {array} users holds users lists
 * @param {array} roles holds roles list
 * @param {func} onLineChange callback on line
 * change @returns {selectedRoleOption, selectedUserOption}
 * @param {func} removeLine called on line remove
 * @param {object} initUser init user to show on form
 * @param {initRole} initUser init role to show on form
 */
class NewPeopleLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRoleOption: this.props.initRole.empty ? '' : this.props.initRole,
      selectedUserOption: this.props.initUser.empty ? '' : this.props.initUser,
    };
    this.onLineChange = this.props.onLineChange.bind(this);
  }

  innerHandleRoleChange = (selectedRoleOption) => {
    this.setState({ selectedRoleOption }, () => {
      this.onLineChange(this.state);
    });
  };

  innerHandleUserChange = (selectedUserOption) => {
    this.setState({ selectedUserOption }, () => {
      this.onLineChange(this.state);
    });
  };

  render() {
    return (
      <div className="NewPeopleLine grid-x">
        <div className="cell small-6 medium-4 v-center">
          {!this.props.canRemove ? (
            <span className="u-red">•</span>
          ) : (
            <span className="u-red">&nbsp;</span>
          )}
          <label htmlFor="role" className="role">
            <Select
              name="role"
              value={this.state.selectedRoleOption}
              options={this.props.roles}
              onChange={(val) => {
                this.innerHandleRoleChange(val);
              }}
              clearable={false}
              disabled={this.props.idx === 0}
            />
          </label>
        </div>
        <div className="cell small-6 medium-6 v-center">
          <label htmlFor="user" className="user">
            <Select
              name="user"
              value={this.state.selectedUserOption}
              options={this.props.users}
              onChange={(val) => {
                this.innerHandleUserChange(val);
              }}
              clearable={false}
            />
          </label>
          <div
            className="removePeopleLine"
            role="presentation"
            onClick={this.props.removeLine}
            onKeyPress={this.props.removeLine}
          >
            {this.props.canRemove ? (
              <span>
                <img src={removeImg} alt="remove" />
&nbsp;&nbsp;
              </span>
            ) : (
              <span />
            )}
            {this.props.isInvalid ? <div className="validation-required">Required</div> : <span />}
          </div>
        </div>
      </div>
    );
  }
}

export default NewPeopleLine;

NewPeopleLine.defaultProps = {
  initUser: '',
  initRole: '',
};

NewPeopleLine.propTypes = {
  users: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  onLineChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
  idx: PropTypes.number.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  initUser: PropTypes.object,
  initRole: PropTypes.object,
};
