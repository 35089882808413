import React, { Component } from "react";
import moment from "moment";
import Icon from "antd/lib/icon";
import message from "antd/lib/message";
import Notifications from "../../shared/notifications/Notifications";
import UsersService from "../../services/UsersService";
import { authServiceStatic, isAdmin, isRoot } from "../../auth/AuthService";
import "./PeoplePage.scss";
import Button from "antd/lib/button";
import Table from "antd/lib/table";
import { DEFAULT_DATE_FORMAT_FOR_DB } from "../../shared/Constants";

class PeoplePage extends Component {
  constructor(props) {
    super(props);
    this.usersService = new UsersService();
    this.state = {
      users: [],
      isLoading: true,
      forbidden: false,
      filteredInfo: [{ blocked: [true] }],
      sortedInfo: null,
    };
    this.doShowAdmin = isAdmin();
    this.doShowRoot = isRoot();
  }

  componentDidMount() {
    if (!this.doShowAdmin) {
      this.setState({ forbidden: true });
    } else {
      this.usersService.getUsers((users) => {
        this.setState({ users: users, isLoading: false });
      });
    }
  }
  block = (id) => {
    return (
      <Button
        shape="circle"
        type="danger"
        size="small"
        onClick={() => this.blockUser(id)}
      >
        <Icon type="stop" />
      </Button>
    );
  };
  blockUser = (id) => {
    this.setState({ isLoading: true });
    authServiceStatic.blockUser(
      id,
      () => {
        this.componentDidMount();
      },
      () => {
        this.setState({ isLoading: false });
        message.error("Something went wrong, check logs");
      }
    );
  };
  unblockUser = (id) => {
    this.setState({ isLoading: true });
    authServiceStatic.unblockUser(
      id,
      () => {
        this.componentDidMount();
      },
      () => {
        this.setState({ isLoading: false });
        message.error("Something went wrong, check logs");
      }
    );
  };
  promoteTo = (username, role) => {
    this.setState({ isLoading: true });
    authServiceStatic.setUserRole(
      username,
      role,
      (userBack) => {
        const clone = [...this.state.users];
        const idx = clone.findIndex((c) => c.username === username);
        clone[idx] = userBack;
        this.setState({ users: clone });
        this.setState({ isLoading: true });
      },
      () => {
        this.setState({ isLoading: false });
        message.error("Something went wrong, check logs");
      }
    );
  };

  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", filters);
    this.setState({
      filteredInfo: filters,
    });
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    console.log("[PeoplePage.jsx] filteredInfo", filteredInfo);

    const cols = [
      {
        title: "Id",
        dataIndex: "id",
        // onFilter: (value, record) => record.id.indexOf(value) !== -1,
        sorter: (a, b) => Number(a.id) - Number(b.id),
      },
      {
        title: "Username",
        dataIndex: "username",
        onFilter: (value, record) => record.username.indexOf(value) !== -1,
        sorter: (a, b) => a.username.localeCompare(b.username),
      },
      {
        title: "Name",
        dataIndex: "contact.firstname",
        render: (text, record) => {
          return (
            <>
              {record.contact.firstname} {record.contact.lastname}
            </>
          );
        },
      },
      {
        title: "Joined",
        sorter: (a, b) =>
          moment(a.contact.dateCreated).diff(b.contact.dateCreated),
        render: (text, record) => {
          return (
            <>
              {moment(record.contact.dateCreated).format(
                DEFAULT_DATE_FORMAT_FOR_DB
              )}
            </>
          );
        },
      },
      {
        title: "Roles",
        render: (text, record) => {
          return <>{record.roles.join(", ")}</>;
        },
      },
      {
        title: "Blocked ?",
        dataIndex: "blocked",
        filters: [
          {
            text: "Not Blocked",
            value: false,
          },
          {
            text: "Blocked",
            value: true,
          },
        ],
        defaultFilteredValue: [false],
        onFilter: (value, record) => record.blocked === value,
        render: (text, record) => {
          return <>{record.blocked ? "Yes" : "No"}</>;
        },
      },
      {
        title: "Actions",
        render: (text, record) => {
          const showPromoteToAdmin = record.roles.includes("ROLE_USER");
          const showPromoteToRoot = record.roles.includes("ROLE_ADMIN");
          return (
            <>
              {!record.blocked && (
                <Button
                  className="block-btn"
                  type="danger"
                  size="small"
                  onClick={() => this.blockUser(record.id)}
                >
                  Block
                </Button>
              )}
              {record.blocked && (
                <Button
                  className="block-btn"
                  type="default"
                  size="small"
                  onClick={() => this.unblockUser(record.id)}
                >
                  Unblock
                </Button>
              )}

              {this.doShowAdmin && (
                <Button
                  disabled={!showPromoteToAdmin}
                  type="default"
                  size="small"
                  onClick={() => this.promoteTo(record.username, "ROLE_ADMIN")}
                >
                  Promote To Admin
                </Button>
              )}

              {this.doShowRoot && (
                <Button
                  disabled={!showPromoteToRoot}
                  type="default"
                  size="small"
                  onClick={() => this.promoteTo(record.username, "ROLE_ROOT")}
                >
                  Promote To ROOT
                </Button>
              )}
            </>
          );
        },
      },
    ];

    return (
      <div className="PeoplePage">
        <div className="menu-row">
          <div className="u-padding-both-sides-25">
            <Notifications />
          </div>
        </div>
        {this.state.forbidden && <div>No access</div>}
        {!this.state.forbidden && (
          <div>
            <div className="grid-x list">
              <div className="cell small-12">
                <div className="u-padding-both-sides-25">
                  <Table
                    dataSource={this.state.users}
                    columns={cols}
                    rowKey="id"
                    isLoading={this.state.isLoading}
                    bordered
                    onChange={this.handleChange}
                    pagination={{
                      defaultPageSize: 20,
                      pageSizeOptions: ["20", "40", "80", "100", "200", "500"],
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PeoplePage;
