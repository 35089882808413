import localforage from "localforage";
import { bytesToSize } from "../utils/StringUtils";

window.localforage = localforage;

class Storage {
  constructor(DBName) {
    this.instance = localforage.createInstance({ name: DBName });
  }
  /**
   * returns value to callback function
   * @param {string} key
   * @param {function} cb returns value here
   */
  async getCB(key, cb) {
    const data = await this.instance.getItem(key);
    return cb(data);
  }

  /**
   * returns value to promise function
   * @param {string} key
   */
  async getProm(key) {
    const data = await this.instance.getItem(key);
    return data;
  }

  /**
   * sets value and returns cb functions if when done.
   * @param {string} key
   * @param {any} val
   * @param {function} cb called when value is really set, usually may take ~100ms
   */
  set(key, val, cb = () => {}) {
    this.instance
      .setItem(key, val)
      .then(setValue => {
        cb();
      })
      .catch(function(err) {
        console.log("Localforget value not set: ", err);
      });
  }
  /**
   * Setting values, returning promise.
   * @param {string} key
   * @param {any} val
   */
  async setProm(key, val) {
    return this.instance.setItem(key, val);
  }

  remove(key, cb = () => {}) {
    this.instance
      .removeItem(key)
      .then(() => {
        cb();
      })
      .catch(function(err) {
        console.log("Localforget value not removed: ", err);
      });
  }

  cleanAll() {
    this.instance.clear(ok => {
      console.log("[Storage.js] clean all", ok);
    });
  }
}
export const StorageStaticDefault = new Storage("default");
export default Storage;

class MemStorage {
  constructor(DBName) {
    this.instance = {
      name: DBName
    };
    this.prefixesToClean = [];
  }
  set(key, val) {
    this.instance[key] = val;
  }
  get(key) {
    let retVal;
    if (Array.isArray(this.instance[key])) {
      retVal = [...this.instance[key]];
    } else {
      retVal = { ...this.instance[key] };
    }
    return retVal;
  }
  getCB(key, cb) {
    const data = this.get(key);
    return cb(data);
  }
  async getProm(key) {
    const data = this.get(key);
    return await new Promise.resolve(data);
  }
  removeByPrefix(prefix) {
    const sizeBefore = this.getSize();
    for (const key in this.instance) {
      if (key.startsWith(prefix)) {
        delete this.instance[key];
      }
    }
    const sizeAfter = this.getSize();
    console.info("[Storage.js] In Mem Size", sizeBefore, "=>", sizeAfter);
  }
  setPrefixesToClean(prefixes) {
    this.prefixesToClean = prefixes;
  }

  cleanUpBP() {
    this.prefixesToClean.forEach(path => {
      this.removeByPrefix(path);
    });
  }
  getSize() {
    const blob = new Blob([JSON.stringify(this.instance)], {
      type: "application/json"
    });

    return bytesToSize(blob.size);
  }
}

export const memStorage = new MemStorage("BP");
window.memStorage = memStorage;
