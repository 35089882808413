import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { getUserNames } from '../../ProjectsService';
import './ProjectPeople.scss';
import ProfilePicture from '../../../shared/profilepictures/ProfilePicture';

const namesCountToShow = 2;

const ProjectPeople = ({ list, showLoading, projectId }) => {
  const restNumber = list.length - namesCountToShow;
  const sliced = list.slice(0, namesCountToShow);
  const showRestPeople = restNumber > namesCountToShow;
  return (
    <div className="ProjectPeople">
      <div className="headline">
        <h6>People</h6>
        {' '}
&nbsp;&nbsp;
        <Link to={`/projects/${projectId}/settings/?section=people`}>Edit</Link>
      </div>
      <div className={`ld-over ${showLoading ? 'running' : 'not-running'}`}>
        <span className="ld ld-ring ld-spin" />
        <div className="list u-vertical-middle">
          <div className="icons">
            {list.map(obj => <ProfilePicture key={obj.user.id} user={obj.user} />)}
          </div>
          <div className="names">
            {sliced.map((obj, idx) => (
              <span key={obj.user.id} className="name">
                {getUserNames(obj.user)}
                {namesCountToShow - 1 !== idx ? ', ' : ''}
              </span>
            ))}
            {showRestPeople ? (
              <span>
                <span className="u-gray">&nbsp; and</span>
                {' '}
                <Link to={`/projects/${projectId}/settings/?section=people`}>
                  {' '}
                  {restNumber}
                  {' '}
more.
                </Link>
              </span>
            ) : (
              <span>
                <span className="u-gray">&nbsp;and nobody else.</span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPeople;

ProjectPeople.defaultProps = {
  showLoading: false,
};

ProjectPeople.propTypes = {
  list: PropTypes.array.isRequired,
  projectId: PropTypes.number.isRequired,
  showLoading: PropTypes.bool,
};
