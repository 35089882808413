import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";
import AntSelect from "antd/lib/select";
import "./Fasthooks.scss";

class FasthooksMapsCallbacks extends React.Component {
  constructor(props) {
    super(props);
    this.renderDropDown = this.renderDropDown.bind(this);
    this.renderText = this.renderText.bind(this);
  }
  renderText(cellInfo) {
    return <div className="cell-info">{cellInfo.value}</div>;
  }
  renderDropDown(cellInfo) {
    let defaultVal = (cellInfo) => {
      return cellInfo.original ? cellInfo.original.toName : "";
    };
    if (this.props.callbackKey === "hookmap") {
      defaultVal = (cellInfo) => {
        return cellInfo.original ? cellInfo.original.fromName : "";
      };
    }
    return (
      <div className="custom-drop-down">
        <AntSelect
          showSearch
          className="custom-drop-down-w100p"
          onChange={(val) => this.onChangeVal(val, cellInfo)}
          defaultValue={defaultVal(cellInfo)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <AntSelect.Option value={""}>Empty</AntSelect.Option>
          {this.props.optionsDropDown}
        </AntSelect>
      </div>
    );
  }
  onChangeVal = (val, cellInfo) => {
    let data = {};
    if (this.props.callbackKey === "hookmap") {
      data = { ...cellInfo.original, fromName: val };
    } else if (this.props.callbackKey === "callbackmap") {
      data = { ...cellInfo.original, toName: val };
    }
    data._notUsed = false;
    this.props.onValuesCB({ key: this.props.callbackKey, data });
  };
  render() {
    let data = [];
    if (this.props.callbackKey === "hookmap") {
      // hookmap
      if (this.props.hook && this.props.hook.map) {
        data = this.props.hook.map;
      }
    } else if (this.props.callbackKey === "callbackmap") {
      // callbackmap
      if (this.props.callback && this.props.callback.map) {
        data = this.props.callback.map;
      }
    }
    return (
      <div className="FasthooksMapsCallbacks">
        <div>
          {this.props.callbackKey === "hookmap" && (
            <ReactTable
              noDataText="Select an Event."
              data={data}
              columns={[
                {
                  Header: "Fastloop Field",
                  id: "fromLabel",
                  accessor: "fromName",
                  Cell: (row) => this.renderDropDown(row),
                },
                {
                  Header: "Platform Field",
                  id: "toName",
                  accessor: "toName",
                  Cell: (row) => this.renderText(row),
                },
              ]}
              defaultPageSize={10}
              className="-striped"
            />
          )}
          {this.props.callbackKey === "callbackmap" && (
            <ReactTable
              noDataText="Select an Event."
              data={data}
              columns={[
                {
                  Header: "Platform Field",
                  accessor: "fromName",
                  Cell: (row) => this.renderText(row),
                },
                {
                  Header: "Fasloop Field",
                  id: "toLabel",
                  accessor: "toLabel",
                  Cell: (row) => this.renderDropDown(row),
                },
              ]}
              defaultPageSize={10}
              className="-striped"
            />
          )}
        </div>
      </div>
    );
  }
}

export default FasthooksMapsCallbacks;

FasthooksMapsCallbacks.propTypes = {
  event: PropTypes.object.isRequired,
  hook: PropTypes.object.isRequired,
  viewCols: PropTypes.object.isRequired,
  onValuesCB: PropTypes.func.isRequired,
  optionsDropDown: PropTypes.array.isRequired,
  callbackKey: PropTypes.string.isRequired,
};
