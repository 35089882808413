import React from 'react';
import PropTypes from 'prop-types';
import './ProjectViewMetric.scss';

// it's reverse order on purpose, down is good, up is bad.
import DownImg from '../../../shared/icons/arrow-up-green.svg';
import UpImg from '../../../shared/icons/arrow-down-orange.svg';

/**
 * Shows Metrics widget.
 * @param {string} name on top left corner
 * @param {string} value center display
 * @param {string} direction - up or down, shows arrow green or red
 * @param {bool} noDirection - hides direction arrow
 */
const ProjectViewMetric = ({
  name, value, direction, noDirection,
}) => (
  <div className="ProjectViewMetric">
    <div className="name">{name}</div>

    <div className="value">{value}</div>
    {noDirection ? (
      <div />
    ) : (
      <div className="direction">
        <img src={direction === 'up' ? UpImg : DownImg} alt={direction} />
      </div>
    )}
  </div>
);

export default ProjectViewMetric;

ProjectViewMetric.defaultProps = {
  noDirection: false,
  direction: 'up',
};

ProjectViewMetric.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['up', 'down']),
  noDirection: PropTypes.bool,
};
