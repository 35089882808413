import React from "react";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import Alert from "antd/lib/alert";
import PropTypes from "prop-types";

class CustomViewsName extends React.Component {
  constructor(props) {
    super(props);
    this.state = { valid: true };
    this.value = "";
  }
  onChange(value) {
    this.value = value;
    this.setState({ valid: !this.props.nameList.includes(value) });
  }
  render() {
    const title = this.props.rename ? "Rename the View" : "New View Name";
    const cleanName = this.props.name
      .trim()
      .replace("(private)", "")
      .trim();
    return (
      <Modal
        visible={this.props.show}
        title={title}
        okText={"Save"}
        onOk={() => this.props.save(this.value)}
        onCancel={() => this.props.save(null)}
        okButtonProps={{ disabled: !this.state.valid }}
      >
        <div className="CustomViewsName">
          <Input
            defaultValue={cleanName}
            onChange={e => this.onChange(e.target.value)}
          />
          {!this.state.valid && (
            <Alert message="Name already in use" type="warning" showIcon />
          )}
        </div>
      </Modal>
    );
  }
}

export default CustomViewsName;

CustomViewsName.defaultProps = {
  name: ""
};

CustomViewsName.propTypes = {
  name: PropTypes.string,
  rename: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  nameList: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired
};
