import React, { Component } from "react";
import PropTypes from "prop-types";
import Transfer from "antd/lib/transfer";
import ReactDragListView from "react-drag-listview";
import Tabs from "antd/lib/tabs";

import NameEdit from "./NameEdit";

const TITLES = ["Available", "In View"];
const STYLES = {
  list: {
    width: 350,
    height: 650
  },
  editIcon: { float: "right", paddingRight: "10px" }
};

const filterOption = (inputValue, option) =>
  option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

class ClientViewEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderLine = item => {
    return (
      <span>
        <span> {item.title} </span>
      </span>
    );
  };

  getDragProps = (level, dxNumber) => ({
    onDragEnd: (fromIndex, toIndex) => {
      const ids = this.getIds();
      this.props.onDragEdit(level, fromIndex, toIndex, dxNumber, ids);
    },
    nodeSelector:
      ".ant-transfer-list:last-child .ant-transfer-list-content > div"
  });

  handleChangeD1 = (targetKeys, direction, moveKeys) => {
    this.handleChangeD123(targetKeys, direction, moveKeys, "d1Selected", 1);
  };
  handleChangeD2 = (targetKeys, direction, moveKeys) => {
    this.handleChangeD123(targetKeys, direction, moveKeys, "d2Selected", 2);
  };
  handleChangeD3 = (targetKeys, direction, moveKeys) => {
    this.handleChangeD123(targetKeys, direction, moveKeys, "d3Selected", 3);
  };

  handleChangeD123 = (targetKeys, direction, moveKeys, varName, dxNumber) => {
    const ids = this.getIds();
    this.props.handleChange123(
      targetKeys,
      direction,
      moveKeys,
      varName,
      dxNumber,
      ids
    );
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });
  };

  setNewName(newName) {
    const ids = this.getIds();
    this.props.onRename(ids, newName);
  }
  onDeleteView = () => {};

  getIds = () => {
    const ids = {
      d1Id: this.props.DXData.d1Id,
      d2Id: this.props.DXData.d2Id,
      d3Id: this.props.DXData.d3Id
    };
    return ids;
  };

  render() {
    return (
      <div className="ClientViewEditor">
        <div className="header">
          <NameEdit
            viewName={this.props.view.viewName}
            onSave={newName => this.setNewName(newName)}
          />
        </div>
        <div className="body">
          <Tabs
            defaultActiveKey="dynamic1"
            // onChange={this.onTabActivate}
            tabPosition="left"
          >
            <Tabs.TabPane tab="Plan" key="dynamic1">
              <ReactDragListView {...this.getDragProps("d1Selected", 1)}>
                <Transfer
                  dataSource={this.props.DXData.d1}
                  targetKeys={this.props.DXData.d1Selected}
                  showSearch
                  filterOption={filterOption}
                  listStyle={STYLES.list}
                  onChange={this.handleChangeD1}
                  onSelectChange={this.handleSelectChange}
                  render={item => this.renderLine(item)}
                  titles={TITLES}
                />
              </ReactDragListView>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Placement" key="dynamic2">
              <ReactDragListView {...this.getDragProps("d2Selected", 2)}>
                <Transfer
                  dataSource={this.props.DXData.d2}
                  targetKeys={this.props.DXData.d2Selected}
                  showSearch
                  filterOption={filterOption}
                  listStyle={STYLES.list}
                  onChange={this.handleChangeD2}
                  onSelectChange={this.handleSelectChange}
                  render={item => this.renderLine(item)}
                  titles={TITLES}
                />
              </ReactDragListView>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Creative" key="dynamic3">
              <ReactDragListView {...this.getDragProps("d3Selected", 3)}>
                <Transfer
                  dataSource={this.props.DXData.d3}
                  targetKeys={this.props.DXData.d3Selected}
                  showSearch
                  filterOption={filterOption}
                  listStyle={STYLES.list}
                  onChange={this.handleChangeD3}
                  onSelectChange={this.handleSelectChange}
                  render={item => this.renderLine(item)}
                  titles={TITLES}
                />
              </ReactDragListView>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default ClientViewEditor;

ClientViewEditor.propTypes = {
  view: PropTypes.object.isRequired,
  DXData: PropTypes.object.isRequired,
  handleChange123: PropTypes.func.isRequired,
  onDragEdit: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired
};
