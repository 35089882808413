import React, { Component } from "react";
// import PropTypes from 'prop-types';
import differenceInDays from "date-fns/difference_in_days";
import Notifications from "../../shared/notifications/Notifications";
import "./ProjectView.scss";
import { getParamAfterSlug, getQueryStringParam } from "../../utils/URLUtils";
import ProjectsService, {
  milestonesHelper,
  getReportsHelper,
} from "../ProjectsService";
import ProjectActivitySide from "./projectActivity/ProjectActivitySide";
import ProjectPeople from "./projectPeople/ProjectPeople";
import ProjectMilestones from "./projectMilestones/ProjectMilestones";
import ProjectReports from "./projectReports/ProjectReports";
import ProjectAdvertiserNotes from "./projectAdvertiserNotes/ProjectAdvertiserNotes";
import CheckImg from "../../shared/icons/check-circle-white.svg";
import CloseImg from "../../shared/icons/times-circle-white.svg";

class ProjectView extends Component {
  constructor(props) {
    super(props);
    this.projectsService = new ProjectsService();
    const days = differenceInDays(new Date(2018, 1, 1), new Date()) / 31;
    this.state = {
      projectId: 0,
      project: {},
      rolesList: [],
      milestones: [],
      reports: [],
      name: "",
      files: [],
      notes: [],
      showNewMsg: false,
      apiLoaded: false,
      percentCompleteInner: 1 - days,
      percentCompleteOuter: days,
      percentCompleteInnerText: "20%",
      percentCompleteOuterText: "60%",
      forbidden: false,
      customerNotes: [],
    };
  }

  componentDidMount() {
    const projectId = getParamAfterSlug("projects");
    const isNew = getQueryStringParam("new");
    this.projectsService.getProject(
      projectId,
      (data) => {
        this.setState({
          ...this.state,
          projectId,
          project: data,
          name: data.name,
          files: data.files,
          notes: data.notes,
          reports: getReportsHelper(data.files),
          rolesList: data.roles,
          milestones: milestonesHelper(data.milestones),
          apiLoaded: true,
          showNewMsg: isNew,
          forbidden: false,
          customerNotes: data.customer.notes,
        });
      },
      (err) => {
        if (err.response.status === 403) {
          this.setState({ forbidden: true });
        }
        console.log("err", err);
      }
    );
  }

  reloadProject() {
    this.componentDidMount();
  }

  hideNew() {
    this.setState({ showNewMsg: false });
  }

  render() {
    return (
      <div className="ProjectView" data-api-loaded={this.state.apiLoaded}>
        <div className="u-padding-both-sides-25 u-padding-top-10">
          <Notifications />
        </div>
        {this.state.forbidden && (
          <div>
            <div className="u-padding-both-sides-25">
              Sorry, seems you have no access to this page.
            </div>
          </div>
        )}
        {!this.state.forbidden && (
          <div>
            <div className="u-padding-both-sides-25">
              <div
                className={`grid-x notifications-row ${
                  this.state.showNewMsg ? "show" : "hide"
                }`}
              >
                <div className="cell small-12 new-project">
                  <img src={CheckImg} alt="ok" className="" />
                  <strong>{this.state.project.name}</strong> project
                  successfully created.
                  <img
                    src={CloseImg}
                    alt="ok"
                    role="presentation"
                    className="close u-clickable"
                    onClick={() => this.hideNew()}
                    onKeyDown={() => this.hideNew()}
                  />
                </div>
              </div>
            </div>
            <div className="u-padding-both-sides-25">
              <div className="grid-x grid-margin-x">
                <div className="cell small-12 medium-6 border-right">
                  <ProjectActivitySide
                    notes={this.state.notes}
                    files={this.state.files}
                    projectId={parseInt(this.state.projectId, 10)}
                    notesAddedCallback={() => this.reloadProject()}
                  />
                </div>
                <div className="cell small-12 medium-6">
                  <ProjectPeople
                    list={this.state.rolesList}
                    showLoading={!this.state.apiLoaded}
                    projectId={parseInt(this.state.projectId, 10)}
                  />
                  <ProjectMilestones
                    milestones={this.state.milestones}
                    showLoading={!this.state.apiLoaded}
                    projectId={parseInt(this.state.projectId, 10)}
                  />
                  <ProjectReports
                    reports={this.state.reports}
                    showLoading={!this.state.apiLoaded}
                    projectId={parseInt(this.state.projectId, 10)}
                  />
                  <ProjectAdvertiserNotes
                    notes={this.state.customerNotes}
                    showLoading={!this.state.apiLoaded}
                    projectId={parseInt(this.state.projectId, 10)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ProjectView;

ProjectView.propTypes = {
  // match: PropTypes.object.isRequired,
};
