import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  Form,
  Input,
  Checkbox,
  Select,
  Icon,
  Alert,
} from "antd";
import "antd/dist/antd.css";
import { profileServiceStatic } from "../../pages/profile/ProfileService";
import "./AdvertiserView.scss";
// import { projectsServiceStatic } from "../../projects/ProjectsService";
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 14, offset: 5 },
};

const formItemLayoutLabel = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

let id = 0;

const CollectionCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    constructor() {
      super();
      this.state = { acknowledged: false };
    }
    remove = (k) => {
      const { form } = this.props;
      // can use data-binding to get
      const keys = form.getFieldValue("keys");
      // We need at least one passenger
      if (keys.length === 1) {
        return;
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter((key) => key !== k),
      });
    };

    add = () => {
      const { form } = this.props;
      // can use data-binding to get
      const keys = form.getFieldValue("keys");
      const nextKeys = keys.concat(id++);
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys: nextKeys,
      });
    };
    onCreateLocal = () => {};

    handleChange = (e) => {
      this.setState({ acknowledged: e.target.checked });
    };
    shortCodeValidator(rule, value, cb) {
      const regularExpression = new RegExp(/^([a-zA-Z0-9]){2,10}$/);
      var valid = regularExpression.test(value);
      if (valid) return cb();
      else
        return cb([
          new Error(
            "Only letters and numbers, between 2 and 10 characters long"
          ),
        ]);
    }

    render() {
      const { visible, onCancel, onCreate, form, isLoading } = this.props;
      const { getFieldDecorator } = form;

      getFieldDecorator("keys", { initialValue: [] });
      const keys = form.getFieldValue("keys");
      const formItems = keys.map((k, index) => (
        <Form.Item
          {...(index === 0
            ? { ...formItemLayoutLabel }
            : { ...formItemLayoutWithOutLabel })}
          label={index === 0 ? "Users" : ""}
          required={false}
          key={k}
        >
          {getFieldDecorator(`users[${k}]`, {
            validateTrigger: ["onChange", "onBlur"],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Please input user's email name or delete this field.",
              },
            ],
          })(<Input placeholder="user email " className="stdInputs" />)}
          {keys.length > 1 ? (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              disabled={keys.length === 1}
              onClick={() => this.remove(k)}
            />
          ) : null}
        </Form.Item>
      ));

      return (
        <Modal
          visible={visible}
          title="New Advertiser"
          okText="Create"
          onCancel={onCancel}
          onOk={onCreate}
          width={800}
          confirmLoading={isLoading}
          bodyStyle={{ height: "600px", overflow: "scroll" }}
          okButtonProps={{
            disabled: !this.state.acknowledged,
          }}
        >
          <div className="AdvertiserView">
            {this.props.serverError.errorCode !== 0 && (
              <>
                <Alert
                  message={this.props.serverError.message}
                  type="error"
                  closable
                />
                <br />
              </>
            )}
            <Form onSubmit={this.handleSubmit}>
              <Form.Item {...formItemLayout} label="Name">
                {getFieldDecorator("name", {
                  initialValue: this.props.data.name,
                  rules: [
                    {
                      required: true,
                      message: "Please enter name",
                      whitespace: true,
                    },
                  ],
                })(<Input placeholder="Name" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Short Code">
                {getFieldDecorator("shortcode", {
                  initialValue: this.props.data.shortCode,
                  rules: [
                    {
                      max: 10,
                      min: 2,
                      required: true,
                      message:
                        "Only letters and numbers, between 2 and 10 characters long",
                      whitespace: true,
                      validator: this.shortCodeValidator,
                    },
                  ],
                })(<Input className="shortCode" placeholder="Short Code" />)}

                <Checkbox
                  id="acknowledged"
                  name="acknowledged"
                  // checked={this.state.checkNick}
                  onChange={this.handleChange}
                >
                  I understand that short code is final and not alterable.
                </Checkbox>
              </Form.Item>
              <Form.Item label="Parent" {...formItemLayout}>
                {getFieldDecorator("parentId", {
                  initialValue: this.props.data.parentId,
                  rules: [
                    {
                      required: true,
                      message: "Please select parent",
                    },
                  ],
                })(
                  <Select placeholder="Parent">
                    {this.props.parentsList.map((parent) => {
                      return (
                        <Option key={parent.id} value={parent.id}>
                          {parent.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              {formItems}
              <Form.Item {...formItemLayoutWithOutLabel}>
                <Button type="dashed" onClick={this.add} className="stdInputs">
                  <Icon type="plus" /> Add user
                </Button>
              </Form.Item>
              <Form.Item
                className="notes"
                label="Notes (Please specify additional customer requirements, e.g Lookback windows per Platform)"
                {...formItemLayoutWithOutLabel}
              >
                {getFieldDecorator("notes", {})(
                  <Input.TextArea
                    rows={6}
                    placeholder="e.g. Facebook - 1 day click / 26 day view; Campaign Manager 30 day click / 90 day view"
                  ></Input.TextArea>
                )}
              </Form.Item>
            </Form>
          </div>
        </Modal>
      );
    }
  }
);

class AdvertiserView extends React.Component {
  state = {
    visible: false,
    serverError: { errorCode: 0 },
    isLoading: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({ isLoading: true });

      const body = profileServiceStatic.getAdvertiserBody();
      const usersList = [];
      if (values.users) {
        const userBody = profileServiceStatic.getUserBody();
        values.users.forEach((us) => {
          const user = { ...userBody };
          user.email = us;
          usersList.push(user);
        });
      }

      values.users = usersList;

      const noteClone = values.notes;

      delete values.notes;

      values.notes = [{ note: noteClone }];

      const merged = { ...body, ...values };

      profileServiceStatic.registerAdvertiser(
        merged,
        (data) => {
          profileServiceStatic.getCustomers(
            (allCustomersData) => {
              form.resetFields();
              this.setState({ visible: false });
              this.props.onCreated(data, allCustomersData);
            },
            (err) => {
              this.setState({ serverError: err, isLoading: false });
            }
          );
        },
        (err) => {
          this.setState({ serverError: err, isLoading: false });
        }
      );
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div className="AdvertiserView-outer">
        <Button type="primary" onClick={this.showModal}>
          New Advertiser
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          parentsList={this.props.parentsList}
          data={this.props.data}
          serverError={this.state.serverError}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}
export default AdvertiserView;

AdvertiserView.defaultProps = {
  data: {
    name: "",
    shortCode: "",
    parentId: "",
  },
};

AdvertiserView.propTypes = {
  parentsList: PropTypes.array.isRequired,
  onCreated: PropTypes.func.isRequired,
  data: PropTypes.object,
};
