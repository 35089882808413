/* eslint no-case-declarations: 0 */
import * as types from "../types/types";
import { lineAlterationHelper } from "./BPLineAlterationReducerHelper";

const initState = {
  nav: {
    customView: "",
    page: "",
    subPage: "",
    projectId: 0,
    tacticsPopup: {
      open: false,
      lineId: 0
    },
    saveDialog: false,
    deleteDialog: {
      open: false,
      lineToDelete: null
    }
  },
  budgets: {
    total: "",
    left: ""
  },
  lines: {},
  // storing calculated child vals.
  tacticsCalc: {},
  linesChanged: {
    added: [],
    deleted: [],
    altered: [],
    all: {},
    dirty: false
  },
  linesHasErrors: {}, // line id - with error body as obj
  linesExpanded: 0,
  trafficExpanded: 0,
  trafficLines: {},
  adPopup: {
    open: false,
    upladInfoId: 0,
    adTypeId: 0,
    platformId: 0
  },
  adLandingPages: {
    // id of traffic line
  },
  filters: {
    dynamic1: [],
    dynamic2: [],
    dynamic3: []
  },
  dataBus: {
    dynamic1: [],
    dynamic2: [],
    dynamic3: []
  },
  dataBusFormatting: {
    dynamic1: [],
    dynamic2: [],
    dynamic3: []
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.BP_NAV_CUSTOM_VIEW:
      return {
        ...state,
        nav: { ...state.nav, customView: action.payload.customView }
      };
    case types.BP_NAV_PAGE_CHANGE:
      return { ...state, nav: { ...state.nav, page: action.payload.page } };
    case types.BP_NAV_SUBPAGE_CHANGE:
      return {
        ...state,
        nav: { ...state.nav, subPage: action.payload.subPage }
      };
    case types.BP_NAV_CHANGE:
      const newNav = {};
      Object.entries(action.payload).forEach(([key, val]) => {
        if (val) {
          newNav[key] = val;
        }
      });
      return { ...state, nav: { ...state.nav, ...newNav } };
    case types.BP_NAV_OPEN_TACTICS_POPUP:
      return {
        ...state,
        nav: {
          ...state.nav,
          tacticsPopup: {
            ...state.nav.tacticsPopup,
            open: action.payload.open,
            lineId: action.payload.lineId
          }
        }
      };
    case types.BP_OPEN_SAVEDIALOG:
      return {
        ...state,
        nav: {
          ...state.nav,
          saveDialog: action.payload
        }
      };
    case types.BP_OPEN_DELETEDIALOG:
      return {
        ...state,
        nav: {
          ...state.nav,
          deleteDialog: action.payload
        }
      };
    case types.BP_BUDGET:
      return { ...state, budgets: action.payload.budgets };
    case types.BP_NEW_LINE_ADDED:
      const linesClone = { ...state.lines };
      action.payload.forEach(itm => {
        linesClone[itm.id] = itm;
      });
      return { ...state, lines: linesClone };
    case types.BP_LOAD_LINES:
      return { ...state, lines: { ...state.lines, ...action.payload } };
    case types.BP_TR_LINES:
      return {
        ...state,
        trafficLines: { ...state.trafficLines, ...action.payload }
      };
    case types.BP_TR_LANDING_PAGE:
      return {
        ...state,
        adLandingPages: { ...state.adLandingPages, ...action.payload }
      };
    case types.BP_TR_LANDING_PAGE_ADD:
      return {
        ...state,
        adLandingPages: { ...state.adLandingPages, ...action.payload }
      };
    case types.BP_LINES_HAS_ERRORS:
      const errorsClone = { ...state.linesHasErrors };
      const { lineId, errorBody, status } = action.payload;
      if (status === 1) {
        errorsClone[lineId] = errorBody;
      } else {
        delete errorsClone[lineId];
      }
      return { ...state, linesHasErrors: errorsClone };
    case types.BP_LINE_EXPAND:
      let linesExpandedTmp;
      if (action.payload === state.linesExpanded) {
        linesExpandedTmp = 0;
      } else {
        linesExpandedTmp = action.payload;
      }
      return { ...state, linesExpanded: linesExpandedTmp };
    case types.BP_TR_EXPAND:
      let trLinesExpandedTmp;
      if (action.payload === state.trafficExpanded) {
        trLinesExpandedTmp = 0;
      } else {
        trLinesExpandedTmp = action.payload;
      }
      return { ...state, trafficExpanded: trLinesExpandedTmp };
    case types.BP_LINE_ACTION:
      return lineAlterationHelper(state, action);
    case types.BP_RESET_ALL:
      return {
        ...initState,
        nav: { ...initState.nav, projectId: state.nav.projectId }
      };
    case types.BP_AD_POPUP_OPEN:
      return {
        ...state,
        adPopup: {
          open: true,
          upladInfoId: action.payload.upladInfoId,
          adTypeId: action.payload.adTypeId,
          platformId: action.payload.platformId
        }
      };
    case types.BP_FILTERS:
      const filters = { ...state.filters };
      const { type, filter } = action.payload;
      filters[type] = filter;
      return { ...state, filters };
    case types.BP_DATABUS:
      const dataBus = { ...state.dataBus };
      const { level, data } = action.payload;
      dataBus[level] = data;
      return { ...state, dataBus };
    case types.BP_DATABUS_FORMATTING:
      const dataBusFormatting = { ...state.dataBusFormatting };
      dataBusFormatting[action.payload.level] = action.payload.data;
      return { ...state, dataBusFormatting };

    case types.BP_AD_POPUP_CLOSE:
      return {
        ...state,
        adPopup: {
          open: false,
          upladInfoId: 0,
          platformId: 0,
          adTypeId: 0
        }
      };
    default:
      return state;
  }
};
