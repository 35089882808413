import React from "react";
// import HelpLabel from '../../../shared/helpComponent/HelpLabel.jsx';
// import PropTypes from 'prop-types';

import "./MainProjectsLineHeader.scss";

/**
 * Displays single project line. Params in the bottom of the file.
 */
const MainProjectsLineHeader = () => (
  <div className="MainProjectsLineHeader hide-for-small-only">
    <div className="grid-x grid-margin-x">
      <div className="cell small-8 medium-3 large-4 name-cell">
        <span>Plan</span>
      </div>
      <div className="cell small-4 medium-2 large-2 timevsbudget-cell text-center">
        {/* <span>Time vs Budget</span> */}
      </div>
      <div className="cell small-6 medium-1 large-1 spend-cell">
        {/* <span>
          <HelpLabel code="1">Spend-V</HelpLabel>
        </span> */}
      </div>
      <div className="cell small-6 medium-2 large-1 ends-cell">
        <span>Plan Starts</span>
      </div>
      <div className="cell small-6 medium-2 large-1 ends-cell">
        <span>Plan Ends</span>
      </div>
      <div className="cell small-12 medium-2 large-1 lu-cell">
        <span>Last Updated</span>
      </div>
      <div className="cell small-12 medium-2 large-1 lu-cell">
        <span>Media plan</span>
      </div>
      <div className="cell small-12 medium-2 large-1">
        <span>Status</span>
      </div>
    </div>
  </div>
);

export default MainProjectsLineHeader;
