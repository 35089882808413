import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import './MetasEditor';
import FormulasEngine from '../../../utils/formulasEngine/formulasEngine';
import CustomFormulas from '../../../utils/formulas';

const SupportedFormulasList = ({ anchorEl, handleClose }) => {
  const formulasEngineInstance = new FormulasEngine();
  const formulas = formulasEngineInstance.helper.SUPPORTED_FORMULAS;
  return (
    <div className="SupportedFormulasList">
      <Popover
        id="simple-popper"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="SupportedFormulasListBody">
          {Object.keys(CustomFormulas).map(formula => (
            <div className="cellx" key={formula}>
              {formula}
            </div>
          ))}
          <br />
          {formulas.map(formula => (
            <div className="cellx" key={formula}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.excelfunctions.net/search.html?q=${formula}`}
              >
                {formula}
              </a>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default SupportedFormulasList;

SupportedFormulasList.defaultProps = {
  anchorEl: null,
};

SupportedFormulasList.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};
