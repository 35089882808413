import React, { Component } from "react";
import "antd/dist/antd.css";
import { Button } from "antd";

import "../clientsPage/ClientsPage.scss";
import ProjectsService from "../../projects/ProjectsService";

const NEXT = "Next",
  SAVE = "Save",
  USER = "USER",
  CRED = "CRED",
  PA = "PA",
  ACCOUNT = "ACCOUNT";

export default class NextFinsh extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectsService();
  }

  handleClick = (tab, tabType, id, data, label) => {
    if (label === NEXT && tab === 4) {
      this.props.changeTab(1);
    } else if (label === NEXT) {
      this.props.changeTab(tab + 1);
    } else if (label === SAVE) {
      this.saveData(id, data, tabType);
    }
  };

  saveData = (id, data, tabType) => {
    if (tabType === USER) this.saveUsersData(id, data);
    else if (tabType === CRED)
      this.projectService.updateCredentials(
        id,
        data,
        this.props.onSave,
        this.props.onFail
      );
    else if (tabType === PA) {
      this.projectService.updatePartnersAdvertisers(
        data,
        this.props.onSave,
        this.props.onFail
      );
    } else if (tabType === ACCOUNT)
      this.projectService.updateCustomerContacts(
        id,
        data,
        () => this.props.onSave(),
        () => this.props.onFail()
      );
  };

  saveUsersData = (id, data) => {
    const newData = data.map(user => {
      if (!user.isNew) return user;
      delete user.id;
      delete user.isNew;
      return user;
    });
    this.projectService.updateCustomerUsers(
      id,
      newData,
      () => this.props.onSave(),
      () => this.props.onFail()
    );
  };

  render() {
    const { edittedData, tabKey, client, tabType } = this.props;
    return (
      <div className="save-next-buttons">
        <Button
          type="primary"
          htmlType="submit"
          onClick={() =>
            this.handleClick(tabKey, tabType, client.id, edittedData, SAVE)
          }
        >
          Save
        </Button>
        <Button
          type="secondary"
          disabled={!edittedData}
          onClick={() =>
            this.handleClick(tabKey, tabType, client.id, edittedData, NEXT)
          }
        >
          Next
        </Button>
      </div>
    );
  }
}
