import React, { Component } from "react";
import { Table, Popconfirm, Select, Button, notification, Icon } from "antd";

import ProjectsService from "../../projects/ProjectsService";
import CreateNewModal from "./CreateNewModal";
import NextFinish from "./NextFinish";

const Option = Select.Option;

export class UserTable extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectsService();
    this.columns = [
      {
        title: "User Name",
        dataIndex: "user.contact",
        render: name => `${name.firstname} ${name.lastname}`
      },
      {
        title: "Email",
        dataIndex: "user.contact.email"
      },
      {
        title: "Access Rights",
        dataIndex: "role.role",
        render: (text, record) => (
          <Select
            value={text}
            style={{ width: 150 }}
            onChange={val => this.onChangeRole(record, val)}
          >
            <Option key={1} value={1} disabled={text === "admin"}>
              admin
            </Option>
            <Option key={2} value={2} disabled={text === "user"}>
              user
            </Option>
          </Select>
        )
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <Popconfirm
            title="Are you sure? Cannot undo once page is saved."
            placement="left"
            okText="Yes"
            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
            onConfirm={() => this.handleDelete(record)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        )
      }
    ];

    this.state = {
      accountUsers: this.props.accountUsers,
      usersToBeEdited: []
    };
  }

  addNewUser = values => {
    const newTobeAdded = [...this.state.usersToBeEdited];
    const newUsers = [...this.state.accountUsers];
    const newId = Date.now();
    if (!values.phone) values.phone = "";
    const newUser = {
      id: newId,
      isNew: true,
      user: {
        contact: {
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          phone: values.phone
        }
      },
      role: {
        role: "user",
        id: 2
      }
    };
    const newUserToAdd = {
      id: newId,
      isNew: true,
      email: values.email,
      firstname: values.firstname,
      lastname: values.lastname,
      phone: values.phone,
      roleId: 2
    };
    newUsers.push(newUser);
    newTobeAdded.push(newUserToAdd);
    this.setState({ accountUsers: newUsers, usersToBeEdited: newTobeAdded });
  };

  handleDelete = user => {
    const newUsers = this.state.accountUsers.filter(u => u.id !== user.id);
    const newToBeDeleted = this.state.usersToBeEdited.filter(
      u => u.id !== user.id
    );
    if (user.isNew) {
      this.setState({
        accountUsers: newUsers,
        usersToBeEdited: newToBeDeleted
      });
    } else {
      const newUserToDelete = {
        id: user.id,
        delete: true
      };
      newToBeDeleted.push(newUserToDelete);
      this.setState({
        accountUsers: newUsers,
        usersToBeEdited: newToBeDeleted
      });
    }
  };

  onChangeRole = (user, val) => {
    let newRole = "";
    if (val === 1) {
      newRole = "admin";
    } else {
      newRole = "user";
    }
    const clone = this.state.accountUsers.map(u => {
      if (u.id !== user.id) return u;
      u.role.role = newRole;
      u.role.id = val;
      return u;
    });
    if (this.state.usersToBeEdited.some(u => u.id === user.id)) {
      const newToBeEdited = this.state.usersToBeEdited.map(u => {
        if (u.id !== user.id) return u;
        u.roleId = val;
        return u;
      });
      this.setState({ accountUsers: clone, usersToBeEdited: newToBeEdited });
    } else {
      const newToBeEdited = [...this.state.usersToBeEdited];
      const newUserToEdit = {
        id: user.id,
        userId: user.user.id,
        roleId: val
      };
      newToBeEdited.push(newUserToEdit);
      this.setState({ accountUsers: clone, usersToBeEdited: newToBeEdited });
    }
  };

  showMessage = (type, title, descr) => {
    let dur = 0;
    if (type === "success") {
      dur = 2;
    }
    notification[type]({
      message: title,
      description: descr,
      duration: dur,
      placement: "bottomRight"
    });
  };

  onSave = () => {
    this.setState({ usersToBeEdited: [] });
    this.showMessage(
      "success",
      "Success!",
      "Your client`s users have been updated."
    );
  };

  onFail = () => {
    this.showMessage(
      "error",
      "Error!",
      "Something went wrong! Users did not save, please check info and try again."
    );
  };

  render() {
    const { create, client, changeTab, tabKey } = this.props;
    return (
      <div>
        <div align="right">
          <CreateNewModal
            buttonText="Add New User"
            modalTitle="Add a New User"
            addNew={this.addNewUser}
          />
        </div>
        <div>
          <Table
            columns={this.columns}
            dataSource={this.state.accountUsers}
            rowKey={record => record.id}
            size="middle"
          />
        </div>
        <div align="right">
          <NextFinish
            client={client}
            create={create}
            createClient={this.props.createClient}
            edittedData={this.state.usersToBeEdited}
            onSave={() => this.onSave()}
            onFail={() => this.onFail()}
            changeTab={changeTab}
            tabType={"USER"}
            tabKey={tabKey}
          />
        </div>
      </div>
    );
  }
}

export default UserTable;
