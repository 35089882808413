import React from 'react';
import PropTypes from 'prop-types';
import ProfilePicture from '../profilepictures/ProfilePicture';
import './UserCard.scss';

import { getUserNames } from '../../projects/ProjectsService';

const UserCard = ({ user, bigger, showIdsInsteadOfFaces }) => (
  <div className="UserCard">
    {showIdsInsteadOfFaces ? (
      <div className="uid">{user.id}</div>
    ) : (
      <ProfilePicture user={user} bigger={bigger} />
    )}
    <span className="name">{getUserNames(user)}</span>
  </div>
);

export default UserCard;

UserCard.defaultProps = {
  bigger: false,
  showIdsInsteadOfFaces: false,
};

UserCard.propTypes = {
  /**
   * @param {object} user from camp.
   */
  user: PropTypes.object.isRequired,
  bigger: PropTypes.bool,
  showIdsInsteadOfFaces: PropTypes.bool,
};
