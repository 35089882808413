import store from 'store';

const DEV_MODE = 'DEV_MODE';

class devMode {
  setDevMode(v = true) {
    console.warn('[DevMode] set', v);
    return store.set(DEV_MODE, v);
  }
  isDevMode() {
    return store.get(DEV_MODE);
  }
  checkUrl() {
    if (window.location.search.indexOf('dev=1') > -1) {
      this.setDevMode();
    }
    if (window.location.search.indexOf('dev=0') > -1) {
      this.setDevMode(false);
    }
  }
}

const devModeStatic = new devMode();
export { devModeStatic };

export default devMode;
