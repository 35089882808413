import React, { Component } from "react";
import PropTypes from "prop-types";
import localForage from "localforage";
import { GOOGLE_SHEETS_HELP_STORAGE_KEY } from "../Constants";
import { Popover } from "antd";
import "antd/dist/antd.css";
import "./Help.scss";

export class HelpLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      documentation: {}
    };
  }

  componentDidMount() {
    var that = this;
    localForage
      .getItem(GOOGLE_SHEETS_HELP_STORAGE_KEY)
      .then(function(value) {
        that.setState({ documentation: value[that.props.code - 1] });
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  handleHoverChange = visible => {
    this.setState({
      hovered: visible
    });
  };

  getContent = type => {
    if (!this.state.documentation) return;
    if (type === "link") return this.state.documentation[type];
    else return <div>{this.state.documentation[type]}</div>;
  };

  render() {
    const content = this.getContent("content");
    const documentationLink = this.getContent("link");
    return (
      <div className="HelpLabel">
        {!!this.state.documentation.link ? (
          <Popover
            width={500}
            content={
              <div className="Help-link Help-popover">
                {content}
                <a
                  href={documentationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help
                </a>
              </div>
            }
            trigger="hover"
            visible={this.state.hovered}
            onVisibleChange={this.handleHoverChange}
          >
            {this.props.children}
          </Popover>
        ) : (
          <Popover
            content={content}
            trigger="hover"
            visible={this.state.hovered}
            onVisibleChange={this.handleHoverChange}
          >
            <div className="Help-popover">{this.props.children}</div>
          </Popover>
        )}
      </div>
    );
  }
}

export default HelpLabel;

HelpLabel.defaultProps = {};

HelpLabel.propTypes = {
  code: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
