import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Spin from "antd/lib/spin";
import Notifications from "../../../shared/notifications/Notifications";
import ProjectGeneralSettings from "./generalSettings/ProjectGeneralSettings";
import ProjectPeopleSettings from "./projectPeopleSettings/ProjectPeopleSettings";
import "./ProjectSettingsMainView.scss";
import ProjectsService from "../../ProjectsService";
import {
  getParamAfterSlug,
  getQueryStringParam,
} from "../../../utils/URLUtils";
import ProjectMilestonesSettings from "./projectMilestonesSettings/ProjectMilestonesSettings";
// import ProjectTagsSettings from "./projectTagsSettings/ProjectTagsSettings";
import WarnImg from "../../../shared/icons/paper-plane.svg";
import CloseImg from "../../../shared/icons/times-circle-white.svg";
import SMFSettings from "./SMFSettings/SMFSettings";
import { isAdmin, isRoot } from "../../../auth/AuthService";
// import MetaSettings from './MetaSettings/MetaSettings';
import ProjectConstants from "./projectConstants/ProjectConstants";
import AdminSettings from "./projectAdminSettings/ProjectAdminSettings";
import HelpLabel from "../../../shared/helpComponent/HelpLabel.jsx";
import { BPDevRow } from "../../../pages/bp/BPDevRow";
import { notify } from "../../../shared/notifications/Notify";

class ProjectSettingsMainView extends Component {
  constructor(props) {
    super(props);
    this.projectsService = new ProjectsService();
    this.projectCopy = {};
    this.state = {
      section: "",
      project: {},
      putApiLoadingName: false,
      nameSaved: false,
      budgetSaved: false,
      tokenSaved: false,
      putApiLoadingToken: false,
      showLoadingMain: true,
      showErrorMsg: false,
      forbidden: false,
    };
    this.doShowAdmin = isAdmin();
    this.doShowRoot = isRoot();
  }

  componentDidMount() {
    this.projectId = getParamAfterSlug("projects");
    const section = getQueryStringParam("section") || "general";
    this.projectsService.getProject(
      this.projectId,
      (project) => {
        this.projectCopy = project;
        this.setState(
          {
            ...this.state,
            project,
            section,
            showLoadingMain: false,
          },
          () => {
            this.onClickHandler(null, section);
          }
        );
      },
      (err) => {
        if (err.response.status === 403) {
          this.setState({ forbidden: true });
        }
      }
    );
  }

  onFail = () => {};

  onClickHandler(e, target) {
    this.setState({
      ...this.state,
      section: target,
      project: this.state.project,
    });
  }

  saveGenNext = (key, val) => {
    const pro = { ...this.state.project };
    pro[key] = val;

    this.projectsService.updateProjectGenNext(
      this.state.project.id,
      pro,
      () => {
        notify("Success!", "Settings updated!");
      },
      () => {
        notify(
          "Fail.",
          "Something went wrong, please contact administrator",
          "error"
        );
      }
    );
  };

  saveGeneral(type, val) {
    let nameVal = this.state.project.name;
    let budgetVal = this.state.project.budget;
    let reportingVal = this.state.project.reportingUrl;
    let tokenVal = this.state.project.reportingToken;
    let saving = {};
    if (type === "name") {
      nameVal = val;
      this.setState({ putApiLoadingName: true });
      saving = {
        putApiLoadingName: false,
        putApiLoadingToken: false,
        nameSaved: true,
        tokenSaved: false,
      };
    } else if (type === "budget") {
      budgetVal = val;
      this.setState({ putApiLoadingBudget: true });
      saving = {
        putApiLoadingName: false,
        putApiLoadingBudget: false,
        putApiLoadingToken: false,
        nameSaved: false,
        budgetSaved: true,
        tokenSaved: false,
      };
    } else if (type === "tableau") {
      reportingVal = val;
      this.setState({ putApiLoadingTableau: true });
      saving = {
        putApiLoadingName: false,
        putApiLoadingToken: false,
        nameSaved: false,
        tokenSaved: false,
      };
    } else {
      tokenVal = val;
      this.setState({ putApiLoadingToken: true });
      saving = {
        putApiLoadingName: false,
        putApiLoadingToken: false,
        nameSaved: false,
        tokenSaved: true,
      };
    }
    this.projectsService.updateProjectGeneral(
      this.state.project.id,
      nameVal,
      budgetVal,
      reportingVal,
      tokenVal,
      () => {
        const clone = Object.assign({}, this.state.project);
        clone.name = nameVal;
        clone.reportingUrl = reportingVal;
        clone.reportingToken = tokenVal;
        this.setState({
          ...this.state,
          ...saving,
          project: clone,
        });
      },
      (err) => {
        console.warn("err", err);
        this.setState({
          ...this.state,
          ...saving,
          showErrorMsg: true,
        });
      }
    );
  }

  hideErrorMsg() {
    this.setState({ showErrorMsg: false });
  }

  reloadProject() {
    this.componentDidMount();
  }

  render() {
    if (this.state.forbidden) {
      return (
        <div className="ProjectSettingsMainView">
          <div>
            <div className="u-padding-top-10 u-padding-both-sides-25">
              Sorry, seems you have no access to this page.
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="ProjectSettingsMainView">
        <div className="settings-menu">
          <Spin spinning={this.state.showLoadingMain}>
            <div>
              <div className="u-padding-both-sides-25">
                <ul className="menu">
                  <li className="general">
                    <Link
                      className={
                        this.state.section === "general" ? "active" : ""
                      }
                      onClick={(event) => this.onClickHandler(event, "general")}
                      to={`${this.props.match.url}`}
                    >
                      General
                    </Link>
                  </li>
                  <li className="people">
                    <Link
                      className={
                        this.state.section === "people" ? "active" : ""
                      }
                      onClick={(event) => this.onClickHandler(event, "people")}
                      to={`${this.props.match.url}?section=people`}
                    >
                      People
                    </Link>
                  </li>
                  <li className="dates">
                    <Link
                      className={this.state.section === "dates" ? "active" : ""}
                      onClick={(event) => this.onClickHandler(event, "dates")}
                      to={`${this.props.match.url}?section=dates`}
                    >
                      Dates
                    </Link>
                  </li>
                  {/* <li className="tags">
                    <Link
                      className={this.state.section === "tags" ? "active" : ""}
                      onClick={(event) => this.onClickHandler(event, "tags")}
                      to={`${this.props.match.url}?section=tags`}
                    >
                      Tags
                    </Link>
                  </li> */}
                  <li className="kpi">
                    <Link
                      className={this.state.section === "kpi" ? "active" : ""}
                      onClick={(event) => this.onClickHandler(event, "kpi")}
                      to={`${this.props.match.url}?section=kpi`}
                    >
                      KPI Settings
                    </Link>
                  </li>
                  {/* <li className="metas">
                  <Link
                    className={this.state.section === 'metas' ? 'active' : ''}
                    onClick={event => this.onClickHandler(event, 'metas')}
                    to={`${this.props.match.url}?section=metas`}
                  >
                    Metas Editor
                  </Link>
                </li> */}
                  <li className="metas">
                    <Link
                      className={
                        this.state.section === "constants" ? "active" : ""
                      }
                      onClick={(event) =>
                        this.onClickHandler(event, "constants")
                      }
                      to={`${this.props.match.url}?section=constants`}
                    >
                      Constants
                    </Link>
                  </li>
                  {this.doShowAdmin && (
                    <li className="fasthooks">
                      <HelpLabel code="2">
                        <Link
                          className={
                            this.state.section === "fasthooks" ? "active" : ""
                          }
                          onClick={(event) =>
                            this.onClickHandler(event, "fasthooks")
                          }
                          to={`${this.props.match.url}?section=fasthooks`}
                        >
                          Fasthooks
                        </Link>
                      </HelpLabel>
                    </li>
                  )}
                  {this.doShowRoot && (
                    <li className="admin">
                      <Link
                        className={
                          this.state.section === "admin" ? "active" : ""
                        }
                        onClick={(event) => this.onClickHandler(event, "admin")}
                        to={`${this.props.match.url}?section=admin`}
                      >
                        Admin Settings
                      </Link>
                    </li>
                  )}
                  {/* <li> Not for P1
                <Link
                  // className={this.state.section === 'alerts' ? 'active' : ''}
                  className="disabled"
                  onClick={event => this.onClickHandler(event, 'alerts')}
                  to={`${this.props.match.url}?section=alerts`}
                >
                  Alerts
                </Link>
              </li> */}
                </ul>
              </div>
            </div>
          </Spin>
        </div>
        {/* for future use */}
        <div className="u-padding-both-sides-25 u-padding-bottom-10">
          <Notifications />
        </div>
        <div className="u-padding-both-sides-25">
          <div
            className={`grid-x notifications-row error ${
              this.state.showErrorMsg ? "show" : "hide"
            }`}
          >
            <div className="cell small-12 new-project">
              <img src={WarnImg} alt="error" className="" />
              We have unexpected exception, developers has been notified. Sorry
              :(
              <img
                src={CloseImg}
                alt="ok"
                role="presentation"
                className="close u-clickable"
                onClick={() => this.hideErrorMsg()}
                onKeyDown={() => this.hideErrorMsg()}
              />
            </div>
          </div>
        </div>
        <div className="u-padding-both-sides-25">
          <div className={`${this.state.section === "general" ? "" : "hide"}`}>
            <ProjectGeneralSettings
              project={this.state.project}
              onSave={(e, x) => this.saveGeneral(e, x)}
              onSaveGenNext={(key, val) => this.saveGenNext(key, val)}
              showLoading={this.state.putApiLoadingName}
              showLoadingToken={this.state.putApiLoadingToken}
              nameSaved={this.state.nameSaved}
              tokenSaved={this.state.tokenSaved}
              budgetSaved={this.state.budgetSaved}
              bqUpdateSaved={this.state.bqUpdate}
            />
          </div>
          <div className={`${this.state.section === "people" ? "" : "hide"}`}>
            <ProjectPeopleSettings
              project={this.state.project}
              onSave={() => this.reloadProject()}
              onFail={() => this.onFail()}
            />
          </div>
          <div className={`${this.state.section === "dates" ? "" : "hide"}`}>
            {this.state.section === "dates" && (
              <ProjectMilestonesSettings
                onSave={() => this.reloadProject()}
                project={this.state.project}
                onFail={() => this.onFail()}
              />
            )}
          </div>
          {/* <div className={`${this.state.section === "tags" ? "" : "hide"}`}>
            <ProjectTagsSettings
              project={this.state.project}
              onTagsChange={() => this.reloadProject()}
              onFail={() => this.onFail()}
            />
          </div> */}
          <div className={`${this.state.section === "kpi" ? "" : "hide"}`}>
            {this.state.section === "kpi" && (
              <SMFSettings project={this.state.project} />
            )}
          </div>
          {/* <div className={`${this.state.section === 'metas' ? '' : 'hide'}`}>
            {this.state.section === 'metas' && <MetaSettings project={this.state.project} />}
          </div> */}
          <div
            className={`${this.state.section === "constants" ? "" : "hide"}`}
          >
            {this.state.section === "constants" && (
              <ProjectConstants project={this.state.project} />
            )}
          </div>
          <div
            className={`${this.state.section === "fasthooks" ? "" : "hide"}`}
          >
            {this.state.section === "fasthooks" && (
              <>
                <BPDevRow
                  projectId={this.state.project.id}
                  project={this.state.project}
                  defaultShowFashooks={true}
                />
              </>
            )}
          </div>
          <div className={`${this.state.section === "admin" ? "" : "hide"}`}>
            {this.state.section === "admin" && (
              <AdminSettings project={this.state.project} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectSettingsMainView;

ProjectSettingsMainView.propTypes = {
  match: PropTypes.object.isRequired,
};
