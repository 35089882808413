import React from "react";
import PropTypes from "prop-types";
import Menu from "antd/lib/menu";
import Icon from "antd/lib/icon";
import Spin from "antd/lib/spin";
import { commonModalsOpenBound } from "../../../actions/commonDataActionsUtils";
import { listenForCustomMenuChanges } from "../../../actions/commonDataActionsUtils";
import { getViewsMetaInfo } from "../BPViewService";
import { extractViewNames2 } from "./MPUtils";
import { BPVIEWS } from "../../../shared/Constants";
import { memStorage } from "../../../services/Storage";

class BPJoinedNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      viewNames: [],
      names: [],
      isLoading: true
    };
    this.viewData = [];
  }
  componentDidMount() {
    this.loadMenus();
    this.listenForModalChange = listenForCustomMenuChanges(() => {
      this.setState({ isLoading: true });
      this.loadMenus();
    });
  }
  loadMenus() {
    this.viewData = getViewsMetaInfo(this.props.projectId);
    const viewNames = extractViewNames2(this.viewData);
    const names = this.splitDefaultNames(viewNames);
    let current = this.state.current;
    if (this.state.current === 0) {
      current = names[0].id;
      const viewIds = this.getViewIds(names[0].viewName);

      memStorage.set("selectedView", {
        id: Number(current),
        name: names[0].viewName,
        viewIds
      });
    }
    this.setState({ viewNames, names, isLoading: false, current });
  }

  componentWillUnmount() {
    this.listenForModalChange();
  }
  handleClick = key => {
    const viewIds = this.getViewIds(key.item.props.name);

    memStorage.set("selectedView", {
      id: Number(key.key),
      name: key.item.props.name,
      viewIds
    });
    this.setState({ current: key.key }, () => {
      this.props.handleClick(key.item.props.name, key);
    });
  };
  editClick = (e, name) => {
    e.preventDefault();
    e.stopPropagation();

    commonModalsOpenBound("customviews", {
      projectId: Number(this.props.projectId),
      selectedViewName: name
    });
  };

  getViewIds(name) {
    const vp1 = this.viewData.filter(
      v => v.viewName === name && v.type === "dynamic1"
    );
    const vp2 = this.viewData.filter(
      v => v.viewName === name && v.type === "dynamic2"
    );
    const vp3 = this.viewData.filter(
      v => v.viewName === name && v.type === "dynamic3"
    );
    const viewIds = {
      dynamic1: vp1[0],
      dynamic2: vp2[0],
      dynamic3: vp3[0]
    };
    return viewIds;
  }

  splitDefaultNames(viewNames) {
    let names = [];
    if (Array.isArray(viewNames) && viewNames.length >= 1) {
      const noAll = viewNames.filter(
        v => v.viewName !== BPVIEWS.VIEWS.ALLCOLUMNSNAME
      );
      const publicDefault = noAll.filter(
        v => v.viewName === BPVIEWS.VIEWS.DEFAULTNAME
      );
      const publicNames = noAll
        .filter(
          v => v.userId === null && v.viewName !== BPVIEWS.VIEWS.DEFAULTNAME
        )
        .sort((a, b) => {
          const aa = a.viewName.toUpperCase();
          const bb = b.viewName.toUpperCase();
          return aa.localeCompare(bb);
        });

      const userNames = viewNames
        .filter(v => v.userId !== null)
        .sort((a, b) => {
          const aa = a.viewName.toUpperCase();
          const bb = b.viewName.toUpperCase();
          return aa.localeCompare(bb);
        });
      names = [...publicDefault, ...publicNames, ...userNames];
    }
    //removes nulls
    names = names.filter(v => {
      return v;
    });
    return names;
  }

  render() {
    const currentStr = this.state.current.toString();
    return (
      <div className="BPJoinedNav" id="BPCustomViewsNav">
        <Spin spinning={this.state.isLoading}>
          <Menu
            onClick={this.handleClick}
            selectedKeys={[currentStr]}
            mode="horizontal"
          >
            {this.state.names.map(name => {
              return (
                <Menu.Item key={name.id} name={name.viewName}>
                  {name.viewName}
                  {"  "}
                  <Icon
                    onClick={e => this.editClick(e, name.viewName)}
                    type="edit"
                    className="nav-edit"
                  />
                </Menu.Item>
              );
            })}
          </Menu>
        </Spin>
      </div>
    );
  }
}

export default BPJoinedNav;

BPJoinedNav.propTypes = {
  handleClick: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired
};
