import React, { useState, useCallback } from "react";
import NewWindow from "react-new-window";
import { useEventListener } from "../../utils/customHooks";
import FasthookLogs from "./FasthookLogs";

const EVENT_NAME = "CUSTOM_EVENT_OPEN_FASTHOOKS_LOG";

export default function FasthooksLogsOutside() {
  const [data, setData] = useState({
    showLogs: false,
    projectId: 0,
    props: {},
  });

  const handler = useCallback(
    (event) => {
      const showLogs = event.detail.showLogs;
      const projectId = event.detail.projectId;
      const props = event.detail.props;
      setData({ showLogs, projectId, props });
    },
    [setData]
  );

  useEventListener(EVENT_NAME, handler);

  return (
    <>
      {data.showLogs && (
        <>
          <NewWindow
            center="screen"
            title="Fasthook Logs"
            features={{ width: 1400, height: 800 }}
            onUnload={() => {
              setData({ showLogs: false, projectId: 0 });
            }}
          >
            <FasthookLogs projectId={Number(data.projectId)} {...data.props} />
          </NewWindow>
        </>
      )}
    </>
  );
}

export const openLogsOutside = (projectId, props = {}) => {
  var event = new CustomEvent(EVENT_NAME, {
    detail: { showLogs: true, projectId, props },
  });
  window.dispatchEvent(event);
};

export const closeLogsOutside = () => {
  var event = new CustomEvent(EVENT_NAME, {
    detail: { showLogs: false, projectId: 0, props: {} },
  });
  window.dispatchEvent(event);
};
