import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NewProjectSubTitle from './NewProjectSubTitle';

/**
 * Handles note textarea
 * @param {func} noteCallback - callback @returns note
 */
class NewProjectNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
    };
  }

  noteHandler = (event) => {
    this.setState({ note: event.target.value }, () => {
      this.props.noteCallback(this.state.note);
    });
  };

  render() {
    return (
      <div className="NewProjectNotes">
        <div className="grid-container">
          <div className="mid grid-x">
            <div className="cell small-12 medium-6">
              <NewProjectSubTitle
                title="Notes"
                desc="Include any further information that will be useful for others."
                descEndInGray="(Optional.)"
              />
              <div className="grid-x">
                <div className="cell small-12 v-center">
                  <textarea className="notes-box" onChange={this.noteHandler} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewProjectNotes;

NewProjectNotes.propTypes = {
  noteCallback: PropTypes.func.isRequired,
};
