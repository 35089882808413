import React from 'react';
import PropTypes from 'prop-types';
import Forward from '@material-ui/icons/Forward';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

/**
 * Styled MUI buttons
 */

const stylesNormal = {
  root: {
    color: '#A9B2BD',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: 400,
  },
};

const stylesActive = {
  root: {
    color: '#A9B2BD',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '13px',
    fontWeight: 'bold',
  },
};

const stylesNextBtn = {
  label: {
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: 'bold',
  },
};

const styleDeleteBtn = {
  root: {
    width: '35px',
    height: '35px',
  },
};

const stylesTextButtonSmall = {
  root: {
    width: '40px',
    height: '30px',
  },
};

function NavBtn(props) {
  return (
    <Button
      classes={{
        root: props.classes.root,
        label: props.classes.label,
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}

function NextBtn(props) {
  return (
    <Button
      classes={{
        root: props.classes.root,
        label: props.classes.label,
      }}
      {...props}
    >
      {props.children}
      <Forward />
    </Button>
  );
}

function AddNewBtn(props) {
  return (
    <Button
      classes={{
        root: props.classes.root,
        label: props.classes.label,
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}

function TextBtn(props) {
  return (
    <Button
      classes={{
        root: props.classes.root,
        label: props.classes.label,
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}

function DeleteBtn(props) {
  return (
    <IconButton
      classes={{
        root: props.classes.root,
      }}
      aria-label="Delete"
      color="primary"
      {...props}
    >
      <DeleteIcon />
    </IconButton>
  );
}

function CopyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="#3f51b5"
        d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
      />
    </SvgIcon>
  );
}

function CopyBtn(props) {
  return (
    <IconButton
      classes={{
        root: props.classes.root,
      }}
      aria-label="Delete"
      color="primary"
      {...props}
    >
      <CopyIcon />
    </IconButton>
  );
}

NavBtn.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

NextBtn.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

AddNewBtn.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

TextBtn.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

DeleteBtn.propTypes = {
  classes: PropTypes.object.isRequired,
};

CopyBtn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const NormalButton = withStyles(stylesNormal)(NavBtn);
export const ActiveButton = withStyles(stylesActive)(NavBtn);
export const NextButton = withStyles(stylesNextBtn)(NextBtn);
export const AddNewButton = withStyles(stylesNextBtn)(AddNewBtn);
export const TextButton = withStyles(styleDeleteBtn)(TextBtn);
export const TextButtonSmall = withStyles(stylesTextButtonSmall)(TextBtn);
export const DeleteButton = withStyles(styleDeleteBtn)(DeleteBtn);
export const CopyButton = withStyles(styleDeleteBtn)(CopyBtn);
