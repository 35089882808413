import React, { Component } from "react";
import "./BPPage.scss";
import { getActiveProjectId, resetAllBPStateBound } from "./bpUtils";
import { getProjectByIdSelBound } from "../../projects/ProjectReduxUtils";
import BPShell from "./bpCosting/BPShell";
import { initViews, initCommonData } from "./BPViewService";
import Notifications from "../../shared/notifications/Notifications";
import { projectsServiceStatic } from "../../projects/ProjectsService";
import { BPDevRow } from "./BPDevRow";

import { isAdmin } from "../../auth/AuthService";
/**
 * Blocking Plan wrapper page. Loads subpages depending from navigation.
 */
class BPPage extends Component {
  constructor(props) {
    super(props);
    this.state = { section: "init", project: {}, forbidden: false };
    this.isAdmin = isAdmin();
  }

  componentDidMount() {
    const projectId = getActiveProjectId();
    let project = getProjectByIdSelBound(projectId);
    initCommonData("BPPage", projectId);
    if (project) {
      this.setState({ project });
      initViews(projectId, () => {
        this.setState({ projectLoaded: true });
      });
    } else {
      projectsServiceStatic.getProject(
        projectId,
        () => {
          project = getProjectByIdSelBound(projectId);
          this.setState({ project });
          initViews(projectId, () => {
            this.setState({ projectLoaded: true });
          });
        },
        (err) => {
          if (err.response.status === 403) {
            this.setState({ forbidden: true });
          }
        }
      );
    }
  }

  componentWillUnmount() {
    resetAllBPStateBound();
  }

  render() {
    if (this.state.forbidden) {
      return (
        <div className="BPPage">
          <div>
            <div className="u-padding-top-10 u-padding-both-sides-25">
              Sorry, seems you have no access to this page.
            </div>
          </div>
        </div>
      );
    }
    if (!this.state.projectLoaded) {
      return <div className="BPPage">Loading....</div>;
    }
    return (
      <div className="BPPage">
        <div className="notifications-outer">
          <Notifications />
        </div>
        <div className="main-shell h-auto-calc" id="main-shell-id">
          <div className="grid-x h-auto-calc">
            <div className="cell auto relative">
              <div className="main-outer" id="main-outer-w">
                <div className="">
                  <BPShell />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.isAdmin && (
          <div>
            <BPDevRow
              projectId={Number(this.props.match.params.id)}
              project={this.state.project}
              defaultShowFashooks={false}
            />
          </div>
        )}
      </div>
    );
  }
}

export default BPPage;
