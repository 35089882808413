import React from 'react';
import './ComingSoon.scss';

const ComingSoon = () => (
  <div className="ComingSoon">
    <div className="u-padding-both-sides-25 msg"> Page is coming Soon...</div>
  </div>
);

export default ComingSoon;
