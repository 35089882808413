import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import { commonCallForReloadBound } from '../../../actions/commonDataActionsUtils';
import { BP_DRAWER } from '../../../shared/Constants';
class Unhide extends React.Component {
  constructor() {
    super();
    this.show = {};
  }
  onChange(name, e) {
    this.show[name] = e.target.checked;
  }
  save() {
    const un = [];
    Object.keys(this.show).forEach(el => {
      if (this.show[el]) {
        un.push(el);
      }
    });
    commonCallForReloadBound(BP_DRAWER.UNHIDE, {
      unhide: un,
      clickedElement: this.props.clickedElement,
    });
    this.props.closeCall();
  }
  render() {
    const clickedElement = this.props.view.find(
      v => v.name === this.props.clickedElement
    );
    return (
      <div>
        <h5>Un-hide and Insert</h5>
        <ul>
          {this.props.hiddenFields.map(hf => {
            const name = this.props.view.find(v => v.name === hf);
            return (
              <li key={hf}>
                <Checkbox onChange={e => this.onChange(hf, e)}>
                  {name.label}
                </Checkbox>
              </li>
            );
          })}
        </ul>
        <Button onClick={() => this.save()}>Insert</Button>
        <br />
        <em>Insert on the left of "{clickedElement.label}" element</em>
      </div>
    );
  }
}

Unhide.defaultProps = {
  closeCall: () => {},
};

Unhide.propTypes = {
  clickedElement: PropTypes.string.isRequired,
  hiddenFields: PropTypes.array.isRequired,
  view: PropTypes.array.isRequired,
  closeCall: PropTypes.func,
};

export default Unhide;
