import React from "react";
import PlanIconComp from "./PlanIconComp";
import PlacementIconComp from "./PlacementIconComp";
import CreativeIconComp from "./CreativeIconComp";

export { CreativeIconComp, PlacementIconComp, PlanIconComp };

export const IconByLevel = ({ level, ...rest }) => {
  switch (level) {
    case 1:
      return <PlanIconComp {...rest} />;
    case 2:
      return <PlacementIconComp {...rest} />;
    case 3:
      return <CreativeIconComp {...rest} />;
    default:
      return <>n/a</>;
  }
};
